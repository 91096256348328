import {MaskInputController} from "../controllers";
import React, {useMemo} from "react";
import {useFieldFormat, useFormHelper, useTranslation} from "../../hooks";
import {isEnabledByMarket} from "../../utils/MarketConfig";

export default function PostcodeInput(props) {
    const {t} = useTranslation()
    const {formats} = useFieldFormat()
    const {setValue} = useFormHelper()

    const options = useMemo(() => {
        const options = {
            ...props.options,
        }

        if (isEnabledByMarket('autoUppercasePostCode')) {
            if (formats.postcode.getMask()) {
                options.maskInputProps = {
                    onChange,
                    ...props.options?.maskInputProps,
                }
            } else {
                options.inputProps = {
                    onChange,
                    ...props.options?.inputProps,
                }
            }
        }

        return options
    }, [props.options, formats.postcode.getMask])

    function onChange(e) {
        let value = e.target.value

        if (isEnabledByMarket('autoUppercasePostCode')) {
            value = value.toUpperCase()
        }

        setValue('postcode', value, {shouldValidate: true})
    }


    return (
        <MaskInputController
            name="postcode"
            label={t('postcode')}
            mask={formats.postcode.getMask()}
            options={options}
            {...props}
        />
    )
}
