import {Checkbox} from "../../../../forms/inputs";
import {useReseller, useTranslation} from "../../../../hooks";
import {useForm, useWatch} from "react-hook-form";
import {useFormRulesBuilder} from "../../../../forms/hooks";
import {DatePickerController} from "../../../../forms/controllers";
import {FormGroup, FormRow} from "../../../../forms/components";
import Button from "../../../../components/buttons/Button";
import {Form} from "../../../../forms";
import React, {useEffect} from "react";
import {ButtonIcon} from "../../../../components/buttons/_index";
import {useGenerateReport} from "../hooks";
import {InfoComponent} from "../../../../components/_index";
import {ReportGenerateStatusModal} from "./index";

export default function SelectedReportsUsersActions({query, selectedItems, setSelectedItems, selectAllItems, idFieldName}) {
    const {t} = useTranslation()
    const {user, role} = useReseller()

    const {
        generateReportMutation,
        alert,
        modalVisible,
        setModalVisible,
        generateStatus
    } = useGenerateReport()


    const formMethods = useForm({
        mode: 'onChange',
    })

    const dateFrom = useWatch({
        control: formMethods.control,
        name: 'date_from'
    })

    const dateTo = useWatch({
        control: formMethods.control,
        name: 'date_to'
    })


    const formRules = useFormRulesBuilder({
        'date_from': b => b.required().lessThen(dateTo),
        'date_to': b => b.required().biggerThan(dateFrom),
    }, formMethods)

    const selectAll = () => {
        let arr = [...selectedItems]
        const currentSelected = selectAllItems.filter(allItem => arr.find(arrItem => arrItem[idFieldName] === allItem[idFieldName]));

        if (currentSelected.length === selectAllItems.length) {
            arr = arr.filter(a => !selectedItems.find(si => a[idFieldName] === si[idFieldName]))
        } else {
            arr = [...arr, ...selectAllItems.filter(allItem => !arr.find(arrItem => allItem[idFieldName] === arrItem[idFieldName]))]
        }

        setSelectedItems(arr)
    }

    const includeMe = () => {
        const arr = [...selectedItems]
        const idx = arr.findIndex(i => i[idFieldName] === user.id_reseller)

        if (idx !== -1) arr.splice(idx, 1);
        else arr.push(user)

        setSelectedItems(arr)
    }

    const handleSubmit = (data) => {
        generateReportMutation.mutate({
            dateFrom: data.date_from,
            dateTo: data.date_to,
            resellerIds: selectedItems.map(si => si[idFieldName])
        })
    }

    useEffect(() => {
        if (generateReportMutation.isSuccess && role !== 'pos_user') setSelectedItems([])
    }, [generateReportMutation.isSuccess])

    useEffect(() => {
        if(role === 'pos_user' || role === 'nca_user')
            includeMe()
    }, [])

    return (
        <div className="selectedReportsUsersActions">
            {(role !== 'pos_user' && role !== 'nca_user') && (
                <div className="selectedReportsUsersActions__header">
                    <Checkbox
                        name="selectAll"
                        label={t('select_all_results', selectAllItems.length)}
                        value={selectedItems.filter(si => selectAllItems.find(sa => sa[idFieldName] === si[idFieldName])).length === selectAllItems.length}
                        options={{
                            inputProps: {
                                onChange: selectAll
                            }
                        }}
                    />
                    <Checkbox
                        name="withMe"
                        label={t('reports_users.filters.generate_with_me')}
                        value={!!selectedItems.find(si => si[idFieldName] === user.id_reseller)}
                        options={{
                            inputProps: {
                                onChange: includeMe
                            }
                        }}
                    />
                </div>
            )}

            <Form
                formMethods={formMethods}
                formRules={formRules}
                onSubmit={handleSubmit}
                options={{
                    triggerOnChange: [
                        {target: 'data_from', trigger: 'data_to'},
                        {target: 'data_to', trigger: 'data_from'},
                    ]
                }}
            >
                <div className="selectedReportsUsersActions__body">
                    <FormRow>
                        <FormGroup>
                            <DatePickerController
                                name="date_from"
                                label={t('reports_users.filters.date_from')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <DatePickerController
                                name="date_to"
                                label={t('reports_users.filters.date_to')}
                            />
                        </FormGroup>
                    </FormRow>
                </div>
                <div className="selectedReportsUsersActions__footer">
                    <Button
                        btnType="submit"
                        label={(role === 'pos_user' || role === 'nca_user') ? t('reports_users.generate_normal_user') : `${t('reports_users.generate')} (${selectedItems.length})`}
                        disabled={!(selectedItems.length > 0) || generateReportMutation.isLoading}
                        isLoading={generateReportMutation.isLoading}
                    />
                </div>
            </Form>

            <div className="grid">
                <div/>
                <InfoComponent type={alert.type} message={t(alert.message)}/>
            </div>

            <ReportGenerateStatusModal
                onClose={() => setModalVisible(false)}
                isGenerated={generateStatus.isGenerated}
                reportUrl={generateStatus.reportUrl}
                visible={modalVisible}
                alert={alert}
            />

        </div>
    )
}