import {Routes} from "../../../utils/_index";
import {useCart, useNavigation} from "../../../misc/Hooks";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import useStepsNavigation from "./useStepsNavigation";
import {isEnabledByMarket} from "../../../utils/MarketConfig";


/**
 * Validates Shop module route / step
 * @param route
 * @type route: string
 * @returns {{lastValidRoute: (*), value: boolean}}
 */
export default function useStepValidation(route) {
    const navigation = useNavigation()
    const location = useLocation()
    const stepsNavigation = useStepsNavigation()
    const store = useSelector(({main, subscription}) => ({
        country: main.country,
        user: main.user,
        customer: subscription.customer,
        order: subscription.order,
        machine: subscription.machine,
        nespressoMember: subscription.nespressoMember,
        cart: subscription.cart,
        plan: subscription.plan,
        qrCodeUrl: subscription.qrCodeUrl,
        shopData: subscription.shopData,
        promotion: subscription.promotion,
        isNewMember: subscription.isNewMember,
    }))
    const orderUuid = new URLSearchParams(location?.search).get('uuid')
    const keyAccountId = new URLSearchParams(store.qrCodeUrl).get('keyAccount')
    const {isCartValid} = useCart('subscription')

    const response = {
        canShowStep: true,
        lastValidStep: Routes.SUBSCRIPTION_MACHINE,
    }

    switch (route) {
        case Routes.SUBSCRIPTION_SUMMARY:
            if (orderUuid) {
                response.canShowStep = true
                response.lastValidStep = Routes.SUBSCRIPTION_SUMMARY
                break
            }

            if (!store.customer) {
                response.canShowStep = false
                response.lastValidStep = stepsNavigation.prev()
            }
        case Routes.SUBSCRIPTION_CUSTOMER:
            if (!isCartValid) {
                response.canShowStep = false
                response.lastValidStep = stepsNavigation.prev()
            }
        case Routes.SUBSCRIPTION_PLAN:
            if (!store.machine
                || !(isEnabledByMarket('disableEcapi') || (store.isNewMember === false ? store.nespressoMember : typeof store.isNewMember === 'boolean'))
                || (store.qrCodeUrl && keyAccountId ? !store.shopData : false)) {
                response.canShowStep = false
                response.lastValidStep = stepsNavigation.prev()
            }
        case Routes.SUBSCRIPTION_MACHINE:
    }

    return {
        value: response.canShowStep,
        lastValidRoute: navigation(response.lastValidStep),
    };
}
