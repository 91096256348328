import './style.scss'
import {useEffect, useState} from "react";
import {useTranslation} from "../../../../../hooks";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import sanitizeText from "../../../../../helpers/sanitizeText";
import {useStockQuantity} from "../../hooks";
import Button from "../../../../../components/buttons/Button";

export default function LocalStockQuantity({
    initValue = 0,
    step = 1,
    idProduct,
    idPos,
    query
}) {
    const {t} = useTranslation()
    const {
        quantity,
        error,
        isEditable,
        setIsEditable,
        onClickDecrease,
        onClickIncrease,
        onChangeValue,
        onClickReject,
        updateMutation,
        lastQuantity
    } = useStockQuantity({
        initValue: Number(initValue),
        step: Number(step),
        idProduct: idProduct,
        idPos: idPos
    })


    return (
        <div className="localStockQuantity">

            <div className="localStockQuantity__inputWrapper">

                {!!isEditable && (
                    <button className="localStockQuantity__editButton" onClick={onClickDecrease}>
                        <AiOutlineMinus/>
                    </button>
                )}

                <input
                    className={`localStockQuantity__input ${!!error ? '-error' : ''}`}
                    type="number"
                    value={quantity.toString()}
                    onChange={e => onChangeValue(e.target.value)}
                    onFocus={() => setIsEditable(true)}
                />

                {!!isEditable && (
                    <button className="localStockQuantity__editButton" onClick={onClickIncrease}>
                        <AiOutlinePlus/>
                    </button>
                )}

            </div>
            {quantity !== Number(lastQuantity) && (
                <span
                    className="localStockQuantity__oldValue"
                    {...sanitizeText(t('stock_management.previous_value', lastQuantity))}
                />
            )}
            {!!error && (
                <span className="localStockQuantity__error">{error}</span>
            )}

            {!!isEditable && (
                <div className="localStockQuantity__buttons">
                    <Button
                        label={updateMutation.isLoading ? t('stock_management.wait') : t('save')}
                        size="sm"
                        disabled={Number(lastQuantity) === quantity || !!error || updateMutation.isLoading}
                        onClick={updateMutation.mutate}
                        isLoading={updateMutation.isLoading}
                    />
                    <Button
                        label={t('cancel')}
                        size="sm"
                        type="secondary"
                        onClick={onClickReject}
                    />
                </div>
            )}

        </div>
    )
}