import {useIdleTimer} from "react-idle-timer";
import {reducers} from "../misc/Hooks";
import {useEffect, useMemo, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useBeforeunload} from "react-beforeunload";
import {useOnExitPage, useTranslation} from "./index";
import * as resellerReducer from "../redux/resellerReducer";
import {useHistory, useLocation} from "react-router-dom";

export default function useSession({
    moduleName,
    sessionTime,
    homeRoute,
}) {
    sessionTime = sessionTime ?? 6000000

    const messageTimeoutRef = useRef(null)
    const {t} = useTranslation()
    const onExitPage = useOnExitPage(moduleName)
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const store = useSelector(state => ({
        lastVisit: state[moduleName]?.lastVisit,
    }))
    const reducer = useMemo(() => reducers[moduleName], [moduleName])
    const {getLastActiveTime} = useIdleTimer({
        timeout: sessionTime,
        onIdle,
        onAction,
        debounce: 500,
    })

    useEffect(() => {
        if (store.lastVisit) {
            if ((store.lastVisit + sessionTime) <= Date.now()) {
                onIdle()
            }
        }
    }, [])

    useBeforeunload(e => {
        dispatch(resellerReducer.setLastVisit(getLastActiveTime().getTime()))
    })

    function onIdle(e) {
        onExitPage.sendLog(e, 'SESSION_EXPIRED')
        dispatch(reducer.clearStore())
        clearTimeout(messageTimeoutRef.current)

        if (homeRoute) {
            history.replace({
                pathname: homeRoute,
                state: {
                    message: t('session_expired.text')
                }
            })
        }
    }

    function onAction() {
        if (!location.state?.message) return
        messageTimeoutRef.current = setTimeout(() => {
            history.replace({
                pathname: location.pathname,
                state: {
                    ...location.state,
                    message: null,
                }
            })
        }, 5000)
    }

    return {
        onExitPage,
        message: location.state?.message,
    }
}
