import React, {useRef, useState} from 'react';

export default (props) => {
    const inputRef = useRef(null);
    const [isFocused, setFocused] = useState(false);


    const getValueLengthCounter = () => {
        if(props.maxLength > 0) {
            return `${props.value?.length ?? 0} / ${props.maxLength}`
        }
        return ''
    }


    const handleOnBlur = (e) => {
        setFocused(false);

        if(props.onBlur) {
            props.onBlur(e);
        }
    }


    let formGroupClassNames = '';
    if(props.value?.toString() || props.error) {
        formGroupClassNames += ' -active';
    }
    if(isFocused && !props.readonly) {
        formGroupClassNames += ' -focused';
    }
    if(props.error) {
        formGroupClassNames += ' -invalid';
    }
    if(!props.error && props.value && !props.raw) {
        formGroupClassNames += ' -valid';
    }
    if(props.readonly) {
        formGroupClassNames += ' -disabled';
    }

    formGroupClassNames += ` ${props.formGroupClassNames}`

    return (
        <div className={`formGroup ${formGroupClassNames}`}>
            <label className="formGroup__field">
                {!!props.label && (
                    <span className="formGroup__label">{props.label} {getValueLengthCounter()}</span>
                )}
                <input
                    ref={props.inputRef ?? inputRef}
                    {...props.inputProps}
                    type={props.type ?? 'text'}
                    name={props.name ?? ''}
                    value={props.value}
                    onChange={props.onChange}
                    onFocus={() => setFocused(true)}
                    onBlur={handleOnBlur}
                    readOnly={props.readonly}
                />

                {!!props.loading && (
                    <span className="formGroup__loading">
                        <img src={require('../../../assets/img/icons/preloader2.gif').default} alt=""/>
                    </span>
                )}
            </label>

            {!!props.error && (
                <span className="formGroup__invalidFeedback">{props.error.message}</span>
            )}
        </div>
    );
}
