import {useSelector} from "react-redux";
import * as dateFns from 'date-fns'

export default function useDateFormatter() {
    const store = useSelector(({main}) => ({
        country: main.country,
    }))

    const getDate = (date) => {
        if(!date) return null
        if(typeof date === 'string')
            date = new Date(date)

        return dateFns.format(date, store.country.country_custom_format?.date_format)
    }

    return {
        getDate,
    }
}
