import Form from "./Form";
import {useForm} from "react-hook-form";
import {useFormRulesBuilder} from "./hooks";
import {FormGroup} from "./components";
import {EmailInput, LangSelect} from "./presets";
import {Button} from "../components/buttons/_index";
import {useCountry, useModuleContext, useTranslation} from "../hooks";
import {useContext, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {webAccountExists} from "../actions/MainActions";
import BaseModal from "../components/modals/BaseModal";
import WebAccountNotExistConfirmationModal from "../components/modals/WebAccountNotExistConfirmationModal";
import {setWebAccountExists} from "../redux/machineRegistrationReducer";

export default function AssistedAuthForm({onSubmit}) {
    const {t} = useTranslation();
    const {store, reducer, dispatch} = useModuleContext()
    const {data: country} = useCountry()
    const webAccountExistMutation = useMutation({
        mutationFn: payload => webAccountExists(payload),
    })
    const [confirmationData, setConfirmationData] = useState(null)
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false)

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            email: store.customer?.details?.email ?? '',
            lang: store.customer?.details?.lang ?? undefined,
        }
    })
    const formRules = useFormRulesBuilder({
    }, formMethods)

    const handleSubmit = async (data) => {
        if(country.country_check_WA_existence_before_promotion) {
            webAccountExistMutation.mutateAsync({email: data.email})
                .then(() => {
                    onSubmit(data)
                    dispatch(reducer.setWebAccountExists(true))
                })
                .catch(() => {
                    setConfirmationData(data)
                    formMethods.setError('email', {
                        type: 'invalid',
                        message: t(`validation.web_account_exists`)
                    })
                    // console.log(err, 'error!')
                    setConfirmationModalVisible(true)
                    dispatch(reducer.setWebAccountExists(false))
                })
        } else {
            dispatch(reducer.setWebAccountExists(true))
            onSubmit(data)
        }
    }

    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            state={{
                isLoading: webAccountExistMutation.isLoading,
                isDisabled: webAccountExistMutation.isLoading,
            }}
            onSubmit={handleSubmit}
        >
            <FormGroup>
                <EmailInput/>
            </FormGroup>
            <FormGroup>
                <LangSelect/>
            </FormGroup>

            <Button
                btnType="submit"
                isLoading={webAccountExistMutation.isLoading}
                disabled={webAccountExistMutation.isLoading}
                label={t('continue')}
            />

            <WebAccountNotExistConfirmationModal
                isVisible={confirmationModalVisible}
                onClose={() => setConfirmationModalVisible(false)}
                onSubmit={() => onSubmit(confirmationData)}
            />
        </Form>
    )
}
