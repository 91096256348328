
const SET_UUID = 'eTradeReopen/setUuid';
const SET_CALLBACK_PARAM = 'eTradeReopen/setCallbackParam';
const SET_TOKEN = 'eTradeReopen/setToken';
const SET_DATA = 'eTradeReopen/setData';
const SET_REOPEN_DATA = 'eTradeReopen/setReopenData';
const SET_IS_NEW_MEMBER = 'eTradeReopen/setIsNewMember';
const SET_NESPRESSO_MEMBER = 'eTradeReopen/setNespressoMember';
const SET_NESPRESSO_MEMBER_EXIST = 'eTradeReopen/setNespressoMemberExist';
const SET_CUSTOMER = 'eTradeReopen/setCustomer';
const SET_PRODUCTS = 'eTradeReopen/setProducts';
const SET_EXTRA_LINE_PRODUCTS = 'eTradeReopen/setExtraLineProducts';
const SET_EXTRA_LINE_CATEGORIES = 'eTradeReopen/setExtraLineCategories';
const SET_CATEGORIES = 'eTradeReopen/setCategories';
const UPDATE_CART = 'eTradeReopen/updateCart';
const SET_PLAN = 'eTradeReopen/setPlan';
const SET_CAN_REGISTER_RESPONSE = 'eTradeReopen/setCanRegisterResponse';
const SET_ORDER = 'eTradeReopen/setOrder';
const SET_ORDER_FINALIZED_DATA = 'eTradeReopen/setOrderFinalizedData';
const SET_PAYMENT_METHOD = 'eTradeReopen/setPaymentMethod'
const SET_DELIVERY_METHOD = 'eTradeReopen/setDeliveryMethod';
const SET_ADDRESSES = 'eTradeReopen/setAddresses';
const SET_COOKIES_ACCEPTED = 'eTradeReopen/setCookiesAccepted';
const SET_ORDER_ACCOUNT = 'eTradeReopen/setOrderAccount';
const SET_LAST_VISIT = 'eTradeReopen/setLastVisit';
const SET_OPEN_PLAN_MODALS = 'eTradeReopen/setOpenPlanModals';
const SET_CART_VISIBLE = 'eTradeReopen/setCartVisible';

const CLEAR_STORE = 'eTradeReopen/clearStore';

const initialState = {
    uuid: '',
    callbackParam: null,
    token: null,
    data: null,
    reopenData: null,
    isNewMember: false,
    nespressoMember: undefined,
    nespressoMemberExist: false,
    customer: null,
    categories: [],
    products: [],
    extraLineCategories: [],
    extraLineProducts: [],
    cart: {
        items: [],
        sum: '',
    },
    canRegisterResponse: null,
    order: null,
    orderFinalizedData: null,
    paymentMethod: null,
    deliveryMethod: null,
    addresses: [],
    cookiesAccepted: undefined,
    orderAccount: null,
    orderAccountLinked: false,
    ecapiAccountCreated: false,
    lastVisit: null,
    openPlanModals: [],
    cartVisible: false,
}


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_UUID:
            return {...state, uuid: action.uuid}
        case SET_CALLBACK_PARAM:
            return {...state, callbackParam: action.callbackParam}
        case SET_DATA:
            return {...state, data: action.data}
        case SET_REOPEN_DATA:
            return {...state, reopenData: action.reopenData}
        case SET_TOKEN:
            return {...state, token: action.token}
        case SET_IS_NEW_MEMBER:
            return {...state, isNewMember: action.isNewMember}
        case SET_NESPRESSO_MEMBER:
            return {...state, nespressoMember: action.nespressoMember}
        case SET_NESPRESSO_MEMBER_EXIST:
            return {...state, nespressoMemberExist: action.nespressoMemberExist}
        case SET_CUSTOMER:
            return {...state, customer: action.customer}
        case SET_PRODUCTS:
            return {...state, products: action.products}
        case SET_CATEGORIES:
            return {...state, categories: action.categories}
        case SET_EXTRA_LINE_CATEGORIES:
            return {...state, extraLineCategories: action.extraLineCategories}
        case SET_EXTRA_LINE_PRODUCTS:
            return {...state, extraLineProducts: action.extraLineProducts}
        case UPDATE_CART:
            return {...state, cart: action.cart}
        case SET_PLAN:
            return {
                ...state,
                token: action.token,
                data: action.data,
                products: action.products,
                categories: action.categories,
                cart: action.cart,
            }
        case SET_CAN_REGISTER_RESPONSE:
            return {...state, canRegisterResponse: action.canRegisterResponse}
        case SET_ORDER:
            return {...state, order: action.order}
        case SET_ORDER_FINALIZED_DATA:
            return {...state, orderFinalizedData: action.orderFinalizedData}
        case SET_PAYMENT_METHOD:
            return {...state, paymentMethod: action.paymentMethod}
        case SET_DELIVERY_METHOD: return {...state, deliveryMethod: action.deliveryMethod}
        case SET_ADDRESSES:
            return {...state, addresses: action.addresses}
        case SET_COOKIES_ACCEPTED:
            return {...state, cookiesAccepted: action.cookiesAccepted}
        case SET_ORDER_ACCOUNT:
            return {
                ...state,
                orderAccount: action.orderAccount,
                orderAccountLinked: action.orderAccountLinked,
                ecapiAccountCreated: action.ecapiAccountCreated,
            }
        case SET_LAST_VISIT:
            return {...state, lastVisit: action.lastVisit}
        case SET_OPEN_PLAN_MODALS:
            return {...state, openPlanModals: action.openPlanModals}
        case SET_CART_VISIBLE: return {...state, cartVisible: action.cartVisible}
        case CLEAR_STORE:
            return {...initialState}

        default: return state;
    }
}


export const setUuid = (uuid) => ({type: SET_UUID, uuid})
export const setCallbackParam = (callbackParam) => ({type: SET_CALLBACK_PARAM, callbackParam})
export const setData = (data) => ({type: SET_DATA, data})
export const setReopenData = (reopenData) => ({type: SET_REOPEN_DATA, reopenData})
export const setToken = (token) => ({type: SET_TOKEN, token})
export const setIsNewMember = (isNewMember) => ({type: SET_IS_NEW_MEMBER, isNewMember})
export const setNespressoMember = (nespressoMember) => ({type: SET_NESPRESSO_MEMBER, nespressoMember})
export const setNespressoMemberExist = (nespressoMemberExist) => ({type: SET_NESPRESSO_MEMBER_EXIST, nespressoMemberExist})
export const setCustomer = (customer) => ({type: SET_CUSTOMER, customer})
export const setProducts = (products) => ({type: SET_PRODUCTS, products})
export const setCategories = (categories) => ({type: SET_CATEGORIES, categories})
export const setExtraLineProducts = (extraLineProducts) => ({type: SET_EXTRA_LINE_PRODUCTS, extraLineProducts})
export const setExtraLineCategories = (extraLineCategories) => ({type: SET_EXTRA_LINE_CATEGORIES, extraLineCategories})
export const updateCart = (cart) => ({type: UPDATE_CART, cart})
export const setPlan = (token, data, products, categories, cart) => ({
    type: SET_PLAN,
    token,
    data,
    products,
    categories,
    cart,
})
export const setCanRegisterResponse = (canRegisterResponse) => ({type: SET_CAN_REGISTER_RESPONSE, canRegisterResponse})
export const setOrder = (order) => ({type: SET_ORDER, order})
export const setOrderFinalizedData = (orderFinalizedData) => ({type: SET_ORDER_FINALIZED_DATA, orderFinalizedData})
export const setPaymentMethod = (paymentMethod) => ({type: SET_PAYMENT_METHOD, paymentMethod})
export const setDeliveryMethod = (deliveryMethod) => ({type: SET_DELIVERY_METHOD, deliveryMethod})
export const setAddresses = (addresses) => ({type: SET_ADDRESSES, addresses})
export const setCookiesAccepted = (cookiesAccepted) => ({type: SET_COOKIES_ACCEPTED, cookiesAccepted})
export const setOrderAccount = (orderAccount, orderAccountLinked, ecapiAccountCreated = false) => ({
    type: SET_ORDER_ACCOUNT,
    orderAccount,
    orderAccountLinked,
    ecapiAccountCreated,
})
export const setLastVisit = (lastVisit) => ({type: SET_LAST_VISIT, lastVisit})
export const setOpenPlanModals = (openPlanModals) => ({type: SET_OPEN_PLAN_MODALS, openPlanModals})
export const setCartVisible = (cartVisible) => ({type: SET_CART_VISIBLE, cartVisible})
export const clearStore = () => ({type: CLEAR_STORE})

export default reducer;
