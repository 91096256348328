import {SearchForm, ListForm} from "./components";
import {useModuleContext, useModuleStepsNavigation, useTranslation} from "../../hooks";
import {InfoComponent} from "../_index";
import React from "react";
import {NespressoMember} from "../../services/NespressoMemberService";
import {useSearchNespressoMembers} from "./hooks";


export default function SearchNespressoMember() {
    const {t} = useTranslation()
    const {store, reducer, dispatch} = useModuleContext()
    const stepsNavigation = useModuleStepsNavigation()
    const searchNespressoMembers = useSearchNespressoMembers()

    const onSubmitSearch = (data) => {
        dispatch(reducer.setSearchedMembers([]))
        dispatch(reducer.setSearchedMember(null))
        dispatch(reducer.setNespressoMember(null))
        searchNespressoMembers.mutation.mutate({
            firstName: data.firstname,
            lastName: data.lastname,
            memberNumber: data.memberNumber ? Number(data.memberNumber) : undefined,
            postCode: data.postcode,
            email: data.email,
            phoneNumber: data.phone,
        })
    }

    const onSubmitMemberSelect = (data) => {
        const nespressoMember = new NespressoMember({
            memberNumber: data.member.memberNumber,
            email: data.member.email,
        })
        dispatch(reducer.setSearchedMember(data.member))
        dispatch(reducer.setNespressoMember(nespressoMember))

        stepsNavigation.navToNext()
    }

    return (
        <div>
            <div className="grid --mb2">
                <div>
                    <SearchForm
                        state={{
                            isLoading: searchNespressoMembers.mutation.isLoading,
                        }}
                        {...searchNespressoMembers}
                        onSubmit={onSubmitSearch}
                    />

                    {searchNespressoMembers.mutation.isError && (
                        <InfoComponent
                            type="error"
                            message={t(`error.${searchNespressoMembers.mutation.error?.error}`) || t('error.unknown_error')}
                        />
                    )}
                </div>
                <div/>
            </div>

            {!searchNespressoMembers.mutation.isLoading && !!(searchNespressoMembers.mutation.data?.length || store.searchedMembers?.length) && (
                <div className="--mb4">
                    <ListForm
                        data={searchNespressoMembers.mutation.data ? searchNespressoMembers.mutation.data : store.searchedMembers}
                        defaultValues={{
                            member: store.searchedMember
                        }}
                        onSubmit={onSubmitMemberSelect}
                    />
                </div>
            )}
        </div>
    )
}``
