import {useCartValidation, useModuleContext, useTranslation} from "../../../hooks";
import {useSlider} from "../hooks";
import {getImage} from "../../../misc/Helpers";
import {Config} from "../../../utils/_index";
import {AiOutlineLeft, AiOutlineRight} from "react-icons/ai";
import BottomSliderItem from "./BottomSliderItem";
import {TopSliderItem} from "./index";
import {useEffect, useState} from "react";
import {useInView} from "react-intersection-observer";
import {useCountryConfig} from "../../../misc/Hooks";
import useResizeObserver from "@react-hook/resize-observer";

export default function CategoryBar(props) {
    const {t} = useTranslation();
    const {storeName} = useModuleContext()
    const {selectable_products_enable: selectableProductsEnable} = useCountryConfig()
    const inView = useInView()
    const bottomSlider = useSlider({
        itemsLength: props.categoryBar.categories.length,
    })
    const topSlider = useSlider({
        maxVisibleItems: 3,
        stylePropertyName: '--minWidthSliderItem',
        itemsLength: props.categoryBar?.coffeeLines.length > 1 ?
            props.categoryBar?.coffeeLines.length : props.categoryBar.categories.length,
        rwdElements: [2, 1, 1]
    })
    const fixedSlider = useSlider({
        maxVisibleItems: 3,
        stylePropertyName: '--minWidthSliderItem',
        itemsLength: props.categoryBar.categories.length,
        rwdElements: [2, 1, 1]
    })

    const {status} = useCartValidation(storeName)

    const [fixedSliderVisible, setFixedSliderVisible] = useState(false)

    useEffect(() => {
        const el = document.querySelector(".evCS__mobileFooter")
        if (el) {
            const observer = new IntersectionObserver(
                ([e]) => e.target.classList.toggle("-isPinned", e.intersectionRatio < 1),
                {threshold: [1]}
            );
            observer.observe(el);
        }


    }, [])


    useEffect(() => {
        if (!inView.inView) {
            if (inView.entry?.boundingClientRect?.top <= 0) {
                setFixedSliderVisible(true)
            } else {
                setFixedSliderVisible(false)
            }
        } else {
            setFixedSliderVisible(false)
        }
    }, [inView.inView])

    const onChangeCoffeeLine = (line) => {
        if (props.filter?.line === line)
            line = null
        props.onSubmitFilter({
            ...props.filter,
            line,
        })
    }

    const onClickCategory = (idx, idCategory) => {
        !selectableProductsEnable || props.isMobileView
            ? props.categoryBar.scrollTo(idx)
            : props.setCurrentSelectedCategory(idCategory)
    }

    const checkCategoryIsActive = (idCategory) => {
        return !selectableProductsEnable || props.isMobileView ? null : props.currentSelectedCategory === idCategory
    }

    if (props.products.promotion?.promotion_type === Config.PROMO_TYPE.EASY_ORDER && !props.easyOrderSetup)
        return null;

    return (
        <div ref={inView.ref}>
            <div {...topSlider.swipe}>
                <div className="productsTopBar" ref={topSlider.wrapperRef}>
                    <div className="productsTopBar__filters">
                        <button className="filtersButton"
                                onClick={props.openModalFilter}
                        >
                            <span>{t('filter.title')}</span>
                            <img src={require('../../../assets/img/icons/filters.svg').default} alt=""/>
                        </button>
                    </div>
                    <div className="productsTopBar__body" ref={topSlider.sliderRef}>
                        {props.categoryBar?.coffeeLines.length > 1 ? (
                            <>
                                {props.categoryBar?.coffeeLines?.map(cf => (
                                    <TopSliderItem
                                        key={cf.stack_value}
                                        title={cf.stack_title}
                                        image={getImage(cf.stack_gallery)}
                                        onClick={() => onChangeCoffeeLine(cf.stack_value)}
                                        active={props.filter?.line === cf.stack_value}
                                    />
                                ))}
                            </>
                        ) : (
                            <>
                                {props.categoryBar?.categories?.map((cat, idx) => (
                                    <TopSliderItem
                                        key={idx}
                                        title={cat.category_title}
                                        image={getImage(cat.category_gallery)}
                                        onClick={() => onClickCategory(idx, cat.id_category)}
                                        active={checkCategoryIsActive(cat.id_category)}
                                        categories
                                        cat={cat}
                                        requiredCategory={!!status.invalidCategories.find(ic => ic.category.id_category === cat.id_category)}
                                        // validateParentCategory={!!status.invalidCategories.find(ic => ic.category.category.subcategories?.find(sc => sc.id_category === cat.id_category))}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                    <div className={`productsTopBar__right -border`}>
                        {topSlider.arrowVisible && (
                            <>
                                <button
                                    className="productsCategorySlider__arrow"
                                    onClick={topSlider.sliderPrev}
                                    disabled={!topSlider.offset}
                                >
                                    <AiOutlineLeft/>
                                </button>
                                <button
                                    className="productsCategorySlider__arrow"
                                    onClick={topSlider.sliderNext}
                                    disabled={topSlider.lockNext}
                                >
                                    <AiOutlineRight/>
                                </button>
                            </>
                        )}
                    </div>

                </div>
            </div>
            {(props.categoryBar?.categories?.length > 0 && props.categoryBar.coffeeLines.length > 1) && (
                <div {...bottomSlider.swipe}>
                    <div className="productsCategorySlider" ref={bottomSlider.wrapperRef}>
                        <div className="productsCategorySlider__leftArrow">
                            {bottomSlider.arrowVisible && (
                                <button
                                    className="productsCategorySlider__arrow"
                                    onClick={bottomSlider.sliderPrev}
                                    disabled={!bottomSlider.offset}
                                >
                                    <AiOutlineLeft/>
                                </button>
                            )}
                        </div>
                        <div className="productsCategorySlider__body" ref={bottomSlider.sliderRef}>
                            {props.categoryBar?.categories?.map((cat, idx) => (
                                <BottomSliderItem
                                    key={idx}
                                    onClick={() => onClickCategory(idx, cat.id_category)}
                                    background={getImage(cat.category_gallery, Config.GALLERY_TYPE.BACKGROUND)}
                                    image={getImage(cat.category_gallery)}
                                    title={cat.category_title}
                                    active={checkCategoryIsActive(cat.id_category)}
                                    requiredCategory={!!status.invalidCategories.find(ic => ic.category.id_category === cat.id_category)}
                                />
                            ))}
                        </div>
                        <div className="productsCategorySlider__rightArrow">
                            {bottomSlider.arrowVisible && (
                                <button
                                    className="productsCategorySlider__arrow"
                                    onClick={bottomSlider.sliderNext}
                                    disabled={bottomSlider.lockNext}
                                >
                                    <AiOutlineRight/>
                                </button>
                            )}
                        </div>
                    </div>

                </div>
            )}

            <div className={`categoryBarFixed ${fixedSliderVisible ? '-active' : ''}`}>
                <div {...fixedSlider.swipe}>
                    <div className="productsTopBar" ref={fixedSlider.wrapperRef}>
                        <div className="productsTopBar__filters">
                            <button className="filtersButton"
                                    onClick={props.openModalFilter}
                            >
                                <span>{t('filter.title')}</span>
                                <img src={require('../../../assets/img/icons/filters.svg').default} alt=""/>
                            </button>
                        </div>
                        <div className="productsTopBar__body" ref={fixedSlider.sliderRef}>
                            {props.categoryBar?.categories?.map((cat, idx) => (
                                <TopSliderItem
                                    key={idx}
                                    title={cat.category_title}
                                    image={getImage(cat.category_gallery)}
                                    onClick={() => onClickCategory(idx, cat.id_category)}
                                    active={checkCategoryIsActive(cat.id_category)}
                                    categories
                                    requiredCategory={!!status.invalidCategories.find(ic => ic.category.id_category === cat.id_category)}
                                />
                            ))}
                        </div>
                        <div className={`productsTopBar__right -border`}>
                            {fixedSlider.arrowVisible && (
                                <>
                                    <button className="productsCategorySlider__arrow"
                                            onClick={fixedSlider.sliderPrev}
                                            disabled={!fixedSlider.offset}
                                    >
                                        <AiOutlineLeft/>
                                    </button>
                                    <button
                                        className="productsCategorySlider__arrow"
                                        onClick={fixedSlider.sliderNext}
                                        disabled={fixedSlider.lockNext}
                                    >
                                        <AiOutlineRight/>
                                    </button>
                                </>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
