
/**
 * Api Exception
 * @param {string} error Error message
 * @param {any} data Error data
 * @param {string|null} errorCode? Error code
 * @constructor
 */
export default function ApiException(error, data, errorCode = null) {
  this.error = error
  this.errorCode = errorCode
  this.data = data
}
