import {useMutation} from "@tanstack/react-query";
import {getPromoCode} from "../actions/MainActions";
import {ApiException} from "../errors";
import {useModuleContext, useTranslation} from "./index";
import {useSelector} from "react-redux";
import {useState} from "react";

export default function usePromoCode() {
    const {t} = useTranslation()
    const context = useModuleContext()
    const store = useSelector(state => ({
        machine: state[context.storeName]?.machine,
        promoCode: state[context.storeName]?.promoCode,
    }))
    const [data, setData] = useState(store.promoCode)
    const [error, setError] = useState(null)
    const mutation = useMutation(
        async (payload) => {
            const {data} = await getPromoCode(payload, store.machine?.id_machine ?? 0)

            if(data.status === 'success')
                return data
            throw new ApiException(data.messages[0], data)
        },
        {
            onMutate: () => {
                setError(null)
                // setData(null)
            },
            onSuccess: ({data}) => {
                setData(data)
            },
            onError: ({error}) => {
                setError(t(`validation.${error}`) || t('validation.invalid'))
            },
        }
    )

    return {
        mutation,
        error,
        data,
    }
}
