import React, {useMemo} from 'react';
import BaseModal from "./BaseModal";
import {useNavigationWithoutLang, useTranslation} from "../../misc/Hooks";
import Routes from "../../utils/Routes";
import QRCode from "react-qr-code";
import {Button} from "../buttons/_index";
import {useResellerActions} from "../../actions/useResellerActions";
import {useMutation} from "@tanstack/react-query";

export default function ({visible, onClose, text, uuid, lang, route, ...props}) {
    const t = useTranslation();
    const navigation = useNavigationWithoutLang()
    const resellerActions = useResellerActions();

    const url = useMemo(() => {
        return `${window.location.origin}/${!!lang ? lang : process.env.REACT_APP_DEFAULT_LANG}` + navigation(route ?? Routes.ORDER_FINISH, uuid)
    }, [uuid, lang])

    const statusQrCodeScanned = useMutation(data => resellerActions.putResellerQrScannedChoice(data))

    const onClickScannedButton = (type) => {
        statusQrCodeScanned.mutate({
            uuid,
            was_qr_code_scanned: type
        })
        onClose()
    }

    return (
        <BaseModal
            visible={visible}
            onClose={onClose}
        >
            <div className="modal__body --mb0">
                {!!uuid && (
                    <>
                        <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1rem'}}>
                            <QRCode
                                value={url}
                                size={450}
                                style={{height: "auto", maxWidth: "450px", width: "100%"}}
                                viewBox={`0 0 450 450`}
                            />
                        </div>
                        <div className="--alignCenter --mb3">
                            <a className="link primary" href={url}>{url}</a>
                        </div>
                    </>
                )}
                <h3 className="modal__title --alignCenter --mb4">{text ?? t('modal.link_sent.text')}</h3>
                {!!uuid && (
                    <div className="modal__buttons">
                        <Button label={t('modal.link_sent.scanned_the_code')} onClick={() => onClickScannedButton(1)}/>
                        <Button label={t('modal.link_sent.not_scanned_the_code')} type="grey" onClick={() => onClickScannedButton(0)}/>
                    </div>
                )}
            </div>
        </BaseModal>
    );
}
