import {useDispatch, useSelector} from "react-redux";
import {useMemo} from "react";
import {reducers} from "../misc/Hooks";


export default function (storeName) {
    const reducer = useMemo(() => reducers[storeName], [storeName])
    const dispatch = useDispatch()
    const store = useSelector(state => ({
        optIn: state[storeName]?.optIn,
    }))

    const update = (optIn) => {
        if(JSON.stringify(optIn) !== JSON.stringify(store.optIn)) {
            if(typeof reducer.setOptIn === 'function')
                dispatch(reducer.setOptIn(optIn))
        }
    }

    return {
        data: store.optIn,
        update,
    }
}
