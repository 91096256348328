import {DropzoneController, TextInputController} from "../controllers";
import React from "react";
import {useTranslation} from "../../hooks";

export default function FileDropzone(props) {
    const {t} = useTranslation()

    return (
        <DropzoneController
            name="file"
            label={t('upload_box')}
            {...props}
            options={{
                ...props.options,
                inputProps: {
                    accept: 'image/png,image/jpeg,image/bmp,application/pdf',
                    ...props.options?.inputProps,
                }
            }}
        />
    )
}
