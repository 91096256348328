import {useParams} from "react-router-dom";
import {useTableBuilder, useTableFilters} from "../../../../components/baseTable";
import {useReseller, useTranslation} from "../../../../hooks";
import {useResellerActions} from "../../../../actions/useResellerActions";
import {ApiException} from "../../../../errors";
import React, {Fragment, useEffect, useState} from "react";
import {RenewalCertificate, ResetPasswordButton, UserStatusSwitch, VerifyButtons} from "../components";
import Button from "../../../../components/buttons/Button";
import {useNavigation} from "../../../../misc/Hooks";
import {Routes} from "../../../../utils/_index";
import {SelectController} from "../../../../forms/controllers";
import {SelectOption} from "../../../../services";

export default function useResellerUsers() {
    const {id} = useParams();
    const {user} = useReseller()
    const {t} = useTranslation()
    const navigation = useNavigation()
    const resellerActions = useResellerActions();
    const [shopsModalVisible, setShopsModalVisible] = useState(false);
    const [modalShops, setModalShops] = useState(null)
    const [currentReseller, setCurrentReseller] = useState(null)
    const [lackOfAccess, setLackOfAccess] = useState(false)

    const resellerChildFilters = useTableFilters({
        builder: {
            formConfig: [['slug', 'status', 'certificate']],
            inputComponents: {
                status: (
                    <SelectController
                        name="status"
                        label={t('table.status.status')}
                        selectOptions={[
                            new SelectOption(t('table.status.all'), undefined),
                            new SelectOption(t('table.status.inactive'), '0'),
                            new SelectOption(t('table.status.active'), '1'),
                        ]}
                        defaultValue={new SelectOption(t('table.status.all'), undefined)}
                        options={{
                            inputProps: {
                                isDisabledValidation: true
                            }
                        }}
                    />
                ),
                certificate: (
                    <SelectController
                        name="certificate"
                        label={t('table.certificate.certificate')}
                        selectOptions={[
                            new SelectOption(t('table.certificate.all'), undefined),
                            new SelectOption(t('table.certificate.expired'), '0'),
                            new SelectOption(t('table.certificate.active'), '1'),
                        ]}
                        defaultValue={new SelectOption(t('table.certificate.all'), undefined)}
                        options={{
                            inputProps: {
                                isDisabledValidation: true
                            }
                        }}
                    />
                )
            }
        },
        searchPrefix: "usersFilter",
        mapFunc: (data) => ({
            status: data.usersFilter.status?.value ?? null,
            certificate: data.usersFilter.certificate?.value ?? null,
            slug: data.usersFilter.slug ?? null
        }),
        delayFields: ['slug']
    })


    const resellerChildTable = useTableBuilder({
        name: `reseller_child_table_${id ?? user.id_reseller}`,
        action: async (params) => {
            const {data} = await resellerActions.getResellerChildrenV2({id_reseller: id ?? user.id_reseller, ...params})
            if (data.status === 'success') {
                const {childs, ...res} = data.data
                setCurrentReseller(res)
                return {data: {data: childs}}
            }

            throw new ApiException(data.messages[0], data)
        },
        params: {
            slug: resellerChildFilters.rawData.slug,
            status: resellerChildFilters.rawData?.status,
            certificate_expired_at: resellerChildFilters.rawData?.certificate
        },
        columns: [
            {
                name: 'name',
                label: 'reseller_client.full_name',
                cell: ({row: {original}}) => `${original.reseller_firstname} ${original.reseller_lastname}`
            },
            {
                name: 'reseller_number',
                label: 'reseller_client.reseller_number',
            },
            {
                name: 'reseller_shops',
                label: 'shop',
                cell: ({value}) => (
                    <Fragment>
                        {value.slice(0, 3).map(shop => (
                            <span key={shop.id_shop} style={{display: 'block', lineHeight: 1}}>{shop.shop_title}</span>
                        ))}
                        {value?.length > 3 && (
                            <button
                                className={"link -primary"}
                                style={{fontSize: '.65rem'}}
                                onClick={() => {
                                    setShopsModalVisible(true)
                                    setModalShops(value)
                                }}
                            >
                                {t('more')} ({value.length - 3})
                            </button>
                        )}
                    </Fragment>
                )
            },
            {
                name: 'reseller_role',
                label: 'role',
                cell: ({value}) => <span>{t(`reseller_client.reseller_role.${value}`)}</span>
            },
            {
                name: 'reseller_email',
                label: 'email',
            },
            {
                name: 'certificate_expiration_date',
                label: 'reseller_client.certificate_expiration_date',
                cell: ({row: {original}}, query) => {
                    const date = original.certificate_expired_at
                        ? new Date(original.certificate_expired_at).toLocaleString().split(',')[0]
                        : null

                    return <RenewalCertificate
                        isCertificateExpired={original.is_certificate_expired}
                        canRenewal={original.can_recertificate}
                        date={date}
                        idReseller={original.id_reseller}
                        query={query}
                    />
                }
            },
            {
                name: 'password_reset',
                label: 'reseller_client.password_reset',
                cell: ({row: {original}}) => <ResetPasswordButton
                    resellerId={original.id_reseller}
                    disabled={!original.reseller_password_reset_enabled || !!original.id_sso}
                />
            },
            {
                name: 'subordinates',
                label: 'reseller_client.subordinates',
                cell: ({row: {original}}) => (
                    <Button
                        size="sm"
                        label={original.childs_count ? (t('reseller_client.show') || t('show')) : t('none')}
                        disabled={!original.childs_count}
                        href={navigation(Routes.V2_RESELLER_USERS, original.id_reseller)}
                        style={{width: '100%'}}
                        tooltip={!original.childs_count ? t('reseller_client.reseller_empty_subordinates') : ''}
                    />
                )
            },
            {
                name: 'edit',
                label: 'edit',
                cell: ({row: {original}}) => (
                    <Button
                        size="sm"
                        label={t('reseller_client.edit') || t('edit')}
                        href={navigation(Routes.V2_RESELLER_USERS_USER, original.id_reseller)}
                        style={{width: '100%'}}
                    />
                )
            },
            {
                name: 'status',
                label: 'status',
                cell: ({row: {original}}, query) =>
                    <UserStatusSwitch
                        resellerId={original.id_reseller}
                        defaultStatus={original.reseller_status}
                        isCertificateExpired={original.is_certificate_expired}
                        query={query}
                    />
            },
        ],
        config: {
            selectable: true,
            idFieldName: 'id_reseller',
            confirmingActions: true,
            searchParamPrefix: 'users',
            onError: (err) => {
                if(err === 'no_relation')
                    setLackOfAccess(true)
            },
        }

    })

    const resellerRequestFilters = useTableFilters({
        builder: {
            formConfig: [['slug']],
        },
        searchPrefix: "requestsFilter",
        mapFunc: (data) => ({
            slug: data.requestsFilter.slug ?? null
        }),
        delayFields: ['slug'],
    })

    const resellerRequestTable = useTableBuilder({
        name: `reseller_requests_table_${user.id_reseller}`,
        action: (params) => {
            resellerChildTable.query.refetch()
            return resellerActions.getNewRequestV2(params)
        },
        params: {...resellerRequestFilters.rawData},
        columns: [
            {
                name: 'name',
                label: 'reseller_client.full_name',
                cell: ({row: {original}}) => `${original.reseller_firstname} ${original.reseller_lastname}`
            },
            {
                name: 'reseller_number',
                label: 'reseller_client.reseller_number',
            },
            {
                name: 'reseller_shops',
                label: 'shop',
                cell: ({value}) => (
                    <Fragment>
                        {value.slice(0, 3).map(shop => (
                            <span key={shop.id_shop} style={{display: 'block', lineHeight: 1}}>{shop.shop_title}</span>
                        ))}
                        {value?.length > 3 && (
                            <button
                                className={"link -primary"}
                                style={{fontSize: '.65rem'}}
                                onClick={() => {
                                    setShopsModalVisible(true)
                                    setModalShops(value)
                                }}
                            >
                                {t('more')} ({value.length - 3})
                            </button>
                        )}
                    </Fragment>
                )
            },
            {
                name: 'reseller_email',
                label: 'email',
            },
            {
                name: 'verification',
                label: 'verification',
                cell: ({row: {original}}, query) => <VerifyButtons idReseller={original.id_reseller} query={query}/>
            }
        ],
        config: {
            selectable: true,
            idFieldName: 'id_reseller',
            confirmingActions: true,
            searchParamPrefix: 'requestsUsers',
            enabled: !id,
        }
    })

    useEffect(() => {
        setCurrentReseller(null)
    }, [id])


    return {
        currentReseller,
        paramsId: id,
        resellerChildTable,
        user,
        shopsModalVisible,
        modalShops,
        setShopsModalVisible,
        resellerChildFilters,
        resellerRequestFilters,
        resellerRequestTable,
        lackOfAccess
    }
}