import React, {useMemo} from "react";


export default function FilePreview({file, ...props}) {

    const fileUrl = useMemo(() => {
        if (file instanceof File)
            return URL.createObjectURL(file)
        if (typeof file === 'string')
            return file
        return undefined
    }, [file])

    if(!file)
        return null

    return (typeof file === 'string' && !file?.type) || file?.type?.match(/^image/) ? (
        <img src={fileUrl} alt="" className="img-fluid" style={{borderRadius: '6px'}}/>
    ) : file?.type?.match(/^application\/pdf/) ? (
        <>
            <embed
                src={fileUrl}
                width='100%'
                height="375"
                type="application/pdf"
            />
        </>
    ) : <p>unknown file type</p>
}
