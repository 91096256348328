import {HiddenFile} from "../inputs";
import {useController} from "react-hook-form";
import {useFormHelper, useTranslation} from "../../hooks";
import {useEffect, useMemo} from "react";
import {rules} from "react-google-recaptcha/.eslintrc";
import {Config} from "../../utils/_index";

/**
 * HiddenFileController
 * @param name
 * @param defaultValue
 * @returns {JSX.Element}
 * @constructor
 */
export default function HiddenFileController({
    name: _name,
    relatedInputName,
    defaultValue = '',
    inputRef,
    inputProps,
}) {
    const {t} = useTranslation()
    const formHelper = useFormHelper()
    const name = useMemo(
        () => formHelper.formFragmentContext.getInputName(_name),
        [_name, formHelper.formFragmentContext]
    )
    const {
        field: {ref, value, ...controllerInputProps},
    } = useController({
        name,
        control: formHelper.formMethods.control,
        defaultValue,
    })

    return (
        <HiddenFile
            key={value}
            {...controllerInputProps}
            {...inputProps}
            inputRef={r => {
                ref.current = r
                if (inputRef)
                    inputRef.current = r
            }}
            onChange={(e) => {
                if (e.target.files?.length) {
                    const file = e.target.files[0]
                    if(file.size <= Config.FILE_SIZE_MB * 1024 * 1024) {
                        controllerInputProps.onChange(file)
                    } else if(relatedInputName) {
                        formHelper.setError(
                            formHelper.name(relatedInputName),
                            {type: 'invalid', message: t('error.file_too_big', Config.FILE_SIZE_MB)},
                        )
                    }
                } else {
                    controllerInputProps.onChange(undefined)
                }
            }}
        />
    )
}

