import useCart2 from "../hooks/useCart2";
import {
    useCart,
    useCountryConfig,
    useLocalizeObject,
    useMarketConfig,
    useNavigation,
    usePriceFormatter
} from "../../../misc/Hooks";
import React from "react";
import {Button} from "../../buttons/_index";
import {useTranslation} from "../../../hooks";

export default function CartSummaryFooter({disableFooter, summaryRoute, customData, onContinue}){
    const cart = useCart2(customData)

    const {
        capsulesValue,
        capsulesNetValue,
        giftValue,
        giftNetValue,
        isCartValid,
        giftValueWithoutPromo,
        deliveryValue,
    } = useCart(cart.storeName)

    const localizeObject = useLocalizeObject()
    const marketConfig = useMarketConfig()
    const countryConfig = useCountryConfig()
    const {t} = useTranslation()
    const {formatPrice} = usePriceFormatter()
    const navigation = useNavigation()

    const promotionSummaryText = localizeObject(cart.store.promotion?.promotion_summary_text);
    const summaryText = promotionSummaryText ?? localizeObject(marketConfig?.summary_text);

    return (
        <div className="cartSummaryNew__footer">
            {!!(cart.store.cart.items.length && summaryText) && (
                <p className="cartSummaryNew__info" dangerouslySetInnerHTML={{__html: summaryText}}/>
            )}

            {!disableFooter && (cart.store.cart.items.length ? (
                <>
                    <div className="cartBoxNew__total">
                        <span className="cartBoxNew__name">{t('cart.total')}</span>
                        <span className="cartBoxNew__price">
                            {formatPrice(
                            countryConfig?.price_type_display === 'net'
                                ? (capsulesNetValue + giftNetValue)
                                : (capsulesValue + deliveryValue + giftValue + giftValueWithoutPromo.gross))}
                        </span>

                    </div>

                    <div className="cartSummaryNew__submit">
                        <Button
                            type="primary"
                            label={t('continue')}
                            disabled={!isCartValid}
                            href={navigation(summaryRoute)}
                        />
                    </div>
                </>
            ) : (
                <div className="cartSummaryNew__empty">
                    <h4 className="cartSummaryNew__head">{t('cart.empty.title')} </h4>
                    <p
                        className="cartSummaryNew__text"
                        dangerouslySetInnerHTML={{__html: t(`cart.empty.${cart.store.machine ? 'text2' : 'text'}`)}}
                    />
                </div>
            ))}

        </div>
    )
}