import {AsyncSelectController} from "../controllers";
import React from "react";
import {useTranslation} from "../../hooks";
import {getStackByType} from '../../actions/MainActions';
import {useAsyncSelect} from "../hooks";
import {SelectOption} from "../../services";

export default function ProfessionSelect(props) {
    const {t} = useTranslation()
    const asyncSelect = useAsyncSelect({
        name: 'professions',
        action: params => getStackByType('profession', params),
        paramFunc: (slug) => ({
            slug: slug,
            limit: 100,
        }),
        mapFunc: o => new SelectOption(o.stack_title, o.stack_value),
    })

    return (
        <AsyncSelectController
            asyncSelect={asyncSelect}
            name="profession"
            label={t('profession')}
            {...props}
            options={{
                nullable: true,
                ...props.options,
            }}
        />
    )
}
