import {useEffect, useRef} from "react";
import {getDefaultCoords} from "../../../utils/MarketConfig";
import {Preloader} from "../../../misc/_index";

export default function Map({
    apiLoader,
    map,
    data,
    setMap,
    isLoading,
}) {
    const ref = useRef(null)


    useEffect(() => {
        if (!apiLoader.isSuccess || !apiLoader.data || !ref.current)
            return

        createMap()

        async function createMap() {
            let coords = getDefaultCoords()

            const map = new apiLoader.data.maps.Map(ref.current, {
                center: {
                    lat: coords[0],
                    lng: coords[1],
                },
                zoom: coords[2],
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControl: false,
                rotateControl: false,
                styles: [
                    {
                        featureType: "poi",
                        stylers: [
                            {visibility: "off"}
                        ]
                    }
                ]
            })

            if (typeof setMap === 'function')
                setMap(map)
        }
    }, [ref.current, apiLoader.status, JSON.stringify(apiLoader.data)])

    if (apiLoader.isError) {
        return (
            <div>cannot load map :(</div>
        )
    }

    return (
        <div className="pickupPointMap">
            <div
                ref={ref}
                className="pickupPointMap__map"
            />
            {!!isLoading && (
                <div className="pickupPointMap__fetching">
                    <Preloader size="1.5rem"/>
                </div>
            )}
        </div>
    )
}
