import {Link} from "react-router-dom";
import {Button} from "../../../components/buttons/_index";
import {useTranslation} from "../../../hooks";
import {useNavigation} from "../../../misc/Hooks";

export default function Tile({item}){
    const {t} = useTranslation()
    const navigation = useNavigation()

    return (
        <div className="resellerOptions__item">
            <img
                src={item.imageLight}
                alt={t(item.label)}
                className="resellerOptions__bg"
            />
            <div className="resellerOptions__thumb">
                <img src={item.imageBold} alt="" className="img-fluid"/>
            </div>
            <h4 className="resellerOptions__label">{t(item.label)}</h4>
            <div className="resellerOptions__button">
                    <Button
                        label={t('select')}
                        href={navigation(item.route)}
                    />
            </div>
        </div>
    )
}