import Badge from "../Badge";
import React, {useMemo} from "react";
import {useTranslation} from "../../hooks";


export default function SubscriptionStatusLabel({status, ...props}) {
    const {t} = useTranslation()
    const className = useMemo(() => {
        switch (status) {
            case -3:
                return '-success -outline';
            case -1:
                return '-error';
            case 0:
                return '-success -outline';
            case 1:
                return '-success -outline';
            case 'processed':
                return '-success -outline';
            case 'in_treatment':
                return '-success -outline';
        }
    }, [status])

    return (
        <Badge className={className} label={t(`subscription_status.${status}`)}/>
    )
}
