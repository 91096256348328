import {useNavigation, useTranslation} from "../../../misc/Hooks";
import {Button} from "../../../components/buttons/_index";
import {Link} from "react-router-dom";
import {Routes} from "../../../utils/_index";
import {Header} from "../../../components/misc/_index";


export default function TokenExpiredScreen(props) {
    const t = useTranslation()
    const navigation = useNavigation()

    return (
        <div className="app">
            <Header cartDisabled/>

            <div className="main">
                <div className="wrapper-bg formContainer">
                    <div className="wrapper">
                        <div className="--mb4" style={{textAlign: 'center'}}>
                            <h1 className="par-1">{t('stay_home.token_expired.title')}</h1>
                            <p className="par-2 --mb4">{t('stay_home.token_expired.text')}</p>
                                <Button
                                    label={t('stay_home.token_expired.button')}
                                    href={navigation(Routes.STAY_HOME)}
                                />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
