import {useMutation} from "@tanstack/react-query";
import * as mainActions from "../actions/MainActions";
import {ApiException} from "../errors";
import {useGTM, useModuleContext, useOrderData} from "./index";
import {useMemo, useState} from "react";
import * as orderActions from "../actions/OrderActions";
import {useCart} from "../misc/Hooks";

export default function useNonPersistentQuotation({
    paymentMethods: _paymentMethods,
    deliveryMethods: _deliveryMethods,
    deliveryMethodEnabled = false,
}) {
    const orderData = useOrderData()
    const {errorTracking} = useGTM()
    const {storeName, reducer, dispatch} = useModuleContext()
    const {
        cartValue,
        deliveryValue
    } = useCart(storeName);
    const [paymentMethods, setPaymentMethods] = useState(_paymentMethods ? [..._paymentMethods] : [])
    const [deliveryMethods, setDeliveryMethods] = useState(_deliveryMethods ? [..._deliveryMethods] : [])
    const [memberCredit, setMemberCredit] = useState(null);
    const prepareMutation = useMutation({
        mutationFn: async (payload) => {
            return await mainActions.nonPersistentQuotationPrepare(payload)
                .then(res => {
                    if (res.response) {
                        throw new ApiException(res.response?.data?.message, res.response?.data)
                    }
                    return res.data?.data
                })
        },
        onSuccess: (data) => {
            const allowedDeliveryMethods = data.delivery_methods
                // .filter(d => d.type !== 'timeSlot')
                .map(d => ({
                    ..._deliveryMethods.find(i => i.id_delivery_method === d.id),
                    quotationData: {
                        ...d,
                        // amount: 8.99,
                        // type: 'pickup',
                    },
                }))

            // setDeliveryMethods(allowedDeliveryMethods)
        },
        onError: (error) => {
            errorTracking('Delivery And Payment', 'invalid_order')
            orderData.setQuotationError(error)
            // setPaymentMethods(_paymentMethods.filter(p => p.payment_method_integration_type !== 'quotation_non_cc'))
        },
    })
    const verifyMutation = useMutation({
        mutationFn: async (payload) => {
            return await mainActions.nonPersistentQuotationVerify(payload)
                .then(res => {
                    if (res.response) {
                        throw new ApiException(res.response?.data?.message, res.response?.data)
                    }
                    return res.data?.data
                })
        },
        onError: (error) => {
            // setPaymentMethods(_paymentMethods.filter(p => p.payment_method_integration_type !== 'quotation_non_cc'))
        },
        onSuccess: (data) => {
            setMemberCredit(data.creditData)
            setPaymentMethods(_paymentMethods ? [...paymentMethods] : [])
            dispatch(reducer.setCartQuotationData(data))
        },
    })
    const memberCreditMutation = useMutation({
        mutationFn: async (payload) => {
            const {data} = await orderActions.checkCredit(payload)

            if (data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        onError: (error) => {
            errorTracking('Delivery And Payment', error?.error ?? 'invalid_order')
            // setError(t(`error.${error.message || 'invalid_order'}`))
        },
        onSuccess: ({creditData}) => {
            setMemberCredit(creditData)
            setDeliveryMethods(_deliveryMethods)
        },
    })
    const quotationMutation = useMutation({
        mutationFn: async (payload) => {
            const {data} = await orderActions.cartQuotation(payload)

            if (data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        onError: (error) => {
            errorTracking('Delivery And Payment', 'invalid_order')
            orderData.setQuotationError(error)
            setPaymentMethods(_paymentMethods.filter(p => p.payment_method_integration_type !== 'quotation_non_cc'))
        },
        onSuccess: (data) => {
            setMemberCredit(data.creditData)
            dispatch(reducer.setCartQuotationData(data))
        },
    })
    const {isLoading, isIdle, isProcessed} = useMemo(() => {
        return {
            isLoading: prepareMutation.isLoading || verifyMutation.isLoading || memberCreditMutation.isLoading || quotationMutation.isLoading,
            isIdle: prepareMutation.isIdle || verifyMutation.isIdle || memberCreditMutation.isIdle || quotationMutation.isIdle,
            isProcessed: !![prepareMutation.status, verifyMutation.status, memberCreditMutation.status, quotationMutation.status].find(i => ['error', 'success'].includes(i)),
        }
    }, [prepareMutation.status, verifyMutation.status, memberCreditMutation.status, quotationMutation.status])
    const nonPersistentQuotationPayment = useMemo(() => {
        return paymentMethods.find(p => p.payment_method_integration_type === 'quotation_non_cc')
            || paymentMethods.find(p => p.payment_method_basket_null && Number(cartValue + deliveryValue) <= 0)
    }, [JSON.stringify(paymentMethods)])

    function prepareQuotation() {
        if (nonPersistentQuotationPayment) {
            if (deliveryMethodEnabled) {
                const payload = {
                    customer: orderData.getData(),
                    cart: orderData.getCartWithoutExtraLine(),
                    delivery_methods: orderData.getDeliveryMethodsData(_deliveryMethods),
                };

                prepareMutation.mutate(payload)
            } else {
                const payload = {
                    customer: orderData.getData(),
                    cart: orderData.getCartWithoutExtraLine(),
                };

                quotationMutation.mutate(payload)
            }
        } else {
            const customer = orderData.getData()
            const payload = {
                customer,
                address_id: customer.address_id,
                items: orderData.memberCreditCart(),
                delivery_methods: orderData.getDeliveryMethodsData(deliveryMethods),
            };

            memberCreditMutation.mutate(payload)
        }

    }

    function verifyQuotation(deliveryMethod) {
        if (!nonPersistentQuotationPayment || !deliveryMethodEnabled)
            return

        const payload = {
            customer: {
                ...orderData.getData(),
                id_delivery_method: deliveryMethod?.id_delivery_method,
            },
            cart: orderData.getCartWithoutExtraLine(),
        };
        verifyMutation.mutate(payload)
    }

    return {
        prepareMutation,
        verifyMutation,
        quotationMutation,
        prepareQuotation,
        verifyQuotation,
        paymentMethods,
        deliveryMethods,
        memberCredit,
        isLoading,
        isIdle,
        isProcessed,
    }
}
