import {useTableBuilder, useTableFilters} from "../../../../components/baseTable";
import {useResellerActions} from "../../../../actions/useResellerActions";
import {useReseller, useTranslation} from "../../../../hooks";
import {useMutation} from "@tanstack/react-query"
import {ApiException} from "../../../../errors";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import {SelectOption} from "../../../../services";
import {SelectController} from "../../../../forms/controllers";
import {isoDateConverterWithTime} from "../../../../misc/Helpers";
import {DownloadReportButton} from "../components";

export default function useResellerReports() {
    const actions = useResellerActions()
    const {t} = useTranslation();
    const {role} = useReseller()
    const [resellersModalVisible, setResellersModalVisible] = useState(false);
    const [modalResellers, setModalResellers] = useState(null)
    const [selectAllItems, setSelectAllItems] = useState([])
    const [disabledInputs, setDisabledInputs] = useState({
        supervisor: false,
        keyAccount: false,
        shop: false,
        role: false,
    })

    const filtersDataMutation = useMutation(async (params) => {
        const {data} = await actions.getReportsFilters(params)

        if (data.status === 'success')
            return data
        throw new ApiException(data.messages[0], data)
    })

    const supervisorOptions = useMemo(() => {
        const resellersData = filtersDataMutation?.data?.data?.resellers
        let options = []
        if (resellersData) {
            options = resellersData?.map(r => (
                new SelectOption(
                    `${r.reseller_firstname} ${r.reseller_lastname} [${r.shop_title}]`,
                    Number(r.id_reseller)
                )
            ))
        }
        return [new SelectOption(t('reports_users.filters.all'), undefined), ...options]
    }, [filtersDataMutation?.data?.data?.resellers])

    const shopsOptions = useMemo(() => {
        const shopsData = filtersDataMutation?.data?.data?.shops
        let options = []
        if (shopsData)
            options = shopsData?.map(s => (new SelectOption(s.shop_title, Number(s.id_shop))))

        return [new SelectOption(t('reports_users.filters.all'), undefined), ...options]
    }, [filtersDataMutation?.data?.data?.shops])

    const keyAccountsOptions = useMemo(() => {
        const shopGroupsData = filtersDataMutation?.data?.data?.shop_groups
        let options = []
        if (shopGroupsData)
            options = shopGroupsData?.map(sg => (new SelectOption(sg.shop_group_title, Number(sg.id_shop_group))))

        return [new SelectOption(t('reports_users.filters.all'), undefined), ...options]
    }, [filtersDataMutation?.data?.data?.shop_groups])

    const roleOptions = useMemo(() => ([
        new SelectOption(t('reports_users.filters.all'), undefined),
        new SelectOption(t('reseller_client.reseller_role.pos_super_user'), 'pos_super_user'),
        new SelectOption(t('reseller_client.reseller_role.pos_user'), 'pos_user')
    ]), [filtersDataMutation.isSuccess])

    const usersFilters = useTableFilters({
        builder: {
            formConfig: [['supervisor', 'key_account', 'shop', 'role']],
            inputComponents: {
                supervisor: (
                    <SelectController
                        name="supervisor"
                        label={t('reports_users.filters.supervisor')}
                        selectOptions={supervisorOptions ?? []}
                        options={{
                            inputProps: {
                                isLoading: filtersDataMutation.isLoading,
                                isDisabled: disabledInputs.supervisor,
                                isDisabledValidation: true
                            }
                        }}
                        defaultValue={supervisorOptions[0]}
                    />
                ),
                key_account: (
                    <SelectController
                        name="keyAccount"
                        label={t('reports_users.filters.key_account')}
                        selectOptions={keyAccountsOptions ?? []}
                        options={{
                            inputProps: {
                                isLoading: filtersDataMutation.isLoading,
                                isDisabled: disabledInputs.keyAccount,
                                isDisabledValidation: true
                            }
                        }}
                        defaultValue={keyAccountsOptions[0]}
                    />
                ),
                shop: (
                    <SelectController
                        name="shop"
                        label={t('reports_users.filters.shop')}
                        selectOptions={shopsOptions ?? []}
                        options={{
                            inputProps: {
                                isLoading: filtersDataMutation.isLoading,
                                isDisabled: disabledInputs.shop,
                                isDisabledValidation: true
                            }
                        }}
                        defaultValue={shopsOptions[0]}
                    />
                ),
                role: (
                    <SelectController
                        name="role"
                        label={t('reports_users.filters.role')}
                        selectOptions={roleOptions ?? []}
                        options={{
                            inputProps: {
                                isLoading: filtersDataMutation.isLoading,
                                isDisabled: disabledInputs.role,
                                isDisabledValidation: true
                            }
                        }}
                        defaultValue={roleOptions[0]}
                    />
                )
            }
        },
        searchPrefix: "usersFilter",
        mapFunc: (data) => ({
            supervisor: data.usersFilter.supervisor?.value ?? undefined,
            keyAccount: data.usersFilter.keyAccount?.value ?? undefined,
            shop: data.usersFilter.shop?.value ?? undefined,
            role: data.usersFilter.role?.value ?? undefined,
        }),
    })

    const usersTable = useTableBuilder({
        name: "users_reports",
        action: async (params) => {
            const {page, limit, ...filterParams} = params
            filtersDataMutation.mutate(filterParams)
            const {data} = await actions.getResellerListV2(params)

            if (data.status === 'success') {
                const {all_resellers, resellers} = data.data
                setSelectAllItems(all_resellers)
                return {data: {data: resellers}}
            }
        },
        columns: [
            {
                name: 'name',
                label: 'reseller_client.full_name',
                cell: ({row: {original}}) => `${original.reseller_firstname} ${original.reseller_lastname}`
            },
            {
                name: 'reseller_number',
                label: 'reseller_client.reseller_number'
            },
            {
                name: 'shop_title',
                label: 'shop'
            },
            {
                name: 'reseller_role',
                label: 'role',
                cell: ({value}) => t(`reseller_client.reseller_role.${value}`)
            },
            {
                name: 'reseller_email',
                label: 'email'
            },

        ],
        config: {
            selectable: true,
            idFieldName: 'id_reseller',
            searchParamPrefix: 'users'
        },
        params: {
            id_reseller: usersFilters.rawData.supervisor,
            id_shop_group: usersFilters.rawData.keyAccount,
            id_shop: usersFilters.rawData.shop,
            role: usersFilters.rawData.role,
            enabled: role !== 'pos_user'
        }
    })

    useEffect(() => {
        setDisabledInputs(old => ({...old, supervisor: usersFilters.rawData?.role === 'pos_user'}))
    }, [usersFilters.rawData?.role])

    useEffect(() => {
        setDisabledInputs(old => ({...old, role: !!usersFilters.rawData?.supervisor}))
    }, [usersFilters.rawData?.supervisor])

    const generatedReportsTable = useTableBuilder({
        name: "generated_reports",
        action: async (params) => {
            return actions.getGeneratedReportsV2(params)
        },
        columns: [
            {
                name: 'reseller_report_resellers',
                label: 'reports.table.generated_for',
                cell: ({value}) => (
                    <Fragment>
                        {value.slice(0, 3).map((reseller, idx) => (
                            <span key={idx} style={{display: 'block', lineHeight: 1}}>{reseller}</span>
                        ))}
                        {value?.length > 3 && (
                            <button
                                className={"link -primary"}
                                style={{fontSize: '.65rem'}}
                                onClick={() => {
                                    setResellersModalVisible(true)
                                    setModalResellers(value)
                                }}
                            >
                                {t('more')} ({value.length - 3})
                            </button>
                        )}
                    </Fragment>
                )
            },
            {name: 'reseller_report_date_from', label: 'reports.table.date_from'},
            {name: 'reseller_report_date_to', label: 'reports.table.date_to'},
            {
                name: 'created_at',
                label: 'reports.table.created_at',
                cell: ({value}) => isoDateConverterWithTime(value, '-', ':')
            },
            {
                name: 'download',
                label: 'reports.table.download',
                cell: ({row: {original}}) =>
                    <DownloadReportButton
                        status={original.reseller_report_status}
                        url={original.reseller_report_url}
                    />
            }

        ],
        config: {
            autoRefetch: true,
            autoRefetchTimeout: 6000,
        },
        params: {}
    })


    return {
        usersTable,
        usersFilters,
        filtersDataMutation,
        selectAllItems,
        generatedReportsTable,
        setResellersModalVisible,
        resellersModalVisible,
        modalResellers
    }
}