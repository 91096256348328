import React, {useMemo} from 'react';
import {useCountryConfig, useMarketConfig, useTranslation} from "../../misc/Hooks";
import {getFileUrl} from "../../misc/Helpers";
import Env from "../../utils/Env";
import {useRouteMatch} from "react-router-dom";

const Footer = (props) => {
    const t = useTranslation();
    const marketConfig = useMarketConfig();
    const countryConfig = useCountryConfig();
    const match = useRouteMatch();
    const files = useMemo(() => {
        const filter = (data) => {
            if(!Array.isArray(data))
                return []
            return data?.filter(f => f.lang === (match.params.lang ?? Env.DEFAULT_LANG)
                && f.type === 'privacy_policy'
                && Number(f.status)
            )
        }
        if(filter(countryConfig.files)?.length)
            return filter(countryConfig.files)
        return filter(marketConfig.files)
    }, [marketConfig, countryConfig])

    const year = new Date().getFullYear();

    const contactUrl = countryConfig?.contact_url || t(`${props.module ? `${props.module}.` : ''}contact_us_url`);

    return (
        <footer className="footer">
            <div className="wrapper-bg">
                <div className="wrapper -xxl">
                    <div className="grid">
                        <div className="footer__text">&copy; Nespresso {year}</div>
                        <ul>
                            {!!t('custom_link_footer.label') && (
                                <li>
                                    <a href={t('custom_link_footer.url')} title={t('custom_link_footer.label')} target="_blank">{t('custom_link_footer.label')}</a>
                                </li>
                            )}
                            {!props.simple && files?.map((f, idx) => (
                                <li key={idx}>
                                    <a href={getFileUrl(f)} title={f.label} target="_blank">{f.name}</a>
                                </li>
                            ))}
                            {(!!contactUrl && !props.hideContact) && (
                                <li>
                                    <a href={contactUrl} target="_blank">{t(`${props.module ? `${props.module}.` : ''}contact_us`)}</a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>

        </footer>
    );
}


export default Footer;
