import React, {useState} from 'react';
import BaseModal from "../../modals/BaseModal";
import {Button, PlusMinusButton} from "../../buttons/_index";
import {getImage} from "../../../misc/Helpers";
import {useCart, usePriceFormatter, useTranslation} from "../../../misc/Hooks";
import {Config} from "../../../utils/_index";
import {useSelector} from "react-redux";
import {Accordion} from "../../misc/_index";
import {GetInTouchModal} from "../../modals/_index";
import {isEnabledByMarket} from "../../../utils/MarketConfig";

const CoffeeItemModal = (props) => {
    const {formatPrice, formatPriceObj, checkPromoPrice} = usePriceFormatter();
    const {updateItem, isItemLocked} = useCart(props.storeName);
    const t = useTranslation();
    const store = useSelector(state => ({
        coffeeSizes: state.main.coffeeSizes,
        products: state[props.storeName].products,
    }))

    const [gitModalVisible, setGitModalVisible] = useState(false);


    const renderSize = (s, idx) => {
        const size = store.coffeeSizes?.find(i => i.stack_value === s);

        if(!size) {
            return null;
        }

        if(s.match(/_image$/)) {
            return  (
                <div key={idx} className="coffeeModal__imageSize">
                    <img src={getImage(size.stack_gallery)} alt={size.stack_title}/>
                    <p className="coffeeModal__size" dangerouslySetInnerHTML={{__html: size.stack_title}}/>
                </div>
            )
        }
        return <span key={idx} className="coffeeModal__size" dangerouslySetInnerHTML={{__html: size.stack_title}}/>;
    }


    const hasPromoPrice = checkPromoPrice(
        parseInt(props.item.product_price_promo?.status),
        props.item.product_price_promo?.date_from,
        props.item.product_price_promo?.date_to,
    );


    let intensityBox = [];
    for (let i = 0; i < 13; i++) {
        let active = i < parseInt(props.item.product_intensity) ? '-active' : '';
        intensityBox.push(<span key={i} className={active}/>);
    }


    return (
        <BaseModal
            visible={props.visible}
            onClose={props.onClose}
        >
            <div className="coffeeModal">
                <div>
                    <div className="coffeeModal__thumb --mb3">
                        <img src={getImage(props.item.product_gallery)} alt={props.item.product_title}/>
                    </div>
                    {props.item.product_type === Config.COFFEE_TYPE.CAPSULE && (
                        <>
                            <div className="coffeeModal__intensity --mb3">
                                {!!parseInt(props.item.product_intensity) && (
                                    <div className="coffeeItem__intensity">
                                        <div className="coffeeItem__intensityBox">
                                            {intensityBox.map(item => item)}
                                        </div>
                                        <span className="coffeeItem__intensityText">{t('coffee.intensity')} {props.item.product_intensity}</span>
                                    </div>
                                )}
                            </div>
                            {!!props.item.product_cup_size?.length && (
                                <div className="--mb3">
                                    <h5 className="par-2 --alignCenter --mb2_5">{t('coffee.cup_sizes')}</h5>
                                    <div className="coffeeModal__sizes">
                                        {props.item.product_cup_size.map(renderSize)}
                                    </div>
                                </div>
                            )}
                            {!!props.item.product_similar?.length && (
                                <div className="coffeeModal__similar">
                                    <h5 className="par-2 --alignCenter --mb3">{t('coffee.similar_coffees')}</h5>
                                    <div className="similar">
                                        {props.item.product_similar.map(sId => {
                                            const s = store.products.find(p => p.id_product === parseInt(sId));
                                            return !!s && (
                                                <div key={sId} className="similar__item">
                                                    <div className="similar__thumb">
                                                        <img src={getImage(s.product_gallery)} alt={s.product_title}/>
                                                    </div>
                                                    <h6 className="similar__title">{s.product_title}</h6>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div>
                    <h3 className="coffeeModal__title">{props.item.product_title}</h3>
                    <div className="coffeeModal__lead par-5" dangerouslySetInnerHTML={{__html: props.item.product_lead}}/>
                    <h5 className="coffeeModal__price --mb0">
                        {!hasPromoPrice ? (
                            <span>{formatPriceObj(props.item.product_price)}</span>
                        ) : (
                            <>
                                <small><s className="--textDark" style={{marginRight: '6px'}}>{formatPriceObj(props.item.product_price)}</s></small>
                                <span>{formatPriceObj(props.item.product_price_promo)}</span>
                            </>
                        )}
                    </h5>
                    <h5 className="coffeeModal__price -secondary">
                        {!hasPromoPrice ? (
                            <span>{formatPrice(props.item.product_price?.gross * props.item.product_package_qty)} / {t('box')}</span>
                        ) : (
                            <>
                                <small><s className="--textDark" style={{marginRight: '6px'}}>{formatPrice(props.item.product_price?.gross * props.item.product_package_qty)}</s></small>
                                <span>{formatPrice(props.item.product_price_promo?.gross * props.item.product_package_qty)} / {t('box')}</span>
                            </>
                        )}
                    </h5>

                    <div className="coffeeModal__button">
                        {props.lockedByOptIn?.value ? (
                            <Button
                                type="primary"
                                size="sm"
                                label={t(!props.itemInCart ? 'buy_now' : 'remove_from_cart')}
                                disabled
                            />
                        ) : props.itemInCart ? (
                            <PlusMinusButton
                                type="primary"
                                size="sm"
                                plusDisabled={props.itemInCart.value >= props.item.product_max_qty || isItemLocked(props.item.id_product)}
                                value={props.itemInCart.value * props.item.product_package_qty}
                                step={props.item.product_step}
                                packageQty={props.item.product_package_qty}
                                max={props.item.product_max_qty ?? undefined}
                                onClickMinus={() => updateItem(props.item.id_product, (props.itemInCart?.value ?? 0) - props.item.product_step)}
                                onClickPlus={() => updateItem(props.item.id_product, (props.itemInCart?.value ?? 0) + props.item.product_step)}
                                onChange={v => updateItem(props.item.id_product, v)}
                            />
                        ) : (
                            <Button
                                type="primary"
                                size="sm"
                                label={t('buy_now')}
                                disabled={isItemLocked(props.item.id_product)}
                                onClick={() => updateItem(props.item.id_product, props.item.product_step)}
                            />
                        )}
                    </div>
                    <div
                        className="coffeeModal__description par-5 --mb4"
                        dangerouslySetInnerHTML={{__html: props.item.product_description}}
                    />
                    {(!!props.item.product_allergens && !isEnabledByMarket('hideIngredientsAndAllergens')) && (
                        <div className="--mb4">
                            <Accordion title={t('coffee.allergens_title')}>
                                <p
                                    className="par-5"
                                    dangerouslySetInnerHTML={{__html: props.item.product_allergens}}
                                />
                            </Accordion>
                        </div>
                    )}
                    {(!!props.item.product_ingredients && !isEnabledByMarket('hideIngredients')) && (
                        <div className="--mb4">
                            <Accordion title={t('coffee.ingredients_title')}>
                                <p
                                    className="par-5"
                                    dangerouslySetInnerHTML={{__html: props.item.product_ingredients}}
                                />
                            </Accordion>
                        </div>
                    )}

                    <div className="par-4">
                        <b>{t('need_help')} </b>
                        <a className="link -primary -parentSize" onClick={() => setGitModalVisible(true)}>
                            <b>{t('get_in_touch')}</b>
                        </a>
                    </div>
                </div>
            </div>


            <GetInTouchModal
                visible={gitModalVisible}
                onClose={() => setGitModalVisible(false)}
            />
        </BaseModal>
    );
}


export default CoffeeItemModal;
