

export default async function asyncAction(action, params) {
    const abortController = new AbortController()
    const response = await action(...params, {signal: abortController.signal})
        .then(res => {
            if(res.data.status === 'success') {
                return res
            }
            throw new Error('error')
        })
    abortController.abort()

    return response
}
