import {useCustomer, useFieldFormat, useFormHelper, useModuleContext, useTranslation} from "../../hooks";
import {FormFragmentProvider, useFormRulesBuilder} from "../hooks";
import {TextInputController} from "../controllers";
import React, {useEffect} from "react";
import {FormGroup} from "../components";
import {useWatch} from "react-hook-form";
import {AccountNumberInput} from "../presets";
import {useFetchBank, usePaymentMethodCodeVerify} from "../../components/PaymentMethodCodeVerify";
import {Button} from "../../components/buttons/_index";


export default function PaymentMethodCodeVerifyFormFragment({paymentMethod, paymentMethodId, machinePlan}) {
    const {t} = useTranslation()
    const {storeName, store, dispatch, reducer} = useModuleContext()
    const {nespressoMember} = useCustomer()
    const {formats, getIfValid} = useFieldFormat()
    const {bank, loading, error, fetchBank} = useFetchBank(storeName)
    const paymentMethodCodeVerify = usePaymentMethodCodeVerify()
    const {formMethods, setError, name} = useFormHelper('paymentMethodCodeVerify')
    const formRules = useFormRulesBuilder({
        'accountNumber': b => b.required()
            .pattern(formats.accountNumber.pattern)
            .validate(() => bank && !paymentMethodCodeVerify.loadingCode),
        'verificationCode': b => b.required()
            .validate(v => !paymentMethodCodeVerify.codeVerifyError && !paymentMethodCodeVerify.loadingVerifyCode)
            .minLength(6)
            .maxLength(6),
    }, formMethods)
    const email = useWatch({
        control: formMethods.control,
        name: 'details.email',
    })
    const accountNumber = useWatch({
        control: formMethods.control,
        name: name('accountNumber'),
    })
    const verificationCode = useWatch({
        control: formMethods.control,
        name: name('verificationCode'),
    })

    useEffect(() => {
        if (accountNumber) {
            formMethods.trigger(name('accountNumber'))
        }
    }, [bank, paymentMethodCodeVerify.loadingCode])

    useEffect(() => {
        if (verificationCode) {
            formMethods.trigger(name('verificationCode'))
        }
    }, [paymentMethodCodeVerify.codeVerifyError, paymentMethodCodeVerify.loadingVerifyCode])

    useEffect(() => {
        if (bank && (nespressoMember?.email || email) && store.verifiedPaymentMethod?.id_payment_method !== Number(paymentMethodId)) {
            paymentMethodCodeVerify.generateCode(nespressoMember?.email ?? email, machinePlan.id_product, accountNumber)
        }
    }, [bank, email, nespressoMember])

    useEffect(() => {
        if (verificationCode?.length === 6) {
            paymentMethodCodeVerify.verifyCode(verificationCode)
                .then(isCodeValid => {
                    if (!isCodeValid) {
                        setError('verificationCode', {
                            type: 'invalid',
                            message: t(`error.${paymentMethodCodeVerify.codeVerifyError}`) || t('validation.invalid'),
                        })
                    } else {
                        if(typeof reducer.setVerifiedPaymentMethod === 'function')
                            dispatch(reducer.setVerifiedPaymentMethod(paymentMethod))
                    }
                })
        }
    }, [verificationCode])

    useEffect(() => {
        const value = getIfValid(accountNumber, formats.accountNumber.pattern)
        if (value) {
            fetchBank(value)
        }
    }, [accountNumber])

    useEffect(() => {
        if (error) {
            setError('accountNumber', {
                type: 'invalid',
                message: t(`error.${error}`) || t('validation.invalid'),
            })
        }
    }, [error])

    const resendCode = () => {
        paymentMethodCodeVerify.resendCode(nespressoMember?.email ?? email, machinePlan.id_product, accountNumber)
    }

    if (!paymentMethod?.payment_method_need_code_verify)
        return null

    return (
        <FormFragmentProvider value={{prefix: 'paymentMethodCodeVerify'}}>
            <FormGroup>
                <AccountNumberInput
                    label={t('payment_method_code_verify.account_number')}
                    options={{
                        isLoading: loading || paymentMethodCodeVerify.loadingCode,
                    }}
                    rules={formRules.values.accountNumber}
                />
            </FormGroup>
            {!!bank && (
                <div className="--mb3 --pl2">
                    <p className="par-2 --semiBold">{bank.bank_name}</p>
                </div>
            )}
            {paymentMethodCodeVerify.codeVisible && (
                <div className="--mb3">
                    <p className="par-2">{t('payment_method_code_verify.verification_code_info')}</p>
                    <FormGroup>
                        <TextInputController
                            name="verificationCode"
                            label={t('payment_method_code_verify.verification_code')}
                            rules={formRules.values.verificationCode}
                            options={{
                                isLoading: paymentMethodCodeVerify.loadingVerifyCode,
                            }}
                        />
                    </FormGroup>
                    <Button
                        label={t('resend')}
                        size="md"
                        disabled={paymentMethodCodeVerify.loadingCode || paymentMethodCodeVerify.loadingVerifyCode}
                        onClick={resendCode}
                    />
                </div>
            )}
        </FormFragmentProvider>
    )
}
