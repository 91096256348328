import {CheckboxController} from "../controllers";
import React from "react";
import {useTranslation} from "../../hooks";

export default function IsCompanyAddressCheckbox(props) {
    const {t} = useTranslation()

    return (
        <CheckboxController
            name="isCompanyAddress"
            label={t('company_address')}
            {...props}
        />
    )
}
