import {useEffect, useState} from "react";
import {useProducts} from "../../../hooks";
import Config from "../../../utils/Config";
import {useSelector} from "react-redux";
import {getCategories} from "../../../misc/Helpers";

export default function useProductsFilters(products, categoryBar) {
    const store = useSelector(state => ({
        machine: state[products.storeName]?.machine,
    }))
    const [modalVisible, setModalVisible] = useState(false)
    const [filter, setFilter] = useState(null);
    const [cupSizes, setCupSizes] = useState([]);
    const [aromaticProfiles, setAromaticProfiles] = useState([]);
    const [coffeeTypes, setCoffeeTypes] = useState([]);
    const [accessoryTypes, setAccessoryTypes] = useState([]);
    const {getAvailable} = useProducts(products.storeName)

    const getProductsCount = () => {
        let count = 0;
        products.categories.map(c => {
            count += c.products.length
        })

        return count;
    }

    const isCoffee = (type) => {
        return [Config.COFFEE_TYPE.CAPSULE].includes(type)
    }

    const isPackage = (type) => {
        return [Config.COFFEE_TYPE.PACKAGE].includes(type)
    }

    const isAccessory = (type) => {
        return [Config.COFFEE_TYPE.ACCESSORY].includes(type)
    }

    const handleFilterSubmit = (data) => {
        const arr = [...products.originalCategories];
        const intensities = [];
        data?.intensities?.map(value => {
            if (typeof value === 'string') {
                const [from, to] = value.split('-');
                for (let i = parseInt(from); i <= parseInt(to); i++) {
                    intensities.push(i.toString());
                }
            } else {
                intensities.push(value.toString());
            }
        })

        for (const catIdx in arr) {
            const cat = arr[catIdx];
            let catProducts = cat.products;
            if (intensities?.length) {
                catProducts = catProducts.filter(p => p
                    && isCoffee(p.product_type)
                    ? intensities.includes(p.product_intensity.toString())
                    : true
                )
            }
            if (data?.sizes?.length) {
                catProducts = catProducts.filter(p => p
                    && isCoffee(p.product_type)
                    ? p.product_cup_size?.some(s => {
                        return data.sizes.indexOf(s.replace(/_image$/, '')) >= 0
                    })
                    : true
                )
            }
            if (data?.aromaticProfiles?.length) {
                catProducts = catProducts.filter(p => {
                        if(p && isCoffee(p.product_type)) {
                            if(Array.isArray(p.product_aromatic_profile)) {
                                return data.aromaticProfiles.find(a => p.product_aromatic_profile.find(i => i === a))
                            } else {
                                return data.aromaticProfiles.includes(p.product_aromatic_profile)
                            }
                        }
                        return true
                    })
            }
            if (data?.coffeeTypes?.length) {
                catProducts = catProducts.filter(p => p
                    && isCoffee(p.product_type)
                    ? data.coffeeTypes.includes(p.product_coffee_type)
                    : true
                )
            }
            if (data?.line) {
                if (cat.category_coffee_type !== data?.line && cat.category_coffee_type !== '0') {
                    catProducts = [];
                }
            }
            if (data?.types?.length) {
                catProducts = catProducts.filter(p => {
                    return p && data.types.includes(cat.category_type)
                })
            }
            if (data?.accessoryTypes?.length) {
                catProducts = catProducts.filter(p => p
                    && isAccessory(p.product_type)
                    ? p.product_accessory_type && data.accessoryTypes.find(t => p.product_accessory_type.includes(t))
                    : true
                )
            }
            arr[catIdx] = {
                ...cat,
                products: catProducts,
            }
        }

        setFilter(data);
        products.setCategories(arr);
    }

    const handleFilterReset = () => {
        setFilter({});
        handleFilterSubmit({}, null)
    }


    useEffect(() => {
        const arrCS = [];
        const arrAP = [];
        const arrCT = [];
        const arrAT = [];

        getAvailable().map(p => {
            p.product_cup_size?.map(s => {
                s = s.replace(/_image$/, '')
                if (s && !arrCS.find(o => o === s)) {
                    arrCS.push(s)
                }
            })

            if (p.product_aromatic_profile) {
                if(Array.isArray(p.product_aromatic_profile)) {
                    const missing = p.product_aromatic_profile.filter(t => !arrAP.includes(t))
                    arrAP.push(...missing);
                } else if(!arrAP.includes(p.product_aromatic_profile)){
                    arrAP.push(p.product_aromatic_profile)
                }
            }
            if (p.product_coffee_type && !arrCT.includes(p.product_coffee_type)) {
                arrCT.push(p.product_coffee_type);
            }
            if (p.product_accessory_type) {
                const missing = p.product_accessory_type.filter(t => !arrAT.includes(t))
                arrAT.push(...missing)
            }
        })

        const collator = new Intl.Collator(Math, {numeric: true, sensitivity: 'base'})

        setCupSizes(arrCS.sort(collator.compare));
        setAromaticProfiles(arrAP);
        setCoffeeTypes(arrCT);
        setAccessoryTypes(arrAT);

    }, [products.store.products, products.store.categories])

    useEffect(() => {
        if(!filter && categoryBar.coffeeLines?.length > 1 && store.machine) {
            const line = categoryBar.coffeeLines.find(l => l.stack_value === store.machine.machine_coffee_type)
            setFilter({line: line.stack_value})
        }
    }, [store.machine, categoryBar.coffeeLines])

    useEffect(() => {
        products.setOriginalCategories(getCategories(products.store.categories));
    }, [products.store.categories])

    useEffect(() => {
        handleFilterSubmit(filter)
    }, [products.originalCategories])

    return {
        modalVisible,
        setModalVisible,
        filter,
        cupSizes,
        aromaticProfiles,
        coffeeTypes,
        accessoryTypes,
        getProductsCount,
        handleFilterSubmit,
        handleFilterReset,
    }
}
