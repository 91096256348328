import {useSelector} from "react-redux";
import {useEffect} from "react";


export default function useProducts(storeName) {
    const store = useSelector(state => ({
        categories: state[storeName]?.categories,
        products: state[storeName]?.products,
        extraLineCategories: state[storeName].extraLineCategories,
        extraLineProducts: state[storeName].extraLineProducts,
        cart: state[storeName].cart,
    }))

    const getAvailable = () => {
        const ids = store.categories?.reduce((arr, c) => {
            arr.push(...(c.category?.subcategories?.reduce((arr, c) => {
                return [...arr, ...c.products.map(p => p.id_product)]
            }, []) ?? []))
            arr.push(...c.products.map(p => p.id_product))
            return arr
        }, [])

        const uniq = [...new Set(ids)]

        return uniq.map(id => store.products.find(p => p.id_product === id)).filter(p => p)
    }

    return {
        getAvailable,
        allCategories: [...store.categories, ...store.extraLineCategories],
        allProducts: [...store.products, ...store.extraLineProducts]
    }
}
