import {Config} from "../../../utils/_index";
import CartSummaryCategory from "./CartSummaryCategory";
import useCart2 from "../hooks/useCart2";
import {Fragment} from "react";
import {useModuleContext, useTranslation} from "../../../hooks";

export default function CartSummaryBody({disableEdit, customData, hidePlusMinusButtons}) {
    const {t} = useTranslation()
    const cart = useCart2(customData)
    const {store} = useModuleContext()

    return (
        <div className="cartSummaryNew__body">
            <div className="cartSummaryNew__bodyContent">
                {cart.getCategories().map(parentCat => {
                    const {
                        products,
                        toBeDelivered,
                        toPickUp,
                        capsulesCount,
                        capsulesCountToBeDelivered,
                        capsulesCountToPickUp,
                    } = cart.getProducts(parentCat)
                    return (!!products.length && parentCat.category.category_type !== Config.COFFEE_TYPE.DELIVERY) && (
                        <Fragment key={parentCat.id_promotion_category ?? parentCat.category?.id_category}>
                            {toPickUp.length && !store.msnRequired && !store.machine ? (
                                <>
                                    <CartSummaryCategory
                                        products={toPickUp}
                                        parentCat={parentCat}
                                        capsulesCount={capsulesCountToPickUp}
                                        disableEdit={disableEdit}
                                        customData={customData}
                                        hidePlusMinusButtons={hidePlusMinusButtons}
                                        titleSmallText={t('cart.products_to_pickup')}
                                    />
                                    {!!toBeDelivered.length && (
                                        <CartSummaryCategory
                                            products={toBeDelivered}
                                            parentCat={parentCat}
                                            capsulesCount={capsulesCountToBeDelivered}
                                            disableEdit={disableEdit}
                                            customData={customData}
                                            hidePlusMinusButtons={hidePlusMinusButtons}
                                            titleSmallText={t('cart.products_to_be_delivered')}
                                        />
                                    )}
                                </>
                            ) : (
                                <CartSummaryCategory
                                    products={products}
                                    parentCat={parentCat}
                                    capsulesCount={capsulesCount}
                                    disableEdit={disableEdit}
                                    customData={customData}
                                    hidePlusMinusButtons={hidePlusMinusButtons}
                                />
                            )}
                        </Fragment>
                    )
                })}
            </div>
        </div>
    )
}
