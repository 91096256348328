import {useTranslation} from "../../../../../hooks";
import {Button} from "../../../../../components/buttons/_index";
import {useMutation} from "@tanstack/react-query";
import * as orderActions from "../../../../../actions/OrderActions";
import {ApiException} from "../../../../../errors";
import {useResellerActions} from "../../../../../actions/useResellerActions";

export default function LocalStockRequestResend({idShop, idRequest, query}) {
    const {t} = useTranslation()
    const {resendPosStockRequest} = useResellerActions()


    const resendMutation = useMutation({
            mutationFn: async () => {
                const {data} = await resendPosStockRequest(idShop, {id_local_stock_request: idRequest})

                if (data.status === 'success')
                    return data.data
                throw new ApiException(data.messages[0], data)
            },
            onError: (error) => {},
            onSuccess: () => {
                // query.refetch()
            }
        })

    return (
        <Button
            label={resendMutation.isError ? t('error_occurred') : resendMutation.isSuccess ? t('stock_management.resend_success') : t('resend')}
            size="md"
            type="primary-outline"
            isLoading={resendMutation.isLoading}
            disabled={resendMutation.isLoading || query.isFetching || resendMutation.isSuccess}
            onClick={resendMutation.mutate}
        />
    )
}