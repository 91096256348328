import {COUNTRY_IMAGE_TYPE} from "../../../hooks/useCountryImages";
import React from "react";
import {useCountryImages, useTranslation} from "../../../hooks";

export default function CountryAuthImage(){
    const {getImage} = useCountryImages()
    const {t} = useTranslation();

    return (
        <img
            src={getImage(COUNTRY_IMAGE_TYPE.machine)}
            alt={t('reseller_auth.title')}
            className="img-fluid"
        />
    )
}