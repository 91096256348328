import React from 'react';
import {useTranslation} from "../../../misc/Hooks";
import {Button} from "../../../components/buttons/_index";
import {useModuleStepsNavigation} from "../../../hooks";
import {usePlansContext} from "../../../hooks/useFetchPlans";
import PlanSelection from "../../../components/planSelection";
import WelcomeMessage from "../../../components/WelcomeMessage";

export default function PlanSelectionScreen() {
    const t = useTranslation();
    const stepsNavigation = useModuleStepsNavigation()
    const {query} = usePlansContext()

    return (
        <>
            <WelcomeMessage/>

            <PlanSelection/>

            {!query.isLoading && (
                <div className="step__nav evCS__mobileFooter">
                    <Button
                        type="primary-outline"
                        href={stepsNavigation.prev()}
                        label={t('back')}
                    />
                    <Button
                        type="primary"
                        href={stepsNavigation.next()}
                        label={t('continue')}
                    />
                </div>
            )}
        </>
    )
}
