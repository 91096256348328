import {useMutation} from "@tanstack/react-query";
import {ApiException} from "../errors";
import {sefazInvoiceNumberValidation} from "../actions/MainActions";
import {isEnabledByMarket} from "../utils/MarketConfig";
import {useRef} from "react";


export default function useValidateInvoiceNumber() {
    const mutationTimeout = useRef(null)
    const mutation = useMutation({
        mutationFn: async (value) => {
            const {data} = await sefazInvoiceNumberValidation(value)

            if (data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
    })
    const isValid = !isEnabledByMarket('sefazInvoiceNumberValidation')
        || (mutation.isSuccess && mutation.data.result)

    function validate(value) {
        if(!isEnabledByMarket('sefazInvoiceNumberValidation'))
            return

        if(value) {
            clearTimeout(mutationTimeout.current)
            mutationTimeout.current = setTimeout(() => {
                mutation.mutate(value)
            }, 500)
        } else {
            clearTimeout(mutationTimeout.current)
        }
    }

    return {
        mutation,
        validationRequired: isEnabledByMarket('sefazInvoiceNumberValidation'),
        isValid,
        validate,
    }
}
