
const SET_UUID = 'ev/setUuid';
const SET_RECAPTCHA_TOKEN = 'ev/setRecaptchaToken';

const SET_PROMO_CODE = 'ev/setPromoCode';
const SET_MACHINE = 'ev/setMachine';
const SET_PROOF_OF_PURCHASE = 'ev/setProofOfPurchase';
const SET_PROOF_OF_PURCHASE_LOGS = 'ev/setProofOfPurchaseLogs';
const SET_IS_NEW_MEMBER = 'ev/setIsNewMember';
const SET_NESPRESSO_MEMBER = 'ev/setNespressoMember';
const SET_NESPRESSO_MEMBER_EXIST = 'ev/setNespressoMemberExist';
const SET_PROMOTION = 'ev/setPromotion';
const SET_ONLY_PROMOTION = 'ev/setOnlyPromotion';
const SET_CUSTOMER = 'ev/setCustomer';
const SET_OPT_IN = 'ev/setOptIn';

const SET_PRODUCTS = 'ev/setProducts';
const SET_CATEGORIES = 'ev/setCategories';
const UPDATE_CART = 'ev/updateCart';

const SET_PAID = 'ev/setPaid';
const SET_OCR_LOGS = 'ev/setOcrLogs';
const SET_ORDER = 'ev/setOrder';

const SET_COFFEE_LINES = 'ev/setCoffeeLines';
const SET_ADDRESSES = 'ev/setAddresses';

const SET_PAYMENT_METHOD = 'ev/setPaymentMethod';
const SET_EASY_ORDER_SETUP = 'ev/setEasyOrderSetup';

const SET_LOADING_CATEGORIES = 'ev/setLoadingCategories';
const SET_CAN_REGISTER_RESPONSE = 'ev/setCanRegisterResponse';
const SET_COOKIES_ACCEPTED = 'ev/setCookiesAccepted';

const SET_ORDER_ACCOUNT = 'ev/setOrderAccount';
const SET_LAST_VISIT = 'ev/setLastVisit';
const SET_CART_QUOTATION_DATA = 'ev/setCartQuotationData';
const SET_FREQUENCIES = 'ev/setFrequencies';

const CLEAR_STORE = 'ev/clearStore';


const initialState = {
    uuid: '',

    testOrder: false,
    serialNumber: null,

    promoCode: null,
    machine: null,
    proofOfPurchase: null,
    proofOfPurchaseLogs: null,
    proofOfPurchaseHistory: null,
    isNewMember: false,
    nespressoMember: null,
    nespressoMemberExist: false,
    promotion: null,
    customer: null,
    optIn: null,

    categories: [],
    products: [],

    cart: {
        items: [],
        sum: '',
    },

    paymentMethod: null,
    order: null,
    paid: false,

    coffeeLines: null,
    addresses: [],

    recaptchaToken: null,

    easyOrderSetup: null,

    ocrLogs: [],

    loadingCategories: false,
    canRegisterResponse: null,

    cookiesAccepted: undefined,

    orderAccount: null,
    orderAccountLinked: false,
    ecapiAccountCreated: false,
    cartQuotationData: null,

    lastVisit: null,

    frequencies: [],
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_UUID: return {...state, uuid: action.uuid}
        case SET_RECAPTCHA_TOKEN: return {...state, recaptchaToken: action.recaptchaToken}

        case SET_PROMO_CODE: return {...state, promoCode: action.promoCode}

        case SET_MACHINE: {
            const res = {
                ...state,
                machine: action.machine,
            }
            if(action.testOrder) {
                res.testOrder = action.testOrder;
            }
            if(action.serialNumber) {
                res.serialNumber = action.serialNumber;
            }
            return res;
        }
        case SET_PROOF_OF_PURCHASE: return {...state, proofOfPurchase: action.proofOfPurchase}
        case SET_PROOF_OF_PURCHASE_LOGS: return {...state, proofOfPurchaseLogs: action.proofOfPurchaseLogs}
        case SET_IS_NEW_MEMBER: return {...state, isNewMember: action.isNewMember}
        case SET_NESPRESSO_MEMBER: return {...state, nespressoMember: action.nespressoMember}
        case SET_NESPRESSO_MEMBER_EXIST: return {...state, nespressoMemberExist: action.nespressoMemberExist}
        case SET_PROMOTION:
            return {
                ...state,
                promotion: action.promotion,
                products: initialState.products,
                categories: initialState.categories,
                cart: {
                    items: [],
                    sum: '',
                },
                easyOrderSetup: initialState.easyOrderSetup,
            }
        case SET_ONLY_PROMOTION: return {...state, promotion: action.promotion}

        case SET_CUSTOMER: return {...state, customer: action.customer}
        case SET_OPT_IN: return {...state, optIn: action.optIn}

        case SET_PRODUCTS: return {...state, products: action.products}
        case SET_CATEGORIES:
            return {...state, categories: action.categories, loadingCategories: action.loadingCategories}
        case UPDATE_CART: return {...state, cart: action.cart}

        case SET_PAYMENT_METHOD: return {...state, paymentMethod: action.paymentMethod}
        case SET_PAID: return {...state, paid: action.paid}
        case SET_OCR_LOGS: return {...state, ocrLogs: action.ocrLogs}
        case SET_ORDER: return {...state, order: action.order}

        case SET_COFFEE_LINES:
            return {...state, coffeeLines: action.coffeeLines}
        case SET_ADDRESSES:
            return {...state, addresses: action.addresses}

        case SET_EASY_ORDER_SETUP: return {...state, easyOrderSetup: action.easyOrderSetup}

        case SET_LOADING_CATEGORIES: return {...state, loadingCategories: action.loadingCategories}
        case SET_CAN_REGISTER_RESPONSE: return {...state, canRegisterResponse: action.canRegisterResponse}
        case SET_COOKIES_ACCEPTED: return {...state, cookiesAccepted: action.cookiesAccepted}
        case SET_ORDER_ACCOUNT:
            return {
                ...state,
                orderAccount: action.orderAccount,
                orderAccountLinked: action.orderAccountLinked,
                ecapiAccountCreated: action.ecapiAccountCreated,
            }
        case SET_CART_QUOTATION_DATA:
            return {...state, cartQuotationData: action.cartQuotationData}
        case SET_FREQUENCIES: return {...state, frequencies: action.frequencies}

        case SET_LAST_VISIT: return {...state, lastVisit: action.lastVisit}

        case CLEAR_STORE:
            return {
                ...initialState,
                cart: {
                    items: [],
                    sum: '',
                },
            }

        default: return state
    }
}


export const setUuid = (uuid) => ({type: SET_UUID, uuid})
export const setRecaptchaToken = (recaptchaToken) => ({type: SET_RECAPTCHA_TOKEN, recaptchaToken})

export const setPromoCode = (promoCode) => ({type: SET_PROMO_CODE, promoCode})

export const setMachine = (machine, testOrder = null, serialNumber = null) => ({
    type: SET_MACHINE,
    machine,
    testOrder,
    serialNumber
})
export const setIsNewMember = (isNewMember) => ({type: SET_IS_NEW_MEMBER, isNewMember})
export const setNespressoMember = (nespressoMember) => ({type: SET_NESPRESSO_MEMBER, nespressoMember})
export const setNespressoMemberExist = (nespressoMemberExist) => ({type: SET_NESPRESSO_MEMBER_EXIST, nespressoMemberExist})
export const setProofOfPurchase = (proofOfPurchase) => ({type: SET_PROOF_OF_PURCHASE, proofOfPurchase})
export const setProofOfPurchaseLogs = (proofOfPurchaseLogs) => ({type: SET_PROOF_OF_PURCHASE_LOGS, proofOfPurchaseLogs})
export const setPromotion = (promotion) => ({type: SET_PROMOTION, promotion})
export const setOnlyPromotion = (promotion) => ({type: SET_ONLY_PROMOTION, promotion})
export const setCustomer = (customer) => ({type: SET_CUSTOMER, customer})
export const setOptIn = (optIn) => ({type: SET_OPT_IN, optIn})

export const setProducts = (products) => ({type: SET_PRODUCTS, products})
export const setCategories = (categories) => ({type: SET_CATEGORIES, categories})
export const updateCart = (cart) => ({type: UPDATE_CART, cart})

export const setPaymentMethod = (paymentMethod) => ({type: SET_PAYMENT_METHOD, paymentMethod})

export const setPaid = (paid) => ({type: SET_PAID, paid})
export const setOrder = (order) => ({type: SET_ORDER, order})
export const setOcrLogs = (ocrLogs) => ({type: SET_OCR_LOGS, ocrLogs})

export const setCoffeeLines = (coffeeLines) => ({type: SET_COFFEE_LINES, coffeeLines})
export const setAddresses = (addresses) => ({type: SET_ADDRESSES, addresses})

export const setEasyOrderSetup = (easyOrderSetup) => ({type: SET_EASY_ORDER_SETUP, easyOrderSetup})
export const setLoadingCategories = (loadingCategories) => ({type: SET_LOADING_CATEGORIES, loadingCategories})
export const setCanRegisterResponse = (canRegisterResponse) => ({type: SET_CAN_REGISTER_RESPONSE, canRegisterResponse})
export const setOrderAccount = (orderAccount, orderAccountLinked, ecapiAccountCreated = false) => ({
    type: SET_ORDER_ACCOUNT,
    orderAccount,
    orderAccountLinked,
    ecapiAccountCreated,
})
export const setCookiesAccepted = (cookiesAccepted) => ({type: SET_COOKIES_ACCEPTED, cookiesAccepted})

export const setLastVisit = (lastVisit) => ({type: SET_LAST_VISIT, lastVisit})
export const setCartQuotationData = (cartQuotationData) => ({type: SET_CART_QUOTATION_DATA, cartQuotationData})
export const setFrequencies = (frequencies) => ({type: SET_FREQUENCIES, frequencies})

export const clearStore = () => ({type: CLEAR_STORE})



export default reducer;
