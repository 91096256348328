import React from "react";
import {useModuleContext, useSeoPage} from "../../../hooks";
import {Config, Routes} from "../../../utils/_index";
import * as orderActions from "../../../actions/OrderActions";
import {OrderSummary} from "../../../components/Summary";

export default function SummaryScreen() {
    useSeoPage({title: 'etrade.summary.title'})
    const {store} = useModuleContext()

    const payloadFn = ({orderData, paymentMethod, deliveryMethod, pickupPoint}) => {
        return {
            customer: {
                ...orderData.getData(),
                ...orderData.getPaymentMethodData(paymentMethod),
                ...orderData.getDeliveryMethodData(deliveryMethod, pickupPoint),
                promotion_type: Config.PROMO_TYPE.SUBSCRIPTION_MACHINE,
                id_etrade: store.data.id_etrade,
                id_etrade_plan: store.data.id_etrade_plan,
                etrade_shop_redirect: store.callbackParam,
                id_shop: store.data.id_shop,
                id_machine: store.data.machine.id_machine,
                id_reopened_order: store.reopenData?.id_order,
            },
            cart: store.cart,
            ...orderData.getCartQuotationData(),
        }
    }

    return (
        <OrderSummary
            homeRoute={Routes.MACHINE_REGISTRATION_REGISTRATION}
            orderFinalizedRoute={Routes.ETRADE_ORDER_FINALIZED}
            action={orderActions.finalizeETrade}
            payloadFn={payloadFn}
        />
    )
}
