import {TextInputController} from "../controllers";
import React from "react";
import {useTranslation} from "../../hooks";

export default function AddressLine1Input(props) {
    const {t} = useTranslation()

    return (
        <TextInputController
            name="addressLine1"
            label={t('address_line_1')}
            {...props}
        />
    )
}
