import {TextInputController} from "../controllers";
import React from "react";
import {useFieldFormat, useTranslation} from "../../hooks";

export default function EmailInput(props) {
    const {t} = useTranslation()
    const fieldFormat = useFieldFormat()
    const format = props.format ?? fieldFormat.formats.email

    const onCopy = (e) => {
        if (!format.copyEnabled) {
            e.preventDefault()
        }
    }

    const onPaste = (e) => {
        if (!format.pasteEnabled) {
            e.preventDefault()
        }
    }

    return (
        <TextInputController
            name="email"
            type="email"
            label={t('email')}
            {...props}
            options={{
                inputProps: {
                    onCopy,
                    onPaste,
                    ...props.options?.inputProps,
                },
                ...props.options
            }}
        />
    )
}
