import {Button} from "../../../components/buttons/_index";
import {useTranslation} from "../../../misc/Hooks";
import {useHistory} from "react-router-dom";
import {AuthForm, IsNewMemberForm} from "../../../forms";
import React from "react";
import {useModuleContext, useModuleStepsNavigation, useNespressoMember} from "../../../hooks";


export default function AuthorizationScreen(props) {
    const t = useTranslation()
    const {store, dispatch, reducer} = useModuleContext()
    const history = useHistory()
    const {loginMutation, error, setError, nespressoMember, storeLoggedUser} = useNespressoMember()
    const stepsNavigation = useModuleStepsNavigation()

    const handleSubmitIsNewMember = (isNewMember) => {
        if (isNewMember !== store.isNewMember) {
            dispatch(reducer.updateCart({items: [], sum: ''}));
            dispatch(reducer.setOrderAccount(null, null));
            dispatch(reducer.setCustomer(null))
        }
        dispatch(reducer.setIsNewMember(isNewMember));
        if (isNewMember === true) {
            dispatch(reducer.setCustomer(null))
            history.push(stepsNavigation.next());
        }
    }

    const handleLogin = async (data) => {
        const payload = {
            login: data.email,
            password: data.password,
            recaptchaToken: data.recaptchaToken,
        }

        loginMutation.mutateAsync(payload)
            .then((data) => {
                storeLoggedUser(data, payload)
                stepsNavigation.navToNext()
            })
    }

    return (
        <div>
            <div className="grid">
                <div>
                    <IsNewMemberForm
                        text={t('stay_home.authorization.text')}
                        onSubmit={handleSubmitIsNewMember}
                    />

                    {store.isNewMember === false && (
                        <>
                            {nespressoMember ? (
                                <Button
                                    href={stepsNavigation.next()}
                                    label={t('continue')}
                                />
                            ) : (
                                <AuthForm
                                    state={{
                                        error,
                                        isLoading: loginMutation.isLoading,
                                    }}
                                    onSubmit={handleLogin}
                                />
                            )}
                        </>
                    )}

                    {store.isNewMember === true && (
                        <Button
                            href={stepsNavigation.next()}
                            label={t('continue')}
                        />
                    )}
                </div>
                <div/>
            </div>
        </div>
    )
}
