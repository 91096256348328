import {getSummaryOrder, isEnabledByMarket} from "../../utils/MarketConfig";
import React, {Fragment, useMemo} from "react";
import {useCountryConfig, useMarketConfig, usePriceFormatter} from "../../misc/Hooks";
import {useReseller, useTranslation} from "../../hooks";
import {Config} from "../../utils/_index";

export default function SummaryPriceComponent({
    cartValueByCustomVat,
    cartValueRaw,
    showNetPrice,
    giftNetValue,
    giftValue,
    giftValueWithoutPromo,
    deliveryValue,
    deliveryNetValue,
    promoPriceBenefitValue,
    promotion,
    moduleName = 'shop',
    quotationCartValues = null,
    paymentMethod,
    memberCredit,
    plan,
    getCustomSummaryOrder,
    ...props
}) {
    if (!cartValueByCustomVat)
        cartValueByCustomVat = cartValueRaw
    const marketConfig = useMarketConfig();
    const countryConfig = useCountryConfig()
    const {t} = useTranslation();
    const {formatPrice} = usePriceFormatter();
    const memberCreditVisible = useMemo(() => {
        if (!memberCredit)
            return false
        if (memberCredit.used <= 0)
            return false
        if (paymentMethod?.payment_method_integration_type === 'xml')
            return false
        return getTotal() > 0;
    }, [memberCredit, paymentMethod, cartValueRaw])

    const reseller = useReseller();
    const deliveryInfoText = !!reseller.user ? t('ev_delivery_payment.delivery_info_reseller') || t('ev_delivery_payment.delivery_info') : t('ev_delivery_payment.delivery_info');

    let plan_price = plan?.product_plan_price?.gross ?? 0
    let plan_machine_price = Number(plan?.product_plan_machine_price ?? 0) - Number(plan?.product_plan_machine_refund ?? 0)


    let subtotal = formatPrice(cartValueByCustomVat?.gross, countryConfig.currency_to_fixed_product_summary)
    let plan_subtotal = cartValueByCustomVat?.gross - plan_price - plan_machine_price
    let benefit = formatPrice(getBenefit(), countryConfig.currency_to_fixed_product_summary)
    let tax = formatPrice(cartValueByCustomVat?.vat, countryConfig.currency_to_fixed_product_summary)
    let total = formatPrice(getTotal(), countryConfig.currency_to_fixed_product_summary)
    let subtotal_after_benefit = formatPrice(cartValueByCustomVat?.gross - (showNetPrice ? Math.abs(giftNetValue) : Math.abs(giftValue)), countryConfig.currency_to_fixed_product_summary)


    if (moduleName === 'noMachineShop') {
        benefit = formatPrice(showNetPrice ? Math.abs(giftNetValue) : Math.abs(giftValue), countryConfig.currency_to_fixed_product_summary)
    }

    if (![Config.PROMO_TYPE.EASY_ORDER].includes(promotion?.promotion_type) && quotationCartValues) {
        subtotal = formatPrice(quotationCartValues.subtotal, countryConfig.currency_to_fixed_product_summary)
        benefit = formatPrice(getBenefit(), countryConfig.currency_to_fixed_product_summary)
        tax = formatPrice(quotationCartValues.tax, countryConfig.currency_to_fixed_product_summary)
        total = formatPrice(getTotal(), countryConfig.currency_to_fixed_product_summary)
    }

    function getBenefit() {
        if (![Config.PROMO_TYPE.EASY_ORDER].includes(promotion?.promotion_type) && quotationCartValues) {
            return Math.abs(quotationCartValues.benefit)
        }
        let value = showNetPrice ? Math.abs(giftNetValue) : Math.abs(giftValue)
        // value += Math.abs(promoPriceBenefitValue?.gross)
        return value
    }

    function getTotal() {
        if (![Config.PROMO_TYPE.EASY_ORDER].includes(promotion?.promotion_type) && quotationCartValues) {
            return quotationCartValues.subtotal + quotationCartValues.benefit
        }
        return cartValueByCustomVat?.gross + deliveryValue + giftValue
    }

    return (
        <div className="summary">
            <h3 className="evDP__title">{t('ev_delivery_payment.totals')}</h3>
            <div className="summary__body">
                {(typeof getCustomSummaryOrder === "function" ? getCustomSummaryOrder() : getSummaryOrder()).map((summaryItem, idx) => (
                    <Fragment key={idx}>
                        {(!marketConfig.hide_summary_subtotal_value && summaryItem === 'subtotal') && (
                            <div className="summary__row">
                                <h5 className="summary__name">{t('ev_delivery_payment.subtotal')}</h5>
                                <p className="summary__value">{subtotal}</p>
                            </div>
                        )}
                        {(!marketConfig.hide_summary_subtotal_value && summaryItem === 'plan_subtotal' && !!plan_subtotal) && (
                            <div className="summary__row">
                                <h5 className="summary__name">{t('ev_delivery_payment.subtotal')}</h5>
                                <p className="summary__value">{formatPrice(plan_subtotal)}</p>
                            </div>
                        )}
                        {(!marketConfig.hide_summary_benefit_value && (summaryItem === 'benefit' || (summaryItem === 'plan_benefit' && !!getBenefit()))) && (
                            <div className="summary__row">
                                <h5 className="summary__name --bold">{t('ev_delivery_payment.benefit')}</h5>
                                <p className="summary__value --bold">{benefit}</p>
                            </div>
                        )}
                        {summaryItem === 'subtotal_after_benefit' && (
                            <div className="summary__row">
                                <h5 className="summary__name">{t('ev_delivery_payment.subtotal_after_benefit')}</h5>
                                <p className="summary__value">{subtotal_after_benefit}</p>
                            </div>
                        )}
                        {(!marketConfig.hide_summary_tax_value && summaryItem === 'tax') && (
                            <div className="summary__row">
                                <h5 className="summary__name">{t('ev_delivery_payment.tax')}</h5>
                                <p className="summary__value">{tax}</p>
                            </div>
                        )}
                        {(summaryItem === 'plan_price' && !!plan_price) && (
                            <div className="summary__row">
                                <h5 className="summary__name">{t('stay_home.summary.plan_price')}</h5>
                                <p className="summary__value">{formatPrice(plan_price)}</p>
                            </div>
                        )}
                        {(summaryItem === 'machine_price' && !!plan_machine_price) && (
                            <div className="summary__row">
                                <h5 className="summary__name">{t('stay_home.summary.machine_price')}</h5>
                                <p className="summary__value">{formatPrice(plan_machine_price)}</p>
                            </div>
                        )}
                        {(!marketConfig.hide_summary_delivery_value && summaryItem === 'delivery') && (
                            <div className="summary__row --mb4">
                                <h5 className="summary__name">{t('ev_delivery_payment.delivery')}</h5>
                                <p className="summary__value">{deliveryValue > 0 ? formatPrice(showNetPrice ? deliveryNetValue : deliveryValue) : t('free')}</p>
                            </div>
                        )}
                        {(!!deliveryInfoText && summaryItem === 'delivery_info' && !!promotion?.promotion_show_free_delivery_information) && (
                            <p
                                className="par-4 --mb4"
                                style={{textAlign: 'right'}}
                                key={idx}
                            >
                                {deliveryInfoText}
                            </p>
                        )}
                        {summaryItem === 'total' && (
                            <div className="summary__row">
                                <h5 className="summary__name">{t('ev_delivery_payment.total')}</h5>
                                <p className={`summary__value --success --bold`}>
                                    {memberCreditVisible ? (memberCredit.grandTotal > 0 ? formatPrice(memberCredit.grandTotal - memberCredit.used) : 0) : total}
                                </p>
                            </div>
                        )}
                        {summaryItem === 'summary_info' && (
                            <Fragment key={idx}>
                                {moduleName === 'stayHome' ? (
                                    <div className="evDP__summaryInfo">
                                        <p className="par-4">{t('etrade.summary.total_info')}</p>
                                        <p className="par-4">{t('etrade.summary.summary_info')}</p>
                                    </div>
                                ) : moduleName !== 'subscription' ? (
                                    <div className="evDP__summaryInfo">
                                        <p className="par-4">{!!promotion?.promotion_coffee_required && t('ev_delivery_payment.total_info')}</p>
                                        <p className="par-4"
                                           dangerouslySetInnerHTML={{__html: t('ev_delivery_payment.summary_info')}}/>
                                    </div>
                                ) : (
                                    <div className="evDP__summaryInfo">
                                        <p className="par-4">{t('ev_delivery_payment.total_info')}</p>
                                        {!isEnabledByMarket('hideTotalPriceInfoMachineSubscription') && (
                                            <p className="par-4"
                                               dangerouslySetInnerHTML={{__html: t('ev_delivery_payment.summary_info')}}/>
                                        )}
                                    </div>
                                )}
                            </Fragment>
                        )}
                        {summaryItem === 'memberCreditAvailable' && !!memberCreditVisible && (
                            <div className="summary__row">
                                <h5 className="summary__name">{t('member_credit.available_credit')}</h5>
                                <p className="summary__value">{formatPrice(memberCredit.used + memberCredit.remaining)}</p>
                            </div>
                        )}
                        {summaryItem === 'memberCredit' && !!memberCreditVisible && (
                            <div>
                                <div className="--mb4"/>
                                <p className="par-2">{t('member_credit.text')}</p>
                                <div className="summary__row">
                                    <h5 className="summary__name">{t('member_credit.remaining_after')}</h5>
                                    <p className="summary__value">{formatPrice(memberCredit.remaining)}</p>
                                </div>
                            </div>
                        )}
                    </Fragment>
                ))}
            </div>
        </div>
    )
}
