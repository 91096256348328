import {HiddenInput, TextInput} from "../inputs";
import {useController, useFormContext} from "react-hook-form";
import {useFormHelper} from "../../hooks";
import {useFormFragmentContext} from "../hooks";
import {useMemo} from "react";

/**
 * HiddenInputController
 * @param name
 * @param defaultValue
 * @returns {JSX.Element}
 * @constructor
 */
export default function HiddenInputController({
    name: _name,
    defaultValue = '',
    inputProps,
}) {
    const formHelper = useFormHelper()
    const name = useMemo(
        () => formHelper.formFragmentContext.getInputName(_name),
        [_name, formHelper.formFragmentContext]
    )
    const {
        field: {ref, ...controllerInputProps},
    } = useController({
        name,
        control: formHelper.formMethods.control,
        defaultValue,
    })

    return (
        <HiddenInput
            {...controllerInputProps}
            {...inputProps}
            inputRef={ref}
        />
    )
}
