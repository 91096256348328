import React, {Fragment, useEffect, useMemo} from 'react';
import {Link} from "react-router-dom";
import {ImSpinner8} from "react-icons/im";
import {BsQuestion} from "react-icons/bs";
import {makeClassName} from "../../helpers";
import ReactTooltip from "react-tooltip";

export default ({
    label,
    type,
    size,
    active,
    href,
    disabled,
    style,
    onClick,
    isLoading,
    tooltip,
    btnType,
    tooltipStyle,
    target = '_self',
    ...props
}) => {

    const classNameType = useMemo(() => {
        switch (type) {
            case "primary-outline":
                return '-primary -outline';
            case "grey":
                return '-grey';
            case "secondary":
                return '-secondary';
            case "error":
                return '-error';
            default:
                return '-primary';
        }
    }, [type])

    const spinnerSize = useMemo(() => {
        switch (size) {
            case "sm":
                return '.5rem'
            case "md":
                return '.65rem'
            case "lg":
                return '.75rem'
            default:
                return '1rem'
        }
    }, [size])

    const className = useMemo(() =>
        makeClassName(`btn`, {
            [classNameType]: classNameType,
            '-active': active,
            '-disabled': !!disabled,
            'btnLink': !!href,
            [`-${size}`]: size
        }), [type, size, active, disabled, classNameType, href])

    const ButtonBody = (
        <Fragment>
            {!!tooltip && <span className="btn__tooltipIcon"><BsQuestion/></span>}
            {label ? <span dangerouslySetInnerHTML={{__html: label}}/> : props.children}
            {isLoading && <ImSpinner8 className="spinner" style={{fontSize: spinnerSize, marginLeft: '.75rem'}}/>}
        </Fragment>
    )

    const Button = (
        <button
            type={btnType ?? 'button'}
            className={className}
            style={style}
            onClick={!disabled ? onClick : undefined}
        >
            {ButtonBody}
        </button>
    )

    useEffect(() => {
        ReactTooltip.rebuild();
    })

    return (href && !onClick) ? (
        <Fragment>
            {!!disabled ? (
                <span
                    className={className}
                    style={{...style, ...tooltipStyle}}
                    data-tip={tooltip}
                >
                    {ButtonBody}
                </span>
            ) : (
                <Fragment>
                    {href.startsWith('http') ? (
                        <a
                            href={href}
                            className={className}
                            style={{...style, ...tooltipStyle}}
                            data-tip={tooltip}
                            target={target}
                        >
                            {ButtonBody}
                        </a>
                    ) : (
                        <Link
                            to={href}
                            className={className}
                            style={{...style, ...tooltipStyle}}
                            data-tip={tooltip}
                            target={target}
                        >
                            {ButtonBody}
                        </Link>
                    )}
                </Fragment>
            )}
        </Fragment>
    ) : tooltip ? (
        <span style={tooltipStyle} data-tip={tooltip} className="btnTooltip">
            {Button}
        </span>
    ) : (
        Button
    )
}
