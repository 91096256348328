import {DatePicker, Recaptcha} from "../inputs";
import {useController} from "react-hook-form";
import {useFormHelper} from "../../hooks";
import {useMemo} from "react";

/**
 * RecaptchaController
 * @param name
 * @param label
 * @param rules
 * @returns {JSX.Element}
 * @constructor
 */
export default function RecaptchaController({
    name: _name = 'recaptchaToken',
    rules: _rules,
}) {
    const formHelper = useFormHelper()
    const name = useMemo(
        () => formHelper.formFragmentContext.getInputName(_name),
        [_name, formHelper.formFragmentContext]
    )
    const rules = useMemo(
        () => formHelper.getRules(_rules ?? _name),
        [formHelper, _rules, _name]
    )
    const {
        field: {ref, ...controllerInputProps},
        fieldState,
    } = useController({
        name,
        control: formHelper.formMethods.control,
        rules: rules,
    })

    return (
        <Recaptcha
            {...controllerInputProps}
            fieldState={fieldState}
        />
    )
}
