import {useState} from "react";
import {useResellerActions} from "../../../actions/useResellerActions";
import {ApiException} from "../../../errors";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useHistory, useParams} from "react-router-dom";
import {Routes} from "../../../utils/_index";
import {useNavigation} from "../../../misc/Hooks";

export default function useResellerResetPassword() {
    const resellerActions = useResellerActions();
    const params = useParams();
    const history = useHistory();
    const navigation = useNavigation();

    const [tokenIsValid, setTokenIsValid] = useState(false);
    const [message, setMessage] = useState({
        type: 'error',
        message: ''
    });

    const emailMutation = useMutation(
        async (payload) => {
            const {data} = await resellerActions.forgotPassword(payload)
            if (data.status === 'success') return data

            throw new ApiException(data.messages[0], data)
        },
        {
            onMutate: () => setMessage({type: '', message: ''}),
            onSuccess: ({data}) => setMessage({type: 'success', message: 'modal.forgot_password_email_sent_reseller.text'}),
            onError: ({error}) => setMessage({type: 'error', message: `error.${error}`})
        }
    )

    const verificationTokenQuery = useQuery(
        ['verificationToken', params?.token],
        async () => {
            const {data} = await resellerActions.isForgotPasswordTokenValid(params?.token)
            if (data.status === 'success') return data
            throw new ApiException(data.messages[0], data)
        },
        {
            enabled: false,
            onSuccess: () => setTokenIsValid(true),
            onError: ({error}) => setMessage({
                type: 'warn',
                message: `modal.forgot_password_token_expired_reseller.title`
            })
        }
    )

    const resetPasswordMutation = useMutation(
        async ({password, rePassword}) => {
            const {data} = await resellerActions.changePassword(params?.token, {password, repassword: rePassword})
            if (data.status === 'success') return data

            throw new ApiException(data.messages[0], data)
        },
        {
            onMutate: () => setMessage({type: '', message: ''}),
            onSuccess: () => {
                setMessage({type: 'success', message: 'modal.forgot_password_password_changed_reseller.text'})
                setTimeout(() => history.push(navigation(Routes.V2_RESELLER_AUTH)), 2000)
            },
            onError: ({error}) => setMessage({type: 'error', message: `error.${error}`})
        }
    )

    return {
        message,
        emailMutation,
        resetPasswordMutation,
        tokenIsValid,
        verificationInProgress: verificationTokenQuery.isLoading,
        verificationTokenQuery,
        isLoading: emailMutation.isLoading || resetPasswordMutation.isLoading,
    }
}