import {FormGroup, FormRow} from "../../../forms/components";
import {TextInputController} from "../../../forms/controllers";
import {useFormHelper, useTranslation} from "../../../hooks";
import {useFormRulesBuilder} from "../../../forms/hooks";

export default function SearchNespressoMemberType1Fragment() {
    const {t} = useTranslation()
    const {formMethods} = useFormHelper()
    const formRules = useFormRulesBuilder({
        memberNumber: b => b.required(),
        lastname: b => b.required(),
    }, formMethods)

    return (
        <FormRow>
            <FormGroup>
                <TextInputController
                    name="memberNumber"
                    label={t('member_number')}
                    rules={formRules.values.memberNumber}
                />
            </FormGroup>
            <FormGroup>
                <TextInputController
                    name="lastname"
                    label={t('lastname')}
                    rules={formRules.values.lastname}
                />
            </FormGroup>
        </FormRow>
    )
}
