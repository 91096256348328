const SET_DATA = 'shopReopen/setData';
const SET_UUID = 'shopReopen/setUuid';
const SET_RECAPTCHA_TOKEN = 'shopReopen/setRecaptchaToken';

const SET_PROMO_CODE = 'shopReopen/setPromoCode';
const SET_MACHINE = 'shopReopen/setMachine';
const SET_MACHINE_RESPONSE = 'machineRegistration/setMachineResponse';
const SET_PROOF_OF_PURCHASE = 'shopReopen/setProofOfPurchase';
const SET_PROOF_OF_PURCHASE_LOGS = 'shopReopen/setProofOfPurchaseLogs';
const SET_IS_NEW_MEMBER = 'shopReopen/setIsNewMember';
const SET_NESPRESSO_MEMBER = 'shopReopen/setNespressoMember';
const SET_NESPRESSO_MEMBER_EXIST = 'shopReopen/setNespressoMemberExist';
const SET_PROMOTION = 'shopReopen/setPromotion';
const SET_ONLY_PROMOTION = 'shopReopen/setOnlyPromotion';
const SET_OPEN_PROMOTION_MODALS = 'shopReopen/setOpenPromotionModals';
const SET_CUSTOMER = 'shopReopen/setCustomer';
const SET_OPT_IN = 'shopReopen/setOptIn';

const SET_PROMOTIONS = 'shopReopen/setPromotions';
const SET_PRODUCTS = 'shopReopen/setProducts';
const SET_CATEGORIES = 'shopReopen/setCategories';
const UPDATE_CART = 'shopReopen/updateCart';

const SET_EXTRA_LINE_PRODUCTS = 'shopReopen/setExtraLineProducts';
const SET_EXTRA_LINE_CATEGORIES = 'shopReopen/setExtraLineCategories';

const SET_PAID = 'shopReopen/setPaid';
const SET_OCR_LOGS = 'shopReopen/setOcrLogs';
const SET_ORDER = 'shopReopen/setOrder';

const SET_COFFEE_LINES = 'shopReopen/setCoffeeLines';
const SET_ADDRESSES = 'shopReopen/setAddresses';

const SET_PAYMENT_METHOD = 'shopReopen/setPaymentMethod';
const SET_DELIVERY_METHOD = 'shopReopen/setDeliveryMethod';
const SET_EASY_ORDER_SETUP = 'shopReopen/setEasyOrderSetup';

const SET_LOADING_CATEGORIES = 'shopReopen/setLoadingCategories';
const SET_CAN_REGISTER_RESPONSE = 'shopReopen/setCanRegisterResponse';
const SET_COOKIES_ACCEPTED = 'shopReopen/setCookiesAccepted';

const SET_ORDER_ACCOUNT = 'shopReopen/setOrderAccount';
const SET_LAST_VISIT = 'shopReopen/setLastVisit';
const SET_CART_QUOTATION_DATA = 'shopReopen/setCartQuotationData';
const SET_FREQUENCIES = 'shopReopen/setFrequencies';
const SET_SHOPS = 'shopReopen/setShops';
const SET_CART_VISIBLE = 'shopReopen/setCartVisible';
const SET_PERSONALIZATION = 'shopReopen/setPersonalization';

const CLEAR_STORE = 'shopReopen/clearStore';


const initialState = {
    data: null,
    uuid: '',

    testOrder: false,
    serialNumber: null,

    promoCode: null,
    machine: null,
    machineResponse: null,
    proofOfPurchase: null,
    proofOfPurchaseLogs: null,
    proofOfPurchaseHistory: null,
    isNewMember: false,
    nespressoMember: null,
    nespressoMemberExist: false,
    promotion: null,
    customer: null,
    optIn: null,

    promotions: [],
    categories: [],
    products: [],

    extraLineCategories: [],
    extraLineProducts: [],

    cart: {
        items: [],
        sum: '',
    },

    paymentMethod: null,
    deliveryMethod: null,
    order: null,
    paid: false,

    coffeeLines: null,
    addresses: [],

    recaptchaToken: null,

    easyOrderSetup: null,

    ocrLogs: [],

    loadingCategories: false,
    canRegisterResponse: null,

    cookiesAccepted: undefined,

    orderAccount: null,
    orderAccountLinked: false,
    ecapiAccountCreated: false,
    cartQuotationData: null,

    lastVisit: null,

    frequencies: [],
    shops: [],
    cartVisible: false,
    openPromotionModals: [],
    personalization: null,

};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_DATA: return {...state, data: action.data}
        case SET_UUID: return {...state, uuid: action.uuid}
        case SET_RECAPTCHA_TOKEN: return {...state, recaptchaToken: action.recaptchaToken}

        case SET_PROMO_CODE: return {...state, promoCode: action.promoCode}

        case SET_MACHINE: {
            const res = {
                ...state,
                machine: action.machine,
            }
            if(action.testOrder) {
                res.testOrder = action.testOrder;
            }
            if(action.serialNumber) {
                res.serialNumber = action.serialNumber;
            }
            return res;
        }
        case SET_PROOF_OF_PURCHASE: return {...state, proofOfPurchase: action.proofOfPurchase}
        case SET_PROOF_OF_PURCHASE_LOGS: return {...state, proofOfPurchaseLogs: action.proofOfPurchaseLogs}
        case SET_IS_NEW_MEMBER: return {...state, isNewMember: action.isNewMember}
        case SET_NESPRESSO_MEMBER: return {...state, nespressoMember: action.nespressoMember}
        case SET_NESPRESSO_MEMBER_EXIST: return {...state, nespressoMemberExist: action.nespressoMemberExist}
        case SET_PROMOTION:
            return {
                ...state,
                promotion: action.promotion,
                products: initialState.products,
                categories: initialState.categories,
                cart: {
                    items: [],
                    sum: '',
                },
                easyOrderSetup: initialState.easyOrderSetup,
                openPromotionModals: []
            }
        case SET_ONLY_PROMOTION: return {...state, promotion: action.promotion}
        case SET_OPEN_PROMOTION_MODALS: {
            return {
                ...state,
                openPromotionModals: action.openPromotionModals
            }
        }
        case SET_CUSTOMER: return {...state, customer: action.customer}
        case SET_OPT_IN: return {...state, optIn: action.optIn}

        case SET_PROMOTIONS: return {...state, promotions: action.promotions}
        case SET_PRODUCTS: return {...state, products: action.products}
        case SET_CATEGORIES:
            return {...state, categories: action.categories, loadingCategories: action.loadingCategories}
        case UPDATE_CART: return {...state, cart: action.cart}

        case SET_EXTRA_LINE_CATEGORIES: return {...state, extraLineCategories: action.extraLineCategories}
        case SET_EXTRA_LINE_PRODUCTS: return {...state, extraLineProducts: action.extraLineProducts}

        case SET_PAYMENT_METHOD: return {...state, paymentMethod: action.paymentMethod}
        case SET_DELIVERY_METHOD: return {...state, deliveryMethod: action.deliveryMethod}
        case SET_PAID: return {...state, paid: action.paid}
        case SET_OCR_LOGS: return {...state, ocrLogs: action.ocrLogs}
        case SET_ORDER: return {...state, order: action.order}

        case SET_COFFEE_LINES:
            return {...state, coffeeLines: action.coffeeLines}
        case SET_ADDRESSES:
            return {...state, addresses: action.addresses}

        case SET_EASY_ORDER_SETUP: return {...state, easyOrderSetup: action.easyOrderSetup}

        case SET_LOADING_CATEGORIES: return {...state, loadingCategories: action.loadingCategories}
        case SET_CAN_REGISTER_RESPONSE: return {...state, canRegisterResponse: action.canRegisterResponse}
        case SET_COOKIES_ACCEPTED: return {...state, cookiesAccepted: action.cookiesAccepted}
        case SET_ORDER_ACCOUNT:
            return {
                ...state,
                orderAccount: action.orderAccount,
                orderAccountLinked: action.orderAccountLinked,
                ecapiAccountCreated: action.ecapiAccountCreated,
            }
        case SET_CART_QUOTATION_DATA:
            return {...state, cartQuotationData: action.cartQuotationData}
        case SET_FREQUENCIES: return {...state, frequencies: action.frequencies}
        case SET_SHOPS: return {...state, shops: action.shops}

        case SET_LAST_VISIT: return {...state, lastVisit: action.lastVisit}
        case SET_CART_VISIBLE: return {...state, cartVisible: action.cartVisible}
        case SET_MACHINE_RESPONSE:return {...state, machineResponse: action.machineResponse}
        case SET_PERSONALIZATION: return {...state, personalization: action.personalization}

        case CLEAR_STORE:
            return {
                ...initialState,
                cart: {
                    items: [],
                    sum: '',
                },
            }

        default: return state
    }
}


export const setData = (data) => ({type: SET_DATA, data})
export const setUuid = (uuid) => ({type: SET_UUID, uuid})
export const setRecaptchaToken = (recaptchaToken) => ({type: SET_RECAPTCHA_TOKEN, recaptchaToken})

export const setPromoCode = (promoCode) => ({type: SET_PROMO_CODE, promoCode})

export const setMachine = (machine, testOrder = null, serialNumber = null) => ({
    type: SET_MACHINE,
    machine,
    testOrder,
    serialNumber
})
export const setIsNewMember = (isNewMember) => ({type: SET_IS_NEW_MEMBER, isNewMember})
export const setNespressoMember = (nespressoMember) => ({type: SET_NESPRESSO_MEMBER, nespressoMember})
export const setNespressoMemberExist = (nespressoMemberExist) => ({type: SET_NESPRESSO_MEMBER_EXIST, nespressoMemberExist})
export const setProofOfPurchase = (proofOfPurchase) => ({type: SET_PROOF_OF_PURCHASE, proofOfPurchase})
export const setProofOfPurchaseLogs = (proofOfPurchaseLogs) => ({type: SET_PROOF_OF_PURCHASE_LOGS, proofOfPurchaseLogs})
export const setPromotion = (promotion) => ({type: SET_PROMOTION, promotion})
export const setOnlyPromotion = (promotion) => ({type: SET_ONLY_PROMOTION, promotion})
export const setOpenPromotionModals = (openPromotionModals) => ({type: SET_OPEN_PROMOTION_MODALS, openPromotionModals})
export const setCustomer = (customer) => ({type: SET_CUSTOMER, customer})
export const setOptIn = (optIn) => ({type: SET_OPT_IN, optIn})

export const setPromotions = (promotions) => ({type: SET_PROMOTIONS, promotions})
export const setProducts = (products) => ({type: SET_PRODUCTS, products})
export const setCategories = (categories, loadingCategories) => ({type: SET_CATEGORIES, categories, loadingCategories})
export const updateCart = (cart) => ({type: UPDATE_CART, cart})

export const setExtraLineProducts = (extraLineProducts) => ({type: SET_EXTRA_LINE_PRODUCTS, extraLineProducts})
export const setExtraLineCategories = (extraLineCategories) => ({type: SET_EXTRA_LINE_CATEGORIES, extraLineCategories})

export const setPaymentMethod = (paymentMethod) => ({type: SET_PAYMENT_METHOD, paymentMethod})
export const setDeliveryMethod = (deliveryMethod) => ({type: SET_DELIVERY_METHOD, deliveryMethod})

export const setPaid = (paid) => ({type: SET_PAID, paid})
export const setOrder = (order) => ({type: SET_ORDER, order})
export const setOcrLogs = (ocrLogs) => ({type: SET_OCR_LOGS, ocrLogs})

export const setCoffeeLines = (coffeeLines) => ({type: SET_COFFEE_LINES, coffeeLines})
export const setAddresses = (addresses) => ({type: SET_ADDRESSES, addresses})

export const setEasyOrderSetup = (easyOrderSetup) => ({type: SET_EASY_ORDER_SETUP, easyOrderSetup})
export const setLoadingCategories = (loadingCategories) => ({type: SET_LOADING_CATEGORIES, loadingCategories})
export const setCanRegisterResponse = (canRegisterResponse) => ({type: SET_CAN_REGISTER_RESPONSE, canRegisterResponse})
export const setOrderAccount = (orderAccount, orderAccountLinked, ecapiAccountCreated = false) => ({
    type: SET_ORDER_ACCOUNT,
    orderAccount,
    orderAccountLinked,
    ecapiAccountCreated,
})
export const setCookiesAccepted = (cookiesAccepted) => ({type: SET_COOKIES_ACCEPTED, cookiesAccepted})

export const setLastVisit = (lastVisit) => ({type: SET_LAST_VISIT, lastVisit})
export const setCartQuotationData = (cartQuotationData) => ({type: SET_CART_QUOTATION_DATA, cartQuotationData})
export const setFrequencies = (frequencies) => ({type: SET_FREQUENCIES, frequencies})
export const setShops = (shops) => ({type: SET_SHOPS, shops})
export const setCartVisible = (cartVisible) => ({type: SET_CART_VISIBLE, cartVisible})
export const setPersonalization = (personalization) => ({type: SET_PERSONALIZATION, personalization})
export const setMachineResponse = (machineResponse) => ({type: SET_MACHINE_RESPONSE, machineResponse})

export const clearStore = () => ({type: CLEAR_STORE})



export default reducer;
