import {useQuery} from "@tanstack/react-query";
import * as mainActions from '../../../../actions/MainActions';

export default function useExperiences() {
    const query = useQuery(
        ['reseller.axExperiences'],
        mainActions.getAxExperiences,
    )

    const sort = (data) => {
        return data.sort((a, b) => a.ax_experience_position < b.ax_experience_position ? -1 : 1);
    }

    return {
        query,
        data: query.isSuccess ? sort(query.data.data?.data) : [],
    }
}
