import React, {useCallback, useEffect, useState} from 'react';
import BaseModal from "./BaseModal";
import {generateQrCode} from '../../actions/MainActions'
import {Preloader} from "../../misc/_index";
import {isoDateConverterWithTime} from "../../misc/Helpers";
import {useTranslation} from "../../misc/Hooks";

export default function QrCodeModal({modalVisible, qrCodeUrl, setModalVisible, linkTime}) {
    const t = useTranslation();

    const [qrCode, setQrCode] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        if (modalVisible) {
            const postData = {
                text: qrCodeUrl,
                size: 300
            }
            generateQrCode(postData).then(r => {
                if (r.data) {
                    setQrCode(r.data)
                }
                setLoading(false)
            })
        }
    }, [modalVisible])

    const onClose = useCallback(() => setModalVisible(false), [])

    return (
        <BaseModal
            visible={modalVisible}
            onClose={onClose}
        >

            {loading ? (
                <Preloader/>
            ) : (
                <div className={"qrCode"}>
                    {!!qrCode && (
                       <>
                           <a target="_blank" className="qrCode__imageWrap" href={qrCodeUrl} dangerouslySetInnerHTML={{__html: qrCode}}/>
                           <a href={qrCodeUrl}>{qrCodeUrl}</a>
                           {!!linkTime && (
                               <span className="qrCode__expires">{t('link_expires')} <strong>{isoDateConverterWithTime(linkTime)}</strong></span>
                           )}
                       </>
                    )}
                </div>
            )}

        </BaseModal>
    );
}
