import './style.scss'
import {Button} from "../../../../../components/buttons/_index";
import {useTranslation} from "../../../../../hooks";
import React, {useState} from "react";
import BaseModal from "../../../../../components/modals/BaseModal";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import {useMutation} from "@tanstack/react-query";
import {ApiException} from "../../../../../errors";
import {useResellerActions} from "../../../../../actions/useResellerActions";
import {InfoComponent} from "../../../../../components/_index";

export default function LocalStockRequestFinalizing({idShop, item, query, productListQuery}) {
    const {t} = useTranslation()
    const {finalizePosStockRequest} = useResellerActions()

    const [modalVisible, setModalVisible] = useState(false)
    const [value, setValue] = useState(item.local_stock_request_value)

    const onClickDecrease = () => {
        if (value - item.product_package_qty >= 0)
            setValue(value - item.product_package_qty)
    }
    const onClickIncrease = () => setValue(value + item.product_package_qty)

    const finalizingMutation = useMutation({
        mutationFn: async () => {
            const {data} = await finalizePosStockRequest(
                idShop,
                {
                    id_local_stock_request: item.id_local_stock_request,
                    local_stock_request_added_value_to_stock: value
                }
            )
            if (data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        onError: (error) => {},
        onSuccess: () => {
            query.refetch()
            productListQuery.refetch()
        }
    })

    return (
        <>
            <Button
                label={t('stock_management.finalize')}
                size="md"
                onClick={() => setModalVisible(true)}
                disabled={query.isFetching}
            />

            <BaseModal
                onClose={() => setModalVisible(false)}
                visible={modalVisible}
            >
                <div className="requestFinalizingModal">
                    <h2 className="requestFinalizingModal__title">
                        {t('stock_management.request_finalize_modal_title')}
                    </h2>
                    <div className="requestFinalizingModal__tableWrapper">
                        <table className="requestFinalizingModal__table">
                            <thead>
                            <tr>
                                <th>{t('stock_management.product_title')}</th>
                                <th>{t('stock_management.quantity_ordered')}</th>
                                <th>{t('stock_management.quantity_received')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{item.product_title}</td>
                                <td>{item.local_stock_request_value}</td>
                                <td>
                                    <div className="localStockQuantity">
                                        <div className="localStockQuantity__inputWrapper">

                                            <button className="localStockQuantity__editButton" onClick={onClickDecrease}
                                                    disabled={Number(value) <= 0}>
                                                <AiOutlineMinus/>
                                            </button>

                                            <input
                                                className={`localStockQuantity__input`}
                                                type="number"
                                                value={value.toString()}
                                                readOnly
                                            />

                                            <button className="localStockQuantity__editButton"
                                                    onClick={onClickIncrease}>
                                                <AiOutlinePlus/>
                                            </button>

                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="requestFinalizingModal__button">
                        <Button
                            label={t('save')}
                            size="lg"
                            disabled={!value || finalizingMutation.isLoading || query.isFetching}
                            onClick={finalizingMutation.mutate}
                            isLoading={finalizingMutation.isLoading}
                        />
                    </div>
                    {finalizingMutation.isError && (
                        <InfoComponent type="error" message={t('stock_management.error_request_send')}/>
                    )}
                </div>
            </BaseModal>
        </>
    )
}