import {useCountry, useTranslation} from "../../hooks";
import {useEffect, useMemo, useState} from "react";
import validator from 'validator';
import {useDefaultRules} from "./index";
import {getValidatorLocales} from "../../utils/MarketConfig";
import getObjectValue from "../../helpers/getObjectValue";

export default function useFormRulesBuilder(schema, formMethods) {
    const {t} = useTranslation()
    const {data: country} = useCountry()
    const builder = useMemo(() => new FormRulesBuilder(), [])
    const values = useMemo(() => buildSchema(schema), [schema])
    const defaultRules = useDefaultRules()
    const defaults = useMemo(() => buildSchema(defaultRules), [defaultRules])

    function buildSchema(schema) {
        if (!schema)
            return null
        const values = {...schema}
        Object.keys(schema)
            .forEach(k => values[k] = schema[k](new FormRulesBuilder()).build())
        return values
    }

    function FormRulesBuilder() {
        const rules = {}
        const validateFunctions = []

        this.build = () => {
            if (validateFunctions.length)
                rules.validate = (v) => {
                    const failureFunc = validateFunctions.find(func => typeof func(v) === 'string')
                    return failureFunc ? failureFunc(v) : true
                }
            return rules
        }

        this.required = (value = true, msg = '') => {
            if (value)
                rules.required = new FormRule(true, msg || t('validation.required'))
            return this
        }

        this.minLength = (value, msg = '') => {
            rules.minLength = new FormRule(value, msg || t('validation.min_length', value))
            return this
        }

        this.maxLength = (value, msg = '') => {
            rules.maxLength = new FormRule(value, msg || t('validation.max_length', value))
            return this
        }

        this.pattern = (value, msg = '') => {
            rules.pattern = new FormRule(value, msg || t('validation.invalid'))
            return this
        }

        this.mask = (msg = '') => {
            const func = (v) => !v?.match(/_/g) ? true : msg || t('validation.invalid')
            validateFunctions.push(func)
            return this
        }

        this.validate = (callback, msg = '') => {
            const func = (v, ...args) => callback(v, ...args) ? true : msg || t('validation.invalid')
            validateFunctions.push(func)
            return this
        }

        this.email = (msg = '') => {
            return this.validate(v => v ? validator.isEmail(v) : true, msg || t('validation.type_email'))
        }

        this.alphanumeric = (msg = '', specialChars = '') => {
            function check(value, locales = []) {
                if (!value) return false
                if (typeof locales === 'string')
                    locales = [locales]
                locales.push('en-US')
                return !value.split('')
                    .filter(c => {
                        if(!specialChars)
                            return c
                        return c.replace(new RegExp(`[${specialChars}]`), '')
                    })
                    .filter(c => c.toString().trim())
                    .find(c => {
                        return locales.filter(l => !validator.isAlphanumeric(c, l, {ignore: /( )|(^$)/g})).length === locales.length
                    })
            }

            return this.validate(v => check(v, getValidatorLocales(country.country_shortcode)) || !v, msg || t('validation.invalid'))
        }

        this.password = (msg = '') => {
            const minLength = country?.country_customer_password_min_length ?? 0
            const minLowerCount = country?.country_customer_password_min_lower_count ?? 0
            const minUpperCount = country?.country_customer_password_min_upper_count ?? 0
            const minNumberCount = country?.country_customer_password_min_number_count ?? 0
            const minSpecialCount = country?.country_customer_password_min_special_count ?? 0

            if(minLength)
                this.validate(v => v.match(new RegExp(`^.{${minLength},}$`)), t('validation.password_min_length', minLength))

            if(minLowerCount)
                this.validate(v => v.match(new RegExp(`^(?=(.*[a-z]){${minLowerCount}})`)), t('validation.password_min_lower_count', minLowerCount))

            if(minUpperCount)
                this.validate(v => v.match(new RegExp(`^(?=(.*[A-Z]){${minUpperCount}})`)), t('validation.password_min_upper_count', minUpperCount))

            if(minNumberCount)
                this.validate(v => v.match(new RegExp(`^(?=(.*[0-9]){${minNumberCount}})`)), t('validation.password_min_number_count', minNumberCount))

            if(minSpecialCount)
                this.validate(v => v.match(new RegExp(`^(?=(.*?[#?!@$%^&*-]){${minSpecialCount}})`)), t('validation.password_min_special_count', minSpecialCount))

            return this
            // return this.validate(v => validator.isStrongPassword(
            //     v,
            //     {minLength: 10}
            // ), msg || t('validation.password'))
        }


        this.equals = (value, options = {sameAs: '', msg: ''}) => {
            if (value?.startsWith('$'))
                return this.validate(
                    v => validator.equals(v, formMethods?.control._getWatch(value.replace(/^\$/, ''))),
                    options.msg || t('validation.same_as', options.sameAs)
                )
            return this.validate(
                v => validator.equals(v, value),
                options.msg || t('validation.same_as', options.sameAs)
            )
        }

        this.requiredIf = (name, bool) => {
            const value = formMethods?.control._getWatch(name)
            rules.required = new FormRule(!!getObjectValue(value) === bool, t('validation.required'))
            return this
        }

        this.oneRequired = (msg = '') => {
            return this.validate(v => {
                if (v) return v.find(o => o.value)
                return false
            }, msg || t('validation.one_of_these_fields_must_be_specified'))
        }

        this.requiredOneOf = (names, msg = '') => {
            return this.validate(
                v => names.find(n => formMethods?.control._getWatch(n)),
                msg || t('validation.one_of_these_fields_must_be_specified')
            )
        }

        this.integer = (msg) => {
            return this.validate(v => v?.match(/^\d+$/), msg || t('validation.integer'))
        }

        this.lessThen = (value, msg = '') => {
            if (!value) return this.validate(v => true)
            return this.validate(v => v <= value, msg || t('validation.date_less_than'))

        }
        this.biggerThan = (value, msg = '') => {
            if (!value) return this.validate(v => true)
            return this.validate(v => v >= value, msg || t('validation.date_bigger_than'))
        }
    }

    return {
        builder,
        values: {...defaults, ...values},
        defaults,
    }
}

export function FormRule(value, message) {
    return {value, message}
}

export function FormError(type, message) {
    return {type, message}
}
