import ReactSelect from 'react-select'
import {InputLabel, InvalidFeedback} from "../components";
import {useInputValidationClassNames} from "../hooks";
import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "../../hooks";
import InputSpinner from "../components/InputSpinner";
import {TbChevronDown} from "react-icons/tb";

/**
 * Select
 * @param inputRef
 * @param fieldState
 * @param name
 * @param label
 * @param value
 * @param selectOptions
 * @param defaultInputValue
 * @param {{
 *     inputProps,
 *     isRequired: boolean,
 *     isLoading: boolean,
 *     isFetching: boolean,
 *     maxLength: number,
 *     disabled: boolean,
 *     hideNoOptionsMessage: boolean,
 *     nullable: boolean,
 *     renderIcon: function,
 *     selectPlaceholder: string,
 *     autoSelectFirstIfOneOption: boolean,
 * }} options
 * @param onChange
 * @param onInputChange
 * @param onBlur
 * @returns {JSX.Element}
 * @constructor
 */
export default function MultiSelect({
    inputRef,
    fieldState = {},
    name,
    label,
    value: _value,
    selectOptions,
    defaultInputValue = '',
    options,
    onChange,
    onInputChange,
    onBlur,
}) {
    options = {...defaultOptions, ...options}
    const value = useMemo(() => {
        if (typeof _value === 'number' || typeof _value === 'string') {
            return null
        }
        return _value
    }, [_value, selectOptions])
    const {t} = useTranslation()
    const [focused, setFocused] = useState(false)
    const [inputValue, setInputValue] = useState(defaultInputValue)
    const [selectedOptions, setSelectedOptions] = useState([])
    const {className, isValid, isInvalid} = useInputValidationClassNames({
        className: `fieldset -select`,
        value,
        fieldState,
        params: {
            '-filled': focused || inputValue || value,
            '-icons': !!options.renderIcon || options.isLoading,
        },
        deps: [inputValue, focused, options.renderIcon, options.isLoading],
    })
    const _selectOptions = useMemo(() => {
        if (options.nullable && Array.isArray(selectOptions))
            return [{label: options.selectPlaceholder || t('select_placeholder'), value: null}, ...selectOptions]
        return selectOptions
    }, [selectOptions])

    useEffect(() => {

        if (!_value && selectOptions?.length === 1 && options.autoSelectFirstIfOneOption) {
            onChange(selectOptions[0])
        }
        const nullableOption = selectOptions.find(o => !o.value)
        if (nullableOption && !_value)
            onChange(nullableOption)

        if (!_value)
            return
        if (typeof _value === 'number' || typeof _value === 'string') {
            const option = selectOptions?.find(o => o.value === _value)
            if (option) {
                onChange(option)
            }
        }
    }, [_value, selectOptions])


    const style = getComputedStyle(document.body)
    const selectStyles = {
        control: (styles, {isFocused, isDisabled}) => ({
            display: 'flex',
            paddingLeft: '0.9rem',
            paddingRight: options.isFetching ? '3.4em' : '1.8em',
            paddingTop: '1.4rem',
            paddingBottom: '0.6rem',
            backgroundColor: isDisabled ? style.getPropertyValue('--grey20') : style.getPropertyValue('--white'),
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: style.getPropertyValue(
                isDisabled || options?.inputProps?.isDisabledValidation ? '--grey'
                    : isInvalid ? '--red'
                        : isValid ? '--green'
                            : '--greyDark'
            ),
            borderRadius: '8px',
            boxShadow: isFocused ? style.getPropertyValue('--focusedInputShadow') : undefined,
        }),
        singleValue: (styles, {isDisabled}) => {
            console.log(styles)
            return {
                ...styles,
                color: (!fieldState.error && value?.value) ? style.getPropertyValue('--green') : '#000000',
                margin: 0,
                lineHeight: 1,
            }
        },
        valueContainer: (provider, state) => ({
            ...provider,
            padding: '0',
        }),
        indicatorsContainer: (provider, state) => ({
            ...provider,
            padding: 0,
        }),
        indicatorSeparator: (provider, state) => ({
            display: 'none'
        }),
        dropdownIndicator: (provider, state) => ({
            ...provider,
            position: 'absolute',
            top: '50%',
            right: '0.9rem',
            padding: 0,
            transform: 'translateY(-50%)',
        }),
        loadingIndicator: (provider, state) => ({
            ...provider,
            position: 'absolute',
            top: '50%',
            right: '2.5rem',
            padding: 0,
            transform: 'translateY(-50%)',
        }),
        placeholder: (provider, state) => ({
            ...provider,
            fontSize: '0.875em',
            lineHeight: 1,
            color: '#000000',
        }),
        input: (provider, state) => ({
            ...provider,
            padding: 0,
            margin: 0,
            lineHeight: 1,
        }),
        menu: (provider, state) => ({
            ...provider,
            top: 'calc(100% - 0.3rem)',
            fontSize: '0.875em',
            color: '#000000',
            borderColor: 'blue',
            borderRadius: '8px',
        }),
        option: (provider, {isSelected, isFocused}) => ({
            ...provider,
            backgroundColor: isSelected ? style.getPropertyValue('--green') : isFocused ? style.getPropertyValue('--green20') : undefined,
            color: provider.color,
            '&:hover, &:active': {
                backgroundColor: style.getPropertyValue('--green'),
                color: style.getPropertyValue('--white'),
            },
        }),
    };

    const handleInputChange = (value) => {
        setInputValue(value)
        if (typeof onInputChange === 'function')
            onInputChange(value)
    }

    const handleChange = (option) => {
        if (!option?.value) {
            onChange([])
            handleBlur()
            return
        }

        const arr = [...selectedOptions]
        const idx = arr.findIndex(a => a.value === option.value)

        if (idx !== -1) arr.splice(idx, 1)
        else arr.push(option)

        setSelectedOptions(arr)
        onChange(arr)
    }

    const handleFocus = (e) => {
        setFocused(true)
    }

    const handleBlur = (e) => {
        setFocused(false)
        if (typeof onBlur === "function") onBlur(e)
    }

    useEffect(() => {

    }, [selectedOptions])


    return (
        <fieldset className={className}>
            <div className="fieldset__wrapper"
                 data-tip={selectedOptions.length ? (selectedOptions.join(', </br>')) : ''}>
                <ReactSelect
                    inputRef={inputRef}
                    styles={selectStyles}
                    name={name}
                    value={value}
                    options={_selectOptions}
                    placeholder={false}
                    noOptionsMessage={() => options.hideNoOptionsMessage ? null : t('no_options')}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onInputChange={handleInputChange}
                    isDisabled={options.disabled}
                    isLoading={options.isFetching}
                    components={{
                        SingleValue: () => (
                            <div
                                style={{
                                    boxSizing: "border-box",
                                    gridArea: "1 / 1 / 2 / 3",
                                    label: "singleValue",
                                    marginLeft: 2,
                                    marginRight: 2,
                                    maxWidth: "100%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    color: (!fieldState.error && value?.value) ? style.getPropertyValue('--green') : '#000000',
                                    margin: 0,
                                    lineHeight: 1,
                                }}
                            >{t('selected_options')}: <strong>{selectedOptions.length}</strong></div>
                        ),
                        LoadingIndicator: () => !options.isLoading && (
                            <div style={selectStyles.loadingIndicator()}><InputSpinner/></div>
                        ),
                        DropdownIndicator: () => !options.isLoading && (
                            <div style={selectStyles.dropdownIndicator()}>
                                <TbChevronDown style={{fontSize: '1em'}}/>
                            </div>
                        ),
                    }}
                    openMenuOnFocus
                    menuIsOpen={focused}
                    {...options.inputProps}
                />
                {!!(options.renderIcon || options.isLoading) && (
                    <div className="fieldset__icons">
                        {options.isLoading ? (
                            <InputSpinner/>
                        ) : options.renderIcon()}
                    </div>
                )}
                <InputLabel
                    name={name}
                    value={value}
                    label={label}
                    options={options}
                />
            </div>
            <InvalidFeedback error={fieldState.error}/>
        </fieldset>
    )
}

const defaultOptions = {
    inputProps: null,
    isRequired: false,
    isLoading: false,
    isFetching: false,
    maxLength: 0,
    disabled: false,
    hideNoOptionsMessage: false,
    nullable: false,
    renderIcon: null,
    selectPlaceholder: '',
    autoSelectFirstIfOneOption: false,
}
