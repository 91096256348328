import {CommentItem, EditorForm} from "../components";
import {getDateTime} from "../../../../misc/Helpers";
import {Preloader} from "../../../../misc/_index";
import {Fragment, useEffect, useRef} from "react";
import {useTranslation} from "../../../../hooks";
import {FiChevronLeft} from 'react-icons/fi';
import {useHistory} from "react-router-dom";
import MessageNav from "./MessageNav";

export default function Message({message, comments}) {
    const commentsRef = useRef()
    const {t} = useTranslation()
    const history = useHistory()

    useEffect(() => {
        window.scroll({top: document.body.scrollHeight})
    }, [comments.query.isSuccess])

    useEffect(() => {
        const documentHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        );
        const maxScrollY = documentHeight - window.innerHeight

        if (window.scrollY === maxScrollY) {
            window.scroll({top: document.body.scrollHeight})
        } else {
        }
    }, [comments.query.isRefetching])

    useEffect(() => {
        comments.query.refetch()
    }, [message.mutation.isSuccess])

    return (
        <>
            <MessageNav
                loading={comments.query.isRefetching && !comments.query.isFetchingNextPage}
            />
            <div className="--mb2_5"/>
            <div className="wrapper">
                {message.query.isSuccess && (
                    <div className="--mb2">
                        {message.data.thread_message ? (
                            <CommentItem
                                item={{
                                    ...message.data.thread_message,
                                    is_read: true,
                                }}
                            />
                        ) : (
                            <div className="messagePreview">
                                {!!message.get(message.data).title && (
                                    <h1
                                        className="messagePreview__title par-7"
                                        dangerouslySetInnerHTML={{__html: message.get(message.data).title}}
                                    />
                                )}
                                {!!message.get(message.data).text && (
                                    <p
                                        className="messagePreview__text par-5"
                                        dangerouslySetInnerHTML={{__html: message.get(message.data).text}}
                                    />
                                )}
                                <p
                                    className="messagePreview__date par-2"
                                    dangerouslySetInnerHTML={{__html: getDateTime(message.get(message.data).created_at)}}
                                />
                            </div>
                        )}
                    </div>
                )}

                {comments.query.hasNextPage && !comments.query.isFetchingNextPage && (
                    <div style={{textAlign: 'center'}}>
                        <button
                            type="button"
                            className="messagePreview__loadOlderButton"
                            onClick={comments.query.fetchNextPage}
                        >{t('reseller_messages.load_older_messages')}</button>
                    </div>
                )}
                {comments.query.isFetchingNextPage && <Preloader classes="--mb0"/>}
                <div ref={comments.loadingHandler.ref} style={{height: 10}}/>
                {comments.query.isLoading || message.query.isLoading ? (
                    <Preloader/>
                ) : comments.query.isError ? (
                    <span>Error: {comments.query.error.message}</span>
                ) : (
                    <div ref={commentsRef} className="comments --mb3">
                        {/*{comments.query.isRefetching && (*/}
                        {/*    <Preloader classes="-sm --mb0"/>*/}
                        {/*)}*/}
                        {comments.data.pages.map((page, pageIdx) => (
                            <Fragment key={page.current_page}>
                                {page.data.map((comment, idx) => (
                                    <CommentItem
                                        key={comment.id_thread_message}
                                        index={(pageIdx * 8) + idx}
                                        item={comment}
                                        message={message}
                                    />
                                ))}
                            </Fragment>
                        ))}
                    </div>
                )}
            </div>

            {!!(message.query.isSuccess && comments.query.isSuccess && message.data.thread_two_way_communication) && (
                <div className="messagePreview__editor">
                    <div className="wrapper">
                        <EditorForm
                            mutation={message.mutation}
                            onSubmit={message.sendComment}
                        />
                    </div>
                </div>
            )}
        </>
    )
}
