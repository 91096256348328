import React, {useEffect, useState} from 'react';
import {Button} from "../../../components/buttons/_index";
import {useCart, usePriceFormatter, useTranslation} from "../../../misc/Hooks";
import {getImage, shortText} from "../../../misc/Helpers";
import {useDispatch} from "react-redux";
import * as stayHomeReducer from "../../../redux/stayHomeReducer";
import {PlanModal} from "./index";

export default function PlanItem(props) {
    const dispatch = useDispatch()
    const [modalVisible, setModalVisible] = useState(false);
    const {formatPrice, formatPriceObj} = usePriceFormatter();
    const {updateItem, isItemLocked} = useCart(props.storeName);
    const t = useTranslation();
    const [planMachine, setPlanMachine] = useState(props.item.product_plan_variant[0])
    const [description, setDescription] = useState(shortText(props.item.product_plan_description1))

    useEffect(() => {
        const plan = props.cart.items.find(i => i.id === props.item.id_product)
        if (plan) {
            dispatch(stayHomeReducer.setPlanMachine(planMachine))
        }
    }, [props.cart])

    const toggleDescription = () => {
        if(description.hasMore) {
            setDescription({
                text: props.item.product_plan_description1,
                hasMore: false,
            })
        } else {
            setDescription(shortText(props.item.product_plan_description1))
        }
    }

    const onChangePlanMachine = (planMachine) => {
        setPlanMachine(planMachine)
        const plan = props.cart.items.find(i => i.id === props.item.id_product)
        if (plan) {
            dispatch(stayHomeReducer.setPlanMachine(planMachine))
        }
    }

    const itemInCart = props.cart?.items.find(i => i.id === props.item.id_product);
    const isLocked = isItemLocked(props.item.id_product);

    const coverImage = getImage(props.item.product_gallery) || getImage(planMachine?.machine_gallery);

    if(!planMachine)
        return <p>no variants for plan with id={props.item.id_product}</p>

    return (
        <div className="promoItem">
            <div className="promoItem__header">
                {!!coverImage && (
                    <div className="promoItem__thumb">
                        <img src={coverImage} alt={props.item.product_title}/>
                    </div>
                )}
                <h3 className="promoItem__title">{props.item.product_title}</h3>
                <p
                    className="promoItem__text"
                    style={{cursor: description.hasMore ? 'pointer' : 'normal'}}
                    dangerouslySetInnerHTML={{__html: description.text}}
                    onClick={toggleDescription}
                />
            </div>


            <div className="promoItem__body">
                <div className="gearItem__price">
                    <h5 className="gearItem__priceValue">{formatPriceObj(props.item.product_plan_price, 0)}</h5>
                    <h5 className="gearItem__priceLabel">/ {t('stay_home.gear.price_label')}</h5>
                </div>

                <div className="gearItem__addon">
                    {t('stay_home.gear.addon', formatPrice(Number(props.item.product_plan_machine_price) - Number(props.item.product_plan_machine_refund)))}
                </div>
                <div className="gearItem__inStoresFrom">
                    {t('stay_home.gear.in_stores_from', formatPrice(props.item.product_plan_machine_price))}
                </div>

                <button
                    className="link -primary"
                    onClick={() => setModalVisible(true)}
                >{t('read_more')}</button>
            </div>

            {!!props.item.product_plan_variant?.length && (
                <div className="gearColors">
                    <h4 className="gearColors__title">{planMachine.machine_title}</h4>
                    <div className="gearColors__items">
                        {props.item.product_plan_variant.map(v => (
                            <button
                                key={v.id_machine}
                                className={`gearColor ${planMachine.id_machine === v.id_machine ? '-active' : ''}`}
                                style={{backgroundColor: v.color}}
                                onClick={() => onChangePlanMachine(v)}
                            />
                        ))}
                    </div>
                </div>
            )}


            <div
                className="promoItem__content gearItem__bullets"
                style={{flex: 1}}
                dangerouslySetInnerHTML={{__html: props.item.product_plan_description2 || ''}}
            />


            <div className="promoItem__footer">
                <div className="coffeeItem__button">
                    <Button
                        type="primary"
                        size="sm"
                        label={t(!itemInCart ? 'buy_now' : 'remove_from_cart')}
                        onClick={() => updateItem(props.item.id_product, !itemInCart ? props.item.product_step : 0)}
                        disabled={isLocked && !itemInCart}
                    />
                </div>
            </div>


            {!!modalVisible && (
                <PlanModal
                    visible={modalVisible}
                    item={props.item}
                    onClose={() => setModalVisible(false)}
                />
            )}
        </div>
    )
}
