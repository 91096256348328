import {InputLabel, InvalidFeedback} from "../components";
import {useInputValidationClassNames} from "../hooks";
import MaskedInput from 'react-input-mask';
import {ImSpinner8} from "react-icons/im";
import InputSpinner from "../components/InputSpinner";

/**
 * MaskInput
 * @param inputRef
 * @param fieldState
 * @param name
 * @param label
 * @param value
 * @param mask
 * @param {{forceUppercase, maskInputProps, inputProps, renderIcon, isLoading}} options
 * @param onChange
 * @param onBlur
 * @returns {JSX.Element}
 * @constructor
 */
export default function MaskInput({
    inputRef,
    fieldState,
    name,
    label,
    value,
    mask,
    options,
    onChange,
    onBlur,
}) {
    options = {...defaultOptions, ...options}
    const {className} = useInputValidationClassNames({
        value,
        fieldState,
        params: {
            '-isValid': value
                && value.replace(mask.replace(/[9a*]/g, '_'), '')
                && !fieldState.invalid,
            '-icons': !!options.renderIcon || options.isLoading,
        },
        deps: [options.renderIcon, options.isLoading],
    })

    const handleChange = (e) => {
        const value = e.target.value
        if(!value.replace(mask.replace(/[9a*]/g, '_'), '')) {
            onChange('')
            return
        }
        if (options.forceUppercase && value) {
            onChange(value.toUpperCase())
            return
        }
        onChange(value)
    }

    return (
        <fieldset className={className}>
            <div className="fieldset__wrapper">
                <MaskedInput
                    type="text"
                    className="fieldset__input"
                    name={name}
                    value={value}
                    mask={mask}
                    onChange={handleChange}
                    onBlur={onBlur}
                    alwaysShowMask
                    {...options.maskInputProps}
                >
                    {(inputProps) => (
                        <input
                            {...inputProps}
                            placeholder={label}
                            id={name}
                            ref={r => {
                                inputRef.current = r
                                if(typeof options?.inputProps?.ref !== 'undefined')
                                    options.inputProps.ref = r
                            }}
                            disabled={options.maskInputProps?.disabled}
                            {...options.inputProps}
                        />
                    )}
                </MaskedInput>
                {!!(options.renderIcon || options.isLoading) && (
                    <div className="fieldset__icons">
                        {options.isLoading ? (
                            <InputSpinner/>
                        ) : options.renderIcon()}
                    </div>
                )}
                <InputLabel
                    name={name}
                    value={value}
                    label={label}
                    options={options}
                />
            </div>
            <InvalidFeedback error={fieldState.error}/>
        </fieldset>
    )
}

const defaultOptions = {
    forceUppercase: false,
    maskInputProps: null,
    inputProps: null,
    renderIcon: null,
    isLoading: null,
}
