import {AsyncSelectController} from "../controllers";
import React from "react";
import {useTranslation} from "../../hooks";
import {useAsyncSelect} from "../hooks";
import {SelectOption} from "../../services";
import {getStackByType} from "../../actions/MainActions";

export default function RegionSelect(props) {
    const {t} = useTranslation()
    const asyncSelect = useAsyncSelect({
        name: 'regions',
        action: params => getStackByType('region', params),
        paramFunc: (slug) => ({
            search: slug,
            limit: 100,
        }),
        mapFunc: o => new SelectOption(o.stack_title, o.stack_value),
    })

    return (
        <AsyncSelectController
            asyncSelect={asyncSelect}
            name="region"
            label={t('region')}
            {...props}
        />
    )
}
