import {useSelector} from "react-redux";
import Alert from "./Alert";
import {useCart, useLocalizeObject, useMarketConfig, usePriceFormatter, useTranslation} from "../misc/Hooks";
import {useMemo} from "react";
import {Config} from "../utils/_index";
import {useCartValidation} from "../hooks";


export default function CartAlerts(props) {
    const store = useSelector(state => ({
        cart: state[props.storeName].cart,
        promotion: state[props.storeName].promotion,
        products: state[props.storeName].products,
        categories: state[props.storeName].categories,
        proofOfPurchase: state[props.storeName].proofOfPurchase,
        machine: state[props.storeName].machine,
    }))

    const {capsulesCount, capsulesValue} = useCart(props.storeName);
    const {formatPrice, withCurrency} = usePriceFormatter();
    const t = useTranslation();
    const localizeObject = useLocalizeObject()
    const marketConfig = useMarketConfig()
    const cartItems = useMemo(() => {
        return store.cart?.items
            .map(i => {
                return store.products.find(p => p.id_product === i.id)
            })
            .filter(p => p)
    }, [store.cart, store.products])
    const {firstError} = useCartValidation(props.storeName)

    const replaceByCond = (text, conditions) => {
        conditions.forEach(({name, value}) => {
            text = text.replace(`{${name}}`, value)
        })

        return text;
    }

    const getBenefit = () => {
        const cat = store.categories.find(c => c.promotion_category_is_benefit)
        let minimalOrderValue = Number(store.promotion?.promotion_minimal_order_value ?? 0)
        let benefitValue = store.promotion?.promotion_show_benefit_currency ? withCurrency(store.promotion?.promotion_benefit) : store.promotion?.promotion_benefit ?? ''
        if (cat) {
            let product = null
            if (marketConfig.pop_validation_ocr_machine_price_mandatory) {
                const p = cat.products.find(p => p.promotion_product && (
                    (p.promotion_product.promotion_product_add_auto_min_price_machine === null || !store.proofOfPurchase?.machinePrice ? true : Number(store.proofOfPurchase?.machinePrice) >= p.promotion_product.promotion_product_add_auto_min_price_machine)
                    && (p.promotion_product.promotion_product_add_auto_max_price_machine === null || !store.proofOfPurchase?.machinePrice ? true : Number(store.proofOfPurchase?.machinePrice) < p.promotion_product.promotion_product_add_auto_max_price_machine)
                ))
                if (p)
                    product = p
            } else {
                const productsIds = store.cart?.items.map(i => i.id)
                if (!cat.products.find(p => productsIds.includes(p.id_product))) {
                    // product = cat.products.find(p => p?.promotion_product?.promotion_product_machines.includes(String(store.machine?.id_machine)));
                    // if (!product) {
                        product = cat.products[0]
                    // }
                }
            }
            if (product) {
                benefitValue = formatPrice(Math.abs(product?.product_price?.gross))
                if (product.promotion_product) {
                    if(product.promotion_product.promotion_product_add_auto_min_price !== null){
                        minimalOrderValue = Number(product.promotion_product.promotion_product_add_auto_min_price)
                    }
                }
            }
        }
        return {
            benefitValue,
            minimalOrderValue,
        }
    }

    const {benefitValue, minimalOrderValue} = getBenefit()

    if (!cartItems.filter(p => ![Config.COFFEE_TYPE.EASY_ORDER_GIFT].includes(p.product_type)).length) {
        return null
    }

    if (capsulesValue < minimalOrderValue)
        return (
            <Alert
                type="success"
                text={localizeObject(store.promotion?.promotion_alert_minimal_order_value) ? (
                    replaceByCond(localizeObject(store.promotion?.promotion_alert_minimal_order_value), [
                        {name: 'minValue', value: formatPrice(minimalOrderValue)},
                        {
                            name: 'cartValue',
                            value: formatPrice(minimalOrderValue - capsulesValue)
                        },
                        {name: 'discount', value: benefitValue},
                    ])
                ) : (
                    t(
                        'info.minimal_order_value',
                        {name: 'minValue', value: formatPrice(minimalOrderValue)},
                        {
                            name: 'cartValue',
                            value: formatPrice(minimalOrderValue - capsulesValue)
                        },
                        {name: 'discount', value: benefitValue},
                    )
                )}
            />
        )

    if (capsulesCount < Number(store.promotion?.promotion_minimal_capsules))
        return (
            <Alert
                type="success"
                text={localizeObject(store.promotion?.promotion_alert_minimal_capsules) ? (
                    replaceByCond(localizeObject(store.promotion?.promotion_alert_minimal_capsules), [
                        {name: 'minValue', value: store.promotion.promotion_minimal_capsules},
                        {name: 'cartValue', value: Number(store.promotion?.promotion_minimal_capsules) - capsulesCount},
                        {name: 'discount', value: benefitValue},
                    ])
                ) : (
                    t(
                        'info.minimal_capsules',
                        {name: 'minValue', value: store.promotion.promotion_minimal_capsules},
                        {name: 'cartValue', value: Number(store.promotion?.promotion_minimal_capsules) - capsulesCount},
                        {name: 'discount', value: benefitValue},
                    )
                )}
            />
        )

    if(firstError?.missingProducts > 1) {
        return (
            <Alert
                type="success"
                text={localizeObject(store.promotion?.promotion_alert_minimal_capsules) ? (
                    replaceByCond(localizeObject(store.promotion?.promotion_alert_minimal_capsules), [
                        {name: 'minValue', value: store.promotion.promotion_minimal_capsules},
                        {name: 'cartValue', value: firstError.missingProducts},
                        {name: 'discount', value: benefitValue},
                    ])
                ) : (
                    t(
                        'info.minimal_capsules',
                        {name: 'minValue', value: store.promotion.promotion_minimal_capsules},
                        {name: 'cartValue', value: firstError.missingProducts},
                        {name: 'discount', value: benefitValue},
                    )
                )}
            />
        )
    } else if(firstError?.missingProducts === 1) {
        return (
            <Alert
                type="success"
                text={localizeObject(store.promotion?.promotion_alert_missing_product_from_cat) ? (
                    replaceByCond(localizeObject(store.promotion?.promotion_alert_missing_product_from_cat), [
                        {name: 'arg', value: firstError.category.category.category_title}
                    ])
                ) : (
                    t(
                        'info.missing_product_from_category',
                        firstError.category.category.category_title
                    )
                )}
            />
        )
    }

    let interval = null;
    let invalidInterval = false;
    let categoryMinProducts = null;
    let invalidCategoryMinProducts = false;
    store.categories
        .filter(c => ![Config.COFFEE_TYPE.PLAN_WORK_HOME].includes(c.category.category_type))
        .map(cat => {
            if (cat) {
                let productsIds = cat.products.map(p => p.id_product)
                cat.category.subcategories?.map(s => s.products.map(p => productsIds.push(p.id_product)))

                if (productsIds.length) {
                    const cartItems = store.cart.items.filter(i => productsIds.includes(i.id))
                    let count = 0;
                    cartItems.map(i => {
                        const prod = store.products.find(p => p.id_product === i.id);
                        if (prod) {
                            count += i.value * prod.product_package_qty
                        }
                    })
                    if (count < cat.promotion_category_minimal_products) {
                        categoryMinProducts = cat.promotion_category_minimal_products;
                        invalidCategoryMinProducts = true;
                    }
                    if (count % cat.promotion_category_interval_products !== 0) {
                        interval = cat.promotion_category_interval_products;
                        invalidInterval = true;
                    }
                }
            }
        })


    if (invalidInterval) {
        return (
            <Alert
                type="success"
                text={localizeObject(store.promotion?.promotion_alert_invalid_interval) ? (
                    replaceByCond(localizeObject(store.promotion?.promotion_alert_invalid_interval), [
                        {name: 'arg', value: interval},
                        {name: 'arg', value: interval},
                        {name: 'arg', value: interval * 2},
                        {name: 'arg', value: interval * 3},
                        {name: 'minValue', value: store.promotion.promotion_minimal_capsules},
                        {
                            name: 'cartValue',
                            value: interval - (capsulesCount - (Math.floor(capsulesCount / interval) * interval))
                        },
                        {name: 'discount', value: benefitValue},
                    ])
                ) : (
                    t(
                        'info.invalid_interval',
                        interval,
                        interval,
                        interval * 2,
                        interval * 3,
                    )
                )}
            />
        )
    }

    return null;
}
