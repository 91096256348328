import Provider from "./Provider"
import {Redirect, Route, Switch} from "react-router-dom"
import React from "react"
import {useNavigation} from "../../misc/Hooks"
import {Footer} from "../../components/misc/_index"
import {Routes} from "../../utils/_index"
import {useSteps} from "../../hooks/modules/order_finish"
import InvalidTokenScreen from "./screens/InvalidTokenScreen"
import TokenExpiredScreen from "./screens/TokenExpiredScreen"
import {makeStore, ModuleProvider} from "../../hooks";
import {getStepsRoutes} from "../../misc/Helpers";
import StepsFunnel from "./StepsFunnel";
import Header from "../../components/misc/Header";
import {AppProvider} from "../../misc/_index";
import {OrderFinalizedScreen} from "../../screens";


export default function Funnel(props) {
    const navigation = useNavigation()
    const steps = useSteps()

    const contextValue = makeStore({
        storeName: 'of',
        steps
    })

    return (
        <AppProvider>
            <ModuleProvider value={contextValue}>
                <Provider>
                    <Switch>
                        <Route
                            exact path={[
                            ...getStepsRoutes(steps),
                        ]}
                            component={StepsFunnel}
                        />

                        <Route
                            exact path={Routes.ORDER_FINISH_ORDER_FINALIZED}
                            component={OrderFinalizedScreen}
                        />

                        <Route>
                            <div className="app">
                                <Header
                                    homeRoute={navigation(Routes.MACHINE_REGISTRATION_REGISTRATION)}
                                    disableEdit
                                    alertsDisabled
                                />

                                <div className="main">
                                    <div className="wrapper-bg formContainer">
                                        <div className="wrapper">
                                            <Switch>

                                                <Route
                                                    exact path={Routes.ORDER_FINISH_INVALID_TOKEN}
                                                    component={InvalidTokenScreen}
                                                />
                                                <Route
                                                    exact path={Routes.ORDER_FINISH_TOKEN_EXPIRED}
                                                    component={TokenExpiredScreen}
                                                />

                                                <Route path={[Routes.ORDER_FINISH, Routes.FINISH_ORDER]}>
                                                    <Redirect to={navigation(steps[0]?.route)}/>
                                                </Route>

                                            </Switch>
                                        </div>
                                    </div>
                                </div>

                                <Footer/>
                            </div>
                        </Route>
                    </Switch>
                </Provider>
            </ModuleProvider>
        </AppProvider>
    )
}
