import {MaskInputController, TextInputController} from "../controllers";
import React, {useMemo} from "react";
import {useFieldFormat, useTranslation} from "../../hooks";

export default function PhoneInput({phoneType = 'phone', inputName, inputLabel, ...props}) {
    const {t} = useTranslation()
    const {formats} = useFieldFormat()
    const {name, mask, label} = useMemo(() => {
        const types = {
            phone: {name: inputName || 'phone', mask: formats.phone.getMask(), label: inputLabel || t('phone')},
            phoneMobile: {name: inputName || 'phoneMobile', mask: formats.phoneMobile.getMask(), label: inputLabel || t('phone_mobile')},
            phoneHome: {name: inputName || 'phoneHome', mask: formats.phoneHome.getMask(), label: inputLabel || t('phone_home')},
        }

        return types[phoneType]
    }, [phoneType, formats, t])

    return (
        <MaskInputController
            name={name}
            label={label}
            mask={mask}
            {...props}
        />
    )
}
