import React, {useEffect, useMemo} from "react";
import {useFormBuilder, useFormRulesBuilder} from "../hooks";
import {useFormHelper, useModuleContext, useTranslation} from "../../hooks";
import {CheckboxController, SwitchController} from "../controllers";
import {FormBuilderComponent, FormGroup} from "../components";
import {getBenefitsOrder, isEnabledByMarket} from "../../utils/MarketConfig";
import {getDeepValue} from "../../helpers";
import {BenefitsInfo} from "../../components/misc/_index";

export default function BenefitsFormFragment({
    prefix = '',
    fields = {},
    userData,
    config = {},
}) {
    const {t} = useTranslation()
    const {store, reducer, dispatch} = useModuleContext()
    const {formMethods, ...formHelper} = useFormHelper(prefix)

    const benefits = useMemo(() => getBenefitsOrder(), [])
    const formConfig = useMemo(() => benefits.map(o => [o]), [benefits])
    const rules = useMemo(() => {
        return benefits.reduce((obj, o) => {
            if (config.required === 1) {
                const requiredOneOfNames = benefits.filter(n => fields[n] !== 2).map(n => formHelper.name(n))
                obj[formHelper.name(o)] = b => b.requiredOneOf(requiredOneOfNames)
                    .validate(v => typeof v === 'boolean')
            }
            if (config.required === 2 || fields[o] === 2 || fields[o] === 4) {
                obj[formHelper.name(o)] = b => b.required()
                    .validate(v => typeof v === 'boolean')
            } else if (fields[o] === 1) {
                obj[formHelper.name(o)] = b => b.required(false)
                    .validate(v => typeof v === 'boolean')
            } else {
                obj[formHelper.name(o)] = b => b.validate(v => typeof v === 'boolean')
            }
            return obj
        }, {})
    }, [benefits, fields])
    const defaultValues = useMemo(() => {
        return benefits.reduce((obj, o) => {
            obj[formHelper.name(o)] = (userData && userData[o]) || [3,4].includes(fields[o]) ? true : undefined
            return obj
        }, {})
    }, [benefits, fields])
    const formRules = useFormRulesBuilder(rules, formMethods)
    const inputComponents = useMemo(() => benefits.map(option => ({
        name: option,
        component: (
            <SwitchController
                name={option}
                rules={formRules.values[formHelper.name(option)]}
                defaultValue={defaultValues[formHelper.name(option)]}
                label={() => (
                    <span
                        className="checkbox__label --bold"
                        dangerouslySetInnerHTML={{__html: t(`receive_benefits_${option}`)}}
                    />
                )}
            />
        ),
    })).reduce((obj, option) => {
        obj[option.name] = option.component
        return obj
    }, {}), [benefits, formRules])
    const inputVisibility = useMemo(() => {
        return benefits.reduce((obj, option) => {
            if (fields[option] === 0) {
                obj[option] = false
                return obj
            } else {
                obj[option] = true
            }
            if (userData) {
                if (config.visibility === 'unchecked') {
                    obj[option] = !userData[option]
                } else if (config.visibility === 'none') {
                    obj[option] = false
                }
            }

            return obj
        }, {})
    }, [userData, benefits])
    const {components} = useFormBuilder({
        formConfig,
        inputVisibility,
        inputComponents,
    })

    useEffect(() => {
        const subscription = formMethods.watch((data, {name}) => {
            if (config.required === 1) {
                const requiredOneOfNames = benefits.filter(n => fields[n] !== 2).map(n => formHelper.name(n))
                if (requiredOneOfNames.includes(name)) {
                    formMethods.trigger(requiredOneOfNames)
                }
            }

            if (name === formHelper.name('selectAll')) {
                const checked = getDeepValue(data, name)
                benefits.forEach(o => formHelper.setValue(o, !!checked, {
                    shouldValidate: true,
                    shouldTouch: true,
                    shouldDirty: true
                }))
            }

            if (benefits.map(n => formHelper.name(n)).includes(name)) {
                const checked = getDeepValue(data, name)
                if (!checked && getDeepValue(data, formHelper.name('selectAll'))) {
                    formHelper.setValue('selectAll', false, {
                        shouldValidate: true,
                        shouldTouch: true,
                        shouldDirty: true
                    })
                }


                if(typeof reducer.setOptIn === 'function') {
                    dispatch(reducer.setOptIn({...store.optIn, ...getDeepValue(data, prefix)}))
                }
            }


        })
        return () => subscription.unsubscribe()
    }, [formMethods.watch])

    if (config.visibility === 'none' && userData)
        return null

    if (Object.keys(inputVisibility).length && !Object.keys(inputVisibility).filter(k => inputVisibility[k]).length)
        return null

    return (
        <>
            {!isEnabledByMarket('benefitsInfoUnderCheckboxes') && (<BenefitsInfo/>)}

            {!!config.selectAllVisible && (
                <FormGroup>
                    <CheckboxController
                        name={formHelper.name('selectAll')}
                        label={t('select_all_benefits')}
                    />
                </FormGroup>
            )}
            <FormBuilderComponent prefix={prefix} components={components}/>

            {isEnabledByMarket('benefitsInfoUnderCheckboxes') && (
                <>
                    <div className="--mb4"/>
                    <BenefitsInfo/>
                </>
            )}
        </>
    )
}
