import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import * as mainActions from '../../actions/MainActions';
import Layout from "../../components/Layout";
import {useLocalizeObject, useNavigation} from "../../misc/Hooks";
import {Button} from "../../components/buttons/_index";
import {useTranslation} from "../../hooks";
import Routes from "../../utils/Routes";
import {Error404, ErrorScreen} from "../../components/misc/_index";
import {useAxLandingPage} from "./hooks";
import {AxLandingPageForm} from "./index";

export default function AxLandingPageScreen() {
    const {t} = useTranslation()
    const params = useParams()
    const navigation = useNavigation()
    const localizeObject = useLocalizeObject()
    const query = useQuery(['axLandingPage', params.slug], () => mainActions.getAxLandingPage(params.slug))
    const ax = useAxLandingPage();

    if (query.isSuccess && !query.data?.data?.data)
        return (
            <ErrorScreen>
                <Error404/>
            </ErrorScreen>
        )


    return (
        <Layout screenLoading={query.isLoading}>
            {!!query.data?.data?.data && (
                <>
                    <div className="par-2"
                         dangerouslySetInnerHTML={{__html: localizeObject(query.data.data.data.landing_page_text.top)}}/>
                    <div className="axLp__buttons --mb3">
                        <Button
                            label={t('ax.lp.yes_do_it_now')}
                            href={navigation(Routes.MACHINE_REGISTRATION_REGISTRATION)}
                        />
                        <Button
                            label={t('ax.lp.no_to_it_later')}
                            onClick={() => ax.setFormVisible(true)}
                            disabled={ax.formVisible}
                        />
                    </div>
                    {ax.formVisible && (
                        <AxLandingPageForm onSubmit={ax.onSubmitForm} ax={ax}/>
                    )}
                    <div className="par-2"
                         dangerouslySetInnerHTML={{__html: localizeObject(query.data.data.data.landing_page_text.bottom)}}/>
                </>
            )}
        </Layout>
    )
}
