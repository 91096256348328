import {createContext, useContext, useMemo, useEffect} from "react";
import {useQuery} from "@tanstack/react-query";
import {ApiException} from "../errors";
import {useResellerActions} from "../actions/useResellerActions";
import {useCountry, useMachine, useModuleContext, useReseller} from "./index";
import * as subscriptionActions from "../actions/SubscriptionActions";

const PlansContext = createContext(null)
export const PlansProvider = PlansContext.Provider

export function usePlansContext() {
    const context = useContext(PlansContext)
    const plans = context.query.data?.plans ?? []

    return {
        ...context,
        plans,
    }
}

export default function useFetchPlans() {
    const {store, reducer, dispatch} = useModuleContext()
    const reseller = useReseller()
    const resellerActions = useResellerActions()
    const country = useCountry()
    const machine = useMachine()
    const {params, isEnabled} = useMemo(() => {
        const params = {
            machineId: machine.data?.id_machine ?? 0,
            countryId: country.data.id_country,
            shopId: store.shopData?.shop?.value ?? reseller.shopId,
        }
        const isEnabled = !!(params.machineId && params.shopId)
        return {
            params,
            isEnabled,
        }
    }, [machine.data, country.data, store.shopData?.shop, reseller.shopId])
    const query = useQuery({
        queryKey: [params],
        queryFn: async () => {
            const action = reseller.isLoggedIn ? resellerActions.getPlans : subscriptionActions.getPlans2

            const {data} = await action(params)

            if (data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        onSuccess: (data) => {
            dispatch(reducer.setProducts(data.plans))
            dispatch(reducer.setCategories(data.categories))
        },
        enabled: isEnabled,
        refetchOnWindowFocus: false,
    })

    return {
        query,
    }
}
