import {useTranslation} from "../../../misc/Hooks";
import React from "react";
import {Header} from "../../../components/misc/_index";


export default function InvalidTokenScreen(props) {
    const t = useTranslation()

    return (
        <div className="app">
            <Header
                cartDisabled
                alertsDisabled
            />
            <div className="main">
                <div className="wrapper-bg formContainer">
                    <div className="wrapper">
                        <div className="--mb4" style={{textAlign: 'center'}}>
                            <h1 className="par-1">{t('shop_reopen.invalid_token.title')}</h1>
                            <p className="par-2">{t('shop_reopen.invalid_token.text')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
