
export default function PdfViewer({url}) {
    if(!url.match(/\.[a-zA-Z]+$/))
        return null
    return (
        <div className="pdfViewer">
            <object
                className="pdfViewer__content"
                data={`${url}#toolbar=0&navpanes=0&scrollbar=0&pagemode=none`}
                type="application/pdf"
            />
        </div>
    )
}
