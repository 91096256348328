import {Config, Routes} from "../../../utils/_index";
import {useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";


/**
 * Returns Order Finish module steps
 * @returns {{component, route: string, title: string}[]}
 */
export default function useSteps() {
    const store = useSelector(({of}) => ({
        finishOrder: of.finishOrder,
        promotion: of.promotion,
    }))
    const [finishOrder, setFinishOrder] = useState(store.finishOrder)

    useEffect(() => {
        if(store.finishOrder) {
            setFinishOrder(store.finishOrder)
        }
    }, [store.finishOrder])

    return Config.ORDER_FINISH_STEPS.filter(step => {
        if (step.route === Routes.ORDER_FINISH_AUTHORIZATION) {
            if(finishOrder) {
                if(!finishOrder.webaccount_exist)
                    step.title = 'finish_order_account.title2'
                else
                    step.title = 'finish_order_account.title'
            } else
                step.title = 'ev_customer_identification.title'
        }

        if (step.route === Routes.ORDER_FINISH_CUSTOMER) {
            if(store.promotion?.promotion_show_payment_details && (!store.finishOrder.payment_account_number || !store.finishOrder.payment_bsb_number)) {
                return true
            }
            if(!finishOrder?.webaccount_exist)
                return false
        }

        // if (!finishOrder?.webaccount_exist && step.route === Routes.ORDER_FINISH_CUSTOMER) {
        //     return false
        // }
        return true
    })
}
