import {useSelector} from "react-redux";
import {getImage as getImageHelper} from "../misc/Helpers";
import {getMarketImage} from "../utils/MarketConfig";

export default function useCountryImages() {
    const country = useSelector(({main}) => main.country)

    const getImage = (type) => {
        const item = country.country_artwork?.find(i => i.artwork_type === type)
        if (!item)
            return getMarketImage(type)

        return getImageHelper(item.artwork_gallery) || getMarketImage(type)
    }

    return {
        data: country?.country_artwork,
        getImage,
    }
}

export const COUNTRY_IMAGE_TYPE = {
    machine: 'machine',
    helpMachine: 'helpMachine',
    helpNumber: 'helpNumber',
    customBenefitsInfo: 'customBenefitsInfo',
    emailAmazon: 'emailAmazon',
}
