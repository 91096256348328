import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import React, {useState} from "react";

export default function LocalStockRequestQuantity({item, order, setOrder}){
    const [value, setValue] = useState(0)


    const updateValue = (v) => {
        const arr = [...order]
        setValue(v)

        const idx = arr.findIndex(a => a.id === item.id_product)


        if(Number(v) === 0){
           if(idx !== -1)
               arr.splice(idx, 1)
        } else {
            if(idx !== -1)
                arr[idx].value = v
            else
                arr.push({
                    id: item.id_product,
                    value: v
                })
        }

        setOrder(arr)
    }
    const onClickDecrease = () => {
        if (value - item.product_package_qty >= 0)
            updateValue(value - item.product_package_qty)
    }
    const onClickIncrease = () => updateValue(value + item.product_package_qty)

    return (
        <div className="localStockQuantity">
            <div className="localStockQuantity__inputWrapper">

                <button className="localStockQuantity__editButton" onClick={onClickDecrease} disabled={Number(value) <= 0}>
                    <AiOutlineMinus/>
                </button>

                <input
                    className={`localStockQuantity__input`}
                    type="number"
                    value={value.toString()}
                    readOnly
                />

                <button className="localStockQuantity__editButton" onClick={onClickIncrease}>
                    <AiOutlinePlus/>
                </button>

            </div>
        </div>
    )
}