import {useModuleContext} from "../../../hooks";
import {useEffect, useState} from "react";

export default function useFavoriteProducts() {
    const {store, storeName} = useModuleContext()
    const [products, setProducts] = useState([])
    const [category, setCategory] = useState(null)

    useEffect(() => {
        const c = store.categories.find(c => c.category.category_type === 'favorite_coffee')
        if (!store.personalization?.products?.length || !c) return null

        setCategory(c.category)
        const favoriteProducts = store.personalization.products.reduce((previousValue, currentValue) => {
            const arr = [...previousValue]
            let product = null

            store.categories.forEach((c) => {
                let p = c.products.find(cp => cp.id_product === currentValue.id_product)

                if (p) {
                    product = {product: p, category: c.category}
                    return
                }

                c.category.subcategories.forEach(sc => {
                    p = sc.products.find(scp => scp.id_product === currentValue.id_product)
                    if (p) {
                        product = {product: p, category: sc}
                    }
                })
            })

            if (product) arr.push(product)

            return arr
        }, [])

        setProducts(favoriteProducts.slice(0,5))

    }, [store.personalization?.products])


    return {
        products,
        category,
        storeName
    }
}