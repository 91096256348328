import {Form, PromoCodeForm, ProofOfPurchaseForm} from "./index";
import React, {useMemo, useState} from "react";
import {useModuleContext, useRequiredField, useReseller, useTranslation} from "../hooks";
import {useSelector} from "react-redux";
import {useCountryConfig} from "../misc/Hooks";
import {useForm} from "react-hook-form";
import {useFormRulesBuilder} from "./hooks";
import {Button} from "../components/buttons/_index";


export default function ProofOfPurchaseAndPromoCodeForm({stepFilled, editable, setEditable, onSubmit}) {
    const {t} = useTranslation()
    const context = useModuleContext()
    const countryConfig = useCountryConfig()
    const {requiredField} = useRequiredField()
    const store = useSelector(state => ({
        isFastOrder: state[context.storeName]?.isFastOrder,
        proofOfPurchase: state[context.storeName]?.proofOfPurchase,
        promoCode: state[context.storeName]?.promoCode,
    }))
    const [emptyForm, setEmptyForm] = useState(!store.promoCode && !store.proofOfPurchase)
    const [withCode, setWithCode] = useState(!!store.promoCode);
    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: getDefaultValues({
            ...store.proofOfPurchase,
            promoCode: store.promoCode?.promo_code_name,
        }),
    })
    const formRules = useFormRulesBuilder({}, formMethods)
    const promoCodeVisible = useMemo(() => requiredField ? (
        (
            (requiredField === 'proof_of_purchase_or_promo_code' && withCode)
            || requiredField === 'proof_of_purchase_and_promo_code'
            || requiredField === 'only_promo_code'
        )
        && requiredField !== 'only_proof_of_purchase'
    ) : false, [countryConfig, withCode])

    const popVisible = useMemo(() => requiredField ? (
        (
            (requiredField === 'proof_of_purchase_or_promo_code' && !withCode)
            || requiredField === 'proof_of_purchase_and_promo_code'
            || requiredField === 'only_proof_of_purchase'
        )
        && requiredField !== 'only_promo_code'
    ) : true, [countryConfig, withCode])

    const handleSubmit = (data) => {
        setEditable(false)
        setEmptyForm(false)
        if(typeof onSubmit === 'function')
            onSubmit(data)
    }

    return requiredField !== 'none_required' && (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            onSubmit={handleSubmit}
        >

            {promoCodeVisible && (
                <div className="--mb4">
                    <PromoCodeForm
                        editable={editable}
                        setEditable={setEditable}
                    />
                    {editable ? requiredField === 'proof_of_purchase_or_promo_code' && (
                        <div className="serialNumber__footer">
                            {!!t('ev_machine_registration.promo_code_info') && (
                                <p className="par-4"
                                   dangerouslySetInnerHTML={{__html: t('ev_machine_registration.promo_code_info')}}/>
                            )}

                            <button
                                type="button"
                                className="link -primary"
                                onClick={() => setWithCode(false)}
                            >{t('ev_machine_registration.code_button')} <img
                                src={require('../assets/img/icons/arrow-right-brown.svg').default} alt=""/>
                            </button>
                        </div>
                    ) : (
                        <div className="serialNumber__footer">
                            {/*{!!t('ev_machine_registration.promo_code_info') && (*/}
                            {/*    <p className="par-4" dangerouslySetInnerHTML={{__html: t('ev_machine_registration.promo_code_info')}}/>*/}
                            {/*)}*/}

                            <button
                                type="button"
                                className="link -primary"
                                onClick={() => setEditable(true)}
                            >{t('pop.edit_promo_code_button')} <img
                                src={require('../assets/img/icons/arrow-right-brown.svg').default} alt=""/>
                            </button>
                        </div>
                    )}
                </div>
            )}

            {popVisible && (
                <div>
                    {emptyForm && (
                        <>
                            {requiredField === 'proof_of_purchase_or_promo_code' ? (
                                <div className="par-1">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t('ev_machine_registration.pop_or_code1')}}/>
                                    <button
                                        type="button"
                                        className="link -parentSize -primary"
                                        onClick={() => setWithCode(!withCode)}
                                        style={{display: 'contents'}}
                                    >{t('ev_machine_registration.pop_or_code2')}</button>
                                    <span
                                        dangerouslySetInnerHTML={{__html: t('ev_machine_registration.pop_or_code3')}}/>
                                </div>
                            ) : (
                                <p
                                    className="par-1 --semiBold"
                                    dangerouslySetInnerHTML={{__html: t('ev_machine_registration.pop_text1')}}
                                />
                            )}
                        </>
                    )}
                    <ProofOfPurchaseForm
                        editable={editable}
                        setEditable={setEditable}
                        emptyForm={emptyForm}
                        setEmptyForm={setEmptyForm}
                    />
                </div>
            )}

            {(!stepFilled || editable) && (
                <Button
                    btnType="submit"
                    label={t('continue')}
                    // disabled={mutation.isLoading}
                />
            )}

        </Form>
    )
}

function getDefaultValues(values) {
    return Object.keys(values)
        .reduce((obj, key) => {
            obj[key] = values[key] === null ? undefined : values[key]
            return obj
        }, {})
}
