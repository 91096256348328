import * as orderActions from "../actions/OrderActions";
import {useEffect, useMemo} from "react";
import {reducers} from "../misc/Hooks";
import {useDispatch, useSelector} from "react-redux";
import {asyncAction} from "../helpers";
import * as machineActions from "../actions/MachineActions";
import * as promotionActions from "../actions/PromotionActions";
import * as mainActions from "../actions/MainActions";
import {getDate, uuidv4} from "../misc/Helpers";
import Env from "../utils/Env";

export default function useAbandonedOrder(storeName) {
    const dispatch = useDispatch()
    const reducer = useMemo(() => reducers[storeName], [storeName])
    const store = useSelector(state => ({
        country: state.main.country,
        abandonedOrder: state[storeName]?.abandonedOrder,
        categories: state[storeName]?.categories,
        products: state[storeName]?.products,
    }))

    useEffect(() => {
        if(store.abandonedOrder && store.categories?.length && store.products?.length) {
            parseCart(store.abandonedOrder?.order)
        }
    }, [store.categories, store.products])

    const get = async (uuid) => {
        await asyncAction(orderActions.fetchAbandon, [uuid])
            .then(res => res.data)
            .then(async res => {
                dispatch(reducer.setAbandonedOrder(res.data))
                await fillStore(res.data)
            })
    }

    const fillStore = async ({order}) => {

        dispatch(reducer.setUuid(order.uuid))
        if (!await parseSerialNumber(order)) {
            return
        }

        parseProofOfPurchase(order)
        await parsePromoCode(order)

        dispatch(reducer.setIsNewMember(!!order.isNewMember))

        await parsePromotion(order)
        parseCustomer(order)
    }

    const parseSerialNumber = async (order) => {
        const payload = {
            serial_number: order.serialNumber,
        }
        return await asyncAction(machineActions.validateSerialNumber, [payload, {validate: '0'}])
            .then(res => res.data)
            .then(res => {
                dispatch(reducer.setMachine(res.data.machine, res.data.test_order, {serial_number: order.serialNumber}))
                return true
            })
            .catch(() => false)
    }

    const parseProofOfPurchase = (order) => {
        if (!order.proofOfPurchase || Array.isArray(order.proofOfPurchase))
            return false

        const data = {
            ...order.proofOfPurchase,
            shop: order.proofOfPurchase.retailerStore ?? order.proofOfPurchase.shop,
            shopGroup: order.proofOfPurchase.tradePartner ?? order.proofOfPurchase.shopGroup,
        }

        dispatch(reducer.setProofOfPurchase({
            ...data,
            file: order.proofOfPurchaseLogs.filename,
        }))
        dispatch(reducer.setProofOfPurchaseLogs(order.proofOfPurchaseLogs))
        return true
    }

    const parsePromoCode = async (order) => {
        if(!order.promoCode || Array.isArray(order.promoCode))
            return false

        return asyncAction(mainActions.getPromoCode, [order.promoCode.name, order.machineId ?? 0])
            .then(res => res.data)
            .then(res => {
                dispatch(reducer.setPromoCode(res.data))
                if (res.data.promotion?.length) {
                    dispatch(reducer.setPromotions(res.data.promotion))
                    if(order.promotionId)
                        dispatch(reducer.setPromotion(res.data.promotion[0]))
                }
            })
            .catch(() => false)
    }

    const parseCart = (order) => {
        dispatch(reducer.updateCart(order.cart))
    }

    const parsePromotion = async (order) => {
        if (!order.promotionId || !order.proofOfPurchase || Array.isArray(order.proofOfPurchase))
            return

        // return asyncAction(promotionActions.getById, [order.promotionId])
        return asyncAction(promotionActions.getAll, [
            store.country.id_country,
            order.machineId,
            order.proofOfPurchase.retailerStore?.value ?? order.proofOfPurchase.shop?.value,
            getDate(order.proofOfPurchase.date),
            order.isNewMember ? 0 : 1,
            0,
            {},
        ])
            .then(res => res.data)
            .then(res => {
                const promotion = res.data.find(p => p.id_promotion === order.promotionId)
                dispatch(reducer.setPromotions(res.data))
                dispatch(reducer.setPromotion(promotion))
                return true
            })
            .catch(() => false)
    }

    const parseCustomer = (order) => {
        if (!order.customer || Array.isArray(order.customer))
            return

        if(!order.customer.details && !order.customer.customAddress)
            return

        dispatch(reducer.setCustomer(order.customer))
    }

    return {
        get,
    }
}
