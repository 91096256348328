import Form from "./Form";
import {useForm, useWatch} from "react-hook-form";
import {useFormRulesBuilder} from "./hooks";
import {FormGroup, FormRow} from "./components";
import {EmailInput, FirstnameInput, LastnameInput, ShopGroupSelect, ShopSelect} from "./presets";
import React, {useEffect} from "react";
import {useFieldFormat, useTranslation} from "../hooks";
import {Button} from "../components/buttons/_index";
import {SelectController, TextInputController} from "./controllers";

export default function ResellerRegistrationForm({
    onSubmit,
    state,
    shopFromToken,
}) {

    state = {
        isLoading: false,
        error: null,
        ...state,
    }

    const {t} = useTranslation();
    const {formats} = useFieldFormat()

    const accountRoleOptions = [
        {label: t('reseller_registration.i_am_store_staff'), value: 'pos_user'},
        {label: t('reseller_registration.i_am_nca'), value: 'nca_user'}
    ]

    const defaultValues = {
        account_role: accountRoleOptions[0],
        group: !!shopFromToken ? {
            label: shopFromToken?.shop_group_title,
            value: shopFromToken?.id_shop_group
        } : undefined,
        shop: !!shopFromToken ? {label: shopFromToken?.shop_title, value: shopFromToken?.id_shop} : undefined,
    }

    const formMethods = useForm({
        mode: 'onChange',

        defaultValues
    })
    const formRules = useFormRulesBuilder({
        'login': b => b.required().minLength(6).maxLength(255).pattern(formats.login.pattern),
        'number': b => b.maxLength(255),
        'firstname': b => b.required(),
        'lastname': b => b.required(),
        'account_role': b => b.required(),
        'group': b => b.required(),
        'shop': b => b.required(),
    }, formMethods)

    const handleSubmit = async (data) => {
        if (typeof onSubmit !== 'function') return

        const response = await onSubmit(data).catch(e => console.error(e))
        if (response?.status === 'success') formMethods.reset(defaultValues)
    }

    const login = useWatch({control: formMethods.control, name: 'login'})
    const number = useWatch({control: formMethods.control, name: 'number'})
    const firstname = useWatch({control: formMethods.control, name: 'firstname'})
    const lastname = useWatch({control: formMethods.control, name: 'lastname'})
    const accountRole = useWatch({control: formMethods.control, name: 'account_role'})
    const group = useWatch({control: formMethods.control, name: 'group'})
    const shop = useWatch({control: formMethods.control, name: 'shop'})
    const email = useWatch({control: formMethods.control, name: 'email'})

    useEffect(() => {
        if (state.error) {
            state.error.forEach((e) => {
                formMethods.setError(e.name, {
                    type: "manual",
                    message: t(e.message)
                })
            })
        } else formMethods.clearErrors()

    }, [state.error])

    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            onSubmit={handleSubmit}
        >

            <FormGroup>
                <TextInputController
                    name="login"
                    label={t('reseller_registration.login') || t('login')}
                />
            </FormGroup>

            <FormGroup>
                <TextInputController
                    name="number"
                    label={t('reseller_registration.reseller_number')}
                />
            </FormGroup>

            <FormGroup>
                <FormRow>
                    <FirstnameInput/>
                    <LastnameInput/>
                </FormRow>
            </FormGroup>

            <FormGroup>
                <EmailInput/>
            </FormGroup>

            <FormGroup>
                <SelectController
                    name="account_role"
                    label={t('reseller_registration.account_role')}
                    selectOptions={accountRoleOptions}
                />
            </FormGroup>

            <FormGroup>
                <ShopGroupSelect
                    name="group"
                    options={{
                        inputProps: {
                            isDisabled: !!shopFromToken,
                            disabled: !!shopFromToken
                        }
                    }}
                />
            </FormGroup>

            <FormGroup>
                <ShopSelect
                    groupId={group?.value}
                    options={{
                        inputProps: {
                            isDisabled: !!shopFromToken,
                            disabled: !!shopFromToken
                        }
                    }}
                />
            </FormGroup>

            <Button
                btnType="submit"
                type="primary"
                label={t('continue')}
                disabled={
                    state.isLoading
                    || !login
                    || !number
                    || !firstname
                    || !lastname
                    || !accountRole
                    || !group
                    || !shop
                    || !email
                }
                isLoading={state.isLoading}
            />

        </Form>
    )
}