import React, {useEffect} from "react";
import {Maintenance} from "../components/_index";
import {AppProvider} from "../misc/_index";
import * as configActions from "../actions/ConfigActions";
import {useHistory} from "react-router-dom";
import {useNavigation} from "../misc/Hooks";
import {Routes} from "../utils/_index";


const MaintenanceContainer = (props) => {
    const history = useHistory();
    const navigation = useNavigation();


    useEffect(async () => {
        getConfig().then(recaptchaExists => {
            if(recaptchaExists) {
                history.push(navigation(Routes.CLEAR_STORAGE))
            }
        })
    }, [])


    const getConfig = async () => {
        let recaptchaExists = false;

        await configActions.get().then(r => {
            const res = r.data;
            if (res.status === 'success') {
                recaptchaExists = !!res.data.recaptcha_key;
            } else {
                console.log('Something gone wrong! ', res)
            }
        }).catch(err => {
            console.warn('Error: ', err)
        }).finally(async () => {
            if(!recaptchaExists) {
                await new Promise(resolve => setTimeout(resolve, 20000));
                recaptchaExists = await getConfig();
            }
        })


        return recaptchaExists;
    }

    return (
        <AppProvider>
            <Maintenance/>
        </AppProvider>
    )
}


export default MaintenanceContainer;
