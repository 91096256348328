import {useMemo} from "react";
import {useTranslation} from "../../../hooks";

export default function TopSliderItem({active, onClick, image, title, categories, requiredCategory, ...props}){
    const {t} = useTranslation()
    const className = useMemo(() => {
        let basicClass = 'topBarItem';
        if(active) basicClass += ' -active'
        if(categories) basicClass += ' -categories'

        return basicClass
    }, [active, categories])

    return (
        <div className={className} onClick={onClick}>
            <div className="topBarItem__imageWrap">
                <img src={image} alt=""/>
            </div>
            <span className="topBarItem__title" data-tip={!!requiredCategory ? t('required_category') : ''}>
                {title}
                {!!requiredCategory && <span className="requiredCategory">*</span>}
            </span>

        </div>

    )
}