import {useResellerActions} from "../../../../actions/useResellerActions";
import {useInfiniteQuery, useQuery} from "@tanstack/react-query";
import {useInView} from "react-intersection-observer";
import {useEffect, useRef, useState} from "react";


export default function useComments(messageId) {
    const loadingHandler = useInView()
    const actions = useResellerActions()
    const query = useInfiniteQuery(
        ['reseller.messages.comments', messageId],
        async ({pageParam}) => {
            const {data} = await actions.getThreadMessages(messageId,{page: pageParam, limit: 8})
            if(data.status === 'success')
                return data.data
            throw new Error('error_on_fetch_comments')
        },
        {
            getPreviousPageParam: page => {
                if(!page.current_page)
                    return undefined
                if(page.current_page - 1 >= 0)
                    return page.current_page + 1
                return undefined
            },
            getNextPageParam: page => {
                if(!page.current_page)
                    return undefined
                if(page.current_page + 1 <= page.last_page)
                    return page.current_page + 1
                return undefined
            },
        }
    )

    useEffect(() => {
        if (loadingHandler.inView) {
            query.fetchNextPage()
        }
    }, [loadingHandler.inView])

    const getAllData = () => {
        return query.data?.pages.reduce((arr, item) => {
            arr.push(...item.data)
            return arr
        }, []) ?? []
    }

    return {
        query,
        data: query.data,
        loadingHandler,
        getAllData,
    }
}
