import React from "react";
import {Header} from "../components/misc/_index";
import {useTranslation} from "../misc/Hooks";
import {Config} from "../utils/_index";
import PackageJson from '../../../package.json'


const ClearStorage = (props) => {
    const t = useTranslation();


    return (
        <div className="app">
            <Header cartDisabled/>

            <div className="main">
                <div className="wrapper-bg formContainer">

                    <div className="wrapper">
                        <div className="errorPage" style={{maxWidth: '650px', margin: '0 auto'}}>
                            <h1 className="heading-1">{t('clear_storage.title')}</h1>
                            <h3 className="par-1">{t('clear_storage.text')}</h3>
                            <p className="par-2">v{Config.VERSION}-{PackageJson.version}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ClearStorage;
