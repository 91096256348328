import {MaskInput, TextInput} from "../inputs";
import {useController} from "react-hook-form";
import {useFormHelper} from "../../hooks";
import {useFormFragmentContext} from "../hooks";
import {useMemo} from "react";

/**
 * MaskInputController
 * @param name
 * @param label
 * @param defaultValue
 * @param mask
 * @param options
 * @param rules
 * @returns {JSX.Element}
 * @constructor
 */
export default function MaskInputController({
    name: _name,
    label,
    defaultValue = '',
    mask,
    options,
    rules: _rules,
}) {
    const formHelper = useFormHelper()
    const name = useMemo(
        () => formHelper.formFragmentContext.getInputName(_name),
        [_name, formHelper.formFragmentContext]
    )
    const rules = useMemo(
        () => formHelper.getRules(_rules ?? _name),
        [formHelper, _rules, _name]
    )
    const {
        field: {ref, ...controllerInputProps},
        fieldState,
    } = useController({
        name,
        control: formHelper.formMethods.control,
        defaultValue,
        rules: rules,
    })

    const inputOptions = formHelper.getInputOptions(options, rules)

    if (!mask)
        return (
            <TextInput
                {...controllerInputProps}
                key={JSON.stringify(rules)}
                inputRef={ref}
                fieldState={fieldState}
                label={label}
                options={{
                    ...inputOptions,
                    inputProps: {
                        ...inputOptions?.inputProps,
                        disabled: formHelper.formMethods.state?.isDisabled || inputOptions?.inputProps?.disabled,
                    }
                }}
            />
        )

    return (
        <MaskInput
            {...controllerInputProps}
            key={JSON.stringify(rules)}
            inputRef={ref}
            fieldState={fieldState}
            label={label}
            mask={mask}
            options={{
                ...inputOptions,
                maskInputProps: {
                    ...inputOptions?.maskInputProps,
                    disabled: formHelper.formMethods.state?.isDisabled || inputOptions?.maskInputProps?.disabled,
                }
            }}
        />
    )
}
