import {useMutation} from "@tanstack/react-query";
import {useMemo, useState} from "react";
import {useResellerActions} from "../../../../actions/useResellerActions";
import {ApiException} from "../../../../errors";

export default function useRenewalCertificate(query = null){

    const actions = useResellerActions();

    const renewalMutation = useMutation(
        async (id) => {
            const payload = {id_reseller: typeof id === 'number' ? [id] : id}
            const {data} = await actions.certificateRenewal(payload)

            if (data.status === 'success')
                return data

            throw new ApiException(data.messages[0], data)
        },
        {
            onSuccess: () => {
                setTimeout(() => {
                    if(query) query.refetch()
                }, 1000)
            },
        }
    )

    const label = useMemo(() => {
        if(renewalMutation.isIdle)
            return 'reseller_client.renewal'
        if(renewalMutation.isLoading)
            return 'table.certificate.recertification'
        if(renewalMutation.isSuccess)
            return 'success'
        if(renewalMutation.isError)
            return 'error.unknown_error'
    }, [renewalMutation.status])

    return {
        renewalMutation,
        label
    }
}