import {Config} from "../utils/_index";
import Env from "../utils/Env";
import * as OrderActions from "../actions/OrderActions";
import * as promotionActions from "../actions/PromotionActions";
import {isEnabledByMarket} from "../utils/MarketConfig";


export const getBase64FromFile = (file, accept) => new Promise((resolve, reject) => {
    if (file && accept.includes(file.type)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    }
})


export const changeCartItem = (categories, cart, products, id, value, machine = null, machinePrice = null, customer = null, config = {}) => {
    const product = products.find(p => p.id_product === id);
    if (product) {
        const items = [...(cart?.items ?? [])];
        const item = items.find(i => i.id === id);
        if (item) {
            const idx = items.indexOf(item);
            if (value > 0) {
                if (value <= product.product_max_qty || value < item.value) {
                    item.value = value;
                    items[idx] = item;
                }
            } else {
                items.splice(idx, 1);
            }
        } else {
            items.push({
                id,
                value,
            })
        }

        const sum = JSON.stringify(items);

        if (sum !== cart?.sum) {
            return checkAutoAdd(
                categories,
                products,
                {items, sum},
                machine,
                machinePrice,
                customer,
                true,
                config,
            )
        }
    }

    return checkAutoAdd(categories, products, cart, machine, machinePrice, customer, true, config);
}


export const checkAutoAdd = (categories: [], products, cart, machine = null, machinePrice = null, customer = null, repeat = true, config = {}) => {

    function inCart(product) {
        return !!cart.items.find(i => i.id === product.id_product)
    }

    if (categories.length) {
        categories.map(c => {

            c.products?.map(p => {
                let cartValue = getCartCapsulesValue(cart, products).gross;
                let capsulesCount = getCartCapsulesCountAutoAdd(cart, products, categories);
                let canAddByRules = true

                if (config?.rules) {
                    const productAllowRules = config.rules[Object.keys(config.rules).find(k => Number(k) === p.id_product)]?.allow
                    if (typeof productAllowRules === "object") {
                        productAllowRules.forEach(id => {
                            canAddByRules = !!cart.items.find(cItem => cItem.id === Number(id))
                        })
                    }
                }


                const itemInCart = inCart(p);

                const promotionProduct = p.promotion_product;

                if (promotionProduct?.promotion_product_unlock && itemInCart) {
                    if (
                        cartValue <= Number(promotionProduct.promotion_product_unlock_min_price)
                        || capsulesCount < promotionProduct.promotion_product_unlock_min_products
                        || (promotionProduct.promotion_product_unlock_max_price !== null ? cartValue > Number(promotionProduct.promotion_product_unlock_min_price) : false)
                        || (promotionProduct.promotion_product_unlock_max_products !== null ? capsulesCount > Number(promotionProduct.promotion_product_unlock_max_products) : false)
                    ) {
                        const idx = cart.items.findIndex(i => i.id === p.id_product);
                        cart.items.splice(idx, 1);
                    }
                }


                if (promotionProduct?.promotion_product_add_auto) {
                    const itemInCart = inCart(p);
                    if (
                        cartValue >= Number(promotionProduct.promotion_product_add_auto_min_price)
                        && capsulesCount >= Number(promotionProduct.promotion_product_add_auto_min_products)
                        && (promotionProduct.promotion_product_add_auto_max_price === null ? true : cartValue <= Number(promotionProduct.promotion_product_add_auto_max_price))
                        && (promotionProduct.promotion_product_add_auto_max_products === null ? true : capsulesCount <= Number(promotionProduct.promotion_product_add_auto_max_products))
                        && (promotionProduct.promotion_product_add_auto_min_price_machine === null || !machinePrice ? true : Number(machinePrice) >= promotionProduct.promotion_product_add_auto_min_price_machine)
                        && (promotionProduct.promotion_product_add_auto_max_price_machine === null || !machinePrice ? true : Number(machinePrice) < promotionProduct.promotion_product_add_auto_max_price_machine)
                        // && (promotionProduct.promotion_product_machines?.length ? promotionProduct.promotion_product_machines.includes(String(machine?.id_machine)) : true)
                        && canAddByRules
                    ) {
                        if (!itemInCart) {
                            cart.items.push({id: p.id_product, value: p.product_step});
                        }
                    } else if (itemInCart) {
                        const idx = cart.items.findIndex(i => i.id === p.id_product);
                        cart.items.splice(idx, 1);
                    }
                } else if (c.promotion_category_add_auto) {
                    const itemInCart = inCart(p);
                    if (
                        cartValue >= Number(c.promotion_category_add_auto_min_price)
                        && capsulesCount >= Number(c.promotion_category_add_auto_min_products)
                        && (c.promotion_category_add_auto_max_price === null ? true : cartValue <= Number(c.promotion_category_add_auto_max_price))
                        && (c.promotion_category_add_auto_max_products === null ? true : capsulesCount <= Number(c.promotion_category_add_auto_max_products))
                        && (c.promotion_category_add_auto_min_price_machine === null || !machinePrice ? true : Number(machinePrice) >= c.promotion_category_add_auto_min_price_machine)
                        && (c.promotion_category_add_auto_max_price_machine === null || !machinePrice ? true : Number(machinePrice) < c.promotion_category_add_auto_max_price_machine)
                        && (canAddByRules)
                    ) {
                        if (!itemInCart) {
                            cart.items.push({id: p.id_product, value: p.product_step});
                        }
                    } else if (itemInCart) {
                        const idx = cart.items.findIndex(i => i.id === p.id_product);
                        cart.items.splice(idx, 1);
                    }
                }

                if (customer) {
                    const optInConfig = unlockByOptIn(promotionProduct, c)
                    if (optInConfig.value) {
                        const arr = []

                        if (optInConfig.props.email)
                            arr.push('email')
                        if (optInConfig.props.mobile)
                            arr.push('mobile')
                        if (optInConfig.props.phone)
                            arr.push('phone')
                        if (optInConfig.props.post)
                            arr.push('post')
                        if (optInConfig.props.socialMedia)
                            arr.push('socialMedia')

                        if(customer?.benefits && typeof customer.benefits === 'object') {
                            let valid = !!(arr.length && Object.keys(customer.benefits).length)
                            Object.keys(customer.benefits).map(key => {
                                if (!valid) return
                                if (arr.includes(key) && !customer.benefits[key])
                                    valid = false
                            })

                            const itemInCart = inCart(p);

                            if (valid) {
                                if (!itemInCart) {
                                    cart.items.push({id: p.id_product, value: p.product_step});
                                }
                            } else if (itemInCart) {
                                const idx = cart.items.findIndex(i => i.id === p.id_product);
                                cart.items.splice(idx, 1);
                            }
                        }
                    }
                }
            })
        })
    } else {
        cart = {
            items: [],
            sum: '',
        }
    }

    if (repeat) {
        return checkAutoAdd(categories, products, cart, machine, machinePrice, customer, false, config)
    }

    return cart;
}


export const getPackageCapsulesCount = (product) => {
    let count = 0;
    if (product.product_coffees_in_set && product.product_count_coffees_in_set) {
        Object.keys(product.product_coffees_in_set).map(id => {
            const value = Number(product.product_coffees_in_set[id]);
            if (value > 0) {
                count += value;
            }
        })
    }

    return count;
}


export const getPackageCapsulesLength = (product) => {
    let value = 0;
    if (product.product_coffees_in_set && product.product_count_coffees_in_set) {
        Object.keys(product.product_coffees_in_set).map(id => {
            const v = Number(product.product_coffees_in_set[id]);

            if (v > 0 && !isNaN(v)) {
                value += v;
            }
        })
    }
    return value;
}


export const getCartCapsulesCount = (cart, products) => {
    let count = 0;
    cart?.items?.map(i => {
        const item = products.find(p => p.id_product === i.id);
        if (item) {
            switch (item.product_type) {
                case Config.COFFEE_TYPE.PACKAGE:
                    if (item.product_count_coffees_in_set) {
                        if (item.product_coffees_in_set) {
                            let c = 0;
                            Object.keys(item.product_coffees_in_set).map(id => c += Number(item.product_coffees_in_set[id]))
                            count += i.value * c;
                        }
                        break;
                    }
                    count += i.value * item.product_package_qty;
                    break;
                case Config.COFFEE_TYPE.ACCESSORY:
                    break;
                case Config.COFFEE_TYPE.CAPSULE:
                case Config.COFFEE_TYPE.REBATE:
                case Config.COFFEE_TYPE.PLAN_COFFEE:
                case Config.COFFEE_TYPE.PLAN_WORK_HOME:
                case Config.COFFEE_TYPE.GIFT:
                default:
                    count += i.value * item.product_package_qty;
                    break;
            }
        }
    })
    return count;
}


export const getCartCapsulesCountAutoAdd = (cart, products, categories) => {
    let count = 0;
    cart?.items.map(i => {
        const item = products.find(p => p.id_product === i.id);
        if (item) {
            let promotionProduct = null;
            categories.find(c => promotionProduct = c.products?.find(p => p.id_product === item.id_product)?.promotion_product)

            if (promotionProduct?.promotion_product_add_auto)
                return

            switch (item.product_type) {
                case Config.COFFEE_TYPE.PACKAGE:
                    if (promotionProduct?.promotion_product_unlock || promotionProduct?.promotion_product_add_auto)
                        break;

                    if (item.product_coffees_in_set) {
                        let c = 0;
                        Object.keys(item.product_coffees_in_set).map(id => c += Number(item.product_coffees_in_set[id]))
                        count += i.value * c;
                    }
                    break;
                case Config.COFFEE_TYPE.ACCESSORY:
                    break;
                case Config.COFFEE_TYPE.CAPSULE:
                case Config.COFFEE_TYPE.REBATE:
                case Config.COFFEE_TYPE.PLAN_COFFEE:
                case Config.COFFEE_TYPE.PLAN_WORK_HOME:
                case Config.COFFEE_TYPE.GIFT:
                default:
                    count += i.value * item.product_package_qty;
                    break;
            }
        }
    })

    return count;
}


export const getCartCapsulesValue = (cart, products, vatValue = null) => {
    if (typeof vatValue !== "number")
        vatValue = Number(vatValue);

    let gross = 0;
    let vat = 0;
    let net = 0;
    cart?.items?.map(i => {
        const item = products.find(p => p.id_product === i.id);
        if (item && item.product_type !== Config.COFFEE_TYPE.GIFT
            && item.product_type !== Config.COFFEE_TYPE.EASY_ORDER_GIFT
            && item.product_type !== Config.COFFEE_TYPE.REBATE
            && item.product_type !== Config.COFFEE_TYPE.DELIVERY
            && item.product_type !== Config.COFFEE_TYPE.EXTRA_LINE
        ) {
            const hasPromoPrice = checkPromoPrice(
                parseInt(item.product_price_promo?.status),
                item.product_price_promo?.date_from,
                item.product_price_promo?.date_to,
            );

            const price = hasPromoPrice ? item.product_price_promo
                : item?.product_type?.startsWith('plan_') ? item.product_plan_price : item.product_price;

            price.gross = Number(price.gross);
            price.vat = Number(price.vat);
            price.net = Number(price.net);

            let productGross = 0;
            if (item.product_type === Config.COFFEE_TYPE.PLAN_WORK_HOME || (item.product_type === Config.COFFEE_TYPE.PLAN_MACHINE && isEnabledByMarket('machineSubscriptionPlanBonus'))) {
                if (item?.order_item_plan_machine_price !== undefined && item?.order_item_plan_machine_refund !== undefined) {
                    productGross += Number(item.order_item_plan_machine_price) - Number(item.order_item_plan_machine_refund)
                } else if (item?.product_plan_machine_price !== undefined && item?.product_plan_machine_refund !== undefined) {
                    productGross += Number(item.product_plan_machine_price) - Number(item.product_plan_machine_refund)
                }
            }

            if (vatValue) {
                productGross += i.value * ((price.net * (1 + vatValue)).toFixed(2) * item.product_package_qty)
            } else {
                productGross += i.value * (price.gross.toFixed(2) * item.product_package_qty);
            }
            const productNet = i.value * (Number(price.net) * item.product_package_qty)
            vat += Number((productGross - productNet).toFixed(2));

            gross += productGross;
            net += productNet;
        }
    })

    // console.log('capsulesValue', gross)
    return {gross, vat, net};
}


export const getCartValueByCustomVat = (cart, products, vatValue = null) => {
    if (typeof vatValue !== "number")
        vatValue = Number(vatValue);

    let gross = 0;
    let vat = 0;
    let net = 0;
    cart?.items.map(i => {
        const item = products.find(p => p.id_product === i.id);
        if (item && item.product_type !== Config.COFFEE_TYPE.DELIVERY) {
            const hasPromoPrice = checkPromoPrice(
                parseInt(item.product_price_promo?.status),
                item.product_price_promo?.date_from,
                item.product_price_promo?.date_to,
            );

            const price = hasPromoPrice ? item.product_price_promo
                : item.product_type.startsWith('plan_') ? item.product_plan_price : item.product_price;

            price.gross = Number(price.gross);
            price.vat = Number(price.vat);
            price.net = Number(price.net);

            let productGross = 0

            if (item.product_type === Config.COFFEE_TYPE.PLAN_WORK_HOME || (item.product_type === Config.COFFEE_TYPE.PLAN_MACHINE && isEnabledByMarket('machineSubscriptionPlanBonus'))) {
                if (item?.product_plan_machine_price !== undefined && item.product_plan_machine_refund !== undefined) {
                    productGross += Number(item.product_plan_machine_price) - Number(item.product_plan_machine_refund)
                } else if (item?.order_item_plan_machine_price !== undefined && item.order_item_plan_machine_refund !== undefined) {
                    productGross += Number(item.order_item_plan_machine_price) - Number(item.order_item_plan_machine_refund)
                }
            }

            if (vatValue) {
                productGross += i.value * ((price.net * (1 + vatValue)).toFixed(2) * item.product_package_qty)
            } else {
                productGross += i.value * (price.gross.toFixed(2) * item.product_package_qty);
            }
            const productNet = i.value * (Number(price.net) * item.product_package_qty)
            vat += Number((productGross - productNet).toFixed(2));

            gross += productGross;
            net += productNet;
        }
    })

    return {gross, vat, net};
}


export const getCartGiftValue = (cart, products, capsulesValue = null) => {
    let gross = 0;
    let vat = 0;
    let net = 0;
    cart?.items
        ?.map(i => products.find(p => p.id_product === i.id))
        .filter(p => [Config.COFFEE_TYPE.GIFT, Config.COFFEE_TYPE.EASY_ORDER_GIFT, Config.COFFEE_TYPE.REBATE].includes(p?.product_type))
        .sort(a => a.product_type === Config.COFFEE_TYPE.REBATE ? 1 : -1)
        .map(item => {
            if (item.product_percentage_rebate) {
                const percentageRebate = Number(item.product_percentage_rebate) / 100
                if (!capsulesValue)
                    capsulesValue = getCartCapsulesValue(cart, products)
                gross -= capsulesValue.gross * percentageRebate
                net -= capsulesValue.net * percentageRebate
            } else {
                const cartItem = cart.items.find(i => i.id === item.id_product)

                const hasPromoPrice = checkPromoPrice(
                    parseInt(item.product_price_promo?.status),
                    item.product_price_promo?.date_from,
                    item.product_price_promo?.date_to,
                );

                const price = item.product_price;
                if (price && cartItem) {
                    if (!hasPromoPrice) {
                        gross += cartItem.value * Number(price.gross);
                        net += cartItem.value * Number(price.net);
                    }
                } else {
                    console.error(`Fill price for [product=${cartItem.id}]`)
                }
            }
        })

    // console.log('giftValue', gross)
    return {gross, vat, net};
}

export const getCartProductsPromoPriceBenefitValue = (cart, products, capsulesValue = null) => {
    let gross = 0;
    let vat = 0;
    let net = 0;
    cart?.items
        ?.map(i => products.find(p => p.id_product === i.id))
        .sort(a => a.product_type === Config.COFFEE_TYPE.REBATE ? 1 : -1)
        .map(item => {
            if (!item) return

            const cartItem = cart.items.find(i => i.id === item?.id_product)

            const hasPromoPrice = checkPromoPrice(
                parseInt(item?.product_price_promo?.status),
                item?.product_price_promo?.date_from,
                item?.product_price_promo?.date_to,
            );

            if (!hasPromoPrice) return

            const price = item?.product_price;
            const pricePromo = item?.product_price_promo;
            if (price && pricePromo && cartItem) {
                gross += (cartItem.value * Number(price.gross)) - (cartItem.value * Number(pricePromo.gross));
                net += (cartItem.value * Number(price.net)) - (cartItem.value * Number(pricePromo.net));
            } else {
                console.error(`Fill price for [product=${cartItem.id}]`)
            }
        })

    // console.log('getCartProductsPromoPriceBenefitValue', gross)

    return {gross, vat, net};
}

export const getCartGiftValueWithoutPromo = (cart, products) => {
    let gross = 0;
    let vat = 0;
    let net = 0;
    cart?.items
        ?.map(i => products.find(p => p.id_product === i.id))
        .filter(p => [Config.COFFEE_TYPE.GIFT, Config.COFFEE_TYPE.EASY_ORDER_GIFT, Config.COFFEE_TYPE.REBATE].includes(p?.product_type))
        .sort(a => a.product_type === Config.COFFEE_TYPE.REBATE ? 1 : -1)
        .map(item => {
            const cartItem = cart.items.find(i => i.id === item.id_product)

            const hasPromoPrice = checkPromoPrice(
                parseInt(item.product_price_promo?.status),
                item.product_price_promo?.date_from,
                item.product_price_promo?.date_to,
            );

            const price = item.product_price;
            if (hasPromoPrice && cartItem) {
                gross += cartItem.value * Number(price.gross);
                net += cartItem.value * Number(price.net);
            }
        })
    // console.log('giftValueWithoutPromo', gross)
    return {gross, vat, net};
}


export const getCartDeliveryValue = (cart, products) => {
    let gross = 0;
    let vat = 0;
    let net = 0;
    cart?.items?.map(i => {
        const item = products.find(p => p.id_product === i.id);
        if (item && item.product_type === Config.COFFEE_TYPE.DELIVERY) {
            const price = item.product_price;
            if (price) {
                gross += i.value * Number(price.gross);
                net += i.value * Number(price.net);
            } else {
                console.error(`Fill price for [product=${i.id}]`)
            }
        }
    })
    return {gross, vat, net};
}


export const getCategories = (categories = []) => {
    // console.log(categories)
    categories = Object.values(categories)
        .sort((a, b) => a.promotion_category_position < b.promotion_category_position ? -1 : 0)
        .filter(c => {
            const lockedByOptIn = unlockByOptIn(null, c)?.value
                || c.products.find(p => unlockByOptIn(p.promotion_product, null)?.value)
            return lockedByOptIn || (c.category.category_type === Config.COFFEE_TYPE.CAPSULE
                || c.category.category_type === Config.COFFEE_TYPE.PACKAGE
                || c.category.category_type === Config.COFFEE_TYPE.PLAN_COFFEE
                || c.category.category_type === Config.COFFEE_TYPE.EASY_ORDER_GIFT
                || c.category.category_type === Config.COFFEE_TYPE.ACCESSORY)
        })
    // .sort((a,b) => a.category.category_position < b.category.category_position ? -1 : 0);

    const cats = [];

    categories.map(c => {
        const {category, products, subcategories, ...promotionCategory} = c;
        if (c.category.subcategories.length) {
            const {products, subcategories, ...parentCategory} = c.category
            cats.push(...c.category.subcategories
                .sort((a, b) => a.category_position < b.category_position ? -1 : 0)
                .map(cat => ({
                    ...cat,
                    parentCategory,
                    promotionCategory
                })));
        } else {
            cats.push({
                ...c.category,
                products: c.products,
                parentCategory: null,
                promotionCategory,
            });
        }
    })

    return cats;
}


export const regexFromString = (regex) => {
    if (!regex)
        return undefined;

    let re = null;

    regex = regex.replace(/^\//, '');

    let flag = regex.match(/\/[a-zA-Z0-9]+$/);
    if (flag) {
        regex = regex.replace(flag[0], '');
        flag = flag[0]?.replace('/', '')
        re = new RegExp(regex, flag);
    } else {
        regex = regex.replace(/\/$/, '');
        re = new RegExp(regex);
    }
    return re;
}


export const getImage = (json, type = Config.GALLERY_TYPE.COVER) => {
    if (!json) {
        return '';
    }


    if (typeof json !== 'object') {
        json = JSON.parse(json);
    }


    const item = Object.values(json).find(i => i?.type?.toString().toLowerCase() === type)
    if (item) {
        return `${Env.APP_STORAGE}/${item.path}`;
    }
    return '';
}


export const getFileUrl = (file) => {
    if (!file)
        return '';

    if (file.path?.startsWith('http'))
        return file.path;

    return `${Env.APP_STORAGE}/${file.path}`
}


export const getStepHeaderHeight = () => {
    return document.querySelector('.step')?.scrollHeight ?? 0;
}

export const getAllStepsHeaderHeight = () => {
    let height = 0
    const steps = Array.from(document.querySelectorAll('.step'))
    for (const idx in steps) {
        const step = steps[idx]
        if (!step) continue
        if (step.classList.contains('-active')) {
            const heightModifier = Number(idx) + 1 < steps.length ? 0 : 20
            height += step.querySelector('.step__header').scrollHeight + heightModifier ?? 0
            break
        } else {
            height += step.scrollHeight ?? 0
        }
    }
    return height
}


export const isCheckboxChecked = (values, name) => {
    return !!values.find(a => a === name);
}

export const handleCheckboxChange = (values, name) => {
    let res = [...values];
    const idx = res.findIndex(a => a === name);
    if (idx !== -1) {
        res.splice(idx, 1);
    } else {
        res.push(name);
    }
    return res;
}

export const isCheckboxArrayChecked = (values, names) => {
    return !!values.find(a => names.includes(a));
}

export const handleCheckboxArrayChange = (values, names = []) => {
    return names.reduce((values, name) => {
        const idx = values.findIndex(a => a === name);
        if (idx !== -1) {
            values.splice(idx, 1);
        } else {
            values.push(name);
        }
        return values
    }, values)
}


export const getCivilityByTitle = (title) => {
    switch (title) {
        case 'mr':
            return 1;
        case 'mrs':
            return 2;
        default:
            return null;
    }
}


export const scrollVertically = (element, customOffset = 0, behavior = undefined) => {
    if (element) {
        const headerHeight = document.querySelector('.header')?.clientHeight ?? 0;

        let offset = element.getBoundingClientRect().top + document.documentElement.scrollTop;
        offset -= headerHeight + customOffset;

        window.scrollTo({top: offset, behavior})
    }
}


export const checkPromoPrice = (status, dateFrom, dateTo) => {
    if (!status)
        return false;
    const from = new Date(dateFrom).getTime();
    const to = new Date(dateTo).getTime();
    const now = Date.now();
    return now > from && now < to;
}


export const mapStackToOptions = (stack) => {
    const arr = [];
    stack?.map(s => {
        arr.push({
            value: s.stack_value,
            label: s.stack_title,
        })
    })
    return arr;
}


export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function isoDateConverter(iso, separator = '/') {
    if (!iso) return null;
    const date = new Date(iso);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear()

    return year + separator + (month < 10 ? '0' + month : month) + separator + (day < 10 ? '0' + day : day);
}

export function isoDateConverterWithTime(iso, separatorDate = '/', separatorTime = ":") {
    const date = new Date(iso);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds()

    return year + separatorDate + (month < 10 ? '0' + month : month) + separatorDate + (day < 10 ? '0' + day : day) + ' ' + hours + separatorTime + (minutes < 10 ? '0' + minutes : minutes) + separatorTime + (seconds < 10 ? '0' + seconds : seconds);
}


export function getFieldFormat(object) {
    if (!object)
        object = {}

    let pattern = object.pattern ?? undefined
    if (typeof pattern === 'string')
        pattern = regexFromString(object.pattern)

    const formats = {
        ...object,
        pattern,
        inputMask: object.mask ?? null,
        max_length: object.max_length ? parseInt(object.max_length) : null,
        required: typeof object.required === 'undefined' ? 1 : object.required ? parseInt(object.required) : 0,
        mask_status: object.mask_status ? parseInt(object.mask_status) : 0,
        reseller_required: typeof object.reseller_required === 'undefined' ? 1 : object.reseller_required ? parseInt(object.reseller_required) : 0,
        reseller_status: object.reseller_status ? parseInt(object.reseller_status) : 0,
        status: object.status ? parseInt(object.status) : 0,
    }

    const getMask = () => {
        if (!formats.mask_status)
            return null
        return formats.inputMask
    }

    return {
        ...formats,
        getMask,
    }
}

export function getURLSearchParams(object) {
    if (!object) return ''

    return Object.keys(object).reduce((prev, key) => {
        const value = object[key]
        if (!value) return prev
        return `${prev}&${key}=${value}`.replace(/\?&/, '?');
    }, '?');
}

export function removeSpecialChars(text) {
    return text.replace(/(<.+?>)|(<\/.+?>)/g, '');
}

export function getDate(date) {
    if (!date) return null
    if (typeof date === 'string')
        date = new Date(date)

    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear().toString()
    return `${year}-${month}-${day}`
}


export function getTime(date) {
    if (!date) return null
    if (typeof date === 'string')
        date = new Date(date)

    const hour = date.getHours()
    const minute = date.getMinutes()
    return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
}


export function getDateTime(date) {
    return `${getDate(date)} ${getTime(date)}`
}

export function shortText(text, count = 70) {
    let shortText = '';

    if (typeof text !== "string") return shortText

    const arr = text.split(' ');

    if (text.length > count) {
        text = text.replace(/(<.+?>)|(<\/.+?>)/g, '')
        const specialChars = text.substr(0, count).match(/(<.+?>)|(<\/.+?>)|(&.+?;)/g)
        const specialCharsLength = specialChars?.reduce((length, string) => length + string.length, 0) ?? 0

        shortText = text.substr(0, count + specialCharsLength);
        const idx = shortText.lastIndexOf(' ');
        shortText = shortText.substr(0, idx);

        shortText += ' ...';
        return {
            text: shortText,
            hasMore: true,
        };
    }

    return {
        text,
        hasMore: false,
    };
}


/**
 * Calculate member credit
 * @param {object} data Member credit data
 * @param {number} orderValue Order value
 * @returns {{charge: number, available: number, remaining: number}}
 */
export function calcMemberCredit(data, orderValue) {
    if (!data)
        return null

    const available = data.available.total
    let remaining = data.available.total - data.used.total
    if (remaining < 0) {
        remaining = 0
    }
    let charge = data.available.total - orderValue
    if (charge > 0) {
        charge = 0
    } else {
        charge = Math.abs(charge)
    }

    return {
        available,
        remaining,
        charge,
    }
}


export function mapOrder(order) {
    const categories = [];
    const products = [];
    const cart = {items: [], sum: ''}

    order.items.map(p => {
        products.push({
            ...p,
            id_product: p.id_product,
            id_category: p.id_category,
            product_code: p.product_code,
            product_type: p.product_type,
            product_name: p.product_name,
            product_title: p.product_title,
            product_price: {
                net: p.order_item_price_net,
                gross: p.order_item_price_gross,
                vat: p.order_item_price_vat,
            },
            product_percentage_rebate: p.order_item_percentage_rebate,
            product_plan_price: {
                net: p.order_item_plan_price_net,
                gross: p.order_item_plan_price_gross,
                vat: p.order_item_plan_price_vat,
            },
            product_plan_marketing_email_visible: p.product_plan_marketing_email_visible,
            product_plan_marketing_mobile_visible: p.product_plan_marketing_mobile_visible,
            product_plan_marketing_phone_visible: p.product_plan_marketing_phone_visible,
            product_plan_marketing_post_visible: p.product_plan_marketing_post_visible,
            product_plan_marketing_social_media_visible: p.product_plan_marketing_social_media_visible,
            product_plan_marketing_required: p.product_plan_marketing_required,
            product_plan_marketing_select_all_benefits_enabled: p.product_plan_marketing_select_all_benefits_enabled,
            product_step: p.product_step,
            product_package_qty: p.product_package_qty,
            product_gallery: p.product_gallery,
            product_files: p.product_files,
        })

        if (!categories.find(c => c.id_category === p.id_category)) {
            categories.push({
                id_category: p.id_category,
                category_name: p.category_name,
                category_title: p.category_title,
                category_type: p.category_type,
            })
        }


        cart.items.push({
            id: p.id_product,
            value: Number(p.order_item_qty) > 0 ? p.order_item_qty / p.product_package_qty : p.product_step,
        })
    })

    return {categories, products, cart}
}

export function mapOrderV2(order) {
    const categories = [];
    const products = [];
    const cart = {items: [], sum: ''}

    order.items.map(p => {
        products.push({
            ...p,
            id_product: p.id_product,
            id_category: p.id_category,
            product_code: p.product_code,
            product_type: p.product_type,
            product_name: p.product_name,
            product_title: p.product_title,
            product_price: {
                net: p.order_item_price_net,
                gross: p.order_item_price_gross,
                vat: p.order_item_price_vat,
            },
            product_percentage_rebate: p.order_item_percentage_rebate,
            product_plan_price: {
                net: p.order_item_plan_price_net,
                gross: p.order_item_plan_price_gross,
                vat: p.order_item_plan_price_vat,
            },
            product_plan_marketing_email_visible: p.product_plan_marketing_email_visible,
            product_plan_marketing_mobile_visible: p.product_plan_marketing_mobile_visible,
            product_plan_marketing_phone_visible: p.product_plan_marketing_phone_visible,
            product_plan_marketing_post_visible: p.product_plan_marketing_post_visible,
            product_plan_marketing_social_media_visible: p.product_plan_marketing_social_media_visible,
            product_plan_marketing_required: p.product_plan_marketing_required,
            product_plan_marketing_select_all_benefits_enabled: p.product_plan_marketing_select_all_benefits_enabled,
            product_step: p.product_step,
            product_package_qty: p.product_package_qty,
            product_gallery: p.product_gallery,
            product_files: p.product_files,
        })

        if (!categories.find(c => c.category.id_category === p.id_category)) {

            categories.push({
                category: {
                    id_category: p.id_category,
                    category_name: p.category_name,
                    category_title: p.category_title,
                    category_type: p.category_type,
                }
            })
        }


        cart.items.push({
            id: p.id_product,
            value: Number(p.order_item_qty) > 0 ? p.order_item_qty / p.product_package_qty : p.product_step,
        })
    })

    const arr = [...categories]

    arr.map(c => {
        c.products = products.filter(p => p.id_category === c.category.id_category);
    })

    return {
        categories: arr.sort((a, b) => {
            if (
                a.category.category_type === 'gift'
                || a.category.category_type === 'voucher'
                || a.category.category_type === 'delivery'
            )
                return 1;
            if (
                a.category.category_type === 'package'
                || a.category.category_type === 'capsule'
                || a.category.category_type?.startsWith('plan')
            )
                return -1;
        }),
        products,
        cart
    }
}

export async function getOrder(token) {
    let categories = [];
    let products = [];
    let cart = {items: [], sum: ''}
    let order = null;
    let promotion = null
    await OrderActions.getOrderByUuid(token).then(async r => {
        const res = r.data;
        if (res.status === 'success') {
            order = res.data;
            const mappedOrder = mapOrder(res.data)
            categories = mappedOrder.categories
            products = mappedOrder.products
            cart = mappedOrder.cart

            if (order.id_promotion) {
                if (order.promotion) {
                    promotion = order.promotion
                } else {
                    await promotionActions.getById(order.id_promotion)
                        .then(r => {
                            const res = r.data;
                            if (res.status === 'success') {
                                promotion = res.data
                            } else {
                                console.error('Cannot get promotion')
                            }
                        })
                        .catch(err => {
                            console.error('Error: ', err)
                        })
                }
            }
        } else {
            if (res.messages?.length) {
                if (res.messages[0] === 'order_not_exist') {
                    // history.push(navigation(Routes.SHOP))
                }
            }
        }
    }).catch(err => {
        console.error('Error: ', err);
    })
    return {cart, products, categories, promotion, order};
}

export function mapOrderToCustomer(
    order,
    civilityOptions,
    professionOptions = [],
    regionOptions = [],
) {
    if (!order)
        return {};
    return {
        companyAddress: order.delivery_is_company,
        companyName: order.delivery_company_name,
        profession: professionOptions.find(o => o.value == order.delivery_company_profession),
        title: civilityOptions.find(o => Number(o.value) === Number(order.delivery_civility)),
        taxId: order.delivery_company_tax_id,
        name: order.delivery_firstname,
        surname: order.delivery_lastname,
        address: order.delivery_address1,
        address2: order.delivery_address2,
        postcode: order.delivery_zipcode,
        city: order.delivery_city,
        country: order.delivery_country,
        phone: order.delivery_phone,
        phoneMobile: order.delivery_phone_mobile,
        phoneHome: order.delivery_phone_home,
        email: order.delivery_email,

        withCustomAddress: order.other_delivery,
        customCompanyAddress: order.other_delivery_is_company,
        customCompanyName: order.other_delivery_company_name,
        customTitle: civilityOptions.find(o => Number(o.value) === Number(order.other_delivery_civility)),
        customTaxId: order.other_delivery_company_tax_id,
        customName: order.other_delivery_firstname,
        customSurname: order.other_delivery_lastname,
        customAddress: order.other_delivery_address1,
        customAddress2: order.other_delivery_address2,
        customPostcode: order.other_delivery_zipcode,
        customCity: order.other_delivery_city,
        customCountry: order.other_delivery_country,
        customPhone: order.other_delivery_phone,
        customPhoneMobile: order.other_delivery_phone_mobile,
        customPhoneHome: order.other_delivery_phone_home,
        benefits: {
            email: !!order.marketing_email,
            mobile: !!order.marketing_mobile,
            phone: !!order.marketing_phone,
            post: !!order.marketing_post,
            socialMedia: !!order.marketing_social_media,
        },
        termsAndConditions: order.regulations,
        privacyPolicy: order.privacy_policy,
        pdBsbNumber: order.payment_bsb_number,
        pdAccountNumber: order.payment_account_number,
        pdDataConfirmed: order.payment_data_confirmed,
        paymentMethodCodeVerify: {
            idBank: order.id_bank,
            bankId: order.bank_id,
            bankBranch: order.bank_branch,
            bankName: order.bank_name,
            accountNumber: order.bank_account_number,
        },
        paymentMethodIbanProof: {
            uploadedFile: {
                filename: order.filename_iban,
            },
        },
    };
}

export function mapOrderToCustomer2(
    order,
    civilityOptions,
    professionOptions,
    regionOptions,
    countryOptions,
) {
    if (!order)
        return {};

    const res = {
        details: {
            memberAddress: order.address_id,
            isCompanyAddress: !!order.delivery_is_company,
            companyName: order.delivery_company_name,
            profession: professionOptions.options.find(o => o.value == order.delivery_company_profession),
            title: civilityOptions.options.find(o => Number(o.value) === Number(order.delivery_civility)),
            companyTaxId: order.delivery_is_company ? order.delivery_company_tax_id : undefined,
            taxId: order.delivery_is_company ? undefined : order.delivery_company_tax_id,
            firstname: order.delivery_firstname,
            lastname: order.delivery_lastname,
            addressLine1: order.delivery_address1,
            addressLine2: order.delivery_address2,
            postcode: order.delivery_zipcode,
            city: order.delivery_city,
            country: countryOptions?.findCountry(order.delivery_country),
            phone: order.delivery_phone,
            phoneMobile: order.delivery_phone_mobile,
            phoneHome: order.delivery_phone_home,
            email: order.delivery_email,
        },
        withCustomAddress: !!order.other_delivery,
        benefits: {
            email: order.marketing_email,
            mobile: order.marketing_mobile,
            phone: order.marketing_phone,
            post: order.marketing_post,
            socialMedia: order.marketing_social_media,
        },
        terms: {
            termsAndConditions: !!order.regulations,
            privacyPolicy: !!order.privacy_policy,
            privacyPolicyUseOfPersonalDataForMarketing: !!order.privacy_policy_use_of_personal_data_for_marketing,
            privacyPolicyPersonalizedManagement: !!order.privacy_policy_personalized_management,
            privacyPolicyUseSatisfactionResearch: !!order.privacy_policy_use_satisfaction_research,
        },
    };

    if (order.other_delivery) {
        res.customAddress = {
            memberAddress: order.other_address_id,
            isCompanyAddress: order.other_delivery_is_company,
            companyName: order.other_delivery_company_name,
            title: civilityOptions.options.find(o => Number(o.value) === Number(order.other_delivery_civility)),
            companyTaxId: order.other_delivery_is_company ? order.other_delivery_company_tax_id : undefined,
            taxId: order.other_delivery_is_company ? undefined : order.other_delivery_company_tax_id,
            firstname: order.other_delivery_firstname,
            lastname: order.other_delivery_lastname,
            addressLine1: order.other_delivery_address1,
            addressLine2: order.other_delivery_address2,
            postcode: order.other_delivery_zipcode,
            city: order.other_delivery_city,
            country: countryOptions?.findCountry(order.other_delivery_country),
            phone: order.other_delivery_phone,
            phoneMobile: order.other_delivery_phone_mobile,
            phoneHome: order.other_delivery_phone_home,
        }
    }

    if (order.payment_bsb_number || order.payment_account_number) {
        res.paymentDetails = {
            bsbNumber: order.payment_bsb_number,
            accountNumber: order.payment_account_number,
            reAccountNumber: order.payment_account_number,
            isAccountNumberConfirmed: !!order.payment_data_confirmed,
        }
    }

    if (order.bank_branch || order.bank_account_number) {
        res.paymentMethodCodeVerify = {
            idBank: order.id_bank,
            bankId: order.bank_id,
            bankBranch: order.bank_branch,
            bankName: order.bank_name,
            accountNumber: order.bank_account_number,
        }
    }

    if (order.filename_iban) {
        res.paymentMethodIbanProof = {
            uploadedFile: {
                filename: order.filename_iban,
            },
        }
    }

    return res
}


export function queryParams(obj) {
    if (!obj) return ''
    obj = Object.keys(obj)
        .filter(k => typeof obj[k] !== 'undefined')
        .filter(k => typeof obj[k] === 'string' ? obj[k] : true)
        .reduce((newObj, k) => {
            newObj[k] = obj[k]
            return newObj
        }, {})
    return Object.keys(obj).length ? `?${new URLSearchParams(obj).toString()}` : ''
}

export function unlockByOptIn(product, category) {
    const props = {
        email: !!(product?.promotion_product_required_marketing_email
            || category?.promotion_category_required_marketing_email),
        mobile: !!(product?.promotion_product_required_marketing_mobile
            || category?.promotion_category_required_marketing_mobile),
        phone: !!(product?.promotion_product_required_marketing_phone
            || category?.promotion_category_required_marketing_phone),
        post: !!(product?.promotion_product_required_marketing_post
            || category?.promotion_category_required_marketing_post),
        socialMedia: !!(product?.promotion_product_required_marketing_post
            || category?.promotion_category_required_marketing_social_media),
    }

    return {
        value: !!(props.email || props.mobile || props.phone || props.post)
            && product?.id_promotion_category === category?.id_promotion_category,
        props,
    }
}


/**
 *
 * @param quotationCart
 * @returns {{subtotal: number, tax: number, benefit: number}|null}
 */
export function calcQuotationCart(quotationCart) {
    if (!quotationCart)
        return null
    return quotationCart.reduce(({subtotal, tax, benefit}, item) => {
        if (item.finance.amount.productPrice < 0) {
            benefit += item.product.unitPrice * item.product.quantity
        } else {
            subtotal += item.product.unitPrice * item.product.quantity
        }
        if (item.finance.amount.tax) {
            tax += item.finance.amount.tax
        }

        return {subtotal, tax, benefit}
    }, {subtotal: 0, tax: 0, benefit: 0})
}

export function getStepsRoutes(steps) {
    return steps.reduce((routes, step) => {
        if (Array.isArray(step.route)) {
            routes.push(...step.route)
        } else {
            routes.push(step.route)
        }
        return routes
    }, [])
}

export function toUpperCamelCase(variableName) {
    if (typeof variableName !== "string") return variableName;
    return variableName.replace(/_([a-z])/g, function (g) {
        return g[1].toUpperCase();
    })
}

