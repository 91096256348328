import {useCurrentLanguage} from "../../../hooks";
import {getTime} from "../../../misc/Helpers";
import {useMemo} from "react";

export default function usePickupPoint({pickupPoint}) {
    const {currentLanguage} = useCurrentLanguage()
    const openingHours = useMemo(() => {
        return getOpeningHours(pickupPoint)
    }, [pickupPoint])

    function getOpeningHours(pickupPoint) {
        if (!pickupPoint)
            return null

        return pickupPoint.currentOpenHours.map((o, idx) => {
            const date = new Date()
            date.setMonth(0)
            date.setDate(idx + 2)

            const openingHours = o.openingHours.map(i => {
                const from = new Date()
                from.setHours(i.openingTime.hour)
                from.setMinutes(i.openingTime.minute)
                from.setSeconds(0)

                const to = new Date()
                to.setHours(i.closingTime.hour)
                to.setMinutes(i.closingTime.minute)
                to.setSeconds(0)

                return `${getTime(from)} - ${getTime(to)}`
            })

            return {
                day: date.toLocaleDateString(currentLanguage, {weekday: 'long'}),
                isOpen: o.isOpen,
                openingHours,
            }
        })
    }

    function getAddress(pickupPoint) {
        if (!pickupPoint)
            return {}

        return {
            addressLine: pickupPoint?.addresses[0]?.addressLine1,
            city: pickupPoint?.addresses[0]?.city,
            zipCode: pickupPoint?.addresses[0]?.zipCode,
        }
    }

    function getLabel(pickupPoint) {
        if (!pickupPoint)
            return null

        const address = getAddress(pickupPoint)
        return `${pickupPoint.name} - ${address.addressLine}, ${address.zipCode} ${address.city}`
    }

    return {
        pickupPoint,
        openingHours,
        getOpeningHours,
        getAddress,
        getLabel,
    }
}
