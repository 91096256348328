const config = {
    /**
     * Bigger promo select button
     */
    biggerPromoSelectButton: ['it'],

    /**
     * Auto uppercase in post code input
     */
    autoUppercasePostCode: ['ca'],

    /**
     * Image and text under customer information form
     */
    customBenefitsInfo: ['ca'],

    /**
     * Select the first available country in form by default
     */
    selectDefaultFirstCountry: ['fr', 'ca', 'it', 'au'],

    /**
     * Select the default lang in form by default
     */
    selectDefaultLang: ['fr', 'ca', 'it', 'au', 'ro'],

    /**
     * Hide promo title
     */
    hidePromoTitle: [],

    /**
     * Small form submit button (confirm/buy button)
     */
    smallFormSubmitButton: ['ch'],

    /**
     * Hide total price info (summary step) in machine subscription module
     */
    hideTotalPriceInfoMachineSubscription: ['nl'],

    /**
     * Hide ingredients in Coffee Item Modal
     */
    hideIngredients: ['belux'],

    /**
     * Hide ingredients and allergens in Coffee Item Modal
     */
    hideIngredientsAndAllergens: ['belux'],

    /**
     * Sefaz invoice number validation
     */
    sefazInvoiceNumberValidation: ['br'],

    /**
     * Display toggle button for show/hide today machine subscription orders
     */
    hideTodayMachineSubscriptionOrders: ['hu'],

    /**
     * Disable ecapi - hide customer identification, recaptcha
     */
    disableEcapi: ['il'],

    /**
     * Display "coffees in set" count in product package modal
     */
    displayCoffeesInSetCount: ['ca'],

    /**
     * Hide capsules total in CartSummarySimple
     */
    hideSummaryTotalCapsules: ['cz'],

    /**
     * Show button "Where can I find it?" above input serial number
     */
    serialNumberModalButtonTop: ['us'],

    /**
     * Show button "Where can I find it?" above input upload POP picture
     */
    POPModalButtonTop: ['us'],

    /**
     * Show plan bonus in machine subscription
     */
    machineSubscriptionPlanBonus: ['nl'],

    /**
     * Display default image in left column in identification step. Fill right column with texts.
     */
    defaultImageEnabledInStayHomeIdentificationStep: ['nl'],

    /**
     * Disable customer another delivery address in subscription module
     */
    disableAnotherAddressSubscription: ['nl'],

    /**
     * Hide no options message in select
     */
    hideNoOptionsMessage: ['il'],

    /**
     * Hide errors when it doesn’t recognize the invoice details after uploading a picture.
     */
    hideUploadPopErrors: ['il'],

    /**
     * BenefitsInfo under benefits checkboxes
     */
    benefitsInfoUnderCheckboxes: ['il', 'us'],

    /**
     * Hide text "INSTEAD OF..." in machine subscription if machineSubscriptionPlanBonus is enabled.
     */
    hideSubscriptionPlanItemOriginalMachinePrice: ['nl'],

    /**
     * Disable barcode scanner
     */
    disableBarcodeScanner: ['il'],

    /**
     * Disable barcode scanner
     */
    hidePaymentStatusReseller: ['it', 'fr'],

    /**
     * Hide contact footer in reseller panel
     */
    hideContactFooterInResellerPanel: ['nl'],

    /**
     * Parse proof of purchase machine price value to price format (ex. 100.20 eur)
     */
    parseMachinePriceToPriceFormat: ['at'],

    /**
     * Display comma instead of dot in price format. Ex. 10,50$ instead of 10.50%
     */
    commaInsteadOfDotInPriceFormat: ['at'],

    /**
     * Promo code help modal is visible
     */
    promoCodeHelpModal: ['it'],

    /**
     * Invert Given and Surname in Customer Details Form
     */
    invertGivenAndSurnameNamesInCustomerDetailsSummary: ['jp'],

    /**
     * Hide title label from customer details summary
     */
    hideTitleLabelSummary: ['jp'],
}

const stacks = {
    default: [
        'coffee_type',
        'cup_size',
        'aromatic_profile',
        'civility',
        'coffee_line',
        'accessory_type',
    ],
    gr: ['profession'],
    cl: ['region'],
    us: ['region'],
    co: ['tax_person', 'tax_company'],
}

const images = {
    default: {
        machine: require('../assets/img/machine.png'),
        helpMachine: require('../assets/img/sn-help-machine.png'),
        helpNumber: require('../assets/img/sn-help-number.png'),
        customBenefitsInfo: require('../assets/img/ca-opt-in.jpg'),
        emailAmazon: require('../assets/img/mail-amazon.png'),
        modal_pop1: require('../assets/img/pop-pic.png')
    },
    it: {
        machine: require('../assets/img/machine-it.png'),
        helpMachine: require('../assets/img/sn-help-machine-it.jpg'),
        helpNumber: require('../assets/img/sn-help-number-it.jpg'),
        modal_pop1: require('../assets/img/pop-ca.png')

    },
    ca: {
        machine: require('../assets/img/machine-ca.png'),
    }
}

const benefitsOrder = {
    default: ['mobile', 'email', 'phone', 'post', 'socialMedia'],
    it: ['email', 'mobile', 'phone', 'post', 'socialMedia'],
    belux: ['phone', 'mobile', 'email', 'post', 'socialMedia'],
    co: ['email', 'mobile', 'phone', 'post', 'socialMedia'],
}

const summaryOrder = {
    default: ['subtotal', 'benefit', 'memberCreditAvailable', 'tax', 'delivery', 'delivery_info', 'total', 'memberCredit', 'summary_info'],
    br: ['subtotal', 'benefit', 'delivery', 'delivery_info', 'total', 'summary_info'],
    ca: ['subtotal', 'benefit', 'subtotal_after_benefit', 'tax', 'delivery', 'total', 'delivery_info', 'summary_info']
}

const summaryStayHomeOrder = {
    default: ['plan_subtotal', 'plan_benefit', 'plan_price', 'machine_price', 'delivery' , 'total', 'memberCredit', 'summary_info']
}

const filtersOrder = {
    jp: ['coffee_line', 'aromatic_profile', 'coffee_type', 'cup_size', 'intensity', 'accessory_type'],
}

const priceFormat = {
    co: 'es-CO',
}

const defaultCivility = {
    default: null,
    kr: 6,
}

const languageLabel = {
    br: {
        pt: 'pb',
    },
}

const customerDetailsForm = {
    default: [
        ['isCompanyAddress'],
        ['title'],
        ['taxId'],
        ['companyName'],
        ['companyTaxId'],
        ['firstname', 'lastname'],
        ['postcode', 'city'],
        ['addressLine1'],
        ['addressLine2'],
        ['lang'],
        ['country'],
        ['phones'],
        ['email', 'reEmail'],
        ['password', 'rePassword'],
    ],
    nl: [
        ['isCompanyAddress'],
        ['title'],
        ['taxId'],
        ['companyName'],
        ['companyTaxId'],
        ['firstname', 'lastname'],
        ['postcode', 'city'],
        ['addressLine1'],
        ['addressLine2'],
        // ['region'],
        // ['profession'],
        ['phones'],
        ['email', 'reEmail'],
        ['password', 'rePassword'],
    ],
    ch: [
        ['lastname', 'firstname'],
        ['cityByPostcode'],
        ['addressLine1'],
        ['phones'],
    ],
    co: [
        ['lastname', 'firstname'],
        ['postcodeByCity'],
    ],
}

const assistedCustomerDetailsForm = {
    default: [
        ['isCompanyAddress'],
        ['title'],
        ['taxId'],
        ['companyName'],
        ['companyTaxId'],
        ['firstname', 'lastname'],
        ['postcode', 'city'],
        ['addressLine1'],
        ['addressLine2'],
        ['lang'],
        ['country'],
        ['phones'],
        ['email', 'reEmail'],
    ],
}

const customerCustomAddressForm = {
    default: [
        ['isCompanyAddress'],
        ['title'],
        ['taxId'],
        ['companyName'],
        ['companyTaxId'],
        ['firstname', 'lastname'],
        ['postcode', 'city'],
        ['addressLine1'],
        ['addressLine2'],
        ['phones'],
    ],
}

const validatorLocales = {
    default: 'en-US',
    at: 'de-DE',
    br: 'pt-BR',
    ch: ['de-DE', 'fr-FR'],
    cz: 'cs-CZ',
    dk: 'da-DK',
    es: 'es-ES',
    fi: 'fi-FI',
    fr: 'fr-FR',
    gr: 'el-GR',
    hu: 'hu-HU',
    il: 'he',
    it: 'it-IT',
    nl: 'nl-NL',
    no: 'nn-NO',
    nz: 'en-NZ',
    pl: 'pl-PL',
    pt: 'pt-PT',
    sk: 'sk-SK',
    uk: 'en-GB',
    ro: 'ro-RO',
    jp: 'ja-JP',
    tr: 'tr-TR',
}

const sessionTime = {
    machineRegistration: {
        default: 1000 * 60 * 10,
        ro: 1000 * 60 * 30,
    },
    machineSubscription: {
        default: 1000 * 60 * 10,
        ro: 1000 * 60 * 30,
    },
    orderFinish: {
        default: 1000 * 60 * 10,
        ro: 1000 * 60 * 30,
    },
    etrade: {
        default: 1000 * 60 * 20,
        ro: 1000 * 60 * 30,
    },
    stayHome: {
        default: 1000 * 60 * 10,
        ro: 1000 * 60 * 30,
    },
    reseller: {
        default: 1000 * 60 * 20,
    },
    resellerMachineSubscription: {
        default: 1000 * 60 * 20,
    },
}

const defaultCoords = {
    default: [0.5, 0.5, 16],
    nl: [52.3674850960641, 4.903476621157748, 16],
}

/**
 * Determines if feature is enabled in current market. Current market is defined in project environment (.env file)
 * @param {string} prop Property name
 * @returns {boolean}
 */
export function isEnabledByMarket(prop) {
    if (!config[prop])
        return false

    return !!config[prop]
        .map(v => v.toString().toLowerCase())
        .includes(process.env.REACT_APP_MARKET.toLowerCase())
}


/**
 * Returns list of stacks for current market. Current market is defined in project environment (.env file)
 * @returns {string[]}
 */
export function getMarketStacks() {
    const marketStacks = stacks[process.env.REACT_APP_MARKET] ?? []
    const set = new Set([...stacks.default, ...marketStacks])
    return Array.from(set)
}


/**
 * Returns market image.
 * @param {string} image Image type
 * @returns {{number: string, machine: string}||null}
 */
export function getMarketImage(image) {
    if (!image) return null
    const marketImages = images[process.env.REACT_APP_MARKET] ?? {}
    const obj = {...images.default, ...marketImages}
    return obj[image]
}


/**
 * Returns benefits name in array.
 * @returns {string[]}
 */
export function getBenefitsOrder() {
    return benefitsOrder[process.env.REACT_APP_MARKET] ?? benefitsOrder.default
}

export function getSummaryOrder() {
    return summaryOrder[process.env.REACT_APP_MARKET] ?? summaryOrder.default
}

export function getSummaryStayHomeOrder() {
    return summaryStayHomeOrder[process.env.REACT_APP_MARKET] ?? summaryStayHomeOrder.default
}


/**
 * Returns locale code for specific market (country code).
 * @returns {string | null}
 */
export function getPriceFormat() {
    return priceFormat[process.env.REACT_APP_MARKET]
}

/**
 * Returns default civility code for specific market.
 * @returns {string | null}
 */
export function getDefaultCivility() {
    return defaultCivility[process.env.REACT_APP_MARKET] ?? defaultCivility.default
}

/**
 * Returns custom language code label for specific market.
 * @param {string} languageCode - original language code
 * @returns {string | null}
 */
export function getLanguageLabel(languageCode) {
    if (!languageLabel[process.env.REACT_APP_MARKET])
        return languageCode
    return languageLabel[process.env.REACT_APP_MARKET][languageCode] ?? languageCode
}

/**
 * Returns customer details form config.
 * @param assisted assisted flow
 * @returns {array}
 */
export function getCustomerDetailsForm(assisted = false) {
    if(assisted) {
        return assistedCustomerDetailsForm[process.env.REACT_APP_MARKET] ?? assistedCustomerDetailsForm.default
    }
    return customerDetailsForm[process.env.REACT_APP_MARKET] ?? customerDetailsForm.default
}

/**
 * Returns customer custom address form config.
 * @returns {array}
 */
export function getCustomerCustomAddressForm() {
    return customerCustomAddressForm[process.env.REACT_APP_MARKET] ?? customerCustomAddressForm.default
}

/**
 * Returns market locales for validator package.
 * @returns {array|string}
 */
export function getValidatorLocales(countryCode) {
    return validatorLocales[countryCode] ?? validatorLocales.default
}

/**
 * Returns custom language code label for specific market.
 * @param {string} module - module name
 * @returns {number}
 */
export function getSessionTime(module) {
    if(!sessionTime[module])
        return 1000 * 60 * 10
    return sessionTime[module][process.env.REACT_APP_MARKET] ?? sessionTime[module].default
}

/**
 * Returns filter form options order.
 * @returns {array}
 */
export function getFiltersOrder() {
    return filtersOrder[process.env.REACT_APP_MARKET]
}


/**
 * Returns market default map coordinates.
 * @returns {array|string}
 */
export function getDefaultCoords() {
    return defaultCoords[process.env.REACT_APP_MARKET] ?? defaultCoords.default
}
