import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {confirmSubscription} from '../../../actions/OrderActions'
import {useLocalizeObject, useTranslation} from "../../../misc/Hooks";
import {Footer, Header} from "../../../components/misc/_index";
import {GTM, Preloader} from "../../../misc/_index";
import {GetInTouchModal} from "../../../components/modals/_index";
import {useSeoPage} from "../../../hooks";


export default function ConfirmOrderScreen() {
    useSeoPage({title: 'order_finalized.title'})
    const params = useParams()
    const t = useTranslation()
    const localizeObject = useLocalizeObject()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [gitModalVisible, setGitModalVisible] = useState(false);

    useEffect(() => {
        confirmOrder(params.token)
    }, [])

    const confirmOrder = (uuid) => {
        confirmSubscription(uuid)
            .then(r => {
                const res = r.data
                if (res.status === 'success') {
                    setData(res.data.thank_you_page)
                } else {
                    console.warn(res)
                }
            })
            .catch(err => {
                console.error('Cannot confirm order!', err)
            })
            .finally(() => setLoading(false))
    }

    return (
        <div className="app">
            <Header cartDisabled/>

            <div className="main">
                <div className="wrapper-bg formContainer">

                    <div className="wrapper">


                        <div>
                            {loading ? (
                                <Preloader/>
                            ) : data ? (
                                <div className="par-2 --mb4" style={{overflow: 'hidden'}}>
                                    <div dangerouslySetInnerHTML={{__html: localizeObject(data.thank_you_page_text)}}/>
                                </div>
                            ) : (
                                <div className="--mb4" style={{textAlign: 'center'}}>
                                    <h1 className="par-1">{t('order_finalized.order_not_found.title')}</h1>
                                    <p className="par-2">{t('order_finalized.order_not_found.text')}</p>
                                </div>
                            )}
                        </div>

                        <div className="par-4 --semiBold --alignCenter">
                            <span>{t('need_help')} </span>
                            <a className="link -primary -parentSize" onClick={() => setGitModalVisible(true)}>
                                {t('get_in_touch')}
                            </a>
                        </div>

                    </div>
                </div>
            </div>


            <GetInTouchModal
                visible={gitModalVisible}
                onClose={() => setGitModalVisible(false)}
            />


            <Footer/>
        </div>
    )
}
