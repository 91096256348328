import {AsyncSelectController} from "../../../forms/controllers";
import React, {useEffect} from "react";
import {useFormHelper, useModuleContext, useTranslation} from "../../../hooks";
import {useAsyncSelect} from "../../../forms/hooks";
import * as countryActions from '../../../actions/CountryActions';
import {useWatch} from "react-hook-form";

export default function CountrySelect() {
    const {t} = useTranslation()
    const {store, reducer, dispatch} = useModuleContext()
    const {formMethods} = useFormHelper()
    const countrySelect = useAsyncSelect({
        name: 'allCountries',
        action: countryActions.getAll,
        queryOptions: {
            onSuccess: ({data: {data}}) => {
                if (!data) return null
                dispatch(reducer.setCountries(data))

                const country = data.find(c => c.id_country === store.country.id_country)
                if (country)
                    dispatch(reducer.setCountry(country))

            }
        },
        mapFunc: i => new Option(i.country_title, i.id_country)
    })

    const country = useWatch({
        control: formMethods.control,
        name: 'country',
    })

    useEffect(() => {
        const c = store.countries?.find(v => Number(v.id_country) === Number(country?.value))
        if (c) dispatch(reducer.setCountry(c))
    }, [country])

    return (
        <AsyncSelectController
            name="country"
            label={t('country')}
            asyncSelect={countrySelect}
            defaultValue={new Option(store.country?.country_title, store.country?.id_country)}
        />
    )
}