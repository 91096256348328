import {Button} from "../../buttons/_index";

export default function PickupPointInfoWindow({pickupPoint, openingHours, labels}) {
    const address = {
        addressLine: pickupPoint?.addresses[0]?.addressLine1,
        city: pickupPoint?.addresses[0]?.city,
        zipCode: pickupPoint?.addresses[0]?.zipCode,
    }

    return (
        <div className="pickupPointInfoWindow">
            <div className="pickupPointInfoWindow__header">
                <div className="pickupPointInfoWindow__logo">
                    {!!pickupPoint.logoUrl && (
                        <img src={pickupPoint.logoUrl} alt={address.addressLine}/>
                    )}
                </div>
                <div className="pickupPointInfoWindow__content">
                    <h3 className="pickupPointInfoWindow__title">{address.addressLine}</h3>
                    <h4 className="pickupPointInfoWindow__subtitle">{address.zipCode} {address.city}</h4>
                    {!!labels.type && (
                        <p className="pickupPointInfoWindow__text --mb0">({labels.type})</p>
                    )}
                </div>
            </div>
            <div className="pickupPointInfoWindow__body">
                {openingHours?.map((o, idx) => (
                    <p key={idx} className="pickupPointInfoWindow__text">
                        {o.day}: {o.isOpen ? `${o.openingHours.join(',')}` : labels.isClosed}
                    </p>
                ))}
            </div>
        </div>
    )
}
