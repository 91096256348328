import React, {useEffect} from "react";
import {useFormBuilder, useFormRulesBuilder} from "../hooks";
import {AccountNumberInput} from "../presets";
import {useFieldFormat, useFormHelper, useTranslation} from "../../hooks";
import {useWatch} from "react-hook-form";
import {CheckboxController, MaskInputController} from "../controllers";
import {useBank} from "../../components/PaymentDetailsForm/hooks";
import {FormBuilderComponent} from "../components";
import HiddenInputController from "../controllers/HiddenInputController";

export default function PaymentDetailsFormFragment({prefix = ''}) {
    const {t} = useTranslation()
    const bank = useBank()
    const {formMethods, ...formHelper} = useFormHelper(prefix)
    const {formats} = useFieldFormat()
    const formRules = useFormRulesBuilder({
        'accountNumber': b => b.required().pattern(/^[0-9]{6,11}$/),
        'bsbNumber': b => b.required()
            .pattern(formats.bsbNumber.pattern)
            .validate(() => bank.data),
        'reAccountNumber': b => b.required()
            .pattern(/^[0-9]{6,11}$/)
            .equals(`$${formHelper.name('accountNumber')}`, {sameAs: t('bank_account.number')}),
        'isAccountNumberConfirmed': b => b.required(),
    }, formMethods)
    const {components} = useFormBuilder({
        formConfig: [
            ['bsbNumber'],
            ['accountNumber'],
            ['reAccountNumber'],
            ['isAccountNumberConfirmed'],
            ['bank']
        ],
        inputVisibility: {},
        inputComponents: {
            'accountNumber': (
                <AccountNumberInput
                    rules={formRules.values.accountNumber}
                    mask={false}
                />
            ),
            'bsbNumber': (
                <>
                    <div className="--mb2">
                        <MaskInputController
                            name="bsbNumber"
                            label={t('bank_account.bsb_number')}
                            mask={formats.bsbNumber.getMask()}
                            rules={formRules.values.bsbNumber}
                            options={{
                                isLoading: bank.loading,
                            }}
                        />
                    </div>
                    {!!bank.data && (
                        <p className="par-2 --pl2">{bank.data.branch}, {bank.data.post} {bank.data.city}</p>
                    )}
                </>
            ),
            'reAccountNumber': (
                <AccountNumberInput
                    name="reAccountNumber"
                    label={t('bank_account.repeat_number')}
                    rules={formRules.values.reAccountNumber}
                    mask={false}
                />
            ),
            'isAccountNumberConfirmed': (
                <CheckboxController
                    name="isAccountNumberConfirmed"
                    label={t('bank_account.confirm_number')}
                    rules={formRules.values.isAccountNumberConfirmed}
                />
            ),
            'bank': (
                <HiddenInputController name="bank"/>
            )
        },
    })
    const bsbNumber = useWatch({
        control: formMethods.control,
        name: formHelper.name('bsbNumber'),
    })

    useEffect(() => {
        if (bsbNumber?.match(formats.bsbNumber.pattern)) {
            bank.getBank(bsbNumber)
                .then(bank => {
                    formHelper.setValue('bank', bank ?? undefined)
                    formHelper.trigger('bsbNumber')
                })
        }
    }, [bsbNumber])

    return (
        <>
            <p className="par-2 --semiBold">{t('bank_account.heading')}</p>
            <FormBuilderComponent prefix={prefix} components={components}/>
        </>
    )
}
