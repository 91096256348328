import {useEffect, useMemo, useState} from "react";
import * as AuthActions from '../../../actions/AuthorizationActions';
import {ApiException} from "../../../errors";
import {useMutation} from "@tanstack/react-query";
import * as mainReducer from "../../../redux/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import Axios from "../../../utils/Axios";
import * as resellerReducer from "../../../redux/resellerReducer";
import {useHistory, useLocation} from "react-router-dom";

export default function useResellerAuth() {
    const dispatch = useDispatch();

    const [user, setUser] = useState(null);
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [ssoFormVisible, setSsoFormVisible] = useState(true)
    const [error, setError] = useState(null);
    const history = useHistory()
    const location = useLocation()
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location])
    const ssoAuthorizationParam = useMemo(() => queryParams.get('data'), [queryParams])
    const [defaultLogin, setDefaultLogin] = useState(null)

    const store = useSelector(({main}) => ({
        user: main.user,
    }))

    const checkSsoMutation = useMutation(
        async (payload) => {
            const {data} = await AuthActions.checkSso(payload)
            if (data.status === 'success')
                return data
            throw new ApiException(data.messages[0], data)
        },
        {
            onSuccess: ({data}) => {
                if (data.redirect_url) {
                    window.location.href = data.redirect_url
                    return
                }
                setSsoFormVisible(false)
            },
        }
    )

    const authorizeBySso = useMutation(
        async (payload) => {
            const {data} = await AuthActions.authorizeBySso(payload)
            if (data.status === 'success')
                return data
            throw new ApiException(data.messages[0], data)
        },
        {
            onSuccess: async (res) => {
                await ssoLogin(res)
                history.replace({search: null})
            },
            onError: () => {
                history.replace({search: null})
            }
        }
    )

    const getUserMutation = useMutation(
        async (token) => {
            const params = {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
            const {data} = await AuthActions.getReseller(params)
            if (data.status === 'success') return data

            throw new ApiException(data.messages[0], data)
        },
        {
            onMutate: () => setError(null),
            onSuccess: ({data}) => {
                setUser(data)
            },
            onError: ({error}) => {
                setError(error)
            }
        }
    )

    const userLoginMutation = useMutation(
        async (payload) => {
            const {data, messages} = await AuthActions.signInReseller(payload)
            if (data.status === 'success') return {data, messages}

            throw new ApiException(data.messages[0], data)
        },
        {
            onMutate: () => setError(null),
            onSuccess: async ({data}, payload) => {
                dispatch(mainReducer.setUserToken(data.data));
                Axios.defaults.headers.get['Authorization'] = `Bearer ${data.data}`;
                Axios.defaults.headers.post['Authorization'] = `Bearer ${data.data}`;
                dispatch(resellerReducer.setLastVisit(Date.now()))
                await getUserMutation.mutate(data.data)
                setShowNewPassword(!!Number(data.messages[0].temporary_password))
            },
            onError: ({error}) => {
                setError(error)
            }
        }
    )

    const ssoLogin = async (res) => {
            dispatch(mainReducer.setUserToken(res.data));
            Axios.defaults.headers.get['Authorization'] = `Bearer ${res.data}`;
            Axios.defaults.headers.post['Authorization'] = `Bearer ${res.data}`;
            dispatch(resellerReducer.setLastVisit(Date.now()))
            await getUserMutation.mutate(res.data);
            setShowNewPassword(!!Number(res.messages[0].temporary_password))
    }

    const userShopsOptions = useMemo(() => user?.reseller_shops?.map(s => ({value: s.id_shop, label: s.shop_title})), [user?.reseller_shops])

    const onSubmitShopForm = ({shop, firstname, lastname}) => {
        const shopId = shop.value;

        Axios.defaults.headers.get['Shop'] = shopId;
        Axios.defaults.headers.post['Shop'] = shopId;

        if(firstname && lastname)
            dispatch(resellerReducer.setName(firstname, lastname))

        dispatch(resellerReducer.setShop(shopId))
        dispatch(resellerReducer.setShopLabel(shop.label))
        dispatch(mainReducer.setUser(user))
    }

    const temporaryPasswordMutation = useMutation(
        async ({password, rePassword}) => {
            const {data} = await AuthActions.setTemporaryPassword({password, repassword: rePassword})
            if (data.status === 'success') return data

            throw new ApiException(data.messages[0], data)
        },
        {
            onMutate: () => setError(null),
            onSuccess: ({data}) => setShowNewPassword(false),
            onError: ({error}) => setError(error?.password)
        }
    )

    const onCheckSso = async ({login}) => {
        setDefaultLogin(login)
        const payload = {login}
        await checkSsoMutation.mutate(payload)
    }

    useEffect(() => {
        if (ssoAuthorizationParam && !authorizeBySso.isLoading) {
            authorizeBySso.mutate({data: ssoAuthorizationParam})
        }
    }, [ssoAuthorizationParam])


    return {
        userLoginMutation,
        temporaryPasswordMutation,
        showNewPassword,
        onSubmitShopForm,
        user,
        userStore: store.user,
        userShopsOptions,
        error,
        isLoading: userLoginMutation.isLoading
            || getUserMutation.isLoading
            || temporaryPasswordMutation.isLoading
            || checkSsoMutation.isLoading,
        ssoFormVisible,
        setSsoFormVisible,
        onCheckSso,
        ssoAuthorizationParam,
        defaultLogin,
    }
}