import {useCategoryBar, useCoffeeProducts, useProductsFilters} from "./hooks";
import {Preloader} from "../../misc/_index";
import {Config} from "../../utils/_index";
import {Button} from "../../components/buttons/_index";
import React, {useEffect, useMemo, useState} from "react";
import {useCountryConfig, useLocalizeObject} from "../../misc/Hooks";
import {useModuleContext, useTranslation} from "../../hooks";
import {CategoryBar, CategorySections, FavoriteProducts, FilterModal} from "./components";
import {useCategoriesContext} from "../../hooks/useFetchCategories";
import {EasyOrderSetupForm} from "../../forms";
import useResizeObserver from "@react-hook/resize-observer";

export default function ProductsSelection({
    storeName,
    stepsNavigation,
    isLoadingPersonalization,
    texts = {
        title: '',
        subtitle: '',
        description: '',
    },
    hideIfNoProducts = false,
}) {
    const {query: categoriesQuery} = useCategoriesContext()
    let query = categoriesQuery
    const context = useModuleContext()
    if (!storeName)
        storeName = context.storeName
    if (!query) {
        query = {
            isFetching: context.store?.loadingCategories,
        }
    }

    const coffeeProducts = useCoffeeProducts(storeName)
    const categoryBar = useCategoryBar(coffeeProducts);
    const productsFilter = useProductsFilters(coffeeProducts, categoryBar)
    const localizeObject = useLocalizeObject()
    const {t} = useTranslation()
    const categories = useMemo(() => {
        return coffeeProducts.categories
            .filter((cat, idx) => {
                // unlock rest of the categories, if this category is valid
                let isValid = true;
                coffeeProducts.categories.map((c, cIdx) => {
                    if (!isValid)
                        return;
                    if (cIdx > idx || cIdx === idx)
                        return;

                    if (c.promotionCategory?.promotion_category_hide_others)
                        isValid = coffeeProducts.isCategoryValid(c.id_category);
                })

                return isValid;
            })
            .filter(c => c.products?.length)
    }, [coffeeProducts.categories, coffeeProducts.store.promotion, coffeeProducts.store.easyOrderSetup, coffeeProducts.showAdditional])
    const showCoffees = useMemo(() => {
        return !!(coffeeProducts.store.promotion?.promotion_type !== Config.PROMO_TYPE.EASY_ORDER
            || coffeeProducts.store.easyOrderSetup)
    }, [coffeeProducts.store.promotion, coffeeProducts.store.easyOrderSetup])
    const {selectable_products_enable: selectableProductsEnable} = useCountryConfig()

    const [currentSelectedCategory, setCurrentSelectedCategory] = useState(null)
    const [isMobileView, setIsMobileView] = useState(false)

    useEffect(() => {
        if (selectableProductsEnable && categories.length) {
            const cat = categories.find(c => c.category_type === 'capsule')
            if (cat) {
                setCurrentSelectedCategory(cat.id_category)
            } else {
                setCurrentSelectedCategory(categories[0].id_category)
            }
        }
    }, [categories])

    useResizeObserver(document.body, (entry) => {
            setIsMobileView(entry.contentRect.width <= 576)
    })

    return (
        <div className="evCS">
            {showCoffees && (
                <>
                    <div className="evPS__header">
                        {localizeObject(texts.title) ? (
                            <div className="par-2" dangerouslySetInnerHTML={{__html: localizeObject(texts.title)}}/>
                        ) : (
                            <p className="par-7 --bold">{t('shop_offer_selection.coffee_selection_title')}</p>
                        )}
                        {!!texts.subtitle && (
                            <div className="par-2" dangerouslySetInnerHTML={{__html: localizeObject(texts.subtitle)}}/>
                        )}
                    </div>

                    {!!coffeeProducts.promoImage && (
                        <div className="evCS__promoImage">
                            <img src={coffeeProducts.promoImage} alt=""/>
                        </div>
                    )}

                    {!!localizeObject(texts.description) && (
                        <div className="evCS__header">
                            <div
                                className="par-1"
                                dangerouslySetInnerHTML={{__html: localizeObject(texts.description)}}
                            />
                        </div>
                    )}
                </>
            )}

            {query.isFetching || isLoadingPersonalization ? (
                <div className="--mb4">
                    <Preloader message={t('fetching_products')}/>
                </div>
            ) : categories?.length ? (
                <>


                    {!!categories.length && showCoffees && (
                        <>
                            <FavoriteProducts
                                cart={coffeeProducts.store.cart}
                            />
                            <CategoryBar
                                products={coffeeProducts}
                                categoryBar={categoryBar}
                                filter={productsFilter.filter}
                                onSubmitFilter={productsFilter.handleFilterSubmit}
                                openModalFilter={() => productsFilter.setModalVisible(true)}
                                currentSelectedCategory={currentSelectedCategory}
                                setCurrentSelectedCategory={setCurrentSelectedCategory}
                                isMobileView={isMobileView}
                            />
                        </>
                    )}


                    {coffeeProducts.store.promotion?.promotion_type === Config.PROMO_TYPE.EASY_ORDER && (
                        <div className="--mb3">
                            <EasyOrderSetupForm
                                data={coffeeProducts.store.easyOrderSetup}
                                frequencies={coffeeProducts.store.frequencies}
                                stepNavigation={stepsNavigation}
                                onSubmit={coffeeProducts.handleSubmitEasyOrderSetup}
                            />
                        </div>
                    )}
                    {coffeeProducts.store.promotion?.promotion_type === Config.PROMO_TYPE.EASY_ORDER && !!coffeeProducts.store.easyOrderSetup && (
                        <h3 className="evCS__categoryName --lg">{t('easy_order.select_gift')}</h3>
                    )}

                    {showCoffees && (
                        <CategorySections
                            categories={categories}
                            cart={coffeeProducts.store.cart}
                            storeName={storeName}
                            showAdditional={coffeeProducts.showAdditional}
                            currentSelectedCategory={currentSelectedCategory}
                            isMobileView={isMobileView}
                        />
                    )}

                    {coffeeProducts.store.promotion && coffeeProducts.showAdditionalEnabled && coffeeProducts.store.promotion.promotion_type !== Config.PROMO_TYPE.EASY_ORDER && (
                        <div className="--mb4">
                            <Button
                                type="primary"
                                label={t(`${coffeeProducts.showAdditional ? 'hide' : 'show'}_additional.${categories.find(c => c.promotionCategory?.promotion_category_is_additional)?.products[0]?.product_type}`)}
                                onClick={() => coffeeProducts.setShowAdditional(!coffeeProducts.showAdditional)}
                            />
                        </div>
                    )}
                </>
            ) : !hideIfNoProducts && (
                <p className="par-2 --semiBold --mb4">{t('no_products')}</p>
            )}


            {(!coffeeProducts.store.promotion || (coffeeProducts.store.promotion.promotion_type !== Config.PROMO_TYPE.EASY_ORDER || (coffeeProducts.store.promotion.promotion_type === Config.PROMO_TYPE.EASY_ORDER && coffeeProducts.store.easyOrderSetup))) && (
                <div className="step__nav evCS__mobileFooter">

                    <Button
                        type="primary-outline"
                        label={t('back')}
                        href={stepsNavigation.prev()}
                    />

                    {coffeeProducts.showAdditionalEnabled && !coffeeProducts.showAdditional ? (
                        <Button
                            type="primary"
                            label={t('continue')}
                            onClick={() => coffeeProducts.setShowAdditional(true)}
                        />
                    ) : (
                        <Button
                            onClick={() => coffeeProducts.goToNextStep(stepsNavigation.next())}
                            type="primary"
                            label={t('continue')}
                            disabled={!coffeeProducts.isCartValid || query.isFetching}
                        />
                    )}
                </div>
            )}

            <FilterModal
                visible={productsFilter.modalVisible}
                filter={productsFilter.filter}
                coffeeLines={categoryBar?.coffeeLines}
                cupSizes={productsFilter.cupSizes}
                aromaticProfiles={productsFilter.aromaticProfiles}
                coffeeTypes={productsFilter.coffeeTypes}
                accessoryTypes={productsFilter.accessoryTypes}
                productsCount={productsFilter.getProductsCount}
                categories={coffeeProducts.originalCategories}
                onClose={() => productsFilter.setModalVisible(false)}
                onSubmit={productsFilter.handleFilterSubmit}
                onReset={productsFilter.handleFilterReset}
                hidePopup={() => productsFilter.setModalVisible(false)}
            />
        </div>
    )
}
