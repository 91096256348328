import {Button} from "../../../components/buttons/_index";
import React, {useMemo, useState} from "react";
import {useTranslation} from "../../../hooks";
import {useMutation} from "@tanstack/react-query";
import {useResellerActions} from "../../../actions/useResellerActions";
import {ApiException} from "../../../errors";

//Resend email table button
export default function ResendButton({order}) {
    const {t} = useTranslation()
    const resellerActions = useResellerActions()

    const resendMutation = useMutation(async () => {
            const {data} = order.promotion_type === 'fast_order'
                ? await resellerActions.resendFastOrder(order.id_order)
                : await resellerActions.resendOrder(order.id_order)

            if (data.status === 'success') return data
            throw new ApiException(data.messages[0], data)
        }
    )

    const label = useMemo(() => {
        if(resendMutation.isIdle)
            return 'table.send_again'
        if(resendMutation.isLoading)
            return 'table.sending'
        if(resendMutation.isSuccess)
            return 'table.sent'
        if(resendMutation.isError)
            return 'error_occurred'
    }, [resendMutation.status])

    return (
        <Button
            size="sm"
            label={t(label)}
            disabled={!resendMutation.isIdle}
            isLoading={resendMutation.isLoading}
            onClick={resendMutation.mutate}
            style={{width: '100%'}}
        />
    )
}