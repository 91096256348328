import {Form} from "../../../forms";
import {useForm, useWatch} from "react-hook-form";
import {useFormRulesBuilder} from "../../../forms/hooks";
import MembersList from "../../membersList/MembersList";
import Button from "../../buttons/Button";
import React, {useEffect, useMemo} from "react";
import {useCountry, useModuleContext, useTranslation} from "../../../hooks";
import {useVerifyMember} from "../hooks";
import {InfoComponent} from "../../_index";
import {TextInputController} from "../../../forms/controllers";
import {useMarketConfig} from "../../../misc/Hooks";

export default function ListForm({data, defaultValues, state, onSubmit}) {
    state = {
        isLoading: false,
        error: null,
        ...state,
    }

    const {t} = useTranslation()
    const {data: country} = useCountry()
    const marketConfig = useMarketConfig()
    const {store} = useModuleContext()
    const verifyMember = useVerifyMember()
    const formMethods = useForm({
        mode: 'onChange',
        defaultValues,
    })
    const formRules = useFormRulesBuilder({
        'member': b => b.required(),
        'verificationCode': b => b.required(),
    }, formMethods)
    const member = useWatch({
        control: formMethods.control,
        name: 'member',
    })
    const verificationCode = useWatch({
        control: formMethods.control,
        name: 'verificationCode',
    })
    const communicationChannel = useMemo(() => {
        let channel = country.country_assisted_simplified_flow_communication?.toString()

        if(channel === '-1') {
            channel = marketConfig.communication
        }

        switch (channel) {
            case '0': return t('communication_channel.email').toLowerCase()
            case '1': return t('communication_channel.sms').toLowerCase()
            case '2': return t('communication_channel.email_and_sms').toLowerCase()
        }

        return 'UNDEFINED'
    }, [country])

    useEffect(() => {
        if(verifyMember.error) {
            formMethods.setError('verificationCode', {type: 'invalid', message: t(`error.${verifyMember.error.error}`)})
        } else {
            formMethods.clearErrors('verificationCode')
        }
    }, [verifyMember.error])

    const onSelect = (data) => {
        formMethods.setValue('member', data)
        if(data?.memberNumber !== member?.memberNumber) {
            formMethods.setValue('verificationCode', undefined)
            verifyMember.reset()
        }
        if(!country.country_assisted_simplified_flow_validation_enabled) {
            formMethods.handleSubmit(handleSubmit)()
        }
        // console.log(country.country_assisted_simplified_flow_validation_enabled)
        //TODO: handle submit if no validation
    }

    const handleSubmit = (data) => {
        if(defaultValues?.member?.memberNumber === data.member?.memberNumber) {
            onSubmit(data)
            return;
        }

        if(!country.country_assisted_simplified_flow_validation_enabled) {
            onSubmit(data)
            return
        }

        const payload = {
            uuid: store.uuid,
            phone_number: data.member.phoneNumber,
            email: data.member.email,
        }
        if(!verificationCode) {
            verifyMember.sendMutation.mutate(payload)
            return
        }
        verifyMember.verifyMutation.mutateAsync({
            ...payload,
            code: data.verificationCode,
        }).then(() => {
            onSubmit(data)
        }).catch(() => {
            // already handled in useVerifyMember
        })
    }

    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            state={{
                isDisabled: state.isLoading || verifyMember.isLoading,
                isLoading: state.isLoading || verifyMember.isLoading,
            }}
            onSubmit={handleSubmit}
        >
            <div className="--mb4">
                <p className="par-2">{t('assisted_search_nespresso_member.list_title')}</p>
                <MembersList
                    items={data}
                    selectedItem={member}
                    onClick={onSelect}
                />
            </div>

            {verifyMember.codeSent && (
                <div className="grid --mb3">
                    <div>
                        <p className="par-1 --semiBold">{t('assisted_search_nespresso_member.verification_code_title')}</p>
                        {!!t('assisted_search_nespresso_member.verification_code_text') && (
                            <p className="par-2">
                                {t('assisted_search_nespresso_member.verification_code_text', communicationChannel)}
                            </p>
                        )}
                        <TextInputController
                            name="verificationCode"
                            label={t('assisted_search_nespresso_member.verification_code')}
                        />
                    </div>
                    <div/>
                </div>
            )}
            {verifyMember.sendMutation.isError && (
                <InfoComponent
                    type="error"
                    message={t('error.unknown_error')}
                />
            )}

            {!!member && (
                <div>
                    <Button
                        btnType="submit"
                        label={t('continue')}
                        isLoading={verifyMember.isLoading}
                        disabled={verifyMember.isLoading}
                    />
                </div>
            )}

            {/*{verifyMember.isError && (*/}
            {/*    <InfoComponent*/}
            {/*        type="error"*/}
            {/*        message={t(`error.${verifyMember.error?.error}`) || t('error.unknown_error')}*/}
            {/*    />*/}
            {/*)}*/}
        </Form>
    )
}
