import usePromoModal from "./usePromoModal";
import BaseModal from "../modals/BaseModal";
import {useLocalizeObject} from "../../misc/Hooks";
import React from "react";
import PromoModalForm from "./PromoModalForm";
import {getImage} from "../../misc/Helpers";
import {useModuleContext} from "../../hooks";

export default function PromoModal({storeName, popupType}) {
    const context = useModuleContext()
    if(!storeName)
        storeName = context.storeName
    const promoModal = usePromoModal(storeName, popupType);
    const localizeObject = useLocalizeObject();


    if (promoModal?.modalData?.promotion_popup_layout === 'text_with_image') {
        return (
            <BaseModal
                visible={promoModal.modalVisible}
                onClose={() => promoModal.closeModal()}
                className="customModal"
            >
                <div className="promoModalWithImage">
                    {!!promoModal.modalData.promotion_popup_image && (
                        <div className="promoModalWithImage__image" style={{
                            "--bgImage": `url("${getImage([promoModal.modalData.promotion_popup_image]) ?? ''}")`,
                        }}/>
                    )}
                    <div className="promoModalWithImage__body">
                        <div
                            className="promoModalWithImage__textHeader"
                            dangerouslySetInnerHTML={{__html: localizeObject(promoModal.modalData.promotion_popup_text)}}
                        />
                        {popupType === 'optin' && (
                                <PromoModalForm promoModal={promoModal}/>
                        )}
                        <div
                            className="promoModalWithImage__textFooter"
                            dangerouslySetInnerHTML={{__html: localizeObject(promoModal.modalData.promotion_popup_text_footer)}}
                        />
                    </div>
                </div>
            </BaseModal>
        )
    }

    return (
        <BaseModal
            visible={promoModal.modalVisible}
            onClose={() => promoModal.closeModal()}
        >
            {!!localizeObject(promoModal?.modalData?.promotion_popup_text) && (
                <div
                    className="promoModal"
                    dangerouslySetInnerHTML={{__html: localizeObject(promoModal.modalData.promotion_popup_text)}}
                />
            )}

            {popupType === 'optin' && (
                <>
                    <div className="--mb4"/>
                    <PromoModalForm promoModal={promoModal}/>
                </>
            )}

            {!!localizeObject(promoModal?.modalData?.promotion_popup_text_footer) && (
                <>
                    {popupType === 'optin' && (
                        <div className="--mb4"/>
                    )}
                    <div
                        className="promoModal"
                        dangerouslySetInnerHTML={{__html: localizeObject(promoModal.modalData.promotion_popup_text_footer)}}
                    />
                </>
            )}

        </BaseModal>
    )


}