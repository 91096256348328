import {useState} from "react";
import {useMarketConfig, useTranslation} from "../misc/Hooks";


/**
 * useSmsPhoneVerification
 * @returns {{prepare: function, formVisible: boolean}}
 */
export default function useSmsPhoneVerification(isSmsValidationEnabled = false) {
    const t = useTranslation()
    const [formVisible, setFormVisible] = useState(false);
    const [error, setError] = useState(null)

    const prepare = () => {
        if(isSmsValidationEnabled) {
            setFormVisible(true)
            setError(null)
        }
    }

    const onError = (error) => {
        setFormVisible(true)
        setError(null)
        setError(t(`error.${error}`) || error)
    }

    return {
        formVisible,
        error,
        prepare,
        onError,
    }
}
