import MachinePlanScreen from "../screens/MachinePlanScreen";
import {useSeoPage} from "../../../hooks";

export default function MachinePlanContainer(props) {
    useSeoPage({title: 'etrade.machine_plan.title'})

    return (
        <MachinePlanScreen/>
    )
}
