import {Controller, useForm} from "react-hook-form";
import {MyInput, MySelect} from "../../components/forms/inputs/_index";
import {Link} from "react-router-dom";
import {Button} from "../../components/buttons/_index";
import React from "react";
import {useTranslation} from "../../hooks";
import Patterns from "../../utils/Patterns";

export default function AxLandingPageForm(props){

    const {t} = useTranslation();

    const {control, errors, handleSubmit} = useForm({});

    const rules = {
        email: {
            required: {
                value: true,
                message: t('validation.required'),
            },
            pattern: {
                value: Patterns.email,
                message: t('validation.type_email')
            },
        },
    }

    return (
        <form onSubmit={handleSubmit(props.onSubmit)} className="axEmailForm">
            <div/>
            <div className="axEmailForm__body">
                {!props.ax.emailSendStatus ? (
                    <>
                        <div className="par-1 --mb1 --alignCenter">
                            <p>{t('ax.lp.form_info')}</p>
                        </div>
                        <Controller
                            name="email"
                            control={control}
                            rules={rules.email}
                            render={({field, fieldState}) => (
                                <MyInput
                                    type="email"
                                    name="email"
                                    label={t('email')}
                                    value={field.value}
                                    error={fieldState.error}
                                    onChange={field.onChange}
                                />
                            )}
                        />


                        <Button
                            btnType="submit"
                            type="primary"
                            label={props.ax.mutation.isLoading ? t('ax.lp.sending') : t('ax.lp.send_email')}
                            disabled={props.ax.mutation.isLoading}
                            onClick={handleSubmit(props.onSubmit)}

                        />
                    </>
                ) : (
                    <div className="par-1 --mb1 --alignCenter --success">
                        <p>{t('ax.lp.success_email_send')}</p>
                    </div>
                )}
            </div>

        </form>
    )
}