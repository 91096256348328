import {useForm, useWatch} from "react-hook-form";
import {useFormRulesBuilder} from "../../../forms/hooks";
import React, {useCallback, useEffect, useMemo} from "react";
import {Form} from "../../../forms";
import {FormGroup} from "../../../forms/components";
import {SelectController} from "../../../forms/controllers";
import {
    SearchNespressoMemberType1Fragment,
    SearchNespressoMemberType2Fragment,
    SearchNespressoMemberType3Fragment,
    SearchNespressoMemberType4Fragment
} from "./index";
import Button from "../../buttons/Button";
import {useCountry, useTranslation} from "../../../hooks";

export default function SearchForm({state, mutation, attempts, maxAttempts, attemptsDelayTime, onSubmit}) {
    state = {
        isLoading: false,
        error: null,
        ...state,
    }

    const {t} = useTranslation()
    const {data: country} = useCountry()
    const formMethods = useForm({
        mode: 'onChange',
        shouldUnregister: true,
    })
    const formRules = useFormRulesBuilder({
        'type': b => b.required(),
    }, formMethods)
    const type = useWatch({
        control: formMethods.control,
        name: 'type',
    })
    const typeOptions = useMemo(() => {
        return new Array(4).fill(0)
            .map((_, idx) => {
                idx++
                return {label: t(`assisted_search_nespresso_member.type${idx}`), value: idx}
            })
    }, [])

    const handleSubmit = useCallback(data => {
        if (attempts === 0)
            return
        onSubmit(data)
    }, [onSubmit, attempts])

    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            state={{
                isDisabled: state.isLoading,
                isLoading: state.isLoading,
            }}
            onSubmit={handleSubmit}
        >
            <div className="">
                <p className="par-1 --semiBold">{t('assisted_search_nespresso_member.title')}</p>
                {!!t('assisted_search_nespresso_member.text') && (
                    <p className="par-2">{t('assisted_search_nespresso_member.text')}</p>
                )}
            </div>

            <FormGroup>
                <SelectController
                    name="type"
                    label={t('assisted_search_nespresso_member.type_label')}
                    selectOptions={typeOptions}
                />
            </FormGroup>

            {!!type && (
                <div className="--mb4">
                    {type.value === 1 && (
                        <SearchNespressoMemberType1Fragment/>
                    )}
                    {type.value === 2 && (
                        <SearchNespressoMemberType2Fragment/>
                    )}
                    {type.value === 3 && (
                        <SearchNespressoMemberType3Fragment/>
                    )}
                    {type.value === 4 && (
                        <SearchNespressoMemberType4Fragment/>
                    )}

                    {attempts > 0 && attempts < maxAttempts && (
                        <p className="par-2">{t('assisted_search_nespresso_member.attempts', attempts)}</p>
                    )}
                    {attempts === 0 && attemptsDelayTime > 0 && (
                        <p className="par-2">{t('assisted_search_nespresso_member.no_attempts', attemptsDelayTime)}</p>
                    )}

                    <Button
                        btnType="submit"
                        label={t('search')}
                        isLoading={state.isLoading}
                        disabled={state.isLoading || attempts === 0}
                    />
                </div>
            )}
        </Form>
    )
}
