import {TextInputController} from "../controllers";
import React from "react";
import {useTranslation} from "../../hooks";

export default function InvoiceNumberInput(props) {
    const {t} = useTranslation()

    return (
        <TextInputController
            name="invoiceNumber"
            label={t('invoice_number')}
            {...props}
        />
    )
}
