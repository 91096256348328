import {useMutation} from "@tanstack/react-query";
import {ApiException} from "../../../../errors";
import {useResellerActions} from "../../../../actions/useResellerActions";

export default function useChangeUserStatus(){
    const resellerActions = useResellerActions()

    const userStatusMutation = useMutation(async ({resellerId, status}) => {
            const payload = {
                id_reseller: typeof resellerId === "number" ? [resellerId] : resellerId,
                reseller_status: status
            }
            const {data} = await resellerActions.changeResellerStatus(payload)

            if (data.status === 'success') return data
            throw new ApiException(data.messages[0], data)
        }
    )

    return {
        userStatusMutation
    }
}