import Env from "../../utils/Env";
import React, {useMemo} from "react";
import {useLanguage} from "../../hooks";
import {queryParams} from "../../misc/Helpers";


export default function PaymentIframe({
    uuid,
    memberToken,
    language,
    orderFinalizedUrl,
}) {
    const currentLanguage = useLanguage()
    const params = useMemo(() => {
        return queryParams({
            hash: uuid,
            token: memberToken,
            lang: language || currentLanguage,
            api_url: Env.API_URL,
            order_finalized_url: orderFinalizedUrl,
        })
    }, [uuid, memberToken, language, orderFinalizedUrl])

    return (
        <iframe
            id="paymentIframe"
            width="100%"
            height="600px"
            style={{border: 'none'}}
            src={`/payment.html${params}`}
            // src={`/payment.html?hash=${props.order.uuid}&token=${props.order.member_token}&lang=${currentLanguage}&api_url=${Env.API_URL}&order_finalized_url=${navigation(Routes.ORDER_FINALIZED, props.order.uuid)}&time=${Date.now()}`}
        />
    )
}
