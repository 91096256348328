import {useState} from "react";
import {useMutation} from "@tanstack/react-query";
import * as OrderActions from "../actions/OrderActions";
import {ApiException} from "../errors";
import {GTM} from "../misc/_index";
import {useGTM, useReseller, useTranslation} from "./index";
import {useHistory} from "react-router-dom";

export default function useSaveOrder({action, params = null, setLinkSentModal}) {
    const {t} = useTranslation()
    const {isLoggedIn} = useReseller()
    const history = useHistory()
    const [error, setError] = useState(null)
    const {errorTracking} = useGTM()
    const mutation = useMutation({
        mutationFn: async ([payload, ...args]) => {
            const {data} = await action(payload, params, ...args)

            if(data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        onError: (error) => {
            console.log(error)
        }
    })

    const save = async ({payload, customData}) => {
        const savedOrder = await mutation.mutateAsync([payload, customData])
            .catch(({error}) => {
                if (['order_exists', 'subscription_exists'].includes(error)) {
                    history.replace({search: `?uuid=${payload.customer.uuid}`})
                    if (isLoggedIn) {
                        setLinkSentModal(true)
                    }
                } else {
                    errorTracking('Delivery And Payment', 'order_save_error')
                    setError(t('error.order_save_error'))
                }
            })

        if(savedOrder) {
            history.replace({search: `?uuid=${savedOrder.order?.uuid || savedOrder?.uuid}`})
            // if (isLoggedIn) {
            //     setLinkSentModal(true)
            // }
        }
    }

    return {
        mutation,
        save,
        isLoading: mutation.isLoading,
        error,
        setError,
    }
}
