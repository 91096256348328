import {getImage} from "../../misc/Helpers";
import {MyInput} from "../forms/inputs/_index";
import React from "react";
import {useModuleContext, useRequiredField, useTranslation} from "../../hooks";
import editIcon from '../../assets/img/icons/edit.svg';

export default function MachinePreview({
    texts,
    onCLickEdit,
}) {
    const {t} = useTranslation()
    texts = {
        title: t('ev_machine_registration.text2_machine'),
        ...texts,
    }
    const {requiredField} = useRequiredField()
    const {store} = useModuleContext()
    const machine = store.machine

    if(!machine)
        return null

    return (
        <div>
            {!!texts.title && (
                <p
                    className="par-1 --semiBold"
                    dangerouslySetInnerHTML={{__html: texts.title}}
                />
            )}
            <p className="par-1 --semiBold">{t('my_machine')}</p>
            <div className="evMR__machine">
                <div className="evMR__image">
                    <img src={getImage(machine.machine_gallery)} alt=""/>
                </div>
                <div className="evMR__content">
                    <h3 className="par-2">{machine.machine_title}</h3>
                    <MyInput
                        label={t('serial_number')}
                        value={store.serialNumber?.serial_number}
                        readonly
                    />
                    <div className="--mb4">
                        {typeof onCLickEdit === 'function' && (
                            <div className="--mb2">
                                <button
                                    className="link -primary"
                                    onClick={onCLickEdit}
                                >{t('edit_serial_number')} <img
                                    src={editIcon} alt=""
                                    style={{width: '12px'}}/></button>
                            </div>
                        )}

                        <p
                            className="par-8"
                            dangerouslySetInnerHTML={{__html: t('ev_machine_registration.text3')}}
                        />
                    </div>
                </div>
            </div>
            {!!(store.isFastOrder && requiredField === 'none_required' && store.fastOrderShop) && (
                <div className="pop --mb4">
                    <div className="pop__col">
                        <div className="pop__group">
                            <p className="pop__label">{t('trade_partner')}</p>
                            <p className="pop__value">{store.fastOrderShop.group_name}</p>
                        </div>
                    </div>
                    <div className="pop__col">
                        <div className="pop__group">
                            <p className="pop__label">{t('shop')}</p>
                            <p className="pop__value">{store.fastOrderShop.name}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
