import {Config} from "../../../utils/_index";


/**
 * Returns Shop reopen module steps
 * @returns {{component, route: string, title: string}[]}
 */
export default function useSteps() {
    return Config.SHOP_REOPEN_STEPS.filter(step => true)
}
