import {isEnabledByMarket} from "../utils/MarketConfig";

const SET_UUID = 'machineRegistration/setUuid';
const SET_RECAPTCHA_TOKEN = 'machineRegistration/setRecaptchaToken';

const SET_PROMO_CODE = 'machineRegistration/setPromoCode';
const SET_MACHINE = 'machineRegistration/setMachine';
const SET_MACHINE_RESPONSE = 'machineRegistration/setMachineResponse';
const SET_PROOF_OF_PURCHASE = 'machineRegistration/setProofOfPurchase';
const SET_PROOF_OF_PURCHASE_LOGS = 'machineRegistration/setProofOfPurchaseLogs';
const SET_IS_NEW_MEMBER = 'machineRegistration/setIsNewMember';
const SET_NESPRESSO_MEMBER = 'machineRegistration/setNespressoMember';
const SET_NESPRESSO_MEMBER_EXIST = 'machineRegistration/setNespressoMemberExist';
const SET_PROMOTION = 'machineRegistration/setPromotion';
const SET_ONLY_PROMOTION = 'machineRegistration/setOnlyPromotion';
const SET_CUSTOMER = 'machineRegistration/setCustomer';
const SET_OPT_IN = 'machineRegistration/setOptIn';

const SET_PROMOTIONS = 'machineRegistration/setPromotions';
const SET_PRODUCTS = 'machineRegistration/setProducts';
const SET_CATEGORIES = 'machineRegistration/setCategories';
const UPDATE_CART = 'machineRegistration/updateCart';

const SET_EXTRA_LINE_PRODUCTS = 'machineRegistration/setExtraLineProducts';
const SET_EXTRA_LINE_CATEGORIES = 'machineRegistration/setExtraLineCategories';

const SET_PAID = 'machineRegistration/setPaid';
const SET_OCR_LOGS = 'machineRegistration/setOcrLogs';
const SET_ORDER = 'machineRegistration/setOrder';

const SET_COFFEE_LINES = 'machineRegistration/setCoffeeLines';
const SET_ADDRESSES = 'machineRegistration/setAddresses';

const SET_PAYMENT_METHOD = 'machineRegistration/setPaymentMethod';
const SET_DELIVERY_METHOD = 'machineRegistration/setDeliveryMethod';
const SET_EASY_ORDER_SETUP = 'machineRegistration/setEasyOrderSetup';

const SET_LOADING_CATEGORIES = 'machineRegistration/setLoadingCategories';
const SET_CAN_REGISTER_RESPONSE = 'machineRegistration/setCanRegisterResponse';
const SET_COOKIES_ACCEPTED = 'machineRegistration/setCookiesAccepted';
const SET_ABANDONED_ORDER_CONSENT_ACCEPTED = 'machineRegistration/setAbandonedOrderConsentAccepted';

const SET_ORDER_ACCOUNT = 'machineRegistration/setOrderAccount';
const SET_LAST_VISIT = 'machineRegistration/setLastVisit';
const SET_CART_QUOTATION_DATA = 'machineRegistration/setCartQuotationData';
const SET_FREQUENCIES = 'machineRegistration/setFrequencies';
const SET_IS_POST_PURCHASE_PROMO_CODE = 'machineRegistration/setIsPostPurchasePromoCode';
const SET_ABANDONED_ORDER = 'machineRegistration/setAbandonedOrder';
const SET_CART_VISIBLE = 'machineRegistration/setCartVisible';

const SET_IS_FAST_ORDER = 'machineRegistration/setIsFastOrder';
const SET_FAST_ORDER_SHOP = 'machineRegistration/setFastOrderShop';

const SET_OPEN_PROMOTION_MODALS = 'machineRegistration/setOpenPromotionModals';
const SET_OPEN_PLAN_MODALS = 'machineRegistration/setOpenPlanModals';
const SET_SEARCHED_MEMBERS = 'machineRegistration/setSearchedMembers';
const SET_SEARCHED_MEMBER = 'machineRegistration/setSearchedMember';
const SET_SEARCH_MEMBER_ATTEMPTS = 'machineRegistration/setSearchMemberAttempts';
const SET_SEARCH_MEMBER_ATTEMPT_DELAYED_AT = 'machineRegistration/setSearchMemberAttemptDelayedAt';
const SET_MSN_REQUIRED = 'machineRegistration/setMsnRequired';
const SET_WEB_ACCOUNT_EXISTS = 'machineRegistration/setWebAccountExists';

const RESET_MACHINE = 'machineRegistration/resetMachine';
const SET_PERSONALIZATION = 'machineRegistration/setPersonalization';
const CLEAR_STORE = 'machineRegistration/clearStore';


const initialState = {
    uuid: '',

    testOrder: false,
    serialNumber: null,

    promoCode: null,
    machine: null,
    machineResponse: null,
    proofOfPurchase: null,
    proofOfPurchaseLogs: null,
    proofOfPurchaseHistory: null,
    isNewMember: isEnabledByMarket('disableEcapi') ? 1 : undefined,
    nespressoMember: null,
    nespressoMemberExist: false,
    promotion: null,
    customer: null,
    optIn: null,

    promotions: [],
    categories: [],
    products: [],

    extraLineCategories: [],
    extraLineProducts: [],

    cart: {
        items: [],
        sum: '',
    },

    paymentMethod: null,
    deliveryMethod: null,
    order: null,
    paid: false,

    coffeeLines: null,
    addresses: [],

    recaptchaToken: null,

    easyOrderSetup: null,

    ocrLogs: [],

    loadingCategories: false,
    canRegisterResponse: null,

    cookiesAccepted: undefined,
    abandonedOrderConsentAccepted: undefined,

    orderAccount: null,
    orderAccountLinked: false,
    ecapiAccountCreated: false,
    cartQuotationData: null,

    lastVisit: null,
    isFastOrder: 0,
    fastOrderShop: null,

    frequencies: [],
    isPostPurchasePromoCode: false,
    abandonedOrder: null,
    cartVisible: false,
    openPromotionModals: [],
    openPlanModals: [],
    searchedMembers: [],
    searchedMember: null,
    searchMemberAttempts: -1,
    searchMemberAttemptDelayedAt: null,
    msnRequired: true,
    webAccountExists: false,

    personalization: null,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_UUID: return {...state, uuid: action.uuid}
        case SET_RECAPTCHA_TOKEN: return {...state, recaptchaToken: action.recaptchaToken}

        case SET_PROMO_CODE: return {...state, promoCode: action.promoCode}

        case SET_MACHINE: {
            const res = {
                ...state,
                machine: action.machine,
            }
            if(action.testOrder) {
                res.testOrder = action.testOrder;
            }
            if(action.serialNumber) {
                res.serialNumber = action.serialNumber;
            }
            return res;
        }
        case SET_PROOF_OF_PURCHASE: return {...state, proofOfPurchase: action.proofOfPurchase}
        case SET_PROOF_OF_PURCHASE_LOGS: return {...state, proofOfPurchaseLogs: action.proofOfPurchaseLogs}
        case SET_IS_NEW_MEMBER:
            let isNewMember = action.isNewMember
            if(isEnabledByMarket('disableEcapi') && typeof isNewMember === 'undefined') {
                isNewMember = 1
            }
            return {
                ...state,
                isNewMember
            }
        case SET_NESPRESSO_MEMBER: return {...state, nespressoMember: action.nespressoMember}
        case SET_NESPRESSO_MEMBER_EXIST: return {...state, nespressoMemberExist: action.nespressoMemberExist}
        case SET_PROMOTION:
            return {
                ...state,
                promotion: action.promotion,
                products: initialState.products,
                categories: initialState.categories,
                cart: {
                    items: [],
                    sum: '',
                },
                easyOrderSetup: initialState.easyOrderSetup,
                openPromotionModals: []
            }
        case SET_ONLY_PROMOTION: return {...state, promotion: action.promotion}
        case SET_OPEN_PROMOTION_MODALS: {
            return {
                ...state,
                openPromotionModals: action.openPromotionModals
            }
        }
        case SET_OPEN_PLAN_MODALS: return {...state, openPlanModals: action.openPlanModals}

        case SET_CUSTOMER: return {...state, customer: action.customer}
        case SET_OPT_IN: return {...state, optIn: action.optIn}

        case SET_PROMOTIONS: return {...state, promotions: action.promotions}
        case SET_PRODUCTS: return {...state, products: action.products}
        case SET_CATEGORIES:
            return {...state, categories: action.categories, loadingCategories: action.loadingCategories}
        case UPDATE_CART: return {...state, cart: action.cart}

        case SET_EXTRA_LINE_CATEGORIES: return {...state, extraLineCategories: action.extraLineCategories}
        case SET_EXTRA_LINE_PRODUCTS: return {...state, extraLineProducts: action.extraLineProducts}

        case SET_PAYMENT_METHOD: return {...state, paymentMethod: action.paymentMethod}
        case SET_DELIVERY_METHOD: return {...state, deliveryMethod: action.deliveryMethod}
        case SET_PAID: return {...state, paid: action.paid}
        case SET_OCR_LOGS: return {...state, ocrLogs: action.ocrLogs}
        case SET_ORDER: return {...state, order: action.order}

        case SET_COFFEE_LINES:
            return {...state, coffeeLines: action.coffeeLines}
        case SET_ADDRESSES:
            return {...state, addresses: action.addresses}

        case SET_EASY_ORDER_SETUP: return {...state, easyOrderSetup: action.easyOrderSetup}

        case SET_LOADING_CATEGORIES: return {...state, loadingCategories: action.loadingCategories}
        case SET_CAN_REGISTER_RESPONSE: return {...state, canRegisterResponse: action.canRegisterResponse}
        case SET_COOKIES_ACCEPTED: return {...state, cookiesAccepted: action.cookiesAccepted}
        case SET_ABANDONED_ORDER_CONSENT_ACCEPTED: return {...state, abandonedOrderConsentAccepted: action.abandonedOrderConsentAccepted}
        case SET_ORDER_ACCOUNT:
            return {
                ...state,
                orderAccount: action.orderAccount,
                orderAccountLinked: action.orderAccountLinked,
                ecapiAccountCreated: action.ecapiAccountCreated,
            }
        case SET_CART_QUOTATION_DATA:
            return {...state, cartQuotationData: action.cartQuotationData}
        case SET_FREQUENCIES: return {...state, frequencies: action.frequencies}
        case SET_CART_VISIBLE: return {...state, cartVisible: action.cartVisible}

        case SET_IS_FAST_ORDER: return {...state, isFastOrder: action.isFastOrder}

        case SET_FAST_ORDER_SHOP: return {...state, fastOrderShop: action.fastOrderShop}

        case SET_LAST_VISIT: return {...state, lastVisit: action.lastVisit}
        case SET_IS_POST_PURCHASE_PROMO_CODE: return {...state, isPostPurchasePromoCode: action.isPostPurchasePromoCode}
        case SET_ABANDONED_ORDER: return {...state, abandonedOrder: action.abandonedOrder}
        case RESET_MACHINE:
            return {
                ...state,
                machine: action.machine,
                testOrder: action.testOrder,
                serialNumber: action.serialNumber,
            }
        case SET_MACHINE_RESPONSE:return {...state, machineResponse: action.machineResponse}
        case SET_SEARCHED_MEMBERS:return {...state, searchedMembers: action.searchedMembers}
        case SET_SEARCHED_MEMBER:return {...state, searchedMember: action.searchedMember}
        case SET_SEARCH_MEMBER_ATTEMPTS:return {...state, searchMemberAttempts: action.searchMemberAttempts}
        case SET_SEARCH_MEMBER_ATTEMPT_DELAYED_AT:return {...state, searchMemberAttemptDelayedAt: action.searchMemberAttemptDelayedAt}
        case SET_MSN_REQUIRED:return {...state, msnRequired: action.msnRequired}
        case SET_WEB_ACCOUNT_EXISTS: return {...state, webAccountExists: action.webAccountExists}
        case SET_PERSONALIZATION: return {...state, personalization: action.personalization}

        case CLEAR_STORE:
            return {
                ...initialState,
                cart: {
                    items: [],
                    sum: '',
                },
            }

        default: return state
    }
}


export const setUuid = (uuid) => ({type: SET_UUID, uuid})
export const setRecaptchaToken = (recaptchaToken) => ({type: SET_RECAPTCHA_TOKEN, recaptchaToken})

export const setPromoCode = (promoCode) => ({type: SET_PROMO_CODE, promoCode})

export const setMachine = (machine, testOrder = null, serialNumber = null) => ({
    type: SET_MACHINE,
    machine,
    testOrder,
    serialNumber
})
export const setIsNewMember = (isNewMember) => ({type: SET_IS_NEW_MEMBER, isNewMember})
export const setNespressoMember = (nespressoMember) => ({type: SET_NESPRESSO_MEMBER, nespressoMember})
export const setNespressoMemberExist = (nespressoMemberExist) => ({type: SET_NESPRESSO_MEMBER_EXIST, nespressoMemberExist})
export const setProofOfPurchase = (proofOfPurchase) => ({type: SET_PROOF_OF_PURCHASE, proofOfPurchase})
export const setProofOfPurchaseLogs = (proofOfPurchaseLogs) => ({type: SET_PROOF_OF_PURCHASE_LOGS, proofOfPurchaseLogs})
export const setPromotion = (promotion) => ({type: SET_PROMOTION, promotion})
export const setOnlyPromotion = (promotion) => ({type: SET_ONLY_PROMOTION, promotion})
export const setCustomer = (customer) => ({type: SET_CUSTOMER, customer})
export const setOptIn = (optIn) => ({type: SET_OPT_IN, optIn})

export const setPromotions = (promotions) => ({type: SET_PROMOTIONS, promotions})
export const setOpenPromotionModals = (openPromotionModals) => ({type: SET_OPEN_PROMOTION_MODALS, openPromotionModals})
export const setProducts = (products) => ({type: SET_PRODUCTS, products})
export const setCategories = (categories, loadingCategories) => ({type: SET_CATEGORIES, categories, loadingCategories})
export const updateCart = (cart) => ({type: UPDATE_CART, cart})

export const setExtraLineProducts = (extraLineProducts) => ({type: SET_EXTRA_LINE_PRODUCTS, extraLineProducts})
export const setExtraLineCategories = (extraLineCategories) => ({type: SET_EXTRA_LINE_CATEGORIES, extraLineCategories})
export const setOpenPlanModals = (openPlanModals) => ({type: SET_OPEN_PLAN_MODALS, openPlanModals})
export const setPaymentMethod = (paymentMethod) => ({type: SET_PAYMENT_METHOD, paymentMethod})
export const setDeliveryMethod = (deliveryMethod) => ({type: SET_DELIVERY_METHOD, deliveryMethod})

export const setPaid = (paid) => ({type: SET_PAID, paid})
export const setOrder = (order) => ({type: SET_ORDER, order})
export const setOcrLogs = (ocrLogs) => ({type: SET_OCR_LOGS, ocrLogs})

export const setCoffeeLines = (coffeeLines) => ({type: SET_COFFEE_LINES, coffeeLines})
export const setAddresses = (addresses) => ({type: SET_ADDRESSES, addresses})

export const setEasyOrderSetup = (easyOrderSetup) => ({type: SET_EASY_ORDER_SETUP, easyOrderSetup})
export const setLoadingCategories = (loadingCategories) => ({type: SET_LOADING_CATEGORIES, loadingCategories})
export const setCanRegisterResponse = (canRegisterResponse) => ({type: SET_CAN_REGISTER_RESPONSE, canRegisterResponse})
export const setOrderAccount = (orderAccount, orderAccountLinked, ecapiAccountCreated = false) => ({
    type: SET_ORDER_ACCOUNT,
    orderAccount,
    orderAccountLinked,
    ecapiAccountCreated,
})
export const setCookiesAccepted = (cookiesAccepted) => ({type: SET_COOKIES_ACCEPTED, cookiesAccepted})
export const setAbandonedOrderConsentAccepted = (abandonedOrderConsentAccepted) => ({type: SET_ABANDONED_ORDER_CONSENT_ACCEPTED, abandonedOrderConsentAccepted})

export const setLastVisit = (lastVisit) => ({type: SET_LAST_VISIT, lastVisit})
export const setCartQuotationData = (cartQuotationData) => ({type: SET_CART_QUOTATION_DATA, cartQuotationData})
export const setFrequencies = (frequencies) => ({type: SET_FREQUENCIES, frequencies})
export const setIsPostPurchasePromoCode = (isPostPurchasePromoCode) => ({type: SET_IS_POST_PURCHASE_PROMO_CODE, isPostPurchasePromoCode})
export const setAbandonedOrder = (abandonedOrder) => ({type: SET_ABANDONED_ORDER, abandonedOrder})
export const setCartVisible = (cartVisible) => ({type: SET_CART_VISIBLE, cartVisible})

export const setIsFastOrder = (isFastOrder) => ({type: SET_IS_FAST_ORDER, isFastOrder})
export const setFastOrderShop = (fastOrderShop) => ({type: SET_FAST_ORDER_SHOP, fastOrderShop})
export const setMachineResponse = (machineResponse) => ({type: SET_MACHINE_RESPONSE, machineResponse})
export const setSearchedMembers = (searchedMembers) => ({type: SET_SEARCHED_MEMBERS, searchedMembers})
export const setSearchedMember = (searchedMember) => ({type: SET_SEARCHED_MEMBER, searchedMember})
export const setSearchMemberAttempts = (searchMemberAttempts) => ({type: SET_SEARCH_MEMBER_ATTEMPTS, searchMemberAttempts})
export const setSearchMemberAttemptDelayedAt = (searchMemberAttemptDelayedAt) => ({type: SET_SEARCH_MEMBER_ATTEMPT_DELAYED_AT, searchMemberAttemptDelayedAt})
export const setMsnRequired = (msnRequired) => ({type: SET_MSN_REQUIRED, msnRequired})
export const setWebAccountExists = (webAccountExists) => ({type: SET_WEB_ACCOUNT_EXISTS, webAccountExists})
export const setPersonalization = (personalization) => ({type: SET_PERSONALIZATION, personalization})

export const resetMachine = () => ({type: RESET_MACHINE})
export const clearStore = () => ({type: CLEAR_STORE})



export default reducer;
