import {CheckboxLabel, InvalidFeedback} from "../components";
import {useInputValidationClassNames} from "../hooks";

/**
 * TextInput
 * @param type
 * @param inputRef
 * @param fieldState
 * @param type
 * @param name
 * @param label
 * @param value
 * @param {{inputProps, isRequired: boolean, maxLength: number}} options
 * @param onChange
 * @param onBlur
 * @returns {JSX.Element}
 * @constructor
 */
export default function Checkbox({
    inputRef,
    fieldState = {},
    name,
    label,
    value,
    options = {
        inputProps: null,
        isRequired: false,
        maxLength: 0,
        // green: false,
    },
    onChange,
    onBlur,
}) {
    const {className} = useInputValidationClassNames({
        className: 'checkbox',
        value,
        fieldState,
    })

    return (
        <fieldset className={className}>
            <label htmlFor={name} className="checkbox__row">
                <input
                    ref={inputRef}
                    id={name}
                    className="checkbox__input"
                    type="checkbox"
                    name={name}
                    value={value}
                    checked={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    {...options.inputProps}
                />
                <span className="checkbox__checkmark"/>
                <CheckboxLabel
                    label={label}
                    options={options}
                />
            </label>
            <InvalidFeedback error={fieldState.error}/>
        </fieldset>
    )
}
