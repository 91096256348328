import React, {useEffect, useRef, useState} from "react";
import {Html5Qrcode} from "html5-qrcode"
import {useForm} from "react-hook-form";
import {MySelect} from "./forms/inputs/_index";
import {Preloader} from "../misc/_index";
import {useTranslation} from "../misc/Hooks";
import useResizeObserver from "@react-hook/resize-observer";
import BaseModal from "./modals/BaseModal";

export default function Scanner(props) {
    const t = useTranslation();

    const [camera, setCamera] = useState(props.cameras[props.cameras.length - 1].id)
    let html5QrCode = null;
    const config = {
        fps: 10,
        qrbox: {
            width: 350,
            height: 200,
        },
        disableFlip: false,
        focusMode: "continuous",
        advanced:[{zoom: 2.0}],
        experimentalFeatures: {
            useBarCodeDetectorIfSupported: true,
        },
        rememberLastUsedCamera: true
    }


    useEffect(() => {
            if(!!props.scannerRef?.current){
                props.scannerRef?.current.stop();
            }
            html5QrCode = new Html5Qrcode("reader");
            startCamera()
            props.scannerRef.current = html5QrCode;
    }, [camera])


    const startCamera = () => {
        html5QrCode.start(
            camera,
            config,
            (decodedText, decodedResult) => {
                props.scannerRef?.current.stop()
                props.scannerRef.current = null;
                props.onRead(decodedText)
            },
            (errorMessage) => {
                // parse error, ignore it.
            })
            .catch((err) => {
                // Start failed, handle it.
            });
    }

    const cameraOptions = props.cameras.map(cam => ({
        label: cam.label,
        value: cam.id
    }))


    return (
        <div className="scanner">
            <span className="scanner__title">{t('scanner.title')}</span>
            {!!camera && (
                <div className="scanner__cameraWrapper">
                    <div id="reader"/>
                </div>
            )}

            {props.cameras.length > 1 && (
                <MySelect
                    value={cameraOptions.find(cam => cam.value === camera)}
                    label={t('scanner.camera')}
                    options={cameraOptions}
                    onChange={v => setCamera(v.value)}
                    raw
                />
            )}
            <p className="scanner__problemMessage">
                {t('scanner.problem_text')}
                <button type="button" className="link -primary" onClick={props.selectFile}>
                    {t('scanner.problem_button')}
                </button>
            </p>
        </div>
    )
}