import {FormGroup} from "../../../forms/components";
import FileDropzone from "../../../forms/presets/FileDropzone";
import {useFormHelper, useModuleContext, useTranslation} from "../../../hooks";
import {useWatch} from "react-hook-form";
import {useEffect} from "react";
import {useMutation} from "@tanstack/react-query";
import * as mainActions from "../../../actions/MainActions";
import {ApiException} from "../../../errors";

export default function ReceiptInput({isDisabled}) {
    const {t} = useTranslation()
    const {formMethods} = useFormHelper()
    const {dispatch, reducer} = useModuleContext()
    const receipt = useWatch({control: formMethods.control, name: 'receipt'})

    useEffect(() => {
        if (receipt)
            uploadReceiptMutation.mutate(receipt)
        else
            dispatch(reducer.setReceiptData(null))
    }, [receipt])

    const uploadReceiptMutation = useMutation(async (file) => {
            const isPdf = !!file.type.match('application/pdf')

            const formData = new FormData()
            formData.append('file', file)

            const {data} = isPdf ?
                await mainActions.proofOfPurchasePdfOcr(formData) : await mainActions.proofOfPurchaseOcr(formData)

            if (data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)

        },
        {
            onMutate: () => formMethods.clearErrors('receipt'),
            onSuccess: (data) => {
                dispatch(reducer.setReceiptData(data))
            },
            onError: ({error}) => {
                formMethods.setValue('receipt', null)
                formMethods.setError('receipt', {type: 'invalid', message: t(error)})
                dispatch(reducer.setReceiptData(null))
            }
        })

    return (
        <>
            <FormGroup>
                <FileDropzone
                    name="receipt"
                    label={t('tps.receipt')}
                    options={{
                    inputProps: {
                        disabled: isDisabled
                    }
                }}
                />
            </FormGroup>
            {!receipt && (
                <p
                    className="uploadBox__caption --mb1"
                    dangerouslySetInnerHTML={{__html: t('ev_machine_registration.pop_text2')}}
                />
            )}
        </>
    )
}