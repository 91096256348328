import {Config, Routes} from "../../../utils/_index";
import {useSelector} from "react-redux";
import {useCountry} from "../../index";

/**
 * Returns Shop module steps
 * @returns {{component, route: string, title: string}[]}
 */
export default function useSteps() {
    const store = useSelector(({main, machineRegistration}) => ({
        user: main.user,
        isNewMember: machineRegistration.isNewMember,
    }))

    const country = useCountry()


    return Config.MACHINE_REGISTRATION_STEPS
        .filter(s => store.user
            ? !(!(store.isNewMember === undefined || store.isNewMember || country.data.country_assisted_simplified_flow_enabled) && s.route === Routes.MACHINE_REGISTRATION_CUSTOMER_INFORMATION)
            : true)
}
