import {TextInputController} from "../controllers";
import React from "react";
import {useFieldFormat, useTranslation} from "../../hooks";

export default function AddressLine2Input(props) {
    const {t} = useTranslation()
    const {formats} = useFieldFormat()

    return formats.addressLine2.isEnabled && (
        <TextInputController
            name="addressLine2"
            label={t('address_line_2')}
            {...props}
        />
    )
}
