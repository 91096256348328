import {useSelector} from "react-redux";
import {useCountryImages} from "./index";
import {isEnabledByMarket} from "../utils/MarketConfig";

export default function useCountry() {
    const country = useSelector(({main}) => main.country)
    const images = useCountryImages()

    const formatShopLabel = (shop) => {
        if(!shop)
            return null

        const config = {
            'title': shop.shop_title,
            'address': shop.shop_address,
            'city': shop.shop_city,
            'postcode': shop.shop_postcode,
            'area': shop.shop_area,
            'region': shop.shop_region,
            'pos': shop.shop_pos,
        }

        if(!country.country_shop_labels
            || !Array.isArray(country.country_shop_labels)
            || !country.country_shop_labels.length) {
            return shop.shop_title
        }
        
        return country.country_shop_labels
            .sort((a,b) => a.country_shop_labels_position < b.country_shop_labels_position ? -1 : 1)
            .map(i => i.country_shop_labels_name.toLowerCase())
            .filter(i => i)
            .join(',')
            .replace('city,postcode', 'city postcode')
            .replace('postcode,city', 'postcode city')
            .replace('title', config.title || '')
            .replace('address', config.address || '')
            .replace('city', config.city || '')
            .replace('postcode', config.postcode || '')
            .replace('area', config.area || '')
            .replace('region', config.region || '')
            .replace('pos', config.pos || '')
            .split(',')
            .map(i => i.trim())
            .filter(i => i)
            .join(', ')
    }

    const hideNewMemberCustomAddress = () => {
        return !!country.country_disable_other_delivery_address
    }

    const hideExistingMemberCustomAddress = () => {
        return !!country.country_disable_other_delivery_address
    }

    const isAssistedSimplifiedFlowEnabled = () => {
        return !!country.country_assisted_simplified_flow_enabled
    }

    return {
        data: country,
        formatShopLabel,
        images,
        hideNewMemberCustomAddress,
        hideExistingMemberCustomAddress,
        isAssistedSimplifiedFlowEnabled,
    }
}
