import {TableComponent} from "../../../../components/baseTable";
import {useReportOrdersTable, useResellerReports} from "../hooks";
import {useQrCodeModal, useReseller, useTranslation} from "../../../../hooks";
import {SelectedReportsUsersActions} from "../components";
import {ProcessedButton, ResendButton} from "../../components";
import {Button} from "../../../../components/buttons/_index";
import React, {Fragment} from "react";
import {Routes} from "../../../../utils/_index";
import {QrCodeModal} from "../../../../components/modals/_index";
import {ResellersListModal} from "../../../../components/modals/reseller";

export default function ReportsScreen() {

    const {
        usersTable,
        usersFilters,
        filtersDataMutation,
        selectAllItems,
        generatedReportsTable,
        setResellersModalVisible,
        resellersModalVisible,
        modalResellers
    } = useResellerReports()
    const {t} = useTranslation();
    const {role} = useReseller()
    const qrCodeModal = useQrCodeModal(Routes.ORDER_FINISH);

    const optionColumn = {
        name: 'options',
        label: t('order.options'),
        cell: ({row: {original}}) => {
            return [1, 2].includes(original.payment_status) ? (
                <ProcessedButton idOrder={original.id_order}/>
            ) : (
                <div className="tableOptions">
                    <ResendButton order={original}/>
                    <Button
                        size="sm"
                        label={t('generate_qr_code')}
                        onClick={() => qrCodeModal.showQrCode(original.uuid, original.lang, original.payment_link_time)}
                    />
                </div>
            )
        }
    }

    const {orderTable, orderFilter} = useReportOrdersTable({
        optionColumn: [optionColumn]
    })

    return (
        <Fragment>
            <div className="wrapper -xxl">
                <div className="generatingReport">
                    <TableComponent
                        {...usersTable}
                        title={t('users')}
                        filters={role === 'ka_super_user' ? usersFilters : null}
                        filtersIsLoading={filtersDataMutation.isLoading}
                        selectAllItems={selectAllItems}
                        SelectedActionsComponent={SelectedReportsUsersActions}
                        actionsAlwaysVisible
                        actionsTitle={t('reports_users.filters.title_normal_user')}
                        hideTable={role === 'pos_user' || role === 'nca_user'}
                    />
                    <TableComponent
                        {...orderTable}
                        title={t('assisted_registration.orders')}
                        filters={orderFilter}
                    />

                    <TableComponent
                        {...generatedReportsTable}
                        title={t('reports.table.title')}
                    />

                </div>
            </div>
            <QrCodeModal {...qrCodeModal} />
            <ResellersListModal
                visible={resellersModalVisible}
                onClose={() => setResellersModalVisible(false)}
                resellersList={modalResellers}
            />
        </Fragment>

    )
}