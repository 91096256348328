import {useLocalizeObject, useTranslation} from "../../../misc/Hooks";
import {useDispatch, useSelector} from "react-redux";
import {isEnabledByMarket} from "../../../utils/MarketConfig";
import {useEffect} from "react";
import * as stayHomeReducer from "../../../redux/stayHomeReducer";
import {StayHomeAuthForm, StayHomeCodeForm} from "../../../forms";

export default function Identification(props) {
    const t = useTranslation()
    const localizeObject = useLocalizeObject()
    const dispatch = useDispatch()
    const store = useSelector(({main, stayHome}) => ({
        config: main.stayHomeConfig,
        withEmail: stayHome.withEmail,
        email: stayHome.email,
        data: stayHome.data,
    }))

    useEffect(() => {
        if (!store.data) {
            dispatch(stayHomeReducer.setWithEmail(!!Number(store.config?.stay_home_email)))
        }
    }, [store.config])


    return (
        <div className="shAuthorization">

            {isEnabledByMarket('defaultImageEnabledInStayHomeIdentificationStep') ? (
                <div className="grid --mb4">
                    <div style={{position: 'relative', height: '100%'}}>
                        <img src={require('../../../assets/img/stay-home-img.jpg')} alt=""
                             className="shAuthorization__img"/>
                    </div>
                    <div
                        className="par-5 --mb0"
                        dangerouslySetInnerHTML={{__html: localizeObject(store.config.stay_home_description)}}
                    />
                </div>
            ) : (
                <div
                    className="par-5 --mb4"
                    dangerouslySetInnerHTML={{__html: localizeObject(store.config.stay_home_description)}}
                />
            )}

            {(!props.token || !store.withEmail) && (
                <div className="grid">
                    <div>
                        <StayHomeAuthForm
                            email={props.email}
                            state={{
                                isLoading: props.loading,
                                error: props.error,
                            }}
                            onSubmit={props.onSubmit}
                        />
                    </div>
                    <div/>
                </div>
            )}

            {(props.token && store.withEmail) && (
                <div className="grid">
                    <div>
                        <p
                            className="par-2"
                            dangerouslySetInnerHTML={{__html: t('stay_home.identification.code_info')}}
                        />
                        <StayHomeCodeForm
                            code={props.code}
                            state={{
                                isLoading: props.loading,
                                error: props.error,
                            }}
                            onSubmit={props.onSubmitCode}
                            onGoBack={props.onGoBack}
                        />
                    </div>
                    <div/>
                </div>
            )}
        </div>
    )
}
