import {Radio} from "../inputs";
import {useController} from "react-hook-form";
import {useFormHelper} from "../../hooks";
import {InvalidFeedback} from "../components";
import {useMemo} from "react";

/**
 * RadioController
 * @param name
 * @param defaultValue
 * @param rules
 * @param radioOptions
 * @param renderOption
 * @param options
 * @returns {JSX.Element}
 * @constructor
 */
export default function RadioController({
    name: _name,
    defaultValue = '',
    rules: _rules,
    radioOptions = [],
    renderOption,
    options,
}) {
    const formHelper = useFormHelper()
    const name = useMemo(
        () => formHelper.formFragmentContext.getInputName(_name),
        [_name, formHelper.formFragmentContext]
    )
    const rules = useMemo(
        () => formHelper.getRules(_rules ?? _name),
        [formHelper, _rules, _name]
    )
    const {
        field: {ref, ...controllerInputProps},
        fieldState,
    } = useController({
        name,
        control: formHelper.formMethods.control,
        defaultValue,
        rules: rules,
    })

    const inputOptions = formHelper.getInputOptions(options, rules)

    return (
        <div className="checkboxGroup">
            {radioOptions.map(option => (
                <div className="--mb2" key={option.value}>
                    <Radio
                        {...controllerInputProps}
                        inputRef={ref}
                        fieldState={fieldState}
                        option={option}
                        renderOption={renderOption}
                        options={{
                            ...inputOptions,
                            inputProps: {
                                ...inputOptions?.inputProps,
                                disabled: formHelper.formMethods.state?.isDisabled || inputOptions?.inputProps?.disabled,
                            }
                        }}
                    />
                </div>
            ))}
            <InvalidFeedback error={fieldState.error}/>
        </div>
    )
}
