import {useDispatch, useSelector} from "react-redux";
import * as mainActions from '../../actions/MainActions';
import React, {useEffect, useState} from "react";
import PagePreloader from "../../components/PagePreloader";
import * as mainReducer from '../../redux/mainReducer';
import {useHistory} from "react-router-dom";
import {useNavigation} from "../../misc/Hooks";
import Routes from "../../utils/Routes";
import * as stayHomeReducer from "../../redux/stayHomeReducer";
import {checkAutoAdd} from "../../misc/Helpers";
import {useShActions} from "../../actions/useShActions";
import {useReseller} from "../../hooks";
import mapToOldCategoriesStructure from "../../helpers/mapToOldCategoriesStructure";

export default function Provider(props) {
    const history = useHistory()
    const navigation = useNavigation()
    const actions = useShActions()
    const dispatch = useDispatch();
    const {logout} = useReseller()
    const store = useSelector(({main, stayHome}) => ({
        config: main.stayHomeConfig,
        token: stayHome.token,
        nespressoMember: stayHome.nespressoMember,
        cart: stayHome.cart,
        isNewMember: stayHome.isNewMember,
    }))
    const [loaded, setLoaded] = useState(!!store.config)

    useEffect(() => {
        if (store.token && store.isNewMember !== undefined) {
            fetchCategories()
        }
    }, [store.token, store.isNewMember])

    useEffect(() => {
        fetchConfig()
        logout()

    }, [])

    const fetchConfig = async () => {
        await mainActions.getStayHomeConfig()
            .then(r => {
                const res = r.data
                if (res.status === 'success') {
                    if (!Number(res.data.stay_home_status)) {
                        history.push(navigation(Routes.MACHINE_REGISTRATION_REGISTRATION))
                        return
                    }
                    dispatch(mainReducer.setStayHomeConfig(res.data))
                } else {
                    throw new Error(res.messages[0])
                }
            })
            .catch(err => {
                console.error('Cannot fetch stay home config!', err)
            })
        setLoaded(true)
    }

    const fetchCategories = async () => {
        const categories = []
        const products = []

        dispatch(stayHomeReducer.setLoadingCategories(true))
        await Promise.all([
            actions.getCategories(store.token, 0, store.nespressoMember ? 1 : 0)
                .then(r => {
                    const res = r.data
                    if (res.status === 'success') {
                        const cats = res.data.categories.reduce((arr, c) => {
                            const category = {}
                            Object.keys(c).map(k => {
                                category[k.replace(/^stay_home/, 'promotion')] = c[k]
                            })

                            category.products = category.products?.map(p => {
                                if (p.stay_home_product) {
                                    const promotionProducts = []
                                    Object.keys(p.stay_home_product).map(k => {
                                        promotionProducts[k.replace(/^stay_home/, 'promotion')] = p.stay_home_product[k]
                                    })
                                    delete p.stay_home_product
                                    return {
                                        ...p,
                                        promotion_product: promotionProducts,
                                    }
                                }
                                return p
                            })

                            arr.push(category)
                            return arr
                        }, [])

                        products.push(...Object.values(res.data.products))
                        categories.push(...cats)

                    } else {
                        throw new Error(res.messages[0])
                    }
                })
                .catch(err => {
                    console.error('Cannot fetch stay home categories and products!', err)
                }),
            actions.plans(store.token).then(r => {
                const res = r.data;
                if (res.status === 'success') {
                    products.push(...Object.values(res.data.products))
                    categories.push(...res.data.categories)
                } else {
                    console.log('Something gone wrong! ', res)
                }
            }).catch(err => {
                console.error('Error: ', err)
            })
        ])

        dispatch(stayHomeReducer.setProducts(products))
        dispatch(stayHomeReducer.setCategories(categories))
        dispatch(stayHomeReducer.updateCart(checkAutoAdd(
            categories,
            Object.values(products),
            store.cart,
            null,
            0
        )))
        dispatch(stayHomeReducer.setLoadingCategories(false))
    }

    return (
        <>
            {loaded ? props.children : <PagePreloader/>}
        </>
    )
}
