import React, {Fragment, useState} from "react";
import {useTranslation} from "../../../../hooks";
import Button from "../../../../components/buttons/Button";
import {useMutation} from "@tanstack/react-query";
import {useRenewalCertificate} from "../hooks";

export default function RenewalCertificate({
    isCertificateExpired,
    date,
    canRenewal,
    idReseller,
    query
}) {

    const {t} = useTranslation();
    const {renewalMutation, label} = useRenewalCertificate(query);


    return (
        <Fragment>
           <span className={`--dBlock ${isCertificateExpired && '--error'}`}>{date ?? t('unset')}</span>
            {canRenewal && (
                <Button
                    size="sm"
                    label={t(label)}
                    onClick={() => renewalMutation.mutate(idReseller)}
                    isLoading={renewalMutation.isLoading}
                    disabled={!renewalMutation.isIdle || query.isFetching}
                />
            )}
        </Fragment>
    )
}