import {AppProvider, Preloader} from "../../misc/_index";
import {makeStore, ModuleProvider} from "../../hooks";
import {useMarketConfig, useNavigation} from "../../misc/Hooks";
import {TpsScreen} from "./index";
import {Redirect} from "react-router-dom";
import Routes from "../../utils/Routes";

export default function Funnel() {

    const contextValue = makeStore({
        storeName: 'tps',
    })

    const marketConfig = useMarketConfig()
    const navigation = useNavigation()

    return (
        <AppProvider>
            <ModuleProvider value={contextValue}>
                {!marketConfig || !Object.keys(marketConfig).length ? (
                    <Preloader/>
                ) : !marketConfig.tps ? (
                    <Redirect to={navigation(Routes.MACHINE_REGISTRATION_REGISTRATION)}/>
                ) : (
                    <TpsScreen/>
                )}
            </ModuleProvider>
        </AppProvider>
    )
}