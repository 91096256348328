import React, {useEffect} from "react";
import {useFormBuilder, useFormRulesBuilder} from "../hooks";
import {AccountNumberInput} from "../presets";
import {useFieldFormat, useFormHelper, useTranslation} from "../../hooks";
import {Controller, useWatch} from "react-hook-form";
import {CheckboxController, MaskInputController} from "../controllers";
import {useBank} from "../../components/PaymentDetailsForm/hooks";
import {FormBuilderComponent} from "../components";
import HiddenInputController from "../controllers/HiddenInputController";
import {toUpperCamelCase} from "../../misc/Helpers";
import {MyCheckbox} from "../../components/forms/inputs/_index";
import {useCountryConfig, useMarketConfig} from "../../misc/Hooks";
import PdfViewer from "../../components/pdfViewer";

/**
 * Terms form fragment
 * @param prefix
 * @param options
 * @param options.termsAndConditionsLabel,
 * @param options.termsAndConditionsFileUrl,
 * @param options.privacyPolicyLabel,
 * @param options.privacyPolicyFileUrl,
 * @returns {JSX.Element}
 * @constructor
 */
export default function TermsFormFragment({
    prefix = '',
    options = {},
}) {
    options = {
        termsAndConditionsFileUrl: null,
        termsAndConditionsLabel: null,
        privacyPolicyFileUrl: null,
        privacyPolicyLabel: null,
        ...options,
    }
    const {t} = useTranslation()
    const countryConfig = useCountryConfig()
    const marketConfig = useMarketConfig()
    const {formMethods, ...formHelper} = useFormHelper(prefix)
    const formRules = useFormRulesBuilder({
        termsAndConditions: b => b.required(),
        privacyPolicy: b => b.required(),
    }, formMethods)
    const {components} = useFormBuilder({
        formConfig: [
            ['termsAndConditions'],
            ['privacyPolicy'],
        ],
        inputVisibility: {},
        inputComponents: {
            'termsAndConditions': (
                <CheckboxController
                    name="termsAndConditions"
                    rules={formRules.values.termsAndConditions}
                    label={() => (
                        <span
                            className="par-2 --mb0 --highlightLinks --bold --brown"
                            style={{paddingTop: '0.25rem'}}
                            dangerouslySetInnerHTML={{
                                __html: options.termsAndConditionsLabel || t('terms_and_conditions_confirmation', options.termsAndConditionsFileUrl || t('terms_and_conditions_url'))
                            }}
                        />
                    )}
                />
            ),
            'privacyPolicy': (
                <>
                    <div className="--mb2_5">
                        <CheckboxController
                            name="privacyPolicy"
                            rules={formRules.values.privacyPolicy}
                            label={() => (
                                <span
                                    className="par-2 --mb0 --highlightLinks --bold --brown"
                                    style={{paddingTop: '0.25rem'}}
                                    dangerouslySetInnerHTML={{
                                        __html: options.privacyPolicyLabel || t('privacy_policy_confirmation', options.privacyPolicyFileUrl || t('privacy_policy_url'))
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="privacyPolicyOptions">
                        {countryConfig?.privacy_policy?.map((pp, idx) => {
                            const variableName = toUpperCamelCase(`privacy_policy_${pp.country_privacy_policy_name}`)
                            return (
                                <CheckboxController
                                    key={idx}
                                    name={variableName}
                                    label={() => (
                                        <span
                                            className="par-2 --highlightLinks --bold --brown"
                                            style={{paddingTop: '0.25rem'}}
                                            dangerouslySetInnerHTML={{__html: t(`privacy_policy_${pp.country_privacy_policy_name}`)}}
                                        />
                                    )}
                                />
                            )
                        })}
                    </div>
                </>
            ),
        },
    })
    const privacyPolicy = useWatch({
        control: formMethods.control,
        name: formHelper.name('privacyPolicy')
    })

    useEffect(() => {
        if(privacyPolicy && !!countryConfig?.privacy_policy_all_consents_marked){
            countryConfig?.privacy_policy?.map((pp) => {
                const privacyPolicyOption = toUpperCamelCase(`privacy_policy_${pp.country_privacy_policy_name}`)
                formMethods.setValue(formHelper.name(privacyPolicyOption), true, {shouldValidate: true})
            })
        }
    }, [privacyPolicy])

    return (
        <>
            {!!marketConfig.terms_and_conditions_preview && (
                <div className="grid --mb4">
                    <PdfViewer url={options.termsAndConditionsFileUrl || t('terms_and_conditions_url')}/>
                </div>
            )}

            <FormBuilderComponent prefix={prefix} components={components}/>
        </>
    )
}
