import PlanSelection from "../screens/PlanSelection";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "../../../misc/Hooks";
import * as stayHomeReducer from "../../../redux/stayHomeReducer";
import {Config} from "../../../utils/_index";
import {useSeoPage} from "../../../hooks";

export default function PlanSelectionController(props) {
    useSeoPage({title: 'stay_home.plan_selection.title'})
    const t = useTranslation()
    const dispatch = useDispatch()
    const store = useSelector(({stayHome}) => ({
        token: stayHome.token,
        products: stayHome.products,
        categories: stayHome.categories,
        cart: stayHome.cart,
    }))
    const plan = useSelector(({stayHome}) => {
        return stayHome.products?.find(p => {
            const item = stayHome.cart?.items?.find(i => i.id === p.id_product);
            return item && p.product_type.startsWith('plan_')
        })
    })
    const plans = useSelector(({stayHome}) => {
        return stayHome.products.filter(p => p.product_type === Config.COFFEE_TYPE.PLAN_WORK_HOME)
            .sort((a,b) => a.product_plan_price?.gross < b.product_plan_price?.gross ? -1 : 1);
    })


    const handleChangeUserHasPlan = (value) => {
        dispatch(stayHomeReducer.setUserHasPlan(value))
        if(value) {
            dispatch(stayHomeReducer.clearCart())
        }
    }

    return (
        <PlanSelection
            plans={plans}
            cart={store.cart}
            plan={plan}
            onChangeUserHasPlan={handleChangeUserHasPlan}
        />
    )
}
