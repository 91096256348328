import React, {Fragment, useEffect} from "react";
import {useSeoPage, useTranslation} from "../../../hooks";
import {useResellerResetPassword} from "../hooks";
import {ResellerNewPasswordForm} from "../../../forms";
import {InfoComponent} from "../../../components/_index";
import {Preloader} from "../../../misc/_index";
import {CountryAuthImage} from "../components";

export default function ResellerSetNewPasswordScreen(){
    useSeoPage({title: 'set_new_password_reseller.seo_title'})
    const {t} = useTranslation();
    const resellerResetPassword = useResellerResetPassword();

    useEffect(() => {
        resellerResetPassword.verificationTokenQuery.refetch()
    }, [])

    return (
        <div className="resAuth">
            <div className="resAuth__content">
                {resellerResetPassword.verificationInProgress ? (
                    <Preloader/>
                ) : (
                    <Fragment>
                        <h1 className="header-1">{t('set_new_password_reseller.title')}</h1>
                        <h2 className="par-2 --mb3">{t('set_new_password_reseller.text')}</h2>
                        {resellerResetPassword.tokenIsValid && (
                                <ResellerNewPasswordForm
                                    onSubmit={resellerResetPassword.resetPasswordMutation.mutate}
                                    state={{
                                        isLoading: resellerResetPassword.isLoading
                                    }}
                                />
                        )}
                        <InfoComponent
                            type={resellerResetPassword.message.type}
                            message={t(resellerResetPassword.message.message)}
                        />
                    </Fragment>
                    )}

            </div>
            <div className="resAuth__thumb">
                <CountryAuthImage/>
            </div>
        </div>
    )
}