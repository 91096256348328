import {Link} from "react-router-dom";
import {Button} from "../../../components/buttons/_index";
import {useTranslation} from "../../../misc/Hooks";
import {useStepsNavigation} from "../../../hooks/modules/shop_reopen";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {useNespressoMember} from "../../../hooks";
import {AuthForm} from "../../../forms";


export default function AuthorizationStepScreen() {
    const t = useTranslation();
    const stepNavigation = useStepsNavigation();
    const {loginMutation, error, nespressoMember, storeLoggedUser} = useNespressoMember()
    const store = useSelector(({shopReopen}) => ({
        promotions: shopReopen.promotions,
        data: shopReopen.data,
    }))
    const promotion = useMemo(() => {
        return store.promotions?.find(p => p.id_promotion === store.data.id_promotion)
    }, [store.data, store.promotions])

    const handleLogin = async (data) => {
        const payload = {
            login: data.email,
            password: data.password,
            recaptchaToken: data.recaptchaToken,
        }

        loginMutation.mutateAsync(payload)
            .then((data) => {
                storeLoggedUser(data, payload)
            })
    }

    return (
        <div className="evCID">

            <div className="grid">
                <div>
                    {!nespressoMember && !!t('shop_reopen.welcome_text') && (
                        <div
                            className="par-1 --semiBold --mb4"
                            dangerouslySetInnerHTML={{__html: t('shop_reopen.welcome_text')}}
                        />
                    )}

                    {!nespressoMember && (
                        <div className="grid">
                            <div className="col">
                                <div className="--mb3">
                                    <AuthForm
                                        state={{
                                            error,
                                            isLoading: loginMutation.isLoading,
                                        }}
                                        onSubmit={handleLogin}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {!!nespressoMember && (
                        <>
                            <p
                                className={`par-1 --semiBold --mb4 ${promotion ? '--success' : '--error'}`}
                                dangerouslySetInnerHTML={{
                                    __html: promotion ? t('shop_reopen.promo_available') : t('shop_reopen.promo_not_available'),
                                }}
                            />
                            <div className="step__nav">
                                <Button
                                    label={t('continue')}
                                    href={stepNavigation.next()}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div/>
            </div>

        </div>
    );
}
