import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import {Routes} from "../utils/_index";
import Env from "../utils/Env";
import {ClearStorageContainer, MaintenanceContainer, PaymentRejectedContainer} from "../containers/_index";
import Axios from "../utils/Axios";
import {Error404, ErrorScreen} from "../components/misc/_index";
import * as eTradeHooks from '../hooks/modules/etrade'
import * as stayHomeHooks from '../hooks/modules/stay_home'
import * as orderFinishHooks from '../hooks/modules/order_finish'
import * as shopReopenHooks from '../hooks/modules/shop_reopen'
import * as etradeReopenHooks from '../hooks/modules/etrade_reopen'
import * as noMachineShopHooks from '../hooks/modules/no_machine_shop'
import * as subscriptionHooks from '../hooks/modules/subscription'
import * as machineRegistrationHooks from '../hooks/modules/machine_registration'
import {TpsFunnel} from "../containers/tps";
import {Funnel as TpsFunnelV2} from "../modules/tps";
import BaseModal from "../components/modals/BaseModal";
import ETradeFunnel from "../modules/etrade/Funnel"
import StayHomeFunnel from "../modules/stay_home/Funnel"
import OrderFinishFunnel from "../modules/order_finish/Funnel"
import MachineRegistrationFunnel from "../modules/machine_registration/Funnel"
import FastOrderFunnel from "../modules/fast_order/Funnel"
import ShopReopenFunnel from "../modules/shop_reopen/Funnel"
import EtradeReopenFunnel from "../modules/etrade_reopen/Funnel"
import noMachineShopFunnel from "../modules/no_machine_shop/Funnel"
import SubscriptionFunnel from "../modules/subscription/Funnel"
import {getStepsRoutes} from "./Helpers";
import {AxLandingPageScreen} from "../screens/AxLandingPageScreen";
import {OrderFinalizedScreen, PopTestScreen, ReuploadPopScreen, TestScreen, TestScreen2} from "../screens";
import SerialNumberTestScreen from "../screens/SerialNumberTestScreen";
import CustomerTestScreen from "../screens/CustomerTestScreen";
import ResellerAuthFunnel from "../modules/auth_v2/Funnel"
import ResellerFunnelV2 from "../modules/reseller_v2/Funnel"
import {AppProvider} from "./_index";
import PagePreloader from "../components/PagePreloader";
import {useCurrentLanguage} from "../hooks";
import ErrorBoundaryContainer from "../utils/ErrorBoundaryContainer";


const Navigation = (props) => {
    const {_userToken} = useSelector(({main}) => ({
        _userToken: main.userToken,
    }))
    const eTradeSteps = eTradeHooks.useSteps()
    const stayHomeSteps = stayHomeHooks.useSteps()
    const orderFinishSteps = orderFinishHooks.useSteps()
    const noMachineShopSteps = noMachineShopHooks.useSteps()
    const shopReopenSteps = shopReopenHooks.useSteps()
    const etradeReopenSteps = etradeReopenHooks.useSteps()
    const subscriptionSteps = subscriptionHooks.useSteps();
    const machineRegistrationSteps = machineRegistrationHooks.useSteps();
    const [timeoutModalVisible, setTimeoutModalVisible] = useState(false);

    const {currentLanguage} = useCurrentLanguage()

    const handleClearStore = () => {
        window.location.href = '/vst/clear-storage'
    }


    if (_userToken) {
        if (_userToken !== Axios.defaults.headers.get['Authorization']) {
            Axios.defaults.headers.get['Authorization'] = `Bearer ${_userToken}`;
            Axios.defaults.headers.post['Authorization'] = `Bearer ${_userToken}`;
        }
    } else {
        Axios.defaults.headers.get['Authorization'] = '';
        Axios.defaults.headers.post['Authorization'] = '';
    }

    Axios.defaults.headers.common['Language'] = currentLanguage;

    return (
        <>
            <Router>
                <Switch>
                    <Route exact path={Routes.HOME}>
                        <AppProvider>
                               <PagePreloader/>
                        </AppProvider>
                    </Route>

                    <Route exact path={Routes.ORDER_FINALIZED} component={OrderFinalizedScreen}/>

                    <Route exact path={Routes.UPLOAD_PROOF_OF_PURCHASE} component={ReuploadPopScreen}/>

                    <Route exact path={Routes.TPS} component={TpsFunnel}/>
                    <Route exact path={Routes.TPS_V2} component={TpsFunnelV2}/>

                    <Route exact path={Routes.CLEAR_STORAGE} component={ClearStorageContainer}/>
                    <Route exact path={Routes.MAINTENANCE} component={MaintenanceContainer}/>
                    <Route exact path={Routes.PAYMENT_REJECTED} component={PaymentRejectedContainer}/>
                    <Route exact path={Routes.AX_LANDING_PAGE} component={AxLandingPageScreen}/>

                    <Route
                        exact path={[
                        ...getStepsRoutes(orderFinishSteps),
                        Routes.ORDER_FINISH_INVALID_TOKEN,
                        Routes.ORDER_FINISH_TOKEN_EXPIRED,
                        Routes.ORDER_FINISH_ORDER_FINALIZED,
                        Routes.FINISH_ORDER,
                        Routes.ORDER_FINISH,
                    ]}
                        component={OrderFinishFunnel}
                    />

                    <Route
                        exact path={[
                        ...getStepsRoutes(eTradeSteps),
                        Routes.ETRADE_INVALID_TOKEN,
                        Routes.ETRADE_TOKEN_EXPIRED,
                        Routes.ETRADE_ORDER_FINALIZED,
                        Routes.ETRADE,
                    ]}
                        component={ETradeFunnel}
                    />

                    <Route
                        exact path={[
                        ...getStepsRoutes(stayHomeSteps),
                        Routes.STAY_HOME_ORDER_FINALIZED,
                        Routes.STAY_HOME_TOKEN_EXPIRED,
                        Routes.STAY_HOME,
                    ]}
                        component={StayHomeFunnel}
                    />

                    <Route
                        exact path={[
                        Routes.V2_RESELLER_AUTH,
                        Routes.V2_RESELLER_FORGOT_PASSWORD,
                        Routes.V2_RESELLER_FORGOT_PASSWORD_SET_NEW,
                        Routes.V2_AUTH_RESELLER_REGISTRATION,
                        Routes.V2_AUTH_RESELLER_VERIFICATION,
                    ]}
                        component={ResellerAuthFunnel}
                    />

                    <Route
                        exact path={[
                        Routes.V2_RESELLER,
                        Routes.V2_RESELLER_ASSISTED_REGISTRATION,
                        Routes.V2_RESELLER_FAST_ORDER,
                        Routes.V2_RESELLER_MACHINE_SUBSCRIPTIONS,
                        Routes.V2_RESELLER_ADMIN_REPORTS,
                        Routes.V2_RESELLER_USERS,
                        Routes.V2_RESELLER_USERS_USER,
                        Routes.V2_RESELLER_REPORTS,
                        Routes.V2_RESELLER_REPORTS_NEW,
                        Routes.V2_RESELLER_NO_MACHINE_SHOP,
                        Routes.V2_RESELLER_BURN,
                        Routes.V2_RESELLER_AX,
                        Routes.V2_RESELLER_MESSAGES,
                        Routes.V2_RESELLER_MESSAGE,
                        Routes.V2_RESELLER_NEW_MESSAGE,
                        Routes.V2_RESELLER_STOCK_MANAGEMENT,
                        Routes.V2_RESELLER_STOCK_MANAGEMENT_POS,
                        Routes.V2_RESELLER_FINALIZE_ORDER,
                    ]}
                        component={ResellerFunnelV2}
                    />

                    <Route
                        exact
                        path={[
                            ...getStepsRoutes(machineRegistrationSteps),
                            Routes.MACHINE_REGISTRATION_ORDER_FINALIZED,
                        ]}
                        component={MachineRegistrationFunnel}
                    />

                    <Route
                        exact path={[
                        Routes.FAST_ORDER,
                        Routes.FAST_ORDER_CUSTOMER,
                    ]}
                        component={FastOrderFunnel}
                    />

                    <Route
                        exact path={[
                        ...shopReopenSteps.map(s => s.route),
                        Routes.SHOP_REOPEN,
                        Routes.SHOP_REOPEN_ORDER_FINALIZED,
                    ]}
                        component={ShopReopenFunnel}
                    />


                    <Route
                        exact path={[
                        ...etradeReopenSteps.map(s => s.route),
                        Routes.ETRADE_REOPEN,
                        Routes.ETRADE_REOPEN_ORDER_FINALIZED,
                    ]}
                        component={EtradeReopenFunnel}
                    />

                    <Route
                        exact
                        path={[
                            ...getStepsRoutes(noMachineShopSteps),
                        ]}
                        component={noMachineShopFunnel}
                    />

                    <Route
                        exact
                        path={[
                            ...getStepsRoutes(subscriptionSteps),
                            Routes.SUBSCRIPTION_CONFIRM_ORDER,
                        ]}
                        component={SubscriptionFunnel}
                    />

                    <Route exact path="/test">
                        <TestScreen/>
                    </Route>
                    <Route exact path="/test2">
                        <TestScreen2/>
                    </Route>
                    {/*<Route exact path="/test/msn">*/}
                    {/*    <SerialNumberTestScreen/>*/}
                    {/*</Route>*/}
                    {/*<Route exact path="/test/pop">*/}
                    {/*    <PopTestScreen/>*/}
                    {/*</Route>*/}
                    {/*<Route exact path="/test/customer">*/}
                    {/*    <CustomerTestScreen/>*/}
                    {/*</Route>*/}
                    <Route path={Routes.LANG_PARAM}>
                        <ErrorScreen>
                            <Error404/>
                        </ErrorScreen>
                    </Route>

                </Switch>
            </Router>


            {Env.ENV !== 'prod' && (
                <>
                    <div style={{position: 'fixed', bottom: 0, right: 0, zIndex: 3000}}>
                        <button type="button" onClick={handleClearStore}>CD</button>
                    </div>

                    <BaseModal visible={timeoutModalVisible} onClose={() => undefined}>
                        <div style={{textAlign: 'center', paddingTop: '4rem', paddingBottom: '4rem'}}>
                            <h1 className="par-1">Page will be reloaded in 3 seconds.</h1>
                            <h3 className="par-2">This modal is not visible in production.</h3>
                        </div>
                    </BaseModal>
                </>
            )}
        </>
    );
}


export default Navigation;
