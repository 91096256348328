import {useTranslation} from "../../hooks";
import {TextInputController} from "../controllers";
import {IoIosSearch} from "react-icons/io";
import React from "react";

export default function SearchSlugInput(){
    const {t} = useTranslation()

    return (
        <TextInputController
            name="slug"
            label={t('table.search')}
            options={{
                renderIcon: () => <IoIosSearch/>,
            }}
        />
    )
}