import React, {useEffect, useMemo} from "react";
import {useFormBuilder, useFormRulesBuilder} from "../hooks";
import {useFormHelper, useNespressoMember, useTranslation} from "../../hooks";
import {RadioController} from "../controllers";
import {FormBuilderComponent} from "../components";
import {useLocalizeObject, usePriceFormatter} from "../../misc/Hooks";
import sanitizeText from "../../helpers/sanitizeText";
import {useWatch} from "react-hook-form";

export default function DeliveryMethodFormFragment({
    prefix,
    deliveryMethods = [],
}) {

    const {t} = useTranslation()
    const localizeObject = useLocalizeObject()
    const {formMethods, setValue} = useFormHelper(prefix)
    const {formatPrice} = usePriceFormatter()
    const {nespressoMember} = useNespressoMember()
    const formRules = useFormRulesBuilder({
        'deliveryMethod': b => b.required(),
    }, formMethods)
    const options = useMemo(() => {
        return deliveryMethods.map(i => ({
            value: i.id_delivery_method,
            label: getLabel(i),
            data: i,
        }))
    }, [JSON.stringify(deliveryMethods)])

    const {components} = useFormBuilder({
        formConfig: [
            ['deliveryMethods'],
        ],
        inputVisibility: {},
        inputComponents: {
            'deliveryMethods': (
                <RadioController
                    name="deliveryMethod"
                    radioOptions={options}
                    rules={formRules.values.deliveryMethod}
                    renderOption={renderOption}
                />
            ),
        },
    })
    const deliveryMethod = useWatch({
        control: formMethods.control,
        name: 'deliveryMethod'
    })

    useEffect(() => {
        if (deliveryMethod && options.find(o => o.value === Number(deliveryMethod))) return
        let deliveryMethodOption = null

        if (nespressoMember?.preferredDeliveryMethod || nespressoMember?.defaultDeliveryMethod) {
            deliveryMethodOption = options
                .find(o => o.value === nespressoMember.preferredDeliveryMethod.find(id => options.find(o => o.value === id)))

            if (!deliveryMethodOption && nespressoMember.defaultDeliveryMethod && Array.isArray(nespressoMember.defaultDeliveryMethod)) {
                deliveryMethodOption = options
                    .find(o => o.value === nespressoMember.defaultDeliveryMethod.find(id => options.find(o => o.value === id)))
            }

            if (deliveryMethodOption)
                formMethods.setValue('deliveryMethod', deliveryMethodOption.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true,
                })
        }

        if (!deliveryMethodOption) {
            const freeDeliveryMethod = options
                .find(d => d?.data?.quotationData?.amount === 0)

            if (freeDeliveryMethod) {
                setValue('deliveryMethod', freeDeliveryMethod.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true,
                })
            }
        }
    }, [deliveryMethod, JSON.stringify(options)])

    function getLabel(item) {
        let price = formatPrice(item.quotationData?.amount)
        if (!item.quotationData?.amount)
            price = t('free')
        return `${localizeObject(item.delivery_method_title) || item.delivery_method_name} <b>(${price})</b>`
    }

    function renderOption(option) {
        return <span {...sanitizeText(option.label)}/>
    }

    return (
        <FormBuilderComponent prefix={prefix} components={components}/>
    )
}
