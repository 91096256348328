import React from "react";
import {PaymentRejected} from "../components/_index";
import {AppProvider} from "../misc/_index";


const PaymentRejectedContainer = (props) => {
    return (
        <AppProvider>
            <PaymentRejected/>
        </AppProvider>
    )
}


export default PaymentRejectedContainer;
