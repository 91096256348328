import React from "react"
import AuthorizationScreen from "../screens/AuthorizationScreen"
import {useSeoPage} from "../../../hooks";

export default function AuthorizationContainer(props) {
    useSeoPage({title: 'finish_order_account.title'})

    return (
        <AuthorizationScreen/>
    )
}
