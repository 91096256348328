import React, {useEffect, useMemo} from "react";
import {useFormBuilder, useFormRulesBuilder} from "../hooks";
import {
    useCountry,
    useFormHelper,
    useModuleContext,
    useNespressoMember,
    useReseller,
    useTranslation
} from "../../hooks";
import {RadioController} from "../controllers";
import {FormBuilderComponent} from "../components";
import {useCart, useLocalizeObject} from "../../misc/Hooks";

export default function PaymentMethodFormFragment({
    prefix,
    paymentMethods = [],
}) {
    const {storeName, store} = useModuleContext()
    const {isLoggedIn} = useReseller()
    const {isAssistedSimplifiedFlowEnabled} = useCountry()
    const {
        cartValue,
        deliveryValue
    } = useCart(storeName);
    const {t} = useTranslation()
    const localizeObject = useLocalizeObject()
    const {formMethods} = useFormHelper(prefix)
    const formRules = useFormRulesBuilder({
        'paymentMethod': b => b.required(),
    }, formMethods)
    const {nespressoMember} = useNespressoMember()
    const options = useMemo(() => {
        return paymentMethods
            ?.filter(p => {
                if (isLoggedIn && isAssistedSimplifiedFlowEnabled() && !store.searchedMember?.hasWebAccount) {
                    return !(p.payment_method_integration_type === 'quotation'
                        && p.payment_method_requires_one_time_token)
                }
                return true
            })
            .map(p => ({
                label: localizeObject(
                    p?.payment_method_basket_null
                    && (Number(cartValue + deliveryValue) <= 0)
                        ? p.payment_method_basket_null.payment_method_title || p.payment_method_basket_null.title
                        : p.payment_method_title
                ),
                value: p.id_payment_method,
                images: p?.payment_method_images ?? []
            }))
    }, [paymentMethods, cartValue, deliveryValue])

    const paymentMethod = formMethods.watch('paymentMethod')

    useEffect(() => {
        if(paymentMethod && options.find(o => o.value === Number(paymentMethod))) return;
        let paymentMethodOption = null;

        if(nespressoMember?.preferredPaymentMethod || nespressoMember?.defaultPaymentMethod){
            paymentMethodOption = options
                .find(o => o.value === nespressoMember.preferredPaymentMethod.find(id => options.find(o => o.value === id)))

            if(!paymentMethodOption && nespressoMember.defaultPaymentMethod){
                paymentMethodOption = options.find(o => o.value === nespressoMember.defaultPaymentMethod)
            }
        }

        if (paymentMethodOption) formMethods.setValue('paymentMethod', paymentMethodOption.value)

    }, [JSON.stringify(options)])

    const renderOption = (option) => {
        if (!option.images.length) return option.label
        return (
            <div
                className={`paymentMethods ${option?.images?.length < 4 ? '-inLine' : ''}`}>
                <b>{option.label}</b>
                <div className={`paymentMethods__images`}>
                    {option.images?.map((image, idx) => (
                        <div key={idx} className="paymentMethods__item">
                            <img src={image.path} alt={image.title}/>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const {components} = useFormBuilder({
        formConfig: [
            ['paymentMethods'],
        ],
        inputVisibility: {},
        inputComponents: {
            'paymentMethods': (
                <RadioController
                    name="paymentMethod"
                    radioOptions={options}
                    rules={formRules.values.paymentMethod}
                    renderOption={renderOption}
                />
            ),
        },
    })

    return (
        <FormBuilderComponent prefix={prefix} components={components}/>
    )
}
