import {Button} from "../../../components/buttons/_index";
import React, {useMemo, useState} from "react";
import {useTranslation} from "../../../hooks";
import {useMutation} from "@tanstack/react-query";
import {useResellerActions} from "../../../actions/useResellerActions";
import {ApiException} from "../../../errors";

//Processed order table button
export default function ProcessedButton({idOrder}) {
    const {t} = useTranslation()

    const resellerActions = useResellerActions()

    const processedMutation = useMutation(async () => {
            const {data} = await resellerActions.processOrder(idOrder)

            if (data.status === 'success') return data
            throw new ApiException(data.messages[0], data)
        }
    )

    const label = useMemo(() => {
        if(processedMutation.isIdle)
            return 'table.process'
        if(processedMutation.isLoading)
            return 'table.processing'
        if(processedMutation.isSuccess)
            return 'table.processed'
        if(processedMutation.isError)
            return 'error_occurred'
    }, [processedMutation.status])


    return (
        <Button
            size="sm"
            label={t(label)}
            disabled={!processedMutation.isIdle}
            isLoading={processedMutation.isLoading}
            onClick={processedMutation.mutate}
            style={{width: '100%'}}
        />
    )
}