import React from 'react';
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import {useLanguages} from "../hooks";
import {getLanguageLabel} from "../utils/MarketConfig";


export default (props: Props) => {
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const languages = useLanguages();

    const isLangActive = (lang) => {
        if(!match.params.lang && lang === languages.defaultLang) {
            return true;
        }

        return match.params.lang === lang;
    }


    const handleClick = (lang) => {
        let path = location.pathname;
        if(path.match(/^\/[a-zA-Z]{2}(\/|$)/g)) {
            path = path.replace(/^\/[a-zA-Z]{2}(\/|$)/g, lang !== languages.defaultLang ? `/${lang}/` : '/');
        }
        else {
            path = `${lang !== languages.defaultLang ? `/${lang}` : ''}${path}`;
        }
        history.push({pathname: path, search: location.search});
        window.location.reload();
    }

    return (
        <ul className="langNav">
            {/*{store.country?.country_languages?.map(l => (*/}
            {/*    <li key={l.id_lang} className={isLangActive(l.lang_code) ? '-active' : ''}>*/}
            {/*        <button type="button" onClick={() => handleClick(l.lang_code)}>{l.lang_code}</button>*/}
            {/*    </li>*/}
            {/*))}*/}
            {languages.languages.map((lang, key) => (
                <li key={key} className={isLangActive(lang) ? '-active' : ''}>
                    <button type="button" onClick={() => handleClick(lang)}>{getLanguageLabel(lang)}</button>
                </li>
            ))}
        </ul>
    );
}
