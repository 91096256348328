const resellerRoles = {
    undeclared: null,
    kaSuperUser: "ka_super_user",
    posSuperUser: "pos_super_user",
    posUser: "pos_user",
    ncaSuperUser: "nca_super_user",
    ncaUser: "nca_user"
}

const permissions = {
    assistedRegistration: 'assistedRegistration',
    fastOrder: 'fastOrder',
    machineSubscription: 'machineSubscription',
    adminAndReports: 'adminAndReports',
    userManagement: 'userManagement',
    reporting: 'reporting',
    noMachineShop: 'noMachineShop',
    burn: 'burn',
    ax: 'ax',
    messages: 'messages',
    posStockManagement: 'posStockManagement',
    posStockManagementEdit: 'posStockManagementEdit'
}

const rolePermissions = {
    undeclared: [
        permissions.assistedRegistration,
        permissions.fastOrder,
        permissions.machineSubscription,
        permissions.noMachineShop,
        permissions.ax,
        permissions.messages,
        permissions.posStockManagement,
    ],
    kaSuperUser: [
        permissions.assistedRegistration,
        permissions.fastOrder,
        permissions.machineSubscription,
        permissions.adminAndReports,
        permissions.userManagement,
        permissions.reporting,
        permissions.noMachineShop,
        permissions.ax,
        permissions.messages,
        permissions.posStockManagement,
        permissions.posStockManagementEdit,
    ],
    posSuperUser: [
        permissions.assistedRegistration,
        permissions.fastOrder,
        permissions.machineSubscription,
        permissions.adminAndReports,
        permissions.userManagement,
        permissions.reporting,
        permissions.noMachineShop,
        permissions.ax,
        permissions.messages,
        permissions.posStockManagement,
        permissions.posStockManagementEdit,
    ],
    posUser: [
        permissions.assistedRegistration,
        permissions.fastOrder,
        permissions.machineSubscription,
        permissions.adminAndReports,
        permissions.reporting,
        permissions.noMachineShop,
        permissions.ax,
        permissions.messages,
        permissions.posStockManagement,
    ],
    ncaSuperUser: [
        permissions.assistedRegistration,
        permissions.fastOrder,
        permissions.machineSubscription,
        permissions.adminAndReports,
        permissions.userManagement,
        permissions.reporting,
        permissions.noMachineShop,
        permissions.burn,
        permissions.ax,
        permissions.messages,
        permissions.posStockManagement,
        permissions.posStockManagementEdit,
    ],
    ncaUser: [
        permissions.assistedRegistration,
        permissions.fastOrder,
        permissions.machineSubscription,
        permissions.adminAndReports,
        permissions.reporting,
        permissions.noMachineShop,
        permissions.burn,
        permissions.ax,
        permissions.messages,
        permissions.posStockManagement,
    ]
}

export {
    resellerRoles,
    permissions,
    rolePermissions,
}