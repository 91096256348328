export default function FilterCategory({
    label,
    image,
    bgImage,
    isSelected,
    onClick
}) {
    return (
        <button className={`filterCategory ${!!isSelected && '-selected'}`} onClick={onClick}>
            <span
                className="filterCategory__thumb"
                style={{backgroundImage: `url(${bgImage})`}}
            >
                {!!image && <img src={image} alt={label}/>}
            </span>
            <h3 className="filterCategory__title" dangerouslySetInnerHTML={{__html: label}}/>
        </button>
    )
}
