import {SelectController} from "../controllers";
import React, {useMemo} from "react";
import {useStackOptions, useTranslation} from "../../hooks";

export default function TaxCategorySelect(props) {
    const {t} = useTranslation()
    const {options} = useStackOptions('taxCategories')
    const defaultValue = useMemo(() => {
        if (options.length > 1)
            return null
        return options[0]
    }, [options])

    return (
        <SelectController
            name="taxCategory"
            label={t('tax_category')}
            selectOptions={options}
            defaultValue={defaultValue}
            {...props}
        />
    )
}
