import React from "react";
import {useCountryImages, useTranslation} from "../../../hooks";
import {ResellerForgotPasswordForm} from "../../../forms";
import {useResellerResetPassword} from "../hooks";
import {InfoComponent} from "../../../components/_index";
import {COUNTRY_IMAGE_TYPE} from "../../../hooks/useCountryImages";
import {CountryAuthImage} from "../components";


export default function ResellerForgotPasswordScreen(){
    const {t} = useTranslation();

    const resellerResetPassword = useResellerResetPassword();

    return (
            <div className="resAuth">
                <div className="resAuth__content">
                    <h1 className="header-1">{t('forgot_password_reseller.title')}</h1>
                    <h2 className="par-2 --mb3">{t('forgot_password_reseller.text')}</h2>
                    <ResellerForgotPasswordForm
                        onSubmit={resellerResetPassword.emailMutation.mutate}
                        state={{
                            isLoading: resellerResetPassword.isLoading,
                            error: resellerResetPassword.error
                        }}
                    />
                    <InfoComponent type={resellerResetPassword.message.type} message={t(resellerResetPassword.message.message)}/>
                </div>
                <div className="resAuth__thumb">
                    <CountryAuthImage/>
                </div>
            </div>
    )
}