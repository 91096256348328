import {useTranslation} from "../misc/Hooks";
import {useEffect, useRef, useState} from "react";
import {useInView} from "react-intersection-observer";
import useResizeObserver from "@react-hook/resize-observer";
import {useSelector} from "react-redux";


export default function HeaderStep({index, step, currentStepInView, ...props}) {
    const ref = useRef()
    const t = useTranslation()
    const store = useSelector(({shop}) => ({
        cartVisible: shop.cartVisible,
    }))
    const [offset, setOffset] = useState(0)
    const [headerStepOffset, setHeaderStepOffset] = useState(0)

    useEffect(() => {
        handleElementOffset()
    }, [ref?.current])

    useResizeObserver(document.body, (entry) => handleElementOffset())

    useResizeObserver(document.querySelector('.main .formContainer'), entry => {
        const wrapper = entry.target.querySelector('.wrapper')
        if(wrapper) {
            setHeaderStepOffset(wrapper.getBoundingClientRect().left)
        }
    })

    const handleElementOffset = () => {
        const element = ref?.current
        if(element && store.cartVisible) {
            setOffset(element.offsetLeft)
        }
    }

    return !!(step && currentStepInView) && (
        <div
            ref={ref}
            className="headerStep"
            style={{
                transition: '200ms ease-in-out',
                transform: `translateX(-${store.cartVisible && document.body.offsetWidth <= 104 * 16 ? (headerStepOffset > 0 ? 11 * 16 : offset - (16 * 0.75)) : 0}px)`,
            }}
        >
            <div className="headerStep__circle">{index + 1}</div>
            <span dangerouslySetInnerHTML={{__html: t(step.title)}}/>
        </div>
    )
}
