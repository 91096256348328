import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {useNavigation} from "../misc/Hooks";


export default function useStepsNavigation(steps) {
    const navigation = useNavigation();
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory()

    const arr = location.pathname.split('/');
    const route = arr[arr.length - 1] ?? '';
    const currentStepIndex = steps.findIndex(s => Array.isArray(s.route) ? s.route.includes(match.path) : s.route === match.path);

    const prev = () => {
        const prev = steps[currentStepIndex - 1];
        if (prev) {
            return navigation(prev.route);
        }
        return navigation(steps[currentStepIndex].route);
    }

    const next = () => {
        const next = steps[currentStepIndex + 1];
        if (next) {
            return navigation(next.route);
        }
        return navigation(steps[currentStepIndex].route);
    }

    const hasRoute = (route) => {
        return !!steps.find(s => s.route === route)
    }

    const navToNext = () => {
        history.push(next())
    }

    const navToPrev = () => {
        history.push(next())
    }

    return {
        steps,
        hasRoute,
        prev,
        next,
        navToPrev,
        navToNext,
    }
}
