import {useTranslation} from "../../../hooks";


export default function BottomSliderItem({onClick, background, image, title, active, requiredCategory}){
    const {t} = useTranslation()
    return (
        <div className={`productsSliderItem ${active ? '-active' : ''}`} onClick={onClick}>
            <div className="productsSliderItem__thumb">
                <img src={background}
                     className="productsSliderItem__background" alt=""/>
                <img src={image} className="productsSliderItem__cover" alt=""/>
            </div>
            <span className="productsSliderItem__title" data-tip={!!requiredCategory ? t('required_category') : ''}>
                {title}
                {!!requiredCategory && <span className="requiredCategory">*</span>}
            </span>

        </div>
    )
}