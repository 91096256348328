import {Routes} from "./_index";
import * as ETRADE from "../modules/etrade/containers"
import * as ETRADE_REOPEN from "../modules/etrade_reopen/containers"
import * as STAY_HOME from "../modules/stay_home/containers"
import * as ORDER_FINISH from "../modules/order_finish/containers"
import * as MACHINE_REGISTRATION from "../modules/machine_registration/screens"
import * as SHOP_REOPEN from "../modules/shop_reopen/containers"
import * as NO_MACHINE_SHOP from "../modules/no_machine_shop/containers"
import * as SUBSCRIPTION from "../modules/subscription/containers"
import Env from "./Env";
import {getSessionTime} from "./MarketConfig";

export default {
    VERSION: '23.1.0.0',
    EV_SESSION_TIME: getSessionTime('machineRegistration'),
    SUBSCRIPTION_SESSION_TIME: getSessionTime('machineSubscription'),
    ORDER_FINISH_SESSION_TIME: getSessionTime('orderFinish'),
    RESELLER_SESSION_TIME: getSessionTime('reseller'),
    ETRADE_SESSION_TIME: getSessionTime('etrade'),
    STAY_HOME_SESSION_TIME: getSessionTime('stayHome'),
    RESELLER_MACHINE_SUBSCRIPTION_SESSION_TIME: getSessionTime('resellerMachineSubscription'),
    FILE_SIZE_MB: 8,
    ORDER_FINISH_STEPS: [
        {
            route: Routes.ORDER_FINISH_AUTHORIZATION,
            title: 'finish_order_account.title',
            component: <ORDER_FINISH.AuthorizationContainer/>
        },
        {
            route: Routes.ORDER_FINISH_CUSTOMER,
            title: 'finish_order_customer.title',
            component: <ORDER_FINISH.CustomerContainer/>
        },
        {
            route: Routes.ORDER_FINISH_SUMMARY,
            title: 'finish_order_summary.title',
            component: <ORDER_FINISH.SummaryContainer/>
        },
        // {
        //     route: Routes.ORDER_FINISH_PAYMENT,
        //     title: 'finish_order_payment.title',
        //     component: <ORDER_FINISH.PaymentContainer/>
        // },
    ],
    STAY_HOME_STEPS: [
        {
            route: Routes.STAY_HOME,
            title: 'stay_home.identification.title',
            component: <STAY_HOME.IdentificationContainer/>
        },
        {
            route: Routes.STAY_HOME_AUTHORIZATION,
            title: 'stay_home.authorization.title',
            component: <STAY_HOME.AuthorizationContainer/>
        },
        {
            route: Routes.STAY_HOME_PLAN_SELECTION,
            title: 'stay_home.plan_selection.title',
            component: <STAY_HOME.PlanSelectionController/>
        },
        {
            route: Routes.STAY_HOME_CUSTOMER,
            title: 'stay_home.customer.title',
            component: <STAY_HOME.CustomerContainer/>
        },
        {
            route: Routes.STAY_HOME_SUMMARY,
            title: 'stay_home.summary.title',
            component: <STAY_HOME.SummaryContainer/>
        },
    ],
    ETRADE_STEPS: [
        {
            route: Routes.ETRADE_MACHINE_PLAN,
            title: 'etrade.machine_plan.title',
            component: <ETRADE.MachinePlanContainer/>
        },
        {
            route: Routes.ETRADE_AUTHORIZATION,
            title: 'etrade.authorization.title',
            component: <ETRADE.AuthorizationContainer/>
        },
        {
            route: Routes.ETRADE_CUSTOMER,
            title: 'etrade.customer.title',
            component: <ETRADE.CustomerContainer/>
        },
        {
            route: Routes.ETRADE_SUMMARY,
            title: 'etrade.summary.title',
            component: <ETRADE.SummaryContainer/>
        },
    ],
    ETRADE_REOPEN_STEPS: [
        {
            route: Routes.ETRADE_REOPEN_AUTHORIZATION,
            title: 'etrade.authorization.title',
            component: <ETRADE_REOPEN.AuthorizationContainer/>
        },
        {
            route: Routes.ETRADE_REOPEN_MACHINE_PLAN,
            title: 'etrade.machine_plan.title',
            component: <ETRADE_REOPEN.MachinePlanContainer/>
        },
        {
            route: Routes.ETRADE_REOPEN_CUSTOMER,
            title: 'etrade.customer.title',
            component: <ETRADE_REOPEN.CustomerContainer/>
        },
        {
            route: Routes.ETRADE_REOPEN_SUMMARY,
            title: 'etrade.summary.title',
            component: <ETRADE_REOPEN.SummaryContainer/>
        },
    ],
    MACHINE_REGISTRATION_STEPS: [
        {
            route: Routes.MACHINE_REGISTRATION_REGISTRATION,
            title: 'shop_machine_registration.title',
            component: <MACHINE_REGISTRATION.MachineRegistrationScreen/>
        },
        {
            route: Routes.MACHINE_REGISTRATION_OFFER_SELECTION,
            title: 'shop_offer_selection.title',
            component: <MACHINE_REGISTRATION.OfferSelectionScreen/>
        },
        {
            route: Routes.MACHINE_REGISTRATION_CUSTOMER_INFORMATION,
            title: 'shop_customer_information.title',
            component: <MACHINE_REGISTRATION.CustomerScreen/>
        },
        {
            route: Routes.MACHINE_REGISTRATION_SUMMARY,
            title: 'shop_summary.title',
            component: <MACHINE_REGISTRATION.SummaryScreen/>
        },
    ],
    SHOP_REOPEN_STEPS: [
        {
            route: Routes.SHOP_REOPEN_AUTHORIZATION,
            title: 'shop_reopen.authorization.title',
            component: <SHOP_REOPEN.AuthorizationStepContainer/>
        },
        {
            route: Routes.SHOP_REOPEN_MACHINE_REGISTRATION,
            title: 'shop_reopen.machine_registration.title',
            component: <SHOP_REOPEN.MachineRegistrationStepContainer/>
        },
        {
            route: Routes.SHOP_REOPEN_OFFER_SELECTION,
            title: 'shop_reopen.offer_selection.title',
            component: <SHOP_REOPEN.OfferSelectionStepContainer/>
        },
        {
            route: Routes.SHOP_REOPEN_CUSTOMER_INFORMATION,
            title: 'shop_reopen.customer_information.title',
            component: <SHOP_REOPEN.CustomerInformationStepContainer/>
        },
        {
            route: Routes.SHOP_REOPEN_SUMMARY,
            title: 'shop_reopen.summary.title',
            component: <SHOP_REOPEN.SummaryStepContainer/>
        },
    ],
    NO_MACHINE_SHOP_STEPS: [
        {
            route: Routes.NO_MACHINE_SHOP,
            title: 'no_machine_shop.identification.title',
            component: <NO_MACHINE_SHOP.CustomerIdentificationContainer/>
        },
        {
            route: Routes.NO_MACHINE_SHOP_ORDER,
            title: 'no_machine_shop.order.title',
            component: <NO_MACHINE_SHOP.OfferSelectionStepContainer/>
        },
        {
            route: Routes.NO_MACHINE_SHOP_CUSTOMER,
            title: 'no_machine_shop.customer.title',
            component: <NO_MACHINE_SHOP.CustomerInformationStepContainer/>
        },
        {
            route: Routes.NO_MACHINE_SHOP_SUMMARY,
            title: 'no_machine_shop.summary.title',
            component: <NO_MACHINE_SHOP.SummaryStepContainer/>
        },
    ],
    SUBSCRIPTION_STEPS: [
        {
            route: [Routes.SUBSCRIPTION, Routes.SUBSCRIPTION_MACHINE],
            title: 'r_ms_machine.title',
            component: <SUBSCRIPTION.MachineContainer/>
        },
        {
            route: Routes.SUBSCRIPTION_PLAN,
            title: 'r_ms_plan.title',
            component: <SUBSCRIPTION.PlanSelectionContainer/>
        },
        {
            route: Routes.SUBSCRIPTION_CUSTOMER,
            title: 'r_ms_customer.title',
            component: <SUBSCRIPTION.CustomerContainer/>
        },
        {
            route: Routes.SUBSCRIPTION_SUMMARY,
            title: 'r_ms_summary.title',
            component: <SUBSCRIPTION.SummaryContainer/>
        },
    ],
    GALLERY_TYPE: {
        COVER: 'cover',
        BACKGROUND: 'background',
    },
    COFFEE_TYPE: {
        CAPSULE: 'capsule',
        PACKAGE: 'package',
        GIFT: 'gift',
        PLAN_MACHINE: 'plan_machine',
        PLAN_COFFEE: 'plan_coffee',
        PLAN_WORK_HOME: 'plan_work_home',
        DELIVERY: 'delivery',
        REBATE: 'rebate',
        EASY_ORDER_GIFT: 'easy_order_gift',
        ACCESSORY: 'accessory',
        EXTRA_LINE: 'extra_line',
    },
    PROMO_TYPE: {
        REGISTER_MACHINE: 'register_machine',
        SUBSCRIPTION_COFFEE: 'subscription_coffee',
        SUBSCRIPTION_MACHINE: 'subscription_machine',
        EASY_ORDER: 'easy_order',
        STAY_HOME: 'stay_home',
    },
    FILTER: {
        INTENSITY: ['1-4', '5-8', '9-13'],
    },
    TITLES: [
        {label: 'person_title.mr', value: 'mr'},
        {label: 'person_title.mrs', value: 'mrs'},
    ],
    RESELLER_COUNTRY: Env.RESELLER_COUNTRY,
    SUBSCRIPTION_STATUS: {
        READY: 'ready',
        PENDING: 'pending',
        // PROCESSED: 'processed',
        PROCESSED: 'ready',
        REJECTED: 'rejected',
    },
    STORE_ACTION: {
        EV_CLIENT: 'ev_client',
        EV_RESELLER: 'ev_reseller',
        FINISH_ORDER: 'finish_order',
        RESELLER_MACHINE_SUBSCRIPTION: 'reseller_machine_subscription',
        STAY_HOME: 'stay_home',
    },
    RECAPTCHA_DEV: '6LeHdckUAAAAADYG64VxOlGN3EQWCLCA0Ato986l',
    RTL_LANGUAGES: ['he'],
    POI_TYPE: {
        BOUTIQUE: 'Boutique',
        PICKUP_POINT: 'PickUpPoint',
    },
}
