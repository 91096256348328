import {Routes} from "../../../utils/_index";
import {useCart, useNavigation} from "../../../misc/Hooks";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {isEnabledByMarket} from "../../../utils/MarketConfig";


/**
 * Validates Stay Home module route / step
 * @param route
 * @type route: string
 * @returns {{lastValidRoute: (*), value: boolean}}
 */
export default function useStepValidation(route) {
    const navigation = useNavigation()
    const location = useLocation()
    const store = useSelector(({stayHome}) => ({
        token: stayHome.token,
        data: stayHome.data,
        nespressoMember: stayHome.nespressoMember,
        userHasPlan: stayHome.userHasPlan,
        customer: stayHome.customer,
        order: stayHome.order,
        isNewMember: stayHome.isNewMember,
    }))
    const {isCartValid} = useCart('stayHome')

    const response = {
        canShowStep: true,
        lastValidStep: Routes.STAY_HOME,
    }

    switch (route) {
        case Routes.STAY_HOME_SUMMARY:
            const orderUuid = new URLSearchParams(location?.search).get('uuid')
            if(orderUuid) {
                break
            }
            if(!store.customer) {
                response.canShowStep = false
                response.lastValidStep = Routes.STAY_HOME_CUSTOMER
            }
        case Routes.STAY_HOME_CUSTOMER:
            if(!isCartValid) {
                response.canShowStep = false
                response.lastValidStep = Routes.STAY_HOME_PLAN_SELECTION
            }
        case Routes.STAY_HOME_PLAN_SELECTION:
            if(!(isEnabledByMarket('disableEcapi') || (store.isNewMember === false ? store.nespressoMember : typeof store.isNewMember === 'boolean'))) {
                response.canShowStep = false
                response.lastValidStep = Routes.STAY_HOME_AUTHORIZATION
            }
        case Routes.STAY_HOME_AUTHORIZATION:
            if(!store.token || !store.data) {
                response.canShowStep = false
                response.lastValidStep = Routes.STAY_HOME
            }
        case Routes.STAY_HOME:
    }


    return {
        value: response.canShowStep,
        lastValidRoute: navigation(response.lastValidStep),
    }
}
