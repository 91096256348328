import {useTableBuilder} from "../../../components/baseTable";
import {isoDateConverter, isoDateConverterWithTime} from "../../../misc/Helpers";
import {useResellerActions} from "../../../actions/useResellerActions";
import {useMarketConfig} from "../../../misc/Hooks";
import {useTranslation} from "../../../hooks";
import React from "react";
import SubscriptionStatusLabel from "../../../components/SubscriptionStatusLabel";
import {isEnabledByMarket} from "../../../utils/MarketConfig";
import PaymentStatusLabel from "../../../components/PaymentStatusLabel";

export default function useMachineSubscriptionOrderTable({
    payload,
    daysLimit = false,
    prefix,
    hiddenColumns,
    columns,
    params,
}) {
    const resellerActions = useResellerActions();
    const marketConfig = useMarketConfig();
    const {t} = useTranslation()

    const assistedTable = useTableBuilder({
        name: `assisted_machine_subscription_${payload.status_type}`,
        action: async ({dateFrom: dateFromFilter, dateTo: dateToFilter, ...params}) => {
            let dateFrom = null
            let dateTo = null
            if ((!!marketConfig.reseller_subscription_show_time) && daysLimit)
                dateFrom = new Date(new Date().getTime() - (Number(marketConfig.reseller_subscription_show_time) * 24 * 60 * 60 * 1000))

            if (params?.hideOrders === 'true' && isEnabledByMarket('hideTodayMachineSubscriptionOrders'))
                dateTo = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

            const actionPayload = {
                sort: null,
                order_type: [],
                date_from: dateFromFilter ?? isoDateConverter(dateFrom, '-'),
                date_to: dateToFilter ?? isoDateConverter(dateTo, '-'),
                id_shop: [],
                status: [],
                id_reseller: [],
                status_type: '',
                ...payload
            }
            return resellerActions.getOrdersV2(actionPayload, params)
        },
        columns: [
            {
                name: 'id_order',
                label: 'assisted_registration.id'
            },
            {
                name: 'promotion_type',
                label: 'assisted_registration.promotion_type.title',
                cell: ({value}) => <span>{t(`assisted_registration.promotion_type.${value}`) ?? value}</span>
            },
            {
                name: 'tracking_code',
                label: 'assisted_registration.tracking_code',
            },
            {
                name: 'subscription_status',
                label: 'assisted_registration.status_of_registration',
                cell: ({value}) => <SubscriptionStatusLabel status={value}/>
            },
            {
                name: 'payment_status',
                label: 'assisted_registration.status_of_payment',
                cell: ({value}) => <PaymentStatusLabel status={value}/>
            },
            {
                name: 'date',
                label: 'assisted_registration.date',
                cell: ({row: {original}}) => isoDateConverterWithTime(original.created_at, '-', ':')
            },
            {
                name: 'customer_name',
                label: 'assisted_registration.customer_name',
                cell: ({row: {original}}) => (`${original.delivery_firstname ?? ''} ${original.delivery_lastname ?? ''}`)
            },
            {
                name: 'delivery_email',
                label: 'assisted_registration.customer_email'
            },
            {
                name: 'machine_coffee_type',
                label: 'assisted_registration.type_of_machine'
            },
            {
                name: 'serial',
                label: 'assisted_registration.serial_number'
            },
            ...columns,
        ],
        config: {
            searchParamPrefix: prefix,
            hiddenColumns: hiddenColumns,
            autoRefetch: true,
            autoRefetchTimeout: 20000,
        },
        params: {...params}

    })

    return {...assistedTable}
}