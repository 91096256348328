import React from "react";
import {useFetchCategories, usePromotions, useReseller, useSeoPage} from "../../../hooks";
import {Routes} from "../../../utils/_index";
import * as orderActions from "../../../actions/OrderActions";
import {OrderSummary} from "../../../components/Summary";
import {usePromotionsContext} from "../../../hooks/useFetchPromotions";

export default function SummaryScreen({assistedRedirectTo}) {
    useSeoPage({title: 'shop_summary.title'})
    const {isLoggedIn} = useReseller()
    const fetchCategories = useFetchCategories()
    const {promotion} = usePromotionsContext()

    const payloadFn = ({orderData, paymentMethod, deliveryMethod, pickupPoint}) => {
        return {
            customer: {
                filename_machine: null,
                ...orderData.getData(),
                ...orderData.getPaymentMethodData(paymentMethod),
                ...orderData.getDeliveryMethodData(deliveryMethod, pickupPoint),
            },
            cart: orderData.getCart(),
            ...orderData.getCartQuotationData(),
        }
    }

    const action = (payload, params, customData) => {
        if (isLoggedIn && customData.isAssistedExistingSimplifiedFlow) {
            return orderActions.updateOrder(payload.customer.uuid, payload)
        } else if (isLoggedIn) {
            return orderActions.finalizeReseller(payload, params)
        }
        return orderActions.finalize(payload, params)
    }

    const resellerAction = (payload, params, customData) => {
        if (isLoggedIn && customData.isAssistedExistingSimplifiedFlow) {
            return orderActions.finalizeReseller(payload, params)
        }
        console.error('Unhandled exception!')
        return null
    }

    return (
        <OrderSummary
            homeRoute={Routes.MACHINE_REGISTRATION_REGISTRATION}
            orderFinalizedRoute={Routes.MACHINE_REGISTRATION_ORDER_FINALIZED}
            assistedRedirectTo={assistedRedirectTo || Routes.V2_RESELLER_ASSISTED_REGISTRATION}
            action={action}
            resellerAction={resellerAction}
            payloadFn={payloadFn}
            state={{
                isLoading: fetchCategories.query.isLoading,
                isFetching: fetchCategories.query.isFetching,
            }}
            deliveryMethodEnabled={!!promotion?.promotion_use_delivery_modes}
        />
    )
}
