import {InputLabel, InvalidFeedback} from "../components";
import {useInputValidationClassNames} from "../hooks";
import InputSpinner from "../components/InputSpinner";

/**
 * TextInput
 * @param type
 * @param inputRef
 * @param fieldState
 * @param name
 * @param label
 * @param value
 * @param {{inputProps, isRequired: boolean, isLoading: boolean, maxLength: number, renderIcon}} options
 * @param onChange
 * @param onBlur
 * @returns {JSX.Element}
 * @constructor
 */
export default function TextInput({
    type = 'text',
    inputRef,
    fieldState,
    name,
    label,
    value,
    options,
    onChange,
    onBlur,
}) {
    options = {...defaultOptions, ...options}
    const {className} = useInputValidationClassNames({
        value,
        fieldState,
        params: {
            '-icons': !!options.renderIcon || options.isLoading,
        },
        deps: [options.renderIcon, options.isLoading],
    })

    return (
        <fieldset className={className}>
            <div className="fieldset__wrapper">
                <input
                    className="fieldset__input"
                    ref={inputRef}
                    id={name}
                    type={type}
                    name={name}
                    placeholder={label}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    {...options.inputProps}
                />
                {!!(options.renderIcon || options.isLoading) && (
                    <div className="fieldset__icons">
                        {options.isLoading ? (
                            <InputSpinner/>
                        ) : options.renderIcon()}
                    </div>
                )}
                <InputLabel
                    name={name}
                    value={value}
                    label={label}
                    options={options}
                />
            </div>
            <InvalidFeedback error={fieldState.error}/>
        </fieldset>
    )
}

const defaultOptions = {
    inputProps: null,
    isRequired: false,
    isLoading: false,
    maxLength: 0,
    renderIcon: null,
}
