import {useDispatch, useSelector} from "react-redux";
import {Redirect, useRouteMatch} from "react-router-dom";
import {Routes} from "../../utils/_index";
import React, {useEffect} from "react";
import {useNavigation} from "../../misc/Hooks";
import * as noMachineShopReducer from "../../redux/noMachineShopReducer";
import * as categoryActions from "../../actions/CategoryActions";
import {checkAutoAdd} from "../../misc/Helpers";
import {useResellerActions} from "../../actions/useResellerActions";
import {useLanguage} from "../../hooks";
import Axios from "../../utils/Axios";
import mapToOldCategoriesStructure from "../../helpers/mapToOldCategoriesStructure";

let MOUNTED = false;

export default function Provider(props) {
    const navigation = useNavigation();

    const match = useRouteMatch();
    const resellerActions = useResellerActions()
    const currentLanguage = useLanguage();
    const dispatch = useDispatch();
    const store = useSelector(({main, noMachineShop}) => ({
        user: main.user,
        marketConfig: main.marketConfig,
        country: main.country,
        coffeeTypes: main.coffeeTypes,
        coffeeSizes: main.coffeeSizes,
        aromaticProfiles: main.aromaticProfiles,
        civilities: main.civilities,
        promotion: noMachineShop.promotion,
        cart: noMachineShop.cart,
        uuid: noMachineShop.uuid,
        coffeeLines: noMachineShop.coffeeLines,
        categories: noMachineShop.categories,
        products: noMachineShop.products,
        nespressoMember: noMachineShop.nespressoMember,
        customer: noMachineShop.customer,
        cookiesAccepted: noMachineShop.cookiesAccepted,
    }))

    useEffect(() => {
        Axios.defaults.headers.common['Language'] = currentLanguage;

        if (!store.promotion) {
            dispatch(noMachineShopReducer.updateCart({items: [], sum: ''}))
        }
    }, [store.categories])


    useEffect(() => {
        if (store.promotion) {
            getCategories();
        }
    }, [match.params.lang, store.promotion])


    useEffect(async () => {
        MOUNTED = true;

        return () => {
            MOUNTED = false;
        }
    }, [])

    const getCategories = () => {
        const hasAccount = store.nespressoMember !== false
        dispatch(noMachineShopReducer.setLoadingCategories(true));

        if (store.user) {
            resellerActions.getCategories(
                store.promotion?.id_promotion,
                store.machine?.id_machine ?? 0,
                store.proofOfPurchase?.retailerStore?.value ?? 0,
                hasAccount ? 1 : 0,
            ).then(handleSuccess).catch(err => {
                console.error('Error: ', err)
            })
        } else {
            let params = {}
            if (store.nespressoMember?.member_id) {
                params.member_number = store.nespressoMember?.member_id;
            }

            categoryActions.getAll(
                store.promotion?.id_promotion,
                store.machine?.id_machine ?? 0,
                store.proofOfPurchase?.retailerStore?.value ?? 0,
                hasAccount ? 1 : 0,
                params
            ).then(handleSuccess).catch(err => {
                console.error('Error: ', err)
            })
        }

        async function handleSuccess(r) {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    const {categories, products} = mapToOldCategoriesStructure(res.data)

                    dispatch(noMachineShopReducer.setProducts(Object.values(products)));
                    dispatch(noMachineShopReducer.setCategories(categories));
                    dispatch(noMachineShopReducer.updateCart(checkAutoAdd(
                        categories,
                        Object.values(products),
                        store.cart,
                        null,
                        store.proofOfPurchase?.machinePrice
                    )))
                }
            } else {
                console.warn('Something gone wrong! ', res);
            }
        }
    }

    return (
        <>
            {store.user ? props.children : <Redirect to={navigation(Routes.V2_RESELLER_AUTH)}/>}
        </>
    )
}
