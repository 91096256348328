import {useMutation} from "@tanstack/react-query";
import {getEtradeTYP, getThankYouPage} from "../actions/MainActions";
import {ApiException} from "../errors";
import {GTM} from "../misc/_index";
import {useEffect, useMemo, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useGTM, useSearchParams} from "./index";
import AdyenCheckout from "@adyen/adyen-web";
import {useDispatch, useSelector} from "react-redux";
import * as paymentReducer from "../redux/paymentReducer";

export default function useOrderFinalized(storeName) {
    const params = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const [gitModalVisible, setGitModalVisible] = useState(false)
    const {completeDeliveryStep, gtmLogMutation} = useGTM()
    const searchParams = useSearchParams()
    const sessionId = useMemo(() => searchParams.get('sessionId'), [searchParams.params])
    const paymentSession = useSelector(({payment}) => payment.paymentSession)

    const token = params.token ?? params.uuid

    const typMutation = useMutation(async () => {
            const {data} = (storeName === 'eTrade' || storeName === 'eTradeReopen')
                ? await getEtradeTYP(token)
                : await getThankYouPage(token)

            if (data.status === 'success') return data
            throw new ApiException(data.messages[0], data)
        },
        {
            onSuccess: ({data}) => {
               const res = completeDeliveryStep(data?.promotion_type)
                gtmLogMutation.mutate({
                    body: JSON.stringify(res),
                    uuid: token,
                    place: 'TYP',
                    type: 'Redemption Process Completed'
                })

            },
            onError: () => {}
        })


    // useEffect(() => {
    //     typMutation.mutate()
    // }, [])

    useEffect(() => {
        const redirectResult = searchParams.get('redirectResult')
        // const successCallbackRoute = searchParams.get('successCallbackRoute')

        const createCheckout = async () => {
            const checkout = await AdyenCheckout({
                environment: paymentSession.environment, // Change to one of the environment values specified in step 4.
                clientKey: paymentSession.clientKey, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
                analytics: {
                    enabled: true // Set to false to not send analytics data to Adyen.
                },
                session: { id: sessionId },
                onPaymentCompleted: (response, component) => {
                    // history.replace(successCallbackRoute)
                    dispatch(paymentReducer.clearStore())
                    history.replace({search: null})
                },
                onError: (error, _component) => {
                    console.error(error);
                    // setError(error)
                    // navigate(`/status/error?reason=${error.message}`, { replace: true });
                },
            });
            checkout.submitDetails({ details: { redirectResult } }); // we finalize the redirect flow with the reeived payload
        };

        if(sessionId && paymentSession) {
            createCheckout();
        } else {
            typMutation.mutate()
        }
    }, [searchParams.params, paymentSession])

    return {
        typMutation,
        gitModalVisible,
        setGitModalVisible,
        params,
        sessionId,
    }
}
