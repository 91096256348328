import React from "react";
import {FormProvider} from "react-hook-form";
import {Fragment, useEffect} from "react";
import {FormRulesContext} from "./misc";
import {getDeepValue} from "../helpers";

/**
 * Form
 * @param formMethods
 * @param formRules
 * @param {{triggerOnChange: []}} options
 * @param state
 * @param onSubmit
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function Form({
    formMethods,
    formRules,
    options = {
        triggerOnChange: [],
    },
    state,
    onSubmit,
    children,
}) {
    state = {
        isLoading: false,
        isDisabled: false,
        isError: false,
        error: null,
        ...state,
    }
    const {watch, trigger} = formMethods

    useEffect(() => {
        const subscription = watch((data, {name, type}) => {
            options.triggerOnChange?.forEach(i => {
                if(name === i.target && (getDeepValue(data, i.trigger) || i.config?.includeEmpty)) {
                    trigger(i.trigger)
                }
            })
        })

        return () => subscription.unsubscribe()
    }, [watch])

    return (
        <FormProvider {...formMethods} state={state}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <FormRulesContext.Provider value={formRules?.values}>
                    {React.Children.map(children, child => {
                        if (typeof child === 'function')
                            return <Fragment>{child({...formMethods})}</Fragment>
                        return child
                    })}
                </FormRulesContext.Provider>
            </form>
        </FormProvider>
    )
}
