import {useTranslation} from "../../../../../hooks";
import React, {useMemo} from "react";
import Badge from "../../../../../components/Badge";

export default function LocalStockRequestStatusLabel({status}){
    const {t} = useTranslation()
    const className = useMemo(() => {
        switch (status) {
            case 0:
                return '-success';
            case -1:
                return '-error';
            case 1:
                return '-success -outline';
        }
    }, [status])

    return (
        <Badge className={className} label={t(`stock_management.status.${status}`)}/>
    )
}