import {useFormHelper, useModuleContext, useTranslation} from "../../hooks";
import {FormFragmentProvider, useFormRulesBuilder} from "../hooks";
import {DropzoneController} from "../controllers";
import React, {useEffect} from "react";
import {FormGroup} from "../components";
import {useWatch} from "react-hook-form";
import {usePaymentMethodIbanProof} from "../../components/PaymentMethodIbanProof";
import HiddenInputController from "../controllers/HiddenInputController";
import FilePreview from "../../components/FilePreview";
import editIcon from "../../assets/img/icons/edit.svg";
import {Preloader} from "../../misc/_index";


export default function PaymentMethodIbanProofFormFragment({paymentMethod}) {
    const {t} = useTranslation()
    const {storeName} = useModuleContext()
    const paymentMethodIbanProof = usePaymentMethodIbanProof(storeName)
    const {formMethods, setValue, name} = useFormHelper('paymentMethodIbanProof')
    const formRules = useFormRulesBuilder({
        file: b => b.required(paymentMethod?.payment_method_iban_proof === 'required')
            .validate(() => paymentMethod?.payment_method_iban_proof === 'required' ? paymentMethodIbanProof.data : true)
    }, formMethods)
    const file = useWatch({
        control: formMethods.control,
        name: name('file'),
    })
    const uploadedFile = useWatch({
        control: formMethods.control,
        name: name('uploadedFile'),
    })

    useEffect(() => {
        if (!file)
            return
        if (file instanceof File) {
            paymentMethodIbanProof.store(file)
        }
    }, [file])

    useEffect(() => {
        if (paymentMethodIbanProof.data) {
            setValue('uploadedFile', paymentMethodIbanProof.data)
            formMethods.trigger(name('file'))
        }
    }, [paymentMethodIbanProof.data])

    const resetForm = () => {
        setValue('file', null, {shouldValidate: true})
        setValue('uploadedFile', undefined, {shouldValidate: true})
    }

    return (
        <FormFragmentProvider value={{prefix: 'paymentMethodIbanProof'}}>
            <div className="p-5 --mb2" dangerouslySetInnerHTML={{__html: t('payment_method_iban_proof.header_text')}}/>
            {uploadedFile ? (
                <div>
                    <div className="--mb2">
                        <FilePreview file={uploadedFile.url}/>
                    </div>
                    <button
                        type="button"
                        className="link -primary"
                        onClick={resetForm}
                    >
                        {t('pop.edit_img_button')}
                        {' '}
                        <img src={editIcon} alt="" style={{width: '12px'}}/>
                    </button>
                </div>
            ) : (
                <>
                    <FormGroup>
                        <DropzoneController
                            name="file"
                            label={t('payment_method_iban_proof.placeholder_text')}
                            rules={formRules.values.file}
                        />
                    </FormGroup>
                    {paymentMethodIbanProof.mutation.isLoading && <div className="--mb3"><Preloader/></div>}
                    <p
                        className="par-4 --mb1"
                        dangerouslySetInnerHTML={{__html: t('payment_method_iban_proof.compatible_file_types') || t('ev_machine_registration.pop_text2')}}
                    />
                </>
            )}
            <HiddenInputController name="uploadedFile"/>
        </FormFragmentProvider>
    )
}
