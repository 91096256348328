import React, {useState} from 'react';
import BaseModal from "../../modals/BaseModal";
import {useTranslation} from "../../../misc/Hooks";
import {GetInTouchModal} from "../../modals/_index";
import {FilterForm} from "./index";

export default function FilterModal(props) {
    const [gitModalVisible, setGitModalVisible] = useState(false);
    const t = useTranslation();

    return (
        <BaseModal
            visible={props.visible}
            onClose={props.onClose}
        >
            {!!props.visible && (
                <>
                    <button
                        className="modal__close -left filterModal__reset"
                        onClick={props.onReset}
                    >
                        {t('reset')}
                    </button>
                    <div className="modal__header">
                        <h3 className="modal__title">{t('filter.title')}</h3>
                    </div>

                    <div className="modal__body">
                        <FilterForm
                            filter={props.filter}
                            coffeeLines={props.coffeeLines}
                            cupSizes={props.cupSizes}
                            aromaticProfiles={props.aromaticProfiles}
                            coffeeTypes={props.coffeeTypes}
                            accessoryTypes={props.accessoryTypes}
                            productsCount={props.productsCount}
                            categories={props.categories}
                            onSubmit={props.onSubmit}
                            hidePopup={props.hidePopup}
                        />
                    </div>

                    <div className="modal__footer">

                        <div className="par-4">
                            <b>{t('need_help')} </b>
                            <a className="link -primary -parentSize" onClick={() => setGitModalVisible(true)}>
                                <b>{t('get_in_touch')}</b>
                            </a>
                        </div>
                    </div>

                    <GetInTouchModal
                        visible={gitModalVisible}
                        onClose={() => setGitModalVisible(false)}
                    />
                </>
            )}
        </BaseModal>
    );
}
