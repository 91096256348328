import React from 'react';
import BaseModal from "./BaseModal";
import {Button} from "../buttons/_index";
import {useCountryConfig, useTranslation} from "../../misc/Hooks";

const GetInTouchModal = (props) => {
    const t = useTranslation();
    const countryConfig = useCountryConfig();


    const text = countryConfig?.contact_modal_text || t('modal.git.text');
    const contactPhone = countryConfig?.contact_phone || t('contact_phone');
    const contactEmail = countryConfig?.contact_email || t('contact_email');


    return (
        <BaseModal
            visible={props.visible}
            onClose={props.onClose}
        >
            <div className="modal__header">
                <h3 className="modal__title" dangerouslySetInnerHTML={{__html: t('modal.git.title')}}/>
            </div>

            <div className="modal__body">
                <div className="getInTouchModal__body">
                    <p
                        className="par-2 --mb4"
                        dangerouslySetInnerHTML={{__html: text}}
                    />

                    <div className="getInTouchModal__buttons">
                        {!!contactPhone && (
                            <div className="getInTouchModal__button">
                                {!!t('modal.git.phone_label') && (
                                    <p className="par-1 --semiBold --mb2">{t('modal.git.phone_label')}</p>
                                )}
                                <Button
                                    type="secondary"
                                    label={t('modal.git.button_phone') || contactPhone}
                                    onClick={e => {
                                        e.preventDefault();
                                        window.location.href = `tel:${contactPhone}`
                                    }}
                                />
                            </div>
                        )}
                        {!!contactEmail && (
                            <div className="getInTouchModal__button">
                                {!!t('modal.git.email_label') && (
                                    <p className="par-1 --semiBold --mb2">{t('modal.git.email_label')}</p>
                                )}
                                <Button
                                    type="secondary"
                                    label={t('modal.git.button')}
                                    onClick={e => {
                                        e.preventDefault();
                                        window.location.href = `mailto:${contactEmail}`
                                    }}
                                />
                            </div>
                        )}
                        {!!t('modal.git.link_url') && (
                            <div className="getInTouchModal__button">
                                {!!t('modal.git.link_label') && (
                                    <p className="par-1 --semiBold --mb2">{t('modal.git.link_label')}</p>
                                )}
                                <Button
                                    type="secondary"
                                    label={t('modal.git.link_button')}
                                    onClick={e => {
                                        e.preventDefault();
                                        window.open(t('modal.git.link_url'))
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>

            </div>

        </BaseModal>
    );
}


export default GetInTouchModal;
