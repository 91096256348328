import {useMutation} from "@tanstack/react-query";
import {useResellerActions} from "../../../../actions/useResellerActions";
import {ApiException} from "../../../../errors";

export default function useResellerVerify(query = null) {
    const actions = useResellerActions()

    const verifyMutation = useMutation(async ({id, status}) => {
            const payload = {
                id_reseller: typeof id === "number" ? [id] : id,
                verified: status
            }
            const {data} = await actions.resellerVerify(payload)

            if (data.status === 'success') return data
            throw new ApiException(data.messages[0], data)
        },
        {
            onSuccess: () => query ? query.refetch() : {}
        }
    )

    return {verifyMutation}
}