import {PasswordInputController} from "../controllers";
import React from "react";
import {useTranslation} from "../../hooks";

export default function PasswordInput(props) {
    const {t} = useTranslation()

    return (
        <PasswordInputController
            name="password"
            label={t('password')}
            {...props}
        />
    )
}
