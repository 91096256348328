
export default function getDeepValue(object, key) {
    if(!object)
        return null

    const keys = key.split('.')

    if(keys.length > 1 && typeof object[keys[0]] === 'object')
        return getDeepValue(object[keys[0]], key.replace(/^.+\./, ''));
    return object[keys[0]]
}
