import '../../../assets/style/vendors/skeleton.css';
import {useLocalizeObject, useTranslation} from "../../../misc/Hooks";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Button} from "../../../components/buttons/_index";
import {useStepsNavigation} from "../../../hooks/modules/etrade";
import PromoModal from "../../../components/PromoModal";
import React from "react";


export default function MachinePlanScreen(props) {
    const stepsNavigation = useStepsNavigation()
    const t = useTranslation()
    const localizeObject = useLocalizeObject()
    const data = useSelector(({eTrade}) => eTrade.data)


    return (
        <>
            <div className="eTradeMachinePlan">
                <div className="--mb4">
                    <div className="par-5 listCheck -circle"
                         dangerouslySetInnerHTML={{__html: localizeObject(data.etrade_text)}}/>
                </div>

                <div className="step__nav">
                    <Button label={t('continue')} href={stepsNavigation.next()}/>
                </div>
            </div>
            <PromoModal
                storeName="eTrade"
                popupType="default"
            />
        </>
    )
}
