import React from "react";
import {Step} from "./components";
import Routes from "../../utils/Routes";
import {Config} from "../../utils/_index";
import useFetchPromotions, {PromotionsProvider} from '../../hooks/useFetchPromotions'
import useFetchCategories, {CategoriesProvider} from '../../hooks/useFetchCategories'
import BaseStepsFunnel from '../../components/stepsFunnel'

export default function StepsFunnel() {
    const fetchPromotions = useFetchPromotions()
    const fetchCategories = useFetchCategories()

    return (
        <PromotionsProvider value={fetchPromotions}>
            <CategoriesProvider value={fetchCategories}>
                <BaseStepsFunnel
                    sessionTime={Config.EV_SESSION_TIME}
                    homeRoute={Routes.SHOP_REOPEN_MACHINE_REGISTRATION}
                    summaryRoute={Routes.SHOP_REOPEN_SUMMARY}
                    StepComponent={Step}
                />
            </CategoriesProvider>
        </PromotionsProvider>
    )
}
