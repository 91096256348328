import React, {useEffect, useMemo} from 'react';
import {Link, Redirect, Route, Switch, useRouteMatch,} from "react-router-dom";
import {useNavigation} from "../misc/Hooks";
import {useInView} from "react-intersection-observer";
import checkedIcon from '../assets/img/icons/checked.svg';

export default function Step({stepValidation, ...props}) {
    const navigation = useNavigation();
    const match = useRouteMatch();
    const {ref, inView} = useInView({
        rootMargin: `-${document.querySelector('.header')?.clientHeight ?? 0}px`,
    })
    const {isActive, route} = useMemo(() => {
        if(Array.isArray(props.route)) {
            return {
                isActive: props.route.includes(match.path),
                route: props.route[0],
            }
        }
        return {
            isActive: match.path === props.route,
            route: props.route,
        }
    }, [match.path, props.route]);

    const circleContent = useMemo(() => {
        if(props.currentStep > props.index)
            return <img src={checkedIcon} alt="-"/>
        return props.index + 1
    }, [props.currentStep, props.index])

    const circleActive = useMemo(() => {
        return props.currentStep >= props.index
    }, [props.currentStep, props.index])

    useEffect(() => {
        if(typeof props.onChangeInView === 'function') {
            props.onChangeInView(inView)
        }
    }, [inView])

    useEffect(() => {
        if(stepValidation.value && isActive && props.currentStep !== props.index) {
            props.setCurrentStep();
        }
    })

    if(!stepValidation.value && isActive) {
        return <Redirect to={stepValidation.lastValidRoute}/>
    }

    return (
        <div className={`step ${isActive ? '-active' : ''}`}>
            <div ref={ref} className="step__header">
                <div className="wrapper-bg">
                    <div className="wrapper">
                        {stepValidation.value ? (
                            <div className="step__headerContent">
                                <div className={`step__circle ${circleActive ? '-active' : ''}`}>
                                    {circleContent}
                                </div>
                                <Link to={navigation(route)} style={{textDecoration: 'none'}}>
                                    <h1
                                        className={`step__title${props.currentStep + 1 === props.steps.length ? ' -wrap' : ''}`}
                                        dangerouslySetInnerHTML={{__html: props.title}}
                                    />
                                </Link>
                            </div>
                        ) : (
                            <div className="step__headerContent">
                                <div className={`step__circle ${circleActive ? '-active' : ''}`}>
                                    {circleContent}
                                </div>
                                <h1 className={`step__title${props.currentStep + 1 === props.steps.length ? ' -wrap' : ''}`} dangerouslySetInnerHTML={{__html: props.title}}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="step__body">

                <div className="wrapper-bg">
                    <div className="wrapper">
                        <div className="step__bodyContent">
                            {stepValidation.value && (
                                <Switch>
                                    <Route
                                        exact path={props.route}
                                    >
                                        {props.children}
                                    </Route>
                                </Switch>
                            )}
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}
