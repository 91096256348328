import {usePromotionsContext} from "../../../hooks/useFetchPromotions";
import PromotionSelection from "../../../components/promotionSelection";
import React, {useEffect, useMemo} from "react";
import {
    useModuleStepsNavigation,
    useNespressoMember,
    usePersonalization,
    useSeoPage,
    useTranslation
} from "../../../hooks";
import ProductsSelection from "../../../components/ProductsSelection";
import {Button} from "../../../components/buttons/_index";
import WelcomeMessage from "../../../components/WelcomeMessage";

export default function OfferSelectionScreen() {
    useSeoPage({title: 'shop_offer_selection.title'})
    const {t} = useTranslation()
    const {nespressoMember} = useNespressoMember()
    const {query, promotion} = usePromotionsContext()
    const stepsNavigation = useModuleStepsNavigation()
    const personalization = usePersonalization()

    const data = useMemo(
        () => {
            if (query.isSuccess) {
                return query.data?.filter(p => !(nespressoMember?.has_subscription && p.promotion_type?.startsWith('subscription_')))
            }
            return []
        },
        [query.data, nespressoMember]
    )



    return (
        <div>
            <WelcomeMessage/>

            <PromotionSelection
                query={query}
                data={data}
            />

            {!!promotion && (
                <>
                    {promotion.promotion_show_coffees ? (
                        <>
                            <ProductsSelection
                                stepsNavigation={stepsNavigation}
                                texts={{
                                    title: promotion.promotion_products_selection_text,
                                    description: promotion.promotion_description4,
                                }}
                                isLoadingPersonalization={personalization.mutation.isLoading}
                            />
                        </>
                    ) : (
                        <div className="step__nav evCS__mobileFooter">
                            <Button
                                type="primary-outline"
                                href={stepsNavigation.prev()}
                                label={t('back')}
                            />
                            <Button
                                type="primary"
                                href={stepsNavigation.next()}
                                label={t('continue')}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
