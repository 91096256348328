import {useBurn} from "../hooks";
import {useTranslation} from "../../../hooks";
import {BurnForm} from "../../../forms";
import {InfoComponent} from "../../../components/_index";

export default function BurnScreen(){
    const {burnMutation, alert} = useBurn()
    const {t} = useTranslation()
    return (
        <div className="wrapper">
            <div className="resMS">
                <div className="resMS__header">
                    <h1 className="heading-2">{t('reseller_burn.title')}</h1>
                </div>
                <div className="burn">
                   <div>
                       <BurnForm
                           onSubmit={burnMutation.mutate}
                           isSuccess={burnMutation.isSuccess}
                           state={{
                               isLoading: burnMutation.isLoading
                           }}
                       />
                       <InfoComponent type={alert?.type} message={t(alert?.message)}/>
                   </div>
                </div>
            </div>
        </div>
    )
}