import {useMutation} from "@tanstack/react-query";
import {useResellerActions} from "../../../actions/useResellerActions";
import {ApiException} from "../../../errors";
import {useEffect, useState} from "react";

export default function useBurn(){

    const actions = useResellerActions()
    const [alert, setAlert] = useState({type: '', message: ''})

    useEffect(() => {
        console.log(alert)
    }, [alert])

    const burnMutation = useMutation(
        async ({code, serialNumber}) => {
            let params = {}
            if(code) params.promo_code = code
            if(serialNumber) params.serial_number = serialNumber
            setAlert({type: '', message: ''})
            const {data} = await actions.blockCode(params)

            if (data.status === 'success')
                return {data: data.data, params: params}
            throw new ApiException(data.messages[0], data)
        },
        {

            onSuccess: ({data, params}) => {

                if(params?.promo_code && params?.serial_number)
                    setAlert({type: 'success', message: 'reseller_burn.success.serial_number_and_code_burn'})
                else {
                    if(params?.promo_code)
                        setAlert({type: 'success', message: 'reseller_burn.success.code_burn'})

                    if(params?.serial_number)
                        setAlert({type: 'success', message: 'reseller_burn.success.serial_number_burn'})
                }
            },
            onError: ({error}) => {
                if([
                    'promo_code_used_or_blocked',
                    'promo_code_not_isset',
                    'promotion_expired',
                    'promotion_not_yet_active',
                    'serial_number_blacklisted',
                    'promo_code_does_not_exist',

                ].includes(error)){
                    if(error === 'promo_code_not_isset')
                        setAlert({type: 'error', message: `reseller_burn.error.promo_code_not_isset`})
                    else
                        setAlert({type: 'error', message: `reseller_burn.error.${error}`})

                } else setAlert({type: 'error', message: `reseller_burn.error.error`})
            }
        }
    )

    return {
        burnMutation,
        alert
    }
}