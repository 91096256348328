import {useModuleContext, useOrderFinalized, useTranslation} from "../hooks";
import {Preloader} from "../misc/_index";
import {Footer, Header} from "../components/misc/_index";
import {GetInTouchModal} from "../components/modals/_index";
import {Routes} from "../utils/_index";
import {Banner, CodeField, ShopLogo} from "../modules/etrade/components";
import {Button} from "../components/buttons/_index";
import {useNavigation} from "../misc/Hooks";

export default function OrderFinalizedEtradeScreen({homeRoute}) {
    const {storeName} = useModuleContext()
    const {t} = useTranslation()
    const {typMutation, setGitModalVisible, gitModalVisible, params} = useOrderFinalized(storeName)
    const navigation = useNavigation()

    return (
        <div className="app">
            <Header
                storeName={storeName}
                homeRoute={navigation(homeRoute ?? Routes.ETRADE_ORDER_FINALIZED, params.uuid)}
                secondLogo={() => (
                    <ShopLogo
                        gallery={typMutation.data?.data?.order?.etrade.etrade_gallery}
                        url={typMutation.data?.data?.order?.etrade.etrade_shop_url}
                    />
                )}
                cartDisabled
                alertsDisabled
            />

            <div className="main">
                <div className="wrapper-bg formContainer --pt0">
                    <div className="wrapper -xxl --mb4">
                        <Banner gallery={typMutation.data?.data?.order?.etrade?.etrade_gallery}/>
                    </div>

                    <div className="wrapper">
                        {typMutation.isLoading ? (
                            <Preloader/>
                        ) : typMutation.isSuccess ? (
                            <div className="par-2">
                                <div dangerouslySetInnerHTML={{__html: typMutation.data.data.typ?.thank_you_page_text}}/>
                                <div className="grid">
                                    <div/>
                                    <div>
                                        <div className="--mb3">
                                            <CodeField code={typMutation.data.data.order.etrade_code.etrade_code_name}/>
                                        </div>
                                        {!!typMutation.data.data.order.etrade_shop_redirect && (
                                            <div className="--mb3">
                                                <Button
                                                    type="primary-outline"
                                                    href={
                                                    typMutation.data.data.order.etrade_shop_redirect.startsWith('http')
                                                        ? typMutation.data.data.order.etrade_shop_redirect
                                                        : `https://${typMutation.data.data.order.etrade_shop_redirect}`
                                                }
                                                    label={t('back_to', typMutation.data.data.order.invoice_shop?.shop_title)}
                                                />
                                            </div>
                                        )}
                                        <div className="par-4 --semiBold">
                                            <span>{t('need_help')}</span>
                                            <a
                                                className="link -primary -parentSize"
                                                onClick={() => setGitModalVisible(true)}
                                            >
                                                {t('get_in_touch')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="--mb4" style={{textAlign: 'center'}}>
                                <h1 className="heading-1">{t('etrade.order_not_found.title')}</h1>
                                <p className="par-1">{t('etrade.order_not_found.text')}</p>
                            </div>
                        )}


                        <GetInTouchModal
                            visible={gitModalVisible}
                            onClose={() => setGitModalVisible(false)}
                        />
                    </div>
                </div>
            </div>

            <Footer module={storeName}/>
        </div>
    )
}