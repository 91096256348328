import {Route, Switch, useLocation} from "react-router-dom";
import Provider from "./Provider";
import Header from "../../components/misc/Header";
import React, {useMemo} from "react";
import Routes from "../../utils/Routes";
import {
    ResellerAuthScreen,
    ResellerForgotPasswordScreen,
    ResellerRegistrationScreen,
    ResellerSetNewPasswordScreen,
    ResellerVerificationScreen
} from "./screens";
import {useNavigation} from "../../misc/Hooks";

export default function Funnel(props) {
    const location = useLocation()
    const navigation = useNavigation();
    const homeRoute = useMemo(() => {
        if(location.pathname === navigation(Routes.V2_RESELLER_AUTH))
            return navigation(Routes.MACHINE_REGISTRATION_REGISTRATION)
        return navigation(Routes.V2_RESELLER)
    }, [location.pathname, navigation])

    return (
        <Provider>
            <div className="app">
                <Header
                    cartDisabled
                    alertsDisabled
                    homeRoute={homeRoute}
                />
                <div className="main">
                    <div className="wrapper-bg formContainer">
                        <div className="wrapper">
                            <Switch>
                                <Route exact path={Routes.V2_RESELLER_AUTH} component={ResellerAuthScreen}/>
                                <Route exact path={Routes.V2_RESELLER_FORGOT_PASSWORD} component={ResellerForgotPasswordScreen}/>
                                <Route exact path={Routes.V2_RESELLER_FORGOT_PASSWORD_SET_NEW} component={ResellerSetNewPasswordScreen}/>
                                <Route exact path={Routes.V2_AUTH_RESELLER_REGISTRATION} component={ResellerRegistrationScreen}/>
                                <Route exact path={Routes.V2_AUTH_RESELLER_VERIFICATION} component={ResellerVerificationScreen}/>
                            </Switch>
                        </div>
                    </div>
                </div>

            </div>
        </Provider>
    )
}
