import Axios from "../utils/Axios";
import {queryParams} from "../misc/Helpers";


export const signIn = (data, paramObj = {}, config = {}) => {
    const params = queryParams(paramObj)
    return Axios.post(`/sign-in${params}`, data, config);
}


export const canRegister = (data, paramObj = {}) => {
    const params = queryParams(paramObj)
    return Axios.post(`/can-register${params}`, data);
}

export const canRegister2 = (data, params) => {
    return Axios.post(`/can-register${params}`, data);
}

export const canAttempt = (data, paramObj = {}) => {
    const params = queryParams(paramObj)
    return Axios.post(`/can-attempt${params}`, data);
}

export const canAttempt2 = (data, params = '') => {
    return Axios.post(`/can-attempt${params}`, data);
}


export const getAddresses = (data) => {
    return Axios.post(`/client-addresses`, data);
}


export const signInReseller = (data) => {
    return Axios.post(`/reseller/login`, data);
}

export const getReseller = (params = null) => {
    return Axios.get(`/reseller/user`, params);
}

export const registerReseller = (data) => {
    return Axios.post(`/reseller/register`, data);
}

export const verificationReseller = (status, token, parent_token) => {
    return Axios.get(`/reseller/verification/${status}/${token}/${parent_token}`);
}

export const setTemporaryPassword = (data) => {
    return Axios.post(`/reseller/update-password`, data);
}

export const checkSso = (data) => {
    return Axios.post(`/reseller/sso/check`, data)
}

export const authorizeBySso = (paramObj, data = null) => {
    const params = queryParams(paramObj)
    return Axios.post(`/reseller/sso/login${params}`, data)
}
