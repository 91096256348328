import useCart2 from "../hooks/useCart2";
import {useCart} from "../../../misc/Hooks";
import React, {useEffect, useState} from "react";
import {PlusMinusButton} from "../../buttons/_index";
import {MdOutlineHideImage} from "react-icons/md";


export default function CartSummaryProduct({
    product,
    parentCat,
    disableEdit,
    customData,
    hidePlusMinusButtons
}) {
    const cart = useCart2(customData)
    const [imageIsset, setImageIsset] = useState(true)
    const {
        isCartEditable,
        updateItem,
    } = useCart(cart.storeName);

    const {
        image,
        optInConfig,
        productTotalPrice,
        productTotalOldPrice,
        itemInCart,
        productPrice,
    } = cart.getProduct(product, parentCat)

    useEffect(() => {
        const checkImage = new Promise(resolve => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = image;
        });

        checkImage.then(status => {
            setImageIsset(status)
        })
    }, [image])

    return (
        <div key={product.id_product} className="summaryItemNew">
            <div className="summaryItemNew__thumb">
                {!!imageIsset ? (
                    <img src={image} alt={product.product_title}/>
                ) : (<MdOutlineHideImage/>)}
            </div>
            <div className="summaryItemNew__content">
                <div className="summaryItemNew__header">
                    <h4 className="summaryItemNew__title">{product.product_title}</h4>
                    {(!cart.hideRemoveButtonTypes.includes(parentCat.category.category_type)
                        && isCartEditable
                        && !!disableEdit
                        && !optInConfig) && (
                        <button
                            type="button"
                            className="summaryItemNew__remove"
                            onClick={() => updateItem(product.id_product, 0)}
                        >
                            <img src={require('../../../assets/img/icons/close.svg').default} alt="X"/>
                        </button>
                    )
                    }
                </div>

                <div className="summaryItemNew__footer">
                    <div className={`summaryItemNew__priceWrapper ${hidePlusMinusButtons ? '-oneLine' : ''}`}>
                        <div className="summaryItemNew__price --bold" style={{marginRight: '4px'}}>
                            {!!productTotalOldPrice && (
                                <span className="summaryItem__oldPrice">
                                    {productTotalOldPrice}
                                </span>
                            )}
                            {product.product_percentage_rebate ? (
                                `-${Number(product.product_percentage_rebate).toFixed(0)}%`
                            ) : productTotalPrice}
                        </div>
                        {(!cart.hideSummaryProductPriceTypes.includes(parentCat.category.category_type) && !optInConfig.value) && (
                            <div className="summaryItemNew__price --normal">
                                ({itemInCart.value * product.product_package_qty} x {productPrice})
                            </div>
                        )}
                    </div>

                    {(!cart.hidePlusMinusButtonTypes.includes(parentCat.category.category_type) && !optInConfig.value && !hidePlusMinusButtons) && (
                        <div className="summaryItemNew__value">
                            <PlusMinusButton
                                value={itemInCart.value * product.product_package_qty}
                                step={product.product_step}
                                packageQty={product.product_package_qty}
                                max={product.product_max_qty}
                                minusDisabled={!isCartEditable || disableEdit}
                                plusDisabled={itemInCart.value >= product.product_max_qty || !isCartEditable || disableEdit}
                                onClickMinus={() => updateItem(product.id_product, (itemInCart.value ?? 0) - product.product_step)}
                                onClickPlus={() => updateItem(product.id_product, (itemInCart.value ?? 0) + product.product_step)}
                                onChange={v => !disableEdit ? updateItem(product.id_product, v) : undefined}
                            />
                        </div>
                    )}
                </div>

            </div>

        </div>
    )
}