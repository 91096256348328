import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router-dom";
import {Tps} from "../../components/tps";
import * as promotionActions from '../../actions/PromotionActions';
import * as categoryActions from '../../actions/CategoryActions';
import * as shopActions from '../../actions/ShopActions';
import * as tpsReducer from "../../redux/tpsReducer";
import {useTranslation} from "../../misc/Hooks";
import * as evReducer from "../../redux/evReducer";
import * as mainActions from "../../actions/MainActions";
import * as orderActions from '../../actions/OrderActions';
import * as machineActions from '../../actions/MachineActions';
import * as countryActions from '../../actions/CountryActions';
import Env from "../../utils/Env";
import {getCivilityByTitle, uuidv4} from "../../misc/Helpers";


let MOUNTED = false;

const TpsContainer = (props) => {
    const t = useTranslation();
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const store = useSelector(({main, tps}) => {
        const country = tps.country;
        const machines = tps.machines?.filter(m => m.id_country === country?.id_country);
        const machinePartners = tps.machinePartners
            .filter(model => machines.find(m => m.machine_partner === model.stack_value));
        const machineModels = tps.machineModels
            .filter(model => machines.find(m => m.machine_model === model.stack_value));
        const machineColors = tps.machineColors
            .filter(model => machines.find(m => m.machine_color === model.stack_value));


        return {
            promotions: tps.promotions,
            promotion: tps.promotion,
            categories: tps.categories,
            machines,
            machinePartners,
            machineModels,
            machineColors,
            shop: tps.shop,
            data: tps.data,
            receiptData: tps.receiptData,
            machine: tps.machine,
            countries: tps.countries,
            country,
            machineData: tps.machineData,
            paymentMethod: tps.paymentMethod,
        }
    })
    const [loading, setLoading] = useState(false);
    const [loadingPos, setLoadingPos] = useState(false);
    const [loadingSerialNumber, setLoadingSerialNumber] = useState(false);
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [errorPos, setErrorPos] = useState(null);
    const [errorSerialNumber, setErrorSerialNumber] = useState(null);
    const [errorPop, setErrorPop] = useState(null);
    const [loadingReceipt, setLoadingReceipt] = useState(false);
    const [sending, setSending] = useState(false);
    const [message, setMessage] = useState(null);
    const [machine, setMachine] = useState(store.machine);
    const [promotion, setPromotion] = useState(store.promotion);
    const [paymentMethod, setPaymentMethod] = useState(store.paymentMethod);


    useEffect(async () => {
        if(store.country) {
            setLoading(true);
            await getPromotions(store.country?.id_country);
            setLoading(false);
        }
    }, [store.country])


    useEffect(async () => {
        MOUNTED = true;

        // setLoading(true);
        await Promise.all([getCountries()])
        // setLoading(false);

        return () => {
            MOUNTED = false;
        }
    }, [])


    const getCountries = async () => {
        await countryActions.getAll().then(r => {
            const res = r.data;
            if(res.status === 'success') {
                dispatch(tpsReducer.setCountries(res.data))
                if(!store.country) {
                    dispatch(tpsReducer.setCountry(res.data[0]))
                } else {
                    const country = res.data.find(c => c.id_country === store.country.id_country);
                    if(country) {
                        dispatch(tpsReducer.setCountry(country))
                    }
                }
            }
            else {
                console.warn('Something gone wrong!', res)
            }
        }).catch(err => {
            console.error(err)
        })
    }


    const getPromotions = async (countryId) => {
        await promotionActions.tps(countryId).then(r => {
            const res = r.data;
            if(res.status === 'success') {
                dispatch(tpsReducer.setPromotions(res.data))
            }
            else {
                console.warn('Something gone wrong!', res)
            }
        }).catch(err => {
            console.error(err)
        })
    }


    const getShop = (posId) => {
        setLoadingPos(true);
        setErrorPos(null);

        shopActions.getByPosId(posId).then(r => {
            const res = r.data;
            if(res.status === 'success') {
                setErrorPos(null)
                dispatch(tpsReducer.setShop(res.data))
            }
            else {
                if(res.messages?.length) {
                    setErrorPos(t(`validation.${res.messages[0]}`))
                    if(res.messages[0] === 'shop_not_exist') {
                        dispatch(tpsReducer.setShop(null))
                    }
                }
                else {
                    console.warn('Something gone wrong!', res)
                }
            }
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            if(MOUNTED) {
                setLoadingPos(false);
            }
        })
    }


    const handleChangePromotion = (id) => {
        setLoadingCategories(true);
        const promotion = store.promotions.find(p => p.id_promotion === id);
        if(!promotion)
            return;

        setPromotion(promotion);

        categoryActions.getTpsCategories(id).then(r => {
            const res = r.data;
            if(res.status === 'success') {
                dispatch(tpsReducer.setCategories(res.data))
            }
            else {
                console.warn('Something gone wrong!', res)
            }
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            if(MOUNTED) {
                setLoadingCategories(false);
            }
        })
    }

    const handleChangeSerialNumber = async (serialNumber) => {
        setLoadingSerialNumber(true);
        setErrorSerialNumber(null);
        await machineActions.validateSerialNumberTps({serial_number: serialNumber})
            .then(r => {
                const res = r.data;
                if(res.status === 'success') {
                    setMachine(res.data.machine);
                    dispatch(tpsReducer.setMachineData(res.data))
                } else {
                    if(res.messages.length) {
                        setErrorSerialNumber(res.messages[0]);
                    }
                }
            })
            .catch(err => {
                console.error('Error on validateSerialNumber', err);
            })
        setLoadingSerialNumber(false);
    }

    const handleUploadReceipt = (file) => {
        setLoadingReceipt(true);
        setErrorPop(null);
        if (file.type.match('application/pdf')) {
            const formData = new FormData();
            formData.append('file', file.file)
            // dispatch(evReducer.setProofOfPurchase(null));
            // dispatch(evReducer.setPromoCode(null));
            mainActions.proofOfPurchasePdfOcr(formData).then(r => {
                const res = r.data;
                if (res.status === 'success') {
                    if (MOUNTED) {
                        dispatch(tpsReducer.setReceiptData(res.data))
                        // dispatch(evReducer.setProofOfPurchaseLogs(res.data));
                    }
                } else {
                    if (res.messages?.length) {
                        if (MOUNTED) {
                            dispatch(tpsReducer.setReceiptData(null))
                        }
                    } else {
                        console.log('Something gone wrong! ', res)
                    }
                }
            }).catch(error => {
                console.error('Error', error.message);
            }).finally(() => {
                if (MOUNTED) {
                    setLoadingReceipt(false);
                }
            })
        } else {
            const formData = new FormData();
            formData.append('file', file.file)
            // dispatch(tpsReducer.setProofOfPurchase(null));
            // dispatch(tpsReducer.setPromoCode(null));
            mainActions.proofOfPurchaseOcr(formData).then(r => {
                const res = r.data;
                if (res.status === 'success') {
                    if (MOUNTED) {
                        // setPopData(res.data);
                        dispatch(tpsReducer.setReceiptData(res.data));
                    }
                } else {
                    if (MOUNTED) {
                        tpsReducer.setReceiptData(res.data);
                        // dispatch(evReducer.setProofOfPurchaseLogs(res.data));
                    }

                    if (res.messages?.length) {
                        if (MOUNTED) {
                            setErrorPop(res.messages[0])
                        }
                    } else {
                        console.log('Something gone wrong! ', res)
                    }
                }
            }).catch(error => {
                console.error('Error', error.message);
            }).finally(() => {
                if (MOUNTED) {
                    setLoadingReceipt(false);
                }
            })
        }
    }

    const handleChangeCountry = (countryId) => {
        const country = store.countries.find(c => c.id_country === countryId);
        if(country) {
            dispatch(tpsReducer.setCountry(country));
        }
    }

    const handleChangePaymentMethod = (data) => {
        setPaymentMethod(data.type)
    }

    const handleClearAndReload = () => {
        dispatch(tpsReducer.clearOrder())
        setTimeout(() => {
            window.location.reload()
        }, 2000);
    }

    const handleSubmit = (data) => {
        if(!paymentMethod) {
            return;
        }

        const serialNumber = data.serialNumber?.replace(/_/g, '');
        const storeMachine = store.machines.find(m => m.id_machine === data.machine?.value)

        const purchaseDate = new Date(data.purchaseDate);
        const year = purchaseDate.getFullYear();
        const month = (purchaseDate.getMonth() + 1).toString().padStart(2, '0');
        const day = purchaseDate.getDate().toString().padStart(2, '0');


        const formData = {
            customer: {
                source: 'ev',
                test_order: serialNumber && store.machineData?.test_order ? 1 : 0,
                id_promotion: data.promotion?.value,
                id_machine: serialNumber ? machine?.id_machine : storeMachine?.id_machine,
                id_country: data.country.value,
                id_shop: store.shop?.id_shop,
                promotion_type: promotion.promotion_type,
                coffee_type: serialNumber ? machine?.machine_coffee_type : storeMachine?.machine_coffee_type,
                serial: serialNumber,
                uuid: uuidv4(),
                lang: data.lang?.value ?? Env.DEFAULT_LANG,
                filename_machine: '',
                filename_receipt: store.receiptData?.filename ?? '',
                currency_prefix: store.country.country_currency_prefix,
                currency_suffix: store.country.country_currency_suffix,
                currency_name: store.country.country_currency_name,
                voucher_number: data.voucherNumber,

                invoice_date: data.purchaseDate ? `${year}-${month}-${day}` : null,
                // invoice_number: data.proofOfPurchase.invoiceNumber ?? '',
                invoice_price: data.machinePrice,

                ocr_number: store.receiptData?.data.invoice ?? '',
                ocr_date: store.receiptData?.data.date ?? '',
                ocr_price: store.receiptData?.data.total ?? '',
                ocr_shop: store.receiptData?.data.id_shop ?? null,
                ocr_shop_group: store.receiptData?.data.id_shop_group ?? null,

                // address_id: data.customer.address_id ?? null,

                delivery_is_company: data.companyAddress ? 1 : 0,
                delivery_company_name: data.companyName ?? '',
                // delivery_company_tax_id: data.customer.taxId ?? '',
                // delivery_company_irs: data.customer.irs ?? '',
                delivery_civility: data.title ? Number(data.title.value) : 0,
                delivery_firstname: data.name ?? '',
                delivery_lastname: data.surname ?? '',
                delivery_zipcode: data.postcode ?? '',
                delivery_city: data.city ?? '',
                delivery_address1: data.address ?? '',
                delivery_address2: data.address2 ?? '',
                // delivery_country: data.customer.country?.value ?? '',
                delivery_phone: data.phone ?? '',
                delivery_phone_mobile: data.phoneMobile ?? '',
                delivery_phone_home: data.phoneHome ?? '',
                delivery_email: data.email ?? '',

                other_delivery: data.withCustomAddress ? 1 : 0,
                other_delivery_is_company: data.customCompanyAddress ? 1 : 0,
                other_delivery_company_name: data.customCompanyName ?? '',
                other_delivery_civility: data.customTitle ? Number(data.customTitle.value) : 0,
                other_delivery_firstname: data.customName ?? '',
                other_delivery_lastname: data.customSurname ?? '',
                other_delivery_zipcode: data.customPostcode ?? '',
                other_delivery_city: data.customCity ?? '',
                other_delivery_address1: data.customAddress ?? '',
                other_delivery_address2: data.customAddress2 ?? '',
                other_delivery_phone: data.customPhone ?? '',
                other_delivery_phone_mobile: data.customPhoneMobile ?? '',
                other_delivery_phone_home: data.customPhoneHome ?? '',

                // other_delivery_country: data.customer.customCountry?.value ?? '',
                // other_delivery_phone: data.customPhoneMobile ?? '',
                // other_delivery_email: data.customEmail ?? '',

                // marketing_email: data.customer.benefitsEmail ? 1 : 0,
                // marketing_phone: data.customer.benefitsPhone ? 1 : 0,

                // regulations: data.customer.termsAndConditions ? 1 : 0,
                // privacy_policy: data.customer.privacyPolicy ? 1 : 0,

                id_payment_method : paymentMethod?.id_payment_method ?? null,
                payment_method_id_nestle : paymentMethod?.id_nestle ?? '',

                // ocr_log: JSON.stringify(data.ocrLogs),

                member_number: data.customerNumber ?? '',
                // member_token: data.nespressoMember?.token ?? '',
                // member_password: data.customer.password ?? '',
            },
            
            cart: {items: data.cart},
        };


        dispatch(tpsReducer.setData(data));
        dispatch(tpsReducer.setMachine(machine));
        dispatch(tpsReducer.setPromotion(promotion));
        dispatch(tpsReducer.setPaymentMethod(paymentMethod));
        setMachine(null);
        setPromotion(null);
        setPaymentMethod(null);


        setSending(true);
        orderActions.finalizeTps(formData).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    dispatch(tpsReducer.clearOrder());
                    setMessage({status: 'success', message: t('tps.order_saved')})
                }
            } else {
                if(MOUNTED) {
                    setMessage({status: 'error', message: t('error.tps_save_error', res.messages[0])})
                }
            }
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            if(MOUNTED) {
                setSending(false);
            }
        })
    }

    return (
        <Tps
            data={store.data}
            promotions={store.promotions}
            categories={store.categories}
            machines={store.machines}
            machinePartners={store.machinePartners}
            machineModels={store.machineModels}
            machineColors={store.machineColors}
            country={store.country}
            promotion={promotion || store.promotion}
            paymentMethod={paymentMethod || store.paymentMethod}
            shop={store.shop}
            machine={machine || store.machine}
            loading={loading}
            loadingPos={loadingPos}
            loadingSerialNumber={loadingSerialNumber}
            loadingCategories={loadingCategories}
            sending={sending}
            errorPos={errorPos}
            errorPop={errorPop}
            errorSerialNumber={errorSerialNumber}
            message={message}
            getShop={getShop}
            onChangeCountry={handleChangeCountry}
            onChangePromotion={handleChangePromotion}
            onChangeSerialNumber={handleChangeSerialNumber}
            onChangePaymentMethod={handleChangePaymentMethod}
            onUploadReceipt={handleUploadReceipt}
            onClearAndReload={handleClearAndReload}
            onSubmit={handleSubmit}
        />
    )
}


export default TpsContainer;
