import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {useNavigation} from "../../../misc/Hooks";
import {useTranslation} from "../../../hooks";
import {useHistory, useLocation} from "react-router-dom";
import {Checkbox} from "../../../forms/inputs";

/**
 * useTableBuilder
 * @param name
 * @param action
 * @param columns
 * @param _config
 * @param params
 * @param queryProps
 * @returns Object
 */
export default function useTableBuilder(
    {
        name = 'table',
        action = async () => {},
        columns = [],
        config: _config,
        params = {},
        queryProps = {},
    }
) {
    const config = {...defaultConfig, ..._config}
    const history = useHistory()

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)

    const {t} = useTranslation()

    const [selectedItems, setSelectedItems] = useState([])

    const page = useMemo(
        () => Number(searchParams.get(getParamName('page')) || config.startPage),
        [config, searchParams]
    )
    const size = useMemo(
        () => Number(searchParams.get(getParamName('size')) || config.defaultSize),
        [config, searchParams]
    )

    const query = useQuery(
        [name, page, size, params],
        () => action({page, limit: size, ...params}),
        {
            keepPreviousData: true,
            refetchInterval: config.autoRefetch ? config.autoRefetchTimeout : false,
            refetchOnWindowFocus: false,
            enabled: config.enabled,
            onError: ({error}) => {
                if(typeof config.onError === 'function')
                    config.onError(error)
            },
            ...queryProps,
        }
    )

    const handleSelectItem = (item) => {
        const arr = [...selectedItems]
        const idx = selectedItems.findIndex(i => i[config.idFieldName] === item[config.idFieldName])

        if (idx > -1)
            arr.splice(idx, 1);
        else arr.push(item)

        setSelectedItems(arr)
    }

    const handleSelectAll = (items) => {
        let arr = [...selectedItems]

        const currentSelected = items.filter(item => arr.find(arrItem => arrItem[config.idFieldName] === item[config.idFieldName]));


        if(currentSelected.length === items.length){
            arr = arr.filter(arrItem => !items.find(i => i[config.idFieldName] === arrItem[config.idFieldName]))
        } else {
            arr = [...arr, ...items.filter(i => !arr.find(arrItem => arrItem[config.idFieldName] === i[config.idFieldName]))]
        }

        setSelectedItems(arr)
    }

    const tableColumns = useMemo(() => (
        [config.selectable ?
            {
                name: 'select',
                label: (data, query) => {
                    const items = query?.data?.data?.data?.data ?? []
                    return (
                        <Fragment>
                            <span>{t('reseller_client.select') || t('select')}</span>
                            <Checkbox
                                onChange={() => handleSelectAll(items)}
                                value={items.filter(item => selectedItems.find(sItem => sItem[config.idFieldName] === item[config.idFieldName])).length === items.length}
                            />
                        </Fragment>
                    )
                },
                cell: ({row: {original}}) => (
                    <Checkbox
                        value={selectedItems.find(si => si[config.idFieldName] === original[config.idFieldName])}
                        onChange={() => handleSelectItem(original)}
                    />
                )
            } : false,
            ...columns
        ].map((c) => {
            if (c) return {
                Header: typeof c.label === 'function' ? (data) => c.label(data, query) : !!c.label ? t(c.label) : '',
                accessor: c.name,
                Cell: (props) => typeof c.cell === 'function' ? c.cell(props, query) : (props?.value)
            }
        }).filter(c => !!c)

    ), [columns])


    const tableData = useMemo(() => {
        if (!query?.data?.data?.data?.data) return [];
        return query.data.data.data.data;
    }, [query?.data?.data?.data?.data])

    function setPage(page) {
        setSearchParams({page})
    }

    function setSize(size) {
        setSearchParams({size})
    }

    function setSearchParams(params) {
        if (params && config.searchParamPrefix) {
            const prefixedParams = {}
            Object.keys(params)
                .map(k => {
                    prefixedParams[getParamName(k)] = params[k]
                })
            updateSearchParams(prefixedParams)
            return
        }
        updateSearchParams(params)
    }

    function updateSearchParams(params, config = {}) {
        config = {exceptUndefined: true, ...config}
        let payload = {...getAllSearchParams(), ...params}
        if (config.exceptUndefined) {
            payload = Object.keys(payload)
                .filter(param => typeof payload[param] !== 'undefined')
                .filter(param => typeof payload[param] === 'string' ? payload[param] : true)
                .reduce((obj, param) => {
                    obj[param] = payload[param]
                    return obj
                }, {})
        }
        Object.keys(payload).forEach(k => {
            searchParams.set(k, payload[k])
        })
        history.replace({search: searchParams.toString()})

    }

    function getAllSearchParams() {
        return location.search.replace('?', '')
            .split('&')
            .filter(p => p)
            .reduce((obj, param) => {
                const [name, value] = param.split('=')
                obj[name] = value
                return obj
            }, {})
    }

    function getParamName(paramName) {
        if (!config.searchParamPrefix)
            return paramName
        const key = `${paramName[0].toUpperCase()}${paramName.substring(1)}`
        const prefix = typeof config.searchParamPrefix === 'string' ? config.searchParamPrefix : name
        return `${prefix}${key}`
    }



    return {
        query,
        tableData,
        tableColumns,
        setPage,
        currentPage: page,
        setSize,
        size,
        page,
        hiddenColumns: config.hiddenColumns,
        selectedItems,
        setSelectedItems,
        idFieldName: config.idFieldName,
        confirmingActions: config.confirmingActions,
    }
}

const defaultConfig = {
    defaultSize: 10,
    startPage: 1,
    searchParamPrefix: false,
    autoRefetch: false,
    autoRefetchTimeout: 20000,
    selectable: false,
    idFieldName: 'id',
    confirmingActions: false,
    hiddenColumns: [],
    enabled: true,
    onError: null,
}