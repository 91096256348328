import {useQuery} from "@tanstack/react-query";
import {fetchAll, fetchAllReseller, getOneById} from '../actions/PromotionActions';
import {useCountry, useModuleContext, useModuleStepsNavigation, useNespressoMember, useReseller} from "./index";
import {ApiException} from "../errors";
import {useDispatch, useSelector} from "react-redux";
import {getDate} from "../misc/Helpers";
import {createContext, useContext, useEffect, useMemo} from "react";
import {reducers} from "../misc/Hooks";
import {isEnabledByMarket} from "../utils/MarketConfig";
import {setOnlyPromotion} from "../redux/machineRegistrationReducer";

const PromotionsContext = createContext(null)
export const PromotionsProvider = PromotionsContext.Provider

export function usePromotionsContext() {
    const {storeName} = useModuleContext()
    const reducer = useMemo(() => reducers[storeName], [storeName])
    const dispatch = useDispatch()
    const {promotion} = useSelector(state => ({
        promotion: state[storeName]?.promotion,
    }))
    const stepsNavigation = useModuleStepsNavigation()
    const context = useContext(PromotionsContext)

    const setPromotion = (promotion) => {
        dispatch(reducer.setPromotion(promotion))
        if (!promotion?.promotion_show_coffees)
           stepsNavigation.navToNext()

    }

    return {
        ...context,
        promotion,
        setPromotion,
    }
}

export default function useFetchPromotions() {
    const {isLoggedIn} = useReseller()
    const {storeName, reducer, dispatch} = useModuleContext()
    const {nespressoMember, isNewMember} = useNespressoMember()
    const {data: country} = useCountry()
    const store = useSelector(state => ({
        resellerShop: state.reseller?.shop,
        machine: state[storeName]?.machine,
        proofOfPurchase: state[storeName]?.proofOfPurchase,
        promoCode: state[storeName]?.promoCode,
        isFastOrder: state[storeName]?.isFastOrder,
        promotions: state[storeName]?.promotions,
        isNoMachine: state[storeName]?.isNoMachine,
        msnRequired: state[storeName]?.msnRequired,
        promotion: state[storeName]?.promotion,
    }))
    const {
        isEnabled,
        params,
        queryParamObj,
        payload,
    } = useMemo(() => {
        const params = {
            countryId: country.id_country,
            machineId: store.machine?.id_machine ?? 0,
            shopId: store.promoCode ? store.promoCode.id_shop : store.proofOfPurchase?.shop?.value ?? store.resellerShop ?? 0,
            isExistingMember: isNewMember ? 0 : 1,
            purchaseDate: store.proofOfPurchase ? getDate(store.proofOfPurchase?.date) : null,
        }
        const queryParamObj = {
            is_fast_order: store.isFastOrder ? 1 : 0,
            // no_machine: !!store.isNoMachine,
            // msn_required: store.msnRequired ? 1 : 0,
        }
        if(store.isNoMachine) {
            queryParamObj.no_machine = true
        }
        if(!store.msnRequired) {
            queryParamObj.msn_required = store.machine ? 1 : 0
        }

        const payload = {}

        if (store.promoCode) {
            payload.id_promotions = store.promoCode.promotion?.map(p => p.id_promotion) ?? []
        }
        if (nespressoMember && !isNewMember) {
            queryParamObj.member_number = nespressoMember.memberNumber
        }

        const isEnabled = params.countryId && (params.machineId || store.isNoMachine || !store.msnRequired) && (isEnabledByMarket('disableEcapi') || (isNewMember === true ? true : nespressoMember))

        return {
            isEnabled: !!isEnabled,
            params,
            queryParamObj,
            payload,
        }
    }, [
        country,
        nespressoMember,
        isNewMember,
        store.machine,
        store.promoCode,
        store.proofOfPurchase,
        store.isFastOrder,
        store.resellerShop,
        store.msnRequired,
    ])

    const query = useQuery(
        ['promotions', params, queryParamObj, payload],
        async () => {
            const action = isLoggedIn ? fetchAllReseller : fetchAll

            const {data} = await action(params, queryParamObj, payload)

            if (data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        {
            enabled: isEnabled,
            initialData: store.promotions,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                dispatch(reducer.setPromotions(data))
            },
        },
    )

    const singlePromotionQuery = useQuery({
        queryKey: [
            'promotion',
            params.machineId,
            params.shopId,
            nespressoMember?.memberNumber,
            isLoggedIn,
        ],
        queryFn: async () => {
            return await getOneById({
                id: store.promotion?.id_promotion,
                machineId: params.machineId,
                shopId: params.shopId,
                memberNumber: nespressoMember?.memberNumber,
                customerEmail: nespressoMember?.email,
                resellerLoggedIn: isLoggedIn,
            })
                .then(res => {
                    if(res.response) {
                        throw new ApiException(res.response?.data?.message, res.response?.data)
                    }
                    return res.data?.data
                })
        },
        enabled: !!store.promotion?.id_promotion,
        initialData: store.promotion,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            dispatch(reducer.setOnlyPromotion(data))
        }
    })

    return {
        query,
        singlePromotionQuery,
    }
}
