import {useTableBuilder, useTableFilters} from "../../../components/baseTable";
import {useResellerActions} from "../../../actions/useResellerActions";
import {useTranslation} from "../../../hooks";
import SubscriptionStatusLabel from "../../../components/SubscriptionStatusLabel";
import React from "react";
import PaymentStatusLabel from "../../../components/PaymentStatusLabel";
import {isoDateConverterWithTime} from "../../../misc/Helpers";
import {ResendButton} from "../components";
import {isEnabledByMarket} from "../../../utils/MarketConfig";
import {CheckboxController} from "../../../forms/controllers";

export default function useNoMachineShopTable() {

    const actions = useResellerActions()
    const {t} = useTranslation()

    const ordersFilter = useTableFilters({
        builder: {
            formConfig: [['slug']],
        },
        delayFields: ['slug'],
        searchPrefix: "ordersFilter",
        mapFunc: (data) => ({
            slug: data.ordersFilter?.slug ?? '',
        }),
    });

    const noMachineOrdersTable = useTableBuilder({
        name: 'no_machine_orders',
        action: async (params) => {
            const actionPayload = {
                sort: null,
                order_type: ['no_machine_coffee', 'no_machine_subscription_coffee'],
                date_from: null,
                date_to: null,
                id_shop: [],
                status: [],
                id_reseller: [-1] // -1 = logged user
            }
            return actions.getOrdersV2(actionPayload, params)
        },
        columns: [
            {
                name: 'id_order',
                label: 'assisted_registration.id'
            },
            {
                name: 'promotion_type',
                label: 'order.promotion_type',
                cell: ({value}) => t(`no_machine_shop.reseller.promotion_type.${value}`)
            },
            {
                name: 'tracking_code',
                label: 'assisted_registration.tracking_code'
            },
            {
                name: 'status',
                label: 'order.status_of_registration',
                cell: ({value}) => <SubscriptionStatusLabel status={value}/>
            },
            {
                name: 'payment_status',
                label: 'order.status_of_payment',
                cell: ({value}) => <PaymentStatusLabel status={value}/>
            },
            {
                name: 'date',
                label: 'assisted_registration.date',
                cell: ({row: {original}}) => isoDateConverterWithTime(original.created_at, '-', ':')
            },
            {
                name: 'customer_name',
                label: 'order.name_of_customer',
                cell: ({row: {original}}) => (`${original.delivery_firstname ?? ''} ${original.delivery_lastname ?? ''}`)
            },
            {
                name: 'delivery_email',
                label: 'order.email_address'
            },
            {
                name: 'options',
                label: 'order.options',
                cell: ({row: {original}}) => {
                    if (original.status === -3)
                        return <ResendButton order={original}/>

                    return null
                }
            }
        ],
        config: {
            autoRefetch: true,
            autoRefetchTimeout: 20000,
            hiddenColumns: [isEnabledByMarket('hidePaymentStatusReseller') ? 'payment_status' : null],
        },
        params: {...ordersFilter.rawData}
    })

    return {
        noMachineOrdersTable,
        ordersFilter
    }
}