import {Button} from "../../../../components/buttons/_index";
import Routes from "../../../../utils/Routes";
import {useNavigation} from "../../../../misc/Hooks";
import {getDate, shortText} from "../../../../misc/Helpers";
import {useEffect, useState} from "react";
import {useTranslation} from "../../../../hooks";
import {stripHtmlTags} from "../../../../helpers";
import {PriorityIcon} from "./index";


export default function MessagePreview({item, ...props}) {
    const {t} = useTranslation()
    const navigation = useNavigation()
    const [animate, setAnimate] = useState(false)

    useEffect(() => {
        setAnimate(true)
        setTimeout(() => {
            setAnimate(false)
        }, 600)
    }, [item])

    return (
        <div className={`messagePreview ${animate ? '-animateNew' : ''} ${!!item.important_messages_count && '-priority-high'}`}>
            <h1
                className="messagePreview__title par-7"
                dangerouslySetInnerHTML={{__html: item.thread_title}}
            />
            <p className="messagePreview__date par-2">
                {getDate(item.last_message?.updated_at)} - {item.last_message?.bo_author || item.last_message?.reseller_author}
            </p>
            <div className="messagePreview__body">
                <p
                    className="messagePreview__text par-5"
                    dangerouslySetInnerHTML={{__html: shortText(stripHtmlTags(item.last_message?.thread_message_text), 350).text}}
                />
                <div className="messageItem__infoIcons">
                    {!!item.important_messages_count && (
                        <PriorityIcon priority="high"/>
                    )}
                    {!!item.messages_count && (
                        <div className="messageItem__infoIcon -primary">{item.messages_count}</div>
                    )}
                </div>
            </div>

            <div className="messagePreview__footer">
                <Button
                    label={t('message_item.read_more')}
                    href={navigation(Routes.V2_RESELLER_MESSAGE, item.id_thread)}
                />
            </div>

        </div>
    )
}
