import {useState} from "react";
import {useNavigationWithoutLang} from "../misc/Hooks";

export default function useQrCodeModal(routeName) {
    const navigation = useNavigationWithoutLang()
    const [modalVisible, setModalVisible] = useState(false)
    const [qrCodeUrl, setQrCodeUrl] = useState(null)
    const [linkTime, setLinkTime] = useState(null)


    const showQrCode = (uuid, lang, paymentLinkTime = null) => {
        if (!uuid) return

        const url = `${window.location.origin}/${lang}` + navigation(routeName, uuid)
        setQrCodeUrl(url)
        setLinkTime(paymentLinkTime)
        setModalVisible(true)
    }


    return {
        modalVisible,
        setModalVisible,
        qrCodeUrl,
        showQrCode,
        linkTime
    }
}
