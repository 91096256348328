import CartSummaryBody from "./components/CartSummaryBody";
import CartSummaryFooter from "./components/CartSummaryFooter";
import {useMemo} from "react";
import {makeClassName} from "../../helpers";


export default function CartSummary({
    disableEdit,
    disableFooter,
    summaryRoute,
    onContinue,
    customData,
    hidePlusMinusButtons,
    scrollable = true,
}) {
    const className = useMemo(() => makeClassName('cartSummaryNew', {
        '-scrollable': scrollable,
    }), [scrollable])

    return (
        <div className={className}>
            <CartSummaryBody
                disableEdit={disableEdit}
                customData={customData}
                hidePlusMinusButtons={hidePlusMinusButtons}
            />
            <CartSummaryFooter
                disableFooter={disableFooter}
                summaryRoute={summaryRoute}
                onContinue={onContinue}
                customData={customData}
            />
        </div>
    )
}
