import React from "react";
import {useNavigation} from "../../misc/Hooks";
import {Banner, ShopLogo} from "../etrade/components";
import {Step} from "./components";
import {Config, Routes} from "../../utils/_index";
import {useSelector} from "react-redux";
import {useModuleContext} from "../../hooks";
import {StepsHeader} from "../../components/_index";
import BaseStepsFunnel from "../../components/stepsFunnel";

export default function Funnel() {
    const {storeName} = useModuleContext();
    const navigation = useNavigation()
    const store = useSelector(state => ({
        data: state[storeName]?.data,
    }))

    return (
        <BaseStepsFunnel
            sessionTime={Config.ETRADE_SESSION_TIME}
            homeRoute={navigation(Routes.ETRADE_REOPEN)}
            summaryRoute={Routes.ETRADE_REOPEN_SUMMARY}
            StepComponent={Step}
            HeaderComponent={props => (
                <StepsHeader
                    {...props}
                    secondLogo={() => (
                        <ShopLogo
                            gallery={store.data?.etrade_gallery}
                            url={store.data?.etrade_shop_url}
                        />
                    )}
                    disableEdit
                    alertsDisabled
                />
            )}
            HeaderBannerComponent={() => (
                <div className="wrapper -xxl --mb4">
                    <Banner gallery={store.data?.etrade_gallery}/>
                </div>
            )}
        />
    )
}
