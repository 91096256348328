import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react'
import {persistor, store} from './app/redux/store';
import {Navigation} from "./app/misc/_index";
import Modal from "react-modal";
import smoothscroll from 'smoothscroll-polyfill';
import Env from "./app/utils/Env";
import {QueryClient} from "@tanstack/react-query";
import {QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import ReactTooltip from "react-tooltip";
import React from "react";
import {BrowserRouter} from "react-router-dom";
import * as validatorAlpha from 'validator/lib/alpha';
import {ErrorBoundary} from "react-error-boundary";
import ErrorBoundaryContainer from "./app/utils/ErrorBoundaryContainer";

Modal.setAppElement('#root')

smoothscroll.polyfill();


const tagManagerArgs = {
    gtmId: !!Env.GTM_ID ? Env.GTM_ID : Env.VST_GTM_ID,
    dataLayerName: 'gtmDataObject'
}

if (Env.GTM_ID || Env.VST_GTM_ID) {
    //TagManager.initialize(tagManagerArgs)
    let gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','${tagManagerArgs.dataLayerName}','${tagManagerArgs.gtmId}');`

    let scriptTag = document.createElement('script');
    scriptTag.innerHTML = gtmScript;
    document.getElementsByTagName('head')[0].append(scriptTag)
}

const queryClient = new QueryClient()

validatorAlpha.alpha['ro-RO'] = /^[ăâîșțĂÂÎȘȚ]+$/i
validatorAlpha.alpha['ja-JP'] = /^[ぁ-んァ-ヶｦ-ﾟ一-龠ー・。、]+$/i
validatorAlpha.alphanumeric['ro-RO'] = validatorAlpha.alpha['ro-RO']
validatorAlpha.alphanumeric['ja-JP'] = validatorAlpha.alpha['ja-JP']

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>

                <QueryClientProvider client={queryClient}>
                        <BrowserRouter>
                            <Navigation/>
                        </BrowserRouter>
                    <ReactQueryDevtools initialIsOpen={false}/>
                    <ReactTooltip place="top" effect="solid" multiline html/>
                </QueryClientProvider>

            </PersistGate>
        </Provider>
    );
}




export default App;
