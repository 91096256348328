



const initialState = {
    shop: null,
    shopLabel: null,
    firstname: '',
    lastname: '',
    lastVisit: null,
};


const SET_SHOP = 'reseller/setShop';
const SET_SHOP_LABEL = 'reseller/setShopLabel';
const SET_NAME = 'reseller/setName';
const SET_LAST_VISIT = 'reseller/setLastVisit';
const CLEAR_STORE = 'reseller/clearStore';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHOP:
            return {...state, shop: action.shop};
        case SET_SHOP_LABEL:
            return {...state, shopLabel: action.shopLabel};
        case SET_NAME:
            return {
                ...state,
                firstname: action.firstname,
                lastname: action.lastname,
            };
        case SET_LAST_VISIT:
            return {...state, lastVisit: action.lastVisit};
        case CLEAR_STORE: return {...initialState}

        default: return state;

    }
}


export const setShop = (shop) => ({type: SET_SHOP, shop})
export const setShopLabel = (shopLabel) => ({type: SET_SHOP_LABEL, shopLabel})
export const setName = (firstname, lastname) => ({type: SET_NAME, firstname, lastname})
export const setLastVisit = (lastVisit) => ({type: SET_LAST_VISIT, lastVisit})
export const clearStore = () => ({type: CLEAR_STORE})


export default reducer;
