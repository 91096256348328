import {useReseller, useTranslation} from "../hooks";
import {useForm, useWatch} from "react-hook-form";
import {useFormRulesBuilder} from "./hooks";
import React, {useEffect, useMemo, useState} from "react";
import Form from "./Form";
import {EmailInput, FirstnameInput, LastnameInput} from "./presets";
import {FormGroup, FormRow} from "./components";
import {CheckboxController, SelectController, TextInputController} from "./controllers";
import {SelectOption} from "../services";
import {Button} from "../components/buttons/_index";
import {InfoComponent} from "../components/_index";

export default function EditResellerForm({
    onSubmit,
    state,
    userData,
    resellerData,
    alert: alertMessage
}) {
    state = {
        isLoading: false,
        error: null,
        ...state,
    }

    const {t} = useTranslation();
    const reseller = useReseller();
    const [shopsOptionsState, setShopsOptionsState] = useState(null)

    const roleOptions = useMemo(() => ([
        new SelectOption(t('reseller_client.reseller_role.pos_super_user'), 'pos_super_user'),
        new SelectOption(t('reseller_client.reseller_role.pos_user'), 'pos_user'),
    ]), [])

    const ncaRoleOptions = useMemo(() => ([
        new SelectOption(t('reseller_client.reseller_role.nca_user'), 'nca_user')
    ]), [])

    const supervisorOptions = useMemo(() => (
        userData?.parent_options?.map(p => (new SelectOption(`${p.reseller_firstname} ${p.reseller_lastname}`, p.id_reseller)))
    ), [userData?.parent_options])

    const disabledInputs = useMemo(() => (
        reseller.user.reseller_role !== 'ka_super_user' && reseller.user.reseller_role !== 'nca_super_user'
    ), [reseller.user]);




    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            firstname: resellerData?.reseller_firstname ?? '',
            lastname: resellerData?.reseller_lastname ?? '',
            email: resellerData?.reseller_email ?? '',
            role: reseller.isNespressoWorker
                ? ncaRoleOptions.find(r => r.value === resellerData?.reseller_role)
                : roleOptions.find(r => r.value === resellerData?.reseller_role) ?? null,
            supervisor: supervisorOptions.find(s => s.value === resellerData.id_parent) ?? null,
            status: Boolean(resellerData.reseller_status),
            number: resellerData.reseller_number
        }
    })

    const supervisorWatch = useWatch({control: formMethods.control, name: 'supervisor'})
    const roleWatch = useWatch({control: formMethods.control, name: 'role'})
    const shopWatch = useWatch({control: formMethods.control, name: 'shop'})

    const shopOptions = useMemo(() => {
        const shops = userData.parent_options.find(p => p.id_reseller === resellerData.id_parent)?.reseller_shops;
        return shops?.map(s => new SelectOption(s.shop_title, s.id_shop))
    }, [userData, resellerData, supervisorWatch])

    const formRules = useFormRulesBuilder({
        'firstname': b => b.required().minLength(2),
        'lastname': b => b.required().minLength(2),
        'number': b => b.required().maxLength(255).integer(),
        'email': b => b.required().email(),
        'supervisor': b => b.required(),
        'role': b => b.required(),
        'shop': b => b.required(),

    }, formMethods)

    const handleSubmit = (data) => {
        if (typeof onSubmit !== 'function') return
        onSubmit(data)
    }

    const onChangeSupervisor = (data) => {
        const newParent = userData.parent_options.find(p => p.id_reseller === data.value);
        const newShops = newParent.reseller_shops.map(s => (new SelectOption(s.shop_title, s.id_shop)))

        if (roleWatch.value === 'pos_super_user' && resellerData.childs_count) {
            alert(t('reseller_client.error_form_relations'))
        } else {
            if (roleWatch.value === newParent.reseller_role) {
                alert(t('reseller_client.error_form_same_role'))
            } else {
                formMethods.setValue('supervisor', new SelectOption(data.label, data.value))
                if (!newShops.find(s => s.value === shopWatch?.value)) {
                    formMethods.setValue('shop', new SelectOption(newShops[0].label, newShops[0].value))
                }
            }
        }
    }

    const onChangeRole = (data) => {
        if (resellerData.reseller_role === 'pos_super_user') {
            if (resellerData.childs_count) {
                alert(t('reseller_client.error_form_relations'))
            } else {
                if (userData.parent_options.find(s => s.id_reseller === supervisorWatch.value).reseller_role === 'pos_super_user' && data.value === 'pos_super_user') {
                    alert(t('reseller_client.error_form_same_role'))
                } else {
                    formMethods.setValue('role', new SelectOption(data.label, data.value))
                }
            }
        } else {
            if (userData.parent_options.find(s => s.id_reseller === supervisorWatch.value).reseller_role === 'pos_super_user' && data.value === 'pos_super_user') {
                alert(t('reseller_client.error_form_same_role'))
            } else {
                formMethods.setValue('role', new SelectOption(data.label, data.value))
            }
        }
    }

    const onChangeShop = (data) => {
        if (resellerData.childs_count)
            alert(t('reseller_client.error_form_relations'))
        else formMethods.setValue('shop', new SelectOption(data.label, data.value))
    }

    useEffect(() => {
        if (state.error) {
            state.error.forEach((e) => {
                formMethods.setError(e.name, {
                    type: "manual",
                    message: t(e.message)
                })
            })
        } else formMethods.clearErrors()

    }, [state.error])

    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            onSubmit={handleSubmit}
        >

            <FormGroup>
                <FormRow>
                    <FirstnameInput
                        options={{
                            inputProps: {
                                disabled: state.isLoading,
                            }
                        }}
                    />
                    <LastnameInput
                        options={{
                            inputProps: {
                                disabled: state.isLoading,
                            }
                        }}
                    />
                </FormRow>
            </FormGroup>

            <FormGroup>
                <FormRow>
                    <TextInputController
                        name="number"
                        label={t('reseller_client.reseller_number')}
                        options={{
                            inputProps: {
                                disabled: state.isLoading,
                            }
                        }}
                    />
                    <EmailInput
                        options={{
                            inputProps: {
                                disabled: state.isLoading,
                            }
                        }}
                    />
                </FormRow>
            </FormGroup>
            <FormGroup>
                <FormRow>
                    <SelectController
                        name="supervisor"
                        label={t('reseller_client.supervisor')}
                        selectOptions={supervisorOptions}
                        options={{
                            inputProps: {
                                isDisabled: disabledInputs || state.isLoading,
                                onChange: onChangeSupervisor,
                            }
                        }}
                    />
                    <SelectController
                        name="role"
                        label={t('reseller_client.role') || t('role')}
                        selectOptions={reseller.isNespressoWorker ? ncaRoleOptions : roleOptions}
                        options={{
                            inputProps: {
                                isDisabled: disabledInputs || state.isLoading,
                                onChange: onChangeRole
                            }
                        }}
                    />
                    <SelectController
                        name="shop"
                        label={t('reseller_client.shop') || t('shop')}
                        selectOptions={shopOptions}
                        defaultValue={shopOptions?.find(s => s.value === resellerData.reseller_shops.id_shop)}
                        options={{
                            inputProps: {
                                isDisabled: disabledInputs || state.isLoading,
                                onChange: onChangeShop
                            }
                        }}
                    />
                </FormRow>
            </FormGroup>
            <FormGroup>
                <CheckboxController
                    name="status"
                    label={t('reseller_client.status') || t('status')}
                />
            </FormGroup>

            <div className="grid">
                <div>
                    <Button
                        btnType="submit"
                        label={t('reseller_client.save') || t('save')}
                        tooltipStyle={{minWidth: '33%'}}
                        style={{width: '100%'}}
                        isLoading={state.isLoading}
                        disabled={state.isLoading}
                    />
                    <InfoComponent type={alertMessage.type} message={t(alertMessage.message)}/>
                </div>
                <div/>
            </div>
        </Form>
    )
}