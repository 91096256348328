import {OfferSelectionStepScreen} from "../screens";
import {useSeoPage} from "../../../hooks";


export default function OfferSelectionStepContainer() {
    useSeoPage({title: 'no_machine_shop.order.title'})

    return (
        <OfferSelectionStepScreen/>
    )
}
