import '@adyen/adyen-web/dist/adyen.css';
import {useDispatch} from "react-redux";
import {useNavigation} from "../../misc/Hooks";
import {useQuery} from "@tanstack/react-query";
import {getAdyenSessionByOrder} from "../../actions/MainActions";
import {ApiException} from "../../errors";
import * as paymentReducer from "../../redux/paymentReducer";
import AdyenCheckout from "@adyen/adyen-web";
import {useRef} from "react";
import {Preloader} from "../../misc/_index";
import {useHistory} from "react-router-dom";
import {scrollVertically} from "../../misc/Helpers";

export default function Payment({uuid, orderFinalizedUrl}) {
    const dropInContainerRef = useRef(null)
    const history = useHistory()
    const dispatch = useDispatch()
    const navigation = useNavigation()
    const query = useQuery({
        queryKey: ['orderAdyenPaymentSession', uuid],
        queryFn: async () => {
            return await getAdyenSessionByOrder({
                uuid,
                returnUrl: `${window.location.origin}${navigation(orderFinalizedUrl, uuid)}`,
            })
                .then(res => {
                    if (res.response) {
                        throw new ApiException(res.response?.data?.message, res.response?.data)
                    }
                    return res.data?.data
                })
        },
        onSuccess: (data) => {
            dispatch(paymentReducer.setPaymentSession(data))
            configureDropIn(data)
        },
        refetchOnWindowFocus: false,
    })

    async function configureDropIn(res) {
        const configuration = {
            environment: res.environment, // Change to one of the environment values specified in step 4.
            clientKey: res.clientKey, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
            analytics: {
                enabled: true // Set to false to not send analytics data to Adyen.
            },
            session: {
                id: res.id, // Unique identifier for the payment session.
                sessionData: res.sessionData // The payment session data.
            },
            onPaymentCompleted: async (result, component) => {
                console.info(result, component);
                if(result.resultCode === 'Authorised') {
                    setTimeout(() => {
                        history.push(navigation(orderFinalizedUrl, uuid))
                    }, 2000)
                }
            },
            onError: (error, component) => {
                console.error(error.name, error.message, error.stack, component);
            },
            // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
            // For example, this is 3D Secure configuration for cards:
            paymentMethodsConfiguration: {
                card: {
                    hasHolderName: false,
                    holderNameRequired: false,
                    billingAddressRequired: false
                },
            }
        };

        // Create an instance of AdyenCheckout using the configuration object.
        const checkout = await AdyenCheckout(configuration);

        // Create an instance of Drop-in and mount it to the container you created.
        if (dropInContainerRef.current) {
            const dropinComponent = checkout.create('dropin').mount(dropInContainerRef.current);

            scrollVertically(dropInContainerRef.current)
        }
    }

    return (
        <div>
            {query.isLoading ? (
                <Preloader/>
            ) : (
                <div ref={dropInContainerRef}/>
            )}
        </div>
    )
}