import {useEffect} from "react";
import Axios from "../utils/Axios";
import {useLanguage} from "./index";
import {useMutation} from "@tanstack/react-query";
import * as OrderActions from '../actions/OrderActions';
import {ApiException} from "../errors";
import {useHistory, useParams} from "react-router-dom";
import {useNavigation} from "../misc/Hooks";
import Routes from "../utils/Routes";
import {reUploadPop2} from "../actions/OrderActions";

export default function useReuploadPop(){

    const params = useParams()
    const navigation = useNavigation()
    const history = useHistory()

    useEffect(() => {
        orderInfoMutation.mutate()
        }, [])

    const orderInfoMutation = useMutation(async () => {
        const {data} = await OrderActions.getOrderInfo(params.token)
        if(data.status === 'success')
            return data.data
        throw new ApiException(data.messages[0], data)
    }, {
        onError: ({error}) => {
            if(error === 'order_not_exist')
                history.push(navigation(Routes.MACHINE_REGISTRATION_REGISTRATION))
        }
    })

    const reuploadPopMutation = useMutation(async ({file}) => {

        const formData = new FormData();
        formData.append('file', file)

        const {data} = await OrderActions.reUploadPopV2(params.token, formData)

        if(data.status === 'success')
            return data.data
        throw new ApiException(data.messages[0], data)

    })

    return {
        orderInfoMutation,
        reuploadPopMutation
    }
}