import './style.scss'
import {Map, PickupPointDetails, PickupPointInfoWindow, PickupPointsForm, SearchPlacesForm} from "./components";
import {Fragment, useEffect, useRef, useState} from "react";
import * as ReactDOMServer from 'react-dom/server';
import {useCountry, useFormHelper, useGoogleApiLoader, usePermissions, useTranslation} from "../../hooks";
import {getDefaultCoords} from "../../utils/MarketConfig";
import {useQuery} from "@tanstack/react-query";
import * as mainActions from "../../actions/MainActions";
import {ApiException} from "../../errors";
import {usePickupPoint} from "./hooks";
import {MarkerClusterer, GridAlgorithm} from "@googlemaps/markerclusterer";
import {FormGroup} from "../../forms/components";
import {useWatch} from "react-hook-form";

export default function PickupPoints({
    poiTypes,
}) {
    const fetchingTimeoutRef = useRef(null)
    const infoWindowRef = useRef(null)
    const {t} = useTranslation()
    const {setValue, formMethods} = useFormHelper()
    const permissions = usePermissions()
    const {data: country} = useCountry()
    const apiLoader = useGoogleApiLoader({
        apiKey: 'AIzaSyDKjRD_oAPHaPRt2hsEGdm9gdZf0s0JQbc',
        libraries: ['maps', 'places'],
    })
    const [map, setMap] = useState(null)
    const [coords, setCoords] = useState(null)
    const [markers, setMarkers] = useState([])
    const [canFetch, setCanFetch] = useState(true)
    const pickupPoint = useWatch({
        control: formMethods.control,
        name: 'pickupPoint',
    })
    const {getOpeningHours} = usePickupPoint({pickupPoint: pickupPoint?.data})
    const query = useQuery({
        queryKey: ['pickupPoints', coords?.lat(), coords?.lng()],
        queryFn: async () => {
            if(!canFetch) {
                return
            }
            const payload = {
                latitude: coords.lat(),
                longitude: coords.lng(),
                types: poiTypes ?? country.country_enabled_points_of_interest ?? ['PickUpPoint'],
                rangeInMeters: 5000,
            }

            setCanFetch(false)
            // clearTimeout(fetchingTimeoutRef.current)
            // fetchingTimeoutRef.current = setTimeout(() => {
            //     setCanFetch(true)
            // }, 2000)

            return await mainActions.pointsOfInterest(payload)
                .then(res => {
                    if (res.response) {
                        throw new ApiException(res.response?.data?.message, res.response?.data)
                    }
                    return res.data?.data
                })
        },
        onSettled: () => {
            // setCanFetch(true)
            fetchingTimeoutRef.current = setTimeout(() => {
                setCanFetch(true)
            }, 2000)
        },
        onSuccess: (data) => {
            setMarkers(markers => {
                const arr = [...markers?.map(m => m.data), ...data]
                    ?.filter((i, idx, self) => idx === self.findIndex(m => m.id === i.id))
                    ?.map(i => {
                        const isSelected = i.id === pickupPoint?.data?.id
                        const marker = new apiLoader.data.maps.Marker({
                            position: new window.google.maps.LatLng(i.latitude, i.longitude),
                            map,
                            title: i.name,
                            icon: {
                                url: isSelected ? require('../../assets/img/via-michelin_2.png') : require('../../assets/img/via-michelin_1.png'),
                                scaledSize: new window.google.maps.Size(33, 39),
                                isSelected,
                            },
                            id: i.id,
                        })

                        marker.addListener('click', () => {
                            setValue('pickupPoint', i.id)

                            infoWindowRef.current?.open({
                                anchor: marker,
                                map,
                            })
                        })

                        return {
                            marker,
                            data: i,
                        }
                    })

                const markerCluster = new MarkerClusterer({
                    map,
                    markers: arr.map(i => i.marker),
                    algorithm: new GridAlgorithm({
                        gridSize: 70,
                        maxDistance: 70000,
                    })
                });
                return arr
            })
        },
        enabled: !!coords,
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        permissions.requestPermission('geolocation')
    }, [permissions.requestPermission])

    useEffect(() => {
        if (!pickupPoint?.value) return

        if (infoWindowRef.current) {
            const content = ReactDOMServer.renderToString(
                <PickupPointInfoWindow
                    pickupPoint={pickupPoint.data}
                    openingHours={getOpeningHours(pickupPoint.data)}
                    labels={{
                        isClosed: t('pickup_points.is_closed'),
                        type: t(`pickup_points.type.${pickupPoint.data.type}`),
                    }}
                />
            )

            const marker = markers.find(m => m.data.id === pickupPoint.data.id)
            if (marker) {
                const selectedImg = require('../../assets/img/via-michelin_2.png')

                const previousMarker = markers.find(i => i.marker.icon.isSelected)
                if (previousMarker) {
                    previousMarker.marker.setIcon({
                        ...previousMarker.marker.icon,
                        url: require('../../assets/img/via-michelin_1.png'),
                        isSelected: false,
                    })
                }

                marker.marker.setIcon({
                    ...marker.marker.icon,
                    url: selectedImg,
                    isSelected: true,
                })
            }

            infoWindowRef.current.setContent(content)
        }
    }, [JSON.stringify(pickupPoint)])

    useEffect(() => {
        if (!map) return

        infoWindowRef.current = new window.google.maps.InfoWindow({
            // disableAutoPan: true,
        })

        const dragEndListener = map.addListener('dragend', e => {
            setCoords(map.center)
        })
        const zoomEndListener = map.addListener('zoom_changed', e => {
            setCoords(map.center)
        })
        const clickListener = map.addListener('click', e => {
            if (infoWindowRef.current) {
                infoWindowRef.current.close()
            }
        })

        return () => {
            window.google.maps.event.removeListener(dragEndListener)
            window.google.maps.event.removeListener(zoomEndListener)
            window.google.maps.event.removeListener(clickListener)
        }
    }, [map])

    const onSubmitAddress = ({place}) => {
        infoWindowRef.current?.close()

        const coords = getDefaultCoords()
        map.setCenter(getPointMapCenter(place.data.geometry.location))
        map.setZoom(coords[2])

        setCoords(place.data.geometry.location)
    }

    const onChangePickupPoint = (pickupPoint) => {
        infoWindowRef.current?.close()

        const coords = new window.google.maps.LatLng(pickupPoint.data.latitude, pickupPoint.data.longitude)
        const latLng = getPointMapCenter(coords)

        map.setCenter(latLng)

        const defaultCoords = getDefaultCoords()
        map.setZoom(defaultCoords[2])

        setCoords(coords)



        formMethods.setValue('pickupPoint', pickupPoint, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        })
    }

    const onSetMap = (map) => {
        setMap(map)
        setCoords(map.center)
    }

    const setUserLocation = async () => {
        permissions.requestPermission('geolocation')
            .then(async granted => {
                if(granted) {
                    const {lat, lng} = await new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(pos => {
                            resolve({
                                lat: pos.coords.latitude,
                                lng: pos.coords.longitude,
                            })
                        })
                    })

                    const coords = getPointMapCenter(new window.google.maps.LatLng(lat, lng))
                    map.setCenter(coords)
                }
            })
    }

    function getPointMapCenter(coords) {
        const point = latLng2Point(coords, map)
        point.x -= 150

        return point2LatLng(point, map)
    }

    function latLng2Point(latLng, map) {
        var topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast());
        var bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest());
        var scale = Math.pow(2, map.getZoom());
        var worldPoint = map.getProjection().fromLatLngToPoint(latLng);
        return new google.maps.Point((worldPoint.x - bottomLeft.x) * scale, (worldPoint.y - topRight.y) * scale);
    }

    function point2LatLng(point, map) {
        var topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast());
        var bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest());
        var scale = Math.pow(2, map.getZoom());
        var worldPoint = new google.maps.Point(point.x / scale + bottomLeft.x, point.y / scale + topRight.y);
        return map.getProjection().fromPointToLatLng(worldPoint);
    }

    return (
        <Fragment>
            <div className="mapWrapper --mb4">
                <Map
                    apiLoader={apiLoader}
                    map={map}
                    data={query.data}
                    setMap={onSetMap}
                    isLoading={query.isFetching}
                />
                <div className="mapWrapper__form">
                    <FormGroup>
                        <p className="par-2">
                            {t('pickup_points.form_lead')}
                            {' '}
                            {t('pickup_points.form_lead_location1')}
                            {' '}
                            <button
                                type="button"
                                className="link -primary -parentSize"
                                style={{textDecoration: permissions.isDenied ? 'line-through' : undefined}}
                                onClick={setUserLocation}
                                disabled={permissions.isDenied}
                            >{t('pickup_points.form_lead_location_button')}</button>
                            {' '}
                            {t('pickup_points.form_lead_location2')}
                        </p>
                        {permissions.isDenied && (
                            <p className="par-3">{t('pickup_points.form_lead_location_permissions_denied')}</p>
                        )}
                        <SearchPlacesForm
                            apiLoader={apiLoader}
                            map={map}
                            onSubmit={onSubmitAddress}
                        />
                    </FormGroup>
                    <PickupPointsForm
                        data={markers.map(i => i.data)}
                        isFetching={query.isFetching}
                        onChangePickupPoint={onChangePickupPoint}
                    />
                    {!!pickupPoint?.data && (
                        <div>
                            <div className="--mb3"/>
                            <div className="mapWrapper__pickupPointInfo">
                                <PickupPointDetails pickupPoint={pickupPoint.data}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    )
}
