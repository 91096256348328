import React, {Fragment} from "react";
import {useFieldFormat} from "../../hooks";
import {FormGroup} from "../components";
import {PhoneInput} from "./index";

export default function PhonesGroup(props) {
    const {formats} = useFieldFormat()

    return (
        <Fragment>
            <FormGroup>
                <PhoneInput phoneType="phone"/>
            </FormGroup>
            {formats.phoneMobile.isEnabled && (
                <FormGroup>
                    <PhoneInput phoneType="phoneMobile"/>
                </FormGroup>
            )}
            {formats.phoneHome.isEnabled && (
                <FormGroup>
                    <PhoneInput phoneType="phoneHome"/>
                </FormGroup>
            )}
        </Fragment>
    )
}
