import {Config} from "../../../utils/_index";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "../../../misc/Hooks";
import {scrollVertically} from "../../../misc/Helpers";
import useResizeObserver from '@react-hook/resize-observer'
import {useSelector} from "react-redux";
import {CoffeeItem} from "./index";
import useCategoryCupSizes from "../hooks/useCategoryCupSizes";
import {useCartValidation} from "../../../hooks";


export default function CategorySection({index, category, showAdditional, opened, storeName, onClickOpen, ...props}) {
    const ref = useRef(null);
    const sectionRef = useRef(null);
    const store = useSelector(state => ({
        coffeeSizes: state.main.coffeeSizes,
        promotion: state[storeName]?.promotion,
    }))
    const t = useTranslation();
    const [height, setHeight] = useState(0)
    const categoryCupSizes = useCategoryCupSizes()
    const cupSizes = useMemo(() => categoryCupSizes.getAll(category), [categoryCupSizes.getAll, category])

    useEffect(() => {
        setHeight(ref.current.scrollHeight)
    }, [ref])

    useResizeObserver(ref, (entry) => setHeight(entry.target.scrollHeight))
    const {status} = useCartValidation(storeName)

    useEffect(() => {
        let timeout = null
        if(index === opened && sectionRef && document.body.clientWidth <= 576) {
            timeout = setTimeout(() => {
                const categoryBar = document.querySelector('.evCS__categories')?.clientHeight ?? 0;
                scrollVertically(sectionRef.current, categoryBar)
            }, 200)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [opened])

    const handleOpen = () => {
        onClickOpen(index)
    }

    const requiredCategory = useMemo(() => (
        !!status.invalidCategories.find(ic => ic.category.id_category === category.id_category)
    ), [status, category])

    return (
        <div key={index} ref={sectionRef}>
            <div id={`category_${index}`} className={`evCS__section ${opened === index ? '-opened' : ''}`}>
                <h3
                    className="evCS__categoryName"
                    onClick={handleOpen}
                    data-tip={!!requiredCategory ? t('required_category') : ''}
                >
                    {category.category_title}
                    {!!requiredCategory && <span className="requiredCategory">*</span>}


                    {!!(cupSizes.length && (category.parentCategory ? category.parentCategory.category_display_cup_size : category.category_display_cup_size)) && (
                        <span>{' '}({cupSizes.map(s => s.stack_title).join(', ')})</span>
                    )}
                </h3>
                <div
                    className="evCS__categoryDescription"
                    dangerouslySetInnerHTML={{__html: category.category_description}}
                    onClick={handleOpen}
                />

                {!!category.products.length && (
                    <div
                        ref={ref}
                        className={`evCS__products ${getCategoryProductsModifier(category)}`}
                        style={{maxHeight: document.body.clientWidth <= 576 ? `${opened === index ? height : 0}px` : undefined}}
                    >
                        {category.products?.map(product => (
                            <CoffeeItem
                                key={product.promotion_product ? product.promotion_product.id_promotion_product : product.id_product}
                                item={product}
                                cart={props.cart}
                                category={category}
                                onChange={props.onChange}
                                storeName={storeName}
                            />
                        ))}
                    </div>
                )}
            </div>
            {!!(showAdditional && category.promotionCategory?.promotion_category_hide_others && store.promotion?.promotion_type === Config.PROMO_TYPE.EASY_ORDER) && (
                <h3 className="evCS__categoryName --lg">{t('easy_order.select_coffees')}</h3>
            )}
        </div>
    )
}

function getCategoryProductsModifier(cat) {
    if (cat.category_type === Config.COFFEE_TYPE.PLAN_COFFEE
        || cat.category_type === Config.COFFEE_TYPE.PACKAGE
        || cat.category_type === Config.COFFEE_TYPE.ACCESSORY
    ) {
        return '-lg';
    }
    return '';
}
