import {getCitiesByPostcode} from "../../actions/MainActions";
import {useQuery} from "@tanstack/react-query";
import {useMemo} from "react";
import {ApiException} from "../../errors";
import {useLocalizeObject} from "../../misc/Hooks";

export default function useFetchCitiesByPostcode(postcode) {
    const localizeObject = useLocalizeObject()
    const query = useQuery(
        ['citiesByPostcode', postcode],
        async () => {
            const {data} = await getCitiesByPostcode(postcode)
            if(data.status === 'success')
                return data
            throw new ApiException(data.messages[0], data)
        },
        {
            enabled: !!postcode,
            retry: false,
        }
    )
    const selectOptions = useMemo(() => {
        if(query.isSuccess)
            return query.data.data.map(c => ({
                label: localizeObject(c.city_title),
                value: localizeObject(c.city_title),
            }))
        return [];
    }, [query.status])

    return {
        query,
        data: query.isSuccess ? query.data.data : [],
        selectOptions,
    }
}
