import {Button} from "../../../components/buttons/_index";
import React, {Fragment} from "react";
import {Config, Routes} from "../../../utils/_index";
import {useDispatch} from "react-redux";
import {useMarketConfig, useNavigation} from "../../../misc/Hooks";
import {useHistory} from "react-router-dom";
import {useQrCodeModal, useTranslation} from "../../../hooks";
import {TableComponent, useTableFilters} from "../../../components/baseTable";
import {useMachineSubscriptionOrderTable} from "../hooks";
import {isEnabledByMarket} from "../../../utils/MarketConfig";
import {ProcessedButton, ResendButton} from "../components";
import {QrCodeModal} from "../../../components/modals/_index";
import * as subscriptionReducer from "../../../redux/subscriptionReducer";
import {CheckboxController, DatePickerController} from "../../../forms/controllers";
import {isoDateConverter} from "../../../misc/Helpers";

export default function MachineSubscriptionScreen() {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigation = useNavigation()
    const history = useHistory();
    const qrCodeModal = useQrCodeModal(Routes.ORDER_FINISH);
    const marketConfig = useMarketConfig()

    const filterConfig = {
        builder: {
            formConfig: [[
                'slug',
                !!marketConfig?.reseller_allow_to_exceed_show_time ? 'dateFrom' : null,
                !!marketConfig?.reseller_allow_to_exceed_show_time ? 'dateTo' : null,
            ], isEnabledByMarket('hideTodayMachineSubscriptionOrders') ? ['hideOrders'] : null],
            inputComponents: {
                hideOrders: (
                    <CheckboxController
                        name="hideOrders"
                        label={t('hide_orders')}
                    />
                ),
                dateFrom: (
                    <DatePickerController
                        name="dateFrom"
                        label={t('assisted_registration.filters.date_from')}
                    />
                ),
                dateTo: (
                    <DatePickerController
                        name="dateTo"
                        label={t('assisted_registration.filters.date_to')}
                    />
                ),
            },
        },
        delayFields: ['slug']
    }

    function mapFunc(data, prefix){
        if(!data) return {}
        return {
            slug: data[prefix]?.slug,
            hideOrders: !!data[prefix]?.hideOrders,
            dateFrom: data[prefix]?.dateFrom ? isoDateConverter(data[prefix].dateFrom, '-') : undefined,
            dateTo: data[prefix]?.dateTo ? isoDateConverter(data[prefix].dateTo, '-') : undefined,
        }
    }

    const processedFilter = useTableFilters({
        ...filterConfig,
        searchPrefix: "processedFilter",
        mapFunc: (data) => mapFunc(data, 'processedFilter')
    });

    const pendingFilter = useTableFilters({
        ...filterConfig,
        searchPrefix: "pendingFilter",
        mapFunc: (data) => mapFunc(data, 'pendingFilter')
    });

    const optionColumn = {
        name: 'options',
        label: t('order.options'),
        cell: ({row: {original}}) => {
            return original.payment_status >= 1 ? (
                <ProcessedButton idOrder={original.id_order}/>
            ) : (
                <div className="tableOptions">
                    <ResendButton order={original}/>
                    <Button
                        size="sm"
                        label={t('generate_qr_code')}
                        onClick={() => qrCodeModal.showQrCode(original.uuid, original.lang, original.payment_link_time)}
                    />
                </div>
            )
        }
    }

    const processedOrderTable = useMachineSubscriptionOrderTable({
        payload: {
            order_type: ['subscription_machine'],
            id_reseller: [-1],
            status_type: Config.SUBSCRIPTION_STATUS.PROCESSED,
        },
        daysLimit: true,
        prefix: Config.SUBSCRIPTION_STATUS.PROCESSED,
        hiddenColumns: [isEnabledByMarket('hidePaymentStatusReseller') ? 'payment_status' : null, 'promotion_type'],
        columns: [optionColumn],
        params: {...processedFilter.rawData}
    })

    const pendingOrderTable = useMachineSubscriptionOrderTable({
        payload: {
            order_type: ['subscription_machine'],
            id_reseller: [-1],
            status_type: Config.SUBSCRIPTION_STATUS.PENDING,
        },
        daysLimit: true,
        prefix: Config.SUBSCRIPTION_STATUS.PENDING,
        hiddenColumns: [isEnabledByMarket('hidePaymentStatusReseller') ? 'payment_status' : null, 'promotion_type'],
        columns: [optionColumn],
        params: {...pendingFilter.rawData}
    })

    const navToMachineSubscription = () => {
        dispatch(subscriptionReducer.clearStore())
        history.push(navigation(Routes.SUBSCRIPTION))
    }

    return (
        <Fragment>
            <div className="wrapper -xxl">
                <div className="resMS">
                    <div className="resMS__header">
                        <div className="--flex --spaceBetween">
                            <Button
                                type="primary-outline"
                                label={t('register_new_customer')}
                                onClick={navToMachineSubscription}
                            />
                            {/*{isEnabledByMarket('hideTodayMachineSubscriptionOrders') && (*/}
                            {/*<div style={{paddingLeft: '1rem'}}>*/}
                            {/*    <Button*/}
                            {/*        type="primary-outline"*/}
                            {/*        label={t(showTodayPending ? 'hide_orders' : 'show_orders')}*/}
                            {/*        onClick={() => setShowTodayPending(!showTodayPending)}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*)}*/}
                        </div>
                    </div>

                    <TableComponent
                        {...processedOrderTable}
                        title={t('subscription_status.processed')}
                        filters={processedFilter}
                    />
                    <TableComponent
                        {...pendingOrderTable}
                        title={t('subscription_status.in_treatment')}
                        filters={pendingFilter}
                    />

                </div>
            </div>
            <QrCodeModal {...qrCodeModal} />
        </Fragment>

    )
}