import {useEffect, useMemo, useState} from "react";
import {Config} from "../../../utils/_index";

export default function UseCategoryBar(products) {

    const [barCategories, setBarCategories] = useState([])
    const [coffeeLines, setCoffeeLines] = useState([])


    useEffect(() => {
        getPromoCoffeeLines()
    }, [barCategories])

    useEffect(() => {
        getPromoCoffeeLines()
    }, [products.categories])


    useEffect(() => {
        const newCategories = products.categories.filter((cat, idx) => {
            if (products.store.promotion?.promotion_type === Config.PROMO_TYPE.EASY_ORDER
                && !products.store.easyOrderSetup) {
                return false;
            }

            // unlock rest of the categories, if this category is valid
            let isValid = true;
            products.categories.map((c, cIdx) => {
                if (!isValid)
                    return;
                if (cIdx > idx || cIdx === idx)
                    return;

                if (c.promotionCategory?.promotion_category_hide_others)
                    isValid = products.isCategoryValid(c.id_category);
            })

            return isValid;
        })

        products.setShowAdditionalEnabled(!!newCategories.find(c => c.promotionCategory?.promotion_category_is_additional))
        setBarCategories(
            newCategories.filter(cat =>
                !(!products.showAdditional && cat.promotionCategory.promotion_category_is_additional))
        )
    }, [products.categories, products.store.cart, products.store.easyOrderSetup, products.showAdditional])

    function getPromoCoffeeLines(){
        const promoCoffeeLines = products.store.coffeeLines.reduce((arr, cf) => {
            if(products.originalCategories.find(oc => oc.category_coffee_type === cf.stack_value))
                arr.push(cf)

            return arr
        }, [])

        setCoffeeLines(promoCoffeeLines)
    }

    const scrollTo = (idx) => {
        const headerHeight = document.querySelector('.header')?.clientHeight ?? 0;
        const barHeight = document.querySelector('.productsTopBar')?.clientHeight ?? 0;

        const section = document.getElementById(`category_${idx}`)

        if(section){
            let offset = section.getBoundingClientRect().top + document.documentElement.scrollTop;
            offset -= headerHeight + barHeight + 12;

            window.scrollTo({behavior: 'smooth', top: offset})
        }
    }

    return {
        categories: useMemo(() => barCategories.filter(c => c.products?.length), [barCategories]),
        coffeeLines,
        scrollTo,
    }
}