import {useTranslation} from "../../../hooks";
import {CreateFastOrderForm} from "../../../forms";
import {useFastOrder} from "../hooks";
import {InfoComponent} from "../../../components/_index";
import React, {Fragment} from "react";
import {LinkSentModal} from "../../../components/modals/_index";

export default function FastOrderScreen() {
    const {t} = useTranslation()
    const {modalVisible, createFastOrderMutation, onCloseModal, error} = useFastOrder()
    return (
     <Fragment>
         <div className="wrapper">
             <div className="resMS">
                 <div className="resMS__header">
                     <h2 className="heading-2">{t('fast_order.title')}</h2>
                 </div>
                 <CreateFastOrderForm
                     onSubmit={createFastOrderMutation.mutate}
                     state={{
                         isLoading: createFastOrderMutation.isLoading
                     }}
                 />
                 <div className="grid">
                     <InfoComponent type="error" message={t(error)}/>
                     <div/>
                 </div>
             </div>
         </div>

         <LinkSentModal
             visible={modalVisible}
             onClose={onCloseModal}
             text={t('fast_order.modal_text')}
         />
     </Fragment>
)
}