import {Routes} from "../../../utils/_index";
import {useNavigation} from "../../../misc/Hooks";
import {useSelector} from "react-redux";
import {useStepsNavigation} from "./index";
import {useLocation} from "react-router-dom";


/**
 * Validates Order Finish module route / step
 * @param route
 * @type route: string
 * @returns {{lastValidRoute: (*), value: boolean}}
 */
export default function useStepValidation(route) {
    const navigation = useNavigation()
    const location = useLocation()
    const stepsNavigation = useStepsNavigation()
    const store = useSelector(({of}) => ({
        finishOrder: of.finishOrder,
        nespressoMember: of.nespressoMember,
        customer: of.customer,
        canRegister: of.canRegister,
        promotion: of.promotion,
    }))
    const orderUuid = new URLSearchParams(location?.search).get('uuid')

    const response = {
        canShowStep: true,
        lastValidStep: Routes.ORDER_FINISH,
    }

    switch (route) {
        case Routes.ORDER_FINISH_SUMMARY:
            if(orderUuid) {
                response.canShowStep = true
                response.lastValidStep = Routes.ORDER_FINISH_SUMMARY
                break
            }

            if (stepsNavigation.hasRoute(Routes.ORDER_FINISH_SUMMARY)) {
                if(stepsNavigation.hasRoute(Routes.ORDER_FINISH_CUSTOMER)) {
                    response.canShowStep = store.customer && (store.customer.withCustomAddress ? store.customer.customAddress : store.customer.existingAddress) && (store.promotion?.promotion_show_payment_details ? store.customer.paymentDetails?.accountNumber : true)
                }
                if(!store.nespressoMember) {
                    response.canShowStep = false
                    response.lastValidStep = stepsNavigation.prev()
                }
            }
        case Routes.ORDER_FINISH_CUSTOMER:
            if (stepsNavigation.hasRoute(Routes.ORDER_FINISH_CUSTOMER)) {
                if(!store.nespressoMember && !store.canRegister) {
                    response.canShowStep = false
                    response.lastValidStep = stepsNavigation.prev()
                }
            }
        case Routes.ORDER_FINISH_AUTHORIZATION:
        case Routes.ORDER_FINISH:
            if(!store.finishOrder) {
                response.canShowStep = false
                response.lastValidStep = navigation(Routes.ORDER_FINISH_INVALID_TOKEN)
            }
    }

    return {
        value: response.canShowStep,
        lastValidRoute: navigation(response.lastValidStep),
    }
}
