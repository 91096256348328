import Axios from "../utils/Axios";
import {queryParams} from "../misc/Helpers";


export const getById = (id, config = {}) => {
    return Axios.get(`/promotion/${id}`, config);
}

export const getOneById = ({
    id,
    shopId,
    machineId,
    memberNumber,
    resellerLoggedIn,
    customerEmail,
}, config = {}) => {
    const obj = {
        id_shop: shopId,
        id_machine: machineId,
        member_number: memberNumber,
        user_logged: resellerLoggedIn ? 1 : 0,
        customer_email: customerEmail,
    }
    const params = queryParams(obj)
    return Axios.get(`/v2/promotions/${id}${params}`, config);
}

export const getAll = (id_country, id_machine, id_shop, purchaseDate, user_logged = 0, promotionId = 0, paramObj = {}, data = null) => {
    const params = queryParams(paramObj)
    return Axios.post(`/v2/promotions/${id_country}/${id_machine}/${id_shop}/${user_logged}/${purchaseDate}${params}`, data);
}

export const getAllReseller = (id_country, id_machine, id_shop, purchaseDate, user_logged = 0, promotionId = 0, paramObj = {}, data = null) => {
    const params = queryParams(paramObj)
    return Axios.post(`/v2/reseller/promotions/${id_country}/${id_machine}/${id_shop}/${user_logged}/${purchaseDate}${params}`, data);
}

export const tps = (countryId) => {
    return Axios.get(`/promotions-tps/${countryId}`);
}

/**
 *
 * @param params
 * @param params.countryId
 * @param params.machineId
 * @param params.shopId
 * @param params.isExistingMember
 * @param params.purchaseDate
 * @param queryParamObj
 * @param data
 * @returns {Promise<AxiosResponse<null>>}
 */
export const fetchAll = (params, queryParamObj = null, data = null) => {
    return Axios.post(
        `/v2/promotions/${params.countryId}/${params.machineId}/${params.shopId}/${params.isExistingMember}/${params.purchaseDate}${queryParams(queryParamObj)}`,
        data,
    )
}

/**
 *
 * @param params
 * @param params.countryId
 * @param params.machineId
 * @param params.shopId
 * @param params.isExistingMember
 * @param params.purchaseDate
 * @param queryParamObj
 * @param data
 * @returns {Promise<AxiosResponse<null>>}
 */
export const fetchAllReseller = (params, queryParamObj = null, data = null) => {
    return Axios.post(
        `/v2/reseller/promotions/${params.countryId}/${params.machineId}/${params.shopId}/${params.isExistingMember}/${params.purchaseDate}${queryParams(queryParamObj)}`,
        data,
    )
}

export const getFavoriteCoffees = (memberNumber) => {
    return Axios.get(`/v2/promotions/favorite-coffee/${memberNumber}`)
}
