import {useModuleContext, useTranslation} from "../../hooks";
import React from "react";
import {CartSummary} from "./index";
import {useNavigation} from "../../misc/Hooks";
import {useLocation} from "react-router-dom";

export default function CartSideBox({summaryRoute, disableEdit}){

    const {t} = useTranslation()
    const {store, dispatch, reducer} = useModuleContext()

    const {pathname} = useLocation()
    const navigation = useNavigation()

    if(navigation(summaryRoute) === pathname)
        return null

    return (
        <div className={`sideCartBox ${store.cartVisible ? '-visible' : ''}`}>
            <div className={`cartBox2 ${store.cartVisible ? '-visible' : ''}`}>
                <div className="cartBox__header">
                    <span/>
                    <h5 className="cartBox__title">{t('cart.title')}</h5>
                    <button className="cartBox__close" onClick={() => dispatch(reducer.setCartVisible(!store.cartVisible))}>
                        <img src={require('../../assets/img/icons/close.svg').default} alt="X"/>
                    </button>
                </div>

                <CartSummary
                    disableEdit={disableEdit}
                    summaryRoute={summaryRoute}
                />
            </div>
        </div>
    )
}