import {useCivilityOptions, useCountryOptions, useModuleContext, useStackOptions} from "./index";
import {useEffect} from "react";
import {getAddressLabel} from "../services/NespressoMemberAddresses";
import mergeObjects from 'object-merge'
import {mapOrderToCustomer2} from "../misc/Helpers";

export default function useCustomer() {
    const {store, dispatch, reducer} = useModuleContext()
    const {
        customer,
        addresses,
        isNewMember,
        nespressoMember,
    } = store
    const civilityOptions = useCivilityOptions()
    const professionOptions = useStackOptions('professions')
    const regionOptions = useStackOptions('regions')
    const countryOptions = useCountryOptions()

    function setAddress(address, formData) {
        if(typeof address !== 'object')
            address = addresses.find(a => a.addressId?.toString() === address.toString())

        const data = {
            ...formData,
            details: {
                ...customer?.details,
                ...formData?.details,
                ...address,
                memberAddress: {
                    label: getAddressLabel(address),
                    value: address.addressId,
                },
                phone: formData?.existingAddress?.phone,
                city: formData?.existingAddress?.city || formData?.details?.city || customer?.details?.city || address.city,
                postcode: formData?.existingAddress?.postcode || formData?.details?.postcode || customer?.details?.postcode || address.postcode,
                email: nespressoMember.email,
            },
        }

        if(data.withCustomAddress) {
            data.withCustomAddress = false
            data.customAddress = null
        }

        dispatch(reducer.setCustomer(data))
        return data
    }

    function mapOrderToCustomer(order) {
        return mapOrderToCustomer2(
            order,
            civilityOptions,
            professionOptions,
            regionOptions,
            countryOptions,
        )
    }

    return {
        data: customer,
        isNewMember,
        nespressoMember,
        setAddress,
        mapOrderToCustomer,
    }
}
