import React, {useMemo, useRef} from 'react';
import {AuthBox, CartBox2, HeaderStep, LanguageNav} from "./_index";
import CartAlerts from "./CartAlerts";
import {Link} from "react-router-dom";
import Routes from "../utils/Routes";
import {useNavigation, useTranslation} from "../misc/Hooks";
import useResizeObserver from "@react-hook/resize-observer";
import {useReseller} from "../hooks";
import {InfoAlert} from "./Header";


const StepsHeader = (props) => {
    const ref = useRef()
    const t = useTranslation()
    const navigation = useNavigation();
    const {isLoggedIn} = useReseller()
    const step = useMemo(() => {
        return props.steps[props.step]
    }, [props.steps, props.step])

    useResizeObserver(ref, (entry) => {
        const root = document.documentElement
        root.style.setProperty('--headerHeight', `${document.querySelector('.header')?.clientHeight ?? 0}px`)
    })

    return (
        <header ref={ref} className="header">
            <div className="wrapper">
                <div className={`grid ${isLoggedIn ? `-twoCol` : ''}`}>
                    {!isLoggedIn && (
                        <div className="header__box -left">
                            <HeaderStep
                                index={props.step}
                                step={step}
                                currentStepInView={props.currentStepInView}
                            />
                        </div>
                    )}
                    <div className="header__box -center">
                        <Link
                            to={props.homeRoute ?? navigation(isLoggedIn ? Routes.V2_RESELLER : Routes.MACHINE_REGISTRATION_REGISTRATION)}
                            title="NespressoEV"
                        >
                            <span className="header__logo">
                                <img src={require('../assets/img/logo-black.svg').default} alt="Nespresso"/>
                            </span>
                        </Link>
                        {!!props.secondLogo && props.secondLogo()}
                    </div>
                    <div className="header__box -right">


                        {!props.cartDisabled && !props.alertsDisabled && (
                            <CartAlerts storeName={props.storeName ?? 'ev'}/>
                        )}

                        <AuthBox/>
                        <InfoAlert/>
                        {!props.cartDisabled && (
                            <CartBox2
                                storeName={props.storeName ?? 'ev'}
                                summaryRoute={props.summaryRoute ?? Routes.SHOP_DELIVERY_PAYMENT}
                                disableEdit={props.disableEdit}
                            />
                        )}


                        <LanguageNav/>

                    </div>
                </div>
            </div>
        </header>
    );
}


export default StepsHeader;
