import {useFormHelper, useModuleContext, useTranslation} from "../../../hooks";
import {FormGroup, FormRow} from "../../../forms/components";
import {
    CountrySelect,
    MachineDetails,
    PosInput,
    PromotionSelect,
    ReceiptInput,
    TitleSelect,
    TpsSerialNumber, useTps
} from "../index";
import {CheckboxController, DatePickerController, TextInputController} from "../../../forms/controllers";
import {
    AddressLine1Input,
    AddressLine2Input,
    CityInput,
    CountrySelect as CustomDeliveryCountrySelect,
    EmailInput,
    LangSelect,
    PhoneInput,
    PostcodeInput,
    TaxIdInput
} from "../../../forms/presets";
import {useWatch} from "react-hook-form";
import {useCountryConfig} from "../../../misc/Hooks";
import {useEffect} from "react";

export default function MainFormFragment({state}) {
    const {store, reducer, dispatch} = useModuleContext()
    const {formMethods} = useFormHelper()
    const {t} = useTranslation();
    const countryConfig = useCountryConfig()
    const {tpsCategoriesMutation} = useTps()

    const promotion = useWatch({control: formMethods.control, name: 'promotion'})
    const notNespressoMachine = useWatch({control: formMethods.control, name: 'notNespressoMachine'})
    const machinePartner = useWatch({control: formMethods.control, name: 'machinePartner'})
    const companyAddress = useWatch({control: formMethods.control, name: 'companyAddress'})
    const withCustomAddress = useWatch({control: formMethods.control, name: 'withCustomAddress'})
    const customCompanyAddress = useWatch({control: formMethods.control, name: 'customCompanyAddress'})

    useEffect(() => {
        if (!promotion) return

        const promo = store.promotions.find(p => p.id_promotion === Number(promotion?.value))

        if (promo) {
            dispatch(reducer.setPromotion(promo))
            tpsCategoriesMutation.mutate(promotion?.value)
            formMethods.setValue('cart', [])
        }
    }, [promotion])

    return (
        <div className="tpsForm">
            <div className="tpsForm__grid --mb4">
                <div>
                    <h2 className="par-1 --bold">{t('tps.select_country')}</h2>
                    <FormGroup>
                        <CountrySelect/>
                    </FormGroup>
                </div>
            </div>
            <div className="tpsForm__grid">
                <div>
                    <h2 className="par-1 --bold">{t('tps.receipt_info')}</h2>
                    <FormGroup>
                        <PromotionSelect
                            isLoading={tpsCategoriesMutation.isLoading}
                        />
                    </FormGroup>
                    <FormGroup>
                        <TextInputController
                            name="voucherNumber"
                            label={t('tps.voucher_number')}
                        />
                    </FormGroup>
                    <FormGroup>
                        <div className="--mb2">
                            <CheckboxController
                                name="notNespressoMachine"
                                label={t('tps.not_nespresso_machine')}
                            />
                        </div>
                    </FormGroup>

                    {!notNespressoMachine && (
                        <>
                            {!machinePartner?.value && (
                                <FormGroup>
                                    <TpsSerialNumber/>
                                </FormGroup>
                            )}
                            {!!store.machines && (
                                <MachineDetails/>
                            )}
                            <FormGroup>
                                <PosInput/>
                            </FormGroup>
                            <FormGroup>
                                <TextInputController
                                    name="machinePrice"
                                    label={t('tps.machine_price')}
                                />
                            </FormGroup>
                        </>

                    )}

                    <div className="--mb3">
                        <DatePickerController
                            name="purchaseDate"
                            label={t('tps.purchase_date')}
                        />
                    </div>
                    <ReceiptInput isDisabled={state.isDisabled}/>
                </div>
                <div>
                    <h2 className="par-1 --bold">{t('tps.delivery_info')}</h2>
                    <p className="par-2" dangerouslySetInnerHTML={{__html: t('tps.delivery_info_text')}}/>
                    <FormGroup>
                        <TextInputController
                            name="customerNumber"
                            label={t('tps.customer_number')}
                        />
                    </FormGroup>

                    <FormGroup>
                        <CheckboxController
                            name="companyAddress"
                            label={t('tps.company_address')}
                        />
                    </FormGroup>

                    {!!companyAddress ? (
                        <>
                            <FormGroup>
                                <TextInputController
                                    name="companyName"
                                    label={t('tps.company_name')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <TaxIdInput/>
                            </FormGroup>
                        </>
                    ) : (
                        <FormGroup>
                            <TitleSelect/>
                        </FormGroup>
                    )}

                    <FormRow>
                        <FormGroup>
                            <TextInputController
                                name="name"
                                label={t('name')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInputController
                                name="surname"
                                label={t('surname')}
                            />
                        </FormGroup>
                    </FormRow>

                    <FormRow>
                        <FormGroup>
                            <PostcodeInput/>
                        </FormGroup>

                        <FormGroup>
                            <CityInput/>
                        </FormGroup>
                    </FormRow>

                    <FormGroup>
                        <AddressLine1Input
                            name="address"
                            rules="addressLine1"
                        />
                    </FormGroup>

                    <FormGroup>
                        <AddressLine2Input
                            name="address2"
                            rules="addressLine2"
                        />
                    </FormGroup>

                    <FormGroup>
                        <PhoneInput
                            inputLabel={t('tps.phone')}
                        />
                    </FormGroup>

                    <FormGroup>
                        <PhoneInput
                            phoneType="phoneHome"
                            inputLabel={t('tps.phone_home')}
                        />
                    </FormGroup>

                    <FormGroup>
                        <PhoneInput
                            phoneType="phoneMobile"
                            inputLabel={t('tps.phone_mobile')}
                        />
                    </FormGroup>

                    <FormRow>
                        <FormGroup>
                            <EmailInput/>
                        </FormGroup>
                        <FormGroup>
                            <EmailInput
                                name="reEmail"
                                label={t('re_email')}
                            />
                        </FormGroup>
                    </FormRow>
                    <p className="par-4">{t('tps.lang_info')}</p>

                    <FormGroup>
                        <LangSelect/>
                    </FormGroup>

                    <FormGroup>
                        <CheckboxController
                            name="withCustomAddress"
                            label={t('tps.with_custom_address')}
                        />
                    </FormGroup>

                    {!!withCustomAddress && (
                        <div className="--mb4">
                            <FormGroup>
                                <CheckboxController
                                    name="customCompanyAddress"
                                    label={t('tps.company_address')}
                                />
                            </FormGroup>
                            {!!customCompanyAddress ? (
                                <>
                                    <FormGroup>
                                        <TextInputController
                                            name="customCompanyName"
                                            label={t('tps.company_name')}
                                            rules="companyName"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <TaxIdInput
                                            name="customTaxId"
                                            rules="taxId"
                                        />
                                    </FormGroup>
                                </>
                            ) : (
                                <FormGroup>
                                    <TitleSelect
                                        type="customTitle"
                                        // rules="title"
                                    />
                                </FormGroup>
                            )}

                            <FormRow>
                                <FormGroup>
                                    <TextInputController
                                        name="customName"
                                        label={t('name')}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextInputController
                                        name="customSurname"
                                        label={t('surname')}
                                    />
                                </FormGroup>
                            </FormRow>


                            <FormRow>
                                <FormGroup>
                                    <PostcodeInput
                                        name="customPostcode"
                                        rules="postcode"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <CityInput
                                        name="customCity"
                                        rules="city"
                                    />
                                </FormGroup>
                            </FormRow>
                            <FormGroup>
                                <AddressLine1Input
                                    name="customAddress"
                                    rules="addressLine1"
                                />
                            </FormGroup>

                            <FormGroup>
                                <AddressLine2Input
                                    name="customAddress2"
                                    rules="addressLine2"
                                />
                            </FormGroup>

                            {!!countryConfig.other_delivery_phone_enabled && (
                                <>
                                    <FormGroup>
                                        <PhoneInput
                                            inputLabel={t('tps.phone')}
                                            inputName="customPhone"
                                            rules="phone"
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <PhoneInput
                                            phoneType="phoneHome"
                                            inputLabel={t('tps.phone_home')}
                                            inputName="customPhoneHome"
                                            rules="phoneType"
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <PhoneInput
                                            phoneType="phoneMobile"
                                            inputLabel={t('tps.phone_mobile')}
                                            inputName="customPhoneMobile"
                                            rules="phoneMobile"
                                        />
                                    </FormGroup>
                                </>
                            )}

                            <FormGroup>
                                <CustomDeliveryCountrySelect
                                    name="customCountry"
                                    rules="country"
                                />
                            </FormGroup>
                        </div>
                    )}
                </div>
            </div>

        </div>
    )
}