import Provider from "./Provider";
import {Redirect, Route, Switch} from "react-router-dom";
import {useSteps} from "../../hooks/modules/etrade";
import React from "react";
import {useNavigation} from "../../misc/Hooks";
import {Footer, Header} from "../../components/misc/_index";
import {Routes} from "../../utils/_index";
import InvalidTokenScreen from "./screens/InvalidTokenScreen";
import TokenExpiredScreen from "./screens/TokenExpiredScreen";
import {makeStore, ModuleProvider} from "../../hooks";
import {getStepsRoutes} from "../../misc/Helpers";
import StepsFunnel from "./StepsFunnel";
import {AppProvider} from "../../misc/_index";
import {OrderFinalizedEtradeScreen} from "../../screens";

export default function Funnel() {
    const navigation = useNavigation()
    const steps = useSteps()

    const contextValue = makeStore({
        storeName: 'eTrade',
        steps,
    })

    return (
        <AppProvider>
            <ModuleProvider value={contextValue}>
                <Provider>

                    <Switch>

                        <Route
                            exact path={[
                            ...getStepsRoutes(steps),
                        ]}
                            component={StepsFunnel}
                        />
                        <Route exact path={Routes.ETRADE_ORDER_FINALIZED}>
                            <OrderFinalizedEtradeScreen/>
                        </Route>
                        <Route>
                            <div className="app">
                                <Header
                                    storeName="eTrade"
                                    homeRoute={navigation(Routes.ETRADE)}
                                    cartDisabled
                                    alertsDisabled
                                />

                                <div className="main">
                                    <div className="wrapper-bg formContainer">
                                        <Switch>
                                            <Route exact path={Routes.ETRADE_INVALID_TOKEN}
                                                   component={InvalidTokenScreen}/>
                                            <Route exact path={Routes.ETRADE_TOKEN_EXPIRED}
                                                   component={TokenExpiredScreen}/>

                                            <Route path={Routes.ETRADE}>
                                                <Redirect to={navigation(Routes.ETRADE_MACHINE_PLAN)}/>
                                            </Route>
                                        </Switch>
                                    </div>
                                </div>

                                <Footer module="etrade"/>
                            </div>
                        </Route>

                    </Switch>

                </Provider>
            </ModuleProvider>
        </AppProvider>
    )
}
