import React from "react";
import {Step} from "./components";
import Routes from "../../utils/Routes";
import {StepsHeader} from "../../components/_index";
import StepsProvider from "./StepsProvider";
import {Config} from "../../utils/_index";
import BaseStepsFunnel from '../../components/stepsFunnel'
import {useNavigation} from "../../misc/Hooks";
import useFetchPlans, {PlansProvider} from '../../hooks/useFetchPlans'
import {useReseller} from "../../hooks";

export default function StepsFunnel() {
    const {isLoggedIn} = useReseller()
    const navigation = useNavigation()
    const fetchPlans = useFetchPlans()

    return (
        <StepsProvider>
            <PlansProvider value={fetchPlans}>
                <BaseStepsFunnel
                    sessionTime={isLoggedIn ? Config.RESELLER_MACHINE_SUBSCRIPTION_SESSION_TIME : Config.SUBSCRIPTION_SESSION_TIME}
                    homeRoute={navigation(Routes.SUBSCRIPTION_MACHINE)}
                    summaryRoute={Routes.SUBSCRIPTION_SUMMARY}
                    StepComponent={Step}
                    HeaderComponent={props => (
                        <StepsHeader
                            {...props}
                            alertsDisabled
                        />
                    )}
                />
            </PlansProvider>
        </StepsProvider>
    )
}
