import Form from "./Form";
import {useForm} from "react-hook-form";
import {useFocusError, useFormRulesBuilder} from "./hooks";
import {useCountry, useModuleContext, useReseller, useTranslation} from "../hooks";
import {FormGroup} from "./components";
import React from "react";
import {
    BenefitsFormFragment,
    ExistingCustomerFormFragment,
    NewCustomerFormFragment,
    PaymentDetailsFormFragment,
    PaymentMethodConfirmFormFragment,
    PaymentMethodFormFragment,
    SmsPhoneVerificationFormFragment,
    TermsFormFragment
} from "./fragments";
import {Button} from "../components/buttons/_index";
import {TermsInfo} from "../components/misc/_index";
import UserLogin from "../components/UserLogin";
import {InfoComponent} from "../components/_index";
import {RecaptchaInput} from "./presets";

export default function CustomerForm({
    state,
    nespressoMember,
    smsPhoneVerification,
    prevRoute,
    onSubmit,
    options,
}) {
    options = {
        customAddressVisible: true,
        paymentDetailsVisible: false,
        paymentMethodVisible: false,
        paymentMethods: [],
        machinePlan: null,
        benefitFields: null,
        benefitConfig: null,
        termsOptions: null,
        ...options,
    }
    state = {
        isLoading: false,
        status: null,
        error: null,
        ...state,
    }
    const {t} = useTranslation()
    const reseller = useReseller()
    const {store, dispatch, reducer} = useModuleContext()
    const {data: country} = useCountry()
    const formMethods = useForm({
        mode: 'onChange',
        shouldUnregister: true,
        defaultValues: getDefaultValues({...store.customer, benefits: {...store.customer, ...store.optIn}}),
    })
    const formRules = useFormRulesBuilder({}, formMethods)

    useFocusError(document.querySelector('#customerForm'), formMethods)

    const handleSubmit = (data) => {
        onSubmit(data)
    }

    const onClickNavToLogin = () => {
        dispatch(reducer.setNespressoMember(false));
        dispatch(reducer.setIsNewMember(false));
        dispatch(reducer.setPromotion(null));
    }

    return (
        <>
            <Form
                formMethods={formMethods}
                formRules={formRules}
                options={{
                    triggerOnChange: [
                        {target: 'details.email', trigger: 'details.reEmail'},
                        {target: 'details.password', trigger: 'details.rePassword'},
                        {target: 'paymentDetails.accountNumber', trigger: 'paymentDetails.reAccountNumber'},
                    ]
                }}
                state={{
                    isLoading: state.isLoading,
                    isDisabled: state.isLoading,
                }}
                onSubmit={handleSubmit}
            >
                <div id="customerForm" style={{maxWidth: 575}}>
                    <p className="par-1 --bold">{t('ev_customer_information.your_details')}</p>

                    <div className="--mb4">
                        {nespressoMember ? (
                            <ExistingCustomerFormFragment
                                nespressoMember={nespressoMember}
                                options={options}
                            />
                        ) : (
                            <NewCustomerFormFragment
                                options={options}
                            />
                        )}
                    </div>

                    {!!options.paymentDetailsVisible && (
                        <div className="--mb4">
                            <PaymentDetailsFormFragment prefix="paymentDetails"/>
                        </div>
                    )}

                    <div className="--mb4">
                        <BenefitsFormFragment
                            prefix="benefits"
                            userData={nespressoMember?.consents}
                            fields={options.benefitFields}
                            config={options.benefitConfig}
                        />
                    </div>

                    <TermsInfo/>

                    <div className="--mb4">
                        <TermsFormFragment prefix="terms" options={options.termsOptions}/>
                    </div>

                    <div className="--mb4">
                        {options.paymentMethodVisible && (
                            <>
                                <PaymentMethodFormFragment
                                    paymentMethods={options.paymentMethods}
                                />

                                <PaymentMethodConfirmFormFragment
                                    machinePlan={options.machinePlan}
                                    paymentMethods={options.paymentMethods}
                                />
                            </>
                        )}

                        <div className="--mb4">
                            <SmsPhoneVerificationFormFragment {...smsPhoneVerification}/>
                        </div>

                        {!nespressoMember && (
                            <FormGroup>
                                <RecaptchaInput/>
                            </FormGroup>
                        )}
                    </div>

                    <div className="step__nav">
                        {!!prevRoute && (
                            <Button
                                type="primary-outline"
                                label={t('back')}
                                href={prevRoute}
                                disabled={state.isLoading}
                            />
                        )}
                        <Button
                            btnType="submit"
                            type="primary"
                            label={state.isLoading ? t(`customer_form.status.${state.status}`) || '...' : (!nespressoMember && !reseller.user ? t('save_my_data') : t('continue'))}
                            disabled={state.isLoading}
                            isLoading={state.isLoading}
                        />
                    </div>
                    {!!Object.keys(formMethods.formState.errors).length && (
                        <InfoComponent type="warn" message={t('fill_all_required_fields')} liteShadow/>
                    )}

                    {!!state.error && !state.loginFormVisible && (
                        <InfoComponent type="error" message={state.error} liteShadow/>
                    )}

                    {!!(t('creating_account_loading_text') && state.isLoading && !nespressoMember) && (
                        <InfoComponent type="info" message={t('creating_account_loading_text')} liteShadow/>
                    )}
                </div>
            </Form>

            {state.loginFormVisible && !nespressoMember && (
                <div className="grid" style={{marginTop: '2rem'}} id="existingUserLoginForm">
                    <div className="col">
                        <InfoComponent type={'error'} message={state.error} liteShadow customClassName={"--mb4"}/>
                        {state.userCanLogin ? (
                            <UserLogin/>
                        ) : (
                            <Button
                                type="primary"
                                label={t('error.account_exists_please_log_in_button')}
                                onClick={onClickNavToLogin}
                            />
                        )}
                    </div>
                    <div className="col"/>
                </div>
            )}
        </>
    )
}

function getDefaultValues(customer) {
    if(!customer) {
        return {}
    }

    const data = {...customer}

    if(customer.customAddress?.memberAddress) {
        if(!data.existingAddress)
            data.existingAddress = {}
        data.existingAddress.memberAddress = getSelectOption(customer.customAddress.memberAddress)
        data.withCustomAddress = false
        delete data.details
        delete data.customAddress
    }
    else if(customer.details?.memberAddress) {
        if(!data.existingAddress)
            data.existingAddress = {}
        data.existingAddress.memberAddress = getSelectOption(customer.details.memberAddress)
        delete data.details
    }

    function getSelectOption(value) {
        if(!value && typeof value !== 'number')
            return null
        if(typeof value === 'object')
            return {...value}
        return value
    }

    return {
        ...data,
        smsPhoneVerificationCode: undefined,
    }
}
