import React, {useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import {MyDatePicker, MySelect} from "../../forms/inputs/_index";
import {useTranslation} from "../../../misc/Hooks";
import {Button} from "../../buttons/_index";
import {Link} from "react-router-dom";
import {useLanguage} from "../../../hooks";

const EasyOrderSetupForm = (props) => {
    const t = useTranslation();
    const currentLanguage = useLanguage()
    const {
        control,
        handleSubmit,
        watch,
        formState,
    } = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            frequency: props.data?.frequency ?? null,
            nextShipment: props.data?.nextShipment ?? null,
        }
    });

    const {
        errors,
    } = formState;

    const frequency = watch('frequency');
    const nextShipment = watch('nextShipment');
    const [dates, setDates] = useState([]);
    const [isDisabled, setIsDisabled] = useState(props.data);


    useEffect(() => {
        if (props.data) {
            setIsDisabled(true);
        }
    }, [props.data])


    useEffect(() => {
        getDates();
    }, [frequency, nextShipment])


    const rules = {
        frequency: {
            required: {value: true, message: t('validation.required')},
        },
        nextShipment: {
            required: {value: true, message: t('validation.required')},
        },
    }


    const getOptions = () => {
        const arr = [];
        props.frequencies?.map(i => {
            arr.push({
                label: i.label,
                value: i.id,
            })
        })

        return arr.sort((a, b) => {
            return a.value.localeCompare(b.value, undefined, {numeric: true, sensitivity: 'base'})
        });
    }


    const getDates = () => {
        if (!frequency || !nextShipment)
            return;

        const freqObj = props.frequencies.find(i => i.id === frequency.value)
        if (!freqObj)
            return;

        const arr = [];
        for (let i = 0; i < 3; i++) {
            const date = new Date(nextShipment);

            if (i > 0) {
                switch (freqObj.frequency.timeUnit) {
                    case "MONTHS":
                        date.setMonth(date.getMonth() + (freqObj.frequency.quantity * i));
                        break;
                    case "WEEKS":
                        date.setDate(date.getDate() + (freqObj.frequency.quantity * i * 7));
                        break;
                    case "DAYS":
                        date.setDate(date.getDate() + (freqObj.frequency.quantity * (i + 1)));
                        break;
                }
            }


            arr.push(Intl.DateTimeFormat([currentLanguage], {
                month: "short",
                day: "2-digit",
                year: "numeric",
            }).format(date));
        }
        setDates(arr);
    }

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1)

    return (
        <>
            <form onSubmit={handleSubmit(props.onSubmit)}>

                <p className="evCS__categoryName --lg" dangerouslySetInnerHTML={{__html: t('easy_order.title')}}/>


                <div className="grid --mb4">
                    <div>
                        <p className="par-2 --semiBold" dangerouslySetInnerHTML={{__html: t('easy_order.text')}}/>
                        <div className="">
                            <Controller
                                name="frequency"
                                control={control}
                                rules={rules.frequency}
                                render={({field, fieldState}) => (
                                    <MySelect
                                        value={field.value}
                                        label={t('easy_order.frequency')}
                                        options={getOptions()}
                                        error={fieldState.error}
                                        onChange={field.onChange}
                                        disabled={isDisabled}
                                    />
                                )}
                            />

                            <Controller
                                name="nextShipment"
                                control={control}
                                rules={rules.nextShipment}
                                render={({field, fieldState}) => (
                                    <MyDatePicker
                                        value={field.value}
                                        error={fieldState.error}
                                        label={t('easy_order.next_shipment')}
                                        min={tomorrow}
                                        onChange={field.onChange}
                                        disabled={isDisabled}
                                    />
                                )}
                            />
                        </div>

                        <div className="easyOrderForm__text">
                            <p className="par-4" dangerouslySetInnerHTML={{__html: t('easy_order.text2')}}/>
                        </div>
                    </div>
                    <div className="easyOrderForm__summary">
                        {!!dates?.length && (
                            <>
                                <p className="par-2 --semiBold --mb3"
                                   dangerouslySetInnerHTML={{__html: t('easy_order.text3')}}/>
                                <ul>
                                    {dates?.map((d, idx) => (
                                        <li key={idx}>{d}</li>
                                    ))}
                                    <li>...</li>
                                </ul>
                            </>
                        )}
                    </div>
                </div>


                <div className="step__nav --mb4">
                    {isDisabled ? (
                        <Button
                            type="primary-outline"
                            label={t('edit')}
                            onClick={() => setIsDisabled(false)}
                        />
                    ) : (
                        <>
                            {!props.data && (

                                    <Button
                                        type="primary-outline"
                                        label={t('back')}
                                        href={props.stepNavigation.prev()}
                                    />

                            )}
                            <Button
                                btnType="submit"
                                type="primary"
                                label={t(props.data ? 'easy_order.save' : 'continue')}
                                disabled={isDisabled}
                            />
                        </>
                    )}

                </div>

            </form>
        </>
    );
}


export default EasyOrderSetupForm;
