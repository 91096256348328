import {SelectController} from "../../../forms/controllers";
import {useCivilityOptions, useFormHelper, useModuleContext, useTranslation} from "../../../hooks";
import {useEffect, useState} from "react";
import {mapStackToOptions} from "../../../misc/Helpers";
import {useWatch} from "react-hook-form";

export default function TitleSelect({type = 'title'}) {
    const {store} = useModuleContext()
    const {formMethods} = useFormHelper()
    const companyTitlesOptions = mapStackToOptions(store.companyTitles?.length ? store.companyTitles : store.civilities).sort((a, b) => Number(a.value) < Number(b.value) ? -1 : 1)
    const civilityOptions = useCivilityOptions();
    const {t} = useTranslation()

    const [titleOptions, setTitleOptions] = useState(store.data?.companyAddress ? companyTitlesOptions : civilityOptions.options)
    const [customTitleOptions, setCustomTitleOptions] = useState(store.data?.customCompanyAddress ? companyTitlesOptions : civilityOptions.options)

    const companyAddress = useWatch({control: formMethods.control, name: 'companyAddress'})
    const customCompanyAddress = useWatch({control: formMethods.control, name: 'customCompanyAddress'})

    useEffect(() => {
        formMethods.setValue('title', null);
        setTitleOptions(companyAddress ? companyTitlesOptions : civilityOptions.options)
    }, [companyAddress])

    useEffect(() => {
        formMethods.setValue('customTitle', null);
        setCustomTitleOptions(customCompanyAddress ? companyTitlesOptions : civilityOptions.options)
    }, [customCompanyAddress])

    return (
        <>
            {type === 'title' ? (
                <SelectController
                    name="title"
                    label={t('title')}
                    selectOptions={titleOptions}
                    options={{
                        nullable: true
                    }}
                />
            ) : (
                <SelectController
                    name="customTitle"
                    label={t('title')}
                    selectOptions={customTitleOptions}
                    options={{
                        nullable: true
                    }}
                />
            )}
        </>
    )
}