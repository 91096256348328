import {FormFragmentBenefits} from "../forms/fragments";
import React, {useEffect} from "react";
import {useMarketConfig} from "../../misc/Hooks";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "../../hooks";

export default function PromoModalForm({promoModal}) {
    const marketConfig = useMarketConfig();
    const {t} = useTranslation();

    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            benefits: {
                email: promoModal.modalCurrentBenefits.marketing_email,
                mobile: promoModal.modalCurrentBenefits.marketing_mobile,
                phone: promoModal.modalCurrentBenefits.marketing_phone,
                post: promoModal.modalCurrentBenefits.marketing_post,
                socialMedia: promoModal.modalCurrentBenefits.marketing_social_media
            },
        }
    });

    useEffect(() => {
        promoModal.onChangeBenefit({
            email:  !!formMethods.watch('benefits.email'),
            mobile:  !!formMethods.watch('benefits.mobile'),
            phone:  !!formMethods.watch('benefits.phone'),
            post:  !!formMethods.watch('benefits.post'),
        })
    }, [
        formMethods.watch('benefits.email'),
        formMethods.watch('benefits.mobile'),
        formMethods.watch('benefits.phone'),
        formMethods.watch('benefits.post')
    ])

    return (
        <FormProvider {...formMethods}>
            <div className="--mb2_5">
                <FormFragmentBenefits
                    config={{
                        email: promoModal.store.plan ? promoModal.store.plan.product_plan_marketing_email_visible : promoModal.store.promotion?.promotion_marketing_email_visible,
                        mobile: promoModal.store.plan ? promoModal.store.plan.product_plan_marketing_mobile_visible : promoModal.store.promotion?.promotion_marketing_mobile_visible,
                        phone: promoModal.store.plan ? promoModal.store.plan.product_plan_marketing_phone_visible : promoModal.store.promotion?.promotion_marketing_phone_visible,
                        post: promoModal.store.plan ? promoModal.store.plan.product_plan_marketing_post_visible : promoModal.store.promotion?.promotion_marketing_post_visible,
                        socialMedia: promoModal.store.plan ? promoModal.store.plan.product_plan_marketing_social_media_visible : promoModal.store.promotion?.promotion_marketing_social_media_visible,
                        required: promoModal.store.plan ? promoModal.store.plan.product_plan_marketing_required : promoModal.store.promotion?.promotion_marketing_required,
                        selectAllEnabled: promoModal.store.plan ? promoModal.store.plan.product_plan_marketing_select_all_benefits_enabled : promoModal.store.promotion?.promotion_marketing_select_all_benefits_enabled,
                        configOptionsVisibility: marketConfig?.user_marketing_options_visibility,
                        userConsents: promoModal.store.nespressoMember?.consents,
                        popupConfig: promoModal.modalBenefits
                    }}
                />
            </div>
        </FormProvider>
    )
}