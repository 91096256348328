import getObjectValue from "../helpers/getObjectValue";

function CanRegisterPayload({
    recaptchaToken,
    firstname,
    lastname,
    postcode,
    city,
    phone,
    email,
    smsCode,
    memberNumber,
    rawData,
    uuid,
}) {
    return {
        recaptcha_token: recaptchaToken,
        name: firstname,
        surname: lastname,
        postcode,
        city,
        phone,
        email,
        sms_code: smsCode,
        memberNumber,
        rawData,
        uuid,
    }
}

export function CanRegisterPayloadParams({
    promotionId,
    machinePlanId,
    stayHomeId,
    mobuleName,
}) {
    if(promotionId)
        machinePlanId = undefined
    return {
        id_promotion: promotionId,
        id_plan_machine: !promotionId ? machinePlanId : undefined,
        id_stay_home: stayHomeId,
        mobule: mobuleName,
    }
}


export function canRegisterPayloadFromCustomerForm({data, uuid}) {
    if(!data) return null
    if(!data.details) return null

    const payload = {
        recaptchaToken: data.recaptchaToken,
        smsCode: data.smsPhoneVerificationCode,
        memberNumber: data.nespressoMember?.memberNumber,
        rawData: {
            ...data,
            details: {
                ...data?.details,
                password: undefined,
                rePassword: undefined,
            }
        },
        uuid,
    }
    return new CanRegisterPayload({
        ...payload,
        firstname: data.details.firstname,
        lastname: data.details.lastname,
        postcode: getObjectValue(data.details.postcode, 'label'),
        city: getObjectValue(data.details.city, 'label'),
        phone: data.details.phone,
        email: data.details.email,
    })
}

export function canAttemptPayloadFromCustomerForm({data, nespressoMember, uuid}) {
    if(!data) return null
    const payload = {
        // recaptchaToken: data.recaptchaToken,
        smsCode: data.smsPhoneVerificationCode,
        memberNumber: nespressoMember?.memberNumber,
        rawData: {
            ...data,
            customAddress: {
                ...data.customAddress,
                city: getObjectValue(data.customAddress?.city, 'label'),
                postcode: getObjectValue(data.customAddress?.postcode, 'label')
            }
        },
        uuid,
    }
    return new CanRegisterPayload({
        ...payload,
        phone: data.withCustomAddress ? data.customAddress.phone : data.existingAddress.phone,
    })
}

