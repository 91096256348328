import {RecaptchaController} from "../controllers";
import React, {useEffect, useState} from "react";
import {useFormContext, useWatch} from "react-hook-form";

export default function RecaptchaInput({...props}) {
    const {control, state} = useFormContext()
    const recaptchaToken = useWatch({
        control,
        name: props.name || 'recaptchaToken'
    })
    const [isVisible, setIsVisible] = useState(true)

    useEffect(() => {
        if (state.isLoading && recaptchaToken) {
            setIsVisible(false)
        } else if (!state.isLoading) {
            setIsVisible(true)
        }
    }, [state.isLoading])

    return (
        // <div hidden={!isVisible}>
            <RecaptchaController
                name="recaptchaToken"
                {...props}
            />
        // </div>
    )
}
