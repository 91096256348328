import {useEffect} from "react";
import {useTranslation} from "./index";
import {removeSpecialChars} from "../misc/Helpers";

export default function useSeoPage({title}){
    const {t} = useTranslation()
    useEffect(() => {
        if(typeof title === "string")
            document.title = [removeSpecialChars(t(title)), t('base_seo_title')]
                .filter(i => i?.trim())
                .join(' - ')
    }, [])
}