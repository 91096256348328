import {SelectController} from "../controllers";
import React, {useMemo} from "react";
import {useCountryOptions, useTranslation} from "../../hooks";
import {isEnabledByMarket} from "../../utils/MarketConfig";

export default function CountrySelect({countryById, ...props}) {
    const {t} = useTranslation()
    const {countryOptions} = useCountryOptions(!!countryById)
    const defaultValue = useMemo(() => {
        if(!isEnabledByMarket('selectDefaultFirstCountry'))
            return null
        return countryOptions[0]
    }, [])

    return (
        <SelectController
            name="country"
            label={t('country')}
            selectOptions={countryOptions}
            defaultValue={defaultValue}
            {...props}
        />
    )
}
