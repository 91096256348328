import {useWatch} from "react-hook-form";
import {useEffect, useState} from "react";
import {PlaceInput} from "./index";
import {useFormHelper} from "../../../hooks";

export default function SearchPlacesForm({apiLoader, map, onSubmit}) {
    const {formMethods} = useFormHelper()
    const [service, setService] = useState(null)
    const place = useWatch({
        control: formMethods.control,
        name: 'place'
    })

    useEffect(() => {
        if(place) {
            onSubmit({place})
        }
    }, [place])

    useEffect(() => {
        if (!map || !apiLoader.data) return

        const service = new apiLoader.data.maps.places.PlacesService(map)
        setService(service)
    }, [map, JSON.stringify(apiLoader.data)])

    return (
        <PlaceInput service={service}/>
    )
}
