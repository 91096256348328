import {useReseller, useTranslation} from "../../../hooks";
import {permissions} from "../../../misc/PermissionsConfig";
import Routes from "../../../utils/Routes";
import {useMarketConfig} from "../../../misc/Hooks";
import {Tile} from "./index";
import {Fragment, useMemo} from "react";

export default function DashboardOptions() {
    const {hasPermission} = useReseller();
    const {resellerModuleIsEnabled} = useMarketConfig();
    const {t} = useTranslation();

    const tiles = [
        {
            permission: permissions.assistedRegistration,
            imageLight: require('../../../assets/img/icons/register_m-ic-light.svg').default,
            imageBold: require('../../../assets/img/icons/register_m-ic.svg').default,
            label: 'reseller_options.assisted_registration',
            route: Routes.V2_RESELLER_ASSISTED_REGISTRATION,
            enabled: resellerModuleIsEnabled('assistedRegistration'),
        },
        {
            permission: permissions.machineSubscription,
            imageLight: require('../../../assets/img/icons/machine-ic-light.svg').default,
            imageBold: require('../../../assets/img/icons/machine-ic.svg').default,
            label: 'reseller_options.machine_subscription',
            route: Routes.V2_RESELLER_MACHINE_SUBSCRIPTIONS,
            enabled: resellerModuleIsEnabled('machineSubscription'),
        },
        {
            permission: permissions.adminAndReports,
            imageLight: require('../../../assets/img/icons/assisted-ic-light.svg').default,
            imageBold: require('../../../assets/img/icons/assisted-ic.svg').default,
            label: 'reseller_options.admin_and_reports',
            route: Routes.V2_RESELLER_ADMIN_REPORTS,
            enabled: resellerModuleIsEnabled('adminAndReports'),
        },
        {
            permission: permissions.noMachineShop,
            imageLight: require('../../../assets/img/icons/register_m-ic-light.svg').default,
            imageBold: require('../../../assets/img/icons/register_m-ic.svg').default,
            label: 'reseller_options.no_machine_shop',
            route: Routes.V2_RESELLER_NO_MACHINE_SHOP,
            enabled: resellerModuleIsEnabled('noMachineShop'),
        },
        {
            permission: permissions.burn,
            imageLight: require('../../../assets/img/icons/block-ic-light.svg').default,
            imageBold: require('../../../assets/img/icons/block-ic.svg').default,
            label: 'reseller_burn.title',
            route: Routes.V2_RESELLER_BURN,
            enabled: resellerModuleIsEnabled('burn'),
        },
        {
            permission: permissions.ax,
            imageLight: require('../../../assets/img/icons/ax-ic-light.svg').default,
            imageBold: require('../../../assets/img/icons/ax-ic.svg').default,
            label: 'reseller_ax.title',
            route: Routes.V2_RESELLER_AX,
            enabled: resellerModuleIsEnabled('ax'),
        },
        {
            permission: permissions.messages,
            imageLight: require('../../../assets/img/icons/icon-big-msg.svg').default,
            imageBold: require('../../../assets/img/icons/icon-msg.svg').default,
            label: 'reseller_messages.title',
            route: Routes.V2_RESELLER_MESSAGES,
            enabled: resellerModuleIsEnabled('messages'),
        }
    ]

    const enabledTiles = useMemo(() => tiles.filter(t => t.enabled && hasPermission(t.permission)), [])

    return (
        <Fragment>
            {!!enabledTiles.length && (
                <div className="resellerOptions__header">
                    <h2 className="heading-2">{t('reseller_options.title')}</h2>
                </div>
            )}
            <div className="resellerOptions__items">
                {enabledTiles.map(t => <Tile key={t.route} item={t}/>)}
            </div>
        </Fragment>

    )
}