import Axios from "../utils/Axios";

export const getPlans = (id_machine = 0, id_country = 0, id_shop = 0) => {
    return Axios.get(`/machine-subscription/plans/${id_machine}/${id_shop}/${id_country}`);
}

export const getPlans2 = ({machineId = 0, countryId = 0, shopId = 0}) => {
    return Axios.get(`/machine-subscription/plans/${machineId}/${shopId}/${countryId}`);
}

export const saveOrder = (data) => {
    return Axios.post(`/machine-subscription/order/save`, data)
}
