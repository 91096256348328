import {useCallback, useMemo} from "react";
import {makeClassName} from "../../../../helpers";
import {useLocalizeObject} from "../../../../misc/Hooks";
import {getImage} from "../../../../misc/Helpers";

export default function ExperienceItem({
    item,
    selectedItem,
    classNames = '',
    onClick,
}) {
    const localizeObject = useLocalizeObject()
    const handleChange = useCallback(() => {
        if(typeof onClick === 'function')
            onClick(item)
    }, [item, onClick])
    const className = useMemo(() => makeClassName('experienceItem', {
        '-selected': item.ax_experience_url === selectedItem?.ax_experience_url,
        '-cursorNone': !onClick,
        [classNames]: classNames,
    }), [item, selectedItem])

    return (
        <button
            className={className}
            onClick={handleChange}
        >
            <img src={getImage(item.ax_experience_gallery)} alt={localizeObject(item.ax_experience_title)} className="experienceItem__thumb"/>
            <p className="experienceItem__label">{localizeObject(item.ax_experience_title)}</p>
        </button>
    )
}
