import {useSelector} from "react-redux";
import {useApiLogger, useLanguage, useReseller} from "./index";
import {useBeforeunload} from "react-beforeunload";

export default function useOnExitPage(storeName) {
    const logger = useApiLogger()
    const currentLanguage = useLanguage()
    const reseller = useReseller()
    const store = useSelector(state => ({
        country: state.main.country,
        uuid: state[storeName]?.uuid,
        isNewMember: state[storeName]?.isNewMember,
        cart: state[storeName]?.cart,
        customer: state[storeName]?.customer,
        machine: state[storeName]?.machine,
        proofOfPurchase: state[storeName]?.proofOfPurchase,
        proofOfPurchaseLogs: state[storeName]?.proofOfPurchaseLogs,
        promotion: state[storeName]?.promotion,
        nespressoMember: state[storeName]?.nespressoMember,
        promotions: state[storeName]?.promotions,
        products: state[storeName]?.products,
        serialNumber: state[storeName]?.serialNumber,
        promoCode: state[storeName]?.promoCode,
        abandonedOrderConsentAccepted: state[storeName]?.abandonedOrderConsentAccepted,
    }))

    useBeforeunload(event => {
        sendLog(event)
    })

    const sendLog = (event = undefined, type = 'BEFOREUNLOAD') => {
        if (!store.uuid || (!store.customer && !store.nespressoMember) || reseller.user)
            return

        const customer = store.customer ? {...store.customer} : {}
        if (customer.details) {
            const {password, rePassword, ...details} = customer.details
            customer.details = details
        }

        const {file, ...proofOfPurchase} = store.proofOfPurchase ?? {}

        const payload = {
            event,
            url: window.location.href,
            lang: currentLanguage,
            module: storeName,
            navigator: {
                userAgent: navigator.userAgent,
                platform: navigator.platform,
                language: navigator.language,
                mediaDevices: navigator.mediaDevices,
                storage: navigator.storage,
            },
            order: {
                countryId: store.country?.id_country,
                isNewMember: store.isNewMember ? 1 : 0,
                nespressoMember: {
                    id: store.nespressoMember?.memberNumber,
                    email: store.nespressoMember?.email,
                    hasSubscription: store.nespressoMember?.haSubscription,
                },
                uuid: store.uuid,
                cart: store.cart,
                customer,
                machineId: store.machine?.id_machine,
                serialNumber: store.serialNumber?.serial_number,
                promoCode: {
                    id: store.promoCode?.id_promo_code,
                    name: store.promoCode?.promo_code_name,
                },
                proofOfPurchase,
                proofOfPurchaseLogs: store.proofOfPurchaseLogs,
                promotionId: store.promotion?.id_promotion,
                promotions: store.promotions?.map(p => p.id_promotion),
                products: store.products?.map(p => p.id_product),
                abandonedOrderConsentAccepted: store.abandonedOrderConsentAccepted ? 1 : 0,
            },
        }

        logger.info({
            type,
            uuid: store.uuid,
            payload: JSON.stringify(payload)
        })
    }

    return {sendLog}
}
