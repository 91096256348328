import React, {useEffect} from 'react';
import Modal from 'react-modal';


export default (props) => {
    const customStyles = {
        overlay: {
        },
        content: {
            maxWidth: props.small ? `35rem` : undefined,
        }
    };

    useEffect(() => {
        if(props.visible) {
            document.body.style.overflow = 'hidden'
        }
        return () => {
            document.body.style.overflow = 'unset'
        }
    }, [props.visible])

    return (
        <Modal
            ref={props.wrapperRef}
            isOpen={props.visible}
            style={customStyles}
            className={props.className ?? ''}
            onRequestClose={props.onClose ?? undefined}
        >
            {!!props.onClose && (
                <button className="modal__close" onClick={props.onClose}>
                    <img src={require('../../assets/img/icons/close.svg').default} alt="X"/>
                </button>
            )}


            {props.children}
        </Modal>
    );
}
