import {Message} from "../components";
import {useComments, useMessage} from "../hooks";
import {useParams} from "react-router-dom";

export default function MessageScreen() {
    const params = useParams()
    const message = useMessage(params.id)
    const comments = useComments(params.id)

    return (
        <Message
            message={message}
            comments={comments}
        />
    )
}
