import React from "react";
import {Step} from "./components";
import Routes from "../../utils/Routes";
import {Config} from "../../utils/_index";
import BaseStepsFunnel from "../../components/stepsFunnel";
import useFetchPromotions, {PromotionsProvider} from "../../hooks/useFetchPromotions";
import useFetchCategories, {CategoriesProvider} from "../../hooks/useFetchCategories";

export default function StepsFunnel() {
    const fetchPromotions = useFetchPromotions()
    const fetchCategories = useFetchCategories()

    return (
        <PromotionsProvider value={fetchPromotions}>
            <CategoriesProvider value={fetchCategories}>
                <BaseStepsFunnel
                    sessionTime={Config.EV_SESSION_TIME}
                    summaryRoute={Routes.NO_MACHINE_SHOP_SUMMARY}
                    StepComponent={Step}
                />
            </CategoriesProvider>
        </PromotionsProvider>
    )
}
