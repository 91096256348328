import {useParams} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import {ApiException} from "../../../errors";
import * as authActions from "../../../actions/AuthorizationActions";
import {useEffect, useState} from "react";

export default function useResellerVerification(){

    const {status, token, parent_token} = useParams();
    const [statusVerification, setStatusVerification] = useState(null)

    useEffect(() => {
        verificationMutation.mutate()
    }, [status, token, parent_token])

    const verificationMutation = useMutation(
        async () => {
            const {data} = await authActions.verificationReseller(
                status === 'accept' ? 1 : status === 'reject' ? -1 : null,
                token,
                parent_token
            )
            if (data.status === 'success') return data

            throw new ApiException(data.messages[0], data)
        },
        {
            onSuccess: () => setStatusVerification('success'),
            onError: () => setStatusVerification('error')
        }
    )

    return {
        isLoading: verificationMutation.isLoading,
        statusVerification,
        paramsStatus: status,
    }
}