import React, {useEffect} from "react";
import {useModuleContext, useModuleStepsNavigation, usePromotions} from "../../../hooks";
import {OfferSelectionScreen} from "../../machine_registration/screens";
import {uuidv4} from "../../../misc/Helpers";
import * as noMachineShopReducer from "../../../redux/noMachineShopReducer";
import {useHistory} from "react-router-dom";


export default function OfferSelectionStepScreen() {
    const {storeName} = useModuleContext()
    const {promotions, promotion} = usePromotions(storeName)
    const {reducer, dispatch} = useModuleContext()

    useEffect(() => {
       if(promotions.length === 1){
           if(promotions[0].promotion_type === "no_machine_coffee"){
               dispatch(reducer.setPromotion(promotions[0]))
               dispatch(reducer.setUuid(uuidv4()))
           }
       }
    }, [promotions])

    useEffect(() => {
        if(!promotion) return;
        dispatch(reducer.setUuid(uuidv4()))
    }, [promotion])

    return (
        <OfferSelectionScreen/>
    )
}