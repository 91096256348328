import {useFormContext} from "react-hook-form";
import {useContext} from "react";
import {FormRulesContext} from "../forms/misc";
import {useFormFragmentContext} from "../forms/hooks";
import {getDeepValue} from "../helpers";
import getObjectValue from "../helpers/getObjectValue";


export default function useFormHelper(prefix = null) {
    const formMethods = useFormContext()
    const formRulesContext = useContext(FormRulesContext)
    const formFragmentContext = useFormFragmentContext()

    if(!prefix)
        prefix = formFragmentContext.context.prefix

    const getError = (name) => {
        return getDeepValue(formMethods.formState.errors, getName(name))
    }

    const getName = (name) => {
        if(!prefix)
            return name

        if(Array.isArray(prefix))
            return `${prefix.join('.')}.${name}`

        return `${prefix}.${name}`
    }

    const setValue = async (name, value, options = undefined) => {
        formMethods.setValue(getName(name), value, options)
    }

    const setError = async (name, value) => {
        formMethods.setError(getName(name), value)
    }

    const clearErrors = async (name) => {
        formMethods.clearErrors(getName(name))
    }

    const setAndTrigger = async (name, value) => {
        formMethods.setValue(getName(name), value)
        await formMethods.trigger(getName(name))
    }

    const watch = (name) => {
        return formMethods.watch(getName(name))
    }

    const trigger = async (name) => {
        await formMethods.trigger(getName(name))
    }

    const getRules = (value) => {
        if(typeof value === 'object')
            return value
        if(!formRulesContext)
            return null
        return formRulesContext[value]
    }

    const getInputOptions = (options, rules) => {
        if(!rules)
            rules = {}

        return {
            isRequired: getObjectValue(rules.required),
            maxLength: rules['maxLength']?.value,
            ...options,
        }
    }

    return {
        formMethods,
        formFragmentContext,
        formRulesContext,
        getDeepValue,
        error: getError,
        name: getName,
        getError,
        getName,
        getRules,
        getInputOptions,
        setAndTrigger,
        watch,
        trigger,
        setValue,
        setError,
        clearErrors,
    }
}
