import {Footer, Header} from "../components/misc/_index";
import {AppProvider} from "../misc/_index";
import {PickupPoints} from "../components/_index";
import {useForm} from "react-hook-form";
import {FormFragmentProvider} from "../forms/hooks";
import {Form} from "../forms";

export default function TestScreen() {
    const formMethods = useForm()
    return (
        <Layout>
            <h1>TEST SCREEN</h1>
            <hr/>

            <Form formMethods={formMethods}>
                <PickupPoints/>
            </Form>
        </Layout>
    )
}

function Layout({children}) {
    return (
        <AppProvider>
            <div className="app">
                <Header
                    cartDisabled
                />
                <div className="main">
                    <div className="wrapper-bg formContainer">
                        <div className="wrapper">
                            {children}
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </AppProvider>
    )
}
