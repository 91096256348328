import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as mainReducer from "../redux/mainReducer";
import * as MainActions from "../actions/MainActions";
import * as configActions from "../actions/ConfigActions";
import * as countryActions from "../actions/CountryActions";
import Axios from "../utils/Axios";
import Env from "../utils/Env";
import {useNavigation, useNavigationWithoutLang} from "./Hooks";
import {RecaptchaKeyNotFoundModal} from "../components/modals/_index";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {Config, Routes} from "../utils/_index";
import {GTM} from "./_index";
import {useCalleoChat, useCurrentLanguage, useLanguages, useReseller} from "../hooks";
import PagePreloader from "../components/PagePreloader";
import {getMarketStacks, isEnabledByMarket} from "../utils/MarketConfig";
import {registerLocale} from "react-datepicker";
import * as locales from 'date-fns/locale';
import {ResellerAppProvider} from "../components/_index";
import ErrorBoundaryContainer from "../utils/ErrorBoundaryContainer";

let MOUNTED = false;

const AppProvider = (props) => {
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation()
    const navigation = useNavigation();
    const navigationWithoutLang = useNavigationWithoutLang()
    const dispatch = useDispatch();
    const {currentLanguage} = useCurrentLanguage()
    const {languages} = useLanguages()
    const {isLoggedIn, shopId} = useReseller()
    const calleoChat = useCalleoChat()
    const store = useSelector(({main}) => ({
        country: main.country,
        marketConfig: main.marketConfig,
        recaptchaKey: main.recaptchaKey,
    }))
    const [recaptchaKeyNotFoundModalVisible, setRecaptchaKeyNotFoundModalVisible] = useState(false);
    const [loaded, setLoaded] = useState(!!(store.country && store.marketConfig))

    Axios.defaults.headers.common['Language'] = currentLanguage;
    if (shopId) {
        Axios.defaults.headers.get['Shop'] = shopId;
        Axios.defaults.headers.post['Shop'] = shopId;
    }

    useEffect(() => {
        if (Config.RTL_LANGUAGES.includes(currentLanguage)) {
            document.body.classList.add('-rtl');
        }
    }, [])

    useEffect(() => {
        if (store.country) {
            calleoChat.init()
        }
    }, [store.country?.country_chatbot_library_link])

    useEffect(() => {
        languages.map(l => {
            registerLocale(l, locales[l]);
        })
    }, [languages])

    useEffect(() => {
        if (!location.search)
            window.scrollTo(0, 0);
    }, [location.pathname])

    useEffect(() => {
        if (!isEnabledByMarket('disableEcapi') && !store.recaptchaKey && store.marketConfig) {
            // setRecaptchaKeyNotFoundModalVisible(true);
            if (Env.ENV !== 'dev') {
                history.push(navigation(Routes.MAINTENANCE))
            }
        }
    }, [store.recaptchaKey])


    useEffect(() => {
        if (store.country) {
            GTM.configure(store.country.api_market, store.country.country_currency_name)
        }
    }, [store.country])


    useEffect(async () => {
        MOUNTED = true;

        await Promise.all([
            getConfig(),
            getCountry(),
            getStacks(),
        ]).finally(() => {
            if (MOUNTED) {
                setLoaded(true)
            }
        })
        // await getConfig();
        // await getCountry();
        // await getStacks();

        return () => {
            MOUNTED = false;
        }
    }, [])


    const getConfig = async () => {
        return configActions.get().then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    dispatch(mainReducer.setMarketConfig(
                        res.data.config,
                        res.data.vat_modifiers,
                        res.data.recaptcha_key,
                        res.data.display,
                    ));
                }
            } else {
                console.log('Something gone wrong! ', res)
            }
        }).catch(err => {
            history.push(navigation(Routes.MAINTENANCE))
            console.warn('Error: ', err)
        })
    }

    const getCountry = async () => {
        const params = {}

        if (isLoggedIn) {
            params.id_shop = shopId
        }

        return countryActions.getByDomain(params).then(r => {
            const res = r.data;

            Axios.defaults.headers.common['Language'] = currentLanguage;

            if (res.status === 'success') {
                if (MOUNTED) {
                    if (!GTM.isConfigured()) {
                        GTM.configure(res.data.api_market, res.data.country_currency_name)
                    }

                    dispatch(mainReducer.setCountry(res.data));

                    if (navigation(match.path) === (navigation(Routes.MACHINE_REGISTRATION) ? navigation(Routes.MACHINE_REGISTRATION) : '/')) {
                        if (res.data.country_homepage_redirect) {
                            window.location.href = res.data.country_homepage_redirect.replace('_LANG_', currentLanguage);
                        } else {
                            history.push({
                                pathname: navigation(Routes.MACHINE_REGISTRATION_REGISTRATION),
                                search: location.search,
                            })
                        }
                    }
                }
            } else {
                console.log('Something gone wrong! ', res)
            }
        }).catch(err => {
            console.warn('Error: ', err)
        })
    }


    const getStacks = async () => {
        const stacks = getMarketStacks()

        const arr = {}
        await Promise.all(stacks.map(async s => {
            await MainActions.getStackByType(s).then(r => {
                const res = r.data;
                if (res.status === 'success') {
                    arr[s] = res.data
                } else {
                    console.log('Something gone wrong! ', res);
                }
            })
        }))

        dispatch(mainReducer.setStacks(arr));
    }

    if (!loaded)
        return <PagePreloader/>

    return (
        <ErrorBoundaryContainer>
            {isLoggedIn ? (
                <ResellerAppProvider>
                    {props.children}
                </ResellerAppProvider>
            ) : props.children}

            {Env.ENV !== 'prod' && (
                <RecaptchaKeyNotFoundModal
                    visible={recaptchaKeyNotFoundModalVisible}
                />
            )}
        </ErrorBoundaryContainer>
    )
}


export default AppProvider;
