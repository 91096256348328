import React from "react";

/**
 * Hidden input
 * @param inputRef
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function HiddenInput({inputRef, ...props}) {
    return (
        <input
            ref={inputRef}
            type="hidden"
            {...props}
        />
    )
}
