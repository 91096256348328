import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "./Hooks";

export default (props) => {
    const ref = useRef()
    const t = useTranslation();
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        const element = ref.current
        if (element) {
            const {top, right, left} = element.getBoundingClientRect()
            if (document.body.offsetWidth < right) {
                element.style.setProperty('left', `calc(50% - ${right - document.body.offsetWidth + 5}px)`)
            }
        }
    }, [ref])

    const handleChange = (v = value) => {
        if (v % props.step === 0 && v <= props.max && v >= 0) {
            props.onSubmit(v);
        }
    }


    const handleSubmit = () => {
        const v = Number(value) / props.packageQty;
        if (v % props.step === 0 && v <= props.max && v >= 0) {
            handleChange(v)
        }
    }

    const handleKeyDown = (e) => {
        if(e.key !== 'Enter')
            return
        handleSubmit()
    }

    const values = new Array(15).fill(0)
        .map((_, idx) => {
            if (idx > 10)
                return (props.packageQty * 10) + ((props.packageQty * 10) / 2) * (idx - 10)
            return props.packageQty * idx
        })
        .filter(v => v <= props.max * props.packageQty)

    return (
        <>
            <div className="valuesTooltipOverlay" onClick={props.onClose}/>
            <div ref={ref} className="valuesTooltip">
                <div className="valuesTooltip__content">
                    {!!t('values_tooltip.text') && (
                        <div
                            className="valuesTooltip__header"
                            dangerouslySetInnerHTML={{
                                __html: t(
                                    'values_tooltip.text',
                                    {name: 'value', value: props.packageQty * props.step},
                                    {name: 'packageQty', value: props.packageQty * props.step},
                                    {name: 'step', value: props.step},
                                )
                            }}
                        />
                    )}
                    <div className="valuesTooltip__body" style={{paddingTop: t('values_tooltip.text') ? 0 : undefined}}>
                        <div className="valuesTooltip__items">
                            {values.map((v, idx) => (
                                <button
                                    type="button"
                                    key={idx}
                                    onClick={() => handleChange(v / props.packageQty)}
                                >{v}</button>
                            ))}
                        </div>
                        <div className="valuesTooltip__form">
                            <label>{t('value')}</label>
                            <input
                                type="number"
                                value={value}
                                min={0}
                                max={props.max * props.packageQty}
                                step={props.packageQty}
                                onChange={e => setValue(e.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                            <button type="button" onClick={handleSubmit}>{t('ok')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
