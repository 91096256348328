import {useMutation} from "@tanstack/react-query";
import * as orderActions from "../actions/OrderActions";
import {
    AllowedToLoginClearStorageResponseException,
    AllowedToLoginResponseException,
    ApiException,
    LinkingAccountException,
    MemberExistsResponseException,
    NotAllowedResponseException,
    PhoneBlockingRuleException
} from "../errors";
import * as authActions from "../actions/AuthorizationActions";
import {useMemo, useState} from "react";
import {
    canAttemptPayloadFromCustomerForm,
    canRegisterPayloadFromCustomerForm,
    CanRegisterPayloadParams
} from "../services/CanRegisterService";
import {queryParams, scrollVertically} from "../misc/Helpers";
import {
    useCountry,
    useCurrentLanguage,
    useCustomer, useGTM,
    useModuleContext, useOrderData,
    useReseller,
    useSmsPhoneVerification,
    useTranslation
} from "./index";
import {
    nespressoMemberFromCreateAccount,
    nespressoMemberPayloadFromCustomerForm
} from "../services/NespressoMemberService";
import SmsValidationResponseException from "../errors/SmsValidationResponseException";
import {GTM} from "../misc/_index";
import {usePromotionsContext} from "./useFetchPromotions";
import {useMarketConfig} from "../misc/Hooks";
import {useBeforeunload} from "react-beforeunload";
import {
    getAddressLabel,
    nespressoMemberAddressFromCreateAccount,
    nespressoMemberCustomAddressFromCreateAccount
} from "../services/NespressoMemberAddresses";
import {useSelector} from "react-redux";

export default function useCreateNespressoMember() {
    const {t} = useTranslation()
    const reseller = useReseller()
    const marketConfig = useMarketConfig()
    const {storeName, store, reducer, dispatch} = useModuleContext()
    const {setAddress} = useCustomer()
    const country = useCountry()
    const orderData = useOrderData()
    const currentLanguage = useCurrentLanguage()
    const {promotion} = usePromotionsContext()
    const {errorTracking} = useGTM()
    const plan = useSelector(state => {
        return state[storeName].products?.find(p => {
            const item = state[storeName].cart?.items?.find(i => i.id === p.id_product);
            return item && p.product_type.startsWith('plan_')
        })
    })
    const smsPhoneVerification = useSmsPhoneVerification(promotion?.promotion_user_sms_validation ?? plan?.product_plan_user_sms_validation)
    const [error, setError] = useState(null)
    const [loginFormVisible, setLoginFormVisible] = useState(false)
    const [userCanLogin, setUserCanLogin] = useState(false)

    /**
     * actions status
     */
    const [status, setStatus] = useState(null)
    const [isLoading, setLoading] = useState(false)

    const scrollToLoginForm = () => {
        const loginFormRef = document.querySelector('#existingUserLoginForm')
        if (loginFormRef) {
            setTimeout(() => {
                scrollVertically(loginFormRef, 10, 'smooth')
            }, 500)
        }
    }


    const createAccountMutation = useMutation({
        mutationFn: async (payload) => {
            setStatus('create_account')

            const {data} = await orderActions.createAccount(payload)

            if (data.status === 'success')
                return data.data
            if (data.messages[0] === 'linking_error') {
                throw new LinkingAccountException({
                    error: 'linking_error',
                    data: data.data,
                    ecapiAccountCreated: true,
                })
            }
            if (data.data?.member_number && data.data?.jwtToken) {
                throw new LinkingAccountException({
                    error: 'linking_error',
                    data: data.data,
                    ecapiAccountCreated: false,
                })
            }
            throw new ApiException(data.messages[0], data)
        },
    })
    const canRegisterMutation = useMutation({
        mutationFn: async ([payload, params]) => {
            setStatus('can_register')

            const {data} = await authActions.canRegister2(payload, queryParams(params))

            if (data.status === 'success')
                return data
            throw new ApiException(data.messages[0], data)
        },
        onMutate: () => {
            setError(null)
        },
        onSuccess: ({data}) => {
            dispatch(reducer.setCanRegisterResponse(data))
        },
    })
    const canAttemptMutation = useMutation({
        mutationFn: async ([payload, params]) => {
            setStatus('can_attempt')

            const {data} = await authActions.canAttempt2(payload, queryParams(params))

            if (data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        onMutate: (payload) => {
            setError(null)
        },
        onSuccess: ({data}) => {
            // dispatch(reducer.setCanRegisterResponse(data))
        },
    })
    const linkingAccountMutation = useMutation({
        mutationFn: async ([payload, memberNumber]) => {
            setStatus('linking_account')

            const {data} = await orderActions.linkMember(payload, memberNumber)
            if (data.status === 'success') {
                return data.data;
            } else if (!data.data.retry) {
                throw new LinkingAccountException({error: 'linking_error', data: data})
            } else if (!data.data?.order) {
                throw new LinkingAccountException({error: 'linking_error', data: data})
            }
            throw new ApiException(data.messages[0] || 'linking_error', data)
        },
        retry: (count, error) => {
            if (error instanceof LinkingAccountException) {
                if (error.data.data.retry)
                    return count + 1 < (marketConfig.api_linking_error_retry ?? 9)
                return false
            }
            return false
        },
        retryDelay: () => {
            const seconds = marketConfig.api_linking_error_retry_seconds ?? 20000
            return !isNaN(seconds) && seconds ? seconds * 1000 : 20000
        },
    })
    const createBankDetailsMutation = useMutation({
        mutationFn: async (payload) => {
            setStatus('updating_account')

            const {data} = await orderActions.createBankDetails(payload)
            if (data.status === 'success')
                return data.data;
            throw new ApiException(data.messages[0], data)
        },
    })

    useBeforeunload((e) => {
        if (isLoading) {
            e.preventDefault()
        }
    })

    async function canRegisterFn({data, promotionId, machinePlanId, stayHomeId}) {
        const {recaptchaToken, ...dataToStore} = data
        dispatch(reducer.setCustomer(dataToStore))
        const payload = {
            ...orderData.getSerialNumberData(),
            ...canRegisterPayloadFromCustomerForm({
                data,
                uuid: store.uuid,
            }),
        }
        const params = new CanRegisterPayloadParams({
            promotionId,
            machinePlanId,
            stayHomeId,
        })

        const res = await canRegisterMutation.mutateAsync([payload, params])
            .catch(error => {
                if (error.error === 'phone_to_many_times_used') {
                    throw new PhoneBlockingRuleException({
                        error: t('error.phone_to_many_times_used'),
                        data: error.data,
                    })
                }
                throw error
            })
        switch (res.data.status) {
            case 'SMS_VALIDATION':
                if (data.smsPhoneVerificationCode) {
                    smsPhoneVerification.onError('invalid_sms_code')
                    if (storeName === 'shop') {
                        errorTracking('Customer Information', 'invalid_sms_code')
                    }
                } else {
                    smsPhoneVerification.prepare()
                }
                throw new SmsValidationResponseException()
        }

        return res.data
    }

    async function canAttemptFn({data, nespressoMember, promotionId, machinePlanId, stayHomeId}) {
        const payload = canAttemptPayloadFromCustomerForm({
            data,
            nespressoMember,
            uuid: store.uuid,
        })
        const params = new CanRegisterPayloadParams({
            promotionId,
            machinePlanId,
            stayHomeId,
        })

        const res = await canAttemptMutation.mutateAsync([payload, params])
            .catch(error => {
                if (error.error === 'phone_to_many_times_used') {
                    throw new PhoneBlockingRuleException({
                        error: t('error.phone_to_many_times_used'),
                        data: error.data,
                    })
                }
                throw error
            })
        if (res?.status === 'SMS_VALIDATION') {
            if (data.smsPhoneVerificationCode) {
                smsPhoneVerification.onError('invalid_sms_code')
                if (storeName === 'shop') {
                    errorTracking('Customer Information', 'invalid_sms_code')
                }
            } else {
                smsPhoneVerification.prepare()
            }
            throw new SmsValidationResponseException()
        }
        const {recaptchaToken, ...dataToStore} = data
        if (dataToStore.existingAddress?.memberAddress)
            setAddress(dataToStore.existingAddress.memberAddress.value, dataToStore)
        else
            dispatch(reducer.setCustomer(dataToStore))

        return res?.data
    }

    async function createAccountFn({data}) {
        const nespressoMemberPayload = nespressoMemberPayloadFromCustomerForm({
            data,
            countryId: country.data.id_country,
            countryShortcode: country.data.country_shortcode,
            lang: currentLanguage,
            uuid: store.uuid,
        })

        const payload = {
            ...nespressoMemberPayload,
            customer: {
                ...orderData.getCustomer(data),
                ...nespressoMemberPayload?.customer,
            },
        }

        dispatch(reducer.setOrderAccount(null, false, false))
        const res = await createAccountMutation.mutateAsync(payload)
            .catch(async e => {
                if (e instanceof LinkingAccountException) {
                    const account = nespressoMemberFromCreateAccount(e.data, {email: data.details.email})
                    const linkAccountPayload = {
                        customer: payload.customer,
                        member_token: e.data.jwtToken,
                    }
                    dispatch(reducer.setOrderAccount(account, false, e.ecapiAccountCreated))
                    const res = await linkingAccountMutation.mutateAsync([linkAccountPayload, e.data.member_number])
                    if (res) {
                        dispatch(reducer.setOrderAccount(account, true, true))
                        return {
                            ...res,
                            order: {
                                ...res.order,
                                ...e.data?.order,
                            }
                        }
                    }
                    throw new ApiException('linking_error', {payload: linkAccountPayload, res})
                }
                if (e.data?.data?.member_number) {
                    setLoginFormVisible(true)
                    setUserCanLogin(true)
                    scrollToLoginForm()
                    throw new AllowedToLoginResponseException()
                }
                throw e
            })
        if (res) {
            storeCreatedAccount(data, res)
        }

        return res
    }

    function storeCreatedAccount(data, res) {
        if (!data || !res) return

        const address = nespressoMemberAddressFromCreateAccount(res.order)
        const customAddress = nespressoMemberCustomAddressFromCreateAccount(res.order)

        const {recaptchaToken, ...dataToStore} = {...data}
        dataToStore.details.memberAddress = {
            label: getAddressLabel(address),
            value: address.addressId,
        }
        if (customAddress) {
            dataToStore.customAddress.memberAddress = {
                label: getAddressLabel(customAddress),
                value: customAddress.addressId,
            }
        }
        dataToStore.existingAddress = {
            memberAddress: {
                label: getAddressLabel(customAddress ?? address),
                value: customAddress ? customAddress.addressId : address.addressId,
            }
        }

        dispatch(reducer.setCustomer(dataToStore))
        dispatch(reducer.setNespressoMember(nespressoMemberFromCreateAccount(res, {email: data.details.email})))
    }

    async function canRegisterAndCreateAccountFn({data, customData, promotionId, machinePlanId, stayHomeId}) {
        setLoginFormVisible(false)

        if (store.orderAccount && !store.orderAccountLinked) {
            const nespressoMemberPayload = nespressoMemberPayloadFromCustomerForm({
                data,
                countryId: country.data.id_country,
                countryShortcode: country.data.country_shortcode,
                lang: currentLanguage,
                uuid: store.uuid,
            })
            const payload = {
                ...nespressoMemberPayload,
                customer: {
                    ...orderData.getCustomer(data),
                    ...nespressoMemberPayload.customer,
                },
            }

            const linkAccountPayload = {
                customer: payload.customer,
                member_token: store.orderAccount.token,
            }
            const res = await linkingAccountMutation.mutateAsync([linkAccountPayload, store.orderAccount.memberNumber])
            if (res) {
                dispatch(reducer.setOrderAccount(store.orderAccount, true, true))
                const response = {
                    ...res,
                    order: {
                        ...res.order,
                        ...store.orderAccount?.rawData?.order,
                    }
                }
                storeCreatedAccount(data, response)
                return response
            }
            throw new ApiException('linking_error', {payload: linkAccountPayload, res})
        }
        const res = await canRegisterFn({data, promotionId, machinePlanId, stayHomeId})
        if (!res) {
            // throw error
        }

        switch (res.status) {
            case 'ALLOWED':
                if (reseller.isLoggedIn) {
                    return res
                } else {
                    const account = await createAccountFn({
                        data,
                        customData,
                    })
                    if (account) {

                        return nespressoMemberFromCreateAccount(account, {email: data.details.email})
                    }
                    throw new ApiException('cannot_create_account', null)
                }
            case 'ACCOUNT_EXISTS':
                setLoginFormVisible(true)
                scrollToLoginForm()
                if (res.allowedToLoginWithoutLostProgress) {
                    setUserCanLogin(true)

                    setError(t(`${reseller.isLoggedIn ? 'reseller_error' : 'error'}.member_exist_error_not_lost_progress`))
                    throw new AllowedToLoginResponseException()
                }
                setError(t(`${reseller.isLoggedIn ? 'reseller_error' : 'error'}.member_exist_error_lost_progress`))
                throw new AllowedToLoginClearStorageResponseException()
            // if(res.memberNumber) {
            //     // set form visible
            //     // user can log in exception
            //     throw new AllowedToLoginResponseException()
            // }
            // // setError(t('error.member_exists_error'))
            // // error: member_exists_error
            // throw new MemberExistsResponseException({
            //     error: t('error.member_exists_error'),
            //     data: res,
            // })
            case 'SMS_VALIDATION':
                // ????????
                // sms validation exception
                throw new SmsValidationResponseException()
            case 'MEMBER_EXISTS':
                // TODO: create web account
                throw new MemberExistsResponseException({
                    error: t('error.member_exists_error'),
                    data: res,
                })
            case 'NOT_ALLOWED':
            default:
                // ????????
                // error: cannot_create_account
                throw new NotAllowedResponseException({
                    error: t('error.cannot_create_account'),
                    data: res,
                })
        }
    }

    async function canRegister({data, promotionId, machinePlanId, stayHomeId}) {
        return loadingActionHandler({data, promotionId, machinePlanId, stayHomeId}, canRegisterFn)
    }

    async function canAttempt({data, nespressoMember, promotionId, machinePlanId, stayHomeId}) {
        return loadingActionHandler({data, nespressoMember, promotionId, machinePlanId, stayHomeId}, canAttemptFn)
    }

    async function createAccount({data}) {
        return loadingActionHandler({data}, createAccountFn)
    }

    async function canRegisterAndCreateAccount({data, customData, promotionId, machinePlanId, stayHomeId}) {
        return loadingActionHandler({data, customData, promotionId, machinePlanId, stayHomeId}, canRegisterAndCreateAccountFn)
    }

    async function createBankDetails(args) {
        return loadingActionHandler(args, createBankDetailsMutation.mutateAsync)
    }

    async function loadingActionHandler(data, action) {
        setLoading(true)
        setError(null)
        try {
            const res = await action(data)
            setLoading(false)
            return res
        } catch (e) {
            setLoading(false)
            throw e
        }
    }

    return {
        canRegisterMutation,
        createAccountMutation,
        canAttemptMutation,
        createBankDetailsMutation,
        isLoading,
        canRegister,
        createAccount,
        canRegisterAndCreateAccount,
        canAttempt,
        createBankDetails,
        status,
        error,
        setError,
        userCanLogin,
        loginFormVisible,
        smsPhoneVerification,
    }
}
