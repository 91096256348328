import {TextInputController} from "../controllers";
import React from "react";
import {useTranslation} from "../../hooks";

export default function LastnameInput(props) {
    const {t} = useTranslation()

    return (
        <TextInputController
            name="lastname"
            label={t('lastname')}
            {...props}
        />
    )
}
