import {useTranslation} from "../../../hooks";
import {usePickupPoint} from "../hooks";


export default function PickupPointDetails({pickupPoint}) {
    const {t} = useTranslation()
    const {openingHours} = usePickupPoint({pickupPoint})

    return (
        <div>
            <p className="par-2 --bold --mb2">{t('pickup_points.selected_pickup_point')} ({t(`pickup_points.type.${pickupPoint.type}`)})</p>
            <div className="--mb1">
                {pickupPoint.addresses.map((a, idx) => (
                    <p key={idx} className="par-2 --mb2">
                        {a.addressLine1}, {a.zipCode} {a.city}
                    </p>
                ))}
                <p className="par-2 --semiBold --mb2">{t('pickup_points.open_hours')}</p>
                {openingHours?.map((o, idx) => (
                    <p key={idx} className="par-2 --mb1">
                        {o.day}: {o.isOpen ? `${o.openingHours.join(',')}` : t('pickup_points.is_closed')}
                    </p>
                ))}
            </div>
        </div>
    )
}
