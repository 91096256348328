import {useMutation} from "@tanstack/react-query";
import {useResellerActions} from "../../../actions/useResellerActions";
import {ApiException} from "../../../errors";
import {useCountry, useModuleContext} from "../../../hooks";
import {useEffect} from "react";

export default function useSearchNespressoMembers() {
    const actions = useResellerActions()
    const {data: country} = useCountry()
    const maxAttempts = country.country_assisted_simplified_flow_number_of_attempts
        ? Number(country.country_assisted_simplified_flow_number_of_attempts)
        : -1
    const attemptsDelayTime = country.country_assisted_simplified_flow_attempts_timeout_minutes
        ? Number(country.country_assisted_simplified_flow_attempts_timeout_minutes) * 60
        : 6
    const {store, dispatch, reducer} = useModuleContext()
    const attempts = store.searchMemberAttempts
    const delayedAt = store.searchMemberAttemptDelayedAt
    const mutation = useMutation({
        mutationFn: async (payload) => {
            if(attempts === 0)
                return

            const {data} = await actions.searchNespressoMembers(payload)

            if(data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        onSuccess: (data) => {
            dispatch(reducer.setSearchedMembers(data))
        },
        onSettled: () => {
            if(attempts > 0) {
                const value = attempts - 1
                dispatch(reducer.setSearchMemberAttempts(value))
                dispatch(reducer.setSearchMemberAttemptDelayedAt(new Date()))

                if(value === 0) {
                    setTimeout(() => {
                        resetAttempts()
                    }, attemptsDelayTime * 1000)
                }
            }
        }
    })

    useEffect(() => {
        if(!delayedAt) {
            resetAttempts()
            return
        }

        if(attempts === 0) {
            setTimeout(() => {
                resetAttempts()
            }, attemptsDelayTime * 1000)
            return
        }

        const date = new Date(delayedAt)
        const now = new Date()
        date.setSeconds(date.getSeconds() + attemptsDelayTime)
        if(date.getTime() < now.getTime()) {
            resetAttempts()
        }
    }, [])

    function resetAttempts() {
        dispatch(reducer.setSearchMemberAttempts(maxAttempts))
        dispatch(reducer.setSearchMemberAttemptDelayedAt(new Date()))
    }

    return {
        mutation,
        attempts,
        maxAttempts,
        attemptsDelayTime,
    }
}
