import {Button} from "../../../components/buttons/_index";
import {GTM} from "../../../misc/_index";
import {AuthForm, IsNewMemberForm} from "../../../forms";
import React from "react";
import {useModuleContext, useModuleStepsNavigation, useNespressoMember, useTranslation} from "../../../hooks";


export default function AuthorizationScreen() {
    const {t} = useTranslation()
    const stepsNavigation = useModuleStepsNavigation()
    const {store, dispatch, reducer} = useModuleContext()
    const {loginMutation, error, nespressoMember, storeLoggedUser} = useNespressoMember()

    const resetStore = () => {
        dispatch(reducer.setIsNewMember(undefined))
        dispatch(reducer.setNespressoMember(null))
        dispatch(reducer.setOrderAccount(null, false, false))
    }

    const handleSubmitIsNewMember = (isNewMember) => {
        if (isNewMember !== store.isNewMember) {
            resetStore()
        }
        dispatch(reducer.setIsNewMember(isNewMember));
        if (isNewMember === true) {
            dispatch(reducer.setCustomer(null))
            stepsNavigation.navToNext()
        }
    }

    const handleLogin = async (data) => {
        const payload = {
            login: data.email,
            password: data.password,
            recaptchaToken: data.recaptchaToken,
        }

        loginMutation.mutateAsync(payload)
            .then((data) => {
                storeLoggedUser(data, payload)
                stepsNavigation.navToNext()
            })
    }

    return (
        <div className="grid">
            <div>
                <div>
                    <IsNewMemberForm
                        onSubmit={handleSubmitIsNewMember}
                    />

                    {store.isNewMember === false && (
                        <>
                            {nespressoMember ? (
                                <Button
                                    href={stepsNavigation.next()}
                                    label={t('continue')}
                                />
                            ) : (
                                <AuthForm
                                    state={{
                                        error,
                                        isLoading: loginMutation.isLoading,
                                    }}
                                    onSubmit={handleLogin}
                                />
                            )}
                        </>
                    )}
                    {store.isNewMember === true && (
                        <Button
                            href={stepsNavigation.next()}
                            label={t('continue')}
                        />
                    )}
                </div>
            </div>
            <div/>
        </div>
    )
}
