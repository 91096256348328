import React, {useState} from 'react';
import {Button, PlusMinusButton} from "../../buttons/_index";
import {useCart, usePriceFormatter, useTranslation} from "../../../misc/Hooks";
import {getImage, unlockByOptIn} from "../../../misc/Helpers";
import {Config} from "../../../utils/_index";
import PlanItem from "../../PlanItem";
import {EasyOrderGiftItem, PackageItem} from "../../_index";
import LockedByOptIn from "../../misc/LockedByOptIn";
import {CoffeeItemModal} from "./index";
import {useModuleContext} from "../../../hooks";


const CoffeeItem = (props) => {
    const {store} = useModuleContext()
    const [modalVisible, setModalVisible] = useState(false);
    const {formatPrice, formatPriceObj, checkPromoPrice} = usePriceFormatter();
    const {updateItem, isItemLocked} = useCart(props.storeName ?? 'ev');
    const t = useTranslation();

    const itemInCart = props.cart?.items.find(i => i.id === props.item.id_product);

    const lockedByOptIn = unlockByOptIn(props.item.promotion_product, props.category.promotionCategory)

    let intensityBox = [];
    for (let i = 0; i < 13; i++) {
        let active = i < parseInt(props.item.product_intensity) ? '-active' : '';
        intensityBox.push(<span key={i} className={active}/>);
    }


    if (props.item.product_type === Config.COFFEE_TYPE.PLAN_COFFEE) {
        return <PlanItem {...props}/>
    }

    if (props.item.product_type === Config.COFFEE_TYPE.PACKAGE) {
        return <PackageItem {...props}/>
    }

    if (props.item.product_type === Config.COFFEE_TYPE.EASY_ORDER_GIFT) {
        return <EasyOrderGiftItem {...props}/>
    }

    const hasPromoPrice = checkPromoPrice(
        parseInt(props.item.product_price_promo?.status),
        props.item.product_price_promo?.date_from,
        props.item.product_price_promo?.date_to,
    );

    return (
        <div className="coffeeItem">
            <div className="coffeeItem__header">
                <div className="coffeeItem__thumb">
                    <img src={getImage(props.item.product_gallery)} alt=""/>
                </div>
                <div className="coffeeItem__titleWrapper">
                    <h3 className="coffeeItem__title">{props.item.product_title}</h3>
                    {!!props.item.product_local_stock && !store.msnRequired && !store.machine && (
                        <span className="coffeeItem__titleInfo">({t('local_stock_product')})</span>
                    )}
                </div>
            </div>

            <div className="coffeeItem__body">
                {!!props.item.product_lead && (
                    <p className="coffeeItem__subtitle" dangerouslySetInnerHTML={{__html: props.item.product_lead}}/>
                )}

                <div className="coffeeItem__price">
                    {!hasPromoPrice ? (
                        <span>{formatPriceObj(props.item.product_price)}</span>
                    ) : (
                        <>
                            <small><s className="--textDark"
                                      style={{marginRight: '6px'}}>{formatPriceObj(props.item.product_price)}</s></small>
                            <span>{formatPriceObj(props.item.product_price_promo)}</span>
                        </>
                    )}
                </div>

                <div className="coffeeItem__link">
                    <button className="link -primary" onClick={() => setModalVisible(true)}>{t('read_more')}</button>
                </div>

                <LockedByOptIn {...lockedByOptIn}/>


                {props.item.product_type === Config.COFFEE_TYPE.CAPSULE && !!props.item.product_intensity ? (
                    <div className="coffeeItem__intensity">
                        <div className="coffeeItem__intensityBox">
                            {intensityBox.map(item => item)}
                        </div>
                        <span
                            className="coffeeItem__intensityText">{t('coffee.intensity')} {props.item.product_intensity}</span>
                    </div>
                ) : <div className="--mb1"/>}

                <div className="coffeeItem__button">
                    {lockedByOptIn.value ? (
                        <Button
                            type="primary"
                            size="sm"
                            label={t(!itemInCart ? 'buy_now' : 'remove_from_cart')}
                            disabled
                        />
                    ) : (itemInCart ? (
                        <PlusMinusButton
                            type="primary"
                            size="sm"
                            plusDisabled={itemInCart.value >= props.item.product_max_qty || isItemLocked(props.item.id_product)}
                            value={itemInCart.value * props.item.product_package_qty}
                            step={props.item.product_step}
                            packageQty={props.item.product_package_qty}
                            max={props.item.product_max_qty ?? undefined}
                            onClickMinus={() => updateItem(props.item.id_product, (itemInCart?.value ?? 0) - props.item.product_step, {promotionProduct: props.item.promotion_product})}
                            onClickPlus={() => updateItem(props.item.id_product, (itemInCart?.value ?? 0) + props.item.product_step, {promotionProduct: props.item.promotion_product})}
                            onChange={v => updateItem(props.item.id_product, v, {promotionProduct: props.item.promotion_product})}
                        />
                    ) : (
                        <Button
                            type="primary"
                            size="sm"
                            label={t('buy_now')}
                            disabled={isItemLocked(props.item.id_product)}
                            onClick={() => updateItem(props.item.id_product, props.item.product_step, {promotionProduct: props.item.promotion_product})}
                        />
                    ))}

                </div>
            </div>


            {!!modalVisible && (
                <CoffeeItemModal
                    visible={modalVisible}
                    lockedByOptIn={lockedByOptIn}
                    item={props.item}
                    itemInCart={itemInCart}
                    storeName={props.storeName ?? 'ev'}
                    onClose={() => setModalVisible(false)}
                />
            )}
        </div>
    );
}


export default React.memo(CoffeeItem);
