import {useForm, useWatch} from "react-hook-form";
import {useFormRulesBuilder} from "./hooks";
import Patterns from "../utils/Patterns";
import Form from "./Form";
import {PromoCodeInput} from "./presets";
import {useFieldFormat, useFormHelper, usePromoCode, useTranslation} from "../hooks";
import React, {useEffect, useRef, useState} from "react";
import {isEnabledByMarket} from "../utils/MarketConfig";
import PromoCodeHelpModal from "../components/modals/PromoCodeHelpModal";
import {useLocalizeObject} from "../misc/Hooks";

export default function PromoCodeForm({
    editable,
    setEditable,
}) {
    const mutationTimeout = useRef(null)
    const {t} = useTranslation()
    const localizeObject = useLocalizeObject()
    const {mutation, data, error} = usePromoCode()
    const {formats, getIfValid} = useFieldFormat()
    const [promoCodeModalVisible, setPromoCodeModalVisible] = useState(false);
    const {formMethods} = useFormHelper()
    const formRules = useFormRulesBuilder({
        promoCode: b => b.required()
            .validate(() => mutation.isSuccess)
    }, formMethods)
    const promoCode = useWatch({
        control: formMethods.control,
        name: 'promoCode',
    })

    useEffect(() => {
        const value = getIfValid(promoCode, formats.promoCode.pattern)
        if(value) {
            clearTimeout(mutationTimeout.current)
            mutationTimeout.current = setTimeout(() => {
                mutation.mutate(value)
            }, 500)
        } else {
            clearTimeout(mutationTimeout.current)
        }
    }, [promoCode])

    useEffect(() => {
        formMethods.setValue('promoCodeData', data)
    }, [data])

    useEffect(() => {
        if(error && !data) {
            formMethods.setError('promoCode', {type: 'invalid', message: error})
        } else {
            formMethods.clearErrors('promoCode')
        }
    }, [error, data])


    return (
        <div>

            {editable ? (
                <p
                    className="par-1 --semiBold"
                    dangerouslySetInnerHTML={{__html: t('ev_machine_registration.code_text')}}
                />
            ) : (
                <p
                    className="par-1 --semiBold"
                    dangerouslySetInnerHTML={{__html: t('pop.ready_pop_title')}}
                />
            )}

            {(!!t('modal.promo_code_help.button_label') && isEnabledByMarket('promoCodeHelpModal')) && (
                <button className="link -primary --mb2" onClick={() => setPromoCodeModalVisible(true)}>
                    {t('modal.promo_code_help.button_label')}
                </button>
            )}
            {editable ? (
                <PromoCodeInput
                    rules={formRules.values.promoCode}
                    options={{
                        isLoading: mutation.isLoading,
                    }}
                />
            ) : (
                <>
                    <div className="pop__group">
                        <p className="pop__label" dangerouslySetInnerHTML={{__html: t('promo_code')}}/>
                        <p className="pop__value">{promoCode}</p>
                    </div>
                    {!!(data?.id_shop && data.id_shop_group) && (
                        <div className="pop__group">
                            <p className="pop__label" dangerouslySetInnerHTML={{__html: t('trade_partner')}}/>
                            <p className="pop__value">{localizeObject(data.shop_group_title_translate) || data.shop_group_title}</p>
                        </div>
                    )}
                    {!!data?.id_shop && (
                        <div className="pop__group">
                            <p className="pop__label" dangerouslySetInnerHTML={{__html: t('retailer_store')}}/>
                            <p className="pop__value">{localizeObject(data.shop_title_translate) || data.shop_title}</p>
                        </div>
                    )}
                </>
            )}

            {(!!t('modal.promo_code_help.button_label') && isEnabledByMarket('promoCodeHelpModal')) && (
                <PromoCodeHelpModal
                    visible={promoCodeModalVisible}
                    onClose={() => setPromoCodeModalVisible(false)}
                />
            )}
        </div>
    )
}
