import {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import PagePreloader from "../../components/PagePreloader";
import {useLocalizeObject, useNavigation} from "../../misc/Hooks";
import {CookieConsent} from "../../components/_index";
import {checkAutoAdd, mapOrder, mapOrderToCustomer, uuidv4} from "../../misc/Helpers";
import Routes from "../../utils/Routes";
import * as orderActions from "../../actions/OrderActions";
import {useCivilityOptions, useCustomer, useStackOptions} from "../../hooks";
import * as eTradeReopenReducer from "../../redux/eTradeReopenReducer";
import * as MainActions from "../../actions/MainActions";
import {useResellerActions} from "../../actions/useResellerActions";


export default function Provider(props) {
    const params = useParams()
    const localizeObject = useLocalizeObject()
    const navigation = useNavigation();
    const location = useLocation()
    const history = useHistory();
    const {mapOrderToCustomer} = useCustomer()
    const dispatch = useDispatch();
    const resellerActions = useResellerActions();
    const store = useSelector(({eTradeReopen}) => ({
        token: eTradeReopen.token,
        data: eTradeReopen.data,
        callbackParam: eTradeReopen.callbackParam,
    }))
    const orderUuid = new URLSearchParams(location?.search).get('uuid')
    const [loading, setLoading] = useState(!!params.token)

    useEffect(() => {
        if (params.token) {
            fetch(params.token)
                .then(async order => {
                    if (order && order.etrade_plan) {
                        const {cart} = mapOrder(order)
                        const customer = mapOrderToCustomer(order)
                        resellerActions.logout();

                        dispatch(eTradeReopenReducer.clearStore())
                        dispatch(eTradeReopenReducer.setUuid(uuidv4()))
                        dispatch(eTradeReopenReducer.setCallbackParam(order.etrade_shop_redirect))
                        dispatch(eTradeReopenReducer.setCustomer(customer))
                        dispatch(eTradeReopenReducer.setIsNewMember(!order.webaccount_exist))
                        dispatch(eTradeReopenReducer.setData(order))
                        dispatch(eTradeReopenReducer.setReopenData(order))
                        await checkToken(order.etrade_plan.etrade_plan_slug)

                        // history.push(navigation(Routes.SHOP_REOPEN_AUTHORIZATION))
                        setLoading(false)
                    } else {
                        history.push(navigation(Routes.MACHINE_REGISTRATION_REGISTRATION))
                    }
                })
        } else if (!store.data && !orderUuid) {
            history.push(navigation(Routes.MACHINE_REGISTRATION_REGISTRATION))
        }

        const root = document.documentElement
        root.style.setProperty('--green', '#8F7247')

        return () => {
            root.style.setProperty('--green', '#2E8561')
        }
    }, [])

    const fetch = async (token) => {
        if (!token) return null
        return await orderActions.reopen(token)
            .then(r => {
                const res = r.data
                if (res.status === 'success') {
                    return res.data
                } else {
                    return null
                }
            })
            .catch(err => {
                console.error(err)
                return null
            })
    }

    const checkToken = async (token) => {
        return await MainActions.getETradePlan(token)
            .then(res => {
                if (res.data.status === 'success') {
                    updatePlan(token, res.data.data)
                    return true
                }
                return false
            })
            .catch(() => false)
    }

    const updatePlan = (token, data) => {
        const products = data.products.reduce((prev, next) => {
            prev.push(...next.products)
            return prev
        }, [data.plan])

        const categories = [
            {
                ...data.plan.category,
                category: data.plan.category,
                products: [data.plan],
                subcategories: []
            },
            ...data.products.map(c => ({
                ...c.category,
                promotion_category_add_auto: true,
                promotion_category_add_auto_min_price: 0,
                promotion_category_add_auto_min_products: 0,
                promotion_category_add_auto_max_price: null,
                promotion_category_add_auto_max_products: null,
                ...c,
            }))
        ]

        const cart = {items: [{id: data.plan.id_product, value: 1}], sum: ''}

        dispatch(eTradeReopenReducer.setPlan(
            token,
            data,
            products,
            categories,
            checkAutoAdd(categories, products, cart),
        ))
    }

    return (
        <>
            {loading ? <PagePreloader/> : (
                <>
                    {props.children}

                    {!!store.data?.cookie_consent && (
                        <CookieConsent
                            module="eTradeReopen"
                            text={localizeObject(store.data?.cookie_consent?.cookie_consent_text)}
                            url={localizeObject(store.data?.cookie_consent?.cookie_consent_redirect)}
                        />
                    )}
                </>
            )}
        </>
    )
}
