import React, {useState} from 'react';
import {ValuesTooltip} from "../../misc/_index";


export default (props) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);


    const handleTooltipSubmit = (value) => {
        setTooltipVisible(false);

        props.onChange(value);
    }


    let className = '';
    switch (props.type) {
        case "primary-outline":
            className = '-primary -outline';
            break;
        case "grey":
            className = '-grey';
            break;
        case "secondary":
            className = '-secondary';
            break;
        default:
            className = '-primary';
    }

    switch (props.size) {
        case "sm": {
            className += ' -sm';
            break;
        }
        case "md": {
            className += ' -md';
            break;
        }
    }


    if (props.active) {
        className += ' -active';
    }

    return (
        <div className="plusMinusBtn">
            <button
                type="button"
                className={`btn ${className}`}
                style={props.style}
                disabled={!!props.minusDisabled}
                onClick={props.onClickMinus}
            >
                <img src={require('../../assets/img/icons/minus-white.svg').default} alt=""/>
            </button>
            {(typeof props.value !== "undefined" && props.value !== null) && (
                <div className="plusMinusBtn__content">
                    <button
                        type="button"
                        className="plusMinusBtn__value"
                        onClick={() => setTooltipVisible(true)}
                    >{props.value}</button>
                    {tooltipVisible && props.max / props.step > 5 && (
                        <ValuesTooltip
                            value={props.value}
                            step={props.step}
                            packageQty={props.packageQty}
                            max={props.max}
                            onSubmit={handleTooltipSubmit}
                            onClose={() => setTooltipVisible(false)}
                        />
                    )}
                </div>
            )}
            <button
                type="button"
                className={`btn ${className}`}
                style={props.style}
                disabled={!!props.plusDisabled}
                onClick={props.onClickPlus}
            >
                <img src={require('../../assets/img/icons/plus-white.svg').default} alt=""/>
            </button>


        </div>
    )
}
