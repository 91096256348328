import React from "react";

export default function HiddenFile({inputRef, ...props}) {
    return (
        <input
            ref={inputRef}
            type="file"
            {...props}
            hidden
        />
    )
}
