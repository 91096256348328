
export default class SelectOption {
    constructor(label, value, data = null) {
        this.label = label
        this.value = value
        this.data = data
    }

    toString() {
        return this.label
    }
}
