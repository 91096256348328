import {Routes} from "../../../utils/_index";
import {useCart, useNavigation} from "../../../misc/Hooks";
import {useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {useRequiredField} from "../../index";


/**
 * Validates Shop reopen module route / step
 * @param route
 * @type route: string
 * @returns {{lastValidRoute: (*), value: boolean}}
 */
export default function useStepValidation(route) {
    const navigation = useNavigation()
    const location = useLocation()
    const store = useSelector(({main, shopReopen}) => ({
        country: main.country,
        machine: shopReopen.machine,
        data: shopReopen.data,
        nespressoMember: shopReopen.nespressoMember,
        proofOfPurchase: shopReopen.proofOfPurchase,
        proofOfPurchaseLogs: shopReopen.proofOfPurchaseLogs,
        promoCode: shopReopen.promoCode,
        promotion: shopReopen.promotion,
        customer: shopReopen.customer,
    }))
    const {isCartValid} = useCart('shopReopen')
    const history = useHistory()
    const orderUuid = new URLSearchParams(location?.search).get('uuid')
    const {requiredField} = useRequiredField()

    const response = {
        canShowStep: true,
        lastValidStep: Routes.SHOP_REOPEN_AUTHORIZATION,
    }

    // if(store.order) {
    //     response.canShowStep = route === Routes.STAY_HOME_SUMMARY
    //     response.lastValidStep = Routes.STAY_HOME_SUMMARY
    // } else {
        switch (route) {
            case Routes.SHOP_REOPEN_SUMMARY:
                if(orderUuid) {
                    response.canShowStep = true
                    response.lastValidStep = Routes.SHOP_REOPEN_SUMMARY
                    break
                }

                if(!store.customer) {
                    response.canShowStep = false
                    response.lastValidStep = Routes.SHOP_REOPEN_CUSTOMER_INFORMATION
                }
            case Routes.SHOP_REOPEN_CUSTOMER_INFORMATION:
                if(!isCartValid || !store.promotion) {
                    response.canShowStep = false
                    response.lastValidStep = Routes.SHOP_REOPEN_OFFER_SELECTION
                }
            case Routes.SHOP_REOPEN_OFFER_SELECTION:
                if (store.machine && !store.order) {
                    switch (requiredField) {
                        case 'proof_of_purchase_and_promo_code':
                            if(!(store.proofOfPurchase && store.proofOfPurchase?.machinePrice && store.promoCode))
                                response.canShowStep = false
                            break
                        case 'proof_of_purchase_or_promo_code':
                            if(!((store.proofOfPurchase && store.proofOfPurchase?.machinePrice) || store.promoCode))
                                response.canShowStep = false
                            break
                        case 'only_proof_of_purchase':
                            if(!store.proofOfPurchase && store.proofOfPurchase?.machinePrice)
                                response.canShowStep = false
                            break
                        case 'only_promo_code':
                            if(!store.promoCode)
                                response.canShowStep = false
                            break
                    }
                }
                response.lastValidStep = Routes.SHOP_REOPEN_MACHINE_REGISTRATION
            case Routes.SHOP_REOPEN_MACHINE_REGISTRATION:
                if(!store.nespressoMember) {
                    response.canShowStep = false
                    response.lastValidStep = Routes.SHOP_REOPEN_AUTHORIZATION
                }
            case Routes.SHOP_REOPEN_AUTHORIZATION:
                if(!store.data && !orderUuid) {
                    history.push(navigation(Routes.SHOP_REOPEN_INVALID_TOKEN))
                }
        }
    // }

    return {
        value: response.canShowStep,
        lastValidRoute: navigation(response.lastValidStep),
    }
}
