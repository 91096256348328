import {useEffect, useLayoutEffect, useRef, useState} from "react";
import useResizeObserver from "@react-hook/resize-observer";
import {useSwipeable} from "react-swipeable";

export default function useSlider(config = {}){

    const sliderConfig = {
        maxVisibleItems: 5,
        stylePropertyName: '--minWidth',
        itemsLength: 5,
        rwdElements: [3, 2, 1],
        ...config
    }

    const wrapperRef = useRef();
    const sliderRef = useRef();
    const [countVisibleItems, setCountVisibleItems] = useState(sliderConfig.maxVisibleItems)
    const [offset, setOffset] = useState(0);
    const [lockNext, setLockNext] = useState(isNextLocked(sliderConfig.itemsLength))
    const [arrowVisible, setArrowVisible] = useState(countVisibleItems < sliderConfig.itemsLength)

    useResizeObserver(wrapperRef.current, entry => {
        const itemsCount = getCountVisibleItems(entry.contentRect.width)
        setCountVisibleItems(itemsCount)
        setLockNext(isNextLocked(sliderConfig.itemsLength))
        document.documentElement.style.setProperty(sliderConfig.stylePropertyName, `${100 / itemsCount}%`)

        // if(itemsCount > sliderConfig.itemsLength){
        //     document.documentElement.style.setProperty(sliderConfig.stylePropertyName, `${100 / sliderConfig.itemsLength}%`)
        // }
    })


    useEffect(() => {
        setArrowVisible(countVisibleItems < sliderConfig.itemsLength)
    }, [sliderConfig.itemsLength, countVisibleItems])

    useEffect(() => {
        // setOffset(0)
        setLockNext(isNextLocked(sliderConfig.itemsLength))
    }, [sliderConfig.itemsLength])

    useEffect(() => {
        const el = sliderRef?.current?.querySelector('div');

        if (el) {
            sliderRef?.current?.scrollTo({
                left: offset * el.offsetWidth,
                top: 0,
                behavior: 'smooth',
            })
        }
    }, [offset])


    const getCountVisibleItems = (width) => {
        if (!width)
            width = wrapperRef.current?.offsetWidth

        if (width < 30 * 16) {
            return sliderConfig.rwdElements[2];
        } else if (width < 36 * 16) {
            return sliderConfig.rwdElements[1];
        } else if (width < 48 * 16) {
            return sliderConfig.rwdElements[0];
        }
        return sliderConfig.maxVisibleItems
    }

    function isNextLocked(items: number): boolean {
        let locked = true;

        const width = sliderRef.current?.offsetWidth;
        if (width < 30 * 16) {
            if (items > sliderConfig.rwdElements[2]) locked = false;
        } else if (width < 36 * 16) {
            if (items > sliderConfig.rwdElements[1]) locked = false;
        } else if (width < 48 * 16) {
            if (items > sliderConfig.rwdElements[0]) locked = false;
        } else {
            if (items > sliderConfig.maxVisibleItems) locked = false;
        }

        return locked;
    }

    const sliderPrev = () => {
        const newOffset = offset - 1;
        if (newOffset >= 0) {
            setOffset(newOffset)
            if (lockNext) {
                setLockNext(false);
            }
        }
    }

    const sliderNext = () => {
        const newOffset = offset + 1;

        const itemsCount = getCountVisibleItems();

        if (newOffset + itemsCount <= sliderRef.current.children.length) {
            setOffset(newOffset)
            if (newOffset + itemsCount === sliderRef.current.children.length) {
                setLockNext(true);
            } else if (lockNext) {
                setLockNext(false);
            }
        } else if (!lockNext) {
            setLockNext(true);
        }
    }

    const swipe = useSwipeable({
        onSwipedLeft: (eventData) => {
            if(!lockNext){
                sliderNext()
            }
        },
        onSwipedRight: (eventData) => {
            if(offset){
                sliderPrev()
            }
        },
        preventScrollOnSwipe: true,
    })


    return {
        wrapperRef,
        sliderRef,
        sliderPrev,
        sliderNext,
        offset,
        lockNext,
        countVisibleItems,
        swipe,
        arrowVisible
    }

}