import React, {Fragment} from "react";
import {FormGroup, FormRow} from "./index";
import {FormFragmentProvider} from "../hooks";

export default function FormBuilderComponent({prefix, components}) {
    return (
        <FormFragmentProvider value={{prefix}}>
            {components.map((row, idx) => (
                <Fragment key={idx}>
                    {row.length > 1 ? (
                        <FormRow>
                            {row.map((component, cIdx) => (
                                <FormGroup key={cIdx}>
                                    {component}
                                </FormGroup>
                            ))}
                        </FormRow>
                    ) : row[0] instanceof FormRow ? (
                        row[0]
                    ) : (
                        <FormGroup>
                            {row[0]}
                        </FormGroup>
                    )}
                </Fragment>
            ))}
        </FormFragmentProvider>
    )
}
