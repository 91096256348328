import {useReseller, useTranslation} from "../../../../hooks";
import {useResellerActions} from "../../../../actions/useResellerActions";
import {usePriceFormatter} from "../../../../misc/Hooks";
import {useParams} from "react-router-dom";
import {Error404} from "../../../../components/misc/_index";
import {TableComponent, useTableBuilder, useTableFilters} from "../../../../components/baseTable";
import {getImage, isoDateConverterWithTime} from "../../../../misc/Helpers";
import LocalStockQuantity from "../components/localStockQuantity/LocalStockQuantity";
import React from "react";
import {useQuery} from "@tanstack/react-query";
import {Preloader} from "../../../../misc/_index";
import {ApiException} from "../../../../errors";
import Error401 from "../../../../components/misc/Error401";
import {permissions} from "../../../../misc/PermissionsConfig";
import {LocalStockRequest} from "../components/localStockRequest";
import LocalStockRequestFinalizing from "../components/localStockRequestFinalizing/LocalStockRequestFinalizing";
import LocalStockRequestCancel from "../components/localStockRequestCancel/LocalStockRequestCancel";
import LocalStockRequestStatusLabel from "../components/localStockRequestStatusLabel/LocalStockRequestStatusLabel";
import LocalStockRequestResend from "../components/localStockRequestResend/LocalStockRequestResend";
import LocalStockHistory from "../components/localStockHistory/LocalStockHistory";

export default function PSMPosStockScreen() {
    const {t} = useTranslation()
    const {id} = useParams()
    const {fetchPosStock, fetchPos, fetchPosStockRequests} = useResellerActions()
    const {formatPriceObj} = usePriceFormatter();
    const {hasPermission} = useReseller()
    const posQuery = useQuery(
        ['psm_pos_name', id],
        async () => {
            return await fetchPos(id)
                .then(res => {
                    if(res.response) {
                        throw new ApiException(res.response?.data?.message || res.response?.data?.messages[0], res.response?.data)
                    }
                    return res.data?.data
                })
        },
    )

    const stockFilter = useTableFilters({
        builder: {
            formConfig: [['slug']],
        },
        delayFields: ['slug'],
        searchPrefix: "stockFilter",
        mapFunc: (data) => ({
            slug: data.stockFilter?.slug ?? '',
        }),
    });


    const stockTable = useTableBuilder({
        name: `psm_stock_list_${id}`,
        action: async (params) => await fetchPosStock(id, params),
        columns: [
            {
                name: 'product_gallery',
                label: 'stock_management.product_image',
                cell: ({value}) => (
                    <img className="productImage" src={getImage(value)} alt=""/>
                )
            },
            {
                name: 'product_title',
                label: 'stock_management.product_title',
            },
            {
                name: 'product_code',
                label: 'stock_management.product_code'
            },
            {
                name: 'product_price',
                label: 'stock_management.product_price',
                cell: ({value}) => formatPriceObj(value)
            },
            {
                name: "local_stock_quantity",
                label: 'stock_management.local_stock_quantity',
                cell: ({row: {original}}, query) => {
                    if(!hasPermission(permissions.posStockManagementEdit)) return <span>{original.local_stock_quantity}</span>
                    return (
                        <LocalStockQuantity
                            initValue={original.local_stock_quantity}
                            step={original.product_package_qty}
                            idProduct={original.id_product}
                            idPos={id}
                            query={query}
                        />
                    )
                }
            },
            {
                name: 'blocked_local_stock_quantity',
                label: 'stock_management.blocked_local_stock_quantity',
            },
            {
                name: 'available_stock_quantity',
                label: 'stock_management.available_stock_quantity',
                cell: ({row: {original}}) => (
                    <strong>{original.local_stock_quantity - original.blocked_local_stock_quantity}</strong>
                )
            },
        ],
        config: {
            selectable: !!hasPermission(permissions.posStockManagementEdit),
            idFieldName: 'id_product',
            searchParamPrefix: 'stock'
        },
        params: {...stockFilter.rawData}
    })

    const requestsTable = useTableBuilder({
        name:  `psm_stock_requests_${id}`,
        action: async (params) => await fetchPosStockRequests(id, params),
        columns: [
            {
                name: 'product_title',
                label: 'stock_management.product_title',
            },
            {
                name: 'local_stock_request_value',
                label: 'stock_management.local_stock_request_value',
                cell: ({row: {original}}) => {

                    return (
                        <div>
                            <span className="--dBlock">{t('stock_management.quantity_ordered')}: {original.id_reseller === 0 ? t('stock_management.stock_alert') : original.local_stock_request_value}</span>
                            {original.local_stock_request_status === 0 && (
                                <span className="--dBlock">{t('stock_management.quantity_received')}: {original.local_stock_request_added_value_to_stock}</span>
                            )}
                        </div>
                    )
                }
            },
            {
                name: 'created_at',
                label: 'stock_management.created_at',
                cell: ({value}) => isoDateConverterWithTime(new Date(value), '-', ':')
            },
            {
                name: 'id_reseller',
                label: 'stock_management.created_by',
                cell: ({row: {original}}) => original.id_reseller === 0 ? (
                    <>
                        <span>{t('stock_management.automatically')}</span>
                        <LocalStockHistory item={original}/>
                    </>
                ) : t('reseller')
            },
            {
                name: 'local_stock_request_status',
                label: 'stock_management.status.label',
                cell: ({value}) => <LocalStockRequestStatusLabel status={value}/>
            },
            {
                name: 'action',
                label: 'stock_management.action',
                cell: ({row: {original}}, query) => {
                    if(original.local_stock_request_status !== 1) return null

                    return  (
                        <div className="stockManagementActions">
                            <LocalStockRequestFinalizing
                                idShop={id}
                                item={original}
                                query={query}
                                productListQuery={stockTable.query}
                            />
                            {original.id_reseller > 0 ? (
                                <LocalStockRequestCancel
                                    idShop={id}
                                    idRequest={original.id_local_stock_request}
                                    query={query}
                                />
                            ) : (
                                <LocalStockRequestResend
                                    idShop={id}
                                    idRequest={original.id_local_stock_request}
                                    query={query}
                                />
                            )}
                        </div>
                    )
                }
            },
        ],
        config: {
            enabled: !!hasPermission(permissions.posStockManagementEdit),
            searchParamPrefix: 'request'

        },
        params: {}
    })



    if (isNaN(Number(id)) || (posQuery.isError && posQuery.error?.error === 'shop_not_found'))
        return <Error404/>

    if(posQuery.isError && posQuery.error?.error === 'reseller_not_assigned_to_this_shop')
        return <Error401/>


    return (
        <div className="wrapper -xxl">
            <div className="resMS">
                <div className="resMS__header">
                    {posQuery.isLoading ? (
                        <Preloader/>
                    ) : (
                        <>
                            <TableComponent
                                {...stockTable}
                                title={t('stock_management.stock_list', posQuery.data)}
                                filters={stockFilter}
                                SelectedActionsComponent={LocalStockRequest}
                                customActionsVariables={{
                                    idShop: id,
                                    requestsTableQuery: requestsTable.query
                                }}
                            />
                            {!!hasPermission(permissions.posStockManagementEdit) && (
                                <TableComponent
                                    {...requestsTable}
                                    title={t('stock_management.requests_list')}
                                />
                            )}
                        </>
                    )}

                </div>
            </div>
        </div>

    )
}