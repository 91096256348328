import Provider from "./Provider";
import {Route, Switch} from "react-router-dom";
import {getStepsRoutes} from "../../misc/Helpers";
import StepsFunnel from "./StepsFunnel";
import React from "react";
import {useSteps} from "../../hooks/modules/no_machine_shop";
import {makeStore, ModuleProvider} from "../../hooks";
import {AppProvider} from "../../misc/_index";

export default function Funnel(props) {
    const steps = useSteps()

    const contextValue = makeStore({
        storeName: 'noMachineShop',
        steps,
    })

    return (
        <AppProvider>
            <Provider>
                <Switch>
                    <ModuleProvider value={contextValue}>
                        <Route
                            exact path={getStepsRoutes(steps)}
                            component={StepsFunnel}
                        />
                    </ModuleProvider>
                </Switch>
            </Provider>
        </AppProvider>
    )
}
