import {useFormBuilder, useFormBuilderConfig, useFormRulesBuilder} from "../hooks";
import {getCustomerCustomAddressForm, isEnabledByMarket} from "../../utils/MarketConfig";
import {PhoneInput, WithCustomAddressCheckbox} from "../presets";
import React, {useEffect, useMemo, useRef} from "react";
import {FormBuilderComponent, FormGroup} from "../components";
import {useWatch} from "react-hook-form";
import {useCountry, useFieldFormat, useFormHelper, useNespressoMemberAddresses, useTranslation} from "../../hooks";
import {SelectController} from "../controllers";
import getObjectValue from "../../helpers/getObjectValue";

export default function ExistingCustomerFormFragment({
    nespressoMember,
    options,
}) {
    const {t} = useTranslation()
    const phoneRef = useRef(null)
    const {hideExistingMemberCustomAddress} = useCountry()
    const {getFieldsConfig, getPostcodeAndCityConfig} = useFormBuilderConfig()
    const {query, getSelectOptions, getAddressById, getMainAddressId} = useNespressoMemberAddresses(nespressoMember)
    const {formMethods, setValue} = useFormHelper()
    const {formats} = useFieldFormat()
    const formRules = useFormRulesBuilder({
        'memberAddress': b => b.requiredIf('withCustomAddress', false),
    }, formMethods)
    const withCustomAddress = useWatch({
        control: formMethods.control,
        name: 'withCustomAddress',
    })
    const customAddressIsCompanyAddress = useWatch({
        control: formMethods.control,
        name: 'customAddress.isCompanyAddress',
    })
    const memberAddress = useWatch({
        control: formMethods.control,
        name: 'existingAddress.memberAddress',
    })
    const address = useMemo(() => {
        return getAddressById(getObjectValue(memberAddress))
    }, [getAddressById, memberAddress])
    const formConfig = useMemo(() => {
        const arr = [
            ['memberAddress'],
            ['phone'],
        ]

        if (!address)
            return arr

        if (!address.city || !address.postcode) {
            arr.push(...getPostcodeAndCityConfig())
        }
        return arr
    }, [address, getPostcodeAndCityConfig])

    useEffect(() => {
        if(memberAddress) return;

        const addressId = getMainAddressId();
        if(!!addressId)
            setValue('existingAddress.memberAddress', getSelectOptions().find(a => a.value === addressId))

        console.log(memberAddress)


    }, [JSON.stringify(query)])

    const {components: detailsComponents} = useFormBuilder({
        formConfig,
        inputVisibility: {
            'phone': !!memberAddress,
        },
        inputComponents: {
            'memberAddress': (
                <SelectController
                    name="memberAddress"
                    rules={formRules.values.memberAddress}
                    label={t('choose_your_delivery_address')}
                    selectOptions={getSelectOptions()}
                    options={{
                        isLoading: query.isLoading,
                        isFetching: query.isFetching,
                        // autoSelectFirstIfOneOption: !withCustomAddress
                    }}
                />
            ),
            'phone': (
                <PhoneInput
                    options={{
                        inputProps: {
                            ref: phoneRef,
                        }
                    }}
                />
            ),
        }
    })
    const {components: customAddressComponents} = useFormBuilder({
        formConfig: getFieldsConfig('existingCustomerNewAddress') ?? getCustomerCustomAddressForm(),
        inputVisibility: {
            'title': !customAddressIsCompanyAddress,
            'taxId': !customAddressIsCompanyAddress,
            'taxCategory': !customAddressIsCompanyAddress,
            'profession': !customAddressIsCompanyAddress,
            'companyName': !!customAddressIsCompanyAddress,
            'companyTaxId': !!customAddressIsCompanyAddress,
            'companyTaxCategory': !!customAddressIsCompanyAddress,
            'companyProfession': !!customAddressIsCompanyAddress,
            'companyTaxOffice': !!customAddressIsCompanyAddress,
        },
        inputComponents: {},
    })

    useEffect(() => {
        if (memberAddress) {
            formMethods.setValue('withCustomAddress', false)
            if (address) {
                if(!formats.phone.getMask()) {
                    formMethods.setValue('existingAddress.phone', address.phone, {
                        shouldValidate: true,
                        shouldTouch: true,
                        shouldDirty: true
                    })
                }

                const value = phoneRef?.current?.value
                if (!value) return

                const addressPhone = address.phone
                    .split('')
                    .reverse()
                    .join('')

                let replacerIdx = 0
                const res = value.split('')
                    .reverse()
                    .map((char, idx) => {
                        const replacer = addressPhone.charAt(replacerIdx)
                        if (replacer && char.trim()) {
                            replacerIdx++
                            return char.replace('_', replacer)
                        }
                        return char
                    })
                    .reverse()
                    .join('')
                    .replace(/_/g, '')

                if (res) {
                    formMethods.setValue('existingAddress.phone', res, {
                        shouldValidate: true,
                        shouldTouch: true,
                        shouldDirty: true
                    })
                }
            }
        }
    }, [query.data, memberAddress])

    useEffect(() => {
        if (withCustomAddress) {
            formMethods.setValue('existingAddress.memberAddress', null, {
                shouldValidate: true
            })
            formMethods.setValue('existingAddress.phone', undefined)
        }
    }, [withCustomAddress])

    return (
        <>
            <FormBuilderComponent prefix="existingAddress" components={detailsComponents}/>

            {!!(options.customAddressVisible && !hideExistingMemberCustomAddress()) && (
                <>
                    <FormGroup>
                        <WithCustomAddressCheckbox/>
                    </FormGroup>
                    {!!withCustomAddress && (
                        <FormBuilderComponent prefix="customAddress" components={customAddressComponents}/>
                    )}
                </>
            )}
        </>
    )
}
