import React, {useState} from "react";
import {ApiException} from "../../errors";
import {GTM} from "../../misc/_index";
import {
    useCustomer, useGTM,
    useModuleContext, useModuleStepsNavigation,
    useNespressoMember,
    usePromotions,
    useReseller,
    useTranslation
} from "../../hooks";
import {AuthForm} from "../../forms";
import {NespressoMember, nespressoMemberFromLoginData} from "../../services/NespressoMemberService";
import {Button} from "../buttons/_index";

export default function UserLogin({clearPromo}) {
    const {t} = useTranslation()
    const {isLoggedIn} = useReseller()
    const {store, storeName, reducer, dispatch} = useModuleContext()
    const {loginMutation, error, setError} = useNespressoMember()
    const stepsNavigation = useModuleStepsNavigation()
    const {userLoggedIn} = useGTM()
    const {data: customer} = useCustomer()
    const promotions = usePromotions(storeName)
    const [isLoading, setIsLoading] = useState(false)

    const handleLogin = (data) => {
        setIsLoading(true)
        const payload = {
            login: data.email,
            password: data.password,
            recaptchaToken: data.recaptchaToken,
        }
        loginMutation.mutateAsync(payload)
            .then(async (data) => {
                const nespressoMember = nespressoMemberFromLoginData(data, {email: payload.login})
                if (store.promotion) {
                    const promotion = await promotions.fetchRaw({
                        hasAccount: true,
                        params: {member_number: nespressoMember.memberNumber},
                        payload: {id_promotions: [store.promotion.id_promotion]}
                    })

                    if (!promotion.length) {
                        dispatch(reducer?.setPromotion(null))
                        dispatch(reducer?.setOrderAccount(null, false, false))
                    } else {
                        dispatch(reducer?.setOnlyPromotion({
                            ...store.promotion,
                            promotion_popups: promotion[0].promotion_popups
                        }))
                    }
                }

                dispatch(reducer.setCustomer(null))
                dispatch(reducer.setNespressoMember(nespressoMember))
                dispatch(reducer.setIsNewMember(false))
                dispatch(reducer.setNespressoMemberExist(true))

                if ((nespressoMember?.email !== payload.login) && !store?.isPostPurchasePromoCode) {
                    dispatch(reducer?.setPromotion(null))
                    dispatch(reducer?.setOrderAccount(null, false, false))
                }
                userLoggedIn(nespressoMember.memberNumber)
                setIsLoading(false)
            })
            .catch(error => {
                setIsLoading(false)
                if (error instanceof ApiException) {
                    setError(t(`validation.${error.error}`) || t('error.undefined_error'))
                } else {
                    setError(t('error.undefined_error'))
                }
            })
    }

    const onClickContinueReseller = () => {
        const nespressoMember = new NespressoMember({
            email: customer.details.email,
            rawData: customer,
        })
        dispatch(reducer.setIsNewMember(false))
        dispatch(reducer.setNespressoMember(nespressoMember))
        dispatch(reducer.setCustomer({
            details: {
                email: customer.details.email,
                lang: customer.details.lang,
            }
        }))
        stepsNavigation.navToNext()
    }

    return (
        <>
            {isLoggedIn ? (
                <div>
                    <Button
                        type="primary"
                        label={t('continue')}
                        onClick={onClickContinueReseller}
                    />
                </div>
            ) : (
                <AuthForm
                    state={{
                        isLoading: loginMutation.isLoading || isLoading,
                        error: error,
                    }}
                    onSubmit={handleLogin}
                />
            )}
        </>
    )
}