export default function FormGroup({
    elementProps,
    children,
}) {
    return (
        <div
            className="--mb2_5"
            {...elementProps}
        >
            {children}
        </div>
    )
}
