import {useQuery} from "@tanstack/react-query";
import * as authorizationActions from "../actions/AuthorizationActions";
import {ApiException} from "../errors";
import {useEffect, useMemo, useRef} from "react";
import {getAddressLabel, nespressoMemberAddress} from "../services/NespressoMemberAddresses";
import {useCivilityOptions, useModuleContext, useReseller} from "./index";
import {useDispatch} from "react-redux";
import {useResellerActions} from "../actions/useResellerActions";

export default function useNespressoMemberAddresses(member) {
    const {reducer, store} = useModuleContext()
    const {isLoggedIn} = useReseller()
    const resellerActions = useResellerActions()
    const dispatch = useDispatch()
    const civilityOptions = useCivilityOptions()

    const query = useQuery(
        ['nespressoMember', member?.memberNumber, 'addresses'],
        async () => {
            let action = undefined
            if(isLoggedIn) {
                action = () => resellerActions.getMemberAddressesByMemberNumber(member?.memberNumber)
            } else {
                action = () => authorizationActions.getAddresses({
                    token: member.token,
                    member_number: member.memberNumber
                })
            }

            const {data} = await action()

            if (data.status === 'success')
                return data.data.map(a => nespressoMemberAddress(a))
                    .map(a => ({
                        ...a,
                        title: civilityOptions.find(a.title?.toString())
                    }))
            throw new ApiException(data.messages[0], data)
        },
        {
            enabled: !!member,
            initialData: store.addresses,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                dispatch(reducer.setAddresses(data))
            },
        }
    )

    function getSelectOptions() {
        if(!query.isSuccess)
            return []
        return query.data.map(a => ({
            label: getAddressLabel(a),
            value: a.addressId,
        }))
    }

    function getAddressById(id) {
        if(!query.isSuccess || !id)
            return null
        return query.data.find(a => a.addressId?.toString() === id.toString())
    }

    function getMainAddressId() {
        if(!query.isSuccess)
            return null

       const mainAddresses = query.data.filter(a => a?.isMain)

        if(mainAddresses.length === 1)
            return mainAddresses[0].addressId

        return null;
    }

    return {
        query,
        getSelectOptions,
        getAddressById,
        getMainAddressId,
    }
}
