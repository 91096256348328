import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiException} from "../../../errors";
import * as shopActions from "../../../actions/ShopActions";
import {useEffect, useState} from "react";
import * as authActions from "../../../actions/AuthorizationActions";

export default function useResellerRegistration(){
    const params = useParams()
    const [message, setMessage] = useState({type: '', message: ''})
    const [formErrors, setFormErrors] = useState(null)


    const shopsByTokenQuery = useQuery(
        ['shopByToken', params.token],
        async () => {
            const {data} = await shopActions.getShopByToken(params.token)
            if (data.status === 'success') return data
            throw new ApiException(data.messages[0], data)
        },
        {
            enabled: false,
            onError: ({error}) => setMessage({type: 'warn', message: `validation.${error}`}),
        }
    )

    const registryMutation = useMutation(
        async (payload) => {
            const formData = {
                id_shop: payload.shop.value ?? 0,
                reseller_email: payload.email ?? '',
                reseller_firstname: payload.firstname ?? '',
                reseller_lastname: payload.lastname ?? '',
                reseller_login: payload.login ?? '',
                reseller_number: payload.number ?? 0,
                account_role: payload.account_role.value ?? 'pos_user'
            }
            const {data} = await authActions.registerReseller(formData)
            if (data.status === 'success') return data

            throw new ApiException(data.messages[0], data)
        },
        {
            onMutate: () => {
                setMessage({type: '', message: ''})
                setFormErrors(null)
            },
            onSuccess: () => {setMessage({type: 'success', message: 'reseller_registration.register_success'})},
            onError: ({error}) => {
                setMessage({type: 'error', message: 'reseller_registration.register_error.global'})
                const errors = []
                Object.keys(error).forEach((k) => {
                    const name = k.replace('reseller_', '')
                    errors.push({
                        name,
                        message: `reseller_registration.register_error.${name}.${error[k][0].toLowerCase()}`
                    })
                })
                setFormErrors(errors)
            }
        }
    )

    useEffect(() => {
        if(params.token) shopsByTokenQuery.refetch()
    }, [params.token])

    return {
        isLoading: registryMutation.isLoading,
        byToken: !!params.token,
        shopsByTokenQuery,
        registryMutation,
        message,
        formErrors
    }
}
