
const SET_PAYMENT_SESSION = 'payment/setPaymentSession';
const CLEAR_STORE = 'payment/clearStore';


const initialState = {
    paymentSession: null,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_PAYMENT_SESSION: return {...state, paymentSession: action.paymentSession}

        case CLEAR_STORE:
            return {
                ...initialState,
            }

        default: return state
    }
}


export const setPaymentSession = (paymentSession) => ({type: SET_PAYMENT_SESSION, paymentSession})
export const clearStore = () => ({type: CLEAR_STORE})



export default reducer;
