import {useForm, useWatch} from "react-hook-form";
import React, {useEffect} from "react";
import {useModuleContext, useTranslation} from "../../hooks";
import {useFormRulesBuilder} from "../../forms/hooks";
import {CartFormFragment, MainFormFragment, PaymentFormFragment} from "./index";
import {Form} from "../../forms";
import {Button} from "../../components/buttons/_index";
import {InfoComponent} from "../../components/_index";

export default function TpsForm({onSubmit, state, cartError, hideButtons}) {
    state = {
        isLoading: false,
        error: null,
        ...state,
    }

    const {store} = useModuleContext()
    const {t} = useTranslation()

    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            cart: [],
        }
    })

    const companyAddress = useWatch({control: formMethods.control, name: 'companyAddress'})
    const cart = useWatch({control: formMethods.control, name: 'cart'})
    const customCompanyAddress = useWatch({control: formMethods.control, name: 'customCompanyAddress'})

    const formRules = useFormRulesBuilder({
        'promotion': b => b.required(),
        'paymentMethod': b => b.required(),
        'machine': b => b.requiredIf('serialNumber', false),
        'title': b => b.required(),
        'name': b => b.requiredIf('companyAddress', false),
        'surname': b => b.requiredIf('companyAddress', false),
        'customerNumber': b => b.minLength(3).maxLength(8),
        'posId': b => b.required(),
        'purchaseDate': b => b.required(),
        'receipt': b => b.required(),
        'customTitle': b => b.required(),
        'customName': b => b.requiredIf('customCompanyAddress', false),
        'customSurname': b => b.requiredIf('customCompanyAddress', false),
    }, formMethods)

    useEffect(() => {
        formMethods.clearErrors()
    }, [companyAddress])

    useEffect(() => {
        cartError.setCartError('')
    }, [cart])




    return (
        <>
            <Form
                formMethods={formMethods}
                formRules={formRules}
                onSubmit={onSubmit}
                state={state}
            >
                <MainFormFragment state={state}/>
                {!!store.categories?.length &&
                    <CartFormFragment
                        cartError={cartError}
                        hiddeButtons={hideButtons}
                        state={state}
                    />
                }
                {!!store.promotion && <PaymentFormFragment/>}

                {!hideButtons && (
                    <div className="tpsForm__footer --mb4">
                        <Button
                            btnType="submit"
                            label={t('tps.submit_form')}
                            disabled={state.isDisabled}
                            isLoading={state.isLoading}
                        />
                    </div>
                )}

            </Form>

        </>
    )
}