import Axios from "../utils/Axios";
import {getURLSearchParams} from "../misc/Helpers";


export const getAll = () => {
    return Axios.get(`/machine`);
}


export const validateSerialNumber = (formData, paramObj = null) => {
    const params = getURLSearchParams(paramObj)
    return Axios.post(`/validate-serial-number${params}`, formData);
}


export const validateSerialNumberTps = (formData) => {
    return Axios.post(`/validate-serial-number-tps`, formData);
}


export const getSerialNumberFromImage = (data) => {
    return Axios.post(`/validate-machine`, data);
}
