import './style.scss'
import {AiOutlineDoubleLeft, AiOutlineDoubleRight,} from "react-icons/ai";

export default function TablePagination({data, setPage}) {
    if (!data) return null;

    const canRender = (number) => {
        if (data.current_page === 1) return number <= 3
        if (data.current_page === data.last_page) return number >= data.last_page - 2
        return number >= data.current_page - 2 && number <= data.current_page + 2
    }

    return (
        <div className="pagination">
            <ul>
                <li>
                    <button
                        type="button"
                        onClick={() => setPage(1)}
                        disabled={data.current_page === 1}
                    >
                        <AiOutlineDoubleLeft/>
                    </button>
                </li>
                {new Array(data.last_page).fill(null)
                    .map((_, idx) => idx + 1)
                    .map((i) => {
                        if (canRender(i)) {
                            return (
                                <li key={i}>
                                    <button
                                        type="button"
                                        className={`number ${i === Number(data.current_page) ? '-active' : ''}`}
                                        onClick={() => setPage(i)}
                                    >
                                        {i}
                                    </button>
                                </li>
                            )
                        }
                        return null;
                    })}
                <li>
                    <button
                        type="button"
                        onClick={() => setPage(data.last_page)}
                        disabled={data.current_page === data.last_page}
                    >
                        <AiOutlineDoubleRight/>
                    </button>
                </li>
            </ul>
        </div>
    )
}
