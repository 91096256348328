import {useCountry, useCurrentLanguage} from "./index";

export default function useCalleoChat({
    enabled
} = {
    enabled: false
}) {
    const {data: country} = useCountry()
    const {currentLanguage} = useCurrentLanguage()

    const launchChat = () => {
        if (typeof window.calleoChat === 'function' && enabled) {
            console.log('launch calleo chat')
            window.calleoChat('startChat')
        }
    }

    const init = () => {
        if (!country || !country.country_chatbot_library_link) return

        console.log('init calleo chat')

        window.initCalleoChat(
            window,
            document,
            "script",
            // "https://www.contact.nespresso.com/",
            // "https://www.vst.contact.nespresso.com/",
            country.country_chatbot_library_link,
            country.api_market,
            currentLanguage,
            "b2c"
        )
    }

    return {
        isEnabled: !!country?.country_chatbot_enabled,
        launchChat,
        init,
    }
}
