import './style.scss'
import {HiChatAlt2} from "react-icons/hi";
import {useCalleoChat, useReseller} from "../../hooks";

export default function ChatBotButton() {
    const {isLoggedIn} = useReseller()
    const calleoChat = useCalleoChat({enabled: !isLoggedIn})

    if(isLoggedIn)
        return null

    return calleoChat.isEnabled && (
        <button
            type="button"
            className="chatBotButton"
            onClick={calleoChat.launchChat}
        >
            <HiChatAlt2 />
        </button>
    )
}
