import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {reducers, useCart, useTranslation} from "../misc/Hooks";
import {useRouteMatch} from "react-router-dom";
import {Routes} from "../utils/_index";
import {scrollVertically} from "../misc/Helpers";

export default function CartBox2(props) {
    const match = useRouteMatch();
    const dispatch = useDispatch()
    const store = useSelector(state => ({
        cartVisible: state[props.storeName]?.cartVisible,
    }))
    const {capsulesCount} = useCart(props.storeName);
    const t = useTranslation();

    const onClickCart = () => {
        const reducer = reducers[props.storeName]

        const cartRef = document.querySelector('#summaryStepCart')
        if(cartRef) {
            scrollVertically(cartRef, 10, 'smooth')
        }

        if(!reducer) return
        dispatch(reducer.setCartVisible(!store.cartVisible))

    }


    if (match.url.match(Routes.V2_RESELLER)) {
        return null;
    }


    return (
        <div className="basket basket2">
            <button className="basket__info" onClick={onClickCart}>
                <img src={require('../assets/img/icons/basket.svg').default} alt="" className="basket__infoThumb"/>
                <span className="basket__infoCount">{capsulesCount}</span>
            </button>
        </div>
    );
}
