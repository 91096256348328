import React, {useEffect, useState} from "react";
import {useTranslation} from "../../../hooks";
import {useChangeUserStatus, useRenewalCertificate, useResellerVerify} from "../users/hooks";
import {Select} from "../../../forms/inputs";
import {Button} from "../../../components/buttons/_index";
import {ConfirmActionModal} from "./index";

export default function DefaultSelectedActions({
    query,
    selectedItems,
    actionsOptions,
    setSelectedItems,
    idFieldName,
    confirmingActions
}) {

    const {t} = useTranslation();

    const [action, setAction] = useState(null)
    const {renewalMutation} = useRenewalCertificate(query);
    const {userStatusMutation} = useChangeUserStatus()
    const {verifyMutation} = useResellerVerify()

    const [confirmingModalVisible, setConfirmingModalVisible] = useState(false)

    const takeTheAction = async () => {
        const selectedItemsIds = selectedItems.map(si => si[idFieldName])
        switch (action.value) {
            case 'renew_certificates':
                await renewalMutation.mutate(selectedItemsIds)
                break
            case 'set_status_active':
                await userStatusMutation.mutate({resellerId: selectedItemsIds, status: 1})
                break
            case 'set_status_not_active':
                await userStatusMutation.mutate({resellerId: selectedItemsIds, status: 0})
                break
            case 'accept_reseller_request':
                await verifyMutation.mutate({id: selectedItemsIds, status: 1})
                break
            case 'reject_reseller_request':
                await verifyMutation.mutate({id: selectedItemsIds, status: -1})
                break
        }
        await query.refetch();
        await setSelectedItems([])
    }

    return (
        <>
            <div className="selectedUsersActions">
                <div className="selectedUsersActions__body">
                    <span className="selectedUsersActions__label">{t('for_selected')} ({selectedItems.length}):</span>
                    <Select
                        label={t('action')}
                        selectOptions={actionsOptions}
                        value={action}
                        onChange={(data) => setAction(data)}
                    />
                    <Button
                        label={t('take_the_action')}
                        onClick={confirmingActions ? () => setConfirmingModalVisible(true) : () => takeTheAction}
                        isLoading={renewalMutation.isLoading || userStatusMutation.isLoading}
                        disabled={renewalMutation.isLoading || userStatusMutation.isLoading || !action}
                        style={{width: 'fit-content'}}
                    />
                </div>
            </div>
            {confirmingActions && (
                <ConfirmActionModal
                    visible={confirmingModalVisible}
                    onClose={() => setConfirmingModalVisible(false)}
                    action={action?.label}
                    onConfirm={takeTheAction}
                    items={selectedItems}
                    setSelectedItems={setSelectedItems}
                    columns={[
                        {
                            name: 'name',
                            label: 'reseller_client.full_name',
                            cell: (item) => `${item.reseller_firstname} ${item.reseller_lastname}`
                        },
                        {
                            name: 'reseller_number',
                            label: 'reseller_client.reseller_number',
                            cell: (item) => `${item.reseller_number}`
                        }
                    ]}
                />
            )}
        </>
    )

}