import {useForm, useWatch} from "react-hook-form";
import {useFormRulesBuilder} from "./hooks";
import {Form} from "./index";
import {FormGroup} from "./components";
import {PasswordInput} from "./presets";
import React, {useEffect} from "react";
import {useTranslation} from "../hooks";
import {Button} from "../components/buttons/_index";

export default function ResellerNewPasswordForm({
    onSubmit,
    state,
}){
    state = {
        isLoading: false,
        error: null,
        ...state,
    }
    const {t} = useTranslation()
    const formMethods = useForm({
        mode: 'onChange',
    })
    const formRules = useFormRulesBuilder({
        'rePassword': b => b.required().password().equals('$password', {sameAs: t('password')}),
    }, formMethods)

    const password = useWatch({
        control: formMethods.control,
        name: 'password'
    })

    const rePassword = useWatch({
        control: formMethods.control,
        name: 'rePassword'
    })

    const handleSubmit = (data) => {
        if (typeof onSubmit === 'function')
            onSubmit(data)
    }



    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            onSubmit={handleSubmit}
            options={{
                triggerOnChange: [
                    {target: 'password', trigger: 'rePassword'},
                ]
            }}
        >
            <FormGroup>
                <PasswordInput/>
            </FormGroup>
            <FormGroup>
                <PasswordInput
                    name="rePassword"
                    rules={formRules.values.rePassword}
                    label={t('re_password')}
                />
            </FormGroup>

            <Button
                btnType="submit"
                label={t('continue')}
                disabled={state.isLoading || !password || !rePassword || !formMethods.formState.isValid}
                isLoading={state.isLoading}
            />

        </Form>

    )
}