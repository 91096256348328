import React, {useRef} from 'react';
import DatePicker, {registerLocale} from "react-datepicker";
import * as locales from 'date-fns/locale';
import {useRouteMatch} from 'react-router-dom';

import "react-datepicker/dist/react-datepicker.css";
import Env from "../../../utils/Env";
import {useCountryConfig, useLocalizeObject} from "../../../misc/Hooks";


const languages = Env.LANGUAGES;
languages.map(l => {
    registerLocale(l, locales[l]);
})

export default (props) => {
    const match = useRouteMatch();
    const countryConfig = useCountryConfig();
    const localizeObject = useLocalizeObject();
    const inputRef = useRef(null);


    const handleChange = (d) => {
        props.onChange(d?.toISOString());
        // inputRef.current?.setOpen(false);
    }


    const openPicker = (e) => {
        e.preventDefault();
        inputRef.current?.setOpen(true)
    }


    let formGroupClassNames = '';
    if(props.value || props.error) {
        formGroupClassNames += ' -active';
    }
    if(props.error) {
        formGroupClassNames += ' -invalid';
    }
    if(!props.error && props.value) {
        formGroupClassNames += ' -valid';
    }
    if(props.disabled) {
        formGroupClassNames += ' -disabled';
    }


    return (
        <div className={`formGroup -active ${formGroupClassNames}`}>
            <div className="formGroup__field">
                {!!props.label && (
                    <span className="formGroup__label">{props.label}</span>
                )}

                <DatePicker
                    ref={inputRef}
                    locale={match.params?.lang ?? Env.DEFAULT_LANG}
                    name={props.name ?? ''}
                    minDate={props.min ?? undefined}
                    maxDate={props.max ?? undefined}
                    dateFormat={countryConfig.custom_format?.date_format ?? "dd/MM/yyyy"}
                    placeholderText={localizeObject(countryConfig.custom_format?.date_format_placeholder) ?? "DD/MM/YYYY"}
                    selected={props.value ? new Date(props.value) : null}
                    autoComplete={'off'}
                    disabled={!!props.loading}
                    onChange={handleChange}
                    readOnly={props.disabled}
                />

                {props.loading ? (
                    <span className="formGroup__loading">
                        <img src={require('../../../assets/img/icons/preloader2.gif').default} alt=""/>
                    </span>
                ) : (
                    <button type="button" className="formGroup__fileButton" onClick={openPicker}>
                        <img src={require('../../../assets/img/icons/calendar.svg').default} alt=""/>
                    </button>
                )}

            </div>

            {!!props.error && (
                <span className="formGroup__invalidFeedback">{props.error.message}</span>
            )}

            {(!props.error && props.info) && (
                <p className="formGroup__info" dangerouslySetInnerHTML={{__html: props.info}}/>
            )}

        </div>
    );
}
