import {Controller, useForm, useWatch} from "react-hook-form";
import {useTranslation} from "../../../../hooks";
import {Editor} from "./index";
import {useEffect, useMemo, useRef, useState} from "react";
import {makeClassName, stripHtmlTags} from "../../../../helpers";

const MAX_FILE_SIZE = 8388608

export default function EditorForm({mutation, onSubmit}) {
    const filesInputRef = useRef()
    const {t} = useTranslation()
    const {
        control,
        handleSubmit,
        formState: {isValid},
        setValue,
        trigger,
    } = useForm({
        mode: 'onChange',
    })
    const [focused, setFocused] = useState(false)
    const message = useWatch({
        control,
        name: 'message',
    })
    const files = useWatch({
        control,
        name: 'files',
    })
    const rules = {
        message: {
            validate: v => v && stripHtmlTags(v).length ? true : t('validation.required'),
        },
        files: {
            validate: files => {
                if (!files) return true
                if (Object.values(files).find(f => f.size > MAX_FILE_SIZE)) {
                    return t('error.file_too_big', MAX_FILE_SIZE / 1000000)
                }
                return true
            }
        },
    }

    useEffect(() => {
        if (mutation.isSuccess) {
            setValue('message', '')
            setValue('files', null)
        }
    }, [mutation.isSuccess])

    const handleBlur = (e, callback) => {
        setFocused(false)
        callback(e)
    }

    const handleFocus = () => {
        setFocused(true)
    }

    const onClickUpload = () => {
        filesInputRef.current.click()
    }

    const onClickRemoveFile = (index) => {
        const arr = Array.from(files)
        if (arr.length) {
            const list = new DataTransfer()
            arr.filter((_, idx) => idx !== index)
                .map(file => list.items.add(file))
            setValue('files', list.files)
            trigger('files')
        }
    }

    const editorClassNames = useMemo(() => makeClassName('editorForm', {
        '-focused': focused || stripHtmlTags(message),
    }), [focused, message])


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={editorClassNames}>
                <div className="editorForm__editor">
                    <Controller
                        name="message"
                        control={control}
                        rules={rules.message}
                        render={({ref, ...props}) => (
                            <Editor
                                {...props}
                                inputRef={ref}
                                config={{
                                    maxFileSize: MAX_FILE_SIZE,
                                    onClickUpload,
                                    onClickRemoveFile,
                                    files,
                                    submitButtonState: {
                                        loading: mutation.isLoading,
                                        disabled: !isValid || mutation.isLoading,
                                    },
                                }}
                                onFocus={handleFocus}
                                onBlur={e => handleBlur(e, props.field.onBlur)}
                            />
                        )}
                    />

                    <Controller
                        name="files"
                        control={control}
                        rules={rules.files}
                        render={({field, fieldState}) => (
                            <input
                                ref={filesInputRef}
                                type="file"
                                name="files"
                                onChange={e => field.onChange(e.target.files)}
                                multiple
                                hidden
                            />
                        )}
                    />
                </div>
            </div>
        </form>
    )
}
