import {InvalidFeedback} from "../components";
import {useInputValidationClassNames} from "../hooks";
import {ButtonClose} from "../../components/buttons/_index";
import React, {useMemo, useState} from "react";
import {useDropzone} from "react-dropzone";
import {Config} from "../../utils/_index";
import {useTranslation} from "../../hooks";

/**
 * Dropzone
 * @param type
 * @param inputRef
 * @param fieldState
 * @param name
 * @param label
 * @param value
 * @param {{inputProps, isRequired: boolean, maxLength: number, renderIcon}} options
 * @param onChange
 * @param onBlur
 * @returns {JSX.Element}
 * @constructor
 */
export default function Dropzone({
    inputRef,
    fieldState,
    name,
    label,
    value,
    options,
    onChange,
    onBlur,
}) {
    options = {...defaultOptions, ...options}

    const {t} = useTranslation()

    const [fileSizeValidationError, setFileSizeValidationError] = useState(false)

    const onDrop = acceptedFiles => {
        if (acceptedFiles[0]) {
            onChange(acceptedFiles[0])
        }
    }

    const fileValidator = (file) => {
        setFileSizeValidationError(false)

        if (file.size > Config.FILE_SIZE_MB * 1024 * 1024)
            setFileSizeValidationError(true)
    }

    const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone({
        onDrop,
        validator: fileValidator,
        accept: options.inputProps?.accept,
        maxFiles: 1,
        maxSize: Config.FILE_SIZE_MB * 1024 * 1024,
    })
    const {ref, ...inputProps} = getInputProps()
    const {className} = useInputValidationClassNames({
        value,
        fieldState,
    })
    const fileUrl = useMemo(() => {
        if (value instanceof File)
            return URL.createObjectURL(value)
        if (typeof value === 'string')
            return value
        return undefined
    }, [value])

    const onClickRemove = () => {
        onChange(undefined)
    }

    return (
        <fieldset className={className}>
            <div className="fieldset__wrapper">
                <div className="uploadBox">
                    {!!value && (
                        <div className="uploadBox__close">
                            <ButtonClose
                                positionClass="-topRight"
                                onClick={onClickRemove}
                                disabled={!!options.inputProps?.disabled}
                            />
                        </div>
                    )}
                    <div {...getRootProps()}
                         className={`uploadBox__area ${fileSizeValidationError ? '-isInvalid' : ''}`}>
                        {value?.type?.match(/^image/) ? (
                            <img src={fileUrl} alt="" className="uploadBox__uploadedImg"/>
                        ) : value?.type?.match(/^application\/pdf/) ? (
                            <>
                                <embed
                                    src={fileUrl}
                                    width='100%'
                                    height="375"
                                    type="application/pdf"
                                />
                            </>
                        ) : (
                            <div className={`uploadBox__content ${isDragActive ? '-hover' : ''}`}>
                                <input
                                    {...inputProps}
                                    ref={r => {
                                        ref.current = r
                                        inputRef.current = r
                                    }}
                                    name={name}
                                    multiple={false}
                                    accept={options.inputProps?.accept}
                                />

                                <div className="uploadBox__areaImg">
                                    {options.isLoading ? (
                                        <img src={require('../../assets/img/icons/preloader2.gif').default} alt=""/>
                                    ) : (
                                        <img src={require('../../assets/img/icons/camera.svg').default} alt=""/>
                                    )}
                                </div>

                                <span className="uploadBox__areaText" dangerouslySetInnerHTML={{__html: label}}/>
                                {fileSizeValidationError && (
                                    <span className="uploadBox__areaText --error"
                                          dangerouslySetInnerHTML={{__html: t('maximum_file_size', Config.FILE_SIZE_MB)}}/>
                                )}
                            </div>
                        )}
                    </div>
                    {(options.progress > 0 && options.progress < 100) && (
                        <div className="uploadBox__progress" style={{width: `${options.progress}%`}}/>
                    )}
                </div>
            </div>
            <InvalidFeedback error={fieldState.error}/>
        </fieldset>
    )
}

const defaultOptions = {
    inputProps: null,
    isRequired: false,
    isLoading: false,
    maxLength: 0,
    renderIcon: null,
    progress: 0,
}
