import {FormGroup, FormRow} from "../../../forms/components";
import {TextInputController} from "../../../forms/controllers";
import {useFieldFormat, useFormHelper, useTranslation} from "../../../hooks";
import {useFormRulesBuilder} from "../../../forms/hooks";
import {PhoneInput} from "../../../forms/presets";

export default function SearchNespressoMemberType4Fragment() {
    const {t} = useTranslation()
    const {formMethods} = useFormHelper()
    const {formats} = useFieldFormat()
    const formRules = useFormRulesBuilder({
        firstname: b => b.required(),
        lastname: b => b.required(),
        phone: b => b.required()
            .pattern(formats.phone.pattern),
    }, formMethods)

    return (
        <>
            <FormRow>
                <FormGroup>
                    <TextInputController
                        name="firstname"
                        label={t('firstname')}
                        rules={formRules.values.firstname}
                    />
                </FormGroup>
                <FormGroup>
                    <TextInputController
                        name="lastname"
                        label={t('lastname')}
                        rules={formRules.values.lastname}
                    />
                </FormGroup>
            </FormRow>
            <FormGroup>
                <PhoneInput
                    inputName="phone"
                    inputLabel={t('phone')}
                    rules={formRules.values.phone}
                />
            </FormGroup>
        </>
    )
}
