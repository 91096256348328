import {useCountry, useReseller} from "../../hooks";
import {getCustomerDetailsForm} from "../../utils/MarketConfig";

export default function useFormBuilderConfig() {
    const {data: country} = useCountry()
    const {isLoggedIn} = useReseller()

    const getFieldsConfig = (type) => {
        if (!type) {
            type = isLoggedIn ? 'assisted' : 'unassisted'
        }
        return country.forms?.find(i => i.type === type)?.fields
    }

    const getPostcodeAndCityConfig = (type) => {
        const fields = getFieldsConfig(type)
        if(!fields) return null

        const acceptedFields = ['postcode', 'city', 'cityByPostcode', 'postcodeByCity']

        return fields
            .reduce((arr, row) => {
                if(acceptedFields.find(i => row.includes(i))) {
                    const result = row.filter(i => acceptedFields.includes(i))
                    if(result?.length)
                        arr.push(result)
                }
                return arr
            }, [])
    }

    return {
        getFieldsConfig,
        getPostcodeAndCityConfig,
    }
}
