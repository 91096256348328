import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import PagePreloader from "../../components/PagePreloader";
import * as shopActions from '../../actions/ShopActions'
import {useHistory, useLocation, useParams} from "react-router-dom";
import * as subscriptionReducer from '../../redux/subscriptionReducer';
import {useNavigation} from "../../misc/Hooks";
import Routes from "../../utils/Routes";
import {SelectOption} from "../../services";
import {useCountry} from "../../hooks";


export default function StepsProvider(props) {
    const location = useLocation()
    const dispatch = useDispatch()
    const {formatShopLabel} = useCountry()
    const history = useHistory()
    const navigation = useNavigation()
    const store = useSelector(({main, subscription}) => ({
        country: main.country,
        qrCodeUrl: subscription.qrCodeUrl,
        shopData: subscription.shopData,
    }))
    const [loaded, setLoaded] = useState(isLoaded(location))

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const storeParams = new URLSearchParams(store.qrCodeUrl)

        if(params.get('qrCode')) {
            if(params.get('keyAccount') !== storeParams.get('keyAccount')
                || params.get('pos') !== storeParams.get('pos')) {
                dispatch(subscriptionReducer.clearStore())
            }
            dispatch(subscriptionReducer.setQrCodeUrl(location.search))
            history.replace(navigation(Routes.SUBSCRIPTION_MACHINE))
        }
    }, [location])

    useEffect(() => {
        if(store.qrCodeUrl) {
            fetchShops()
                .then(() => setLoaded(true))
        }
    }, [store.qrCodeUrl])

    const fetchShops = async () => {
        await shopActions.getAll(store.country.id_country)
            .then(r => {
                const res = r.data
                if(res.status === 'success') {
                    dispatch(subscriptionReducer.setShops(res.data))
                    const params = new URLSearchParams(store.qrCodeUrl)
                    const shopGroupId = params.get('keyAccount')
                    const posNumber = params.get('pos')
                    if(shopGroupId) {
                        const shopGroup = res.data.find(g => g.shop_group_number === shopGroupId)
                        if(!shopGroup)
                            return
                        const data = {
                            shopGroup: new SelectOption(shopGroup.shop_group_title, shopGroup.id_shop_group),
                        }
                        if(posNumber) {
                            const shop = shopGroup.shops.find(s => s.shop_pos === posNumber)
                            if(shop) {
                                data.shop = new SelectOption(formatShopLabel(shop), shop.id_shop)
                            }
                        }
                        dispatch(subscriptionReducer.setShopData(data))
                    }

                }
            })
            .catch(err => {
                console.error('Cannot fetch shops!', err)
            })
    }

    if (!loaded)
        return <PagePreloader/>

    return props.children
}


function isLoaded(location) {
    const params = new URLSearchParams(location.search)
    return !params.get('keyAccount');

}
