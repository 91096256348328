import Env from "../utils/Env";


class GTM {
    static marketCode = undefined;
    static currency = undefined;
    static version = null;
    static isProduction = true;
    static gtmID = !!Env.GTM_ID ? Env.GTM_ID : Env.VST_GTM_ID

    static configure(marketCode, currency) {
        this.marketCode = marketCode;
        this.currency = currency;

        if(typeof process !== 'undefined') {
            this.version = process.version
        }

        this.isProduction = Env.ENV === 'prod'
    }


    static async isConfiguredAsync(count = 30) {
        if (this.gtmID) {
            if (this.isConfigured()) {
                return true;
            } else if (count > 0) {
                await new Promise(resolve => setTimeout(resolve, 500))
                return this.isConfiguredAsync(count - 1);
            }
            console.error('GTM configuration error.')
        }
        return false;
    }

    static isConfigured() {
        return this.gtmID && this.marketCode && this.currency;
    }

    /**
     * Checks GTM configuration, creates an object and then sends it.
     * @param {Object} object
     * @param {string} eventName
     * @returns {Promise<void>}
     */
    static async baseGTM(object, eventName) {
        const isConfigured = await this.isConfiguredAsync();
        if (!isConfigured) {
            console.log('error GTM config')
            return;
        }

        const obj = {
                event: eventName,
                ...object
        }

        // if(Env.ENV !== 'prod')
        //     console.log(obj)

        window.gtmDataObject = window.gtmDataObject || []
        window.gtmDataObject.push(obj);
        // window.dataLayer.push(obj.dataLayer);
    }

    /**
     * GTM event - once per page URL refresh when a user occurs on OPR website.
     * @param {boolean} isReseller
     */
    static showPage(isReseller) {
        const obj = {
            customerJourneyType: isReseller ? 'assisted' : 'unassisted',
            pageName: 'OPR redemption',
            pageType: 'OPR funnel',
            pageCategory: 'OPR',
            landscape: 'OPR',
            segmentBusiness: 'B2C',
            market: Env.MARKET
        }

        this.baseGTM(obj, 'event_pageView').then(() => undefined).catch(() => undefined)
    }

    /**
     * GTM event - is loaded in the beginning of user's journey (but only after "event_pageView", not before) when the page is loaded.
     */
    static redemptionProcessStarted() {
        const obj = {
            eventCategory: 'User Engagement',
            eventAction: 'OPR Engagement',
            eventLabel: 'Redemption Process Started: Machine Registration',
            nonInteraction: 1
        }
        this.baseGTM(obj, 'customEvent').then(() => undefined).catch(() => undefined)
    }

    /**
     * GTM event - is loaded when a customer successfully enters machine serial number and proceeds to next step.
     * @param {string} machineSerialNumber
     */
    static successfullyEntersMachineSerialNumber(machineSerialNumber) {
        if (!machineSerialNumber) {
            console.error('Error - incorrect machine serial number')
            return;
        }

        const obj = {
            eventCategory: 'User Engagement',
            eventAction: 'OPR Engagement',
            eventLabel: 'Step Completed: Machine Registration',
            machineSerialNumber: machineSerialNumber
        }

        this.baseGTM(obj, 'customEvent').then(() => undefined).catch(() => undefined)
    }

    /**
     * GTM event - is loaded if a customer logs in during the Customer Identification step
     * @param {number} clubMemberID
     */
    static userLoggedIn(clubMemberID) {
        if (!clubMemberID) {
            console.error('Error - incorrect club member ID')
            return;
        }

        const obj = {
            eventCategory: 'User Engagement',
            eventAction: 'OPR Engagement',
            eventLabel: 'Step Completed: Customer Identification with Log In',
            clubMemberID: Number(clubMemberID)
        }

        this.baseGTM(obj, 'customEvent').then(() => undefined).catch(() => undefined)
    }

    /**
     * GTM event - is loaded if a customer NOT logs in during the Customer Identification step
     */
    static userNotLoggedIn() {
        const obj = {
            eventCategory: 'User Engagement',
            eventAction: 'OPR Engagement',
            eventLabel: 'Step Completed: Customer Identification with no Log In'
        }

        this.baseGTM(obj, 'customEvent').then(() => undefined).catch(() => undefined)
    }

    /**
     *  GTM event - when a user completes Promo Choices step
     * @param {string} promotionSelected
     */
    static competePromoChoice(promotionSelected) {
        if (!promotionSelected) {
            console.error('Error - incorrect promotion selected')
            return;
        }

        const obj = {
            eventCategory: 'User Engagement',
            eventAction: 'OPR Engagement',
            eventLabel: `Step Completed: Promo Choices | ${promotionSelected}`
        }

        this.baseGTM(obj, 'customEvent').then(() => undefined).catch(() => undefined)
    }

    /**
     * GTM event - when a user completes Coffee Selection step
     * @param {string} productName
     */
    static completeCoffeeSelection(productName) {
        if (!productName) {
            console.error('Error - incorrect product name')
            return;
        }

        const obj = {
            eventCategory: 'User Engagement',
            eventAction: 'OPR Engagement',
            eventLabel: `Step Completed: Coffee Selection | ${productName}`
        }

        this.baseGTM(obj, 'customEvent').then(() => undefined).catch(() => undefined)
    }

    /**
     * GTM event - when proceeding forward from the customer Information step, whether creating a new account or adding additional information as existing customer
     * @param {boolean} userCreateNewAccount
     * @param {number} clubMemberID
     */
    static finishCustomerInformationStep(userCreateNewAccount, clubMemberID = null) {
        let obj = {
            eventCategory: 'User Engagement',
            eventAction: 'OPR Engagement',
        }

        if (userCreateNewAccount && !!clubMemberID) {
            obj.eventLabel = 'Step Completed: Customer Information with New Account Creation'
            obj.clubMemberID = Number(clubMemberID)
        } else {
            obj.eventLabel = 'Step Completed: Customer Information'
        }

        this.baseGTM(obj, 'customEvent').then(() => undefined).catch(() => undefined)
    }

    /**
     * GTM event - when a user completes Delivery step, upon the Thank you/Order Confirmation page
     */
    static completeDeliveryStep() {
        const obj = {
            eventCategory: 'User Engagement',
            eventAction: 'OPR Engagement',
            eventLabel: 'Redemption Process Completed'
        }

        this.baseGTM(obj, 'customEvent').then(() => undefined).catch(() => undefined)

        return {
            event: 'customEvent',
            ...obj
        }

    }

    static getErrorMessage(name) {
        const baseFile = require(`../assets/translations/_base.json`);

        let slug = {...baseFile};
        const params = name.toString().split('.');

        params.forEach(s => {
            if (typeof slug !== "undefined")
                slug = slug[s];
            else
                slug = name;
        })

        if (!slug) {
            slug = '';
        }

        return slug;
    }

    /**
     * GTM event - tracking errors
     * @param {string} errorType
     * @param {string} errorName
     * @param {string} errorCode
     */
    static errorTracking(errorType, errorName) {

        const translationError = this.getErrorMessage(`error.${errorName}`);
        const translationValidation = this.getErrorMessage(`validation.${errorName}`);

        const translation = !!translationError ? translationError : !!translationValidation ? translationValidation : errorName

        if (!errorType) {
            console.error('Error - incorrect error type')
            return;
        }

        if (!errorName) {
            console.error('Error - incorrect error name')
            return;
        }


        const obj = {
            eventCategory: 'Error Tracking',
            eventAction: 'OPR Engagement Error',
            error_code: `[${errorName}]`,
            error_name: `[${translation}]`,
            error_type: `[${errorType}]`
        }

        this.baseGTM(obj, 'error_tracking').then(() => undefined).catch(() => undefined)
    }

}


export default GTM;
