import './style.scss'
import {useForm} from "react-hook-form";
import {useFormBuilder} from "../../../../forms/hooks";
import {Form} from "../../../../forms";
import {FormBuilderComponent} from "../../../../forms/components";
import {useTranslation} from "../../../../hooks";
import {useEffect, useRef} from "react";
import {RiFilterOffLine} from "react-icons/ri"
import {Preloader} from "../../../../misc/_index";

export default function TableFilters({
    rawData,
    builder,
    onSubmit,
    title,
    isLoading,
    searchPrefix,
    delayFields = [],
    config = {}
}) {
    let defaultValues = {}
    if (!!searchPrefix)
        defaultValues[searchPrefix] = {...rawData}
    else
        defaultValues = {...rawData}

    const formMethods = useForm({
        defaultValues: defaultValues,
    })
    const {t} = useTranslation();

    const {components} = useFormBuilder({...builder})

    const inputValueTimeout = useRef(null)

    useEffect(() => {
        const subscription = formMethods.watch((data, {name, type}) => {

            if (name) {
                if (delayFields.includes(name.replace(searchPrefix ? `${searchPrefix}.` : '', ''))) {
                    clearTimeout(inputValueTimeout.current)
                    inputValueTimeout.current = setTimeout(() => {
                        formMethods.handleSubmit(onSubmit)()
                    }, 500)
                } else formMethods.handleSubmit(onSubmit)()
            }

        })

        return () => subscription.unsubscribe()
    }, [formMethods.watch])

    const clearForm = () => {
        const formValues = formMethods.getValues();
        const values = searchPrefix ? formValues[searchPrefix] : formValues

        Object.keys(values).forEach(key => {
            if (typeof values[key] === "object") {
                formMethods.setValue(`${searchPrefix ? `${searchPrefix}.` : ''}${key}`, null)
            } else {
                formMethods.setValue(`${searchPrefix ? `${searchPrefix}.` : ''}${key}`, '')
            }
        })

    }


    return (
        <div className="tableFilters">
            <div className="tableFilters__header">
                {!!title ? <h4 className="tableFilters__title">{t('filters_for')} <b>{title}</b></h4> : <div></div>}
                <button
                    className="tableFilters__clear"
                    data-tip={t('clear_filters')}
                    onClick={clearForm}
                >
                    <RiFilterOffLine/>
                </button>
            </div>
            <Form
                formMethods={formMethods}
                onSubmit={onSubmit}
            >
                <div className="tableFilters__body">
                    <div className={`tableFilters__inputs -${config.grid}`}>
                        <FormBuilderComponent prefix={searchPrefix} components={components}/>
                    </div>
                </div>
            </Form>
        </div>
    )
}
