import {Route, Switch} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import Step from "../Step";
import {getStepsRoutes} from "../../misc/Helpers";
import {InfoComponent, StepsHeader} from "../../components/_index";
import {useModuleContext, useSession, useTranslation} from "../../hooks";
import ChatBotButton from "../ChatBotButton";
import {Footer} from "../misc/_index";
import {CartSideBox} from "../cartSummaryComponent";
import {makeClassName} from "../../helpers";
import {GetInTouchModal} from "../modals/_index";
import Alert from "../Alert";
import {MessageAlert} from "../Header";

/**
 * Base Module Steps Funnel
 * @param {number} sessionTime session time in ms
 * @param {string} summaryRoute summary page route
 * @param {string} homeRoute home page route
 * @param {JSX.Element} StepComponent module step component
 * @param {function} HeaderComponent (optional) header component
 * @param {function} FooterComponent (optional) footer component
 * @param {function} CartSideBoxComponent (optional) cart side box component
 * @param {function} HeaderBannerComponent (optional) header banner component
 * @param {boolean} onExitPageEnabled (default: true) enable on exit and before unload actions
 * @param {boolean} chatBotEnabled (default: true) enable chat bot
 * @returns {JSX.Element}
 * @constructor
 */
export default function StepsFunnel({
    sessionTime,
    summaryRoute,
    homeRoute,
    StepComponent = Step,
    HeaderComponent,
    FooterComponent,
    CartSideBoxComponent,
    HeaderBannerComponent,
    onExitPageEnabled = true,
    chatBotEnabled = true,
}) {
    const {storeName, steps} = useModuleContext();
    const {t} = useTranslation()
    const [step, setStep] = useState(-1)
    const [currentStepInView, setCurrentStepInView] = useState(false)
    const session = useSession({
        moduleName: storeName,
        sessionTime,
        homeRoute
    })
    const [gitModalVisible, setGitModalVisible] = useState(false);
    const formContainerClassName = useMemo(() => makeClassName('wrapper-bg formContainer', {
        '--pt0': HeaderBannerComponent || session.message,
    }), [HeaderBannerComponent, session.message])

    useEffect(() => {
        setCurrentStepInView(false)
        if (step > -1 && onExitPageEnabled) {
            session.onExitPage.sendLog(null, 'STEP_CHANGE')
        }
    }, [step])

    const onChangeInView = (inView, idx) => {
        if (idx === step) {
            setCurrentStepInView(!inView)
        }
    }

    const headerComponentProps = {
        storeName,
        summaryRoute,
        homeRoute,
        steps,
        step,
        currentStepInView,
    }

    const cartSideBoxComponentProps = {
        summaryRoute,
    }

    return (
        <div className="app">
            {HeaderComponent ? HeaderComponent(headerComponentProps) : (
                <StepsHeader {...headerComponentProps}/>
            )}

            <div className="main">
                <div className={formContainerClassName}>
                    {!!HeaderBannerComponent && HeaderBannerComponent()}

                    {!!session.message && (
                        <div className="wrapper --mb3">
                            <InfoComponent
                                type="info"
                                message={session.message}
                                liteShadow
                            />
                        </div>
                    )}

                    <div className="wrapper">
                        <div className="steps">
                            <Switch>
                                <Route exact path={getStepsRoutes(steps)}>
                                    {steps.map((s, idx) => (
                                        <StepComponent
                                            key={idx}
                                            index={idx}
                                            currentStep={step}
                                            route={s.route}
                                            title={t(s.title)}
                                            steps={steps}
                                            setCurrentStep={() => setStep(idx)}
                                            onChangeInView={inView => onChangeInView(inView, idx)}
                                        >
                                            {s.component}
                                        </StepComponent>
                                    ))}
                                </Route>
                            </Switch>
                        </div>


                        {step < steps.length - 1 && (
                            <div className="steps__footer par-4">
                                <b>{t('need_help')} </b>
                                <a
                                    className="link -primary -parentSize"
                                    onClick={() => setGitModalVisible(true)}
                                >
                                    <b>{t('get_in_touch')}</b>
                                </a>
                            </div>
                        )}
                    </div>
                </div>

                {CartSideBoxComponent ? CartSideBoxComponent(cartSideBoxComponentProps) : (
                    <CartSideBox {...cartSideBoxComponentProps}/>
                )}
            </div>

            {FooterComponent ? FooterComponent() : (
                <Footer/>
            )}

            {!!chatBotEnabled && (
                <ChatBotButton/>
            )}

            <GetInTouchModal
                visible={gitModalVisible}
                onClose={() => setGitModalVisible(false)}
            />
        </div>
    )
}
