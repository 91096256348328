import Form from "./Form";
import {useForm} from "react-hook-form";
import {useFormRulesBuilder} from "./hooks";
import {RecaptchaController, TextInputController} from "./controllers";
import {useTranslation} from "../hooks";
import {FormGroup} from "./components";
import React, {useEffect} from "react";
import {Button} from "../components/buttons/_index";
import {useCountryConfig, useLocalizeObject, useMarketConfig} from "../misc/Hooks";
import {EmailInput, PasswordInput} from "./presets";

export default function AuthForm({
    HeaderComponent,
    FooterComponent,
    SubmitComponent,
    defaultValues,
    submitText,
    state,
    onSubmit,
}) {
    state = {
        isLoading: false,
        error: null,
        ...state,
    }
    const {t} = useTranslation()
    const countryConfig = useCountryConfig()
    const formMethods = useForm({
        mode: 'onChange',
        defaultValues,
    })
    const formRules = useFormRulesBuilder({
        'password': b => b.required(),
    }, formMethods)
    const localizeObject = useLocalizeObject();
    const marketConfig = useMarketConfig();

    const forgotPasswordLink = localizeObject(countryConfig.forgot_password_link) || localizeObject(marketConfig.forgot_password_link);

    useEffect(() => {
        if(state.error) {
            formMethods.setError('email', {type: 'invalid', message: state.error})
            formMethods.setError('password', {type: 'invalid', message: state.error})
        } else {
            formMethods.clearErrors('email')
            formMethods.clearErrors('password')
        }
    }, [state.error])

    const handleSubmit = (data) => {
        if(typeof onSubmit === 'function')
            onSubmit(data)
    }

    return (
        <div id="authForm">
            <Form
                formMethods={formMethods}
                formRules={formRules}
                state={{
                    isDisabled: state.isLoading,
                }}
                onSubmit={handleSubmit}
            >

                {HeaderComponent ? <HeaderComponent /> : (
                    <p className="par-2">{t('ev_customer_identification.sign_in')}</p>
                )}

                <FormGroup>
                    <EmailInput/>
                </FormGroup>
                <FormGroup>
                    <PasswordInput/>
                </FormGroup>

                {FooterComponent ? FooterComponent({forgotPasswordLink}) : (
                    <div className="--mb3" style={{textAlign: 'right'}}>

                            <Button
                                type="grey"
                                size="sm"
                                label={t('forgot_your_password')}
                                href={forgotPasswordLink ?? t('forgot_your_password_url')}
                                target="_blank"
                            />

                    </div>
                )}

                <FormGroup>
                    <RecaptchaController/>
                </FormGroup>

                {SubmitComponent ? <SubmitComponent/> : (
                    <Button
                        btnType="submit"
                        type="primary"
                        label={submitText ?? t('continue')}
                        disabled={state.isLoading}
                        isLoading={state.isLoading}
                    />
                )}

            </Form>
        </div>
    )
}
