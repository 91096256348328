import Axios from "../utils/Axios";
import {getURLSearchParams} from "../misc/Helpers";

export const getAll = (id_country) => {
    return Axios.get(`/shops/${id_country}`);
}

export const getByPosId = (posId) => {
    return Axios.get(`/shop/${posId}`);
}

export const getShopByToken = (token) => {
    return Axios.get(`/shop/token/${token}`);
}

export const getShopRegion = (idCountry, params) => {
    return Axios.get(`/v2/country/${idCountry}/shops/regions${getURLSearchParams(params)}`)
}

export const getShopRegion2 = (idCountry, params) => {
    return Axios.get(`/v2/country/${idCountry}/shops/regions${params}`)
}

export const getShopGroups = (idCountry, params) => {
    return Axios.get(`/v2/country/${idCountry}/shops/groups${getURLSearchParams(params)}`)
}

export const getShopGroups2 = (idCountry, params) => {
    return Axios.get(`/v2/country/${idCountry}/shops/groups${params}`)
}

export const getShop = (idCountry, params) => {
    return Axios.get(`/v2/country/${idCountry}/shops${getURLSearchParams(params)}`)
}

export const getShops = (idCountry, params) => {
    return Axios.get(`/v2/country/${idCountry}/shops${params}`)
}