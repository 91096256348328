import {Footer} from "./misc/_index";
import React from "react";
import Header from "./misc/Header";
import {Preloader} from "../misc/_index";
import PagePreloader from "./PagePreloader";


export default function Layout({loading, screenLoading, ...props}) {
    if(screenLoading)
        return <PagePreloader/>

    return (
        <div className="app">
            <Header cartDisabled alertsDisabled/>

            <div className="main">
                <div className="wrapper-bg formContainer">
                    <div className="wrapper">
                        {loading ? <Preloader/> : props.children}
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
}
