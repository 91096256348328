import {ErrorBoundary} from "react-error-boundary";
import Button from "../components/buttons/Button";
import {useTranslation} from "../hooks";

export default function ErrorBoundaryContainer({...props}) {
    function TestError() {
        throw new Error('ERRRORR')
    }

    return (
        <ErrorBoundary
            fallbackRender={
                ({error, resetErrorBoundary}) =>
                    <ErrorFallback
                        error={error}
                        resetErrorBoundary={resetErrorBoundary}
                    />
            }
        >
            <>
                {/*<TestError/>*/}
                {props.children}
            </>
        </ErrorBoundary>
    )
}

function ErrorFallback({error, resetErrorBoundary}) {
    const {t} = useTranslation()
    return (
        <div className="app">
            <div role="alert">
                <div className="main">
                    <div className="wrapper-bg">
                        <div className="wrapper">
                            <div className="errorBoundary">
                                <h1 className="heading-1">Oops!</h1>
                                <h3 className="par-1">{t('error.unknown_error')}</h3>
                                <Button
                                    label={t('try_again')}
                                    type="secondary"
                                    size="sm"
                                    onClick={resetErrorBoundary}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}