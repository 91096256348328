import {SelectController} from "../../../forms/controllers";
import {useTranslation} from "../../../hooks";
import {useMemo} from "react";
import {usePickupPoint} from "../hooks";


export default function PickupPointsForm({data, isFetching, onChangePickupPoint}) {
    const {t} = useTranslation()
    const {getLabel} = usePickupPoint({})
    const selectOptions = useMemo(() => {
        return data.map(i => ({
            label: getLabel(i),
            value: i.id,
            data: i,
        }))
    }, [JSON.stringify(data)])

    return (
        <SelectController
            name="pickupPoint"
            label={t('pickup_points.pickup_point_label')}
            options={{
                isFetching,
                inputProps: {
                    isSearchable: true,
                    onChange: onChangePickupPoint,
                }
            }}
            selectOptions={selectOptions}
        />
    )
}
