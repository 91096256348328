import {Select} from "./index";

export default function AsyncSelect({asyncSelect, ...props}) {
    return (
        <Select
            {...props}
            options={{
                ...props.options,
                isFetching: asyncSelect.query.isFetching,
            }}
            defaultInputValue={asyncSelect.inputValue}
            selectOptions={props.selectOptions ?? asyncSelect.data}
            onInputChange={asyncSelect.onInputChange}
        />
    )
}
