import {FcHighPriority} from "react-icons/fc";


export default function PriorityIcon({priority, ...props}) {
    if (priority === 'high')
        return <IconWrapper><FcHighPriority/></IconWrapper>
    // if(priority === 'normal')
    //     return <IconWrapper><FcMediumPriority/></IconWrapper>
    // if(priority === 'low')
    //     return <IconWrapper><FcLowPriority/></IconWrapper>
    return null
}

const IconWrapper = ({children}) => (
    <div className="messageItem__infoIcon" style={{fontSize: '1.25em'}}>
        {children}
    </div>
)
