import {FormGroup, FormRow} from "../../../forms/components";
import {TextInputController} from "../../../forms/controllers";
import {useFormHelper, useTranslation} from "../../../hooks";
import {useFormRulesBuilder} from "../../../forms/hooks";
import {PostcodeInput} from "../../../forms/presets";

export default function SearchNespressoMemberType2Fragment() {
    const {t} = useTranslation()
    const {formMethods} = useFormHelper()
    const formRules = useFormRulesBuilder({
        firstname: b => b.required(),
        lastname: b => b.required(),
        postcode: b => b.required(),
    }, formMethods)

    return (
        <>
            <FormRow>
                <FormGroup>
                    <TextInputController
                        name="firstname"
                        label={t('firstname')}
                        rules={formRules.values.firstname}
                    />
                </FormGroup>
                <FormGroup>
                    <TextInputController
                        name="lastname"
                        label={t('lastname')}
                        rules={formRules.values.lastname}
                    />
                </FormGroup>
            </FormRow>
            <FormGroup>
                <PostcodeInput/>
            </FormGroup>
        </>
    )
}
