import React, {useEffect, useMemo, useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {MyCheckbox, MySwitch} from "../inputs/_index";
import {useFormRules} from "../../../hooks";
import {useTranslation} from "../../../misc/Hooks";
import {getBenefitsOrder} from "../../../utils/MarketConfig";
import {useSelector} from "react-redux";

export default function FormFragmentBenefits(props: FormFragmentBenefitsProps) {
    const t = useTranslation();
    const formRules = useFormRules();
    const {
        control,
        watch,
        trigger,
        formState,
        setValue,
    } = useFormContext();

    const {
        errors,
    } = formState;

    const email = watch('benefits.email')
    const mobile = watch('benefits.mobile')
    const phone = watch('benefits.phone')
    const post = watch('benefits.post')
    const socialMedia = watch('benefits.socialMedia')
    const [selectedAll, setSelectedAll] = useState(email && mobile && phone && post)

    const store = useSelector(state => ({
        nespressoMember: state[props.storeName]?.nespressoMember,
    }))

    const [visibleConfig, setVisibleConfig] = useState({
        email: 0,
        mobile: 0,
        phone: 0,
        post: 0,
        socialMedia: 0,
    })

    const rules = useMemo(() => ({
        email: formRules.make({...isRequired('email')}),
        mobile: formRules.make({...isRequired('mobile')}),
        phone: formRules.make({...isRequired('phone')}),
        post: formRules.make({...isRequired('post')}),
        socialMedia: formRules.make({...isRequired('socialMedia')}),
    }), [formRules, watch])

    useEffect(() => {
        let config = {
            ...visibleConfig
        }
        if (props.config.configOptionsVisibility === 'all' || props.config.configOptionsVisibility === undefined) {
            config = {
                email: props.config.email !== 0,
                mobile: props.config.mobile !== 0,
                phone: props.config.phone !== 0,
                post: props.config.post !== 0,
                socialMedia: props.config.socialMedia !== 0
            }
        } else if (props.config.configOptionsVisibility === 'unchecked') {
            config = {
                email: props.config.email !== 0 && !(props.config.userConsents?.contactByEmail),
                mobile: props.config.mobile !== 0 && !(props.config.userConsents?.mobileNotification),
                phone: props.config.phone !== 0 && !(props.config.userConsents?.contactByPhone),
                post: props.config.post !== 0 && !(props.config.userConsents?.contactByPost),
                socialMedia: props.config.socialMedia !== 0 && !(props.config.userConsents?.socialMedia)
            }
        } else if (props.config.configOptionsVisibility === 'none') {
            if (!props.config.userConsents) {
                config = {
                    email: props.config.email !== 0,
                    mobile: props.config.mobile !== 0,
                    phone: props.config.phone !== 0,
                    post: props.config.post !== 0,
                    socialMedia: props.config.socialMedia !== 0
                }
            }
        }

        if(props.config?.popupConfig){
            config = {
                email: config.email ? props.config.popupConfig.includes('email') ? 1 : 0 : 0,
                mobile: config.mobile ? props.config.popupConfig.includes('mobile') ? 1 : 0 : 0,
                phone: config.phone ? props.config.popupConfig.includes('phone') ? 1 : 0 : 0,
                post: config.post ? props.config.popupConfig.includes('post') ? 1 : 0 : 0,
                socialMedia: config.socialMedia ? props.config.popupConfig.includes('socialMedia') ? 1 : 0 : 0
            }
        }

        setVisibleConfig(config)


    }, [store.nespressoMember])


    useEffect(() => {
        if (formState.isDirty) {
            // trigger(['benefits.email', 'benefits.mobile', 'benefits.phone', 'benefits.post'])
            if ((
                (!email && props.config.email !== 0)
                || (!mobile && props.config.mobile !== 0)
                || (!phone && props.config.phone !== 0)
                || (!post && props.config.post !== 0)
                || (!socialMedia && props.config.socialMedia !== 0)
            ) && selectedAll) {
                setSelectedAll(false)
            }
        }
    }, [email, mobile, phone, post, socialMedia])


    const onSelectAll = () => {
        if(props.config.email !== 4)
            setValue('benefits.email', !selectedAll)
        if(props.config.mobile !== 4)
            setValue('benefits.mobile', !selectedAll)
        if(props.config.phone !== 4)
            setValue('benefits.phone', !selectedAll)
        if(props.config.post !== 4)
            setValue('benefits.post', !selectedAll)
        if(props.config.socialMedia !== 4)
            setValue('benefits.socialMedia', !selectedAll)
        setSelectedAll(!selectedAll)
    }

    function isRequired(name) {
        if (props.config[name] === 2)
            return {
                required: true
            };
        if (props.config.required === 2)
            return {required: true};
        if (props.config.required === 1) {
            return {
                required: !(email === null || mobile === null || phone === null || post === null || socialMedia === null),
                requiredMessage: t('validation.one_of_these_fields_must_be_specified')
            };
        }

        //TODO: required gdy niezaznaczony, gdy wymagany przez bo "required accepted"
        return {
            required: false,
            validate: v => v === null ? t('validation.one_of_the_options_must_be_checked') : true
        };
    }

    if (!visibleConfig.email && !visibleConfig.mobile && !visibleConfig.phone && !visibleConfig.post && !visibleConfig.socialMedia)
        return null

    return (
        <>
            {(!!props.config.selectAllEnabled && !props.config.popupConfig) && (
                <MyCheckbox
                    name="selectAll"
                    value={selectedAll}
                    onChange={onSelectAll}
                    green
                >
                    <b style={{fontWeight: 800}}>{t('select_all_benefits')}</b>
                </MyCheckbox>
            )}
            {getBenefitsOrder().map((benefitName, idx) => {
                if (!!visibleConfig[benefitName]) {
                    return (
                        <Controller
                            key={idx}
                            name={`benefits.${benefitName}`}
                            control={control}
                            rules={rules[benefitName]}
                            render={({field, fieldState}) => (
                                <MySwitch
                                    name={benefitName}
                                    value={field.value}
                                    error={fieldState.error}
                                    onChange={v => props.config[benefitName] === 4 ? undefined : field.onChange(v)}
                                    green
                                >
                                    <b dangerouslySetInnerHTML={{__html: t(`${props.modulePrefix ?? ''}receive_benefits_${benefitName}`)}}/>
                                </MySwitch>
                            )}
                        />
                    )
                }
            })}
        </>
    );
}