import {useTableBuilder, useTableFilters} from "../../../components/baseTable";
import {isoDateConverter, isoDateConverterWithTime} from "../../../misc/Helpers";
import {useResellerActions} from "../../../actions/useResellerActions";
import {useMarketConfig} from "../../../misc/Hooks";
import {useTranslation} from "../../../hooks";
import React, {useMemo} from "react";
import SubscriptionStatusLabel from "../../../components/SubscriptionStatusLabel";
import {isEnabledByMarket} from "../../../utils/MarketConfig";
import PaymentStatusLabel from "../../../components/PaymentStatusLabel";
import {DatePickerController} from "../../../forms/controllers";

export default function useAssistedRegistrationOrderTable({
    payload,
    daysLimit = false,
    prefix,
    hiddenColumns,
    columns
}) {
    const resellerActions = useResellerActions();
    const marketConfig = useMarketConfig();
    const {t} = useTranslation();

    const assistedFilters = useTableFilters({
        builder: {
            formConfig: [[
                'slug',
                !!marketConfig?.reseller_allow_to_exceed_show_time ? 'dateFrom' : null,
                !!marketConfig?.reseller_allow_to_exceed_show_time ? 'dateTo' : null
            ]],
            inputComponents: {
                dateFrom: (
                    <DatePickerController
                        name="dateFrom"
                        label={t('assisted_registration.filters.date_from')}
                    />
                ),
                dateTo: (
                    <DatePickerController
                        name="dateTo"
                        label={t('assisted_registration.filters.date_to')}
                    />
                ),
            }
        },
        delayFields: ['slug'],
        searchPrefix: "assistedFilter",
        mapFunc: (data) => ({
            slug: data.assistedFilter?.slug ?? '',
            dateFrom: data?.assistedFilter?.dateFrom ? isoDateConverter(data.assistedFilter.dateFrom, '-') : undefined,
            dateTo: data?.assistedFilter?.dateTo ? isoDateConverter(data.assistedFilter.dateTo, '-') : undefined,
        }),
    });

    const assistedTable = useTableBuilder({
        name: 'assisted_registration_orders',
        action: async ({dateFrom: dateFromFilter, dateTo: dateToFilter, ...params}) => {

            let dateFrom = null
            if ((!!marketConfig.reseller_subscription_show_time) && daysLimit)
                dateFrom = new Date(new Date().getTime() - (Number(marketConfig.reseller_subscription_show_time) * 24 * 60 * 60 * 1000))

            const actionPayload = {
                sort: null,
                order_type: [],
                date_from: dateFromFilter ?? isoDateConverter(dateFrom, '-'),
                date_to: dateToFilter ?? null,
                id_shop: [],
                status: [],
                id_reseller: [],
                ...payload
            }
            return resellerActions.getOrdersV2(actionPayload, params)
        },
        columns: [
            {
                name: 'id_order',
                label: 'order.id'
            },
            {
                name: 'tracking_code',
                label: 'order.tracking_code',
            },
            {
                name: 'promotion_type',
                label: 'assisted_registration.promotion_type.title',
                cell: ({value}) => <span>{t(`assisted_registration.promotion_type.${value}`) ?? value}</span>
            },
            {
                name: 'status',
                label: 'order.status_of_registration',
                cell: ({value}) => <SubscriptionStatusLabel status={value}/>
            },
            {
                name: 'payment_status',
                label: 'order.status_of_payment',
                cell: ({value}) => <PaymentStatusLabel status={value}/>
            },
            {
                name: 'date',
                label: 'order.date_time',
                cell: ({row: {original}}) => isoDateConverterWithTime(original.created_at, '-', ':')
            },
            {
                name: 'customer_name',
                label: 'order.name_of_customer',
                cell: ({row: {original}}) => (`${original.delivery_firstname ?? ''} ${original.delivery_lastname ?? ''}`)
            },
            {
                name: 'delivery_email',
                label: 'order.email_address'
            },
            {
                name: 'machine_coffee_type',
                label: 'order.machine_type'
            },
            {
                name: 'serial',
                label: 'order.serial_number'
            },
            ...columns,
        ],
        config: {
            searchParamPrefix: prefix,
            hiddenColumns: hiddenColumns,
            autoRefetch: true,
            autoRefetchTimeout: 20000,
        },
        params: {...assistedFilters.rawData}

    })

    return {assistedTable, assistedFilters}
}