import Identification from "../screens/Identification";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useStepsNavigation} from "../../../hooks/modules/stay_home";
import {useShActions} from "../../../actions/_index";
import {useState} from "react";
import * as stayHomeReducer from "../../../redux/stayHomeReducer";
import {uuidv4} from "../../../misc/Helpers";
import * as mainActions from '../../../actions/MainActions';
import {useSeoPage} from "../../../hooks";


export default function IdentificationContainer(props) {
    useSeoPage({title: 'stay_home.authorization.title'})
    const dispatch = useDispatch();
    const store = useSelector(({stayHome}) => ({
        token: stayHome.token,
        email: stayHome.email,
        code: stayHome.code,
        promoCode: stayHome.promoCode,
    }))
    const history = useHistory();
    const stepsNavigation = useStepsNavigation()
    const actions = useShActions()
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (data) => {
        setLoading(true);
        setError(null);

        if (data.email) {
            await actions.checkEmail(data.email)
                .then(r => {
                    const res = r.data;
                    console.log(res)
                    if (res.status === 'success') {
                        dispatch(stayHomeReducer.setTokenAndEmail(res.data, data.email))
                    } else {
                        if (res.messages?.length) {
                                setError(res.messages[0])
                        } else {
                            console.log('Something gone wrong!', r)
                        }
                    }
                })
                .catch(err => {
                    console.error('Error: ', err)
                })
                .finally(() => {

                })
        } else {
            await mainActions.getPromoCode(data.promoCode, 0)
                .then(r => {
                    const res = r.data
                    if(res.status === 'success') {
                        console.log(res.data)
                        dispatch(stayHomeReducer.setTokenAndClearStore(res.data.stay_home_token))
                        dispatch(stayHomeReducer.setWithEmail(false))
                        dispatch(stayHomeReducer.setPromoCode(res.data))
                        dispatch(stayHomeReducer.setData(res.data.stay_home))
                        dispatch(stayHomeReducer.setUuid(uuidv4()))
                        history.push(stepsNavigation.next())
                    } else {
                        if (res.messages?.length) {
                                setError(res.messages[0])
                        } else {
                            console.log('Something gone wrong!', r)
                        }
                    }
                })
                .catch(err => {
                    console.error('Cannot check promo code', err)
                })
        }

        setLoading(false);
    }


    const handleSubmitCode = (data) => {
        setLoading(true);
        setError(null);
        actions.checkCode(data.code, store.token).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                dispatch(stayHomeReducer.setCode(data.code))
                dispatch(stayHomeReducer.setData(res.data))
                dispatch(stayHomeReducer.setUuid(uuidv4()))
                history.push(stepsNavigation.next())
            } else {
                if (res.messages?.length) {
                        setError(res.messages[0])
                        dispatch(stayHomeReducer.setCode(null))
                }
                console.warn('Something gone wrong!', r)
            }
        }).catch(err => {
            console.error('Error: ', err)
        }).finally(() => {
                setLoading(false);
        })
    }


    const handleGoBack = () => {
        dispatch(stayHomeReducer.setTokenAndClearStore(null))
        setLoading(false);
        setError(false);
    }

    return (
        <Identification
            error={error}
            loading={loading}
            email={store.email}
            code={store.code}
            token={store.token}
            onSubmit={handleSubmit}
            onSubmitCode={handleSubmitCode}
            onGoBack={handleGoBack}
        />
    )
}
