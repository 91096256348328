import {
    AddressLine1Input,
    AddressLine2Input,
    BsbNumberInput,
    CityByPostcodeInputs,
    CityInput,
    CompanyNameInput,
    CompanyTaxCategorySelect,
    CompanyTaxIdInput,
    CountrySelect,
    EmailInput,
    FirstnameInput,
    IsCompanyAddressCheckbox,
    LangSelect,
    LastnameInput,
    PasswordInput,
    PhonesGroup,
    PostcodeByCityInputs,
    PostcodeInput,
    ProfessionSelect,
    RegionSelect,
    SearchSlugInput,
    TaxCategorySelect,
    TaxIdInput,
    TitleSelect,
    CompanyTaxOfficeSelect
} from "../presets";
import AccountNumberInput from "../presets/AccountNumberInput";
import React from "react";
import CompanyProfessionSelect from "../presets/CompanyProfessionSelect";
import PhoneticNameInput from "../presets/PhoneticNameInput";
import CitySelect from "../presets/CitySelect";

const presetsConfig = {
    'isCompanyAddress': <IsCompanyAddressCheckbox/>,
    'title': <TitleSelect/>,
    'taxId': <TaxIdInput/>,
    'companyName': <CompanyNameInput/>,
    'companyTaxId': <CompanyTaxIdInput/>,
    'firstname': <FirstnameInput/>,
    'lastname': <LastnameInput/>,
    'postcode': <PostcodeInput/>,
    'city': <CityInput/>,
    'citySelect': <CitySelect/>,
    'addressLine1': <AddressLine1Input/>,
    'addressLine2': <AddressLine2Input/>,
    'lang': <LangSelect/>,
    'country': <CountrySelect/>,
    'phones': <PhonesGroup/>,
    'email': <EmailInput/>,
    'password': <PasswordInput/>,
    'bsbNumber': <BsbNumberInput/>,
    'accountNumber': <AccountNumberInput/>,
    'cityByPostcode': <CityByPostcodeInputs/>,
    'postcodeByCity': <PostcodeByCityInputs/>,
    'slug': <SearchSlugInput/>,
    'region': <RegionSelect/>,
    'profession': <ProfessionSelect/>,
    'companyProfession': <CompanyProfessionSelect/>,
    'taxCategory': <TaxCategorySelect/>,
    'companyTaxCategory': <CompanyTaxCategorySelect/>,
    'phoneticName': <PhoneticNameInput/>,
    'companyTaxOffice': <CompanyTaxOfficeSelect/>,
}

export default presetsConfig
