import {useTranslation} from "../../../../hooks";
import {useResellerActions} from "../../../../actions/useResellerActions";
import {useMutation} from "@tanstack/react-query";
import {ApiException} from "../../../../errors";
import {Button} from "../../../../components/buttons/_index";
import React, {useMemo} from "react";

export default function ResetPasswordButton({resellerId, disabled}){

    const {t} = useTranslation()
    const resellerActions = useResellerActions()

    const resetPasswordMutation = useMutation(
        async () => {
            const {data} = await resellerActions.sendResetPasswordEmail(resellerId)
            if (data.status === 'success') return data
            throw new ApiException(data.messages[0], data)
        }
    )

    const label = useMemo(() => {
        if(resetPasswordMutation.isIdle)
            return 'reset'
        if(resetPasswordMutation.isLoading)
            return 'table.sending'
        if(resetPasswordMutation.isSuccess)
            return 'table.sent'
        if(resetPasswordMutation.isError)
            return 'error_occurred'
    }, [resetPasswordMutation.status])

    return (
        <Button
            size="sm"
            label={t(label)}
            disabled={!resetPasswordMutation.isIdle || disabled}
            isLoading={resetPasswordMutation.isLoading}
            onClick={resetPasswordMutation.mutate}
            style={{width: '100%'}}
            tooltip={disabled ? t('reset_disabled') : ''}
        />
    )
}