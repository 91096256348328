import {Routes} from "../../../utils/_index";
import {useNavigation} from "../../../misc/Hooks";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {isEnabledByMarket} from "../../../utils/MarketConfig";


/**
 * Validates eTrade module route / step
 * @param route
 * @type route: string
 * @returns {{lastValidRoute: (*), value: boolean}}
 */
export default function useStepValidation(route) {
    const navigation = useNavigation()
    const location = useLocation()
    const store = useSelector(({eTrade}) => ({
        data: eTrade.data,
        token: eTrade.token,
        nespressoMember: eTrade.nespressoMember,
        isNewMember: eTrade.isNewMember,
        customer: eTrade.customer,
        order: eTrade.order,
    }))
    const orderUuid = new URLSearchParams(location?.search).get('uuid')

    const response = {
        canShowStep: !!store.data,
        lastValidStep: Routes.ETRADE_MACHINE_PLAN,
    }

    switch (route) {
        case Routes.ETRADE_SUMMARY:
            if(orderUuid) {
                response.canShowStep = true
                response.lastValidStep = Routes.ETRADE_SUMMARY
                break
            }

            if(!store.customer) {
                response.canShowStep = false
                response.lastValidStep = Routes.ETRADE_CUSTOMER
            }
        case Routes.ETRADE_CUSTOMER:
            if(!(isEnabledByMarket('disableEcapi') || (store.isNewMember === false ? store.nespressoMember : typeof store.isNewMember === 'boolean'))) {
                response.canShowStep = false
                response.lastValidStep = Routes.ETRADE_AUTHORIZATION
            }
        case Routes.ETRADE_AUTHORIZATION:
            break
        case Routes.ETRADE_MACHINE_PLAN:
            if(!store.token && !orderUuid) {
                response.canShowStep = false
                response.lastValidStep = Routes.ETRADE_INVALID_TOKEN
            }
    }



    return {
        value: response.canShowStep,
        lastValidRoute: navigation(response.lastValidStep),
    }
}
