import {SwitchController} from "../../../../forms/controllers";
import {Switch} from "../../../../forms/inputs";
import {Fragment, useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {ApiException} from "../../../../errors";
import {useResellerActions} from "../../../../actions/useResellerActions";
import {useChangeUserStatus} from "../hooks";
import {Preloader} from "../../../../misc/_index";

export default function UserStatusSwitch({resellerId, defaultStatus, isCertificateExpired, query}) {

    const [value, setValue] = useState(!!defaultStatus)
    const {userStatusMutation} = useChangeUserStatus()

    return (
        <Fragment>

                <Switch
                    name={`status${resellerId}`}
                    value={query.isFetching ? null : value}
                    onChange={() => {
                        setValue(!value)
                        userStatusMutation.mutate({resellerId, status: value ? 0 : 1})
                    }}
                    options={{
                        inputProps: {
                            disabled: userStatusMutation.isLoading || !!isCertificateExpired
                        }
                    }}
                />
        </Fragment>
    )
}