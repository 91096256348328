import Axios from "../utils/Axios";
import {queryParams} from "../misc/Helpers";


export const getAll = (promotionId, machineId, shopId, userLogged, paramObj = {}) => {
    const params = queryParams(paramObj)
    // return Axios.get(`/categories/${promotionId}/${machineId}/${shopId}/${userLogged}${params}`);
    return Axios.get(`/v2/promotions/${promotionId}/products/${machineId}/${shopId}/${userLogged}${params}`);
}

/**
 * @param params
 * @param params.promotionId
 * @param params.machineId
 * @param params.shopId
 * @param params.isExistingMember
 * @param paramObj
 * @returns {Promise<AxiosResponse<never>>}
 */
export const fetchAll = (params, paramObj = {}) => {
    return Axios.get(`/v2/promotions/${params.promotionId}/products/${params.machineId}/${params.shopId}/${params.isExistingMember}${queryParams(paramObj)}`);
}

export const getTpsCategories = (promotionId) => {
    return Axios.get(`/categories-tps/${promotionId}`)
}

export const getAllStayHome = (token, machineId, userLoggedIn) => {
    return Axios.get(`/stay-home/categories/${token}/${machineId}/${userLoggedIn}`);
}
