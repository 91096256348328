import React from "react";
import {Config, Routes} from "../../../utils/_index";
import {OrderSummary} from "../../../components/Summary";
import {useModuleContext, useReseller, useSeoPage} from "../../../hooks";
import * as orderActions from "../../../actions/OrderActions";

export default function SummaryStepScreen() {
    useSeoPage({title: 'shop_summary.title'})
    const {isLoggedIn} = useReseller()
    const {store} = useModuleContext()

    const payloadFn = ({orderData, paymentMethod, deliveryMethod, pickupPoint, formData}) => {
        return {
            customer: {
                ...orderData.getData(),
                ...orderData.getPaymentMethodData(paymentMethod),
                ...orderData.getPaymentMethodCodeVerifyData(formData.paymentMethodCodeVerify),
                ...orderData.getPaymentMethodIbanProofData(formData.paymentMethodIbanProof),
                ...orderData.getDeliveryMethodData(deliveryMethod, pickupPoint),
                promotion_type: Config.PROMO_TYPE.SUBSCRIPTION_MACHINE,
                coffee_type: store?.machine?.machine_coffee_type ?? null,
            },
            cart: orderData.getCart(),
            ...orderData.getCartQuotationData(),
        }
    }

    const action = (payload, params, customData) => {
        if(isLoggedIn && customData.isAssistedExistingSimplifiedFlow) {
            return orderActions.updateOrder(payload.customer.uuid, payload)
        } else if (isLoggedIn) {
            return orderActions.finalizeSubscription(payload, params)
        }
        return orderActions.finalizeSubscription(payload, params)
    }

    const resellerAction = (payload, params, customData) => {
        if(isLoggedIn && customData.isAssistedExistingSimplifiedFlow) {
            return orderActions.finalizeSubscription(payload, params)
        }
        console.error('Unhandled exception!')
        return null
    }

    return (
        <OrderSummary
            homeRoute={Routes.SUBSCRIPTION_MACHINE}
            orderFinalizedRoute={Routes.MACHINE_REGISTRATION_ORDER_FINALIZED}
            assistedRedirectTo={Routes.V2_RESELLER_MACHINE_SUBSCRIPTIONS}
            action={action}
            resellerAction={resellerAction}
            payloadFn={payloadFn}
        />
    )
}
