import React, {useEffect, useState} from 'react';
import BaseModal from "../../../components/modals/BaseModal";
import {useCountry, useModuleContext, useNespressoMember, useReseller, useTranslation} from "../../../hooks";
import MachinePreview from "../../../components/machinePreview";
import {
    AssistedAuthForm,
    AuthForm,
    IsNewMemberForm,
    SerialNumberForm,
    ShopForm as GlobalShopForm
} from "../../../forms";
import * as subscriptionReducer from "../../../redux/subscriptionReducer";
import {uuidv4} from "../../../misc/Helpers";
import {isEnabledByMarket} from "../../../utils/MarketConfig";
import {Button} from "../../../components/buttons/_index";
import {useStepsNavigation} from "../../../hooks/modules/subscription";
import {GTM} from "../../../misc/_index";
import {NespressoMember} from "../../../services/NespressoMemberService";
import {useHistory} from "react-router-dom";
import {usePlansContext} from "../../../hooks/useFetchPlans";
import SearchNespressoMember from "../../../components/searchNespressoMember";

export default function MachineScreen() {
    const {t} = useTranslation();
    const {store, dispatch} = useModuleContext()
    const history = useHistory()
    const {data: country} = useCountry()
    const stepsNavigation = useStepsNavigation()
    const {loginMutation, error: loginError, nespressoMember, storeLoggedUser} = useNespressoMember()
    const [editSNPopup, setEditSNPopup] = useState(false);
    const [editShopData, setEditShopData] = useState(!store.shopData)
    // const identificationFormVisible = store.qrCodeUrl ? store.shopData && props.machine && !editShopData : props.machine
    const {query} = usePlansContext()

    const reseller = useReseller();
    const text1 = reseller.user
        ? t('ev_machine_registration.text1_reseller') || t('ev_machine_registration.text1')
        : t('ev_machine_registration.text1')

    useEffect(() => {
        setEditShopData(!store.shopData)
    }, [store.shopData])

    const resetStore = () => {
        dispatch(subscriptionReducer.setIsNewMember(undefined))
        dispatch(subscriptionReducer.setNespressoMember(null))
        dispatch(subscriptionReducer.setPromotion(null))
        dispatch(subscriptionReducer.setOrderAccount(null, false, false))
        dispatch(subscriptionReducer.setOptIn(null))
        query.refetch()
    }

    const handleSubmitSerialNumber = ({data, logs, machine}) => {
        setEditSNPopup(false)
        if (data.serialNumber !== store.serialNumber?.serial_number || !store.machine) {
            const backupStore = {...store}
            dispatch(subscriptionReducer.clearStore())
            dispatch(subscriptionReducer.setQrCodeUrl(backupStore.qrCodeUrl))
            dispatch(subscriptionReducer.setUuid(uuidv4()))
            dispatch(subscriptionReducer.setResellerLogged(!!store.user))
            dispatch(subscriptionReducer.setOcrLogs(logs))
            dispatch(subscriptionReducer.setMachine(machine.machine, machine.test_order, {
                ...store.serialNumber,
                serial_number: data.serialNumber,
            }));
        }
    }

    const handleSubmitShop = (data) => {
        dispatch(subscriptionReducer.setShopData(data))
    }

    const handleSubmitIsNewMember = (isNewMember) => {
        if (isNewMember !== store.isNewMember) {
            resetStore()
        }
        dispatch(subscriptionReducer.setIsNewMember(isNewMember));
        if (isNewMember === true) {
            dispatch(subscriptionReducer.setCustomer(null))
            history.push(stepsNavigation.next());
        }

    }

    const handleLogin = async (data) => {
        const payload = {
            login: data.email,
            password: data.password,
            recaptchaToken: data.recaptchaToken,
        }
        loginMutation.mutateAsync(payload)
            .then((data) => {
                storeLoggedUser(data, payload)
                stepsNavigation.navToNext()
            })
    }

    const handleSubmitAssisted = (data) => {
        const nespressoMember = new NespressoMember({
            email: data.email,
            rawData: data,
        })
        dispatch(subscriptionReducer.setNespressoMember(nespressoMember))
        dispatch(subscriptionReducer.setCustomer({
            details: {
                email: data.email,
                lang: data.lang,
            }
        }))
        stepsNavigation.navToNext()
    }

    return (
        <div>
            <div className="--mb4">
                {!!text1 && (
                    <div className="grid --mb3">
                        <div>
                            <p
                                className="par-7 --500"
                                dangerouslySetInnerHTML={{__html: text1}}
                            />
                        </div>
                        <div/>
                    </div>
                )}
                {!store.machine ? (
                    <SerialNumberForm
                        onSubmit={handleSubmitSerialNumber}
                    />
                ) : (
                    <>
                        <div className="grid --mb4">
                            <MachinePreview
                                onCLickEdit={() => setEditSNPopup(true)}
                            />
                            <div/>
                        </div>

                        {!reseller.isLoggedIn && (
                            <div className="grid">
                                <div>
                                    {/*<ShopForm*/}
                                    {/*    editShopData={editShopData}*/}
                                    {/*    onClickEdit={() => setEditShopData(true)}*/}
                                    {/*    onSubmit={props.onSubmitShop}*/}
                                    {/*/>*/}
                                    <GlobalShopForm
                                        editable={editShopData}
                                        setEditable={() => setEditShopData(true)}
                                        onSubmit={handleSubmitShop}
                                    />
                                </div>
                                <div/>
                            </div>
                        )}

                        {(!editShopData || reseller.isLoggedIn) && (
                            <div className="--mb4">
                                {isEnabledByMarket('disableEcapi') ? (
                                    <Button
                                        type="primary"
                                        label={t('continue')}
                                        href={stepsNavigation.next()}
                                    />
                                ) : (
                                    <div>
                                        <div className="grid --mb4">
                                            <div>
                                                <IsNewMemberForm
                                                    onSubmit={handleSubmitIsNewMember}
                                                />

                                                {store.isNewMember === false && (
                                                    <>
                                                        {reseller.isLoggedIn ? (
                                                            <>
                                                                {!country.country_assisted_simplified_flow_enabled && (
                                                                    <AssistedAuthForm
                                                                        onSubmit={handleSubmitAssisted}
                                                                    />
                                                                )}
                                                            </>
                                                        ) : nespressoMember ? (
                                                            <Button
                                                                href={stepsNavigation.next()}
                                                                label={t('continue')}
                                                            />
                                                        ) : (
                                                            <AuthForm
                                                                state={{
                                                                    error: loginError,
                                                                    isLoading: loginMutation.isLoading,
                                                                }}
                                                                onSubmit={handleLogin}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                                {store.isNewMember === true && (
                                                    <Button
                                                        href={stepsNavigation.next()}
                                                        label={t('continue')}
                                                    />
                                                )}
                                            </div>
                                            <div/>
                                        </div>

                                        {!!country.country_assisted_simplified_flow_enabled && store.isNewMember === false && reseller.isLoggedIn && (
                                            <SearchNespressoMember/>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>


            <BaseModal
                visible={editSNPopup}
                onClose={() => setEditSNPopup(false)}
                small
            >
                <SerialNumberForm
                    options={{
                        inRow: false,
                    }}
                    onSubmit={handleSubmitSerialNumber}
                />
            </BaseModal>
        </div>
    )
}
