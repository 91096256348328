import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import * as promotionActions from "../actions/PromotionActions";
import {GTM} from "../misc/_index";
import * as noMachineShopReducer from "../redux/noMachineShopReducer";
import {useGTM} from "./index";


export default function usePromotions(storeName) {
    const mounted = useRef(false)
    const store = useSelector(state => ({
        user: state.main.user,
        country: state.main.country,
        nespressoMember: state[storeName]?.nespressoMember,
        promotion: state[storeName]?.promotion,
        promotions: state[storeName]?.promotions,
        promoCode: state[storeName]?.promoCode,
        proofOfPurchase: state[storeName]?.proofOfPurchase,
        machine: state[storeName]?.machine,
        isFastOrder: state[storeName]?.isFastOrder,
    }))
    const {errorTracking} = useGTM()

    const reseller = useSelector(({reseller})=> (reseller))
    const [fetching, setFetching] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        mounted.current = true

        return () => {
            mounted.current = false
        }
    }, [])

    const fetchAll = async (params = null) => {
        return fetchRaw({params})
    }

    const fetchRaw = async ({country, idMachine, shopId, purchaseDate, hasAccount, promotionId, params, payload}) => {

        setFetching(true)
        setError(null)

        const date = new Date(store.proofOfPurchase?.date);

        let configPayload = {}

        if(store.promoCode) {
            configPayload.id_promotions = store.promoCode.promotion?.map(p => p.id_promotion) ?? []
        }

        let configParams = {...params}
        configPayload = {...configPayload, ...payload}

        const config = {
            country: country ?? store.country.id_country,
            id_machine: idMachine ?? store.machine?.id_machine ?? 0,
            shop_id: shopId ?? store.promoCode ? store.promoCode.id_shop : store.proofOfPurchase?.retailerStore?.value ?? reseller?.shop ?? 0,
            purchase_date: purchaseDate ?? store.proofOfPurchase ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` : null,
            has_account: hasAccount ?? store.nespressoMember !== false,
            promotion_id: promotionId ?? store.promoCode ? store.promoCode.id_promotion : 0,
        }

        console.log('fetchRaw', config)

        let promotions = []

        if (store.user) {
            promotions = await promotionActions.getAllReseller(
                config.country,
                config.id_machine,
                config.shop_id,
                config.purchase_date,
                config.has_account ? 1 : 0,
                config.promotion_id,
                configParams,
                configPayload,
            )
                .then(handleSuccess)
                .catch(handleError)
        } else {
            configParams = {
                is_fast_order: store.isFastOrder ? 1 : 0,
                ...configParams
            }

            if (store.nespressoMember && configParams.member_number !== undefined) {
                configParams['member_number'] = store.nespressoMember.member_id
            }

            promotions = await promotionActions.getAll(
                config.country,
                config.id_machine,
                config.shop_id,
                config.purchase_date,
                config.has_account ? 1 : 0,
                config.promotion_id,
                configParams,
                configPayload,
            )
                .then(handleSuccess)
                .catch(handleError)
        }

        setFetching(false)
        return promotions
    }

    const handleSuccess = (r) => {

        const res = r.data;
        if (res.status === 'success') {
            if (mounted.current) {
                return res.data.sort((a, b) => a.promotion_priority > b.promotion_priority ? -1 : 1);
            }
        } else {
            if(res.messages?.length) {
                errorTracking('Promo Selection', res.messages[0])
            }
            if (res.data) {
                if (mounted.current) {
                    setError(res.data);
                }
            }
            console.warn('Something gone wrong! ', res);
            return []
        }
    }

    const handleError = (error) => {
        setError('error.unknown_error')
        errorTracking('Promo Selection', 'unknown_error')
        console.error('Error on fetching promotions', error)
        return []
    }

    return {
        fetching,
        error,
        promotion: store.promotion,
        promotions: store.promotions,
        fetchAll,
        fetchRaw,
    }
}