import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useTranslation} from "../../../misc/Hooks";
import {useState} from "react";


/**
 *
 * @param code
 * @returns {JSX.Element}
 * @constructor
 */
export default function CodeField({code}) {
    const t = useTranslation()
    const [copied, setCopied] = useState(false)

    return (
        <>
            <CopyToClipboard text={code} onCopy={() => setCopied(true)}>
                <div className="codeField">
                    <p className="codeField__content">
                        {t('etrade.code_field_label')}
                        <br/><strong>{code}</strong>
                    </p>
                </div>
            </CopyToClipboard>
            {copied && <div className="codeField__info par-4">{t('etrade.code_field_copied')}</div>}
        </>
    )
}
