import {useMutation} from "@tanstack/react-query";
import {useModuleContext, useNespressoMember} from "./index";
import {getFavoriteCoffees} from "../actions/PromotionActions";
import {ApiException} from "../errors";
import {useEffect} from "react";

export default function usePersonalization(){
    const {nespressoMember} = useNespressoMember()
    const {reducer, dispatch} = useModuleContext()
    const mutation = useMutation(
        async () => {
            const {data} = await getFavoriteCoffees(nespressoMember.memberNumber)
            if (data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        {
            onSuccess: (data) => {
                dispatch(reducer.setPersonalization(data))
            }
        }
    )

    useEffect(() => {
        if(!nespressoMember?.memberNumber) {
            if(typeof (reducer?.setPersonalization) === "function") reducer.setPersonalization(null)
            return
        }
        mutation.mutate()
    }, [])

    return {
        mutation
    }
}
