import React, {useRef} from 'react';
import {AuthBox, CartBox, LanguageNav} from "../_index";
import CartAlerts from "../CartAlerts";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Routes from "../../utils/Routes";
import {useMarketConfig, useNavigation} from "../../misc/Hooks";
import useResizeObserver from "@react-hook/resize-observer";
import {InfoAlert, MessageAlert} from "../Header";
import {useReseller} from "../../hooks";

const Header = (props) => {
    const ref = useRef()
    const navigation = useNavigation();
    const reseller = useReseller()
    const marketConfig = useMarketConfig()
    const store = useSelector(({main}) => ({
        user: main.user,
    }))

    useResizeObserver(ref, (entry) => {
        const root = document.documentElement
        root.style.setProperty('--headerHeight', `${document.querySelector('.header')?.clientHeight ?? 0}px`)
    })

    return (
        <header ref={ref} className="header">
            <div className="wrapper -xxl">
                <div className={`grid ${store.user ? `-twoCol` : ''}`}>
                    {!store.user && (
                        <div className="header__box -left"/>
                    )}
                    <div className="header__box -center">
                        <Link
                            to={props.homeRoute ?? navigation(store.user ? Routes.V2_RESELLER : Routes.MACHINE_REGISTRATION_REGISTRATION)}
                            title="NespressoEV"
                        >
                            <span className="header__logo">
                                <img src={require('../../assets/img/logo-black.svg').default} alt="Nespresso"/>
                            </span>
                        </Link>
                        {!!props.secondLogo && props.secondLogo()}
                    </div>
                    <div className="header__box -right">


                        {!props.cartDisabled && !props.alertsDisabled && (
                            <CartAlerts storeName={props.storeName ?? 'ev'}/>
                        )}


                        {!!reseller.user && (
                            <>
                                <AuthBox/>
                                {!!marketConfig.reseller_messages_enabled && (
                                    <MessageAlert/>
                                )}
                                <InfoAlert/>
                            </>
                        )}

                        {!props.cartDisabled && (
                            <CartBox
                                storeName={props.storeName ?? 'ev'}
                                summaryRoute={props.summaryRoute ?? Routes.SHOP_DELIVERY_PAYMENT}
                                disableEdit={props.disableEdit}
                            />
                        )}


                        <LanguageNav/>

                    </div>
                </div>
            </div>
        </header>
    );
}


export default Header;
