import {AppProvider, Preloader, TpsConfig} from "../../misc/_index";
import {TpsContainer} from "./index";
import {useMarketConfig, useNavigation} from "../../misc/Hooks";
import {Redirect} from "react-router-dom";
import Routes from "../../utils/Routes";


const TpsFunnel = (props) => {
    const marketConfig = useMarketConfig();
    const navigation = useNavigation();


    return (
        <AppProvider>
            <TpsConfig>
                {!marketConfig || !Object.keys(marketConfig).length ? (
                    <Preloader/>
                ) : !marketConfig.tps ? (
                    <Redirect to={navigation(Routes.MACHINE_REGISTRATION_REGISTRATION)}/>
                ) : (
                    <TpsContainer/>
                )}
            </TpsConfig>
        </AppProvider>
    )
}


export default TpsFunnel;
