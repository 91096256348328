import {useTranslation} from "../../../../hooks";
import {DefaultSelectedActions} from "../../components";

export default function SelectedUsersActions({query, selectedItems, setSelectedItems, idFieldName, confirmingActions}){
    const {t} = useTranslation()

    const actionsOptions = [
        {label: t('reseller_client.renew_certificates'), value: 'renew_certificates'},
        {label: t('reseller_client.set_the_status.active'), value: 'set_status_active'},
        {label: t('reseller_client.set_the_status.not_active'), value: 'set_status_not_active'}
    ]

    return (
        <DefaultSelectedActions
            query={query}
            selectedItems={selectedItems}
            actionsOptions={actionsOptions}
            idFieldName={idFieldName}
            setSelectedItems={setSelectedItems}
            confirmingActions={confirmingActions}
        />
    )
}