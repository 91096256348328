import {Button} from "../../../components/buttons/_index";
import {useTranslation} from "../../../misc/Hooks";
import {SignInForm} from "../../../components/forms/_index";
import {Preloader} from "../../../misc/_index";
import {useStepsNavigation} from "../../../hooks/modules/etrade_reopen";
import {useNespressoMember} from "../../../hooks";
import {AuthForm} from "../../../forms";
import React from "react";


export default function AuthorizationScreen() {
    const t = useTranslation();
    const stepNavigation = useStepsNavigation();
    const {loginMutation, error, nespressoMember, storeLoggedUser} = useNespressoMember()

    const handleLogin = async (data) => {
        const payload = {
            login: data.email,
            password: data.password,
            recaptchaToken: data.recaptchaToken,
        }

        loginMutation.mutateAsync(payload)
            .then((data) => {
                storeLoggedUser(data, payload)
                stepNavigation.navToNext()
            })
    }

    return (
        <div className="evCID">

            <div className="grid">
                <div>
                    {!nespressoMember && !!t('shop_reopen.welcome_text') && (
                        <div
                            className="par-1 --semiBold --mb4"
                            dangerouslySetInnerHTML={{__html: t('shop_reopen.welcome_text')}}
                        />
                    )}

                    {!nespressoMember && (
                        <div className="grid">
                            <div className="col">
                                <div className="--mb3">
                                    <AuthForm
                                        state={{
                                            error,
                                            isLoading: loginMutation.isLoading,
                                        }}
                                        onSubmit={handleLogin}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {!!nespressoMember && (
                        <>
                            <div className="--mb4">{t('you_are_signed_in')}</div>
                            <div className="step__nav">
                                <Button
                                    label={t('continue')}
                                    href={stepNavigation.next()}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div/>
            </div>

        </div>
    );
}
