import {AsyncSelectController} from "../controllers";
import React, {useEffect} from "react";
import {useCountry, useTranslation} from "../../hooks";
import {getShopGroups2} from '../../actions/ShopActions';
import {useAsyncSelect} from "../hooks";
import {SelectOption} from "../../services";

export default function ShopGroupSelect({regionId, ...props}) {
    const {t} = useTranslation()
    const country = useCountry()
    const asyncSelect = useAsyncSelect({
        name: 'shopGroups',
        action: params => getShopGroups2(country.data.id_country, params),
        paramFunc: (slug) => ({
            search: slug,
            limit: 100,
            region: regionId,
        }),
        mapFunc: o => new SelectOption(o.shop_group_title, o.id_shop_group),
        queryOptions: {
            refetchOnWindowFocus: false,
        }
    })

    useEffect(() => {
        asyncSelect.query.refetch()
    }, [regionId])

    return (
        <AsyncSelectController
            asyncSelect={asyncSelect}
            name="shopGroup"
            label={t('trade_partner')}
            selectOptions={asyncSelect.query.isRefetching ? [] : undefined}
            {...props}
        />
    )
}
