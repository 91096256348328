import {useModuleContext, useOrderFinalized, useTranslation} from "../hooks";
import {AppProvider, Preloader} from "../misc/_index";
import {Footer, Header} from "../components/misc/_index";
import {GetInTouchModal} from "../components/modals/_index";

export default function OrderFinalizedScreen() {
    const {storeName} = useModuleContext()
    const {t} = useTranslation()
    const {typMutation, setGitModalVisible, gitModalVisible, sessionId} = useOrderFinalized(storeName)


    return (
        <AppProvider>
            <div className="app">
                <Header cartDisabled/>

                <div className="main">
                    <div className="wrapper-bg formContainer">

                        <div className="wrapper">

                            <div>
                                {typMutation.isLoading || sessionId ? (
                                    <div className="--mb4">
                                        <Preloader/>
                                    </div>
                                ) : typMutation.isSuccess ? (
                                    <div className="par-2 --mb4" style={{overflow: 'hidden'}}>
                                        <div dangerouslySetInnerHTML={{__html: typMutation.data.data.thank_you_page_text}}/>
                                    </div>
                                ) : (
                                    <div className="--mb4" style={{textAlign: 'center'}}>
                                        <h1 className="heading-1">
                                            {t('order_finalized.order_not_found.title') || t('stay_home.order_not_found.title')}
                                        </h1>
                                        <p className="par-1">
                                            {t('order_finalized.order_not_found.text') || t('stay_home.order_not_found.text')}
                                        </p>
                                    </div>
                                )}
                            </div>

                            <div className="par-4 --semiBold --alignCenter">
                                <span>{t('need_help')} </span>
                                <a className="link -primary -parentSize" onClick={() => setGitModalVisible(true)}>
                                    {t('get_in_touch')}
                                </a>
                            </div>

                        </div>
                    </div>
                </div>


                <GetInTouchModal
                    visible={gitModalVisible}
                    onClose={() => setGitModalVisible(false)}
                />


                <Footer/>
            </div>
        </AppProvider>
    )
}