import {AsyncSelectController} from "../controllers";
import React from "react";
import {useTranslation} from "../../hooks";
import {useAsyncSelect} from "../hooks";
import {getCities} from "../../actions/MainActions";
import {SelectOption} from "../../services";
import {useLocalizeObject} from "../../misc/Hooks";

export default function CitySelect(props) {
    const {t} = useTranslation()
    const localizeObject = useLocalizeObject()
    const asyncSelect = useAsyncSelect({
        name: 'cities',
        action: params => getCities(params),
        paramFunc: (slug) => ({
            startsWith: slug,
            limit: 100,
        }),
        mapFunc: o => new SelectOption(localizeObject(o.city_title) || o.city_name, o.id_city)
    })

    return (
        <AsyncSelectController
            asyncSelect={asyncSelect}
            name="city"
            label={t('city')}
            rules="citySelect"
            {...props}
        />
    )
}
