import React, {useMemo} from "react";
import {presetsConfig} from "../misc";

export default function useFormBuilder({
    formConfig,
    inputVisibility = {},
    inputComponents: _inputComponents,
}) {
    const inputComponents = useMemo(() => ({
        ...presetsConfig,
        ..._inputComponents,
    }), [_inputComponents])

    const components = useMemo(() => {
        return formConfig.map(
            row => {
                if(!row) return []
                return row.map(el => {
                    const component = inputComponents[el]
                    const visible = inputVisibility[el]
                    if (component && (typeof visible === 'undefined' || visible))
                        return component
                    return null
                }).filter(el => el)
            }
        ).filter(row => row.length)
    }, [formConfig, inputVisibility, inputComponents])

    return {
        components,
    }
}
