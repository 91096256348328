import {useLocation} from "react-router-dom";
import {useMemo} from "react";

export default function useSearchParams() {
    const location = useLocation()
    const params = useMemo(() => {
        return new URLSearchParams(location.search)
    }, [location.search])

    return {
        params,
        get: (name) => params.get(name),
    }
}
