import {getPostcodes} from "../../actions/MainActions";
import {useQuery} from "@tanstack/react-query";
import {useMemo} from "react";
import {ApiException} from "../../errors";
import {queryParams} from "../../misc/Helpers";

export default function useFetchPostcodesByCity(cityId) {
    const query = useQuery(
        ['city', cityId, 'postcodes'],
        async () => {
            const {data} = await getPostcodes(queryParams({city: cityId}))
            if (data.status === 'success')
                return data
            throw new ApiException(data.messages[0], data)
        },
        {
            enabled: !!cityId,
            retry: false,
        }
    )
    const selectOptions = useMemo(() => {
        if (query.isSuccess)
            return query.data.data.map(p => ({
                label: p.post_code_name,
                value: p.id_post_code,
            }))
        return [];
    }, [query.status])

    return {
        query,
        data: query.isSuccess ? query.data.data : [],
        selectOptions,
    }
}
