import {makeClassName} from "../../helpers";
import {useMemo} from "react";

export default function FormRow({children, ignoreBottomMargin, ...props}) {
    const className = useMemo(() => makeClassName('grid', {
        [props.className]: props.className,
    }), [props.className])
    return (
        <div
            {...props}
            className={className}
            style={{marginBottom: ignoreBottomMargin ? '-1rem' : undefined, ...props.style}}
        >{children}</div>
    )
}
