import {HiddenFileController, MaskInputController} from "../controllers";
import React, {useMemo, useRef, useState} from "react";
import {useFieldFormat, useFormHelper, useTranslation} from "../../hooks";
import uploadImg from '../../assets/img/icons/upload.svg';
import {isEnabledByMarket} from "../../utils/MarketConfig";
import {Html5Qrcode} from "html5-qrcode";
import {Scanner} from "../../components/_index";
import BaseModal from "../../components/modals/BaseModal";

const FILE_ACCEPT = 'image/png,image/jpeg,image/bmp'

export default function SerialNumberInput(props) {
    const fileInputRef = useRef()
    const scannerRef = useRef()
    const {t} = useTranslation()
    const [modalVisible, setModalVisible] = useState(false)
    const [cameras, setCameras] = useState(null)
    const {formats} = useFieldFormat()
    const {setAndTrigger} = useFormHelper()
    const styles = useMemo(() => ({
        input: {
            letterSpacing: 1,
        },
    }), [])

    const FileIconComponent = () => (
        <button type="button" onClick={handleClickUpload}>
            <img src={uploadImg} alt="upload"/>
        </button>
    )

    const handleClickUpload = async (e) => {
        e.preventDefault()
        if (!isEnabledByMarket('disableBarcodeScanner')) {
            const devices = await Html5Qrcode.getCameras()
                .catch(() => undefined)
            if (devices?.length) {
                setCameras(devices)
                setModalVisible(true)
                return
            }
        }
        if (fileInputRef) {
            fileInputRef.current.click()
        }
    }

    const onReadScanner = (text) => {
        setModalVisible(false)
        setAndTrigger('serialNumber', text)
    }

    const selectFile = () => {
        if (fileInputRef) {
            fileInputRef.current.click()
            setModalVisible(false)
        }
    }

    return (
        <>
            <MaskInputController
                name="serialNumber"
                mask={formats.serialNumber.getMask()}
                label={t('serial_number')}
                {...props}
                options={{
                    inputProps: {
                        style: styles.input,
                    },
                    renderIcon: () => <FileIconComponent/>,
                    ...props.options,
                }}
            />

            <HiddenFileController
                name="serialNumberFile"
                relatedInputName="serialNumber"
                inputRef={fileInputRef}
                inputProps={{
                    accept: FILE_ACCEPT,
                }}
            />

            <BaseModal
                visible={modalVisible}
                onClose={() => {
                    scannerRef?.current.stop();
                    scannerRef.current = null;
                    setModalVisible(false)
                }}
            >
                <Scanner
                    cameras={cameras}
                    onRead={onReadScanner}
                    scannerRef={scannerRef}
                    selectFile={selectFile}
                />
            </BaseModal>
        </>
    )
}
