import './style.scss'
import React from "react";
import {useModuleContext, useNespressoMember, useTranslation} from "../../hooks";
import sanitizeText from "../../helpers/sanitizeText";

export default function WelcomeMessage(){
    const {nespressoMember} = useNespressoMember()
    const {t} = useTranslation()
    const {store} = useModuleContext()

    if(!(nespressoMember?.firstName || store?.searchedMember?.firstName)) return null

    return (
            <div className="welcomeMessage">
                <span className="welcomeMessage__title" {...sanitizeText(t('welcome_message.title', nespressoMember?.firstName || store?.searchedMember?.firstName))} />
                <div className="par-2">
                    <p className="welcomeMessage__desc">{t('welcome_message.desc')}</p>
                </div>
            </div>
    )
}