import {Select, TextInput} from "../inputs";
import {useController} from "react-hook-form";
import {useFormHelper} from "../../hooks";
import {useMemo} from "react";

/**
 * TextInputController
 * @param name
 * @param label
 * @param defaultValue
 * @param rules
 * @param selectOptions
 * @param options
 * @returns {JSX.Element}
 * @constructor
 */
export default function SelectController({
    name: _name,
    label,
    defaultValue = '',
    rules: _rules,
    selectOptions,
    options,
    props,
}) {
    const formHelper = useFormHelper()
    const name = useMemo(
        () => formHelper.formFragmentContext.getInputName(_name),
        [_name, formHelper.formFragmentContext]
    )
    const rules = useMemo(
        () => formHelper.getRules(_rules ?? _name),
        [formHelper, _rules, _name]
    )
    const {
        field: {ref, ...controllerInputProps},
        fieldState,
    } = useController({
        name,
        control: formHelper.formMethods.control,
        defaultValue,
        rules: rules,
    })

    const inputOptions = formHelper.getInputOptions(options, rules)

    return (
        <Select
            {...controllerInputProps}
            inputRef={ref}
            fieldState={fieldState}
            label={label}
            selectOptions={selectOptions}
            options={{
                ...inputOptions,
                disabled: formHelper.formMethods.state?.isDisabled || inputOptions?.disabled,
            }}
            {...props}
        />
    )
}
