import {Loader} from "@googlemaps/js-api-loader";
import {useEffect, useMemo, useState} from "react";

export default function useGoogleApiLoader({
    apiKey,
    libraries,
    loaderProps,
}) {
    const [status, setStatus] = useState('idle')
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const statuses = useMemo(() => {
        return {
            isIdle: status === 'idle',
            isLoading: status === 'loading',
            isError: status === 'error',
            isSuccess: status === 'success',
        }
    }, [status])

    useEffect(() => {
        setStatus('loading')
        const loader = new Loader({
            ...loaderProps,
            apiKey,
            version: "weekly",
            libraries: [loaderProps?.libraries, ...libraries],
        });

        loader.load()
            .then(data => {
                setStatus('success')
                setData(data)
            })
            .catch(error => {
                setStatus('error')
                setError(error)
            })
    }, [])

    return {
        status,
        ...statuses,
        error,
        data,
    }
}
