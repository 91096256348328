import {MaskInputController, TextInputController} from "../controllers";
import React from "react";
import {useFieldFormat, useTranslation} from "../../hooks";

export default function AccountNumberInput(props) {
    const {t} = useTranslation()
    const {formats} = useFieldFormat()

    return (
        <MaskInputController
            name="accountNumber"
            label={t('bank_account.number')}
            mask={formats.accountNumber.getMask()}
            {...props}
        />
    )
}
