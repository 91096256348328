import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {CartSummary} from "./_index";
import {CartSummary as StayHomeCartSummary} from '../modules/stay_home/components'
import {useCart, useNavigation, useTranslation} from "../misc/Hooks";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {Config, Routes} from "../utils/_index";
import BaseModal from "./modals/BaseModal";


export const CartBox = (props) => {
    const match = useRouteMatch();
    const navigation = useNavigation()
    const store = useSelector(state => ({
        cart: state[props.storeName].cart,
        products: state[props.storeName].products,
        categories: state[props.storeName].categories,
    }));
    const [cartVisible, setCartVisible] = useState(false);
    const {capsulesCount} = useCart(props.storeName);
    const t = useTranslation();


    if (match.url.match(Routes.V2_RESELLER)) {
        return null;
    }


    return (
        <>
            <div className="basket">
                <button className="basket__info" onClick={() => setCartVisible(true)}>
                    <img src={require('../assets/img/icons/basket.svg').default} alt="" className="basket__infoThumb"/>
                    <span className="basket__infoCount">{capsulesCount}</span>
                </button>

                {(cartVisible && document.body.offsetWidth >= 768) && (
                    <>
                        <div className="cartBox__overlay" onClick={() => setCartVisible(false)}/>
                        <div className="cartBox">
                            <div className="cartBox__header">
                                <span/>
                                <h5 className="cartBox__title">{t('cart.title')}</h5>
                                <button className="cartBox__close" onClick={() => setCartVisible(false)}>
                                    <img src={require('../assets/img/icons/close.svg').default} alt="X"/>
                                </button>
                            </div>
                            <Switch>
                                <Route path={Config.STAY_HOME_STEPS.map(s => navigation(s.route))} render={() => (
                                    <StayHomeCartSummary
                                        storeName={props.storeName}
                                        cart={store.cart}
                                        categories={store.categories}
                                        products={store.products}
                                        onContinue={() => setCartVisible(false)}
                                        disableEdit={props.disableEdit}
                                        summaryRoute={props.summaryRoute}
                                        scrollable
                                    />
                                )}/>
                                <Route render={() => (
                                    <CartSummary
                                        storeName={props.storeName}
                                        cart={store.cart}
                                        categories={store.categories}
                                        products={store.products}
                                        onContinue={() => setCartVisible(false)}
                                        disableEdit={props.disableEdit}
                                        summaryRoute={props.summaryRoute}
                                        scrollable
                                    />
                                )}/>
                            </Switch>

                        </div>
                    </>
                )}
            </div>

            <BaseModal
                visible={cartVisible && document.body.offsetWidth < 768}
                onClose={() => setCartVisible(false)}
                small
            >
                <div className="--mb4">
                    <Switch>
                        <Route path={Config.STAY_HOME_STEPS.map(s => navigation(s.route))} render={() => (
                            <StayHomeCartSummary
                                storeName={props.storeName}
                                cart={store.cart}
                                categories={store.categories}
                                products={store.products}
                                disableEdit={props.disableEdit}
                                summaryRoute={props.summaryRoute}
                                onContinue={() => setCartVisible(false)}
                            />
                        )}/>
                        <Route render={() => (
                            <CartSummary
                                storeName={props.storeName}
                                cart={store.cart}
                                categories={store.categories}
                                products={store.products}
                                disableEdit={props.disableEdit}
                                summaryRoute={props.summaryRoute}
                                onContinue={() => setCartVisible(false)}
                            />
                        )}/>
                    </Switch>
                </div>
            </BaseModal>
        </>
    );
}
