import useLanguages from "./useLanguages";
import {useParams} from "react-router-dom";
import {useMemo} from "react";
import {useSelector} from "react-redux";
import Env from "../utils/Env";

export default function useCurrentLanguage() {
    const country = useSelector(({main}) => main.country)
    const {defaultLang} = useLanguages()
    const params = useParams()
    const currentLanguage = useMemo(() => params.lang ?? defaultLang, [params.lang, defaultLang])
    const apiLangCode = useMemo(() => {
        if(Env.LANGUAGES_FROM_BACKOFFICE && country?.country_languages?.length) {
            return country?.country_languages.find(l => l.lang_code === currentLanguage)?.lang_api_code ?? currentLanguage
        }
        return currentLanguage
    }, [country, currentLanguage])

    return {
        currentLanguage,
        apiLangCode,
    }
}
