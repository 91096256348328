import {useResellerAuth} from "../hooks";
import {Preloader} from "../../../misc/_index";
import {useTranslation} from "../../../hooks";
import {
    ResellerLoginForm,
    ResellerLoginSsoForm,
    ResellerNewPasswordForm,
    ResellerShopSelectionForm
} from "../../../forms";
import {Redirect, useLocation} from "react-router-dom";
import {Routes} from "../../../utils/_index";
import React, {Fragment} from "react";
import {useNavigation} from "../../../misc/Hooks";
import {InfoComponent} from "../../../components/_index";
import {CountryAuthImage} from "../components";

export default function ResellerAuthScreen() {
    const location = useLocation()
    const resellerAuth = useResellerAuth();
    const {t} = useTranslation();
    const navigation = useNavigation();

    if (resellerAuth.userStore) {
        return <Redirect to={location.state?.callbackUrl || navigation(Routes.V2_RESELLER)}/>
    }

    if (resellerAuth.ssoAuthorizationParam) {
        return (
            <div>
                <Preloader/>
            </div>
        )
    }

    return (
        <div className="resAuth">
            <div className="resAuth__content">
                {!resellerAuth.user ? (
                    <Fragment>
                        <h1 className="header-1">{t('reseller_auth.title')}</h1>
                        <h2 className="par-2 --mb3">{t('reseller_auth.subtitle')}</h2>
                        {resellerAuth.ssoFormVisible ? (
                            <ResellerLoginSsoForm
                                submitText={t('log_in')}
                                text={t('sign_in')}
                                state={{
                                    error: resellerAuth.error,
                                    isLoading: resellerAuth.isLoading
                                }}
                                onSubmit={resellerAuth.onCheckSso}
                            />
                        ) : (
                            <ResellerLoginForm
                                submitText={t('log_in')}
                                text={t('sign_in')}
                                state={{
                                    error: resellerAuth.error,
                                    isLoading: resellerAuth.isLoading
                                }}
                                editLogin={() => resellerAuth.setSsoFormVisible(true)}
                                onSubmit={resellerAuth.userLoginMutation.mutate}
                                defaultLogin={resellerAuth.defaultLogin}
                            />
                        )}
                    </Fragment>
                ) : (
                    <>
                        {resellerAuth.showNewPassword ? (
                            <Fragment>
                                <h2 className="par-2 --mb3">{t('reseller_auth.set_new_password')}</h2>
                                <div className="--mb4">
                                    <ResellerNewPasswordForm
                                        onSubmit={resellerAuth.temporaryPasswordMutation.mutate}
                                        state={{
                                            error: resellerAuth.error,
                                            isLoading: resellerAuth.isLoading
                                        }}
                                    />
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <h2 className="par-2 --mb3">{t('reseller_auth.select_shop')}</h2>
                                <div className="--mb4">
                                    <ResellerShopSelectionForm
                                        shops={resellerAuth.userShopsOptions}
                                        onSubmit={resellerAuth.onSubmitShopForm}
                                    />
                                </div>
                            </Fragment>
                        )}
                    </>
                )}
                <InfoComponent
                    type="error"
                    message={t(`validation.${resellerAuth.error}`) || t(`error.${resellerAuth.error}`)}
                />
                {!!location.state?.message && (
                    <InfoComponent
                        type="info"
                        message={t(location.state?.message)}
                    />
                )}
            </div>

            <div className="resAuth__thumb">
                <CountryAuthImage/>
            </div>

        </div>
    )
}