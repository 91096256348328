import {useReseller, useTranslation} from "../../../hooks";
import {useMarketConfig} from "../../../misc/Hooks";
import {permissions} from "../../../misc/PermissionsConfig";
import Routes from "../../../utils/Routes";
import {useMemo} from "react";
import {Tile} from "../components";

export default function AdminAndReportsScreen(){
    const {hasPermission} = useReseller();
    const {resellerModuleIsEnabled} = useMarketConfig();
    const {t} = useTranslation()

    const tiles = [
        {
            permission: permissions.userManagement,
            imageLight: require('../../../assets/img/icons/assisted-ic-light.svg').default,
            imageBold: require('../../../assets/img/icons/assisted-ic.svg').default,
            label: 'reseller_options.users',
            route: Routes.V2_RESELLER_USERS,
            enabled: resellerModuleIsEnabled('userManagement'),
        },
        {
            permission: permissions.reporting,
            imageLight: require('../../../assets/img/icons/report-ic-light.svg').default,
            imageBold: require('../../../assets/img/icons/report-ic.svg').default,
            label: 'reseller_options.reports',
            route: Routes.V2_RESELLER_REPORTS,
            enabled: resellerModuleIsEnabled('reporting'),
        },
        {
            permission: permissions.posStockManagement,
            imageLight: require('../../../assets/img/icons/stock-management-ic-light.svg').default,
            imageBold: require('../../../assets/img/icons/stock-management-ic.svg').default,
            label: 'reseller_options.pos_stock_management',
            route: Routes.V2_RESELLER_STOCK_MANAGEMENT,
            enabled: resellerModuleIsEnabled('posStockManagement'),
        },
    ]

    const enabledTiles = useMemo(() => tiles.filter(t => t.enabled && hasPermission(t.permission)), [])

    return (
        <div className="wrapper">
            <div className="resHome">
                {!!enabledTiles.length && (
                    <div className="resellerOptions__header">
                        <h2 className="heading-2">{t('reseller_options.title')}</h2>
                    </div>
                )}
                <div className="resellerOptions__items">
                    {enabledTiles.map(t => <Tile key={t.route} item={t}/>)}
                </div>
            </div>
        </div>
    )
}