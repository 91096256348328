import {AsyncSelectController, SelectController, TextInputController} from "../../../forms/controllers";
import {FormGroup} from "../../../forms/components";
import {useFormHelper, useModuleContext, useTranslation} from "../../../hooks";
import {useAsyncSelect} from "../../../forms/hooks";
import * as mainActions from "../../../actions/MainActions";
import {useWatch} from "react-hook-form";
import {useEffect, useMemo} from "react";

export default function MachineDetails() {

    const {t} = useTranslation()
    const {formMethods} = useFormHelper()
    const {store} = useModuleContext()

    const machinePartner = useWatch({control: formMethods.control, name: 'machinePartner'})
    const machineModel = useWatch({control: formMethods.control, name: 'machineModel'})
    const machineColor = useWatch({control: formMethods.control, name: 'machineColor'})

    const nullOption = {label: '', value: null}

    const machinePartnerOptions = useMemo(() => {
        return store.machinePartners
            .filter(i => store.machines.find(m => m.machine_partner === i.stack_value))
            .map(i => new Option(i.stack_title, i.stack_value))
    }, [store.machines])

    const machineModelOptions = useMemo(() => {
        if (!machinePartner?.value)
            return []

        const machines = store.machines.filter(m => m.machine_partner === machinePartner.value)

        return store.machineModels
            .filter(i => machines.find(m => m.machine_model === i.stack_value))
            .map(i => new Option(i.stack_title, i.stack_value))
    }, [machinePartner])

    const machineColorOptions = useMemo(() => {
        if (!machineModel?.value || !machinePartner.value)
            return []

        const machines = store.machines
            .filter(m => m.machine_partner === machinePartner.value)
            .filter(m => m.machine_model === machineModel.value)

        return store.machineColors
            .filter(s => machines.find(m => m.machine_color === s.stack_value))
            .map(i => new Option(i.stack_title, i.stack_value))

    }, [machineModel])

    const machineOptions = useMemo(() => {
        if (!machineColor?.value || !machinePartner?.value || !machineModel?.value) return []

        return store.machines
            .filter(m => m.machine_partner === machinePartner.value)
            .filter(m => m.machine_model === machineModel.value)
            .filter(m => m.machine_color === machineColor.value)
            .map(m => new Option(`${m.machine_title} - ${m.machine_sku_number}`, m.id_machine))
    }, [machineColor])

    const onChangePartner = (data) => {
        if (!data?.value)
            formMethods.setValue('machinePartner', nullOption)
        else
            formMethods.setValue('machinePartner', data)

        formMethods.setValue('machineModel', nullOption)
        formMethods.setValue('machineColor', nullOption)
        formMethods.setValue('machine', nullOption)
        formMethods.setValue('machineSku', '')
    }

    const onChangeModel = (data) => {
        if (!data?.value) {
            formMethods.setValue('machineModel', nullOption)
            return
        }
        formMethods.setValue('machineModel', data)
        formMethods.setValue('machineColor', nullOption)
        formMethods.setValue('machine', nullOption)
        formMethods.setValue('machineSku', '')
    }

    const onChangeColor = (data) => {
        if (!data?.value) {
            formMethods.setValue('machineColor', nullOption)
            return
        }
        formMethods.setValue('machineColor', data)
        formMethods.setValue('machine', nullOption)
        formMethods.setValue('machineSku', '')
    }

    const onChangeMachine = (data) => {
        if (!data?.value) {
            formMethods.setValue('machine', nullOption)
            return
        }
        formMethods.setValue('machine', data)

        const machine = store.machines.find(m => Number(m.id_machine) === Number(data.value))

        if (machine) {
            formMethods.setValue('serialNumber', '')
            formMethods.setValue('machineSku', machine.machine_sku_number)
        }
    }


    return (
        <>
            <FormGroup>
                <SelectController
                    name="machinePartner"
                    label={t('tps.machine_partner')}
                    selectOptions={machinePartnerOptions}
                    options={{
                        nullable: true,
                        inputProps: {
                            onChange: data => onChangePartner(data)
                        }
                    }}
                />
            </FormGroup>

            <FormGroup>
                <SelectController
                    name="machineModel"
                    label={t('tps.machine_model')}
                    selectOptions={machineModelOptions}
                    options={{
                        nullable: true,
                        disabled: !machinePartner?.value,
                        inputProps: {
                            onChange: data => onChangeModel(data)
                        }
                    }}
                />
            </FormGroup>
            <FormGroup>
                <SelectController
                    name="machineColor"
                    label={t('tps.machine_color')}
                    selectOptions={machineColorOptions}
                    options={{
                        nullable: true,
                        disabled: !machineModel?.value,
                        inputProps: {
                            onChange: data => onChangeColor(data)
                        }
                    }}
                />
            </FormGroup>

            <FormGroup>
                <SelectController
                    name="machine"
                    label={t('tps.machine')}
                    selectOptions={machineOptions}
                    options={{
                        nullable: true,
                        disabled: !machineColor?.value,
                        inputProps: {
                            onChange: data => onChangeMachine(data)
                        }
                    }}
                />
            </FormGroup>

            <FormGroup>
                <TextInputController
                    name="machineSku"
                    label={t('tps.machine_sku')}
                />
            </FormGroup>
        </>
    )
}