import {useMemo} from "react";
import {useCountry, useCurrentLanguage, useFieldFormat, useTranslation} from "../../hooks";
import Patterns from "../../utils/Patterns";

export default function useDefaultRules() {
    const {t} = useTranslation()
    const {formats} = useFieldFormat()
    const {data: country} = useCountry()
    const {currentLanguage} = useCurrentLanguage()

    return useMemo(() => ({
        title: b => b.required(),
        taxId: b => b.required(formats.taxId.isRequired)
            .maxLength(formats.taxId.max_length)
            .pattern(formats.taxId.pattern),
        companyTaxId: b => b.required(formats.companyTaxId.isRequired)
            .maxLength(formats.companyTaxId.max_length)
            .pattern(formats.companyTaxId.pattern),
        companyName: b => b.required(formats.companyName.isRequired)
            .alphanumeric('', Patterns.basicSpecialChars)
            .maxLength(formats.companyName.max_length)
            .pattern(formats.companyName.pattern),
        firstname: b => b.required(formats.firstname.isRequired)
            .alphanumeric('', Patterns.basicSpecialChars)
            .maxLength(formats.firstname.max_length)
            .pattern(formats.firstname.pattern),
        lastname: b => b.required(formats.lastname.isRequired)
            .alphanumeric('', Patterns.basicSpecialChars)
            .maxLength(formats.lastname.max_length)
            .pattern(formats.lastname.pattern),
        phoneticName: b => b.required()
            .alphanumeric('', Patterns.basicSpecialChars),
        postcode: b => b.required(formats.postcode.isRequired)
            .maxLength(formats.postcode.max_length)
            .pattern(formats.postcode.pattern),
        city: b => b.required(formats.city.isRequired)
            .alphanumeric('', Patterns.basicSpecialChars)
            .maxLength(formats.city.max_length)
            .pattern(formats.city.pattern),
        postcodeSelect: b => b.required(formats.postcode.isRequired),
        citySelect: b => b.required(formats.city.isRequired),
        lang: b => b.required(),
        country: b => b.required(),
        addressLine1: b => b.required(formats.addressLine1.isRequired)
            .alphanumeric('', Patterns.specialChars)
            .maxLength(formats.addressLine1.max_length)
            .pattern(formats.addressLine1.pattern ?? Patterns.getAddressRequiredSigns(country.country_shortcode, currentLanguage)),
        addressLine2: b => b.required(formats.addressLine2.isRequired)
            .alphanumeric('', Patterns.specialChars)
            .maxLength(formats.addressLine2.max_length)
            .pattern(formats.addressLine2.pattern),
        region: b => b.required(),
        profession: b => b.required(),
        phone: b => b.required(formats.phone.isRequired)
            .maxLength(formats.phone.max_length)
            .pattern(formats.phone.pattern),
        phoneMobile: b => b.required(formats.phoneMobile.isRequired)
            .maxLength(formats.phoneMobile.max_length)
            .pattern(formats.phoneMobile.pattern),
        phoneHome: b => b.required(formats.phoneHome.isRequired)
            .maxLength(formats.phoneHome.max_length)
            .pattern(formats.phoneHome.pattern),
        email: b => b.required().email(),
        reEmail: b => b.required().email().equals('$email', {sameAs: t('email')}),
        password: b => b.required().password(),
        rePassword: b => b.required().password().equals('$password', {sameAs: t('password')}),
        recaptchaToken: b => b.required(),
        bsbNumber: b => b.required()
            .pattern(formats.bsbNumber.pattern),
        accountNumber: b => b.required()
            .pattern(formats.accountNumber.pattern),
        serialNumber: b => b.required()
            .pattern(formats.serialNumber.pattern, t('error.serialnumber_to_short')),
        promoCode: b => b.required()
            .pattern(formats.promoCode.pattern)
    }), [])
}
