import React, {useMemo, useState} from 'react';
import {Button} from "./buttons/_index";
import {PromoItemModal} from "./modals/_index";
import {Config} from "../utils/_index";
import {useLocalizeObject, usePriceFormatter, useTranslation} from "../misc/Hooks";
import {getImage} from "../misc/Helpers";
import {isEnabledByMarket} from "../utils/MarketConfig";
import sanitizeText from "../helpers/sanitizeText";

const PromoItem = (props) => {
    const t = useTranslation();
    const localizeObject = useLocalizeObject()
    const {withCurrency, formatPriceObj} = usePriceFormatter();
    const [modalVisible, setModalVisible] = useState(false);
    const benefitText = useMemo(() => localizeObject(props.promotion.promotion_benefit_text), [props.promotion])
    const benefitText2 = useMemo(() => localizeObject(props.promotion.promotion_benefit_text2), [props.promotion])


    const coverImage = getImage(props.promotion.promotion_gallery);

    return (
        <div className={`promoItem ${props.promotion.id_promotion === props.selectedPromotion?.id_promotion ? '-active' : ''} ${props.isCompact ? '-sm' : ''}`}>
            <div className="promoItem__header">
                {!!coverImage && !props.isCompact && (
                    <div className="promoItem__thumb" onClick={props.onClick}>
                        <img src={coverImage} alt={localizeObject(props.promotion.promotion_title)}/>
                    </div>
                )}
                {!isEnabledByMarket('hidePromoTitle') && (
                    <h3
                        className="promoItem__title"
                        onClick={props.promotion.id_promotion === props.selectedPromotion?.id_promotion || props.disabled ? null : props.onClick}
                        dangerouslySetInnerHTML={{__html: localizeObject(props.promotion.promotion_title)}}
                    />
                )}
                {!props.isCompact && (
                    <p
                        className="promoItem__text"
                        dangerouslySetInnerHTML={{__html: localizeObject(props.promotion.promotion_description1)}}
                        onClick={props.promotion.id_promotion === props.selectedPromotion?.id_promotion || props.disabled ? null : props.onClick}
                    />
                )}
            </div>


            <div className="promoItem__body">
                <div className="promoItem__line">
                    {!!props.promotion.promotion_show_benefit && (
                        <>
                            {benefitText2 ? (
                                <h4
                                    className="promoItem__price"
                                    {...sanitizeText(benefitText2)}
                                />
                            ) : !!props.promotion.promotion_benefit && (
                              <>
                                  <h4 className="promoItem__price">
                                      {props.promotion.promotion_show_benefit_currency ? withCurrency(props.promotion.promotion_benefit) : props.promotion.promotion_benefit}
                                  </h4>
                                  {props.promotion.promotion_type === Config.PROMO_TYPE.SUBSCRIPTION_COFFEE && (
                                      <h4 className="promoItem__period">/ {t('per_month')}</h4>
                                  )}
                              </>
                            )}
                        </>
                    )}
                </div>
                {!!benefitText && (
                    <div
                        className="promoItem__label --mb2"
                        dangerouslySetInnerHTML={{__html: benefitText}}
                    />
                )}
            </div>


            <div
                className="promoItem__content"
                dangerouslySetInnerHTML={{__html: props.isCompact ? '' : localizeObject(props.promotion.promotion_description2)}}
                onClick={props.promotion.id_promotion === props.selectedPromotion?.id_promotion || props.disabled ? null : props.onClick}
            />


            <div className="promoItem__footerButtons">
                <div className="promoItem__footerButton">
                    <button
                        className="btn -primary -outline -xs -brownColor"
                        onClick={() => setModalVisible(true)}
                    >{t('read_more_promotion') || t('read_more')}</button>
                </div>
                {!props.readonly && (
                    <div className="promoItem__footerButton">
                        <Button
                            type="primary"
                            size={'lg'}
                            label={t(props.promotion.id_promotion === props.selectedPromotion?.id_promotion ? 'selected' : 'select')}
                            onClick={props.onClick}
                            disabled={props.promotion.id_promotion === props.selectedPromotion?.id_promotion || props.disabled}
                        />
                    </div>
                )}
            </div>


            {!!modalVisible && (
                <PromoItemModal
                    visible={modalVisible}
                    item={props.promotion}
                    onClose={() => setModalVisible(false)}
                />
            )}
        </div>
    );
}


export default React.memo(PromoItem);
