import React from 'react';
import {useTranslation} from "../../misc/Hooks";


export default (props) => {
    const t = useTranslation();
    return (
        <div className="errorPage">
            <h1 className="heading-1">401</h1>
            <h3 className="par-1">{t('not_authorize')}</h3>
        </div>
    );
}
