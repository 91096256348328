import React from "react";
import {Step} from "./components";
import Routes from "../../utils/Routes";
import {Config} from "../../utils/_index";
import BaseStepsFunnel from '../../components/stepsFunnel'

export default function StepsFunnel() {
    return (
        <BaseStepsFunnel
            sessionTime={Config.STAY_HOME_SESSION_TIME}
            summaryRoute={Routes.STAY_HOME_SUMMARY}
            StepComponent={Step}
        />
    )
}
