import {useState} from "react";
import {useMutation} from "@tanstack/react-query";
import * as mainActions from '../../../actions/MainActions';

export default function useAxLandingPage() {

    const [formVisible, setFormVisible] = useState(false)
    const [emailSendStatus, setEmailSendStatus] = useState(false)
    const mutation = useMutation(
        async data => {
            const res = await mainActions.sendAxEmail(data)
            if (res.data.status === 'success')
                return res.data

            throw new Error('error_send_email')
        },
        {
            onSuccess: () => {
                setEmailSendStatus(true)
            },
            onError: () => {
                console.log('error')
            }
        })

    const onSubmitForm = (data) => {
        mutation.mutate(data)
        console.log(mutation)
    }

    return {
        formVisible,
        setFormVisible,
        onSubmitForm,
        mutation,
        emailSendStatus
    }
}