import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone'
import {getBase64FromFile} from "../../../misc/Helpers";
import {ButtonClose} from "../../buttons/_index";


export default (props) => {
    const onDrop = acceptedFiles => {
        if(acceptedFiles[0]) {
            getBase64FromFile(acceptedFiles[0], props.acceptFiles).then(res => {
                props.onChange({
                    type: acceptedFiles[0].type,
                    base64: res,
                    file: acceptedFiles[0]
                });
            })
        }
    }
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: props.acceptFiles,
        maxFiles: 1,
        maxSize: 8388608,
    })

    return (
        <div className={`formGroup ${props.error ? '-invalid' : ''}`}>
            <div className="uploadBox">
                {!!props.value && (
                    <div className="uploadBox__close">
                        <ButtonClose
                            positionClass="-topRight"
                            onClick={() => props.onChange('')}
                        />
                    </div>
                )}
                <div {...getRootProps()} className="uploadBox__area">
                    {props.value?.base64?.match(/^data:image/) ? (
                        <img src={props.value.base64} alt="" className="uploadBox__uploadedImg"/>
                    ) : props.value?.base64?.match(/^data:application\/pdf/) ? (
                        <>
                            <embed
                                src={props.value?.base64}
                                width='100%'
                                height="375"
                                type="application/pdf"
                            />
                        </>
                    ) : (
                        <div className={`uploadBox__content ${isDragActive ? '-hover' : ''}`}>
                            <input
                                {...getInputProps()}
                                multiple={false}
                                accept={props.acceptFiles}
                            />

                            <div className="uploadBox__areaImg">
                                {props.loading ? (
                                    <img src={require('../../../assets/img/icons/preloader2.gif').default} alt=""/>
                                ) : (
                                    <img src={require('../../../assets/img/icons/camera.svg').default} alt=""/>
                                )}
                            </div>

                            <span className="uploadBox__areaText" dangerouslySetInnerHTML={{__html: props.placeholder}}/>
                        </div>
                    )}
                </div>
            </div>

            {!!props.error && (
                <span className="formGroup__invalidFeedback">{props.error.message}</span>
            )}
        </div>
    );
}
