import Form from "./Form";
import {useForm, useWatch} from "react-hook-form";
import {useFormRulesBuilder} from "./hooks";
import {
    useCurrentLanguage,
    useFieldFormat,
    useGTM,
    useModuleContext,
    useReseller,
    useSerialNumber,
    useTranslation
} from "../hooks";
import {FormGroup, FormRow} from "./components";
import React, {useEffect, useMemo, useState} from "react";
import {Button, ButtonClose} from "../components/buttons/_index";
import {SerialNumberInput} from "./presets";
import {getUploadUrl} from "../helpers";
import {isEnabledByMarket} from "../utils/MarketConfig";
import {SkipMsnModal, SNHelpModal} from "../components/modals/_index";
import {useMutation} from "@tanstack/react-query";
import {useResellerActions} from "../actions/useResellerActions";
import {InfoComponent} from "../components/_index";

export default function SerialNumberForm({
    options,
    onSubmit,
}) {
    options = {
        inRow: true,
        checkPromotionLocalStock: false,
        ...options,
    }
    const resellerActions = useResellerActions()
    const reseller = useReseller()
    const {t} = useTranslation()
    const {store, dispatch, reducer} = useModuleContext()
    const {
        mutation,
        fileMutation,
        machine,
        serialNumber: storedSerialNumber,
        fileData,
        isLoading: isSerialNumberLoading,
        error,
        logs
    } = useSerialNumber()
    const {formats, getIfValid} = useFieldFormat()
    const currentLanguage = useCurrentLanguage()
    const {successfullyEntersMachineSerialNumber} = useGTM()
    const localStockPromotionsMutation = useMutation({
        mutationFn: () => resellerActions.localStockPromotionsExists(reseller.shopId),
    })
    const {isRequired} = useMemo(() => {
        return {
            isRequired: reseller.isLoggedIn && options.checkPromotionLocalStock
                ? (localStockPromotionsMutation.data?.data?.data?.msn_required ?? store.msnRequired)
                : true
        }
    }, [localStockPromotionsMutation.status, store.msnRequired])
    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            serialNumber: storedSerialNumber || fileData?.serial_number,
            serialNumberFile: fileData ? getUploadUrl(fileData.filename) : undefined,
        },
    })
    const formRules = useFormRulesBuilder({
        serialNumber: b => b.required()
            .pattern(formats.serialNumber.pattern, t('error.serialnumber_to_short'))
            .validate(() => machine && !error, error)
    }, formMethods)
    const [snHelpModalVisible, setSnHelpModalVisible] = useState(false);
    const [submitVisible, setSubmitVisible] = useState(!(!isRequired && !storedSerialNumber))
    const [skipMsnModalVisible, setSkipMsnModalVisible] = useState(false)

    const isLoading = localStockPromotionsMutation.isLoading || isSerialNumberLoading

    const serialNumber = useWatch({
        control: formMethods.control,
        name: 'serialNumber',
    })
    const file = useWatch({
        control: formMethods.control,
        name: 'serialNumberFile',
    })
    const fileUrl = useMemo(() => {
        if (file instanceof File)
            return URL.createObjectURL(file)
        if (typeof file === 'string')
            return file
        return undefined
    }, [file])

    useEffect(() => {
        if (reseller.isLoggedIn && options.checkPromotionLocalStock) {
            localStockPromotionsMutation.mutate()
        }
    }, [])

    useEffect(() => {
        const value = getIfValid(serialNumber, formats.serialNumber.pattern)
        if (value) {
            setSubmitVisible(true)
            mutation.mutate({serialNumber})
        }
    }, [serialNumber])

    useEffect(() => {
        if (file instanceof File) {
            formMethods.setValue('serialNumber', undefined)
            fileMutation.mutate({file})
        }
    }, [file])

    useEffect(() => {
        if (serialNumber) {
            formMethods.trigger('serialNumber')
        }
    }, [machine])

    useEffect(() => {
        if (fileData) {
            formMethods.setValue('serialNumber', fileData.serial_number, {shouldDirty: true})
        }
    }, [fileData])

    useEffect(() => {
        if (error) {
            formMethods.setError('serialNumber', {type: 'invalid', message: error})
        } else {
            formMethods.clearErrors('serialNumber')
        }
    }, [error])

    const handleSubmit = (data) => {
        if (typeof onSubmit === 'function') {
            onSubmit({data, machine, fileData, logs, isRequired})
            setSkipMsnModalVisible(false)
            if (data.serialNumber) {
                successfullyEntersMachineSerialNumber(data.serialNumber)
            } else {
                setSubmitVisible(false)
                formMethods.clearErrors('serialNumber')
            }
        }
    }

    const onSkip = () => {
        formMethods.setValue('serialNumber', undefined)
        handleSubmit({})
    }

    const handleRemoveFile = () => {
        formMethods.setValue('serialNumberFile', undefined)
    }

    const SerialNumberInfo = () => (
        <>
            {!!t('serial_number_info') && (
                <p className="par-3 --mb1" dangerouslySetInnerHTML={{__html: t('serial_number_info')}}/>
            )}
            {!!(reseller.isLoggedIn && !isRequired) && (
                <InfoComponent
                    type="info"
                    message={t('serial_number_optional_msn_info')}
                />
            )}
        </>
    )

    const SnModalButton = () => (
        <button
            type="button"
            className="link -primary --mb2_5"
            onClick={() => setSnHelpModalVisible(true)}
        >{t('open_sn_modal_label')}</button>
    )

    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            onSubmit={handleSubmit}
        >

            <FormRow style={{display: options.inRow ? undefined : 'block'}}>
                <FormGroup elementProps={{className: '--mb0'}}>
                    <p
                        className="par-1 --semiBold"
                        dangerouslySetInnerHTML={{__html: t('ev_machine_registration.text2')}}
                    />

                    {isEnabledByMarket('serialNumberModalButtonTop') && (<SnModalButton/>)}
                    {currentLanguage === 'he' && (
                        <>
                            <SerialNumberInfo/>
                            {!isEnabledByMarket('serialNumberModalButtonTop') && (<SnModalButton/>)}
                        </>
                    )}
                </FormGroup>
                <div/>
            </FormRow>
            <FormRow style={{display: options.inRow ? undefined : 'block'}}>
                <FormGroup>
                    <div className="--mb2">
                        <SerialNumberInput
                            options={{
                                isLoading,
                                maskInputProps: {
                                    disabled: localStockPromotionsMutation.isLoading,
                                }
                            }}
                        />
                    </div>

                    <div className="serialNumber__footer">
                        <div className="serialNumber__info">
                            {currentLanguage !== 'he' && (
                                <>
                                    <SerialNumberInfo/>
                                    {!isEnabledByMarket('serialNumberModalButtonTop') && (<SnModalButton/>)}
                                </>
                            )}
                        </div>

                        {!!submitVisible && (
                            <div className="serialNumber__submitWrapper">
                                {!isRequired && (
                                    <Button
                                        type="grey"
                                        label={t('continue_without_msn')}
                                        disabled={isLoading}
                                        onClick={() => setSkipMsnModalVisible(true)}
                                    />
                                )}
                                <Button
                                    type="primary"
                                    btnType="submit"
                                    label={t('continue')}
                                    disabled={isLoading}
                                />
                            </div>
                        )}
                    </div>
                </FormGroup>
                <FormGroup>
                    {!!file && (
                        <div className="thumb__box">
                            <ButtonClose
                                positionClass="-topRight"
                                onClick={handleRemoveFile}
                            />
                            <img src={fileUrl} alt="" className="thumb__img"/>
                        </div>
                    )}
                </FormGroup>
            </FormRow>

            <SNHelpModal
                visible={snHelpModalVisible}
                onClose={() => setSnHelpModalVisible(false)}
            />

            <SkipMsnModal
                isVisible={skipMsnModalVisible}
                onClose={() => setSkipMsnModalVisible(false)}
                onSubmit={onSkip}
            />
        </Form>
    )
}
