import React from 'react';
import BaseModal from "./BaseModal";
import {useTranslation} from "../../hooks";

export default function AssistedOrderFullySavedModal({visible, onClose}) {
    const {t} = useTranslation();

    return (
        <BaseModal
            visible={visible}
            onClose={onClose}
        >
            <div className="modal__body --mb0">
                <div className="--alignCenter">
                    <h3 className="modal__title --mb3">{t('modal.assisted_order_fully_saved.title')}</h3>
                    <p className="modal__text">{t('modal.assisted_order_fully_saved.text')}</p>
                </div>
            </div>
        </BaseModal>
    );
}
