import React from "react";
import {Step} from "./components";
import Routes from "../../utils/Routes";
import {Config} from "../../utils/_index";
import useFetchPromotions, {PromotionsProvider} from '../../hooks/useFetchPromotions'
import useFetchCategories, {CategoriesProvider} from '../../hooks/useFetchCategories'
import BaseStepsFunnel from '../../components/stepsFunnel'
import {useNavigation} from "../../misc/Hooks";

export default function StepsFunnel() {
    const fetchPromotions = useFetchPromotions()
    const fetchCategories = useFetchCategories()
    const navigation = useNavigation()

    return (
        <PromotionsProvider value={fetchPromotions}>
            <CategoriesProvider value={fetchCategories}>
                <BaseStepsFunnel
                    sessionTime={Config.EV_SESSION_TIME}
                    homeRoute={navigation(Routes.MACHINE_REGISTRATION_REGISTRATION)}
                    summaryRoute={Routes.MACHINE_REGISTRATION_SUMMARY}
                    StepComponent={Step}
                />
            </CategoriesProvider>
        </PromotionsProvider>
    )
}
