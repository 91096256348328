import {useMutation} from "@tanstack/react-query";
import {ApiException} from "../errors";
import * as Helpers from "../misc/Helpers";
import * as OrderActions from "../actions/OrderActions";
import {mapOrderV2} from "../misc/Helpers";
import * as promotionActions from "../actions/PromotionActions";

export default function useFetchOrder() {
    const mutation = useMutation({
        mutationFn: async (payload) => {
            const {data} = await OrderActions.getOrderByUuid(payload)

            if(data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
    })
    const promotionMutation = useMutation({
        mutationFn: async (id) => {
            const {data} = await promotionActions.getById(id)

            if(data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
    })

    const fetchOrder = async (uuid) => {
        const order = await mutation.mutateAsync(uuid)

        const mappedOrder = mapOrderV2(order)
        let promotion = null
        const paymentMethods = [
            {
                id_payment_method: order.id_payment_method,
                payment_method_id_nestle: order.payment_method_id_nestle,
                payment_method_id_nestle_credit_note: order.payment_method_id_nestle_credit_note,
                payment_method_title: order.payment_method_title,
            }
        ]
        const deliveryMethods = [order.delivery_method]

        if (order.id_promotion) {
            if (order.promotion) {
                promotion = order.promotion
            } else {
                promotion = await promotionMutation.mutateAsync(order.id_promotion)
            }
        }

        return {
            cart: mappedOrder.cart,
            products: mappedOrder.products,
            categories: mappedOrder.categories,
            promotion,
            order,
            paymentMethods,
            deliveryMethods,
        }
    }

    return {
        fetchOrder,
    }
}
