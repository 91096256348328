import Env from "./Env";

const languages = Env.LANGUAGES.join('|');

const LANG_PARAM = `/:lang(${languages})?`;

const Routes = {
    LANG_PARAM,

    HOME: `${LANG_PARAM}/`,
    CLEAR_STORAGE: '/vst/clear-storage',
    MAINTENANCE: `${LANG_PARAM}/maintenance`,
    PAYMENT_REJECTED: `${LANG_PARAM}/payment-rejected`,

    ORDER_FINALIZED: `${LANG_PARAM}/order-finalized/:token?`,
    UPLOAD_PROOF_OF_PURCHASE: `${LANG_PARAM}/upload-proof-of-purchase/:token`,
    FINISH_ORDER_TOKEN: `${LANG_PARAM}/finish-order/:token`,
    ETRADE_SHOP: `${LANG_PARAM}/etrade-shop/:token`,
    TPS: `${LANG_PARAM}/old/tps`,
    TPS_V2: `${LANG_PARAM}/tps`,
    AX_LANDING_PAGE: `${LANG_PARAM}/lp/:slug`,

    SHOP: `${LANG_PARAM}/`,
    SHOP_MACHINE_REGISTRATION: `${LANG_PARAM}/old/machine-registration`,
    SHOP_CUSTOMER_IDENTIFICATION: `${LANG_PARAM}/old/customer-identification`,
    SHOP_PROMO_SELECTION: `${LANG_PARAM}/old/promo-selection`,
    SHOP_COFFEE_SELECTION: `${LANG_PARAM}/old/coffee-selection`,
    SHOP_CUSTOMER_INFORMATION: `${LANG_PARAM}/old/customer-information`,
    SHOP_DELIVERY_PAYMENT: `${LANG_PARAM}/old/delivery-and-payment`,

    RESELLER: `${LANG_PARAM}/v1/reseller`,
    RESELLER_AUTH: `${LANG_PARAM}/v1/reseller/sign-in`,
    RESELLER_FORGOT_PASSWORD: `${LANG_PARAM}/v1/reseller/forgot-password`,
    RESELLER_FORGOT_PASSWORD_SET_NEW: `${LANG_PARAM}/v1/reseller/forgot-password/:token`,
    RESELLER_MACHINE_SUBSCRIPTIONS: `${LANG_PARAM}/v1/reseller/machine-subscriptions`,
    RESELLER_MACHINE_SUBSCRIPTION_MACHINE: `${LANG_PARAM}/v1/reseller/machine-subscription/machine`,
    RESELLER_MACHINE_SUBSCRIPTION_IDENTIFICATION: `${LANG_PARAM}/v1/reseller/machine-subscription/identification`,
    RESELLER_MACHINE_SUBSCRIPTION_PLAN: `${LANG_PARAM}/v1/reseller/machine-subscription/plan`,
    RESELLER_MACHINE_SUBSCRIPTION_CUSTOMER: `${LANG_PARAM}/v1/reseller/machine-subscription/customer`,
    RESELLER_MACHINE_SUBSCRIPTION_SUMMARY: `${LANG_PARAM}/v1/reseller/machine-subscription/summary`,
    RESELLER_ASSISTED_REGISTRATION: `${LANG_PARAM}/v1/reseller/assisted-registration`,
    RESELLER_NO_MACHINE_SHOP: `${LANG_PARAM}/v1/reseller/subscription-or-coffee-order`,

    V2_RESELLER: `${LANG_PARAM}/reseller`,
    V2_RESELLER_AUTH: `${LANG_PARAM}/reseller/sign-in`,
    V2_RESELLER_FORGOT_PASSWORD: `${LANG_PARAM}/reseller/forgot-password`,
    V2_RESELLER_FORGOT_PASSWORD_SET_NEW: `${LANG_PARAM}/reseller/forgot-password/:token`,
    V2_AUTH_RESELLER_REGISTRATION: `${LANG_PARAM}/reseller/registration/:token?`,
    V2_AUTH_RESELLER_VERIFICATION: `${LANG_PARAM}/reseller/verification/:status/:token/:parent_token`,
    V2_RESELLER_ASSISTED_REGISTRATION: `${LANG_PARAM}/reseller/assisted-registration`,
    V2_RESELLER_NO_MACHINE_SHOP: `${LANG_PARAM}/reseller/subscription-or-coffee-order`,
    V2_RESELLER_MACHINE_SUBSCRIPTIONS: `${LANG_PARAM}/reseller/machine-subscriptions`,
    V2_RESELLER_ADMIN_REPORTS: `${LANG_PARAM}/reseller/admin-and-reports`,
    V2_RESELLER_BURN: `${LANG_PARAM}/reseller/burn`,
    V2_RESELLER_AX: `${LANG_PARAM}/reseller/ax`,
    V2_RESELLER_MESSAGES: `${LANG_PARAM}/reseller/messages`,
    V2_RESELLER_MESSAGE: `${LANG_PARAM}/reseller/messages/:id`,
    V2_RESELLER_NEW_MESSAGE: `${LANG_PARAM}/reseller/messages/new/:commentId`,
    V2_RESELLER_COMMENT: `${LANG_PARAM}/reseller/messages/:id/:commentId`,
    V2_RESELLER_FAST_ORDER: `${LANG_PARAM}/reseller/fast-order`,
    V2_RESELLER_USERS: `${LANG_PARAM}/reseller/users/:id?`,
    V2_RESELLER_USERS_USER: `${LANG_PARAM}/reseller/users/user/:id`,
    V2_RESELLER_REPORTS: `${LANG_PARAM}/reseller/reports`,
    V2_RESELLER_REPORTS_NEW: `${LANG_PARAM}/reseller/reports-new`,
    V2_RESELLER_STOCK_MANAGEMENT: `${LANG_PARAM}/reseller/stock-management`,
    V2_RESELLER_STOCK_MANAGEMENT_POS: `${LANG_PARAM}/reseller/stock-management/pos/:id`,
    V2_RESELLER_FINALIZE_ORDER: `${LANG_PARAM}/reseller/finalize-order/:uuid`,

    RESELLER_ADMIN_REPORTS: `${LANG_PARAM}/v1/reseller/admin-and-reports`,
    RESELLER_REPORTS: `${LANG_PARAM}/v1/reseller/reports`,
    RESELLER_REPORTS_NEW: `${LANG_PARAM}/v1/reseller/reports-new`,
    RESELLER_USERS: `${LANG_PARAM}/v1/reseller/users/:id?`,
    RESELLER_USERS_USER: `${LANG_PARAM}/v1/reseller/users/user/:id`,

    RESELLER_BURN: `${LANG_PARAM}/v1/reseller/burn`,

    RESELLER_MESSAGES: `${LANG_PARAM}/v1/reseller/messages`,
    RESELLER_MESSAGE: `${LANG_PARAM}/v1/reseller/messages/:id`,
    RESELLER_NEW_MESSAGE: `${LANG_PARAM}/v1/reseller/messages/new/:commentId`,
    RESELLER_COMMENT: `${LANG_PARAM}/v1/reseller/messages/:id/:commentId`,

    AUTH_RESELLER_REGISTRATION: `${LANG_PARAM}/v1/reseller/registration/:token?`,
    AUTH_RESELLER_VERIFICATION: `${LANG_PARAM}/v1/reseller/verification/:status/:token/:parent_token`,

    // FINISH_ORDER: `${LANG_PARAM}/finish`,
    FINISH_ORDER_ACCOUNT: `${LANG_PARAM}/finish/account`,
    FINISH_ORDER_CUSTOMER: `${LANG_PARAM}/finish/customer`,
    FINISH_ORDER_SUMMARY: `${LANG_PARAM}/finish/summary`,
    FINISH_ORDER_PAYMENT: `${LANG_PARAM}/finish/payment`,

    FINISH_ORDER: `${LANG_PARAM}/finish-order/:token`,
    ORDER_FINISH: `${LANG_PARAM}/order-finish/:token?`,
    ORDER_FINISH_AUTHORIZATION: `${LANG_PARAM}/order-finish/account`,
    ORDER_FINISH_CUSTOMER: `${LANG_PARAM}/order-finish/customer`,
    ORDER_FINISH_SUMMARY: `${LANG_PARAM}/order-finish/summary`,
    ORDER_FINISH_PAYMENT: `${LANG_PARAM}/order-finish/payment`,
    ORDER_FINISH_TOKEN_EXPIRED: `${LANG_PARAM}/order-finish/token-expired`,
    ORDER_FINISH_INVALID_TOKEN: `${LANG_PARAM}/order-finish/invalid-token`,
    ORDER_FINISH_ORDER_FINALIZED: `${LANG_PARAM}/order-finish/order-finalized/:uuid`,

    STAY_HOME: `${LANG_PARAM}/exclusive`,
    STAY_HOME_AUTHORIZATION: `${LANG_PARAM}/exclusive/authorization`,
    STAY_HOME_PLAN_SELECTION: `${LANG_PARAM}/exclusive/plan-selection`,
    STAY_HOME_COFFEE_SELECTION: `${LANG_PARAM}/exclusive/coffee-selection`,
    STAY_HOME_CUSTOMER: `${LANG_PARAM}/exclusive/customer`,
    STAY_HOME_SUMMARY: `${LANG_PARAM}/exclusive/summary`,
    STAY_HOME_TOKEN_EXPIRED: `${LANG_PARAM}/exclusive/token-expired`,
    STAY_HOME_ORDER_FINALIZED: `${LANG_PARAM}/exclusive/order-finalized/:token`,

    ETRADE: `${LANG_PARAM}/etrade/:token?`,
    ETRADE_MACHINE_PLAN: `${LANG_PARAM}/etrade/machine-plan`,
    ETRADE_AUTHORIZATION: `${LANG_PARAM}/etrade/authorization`,
    ETRADE_CUSTOMER: `${LANG_PARAM}/etrade/customer`,
    ETRADE_SUMMARY: `${LANG_PARAM}/etrade/summary`,
    ETRADE_TOKEN_EXPIRED: `${LANG_PARAM}/etrade/token-expired`,
    ETRADE_INVALID_TOKEN: `${LANG_PARAM}/etrade/invalid-token`,
    ETRADE_ORDER_FINALIZED: `${LANG_PARAM}/etrade/order-finalized/:uuid`,

    NEW_SHOP_ALIAS1: `${LANG_PARAM}/new`,
    NEW_SHOP: `${LANG_PARAM}/v1/`,
    NEW_SHOP_MACHINE_REGISTRATION: `${LANG_PARAM}/v1/machine-registration`,
    NEW_SHOP_OFFER_SELECTION: `${LANG_PARAM}/v1/promo-and-coffee-selection`,
    NEW_SHOP_CUSTOMER_INFORMATION: `${LANG_PARAM}/v1/customer-information`,
    NEW_SHOP_SUMMARY: `${LANG_PARAM}/v1/summary`,
    NEW_SHOP_ORDER_FINALIZED: `${LANG_PARAM}/v1/order-finalized/:token`,

    MACHINE_REGISTRATION: `${LANG_PARAM}/`,
    MACHINE_REGISTRATION_REGISTRATION: `${LANG_PARAM}/machine-registration`,
    MACHINE_REGISTRATION_OFFER_SELECTION: `${LANG_PARAM}/promo-and-coffee-selection`,
    MACHINE_REGISTRATION_CUSTOMER_INFORMATION: `${LANG_PARAM}/customer-information`,
    MACHINE_REGISTRATION_SUMMARY: `${LANG_PARAM}/summary`,
    MACHINE_REGISTRATION_ORDER_FINALIZED: `${LANG_PARAM}/order-finalized/:token`,

    SHOP_REOPEN: `${LANG_PARAM}/reopen/:token`,
    SHOP_REOPEN_AUTHORIZATION: `${LANG_PARAM}/reopen/authorization`,
    SHOP_REOPEN_MACHINE_REGISTRATION: `${LANG_PARAM}/reopen/machine-registration`,
    SHOP_REOPEN_OFFER_SELECTION: `${LANG_PARAM}/reopen/promo-and-coffee-selection`,
    SHOP_REOPEN_CUSTOMER_INFORMATION: `${LANG_PARAM}/reopen/customer-information`,
    SHOP_REOPEN_SUMMARY: `${LANG_PARAM}/reopen/summary`,
    SHOP_REOPEN_ORDER_FINALIZED: `${LANG_PARAM}/reopen/order-finalized/:token`,
    SHOP_REOPEN_INVALID_TOKEN: `${LANG_PARAM}/reopen/invalid-token`,

    ETRADE_REOPEN: `${LANG_PARAM}/reopen-etrade/:token`,
    ETRADE_REOPEN_MACHINE_PLAN: `${LANG_PARAM}/reopen-etrade/machine-plan`,
    ETRADE_REOPEN_AUTHORIZATION: `${LANG_PARAM}/reopen-etrade/authorization`,
    ETRADE_REOPEN_CUSTOMER: `${LANG_PARAM}/reopen-etrade/customer`,
    ETRADE_REOPEN_SUMMARY: `${LANG_PARAM}/reopen-etrade/summary`,
    ETRADE_REOPEN_TOKEN_EXPIRED: `${LANG_PARAM}/reopen-etrade/token-expired`,
    ETRADE_REOPEN_INVALID_TOKEN: `${LANG_PARAM}/reopen-etrade/invalid-token`,
    ETRADE_REOPEN_ORDER_FINALIZED: `${LANG_PARAM}/reopen-etrade/order-finalized/:uuid`,

    FAST_ORDER: `${LANG_PARAM}/reseller/fast-order`,
    FAST_ORDER_CUSTOMER: `${LANG_PARAM}/fast-order/:uuid`,

    NO_MACHINE_SHOP:  `${LANG_PARAM}/reseller/no-machine`,
    NO_MACHINE_SHOP_ORDER:  `${LANG_PARAM}/reseller/no-machine/order`,
    NO_MACHINE_SHOP_CUSTOMER:  `${LANG_PARAM}/reseller/no-machine/customer`,
    NO_MACHINE_SHOP_SUMMARY:  `${LANG_PARAM}/reseller/no-machine/summary`,

    SUBSCRIPTION: `${LANG_PARAM}/machine-subscription`,
    SUBSCRIPTION_MACHINE: `${LANG_PARAM}/machine-subscription/machine`,
    SUBSCRIPTION_IDENTIFICATION: `${LANG_PARAM}/machine-subscription/identification`,
    SUBSCRIPTION_PLAN: `${LANG_PARAM}/machine-subscription/plan`,
    SUBSCRIPTION_CUSTOMER: `${LANG_PARAM}/machine-subscription/customer`,
    SUBSCRIPTION_SUMMARY: `${LANG_PARAM}/machine-subscription/summary`,
    SUBSCRIPTION_CONFIRM_ORDER: `${LANG_PARAM}/machine-subscription/confirm-order/:token`,

    RESELLER_AX: `${LANG_PARAM}/reseller/ax`,

}


export default Routes;
