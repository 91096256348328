import {Button} from "../buttons/_index";
import React, {useEffect, useMemo} from "react";
import {useOnExitPage, useReseller, useTranslation} from "../../hooks";
import {useDispatch, useSelector} from "react-redux";
import {reducers, useMarketConfig} from "../../misc/Hooks";
import BaseModal from "../modals/BaseModal";

export default function AbandonedOrderConsent({storeName, ...props}) {
    const {sendLog} = useOnExitPage(storeName)
    const {t} = useTranslation()
    const reducer = useMemo(() => reducers[storeName], [storeName])
    const dispatch = useDispatch()
    const reseller = useReseller()
    const store = useSelector(state => ({
        abandonedOrder: state[storeName]?.abandonedOrder,
        abandonedOrderConsentAccepted: state[storeName]?.abandonedOrderConsentAccepted,
        isNewMember: state[storeName]?.isNewMember,
        nespressoMember: state[storeName]?.nespressoMember,
        customer: state[storeName]?.customer,
    }))
    const marketConfig = useMarketConfig()

    useEffect(() => {
        if(store.abandonedOrderConsentAccepted) {
            sendLog(null, 'STEP_CHANGE')
        }
    }, [store.abandonedOrderConsentAccepted])

    useEffect(() => {
        if(store.abandonedOrder) {
            dispatch(reducer.setAbandonedOrderConsentAccepted(true))
        }
    }, [store.abandonedOrder])

    const onAccept = () => {
        dispatch(reducer.setAbandonedOrderConsentAccepted(true))
    }

    const onReject = () => {
        dispatch(reducer.setAbandonedOrderConsentAccepted(false))
    }

    if (store.abandonedOrderConsentAccepted !== undefined
        || !marketConfig.order_abandon_find
        || store.abandonedOrder
    )
        return null

    if(store.isNewMember) {
        if(!store.customer)
            return null
    }
    if(reseller.user) {
        if(!store.customer)
            return null
    }
    else if(typeof store.nespressoMember !== 'object') {
        return null
    }
    if(typeof store.nespressoMember === 'undefined' || store.nespressoMember === null)
        return null

    return (
        <BaseModal visible>
            {!!t('abandoned_order_consent.title') && (
                <div className="modal__header">
                    <h3 className="modal__title" dangerouslySetInnerHTML={{__html: t('abandoned_order_consent.title')}}/>
                </div>
            )}
            <div
                className="cookieConsent__content par-5 --mb4"
                dangerouslySetInnerHTML={{__html: t('abandoned_order_consent.text')}}
            />
            <div className="cookieConsent__buttons">
                <Button type="primary-outline" label={t('abandoned_order_consent.reject')} onClick={onReject}/>
                <Button type="primary" label={t('abandoned_order_consent.accept')} onClick={onAccept}/>
            </div>
        </BaseModal>
    )
}
