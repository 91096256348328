export default function CheckboxLabel({label, children, options}) {

    const RequiredIcon = () => !!options.isRequired && ' *'

    if (typeof label === 'function')
        return label({options, RequiredIcon})

    return (
        <span className="checkbox__label">
            {label}<RequiredIcon/>
        </span>
    )
}
