import {useResellerVerification} from "../hooks";
import {Preloader} from "../../../misc/_index";
import React from "react";
import {useTranslation} from "../../../hooks";

export default function ResellerVerificationScreen() {

    const {statusVerification, isLoading, paramsStatus} = useResellerVerification()
    const {t} = useTranslation();

    return (
        <div className="resAuth">
            <div className="resAuth__content">
                <h1 className="par-1 --alignCenter">{t('reseller_verification.title')}</h1>
                <h2 className={`par-2 --alignCenter --uppercase --mb3 --${statusVerification}`}>
                    {statusVerification === null ? t('reseller_verification.wait')
                        : statusVerification === 'success'
                            ? t(`reseller_verification.success_${paramsStatus}`) : t('reseller_verification.error')}
                </h2>
                <div className="--mb4">
                    {!!isLoading && (
                        <Preloader/>
                    )}
                </div>
            </div>
        </div>
    )
}