import {Config, Routes} from "../../../utils/_index";
import {useSelector} from "react-redux";
import {useCountry} from "../../index";


/**
 * Returns Machine subscription module steps
 * @returns {{component, route: string, title: string}[]}
 */
export default function useSteps() {
    const store = useSelector(({main, subscription}) => ({
        user: main.user,
        isNewMember: subscription.isNewMember,
    }))
    const country = useCountry()

    return Config.SUBSCRIPTION_STEPS
        .filter(s => store.user ? !(!(store.isNewMember || country.data.country_assisted_simplified_flow_enabled) && s.route === Routes.SUBSCRIPTION_CUSTOMER) : true)
}
