import Form from "./Form";
import {useForm} from "react-hook-form";
import {useFormRulesBuilder} from "./hooks";
import {FormGroup} from "./components";
import {EmailInput, LangSelect} from "./presets";
import {Button} from "../components/buttons/_index";
import {useModuleContext, useTranslation} from "../hooks";
import sanitizeText from "../helpers/sanitizeText";
import React, {useEffect, useMemo} from "react";
import * as stayHomeReducer from "../redux/stayHomeReducer";
import {useSelector} from "react-redux";
import {TextInput} from "./inputs";
import {TextInputController} from "./controllers";

export default function StayHomeCodeForm({code, state, onGoBack, onSubmit}) {
    state = {
        isLoading: false,
        error: null,
        ...state,
    }
    const {t} = useTranslation()
    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            code: code ?? '',
        }
    })
    const formRules = useFormRulesBuilder({
        code: b => b.required(),
    }, formMethods)

    useEffect(() => {
        if (state.error) {
            formMethods.setError('code', {type: 'invalid', message: t(`error.${state.error}`) || t('error.unknown_error')})
        } else {
            formMethods.clearErrors('code');
        }
    }, [state.error])


    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            state={{
                isDisabled: state.isLoading,
            }}
            onSubmit={onSubmit}
        >
            <FormGroup>
                <TextInputController
                    name="code"
                    label={t('stay_home.identification.code_placeholder')}
                />
            </FormGroup>


            <div className="step__nav">
                <Button
                    type="primary-outline"
                    label={t('back')}
                    disabled={state.isLoading}
                    onClick={onGoBack}
                />
                <Button
                    btnType="submit"
                    label={t('continue')}
                    disabled={state.isLoading}
                    isLoading={state.isLoading}
                />
            </div>
        </Form>
    )
}
