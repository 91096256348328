import React from 'react';
import BaseModal from "../../modals/BaseModal";
import {useTranslation} from "../../../misc/Hooks";
import {GenerateReportForm} from "../../forms/reseller";
import {Button} from "../../buttons/_index";

export default (props) => {
    const t = useTranslation();

    return (
        <BaseModal
            visible={props.visible}
            onClose={props.onClose}
        >
            {props.resellersList && (
                <div>
                    {props.resellersList.map((reseller, key) => (
                        <span className="--dBlock">{key + 1}. {reseller}</span>
                    ))}
                </div>
            )}
            {props.shopsList && (
                <div>
                    {props.shopsList.map((shop, key) => (
                        <span className="--dBlock">{key + 1}. {shop.shop_title}</span>
                    ))}
                </div>
            )}
        </BaseModal>
    );
}
