
import {CheckboxController} from "../controllers";
import React from "react";
import {useTranslation} from "../../hooks";

export default function WithCustomAddressCheckbox(props) {
    const {t} = useTranslation()

    return (
        <CheckboxController
            name="withCustomAddress"
            label={t('deliver_to_another_address')}
            {...props}
        />
    )
}
