import {getDateTime} from "../../../../misc/Helpers";
import {useSelector} from "react-redux";
import {AttachmentItem, PriorityIcon} from "./index";
import {useEffect, useState} from "react";
import {FiUser, FiSend, FiMessageSquare, FiCalendar, FiMessageCircle} from 'react-icons/fi';
import {useTranslation} from "../../../../hooks";
import {makeClassName} from "../../../../helpers";
import {useNavigation} from "../../../../misc/Hooks";
import Routes from "../../../../utils/Routes";
import {Link} from "react-router-dom";
import {ImSpinner8} from "react-icons/im";


export default function CommentItem({item, index, message, ...props}) {
    const {t} = useTranslation()
    const navigation = useNavigation()
    const store = useSelector(({main}) => ({
        user: main.user,
    }))
    const [isRead, setRead] = useState(!!item.is_read)

    useEffect(() => {
        if (!item.is_read) {
            // withDelay(1000 + (index * 250)).then(() => setRead(true))
        }
    }, [item])

    const className = makeClassName(`comment -priority-${item.thread_message_priority}`, {
        '-myComment': store.user.id_reseller === item.id_reseller,
        '-new': !isRead && store.user.id_reseller !== item.id_reseller,
    })
    const commentsClassName = makeClassName('comment__comments', {
        '-hasNew': item.last_message?.is_read,
    })

    return (
        <div className={className}>
            <div className="comment__wrapper">
                <p
                    className="comment__text par-5 --mb0"
                    dangerouslySetInnerHTML={{__html: item.thread_message_text}}
                />
                {!!item.attachments?.length && (
                    <div className="comment__attachments">
                        {item.attachments.map(item => (
                            <AttachmentItem
                                key={item.id_thread_message_attachment}
                                item={item}
                            />
                        ))}
                    </div>
                )}
                <div className="comment__footer">
                    <p className="comment__author par-2">
                        <FiUser/> {item.reseller_author || item.bo_author}
                    </p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {!!(!item.last_message && message && message?.data?.thread_two_way_communication && message.data.thread_title) && (
                            <button
                                type="button"
                                className="link -primary -parentSize"
                                style={{marginRight: '1rem', fontSize: '0.85rem'}}
                                onClick={() => message.createThread(item.id_thread_message)}
                            >
                                {message.newMessageMutation.isLoading ? (
                                    <ImSpinner8 className="spinner"
                                                style={{fontSize: '0.7em', marginRight: '0.25rem'}}/>
                                ) : (
                                    <FiMessageCircle title={t('reseller_messages.create_comment_thread')}/>
                                )}
                            </button>
                        )}
                        <p className="comment__date par-2">
                            <FiCalendar/> {getDateTime(item.updated_at)}
                        </p>
                    </div>
                </div>
            </div>
            {!!item.last_message && (
                <div className={commentsClassName}>
                    <span className="comment__commentsTotal">
                        <FiSend/>{' '}{t('reseller_messages.replies_total', item.messages_count)}
                    </span>
                    <span className="comment__commentsTotal">
                        <FiMessageSquare/>
                        {' '}
                        {t(
                            `reseller_messages.${item.last_message.is_read ? 'last_message_from' : 'user_sent_a_message'}`,
                            item.last_message.reseller_author || item.last_message.bo_author,
                            getDateTime(item.last_message.updated_at),
                        )}
                    </span>
                    <Link
                        to={navigation(Routes.V2_RESELLER_MESSAGE, item.last_message.id_thread)}
                        className="comment__commentsAuthor"
                    >
                        <FiMessageSquare/>{' '}{t('reseller_messages.reply_in_thread')}
                    </Link>
                </div>
            )}

            <div className="messageItem__infoIcons">
                <PriorityIcon priority={item.thread_message_priority}/>
            </div>
        </div>
    )
}
