import {Button} from "./buttons/_index";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "../misc/Hooks";


export default function CookieConsent({module, text, url}) {
  const t = useTranslation()
  const dispatch = useDispatch()
  const store = useSelector(state => ({
    cookiesAccepted: state[module].cookiesAccepted,
  }))

  const onAccept = () => {
    dispatch({type: `${module}/setCookiesAccepted`, cookiesAccepted: true})
  }

  const onReject = () => {
    dispatch({type: `${module}/setCookiesAccepted`, cookiesAccepted: false})
    if(url)
      window.location.href = url
  }

  return store.cookiesAccepted === undefined && (
    <div className="cookieConsent">
      <div className="wrapper">
        <div
          className="cookieConsent__content par-5"
          dangerouslySetInnerHTML={{__html: text}}
        />
        <div className="cookieConsent__buttons">
          <Button type="primary-outline" label={t('cookie_consent.reject')} onClick={onReject}/>
          <Button type="primary" label={t('cookie_consent.accept')} onClick={onAccept}/>
        </div>
      </div>
    </div>
  )
}