import {Routes} from "../../../utils/_index";
import {useCart, useNavigation} from "../../../misc/Hooks";
import {useSelector} from "react-redux";
import {useStepsNavigation} from "./index";
import {useHistory, useLocation} from "react-router-dom";
import {isEnabledByMarket} from "../../../utils/MarketConfig";


/**
 * Validates Shop module route / step
 * @param route
 * @type route: string
 * @returns {{lastValidRoute: (*), value: boolean}}
 */
export default function useStepValidation(route) {
    const navigation = useNavigation()
    const location = useLocation()
    const stepsNavigation = useStepsNavigation()
    const history = useHistory()
    const store = useSelector(({main, noMachineShop}) => ({
        country: main.country,
        user: main.user,
        data: noMachineShop.data,
        nespressoMember: noMachineShop.nespressoMember,
        promotion: noMachineShop.promotion,
        customer: noMachineShop.customer,
        isNewMember: noMachineShop.isNewMember
    }))
    const orderUuid = new URLSearchParams(location?.search).get('uuid')
    const {isCartValid} = useCart('noMachineShop')

    const response = {
        canShowStep: true,
        lastValidStep: Routes.NO_MACHINE_SHOP,
    }

    switch (route) {
        case Routes.NO_MACHINE_SHOP_SUMMARY:
            if(orderUuid) {
                response.canShowStep = true
                response.lastValidStep = Routes.ORDER_FINISH_SUMMARY
                break
            }
            if(!store.customer) {
                response.canShowStep = false
                response.lastValidStep = Routes.NO_MACHINE_SHOP_CUSTOMER
            }
        case Routes.NO_MACHINE_SHOP_CUSTOMER:
            if(!isCartValid || !store.promotion) {
                response.canShowStep = false
                response.lastValidStep = Routes.NO_MACHINE_SHOP_ORDER
            }
        case Routes.NO_MACHINE_SHOP_ORDER:
            if (!(isEnabledByMarket('disableEcapi') || (store.isNewMember === false ? store.nespressoMember : typeof store.isNewMember === 'boolean'))) {
                response.canShowStep = false
                response.lastValidStep = stepsNavigation.prev()
            }
        case Routes.NO_MACHINE_SHOP:
    }

    return {
        value: response.canShowStep,
        lastValidRoute: navigation(response.lastValidStep),
    }
}
