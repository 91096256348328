import React, {useEffect, useState} from 'react';
import {useForm, useWatch} from "react-hook-form";
import {DatePickerController, SelectController} from "./controllers";
import Form from "./Form";
import {useCurrentLanguage, useTranslation} from "../hooks";
import {useFormRulesBuilder} from "./hooks";
import {Button} from "../components/buttons/_index";
import {FormGroup} from "./components";

export default function EasyOrderSetupForm(props) {
    const {t} = useTranslation();
    const {currentLanguage} = useCurrentLanguage()
    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            frequency: props.data?.frequency ?? null,
            nextShipment: props.data?.nextShipment ?? null,
        }
    });
    const formRules = useFormRulesBuilder({
        frequency: b => b.required(),
        nextShipment: b => b.required(),
    }, formMethods)

    const frequency = useWatch({
        name: 'frequency',
        control: formMethods.control,
    });
    const nextShipment = useWatch({
        name: 'nextShipment',
        control: formMethods.control,
    });
    const [dates, setDates] = useState([]);
    const [isDisabled, setIsDisabled] = useState(props.data);


    useEffect(() => {
        if (props.data) {
            setIsDisabled(true);
        }
    }, [props.data])


    useEffect(() => {
        getDates();
    }, [frequency, nextShipment])


    const getOptions = () => {
        const arr = [];
        props.frequencies?.map(i => {
            arr.push({
                label: i.label,
                value: i.id,
            })
        })

        return arr.sort((a, b) => {
            return a.value.localeCompare(b.value, undefined, {numeric: true, sensitivity: 'base'})
        });
    }

    const getDates = () => {
        if (!frequency || !nextShipment)
            return;

        const freqObj = props.frequencies.find(i => i.id === frequency.value)
        if (!freqObj)
            return;

        const arr = [];
        for (let i = 0; i < 3; i++) {
            const date = new Date(nextShipment);

            if (i > 0) {
                switch (freqObj.frequency.timeUnit) {
                    case "MONTHS":
                        date.setMonth(date.getMonth() + (freqObj.frequency.quantity * i));
                        break;
                    case "WEEKS":
                        date.setDate(date.getDate() + (freqObj.frequency.quantity * i * 7));
                        break;
                    case "DAYS":
                        date.setDate(date.getDate() + (freqObj.frequency.quantity * (i + 1)));
                        break;
                }
            }


            arr.push(Intl.DateTimeFormat([currentLanguage], {
                month: "short",
                day: "2-digit",
                year: "numeric",
            }).format(date));
        }
        setDates(arr);
    }

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1)

    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            onSubmit={props.onSubmit}
        >
            {/*<form onSubmit={handleSubmit(props.onSubmit)}>*/}

            <p className="evCS__categoryName --lg" dangerouslySetInnerHTML={{__html: t('easy_order.title')}}/>


            <div className="grid --mb4">
                <div>
                    <p className="par-2 --semiBold" dangerouslySetInnerHTML={{__html: t('easy_order.text')}}/>
                    <div className="">
                        <FormGroup>
                            <SelectController
                                name="frequency"
                                label={t('easy_order.frequency')}
                                selectOptions={getOptions()}
                                options={{
                                    disabled: isDisabled,
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <DatePickerController
                                name="nextShipment"
                                label={t('easy_order.next_shipment')}
                                options={{
                                    inputProps: {
                                        minDate: tomorrow,
                                        maxDate: undefined,
                                        disabled: isDisabled,
                                    }
                                }}
                            />
                        </FormGroup>
                    </div>

                    <div className="easyOrderForm__text">
                        <p className="par-4" dangerouslySetInnerHTML={{__html: t('easy_order.text2')}}/>
                    </div>
                </div>
                <div className="easyOrderForm__summary">
                    {!!dates?.length && (
                        <>
                            <p className="par-2 --semiBold --mb3"
                               dangerouslySetInnerHTML={{__html: t('easy_order.text3')}}/>
                            <ul>
                                {dates?.map((d, idx) => (
                                    <li key={idx}>{d}</li>
                                ))}
                                <li>...</li>
                            </ul>
                        </>
                    )}
                </div>
            </div>


            <div className="step__nav --mb4">
                {isDisabled ? (
                    <Button
                        type="primary-outline"
                        label={t('edit')}
                        onClick={() => setIsDisabled(false)}
                    />
                ) : (
                    <>
                        {!props.data && (

                            <Button
                                type="primary-outline"
                                label={t('back')}
                                href={props.stepNavigation.prev()}
                            />

                        )}
                        <Button
                            btnType="submit"
                            type="primary"
                            label={t(props.data ? 'easy_order.save' : 'continue')}
                            disabled={isDisabled}
                        />
                    </>
                )}

            </div>

            {/*</form>*/}
        </Form>
    );
}
