import {useMemo} from "react";
import {useModuleContext, useReseller} from "./index";
import {useCountryConfig, useMarketConfig} from "../misc/Hooks";

export default function useRequiredField() {
    const {store} = useModuleContext()
    const {isLoggedIn, isPopRequired} = useReseller()
    const countryConfig = useCountryConfig()
    const marketConfig = useMarketConfig()
    const fieldsConfig = useMemo(() => ({
        date: {
            isVisible: !!marketConfig.pop_validation_ocr_date_display,
            isRequired: !!marketConfig.pop_validation_ocr_date_mandatory,
        },
        region: {
            isVisible: !!countryConfig.shop_region_visible,
            isRequired: false,
        },
        shopGroup: {
            isVisible: !!marketConfig.pop_validation_ocr_trade_partner_display,
            isRequired: !!marketConfig.pop_validation_ocr_trade_partner_mandatory,
        },
        shop: {
            isVisible: !!marketConfig.pop_validation_ocr_retailer_store_display,
            isRequired: !!marketConfig.pop_validation_ocr_retailer_store_mandatory,
        },
        invoiceNumber: {
            isVisible: !!marketConfig.pop_validation_ocr_invoice_number_display,
            isRequired: !!marketConfig.pop_validation_ocr_invoice_number_mandatory,
        },
        machinePrice: {
            isVisible: !!marketConfig.pop_validation_ocr_machine_price_display,
            isRequired: !!marketConfig.pop_validation_ocr_machine_price_mandatory,
        },
    }), [marketConfig])

    const requiredField = useMemo(() => {
        if(isLoggedIn) {
            return isPopRequired() ? countryConfig?.required_field_reseller : 'none_required'
        }
        if(store.isFastOrder)
            return countryConfig?.required_field_fast_order
        return countryConfig?.required_field
    }, [isLoggedIn, countryConfig, store.isFastOrder])

    const popStepValid = useMemo(() => {
        if (requiredField === 'proof_of_purchase_and_promo_code')
            return isProofOfPurchaseValid(store.proofOfPurchase) && store.promoCode
        if (requiredField === 'proof_of_purchase_or_promo_code')
            return isProofOfPurchaseValid(store.proofOfPurchase) || store.promoCode
        if (requiredField === 'only_promo_code')
            return !!store.promoCode
        if (requiredField === 'only_proof_of_purchase')
            return isProofOfPurchaseValid(store.proofOfPurchase)
        return requiredField === 'none_required';
    }, [requiredField, store.proofOfPurchase, store.promoCode])

    function isProofOfPurchaseValid(data) {
        if(!store.proofOfPurchase)
            return false
        return !Object.keys(data)
            .find(k => fieldsConfig[k]?.isRequired && !store.proofOfPurchase[k])
    }

    return {
        requiredField,
        popStepValid,
        fieldsConfig,
    }
}
