import * as OrderActions from "../../../actions/OrderActions";
import {useEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useNavigation, useTranslation} from "../../../misc/Hooks";
import {Preloader} from "../../../misc/_index";
import * as machineActions from "../../../actions/MachineActions";
import * as machineRegistrationReducer from "../../../redux/machineRegistrationReducer";
import {uuidv4} from "../../../misc/Helpers";
import {Routes} from "../../../utils/_index";
import * as rmsReducer from "../../../redux/rmsReducer";
import * as mainReducer from "../../../redux/mainReducer";

let MOUNTED = false

export default function FastOrderCustomerContainer() {

    const match = useRouteMatch()
    const t = useTranslation();
    const store = useSelector(({main, shop}) => ({
        user: main.user,
        serialNumber: shop.serialNumber,
        machine: shop.machine,
        uuid: shop.uuid,
        ocrLogs: shop.ocrLogs,
    }));

    const dispatch = useDispatch();
    const history = useHistory();
    const navigation = useNavigation();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!!store.user) {
            dispatch(rmsReducer.clearStore());
            dispatch(mainReducer.clearUser());
        }
        if (!!match.params.uuid) {
            getFastOrder(match.params.uuid)
        }
    }, [match.params.uuid])

    const getFastOrder = (uuid) => {
        OrderActions.getFastOrder(uuid).then(r => {
            const res = r.data
            if (res.status === 'success') {
                const order = res.data.order
                if (!!order && store.uuid !== order.uuid) {
                    dispatch(machineRegistrationReducer.clearStore())
                    const formData = {
                        serial_number: order.serial,
                    }
                    machineActions.validateSerialNumber(formData).then(r => {
                        const machine = r.data;
                        if (machine.status === 'success') {
                            dispatch(machineRegistrationReducer.setUuid(order.uuid))
                            dispatch(machineRegistrationReducer.setFastOrderShop(res.data.shop))
                            dispatch(machineRegistrationReducer.setIsFastOrder(1))
                            dispatch(machineRegistrationReducer.setMachine(machine.data.machine, machine.data.test_order, {
                                ...store.serialNumber,
                                serial_number: order.serial,
                            }));
                            history.push(navigation(Routes.MACHINE_REGISTRATION_REGISTRATION))
                        } else {
                           setLoading(false)
                        }
                    }).catch(error => {
                        setLoading(false)
                    })
                } else {
                    history.push(navigation(Routes.MACHINE_REGISTRATION_REGISTRATION))
                }
            } else {
                setLoading(false)
                console.log("Something gone wrong!")
            }
        }).catch(err => {
            setLoading(false)
            console.error('Error:', err)
        })
    }


    return (
                <div className="fastOrderCustomer">
                    {!!loading ? (
                    <Preloader/>
                    ) : (
                        <span className="--alignCenter --dBlock --uppercase --bold">{t('fast_order.order_not_exist')}</span>
                    )}
                </div>
    )
}