import {Button} from "../../../components/buttons/_index";
import React, {Fragment} from "react";
import * as machineRegistrationReducer from "../../../redux/machineRegistrationReducer";
import {Routes} from "../../../utils/_index";
import {useDispatch} from "react-redux";
import {useCountryConfig, useNavigation} from "../../../misc/Hooks";
import {useHistory} from "react-router-dom";
import {useQrCodeModal, useTranslation} from "../../../hooks";
import {TableComponent} from "../../../components/baseTable";
import {useAssistedRegistrationOrderTable} from "../hooks";
import {isEnabledByMarket} from "../../../utils/MarketConfig";
import {ResendButton} from "../components";
import {QrCodeModal} from "../../../components/modals/_index";

export default function AssistedRegistrationScreen() {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigation = useNavigation()
    const history = useHistory();
    const {resellerModuleIsEnabled} = useCountryConfig();
    const qrCodeModal = useQrCodeModal(Routes.ORDER_FINISH);

    const {assistedTable, assistedFilters} = useAssistedRegistrationOrderTable({
        payload: {
            order_type: ['register_machine', 'subscription_coffee', 'easy_order', 'fast_order'],
            id_reseller: [-1]
        },
        daysLimit: true,
        hiddenColumns: [isEnabledByMarket('hidePaymentStatusReseller') ? 'payment_status' : null],
        columns: [
            {
                name: 'options',
                label: t('order.options'),
                cell: ({row: {original}}) => {
                    if (original.promotion_type === 'fast_order' && original.status === -3)
                        return <ResendButton order={original}/>

                    return [1, 2].includes(original.payment_status) ? null : (
                        <div className="tableOptions">
                            <ResendButton order={original}/>
                            <Button
                                size="sm"
                                label={t('generate_qr_code')}
                                onClick={() => qrCodeModal.showQrCode(original.uuid, original.lang, original.payment_link_time)}
                            />
                        </div>
                    )
                }
            }
        ]
    })

    const navToMachineRegistration = () => {
        dispatch(machineRegistrationReducer.clearStore())
        history.push(navigation(Routes.MACHINE_REGISTRATION_REGISTRATION))
    }

    return (
        <Fragment>
            <div className="wrapper -xxl">
                <div className="resMS">
                    <div className="resMS__header">
                        <div className="--flex --columnGap1">
                            <Button
                                type="primary-outline"
                                label={t('assisted_registration.join_the_promotion') ?? t('register_new_customer')}
                                onClick={navToMachineRegistration}
                            />
                            {resellerModuleIsEnabled('fastOrder') ? (
                                <Button
                                    type="primary-outline"
                                    label={t('assisted_registration.fast_order.button_new')}
                                    href={navigation(Routes.V2_RESELLER_FAST_ORDER)}
                                />
                            ) : null}
                        </div>
                        {!!t('assisted_registration.need_help_url') ? (
                            <a
                                href={t('assisted_registration.need_help_url')}
                                target="_blank"
                                className="link -primary -parentSize --dBlock --pt1 --pl2 --bold"
                            >
                                {t('assisted_registration.need_help_label')}
                            </a>
                        ) : null}
                    </div>

                    <TableComponent
                        {...assistedTable}
                        title={t('assisted_registration.orders')}
                        filters={assistedFilters}
                    />

                </div>
            </div>
            <QrCodeModal {...qrCodeModal} />
        </Fragment>

    )
}