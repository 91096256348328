import {Button} from "../../../../components/buttons/_index";
import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "../../../../hooks";

export default function DownloadReportButton({status, url}){

    const {t} = useTranslation();

    const config = useMemo(() => {
        switch (status){
            case 0: return {type: 'grey', label: 'reports.table.button.in_the_process', loading: true}
            case -1: return {type: 'error', label: 'reports.table.button.error', loading: false}
            case 1: return {type: '', label: 'reports.table.button.download', loading: false}
        }
    }, [status])

    const ButtonComponent = (
        <Button
            label={t(config.label)}
            size="sm"
            type={config.type}
            isLoading={config.loading}
        />
    )

    return url && status === 1 ? (
        <a href={url} target="_blank" download>
            {ButtonComponent}
        </a>
    ) : ButtonComponent
}