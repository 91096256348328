import {FiChevronLeft} from "react-icons/fi";
import {useTranslation} from "../../../../hooks";
import {useHistory} from "react-router-dom";
import {useMemo} from "react";
import {makeClassName} from "../../../../helpers";
import {useInView} from "react-intersection-observer";
import {ImSpinner8} from "react-icons/im";


export default function MessageNav({loading}) {
    const {t} = useTranslation()
    const history = useHistory()
    const headerHeight = useMemo(() => {
        const value = getComputedStyle(document.body).getPropertyValue('--headerHeight')
        if (!value) return 0
        return Number(value.replace('px', ''))
    }, [])
    const {ref, inView} = useInView({
        rootMargin: `${-headerHeight - 1}px 0px 0px 0px`,
        threshold: [1],
        initialInView: true,
    })

    const classNames = useMemo(() => makeClassName('messageNav', {
        '-isSticky': !inView,
    }), [inView])

    return (
        <div ref={ref} className={classNames}>
            <div className="wrapper messageNav__wrapper">
                <button
                    type="button"
                    className="link -primary -parentSize"
                    onClick={history.goBack}
                ><FiChevronLeft/> {t('reseller_messages.go_back')}</button>
                <div style={{display: 'flex'}}>
                    {!!loading && (
                        <ImSpinner8 className="spinner"/>
                    )}
                </div>
            </div>
        </div>
    )
}
