import {SelectController} from "../controllers";
import React, {useMemo} from "react";
import {useCountryOptions, useCurrentLanguage, useLanguages, useTranslation} from "../../hooks";
import {isEnabledByMarket} from "../../utils/MarketConfig";

export default function LangSelect(props) {
    const {t} = useTranslation()
    const {languageOptions} = useCountryOptions()
    const {currentLanguage} = useCurrentLanguage()
    const defaultValue = useMemo(() => {
        if(!isEnabledByMarket('selectDefaultLang'))
            return null
        return languageOptions.find(o => o.value === currentLanguage)
    }, [currentLanguage, languageOptions])

    return (
        <SelectController
            name="lang"
            label={t('lang')}
            selectOptions={languageOptions}
            defaultValue={defaultValue}
            {...props}
        />
    )
}
