import {useQuery} from "@tanstack/react-query";
import {useEffect, useRef, useState} from "react";
import {queryParams} from "../../misc/Helpers";

export default function useAsyncSelect({
    name,
    paramFunc,
    action,
    mapFunc,
    timeout = 500,
    refetchOnChangeParams = [],
    queryOptions,
    mapDataFunc,
    deps = []
}) {
    const inputValueTimeout = useRef(null)
    const [inputValue, setInputValue] = useState('')
    const [inputValueParam, setInputValueParam] = useState(inputValue)
    const query = useQuery(
        [name, inputValueParam, ...deps],
        () => {
            if(typeof paramFunc === "function")
                return action(queryParams(paramFunc(inputValueParam)))
            return action()
        },
        {
            keepPreviousData: true,
            staleTime: 2000,
            ...queryOptions,
        }
    )

    useEffect(() => {
        if(!timeout)
            return
        if(inputValue) {
            clearTimeout(inputValueTimeout.current)
            inputValueTimeout.current = setTimeout(() => {
                setInputValueParam(inputValue)
            }, timeout)
        } else {
            setInputValueParam('')
            clearTimeout(inputValueTimeout.current)
        }
    }, [inputValue])

    useEffect(() => {
        if(!timeout || !refetchOnChangeParams.length)
            return
        // if(refetchOnChangeParams[0]) {
            clearTimeout(inputValueTimeout.current)
            inputValueTimeout.current = setTimeout(() => {
                query.refetch()
                // setInputValueParam(inputValue)
            }, timeout)
        // } else {
        //     setInputValueParam('')
        //     clearTimeout(inputValueTimeout.current)
        // }
    }, [...refetchOnChangeParams])

    const mapData = (data) => {
        if(!data)
            return null
        if(typeof data === 'object') {
            data = Object.values(data)
        }

        if(typeof mapDataFunc === 'function')
            return mapDataFunc(data).map(i => mapFunc(i))

        return data.map(i => mapFunc(i))
    }



    const onInputChange = (value) => {
        setInputValue(value)
        if(!timeout) {
            setInputValueParam(value)
        }
    }

    return {
        query,
        data: query.isSuccess ? mapData(query.data?.data?.data) : [],
        inputValue,
        onInputChange,
    }
}
