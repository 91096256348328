import {useReseller} from "../../../hooks";
import {DashboardOptions} from "../components";

export default function DashboardScreen(){
    return (
        <div className="wrapper">
            <div className="resHome">
            <DashboardOptions/>
            </div>
        </div>
    )
}