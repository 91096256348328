import {TextInputController} from "../../../forms/controllers";
import {useFormHelper, useModuleContext, useTranslation} from "../../../hooks";
import {useMutation} from "@tanstack/react-query";
import * as shopActions from '../../../actions/ShopActions';
import {ApiException} from "../../../errors";
import {useWatch} from "react-hook-form";
import React from "react";

export default function PosInput(){
    const {t} = useTranslation()
    const {formMethods} = useFormHelper()
    const {reducer, dispatch, store} = useModuleContext()


    const posId = useWatch({control: formMethods.control, name: 'posId'})

    const shopMutation = useMutation(async (posId) => {
        const {data} = await shopActions.getByPosId(posId)

        if (data.status === 'success')
            return data.data

        throw new ApiException(data.messages[0], data)
    }, {
        onMutate: () => formMethods.clearErrors('serialNumber'),
        onSuccess: (data) => dispatch(reducer.setShop(data)),
        onError: ({error}) => {
            formMethods.setError('posId', {type: 'invalid', message: t(`validation.${error}`)})
            if(error === 'shop_not_exist')
                dispatch(reducer.setShop(null))
        }

    })
    const handleBlur = (e) => {
        const value = e.target.value
        if(value) shopMutation.mutate(value)
    }

    return (
        <>
                <TextInputController
                    name="posId"
                    label={t('tps.pos_id')}
                    options={{
                        inputProps: {
                            onBlur: handleBlur,
                        }
                    }}
                />
            {(!!store.shop && store.shop?.shop_pos === posId) && (
                <p className="par-2 --mb2" style={{paddingLeft: '0.75rem', paddingTop: '.5rem'}}>
                    <strong>{store.shop.shop_group_title} - {store.shop.shop_title}</strong> <br/>
                    {store.shop.shop_address} <br/>
                    {store.shop.shop_postcode} {store.shop.shop_city}
                </p>
            )}
        </>
    )
}