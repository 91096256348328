import {BiMessageDetail} from "react-icons/bi";
import {useNavigation} from "../../../misc/Hooks";
import Routes from "../../../utils/Routes";
import {useQuery} from "@tanstack/react-query";
import {useResellerActions} from "../../../actions/useResellerActions";
import {Link, useLocation} from "react-router-dom";

export default function MessageAlert({...props}) {
    const navigation = useNavigation()
    const actions = useResellerActions()
    const location = useLocation()
    const {isSuccess, data} = useQuery(['reseller.unreadMessages'], actions.fetchUnreadMessages)

    return (
        <div className="messageAlert">
            <Link to={navigation(Routes.V2_RESELLER_MESSAGES)}>
                <BiMessageDetail/>
                {isSuccess && (
                    <span className="messageAlert__alert">{data?.data?.data?.unread_messages_count ?? '-'}</span>
                )}
            </Link>
        </div>
    )
}
