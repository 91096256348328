import {useSeoPage} from "../../../hooks";
import {CustomerInformationStepScreen} from "../screens";

export default function CustomerInformationStepContainer(props) {
    useSeoPage({title: 'shop_customer_information.title'})

    return (
      <CustomerInformationStepScreen/>
    )
}
