import {Button} from "../../../../components/buttons/_index";
import Routes from "../../../../utils/Routes";
import {useNavigation} from "../../../../misc/Hooks";
import {getDate, shortText} from "../../../../misc/Helpers";
import {useTranslation} from "../../../../hooks";
import {stripHtmlTags} from "../../../../helpers";
import {PriorityIcon} from "./index";


export default function MessageListItem({item, currentMessage, ...props}) {
    const {t} = useTranslation()
    const navigation = useNavigation()

    return (
        <div className={`messageItem ${!item.last_message?.is_read ? '-new' : ''} ${!!item.important_messages_count && '-priority-high'}`}>
            <h3
                className="messageItem__title par-7"
                dangerouslySetInnerHTML={{__html: item.thread_title}}
            />
            <p
                className="messageItem__text par-2"
                dangerouslySetInnerHTML={{__html: shortText(stripHtmlTags(item.last_message?.thread_message_text)).text}}
            />

            <div className="messageItem__footer">
                <div className="messageItem__date par-2">{getDate(item.last_message?.updated_at)} - {item.last_message?.bo_author || item.last_message?.reseller_author}</div>
                {(item.id_thread !== currentMessage?.id_thread) && (
                  <>
                      <div className="messageItem__nav -nav">
                          <Button
                              label={t('message_item.show')}
                              size="sm"
                              href={navigation(Routes.V2_RESELLER_MESSAGE, item.id_thread)}
                          />
                      </div>
                      <div className="messageItem__nav -preview">
                          <Button
                              label={t('message_item.show_preview')}
                              size="sm"
                              href={`${navigation(Routes.V2_RESELLER_MESSAGES)}?id=${item.id_thread}`}
                          />
                      </div>
                  </>
                )}
            </div>

            <div className="messageItem__infoIcons">
                {!!item.important_messages_count && (
                    <PriorityIcon priority="high"/>
                )}
                {!!item.messages_count && (
                    <div className="messageItem__infoIcon -primary">{item.messages_count}</div>
                )}
            </div>
        </div>
    )
}
