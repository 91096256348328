import React, {useEffect} from "react";
import {Routes} from "../../../utils/_index";
import {useModuleContext, useSeoPage} from "../../../hooks";
import {OrderSummary} from "../../../components/Summary";
import * as orderActions from "../../../actions/OrderActions";

export default function SummaryScreen() {
    useSeoPage({title: 'finish_order_summary.title'})
    const {store} = useModuleContext()

    const payloadFn = ({orderData, paymentMethod, deliveryMethod, pickupPoint}) => {
        return {
            customer: {
                filename_machine: null,
                ...orderData.getData(),
                ...orderData.getPaymentMethodData(paymentMethod),
                ...orderData.getDeliveryMethodData(deliveryMethod, pickupPoint),
            },
            cart: orderData.getCart(),
            ...orderData.getCartQuotationData(),
        }
    }

    const getPaymentMethods = () => {
        if (!store.finishOrder)
            return null
        return [
            {
                id_payment_method: store.finishOrder.id_payment_method,
                payment_method_integration_type: store.finishOrder.payment_method_integration_type,
                payment_method_requires_one_time_token: store.finishOrder.payment_method_requires_one_time_token,
                payment_method_text: store.finishOrder.payment_method_text,
                payment_method_title: store.finishOrder.payment_method_title,
            }
        ]
    }

    const getDeliveryMethods = () => {
        if (!store.finishOrder?.delivery_method)
            return null
        return [store.finishOrder.delivery_method]
    }

    return (
        <OrderSummary
            homeRoute={Routes.ORDER_FINISH_AUTHORIZATION}
            orderFinalizedRoute={Routes.ORDER_FINISH_ORDER_FINALIZED}
            action={(payload) => orderActions.updateOrder(store.finishOrderToken, payload)}
            payloadFn={payloadFn}
            paymentMethods={getPaymentMethods()}
            deliveryMethods={getDeliveryMethods()}
            previewMode
        />
    )
}
