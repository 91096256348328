import {CommentItem, MessageListItem, MessagePreview} from "./index";
import {Fragment, useEffect, useMemo} from "react";
import {useLocation} from "react-router-dom";
import {useMessages} from "../hooks";
import {Preloader} from "../../../../misc/_index";


export default function MessageList({...props}) {
    const location = useLocation()
    const messages = useMessages()
    const message = useMemo(() => {
        const id = new URLSearchParams(location.search).get('id')
        const data = messages.getAllData()
        if(!id)
            return data[0]
        return data.find(i => i.id_thread === Number(id))
    }, [location.search, messages.data])

    return (
        <div className="messageList">
            <div className="messageList__items">
                {messages.query.isLoading ? (
                    <Preloader/>
                ) : messages.query.isError ? (
                    <span>Error: {messages.query.error.message}</span>
                ) : (
                    <>
                        {messages.data.pages.map(page => (
                            <Fragment key={page.current_page}>
                                {page.data.map(item => (
                                    <MessageListItem
                                        key={item.id_thread}
                                        item={item}
                                        currentMessage={message}
                                    />
                                ))}
                            </Fragment>
                        ))}
                    </>
                )}
                {messages.query.isFetchingNextPage && <Preloader/>}
                <div ref={messages.loadingHandler.ref} style={{height: 10}}/>
            </div>
            {!!message && (
                <div className="messageList__preview">
                    <MessagePreview item={message}/>
                </div>
            )}
        </div>
    )
}
