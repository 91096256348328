import React from "react";
import {getMarketImage} from "../../../utils/MarketConfig";
import {useResellerRegistration} from "../hooks";
import {useCountryImages, useTranslation} from "../../../hooks";
import {ResellerRegistrationForm} from "../../../forms";
import {Preloader} from "../../../misc/_index";
import {InfoComponent} from "../../../components/_index";
import {COUNTRY_IMAGE_TYPE} from "../../../hooks/useCountryImages";
import {CountryAuthImage} from "../components";

export default function ResellerRegistrationScreen() {

    const resellerRegistration = useResellerRegistration();
    const {t} = useTranslation();

    const handleSubmit = (data) => {
        return resellerRegistration.registryMutation.mutateAsync(data)
    }

    return (
        <div className="resAuth">
            <div className="resAuth__content">
                <h1 className="header-1">{t('reseller_registration.reseller_auth.title') || t('reseller_auth.title')}</h1>
                <h2 className="par-2 --mb3">{t('reseller_registration.register_new_reseller')}</h2>

                {resellerRegistration.byToken && resellerRegistration.shopsByTokenQuery.isLoading ? <Preloader/> : (
                        <ResellerRegistrationForm
                            shopFromToken={resellerRegistration.shopsByTokenQuery?.data?.data}
                            onSubmit={handleSubmit}
                            state={{
                                isLoading: resellerRegistration.isLoading,
                                error: resellerRegistration.formErrors
                            }}
                        />
                    )
                }
                <InfoComponent
                    type={resellerRegistration.message?.type}
                    message={t(resellerRegistration.message?.message)}
                />
            </div>
            <div className="resAuth__thumb">
                <CountryAuthImage/>
            </div>
        </div>
    )
}