import './style.scss'
import {Button} from "../../../../../components/buttons/_index";
import {useTranslation} from "../../../../../hooks";
import React, {useEffect, useState} from "react";
import BaseModal from "../../../../../components/modals/BaseModal";
import {isoDateConverterWithTime} from "../../../../../misc/Helpers";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import {Preloader} from "../../../../../misc/_index";
import {useMutation} from "@tanstack/react-query";
import {useResellerActions} from "../../../../../actions/useResellerActions";
import {ApiException} from "../../../../../errors";
import {InfoComponent} from "../../../../../components/_index";

export default function LocalStockHistory({item}) {
    const {t} = useTranslation()

    const {fetchStockRequestLog} = useResellerActions()
    const [modalVisible, setModalVisible] = useState(false)
    const [history, setHistory] = useState([])

    const mutation = useMutation(
        async () => {
            const {data} = await fetchStockRequestLog(item.id_local_stock_request)
            if (data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        {
            onMutate: () => {
                setHistory([])
            },
            onSuccess: (data) => {
                setHistory(data)
            }
        }
    )

    const handleOnClick = () => {
        mutation.mutate()
        setModalVisible(true)
    }

    return (
        <div className="localStockHistory">
            <Button
                label={t('stock_management.history')}
                size="md"
                onClick={handleOnClick}
            />
            <BaseModal
                onClose={() => setModalVisible(false)}
                visible={modalVisible}
            >
                <div className="localStockHistoryModal">
                    <h3 className="localStockHistoryModal__title">
                        {t('stock_management.request_log_details')}
                    </h3>
                    <div className="localStockHistoryModal__tableWrapper">
                        <table className="localStockHistoryModal__table">
                            <thead>
                            <tr>
                                <th>{t('stock_management.product_name')}</th>
                                <th>{t('stock_management.created_at')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{item.product_title}</td>
                                <td>{isoDateConverterWithTime(new Date(item.created_at), '-', ':')}</td>

                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <h3 className="localStockHistoryModal__title">
                        {t('stock_management.request_log')}
                    </h3>
                    {mutation.isLoading ? (
                        <Preloader/>
                    ) : (
                       <>
                           {!!history.length ? (
                               <div className="localStockHistoryModal__tableWrapper">
                                   <table className="localStockHistoryModal__table">
                                       <thead>
                                       <tr>
                                           <th>{t('stock_management.reseller_id')}</th>
                                           <th>{t('stock_management.reseller_name')}</th>
                                           <th>{t('stock_management.created_at')}</th>
                                       </tr>
                                       </thead>
                                       <tbody>
                                       {history.map((h, idx) => (
                                           <tr key={idx}>
                                               <td>{h.id_reseller}</td>
                                               <td>{h.reseller_fullname}</td>
                                               <td>{isoDateConverterWithTime(new Date(h.created_at), '-', ':')}</td>
                                           </tr>
                                       ))}
                                       </tbody>
                                   </table>
                               </div>
                           ) : (
                               <InfoComponent type="warn" message={t('stock_management.request_log_not_found')}/>
                           )}
                       </>
                    )}

                </div>
            </BaseModal>
        </div>
    )
}