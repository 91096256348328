import {useMemo, useState} from "react";
import * as authorizationActions from "../actions/AuthorizationActions";
import {useMutation} from "@tanstack/react-query";
import {ApiException} from "../errors";
import {nespressoMemberFromLoginData} from "../services/NespressoMemberService";
import {useGTM, useModuleContext, useTranslation} from "./index";
import {useDispatch} from "react-redux";
import {reducers} from "../misc/Hooks";

export default function useNespressoMember() {
    const {t} = useTranslation()
    const {storeName, store} = useModuleContext()
    const reducer = useMemo(() => reducers[storeName], [storeName])
    const dispatch = useDispatch()
    const {nespressoMember, isNewMember} = store
    const {userLoggedIn} = useGTM()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [loginAttempts, setLoginAttempts] = useState(0)

    const loginMutation = useMutation(
        async (payload) => {
            const {data} = await authorizationActions.signIn(payload)

            if (data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        {
            onMutate: () => {
                setError(null)
            },
            onError: ({error}) => {
                setLoginAttempts(loginAttempts + 1)
                if (error) {
                    setError(t(`validation.${error}`) || t(`error.${error}`) || t(`error.unknown_error`))
                } else {
                    setError(t(`error.unknown_error`))
                }
            }
        }
    )

    /**
     * @param data {{login: string, password: string, recaptchaToken: string}} Credentials
     * @returns object
     */
    const login = async (data) => {
        setLoading(true)
        setError(null)
        const abortController = new AbortController()
        const response = await authorizationActions.signIn(data, null, {signal: abortController.signal})
            .then(res => res.data)
            .then(res => {
                if (res.status === 'success') {
                    return res.data
                }
                if (res.messages?.length) {
                    setError(res.messages[0])
                    return null
                } else {
                    setError('unknown_error')
                }
            })
        abortController.abort()
        setLoading(false)
        return response
    }

    function storeLoggedUser(data, payload) {
        setLoginAttempts(0)
        dispatch(reducer.setNespressoMember(nespressoMemberFromLoginData(data, {email: payload.login})))

        userLoggedIn(data.member_id)
    }

    return {
        loading,
        error,
        setError,
        login,
        loginMutation,
        nespressoMember,
        isNewMember,
        loginAttempts,
        storeLoggedUser,
    }
}

