import React from "react";
import {DataCell} from "./index";
import {useTranslation} from "../../../hooks";
import getObjectValue from "../../../helpers/getObjectValue";

export default function AddressComponent({data}) {
    const {t} = useTranslation()

    return !!data && (
        <div>
            {data.isCompanyAddress ? (
                <>
                    <DataCell label={t('company_name')} value={data.companyName}/>
                    <DataCell label={t('tax_id')} value={data.companyTaxId}/>
                    <DataCell label={t('company_profession') || t('profession')} value={data.companyProfession?.label}/>
                </>
            ) : (
                <>
                    <DataCell label={t('title')} value={data.title?.label}/>
                    <DataCell label={t('profession')} value={data.profession?.label}/>
                </>
            )}
            <div className="info__row">
                <DataCell label={t('name')} value={data.firstname}/>
                <DataCell label={t('surname')} value={data.lastname}/>
            </div>
            <DataCell label={t('phonetic_name')} value={data.phoneticName}/>
            <div className="info__row">
                <DataCell label={t('postcode')} value={getObjectValue(data.postcode, 'label')}/>
                <DataCell label={t('city')} value={getObjectValue(data.city, 'label')}/>
            </div>
            <DataCell label={t('address_line_1')} value={data.addressLine1}/>
            <DataCell label={t('address_line_2')} value={data.addressLine2}/>
            <DataCell label={t('region')} value={data.region?.label}/>
            <DataCell label={t('phone')} value={data.phone}/>
            <DataCell label={t('phone_mobile')} value={data.phoneMobile}/>
            <DataCell label={t('phone_home')} value={data.phoneHome}/>
            <DataCell label={t('email')} value={data.email}/>
        </div>
    )
}
