import {useLocalizeObject} from "../../../misc/Hooks";
import {useFormHelper, useModuleContext} from "../../../hooks";
import {useMemo} from "react";
import {useFormBuilder} from "../../../forms/hooks";
import {RadioController} from "../../../forms/controllers";
import {FormBuilderComponent} from "../../../forms/components";

export default function PaymentFormFragment(){
    const localizeObject = useLocalizeObject()
    const {store} = useModuleContext()
    const {getRules} = useFormHelper()

    const options = useMemo(() => {
        return store.promotion?.payment_methods?.map(p => ({
            label: localizeObject(p.payment_method_title),
            value: p.id_payment_method,
        }))
    }, [store.promotion?.payment_methods])

    const {components} = useFormBuilder({
        formConfig: [
            ['paymentMethods'],
        ],
        inputVisibility: {},
        inputComponents: {
            'paymentMethods': (
                <RadioController
                    name="paymentMethod"
                    radioOptions={options}
                    rules={getRules('paymentMethod')}
                />
            ),
        },
    })

    return (
        <FormBuilderComponent components={components}/>
    )
}