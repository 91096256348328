import SummaryScreen from "../screens/SummaryScreen";
import React from "react";
import {useSeoPage} from "../../../hooks";

export default function SummaryContainer() {
    useSeoPage({title: 'stay_home.summary.title'})

    return (
        <SummaryScreen/>
    )
}
