import React, {useState} from "react";
import PromoItem from "../PromoItem";
import {Button} from "../buttons/_index";
import {usePlan, useTranslation} from "../../hooks";
import {usePromotionsContext} from "../../hooks/useFetchPromotions";
import PromoModal from "../PromoModal";
import {Preloader} from "../../misc/_index";
import {useCategoriesContext} from "../../hooks/useFetchCategories";
import {usePlansContext} from "../../hooks/useFetchPlans";
import MachinePlanItem from "../MachinePlanItem";


export default function PlanSelection() {
    const {query, plans} = usePlansContext()
    const {t} = useTranslation();

    return (
        <div className="evPS">
            {(query.isLoading || (query.isFetching && !plans.length)) ? (
                <Preloader message={t('fetching_plans') || t('fetching_promotions')}/>
            ) : (
                <>
                    {query.isSuccess && (
                        <>
                            {plans.length ? (
                                <>
                                    {!!t('shop_offer_selection.plan_selection_title') && (
                                        <div className="evPS__header">
                                            <p className="par-7 --bold">{t('shop_offer_selection.plan_selection_title')}</p>
                                        </div>
                                    )}

                                    <div className="evPS__items">
                                        {plans.map(item => (
                                            <MachinePlanItem
                                                key={item.id_product}
                                                item={item}
                                            />
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <div className="--mb4">
                                    <p className="par-5 --bold"
                                       dangerouslySetInnerHTML={{__html: t('no_plans')}}/>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}


            {query.isError && !query.isFetching && (
                <div>
                    <p
                        className="par-5 --bold"
                        dangerouslySetInnerHTML={{__html: t(`error.${query.error?.error}`) || t('error.unknown_error')}}
                    />
                </div>
            )}

            <PromoModal
                popupType="default"
            />
        </div>
    )
}
