

const SET_PROMOTIONS = 'tps/setPromotions';
const SET_CATEGORIES = 'tps/setCategories';
const SET_COUNTRIES = 'tps/setCountries';
const SET_MACHINES = 'tps/setMachines';
const SET_MACHINE_MODELS = 'tps/setMachineModels';
const SET_MACHINE_COLORS = 'tps/setMachineColors';
const SET_MACHINE_PARTNERS = 'tps/setMachinePartners';
const SET_COMPANY_TITLES = 'tps/setCompanyTitles';

const SET_SHOP = 'tps/setShop';
const SET_COUNTRY = 'tps/setCountry';
const SET_DATA = 'tps/setData';
const SET_RECEIPT_DATA = 'tps/setReceiptData';
const SET_MACHINE = 'tps/setMachine';
const SET_MACHINE_DATA = 'tps/setMachineData';
const SET_PROMOTION = 'tps/setPromotion';
const SET_ONLY_PROMOTION = 'tps/setOnlyPromotion';
const SET_PAYMENT_METHOD = 'tps/setPaymentMethod';

const UPDATE_CART = 'tps/updateCart';

const SET_STACKS = 'tps/setStacks';
const CLEAR_ORDER = 'tps/clearOrder';
const CLEAR_STORE = 'tps/clearStore';


const initialState = {
    promotions: [],
    categories: [],
    countries: [],
    machines: [],
    machineModels: [],
    machineColors: [],
    machinePartners: [],
    companyTitles: [],
    cart: [],
    shop: null,
    country: null,
    promotion: null,
    paymentMethod: null,

    data: null,
    receiptData: null,
    machine: null,
    machineData: null,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_PROMOTIONS:
            return {...state, promotions: action.promotions}
        case SET_CATEGORIES:
            return {...state, categories: action.categories}
        case SET_COUNTRIES:
            return {...state, countries: action.countries}
        case SET_MACHINES:
            return {...state, machines: action.machines}
        case SET_MACHINE_MODELS:
            return {...state, machineModels: action.machineModels}
        case SET_MACHINE_COLORS:
            return {...state, machineColors: action.machineColors}
        case SET_MACHINE_PARTNERS:
            return {...state, machinePartners: action.machinePartners}
        case SET_COMPANY_TITLES:
            return {...state, companyTitles: action.companyTitles}

        case UPDATE_CART:
            return {...state, cart: action.cart}

        case SET_SHOP:
            return {...state, shop: action.shop}
        case SET_COUNTRY:
            return {...state, country: action.country}
        case SET_PROMOTION:
            return {...state, promotion: action.promotion}
        case SET_ONLY_PROMOTION:
            return {...state, promotion: action.promotion}
        case SET_PAYMENT_METHOD:
            return {...state, paymentMethod: action.paymentMethod}

        case SET_DATA:
            return {...state, data: action.data}

        case SET_RECEIPT_DATA:
            return {...state, receiptData: action.receiptData}
        case SET_MACHINE:
            return {...state, machine: action.machine}
        case SET_MACHINE_DATA:
            return {...state, machineData: action.machineData}

        case SET_STACKS:
            return {
                ...state,
                machineModels: action.machineModels,
                machineColors: action.machineColors,
                machinePartners: action.machinePartners,
                companyTitles: action.companyTitles,
            }
        case CLEAR_ORDER:
            return {
                ...initialState,
                countries: state.countries,
                country: state.country,
            }
        case CLEAR_STORE:
            return {...initialState}

        default: return state;

    }
}


export const setPromotions = promotions => ({type: SET_PROMOTIONS, promotions});
export const setCategories = categories => ({type: SET_CATEGORIES, categories});
export const setCountries = countries => ({type: SET_COUNTRIES, countries});
export const setMachines = machines => ({type: SET_MACHINES, machines});
export const setMachineModels = machineModels => ({type: SET_MACHINE_MODELS, machineModels});
export const setMachineColors = machineColors => ({type: SET_MACHINE_COLORS, machineColors});
export const setMachinePartners = machinePartners => ({type: SET_MACHINE_PARTNERS, machinePartners});
export const setCompanyTitles = companyTitles => ({type: SET_COMPANY_TITLES, companyTitles});
export const updateCart = cart => ({type: UPDATE_CART, cart});
export const setShop = shop => ({type: SET_SHOP, shop});
export const setCountry = country => ({type: SET_COUNTRY, country});
export const setData = data => ({type: SET_DATA, data});
export const setReceiptData = receiptData => ({type: SET_RECEIPT_DATA, receiptData});
export const setMachine = machine => ({type: SET_MACHINE, machine});
export const setMachineData = machineData => ({type: SET_MACHINE_DATA, machineData});
export const setPromotion = promotion => ({type: SET_PROMOTION, promotion});
export const setOnlyPromotion = (promotion) => ({type: SET_ONLY_PROMOTION, promotion})
export const setPaymentMethod = paymentMethod => ({type: SET_PAYMENT_METHOD, paymentMethod});


export const setStacks = (machineModels, machineColors, machinePartners, companyTitles) => ({
    type: SET_STACKS,
    machineModels,
    machineColors,
    machinePartners,
    companyTitles,
})
export const clearStore = () => ({type: CLEAR_STORE});
export const clearOrder = () => ({type: CLEAR_ORDER});



export default reducer;
