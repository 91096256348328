import React, {useState} from 'react';
import {Button} from "./buttons/_index";
import {PlanRmsItemModal} from "./modals/_index";
import {useCart, usePriceFormatter, useTranslation} from "../misc/Hooks";
import {getImage, shortText} from "../misc/Helpers";
import {Config} from "../utils/_index";
import {isEnabledByMarket} from "../utils/MarketConfig";

export default function MachinePlanItem(props) {
    const [modalVisible, setModalVisible] = useState(false);
    const [description, setDescription] = useState(shortText(props.item?.product_plan_description1))
    const {formatPriceObj, formatPrice} = usePriceFormatter();
    const {updateItem, clearCartAndAddItem, isItemLocked, data: cart} = useCart('subscription');
    const t = useTranslation();

    const toggleDescription = () => {
        if(props.item?.product_plan_description1){
            if(description.hasMore) {
                setDescription({
                    text: props.item.product_plan_description1,
                    hasMore: false,
                })
            } else {
                setDescription(shortText(props.item.product_plan_description1))
            }
        }
    }

    const itemInCart = cart.items.find(i => i.id === props.item.id_product);
    const isLocked = isItemLocked(props.item.id_product);

    const coverImage = getImage(props.item.product_gallery);


    return (

    <div className="promoItem">
            <div className="promoItem__header">
                {!!coverImage && (
                    <div className="promoItem__thumb">
                        <img src={coverImage} alt={props.item.product_title}/>
                    </div>
                )}
                <h3 className="promoItem__title">{props.item.product_title}</h3>

                {isEnabledByMarket('machineSubscriptionPlanBonus') ? (
                    <p
                        className="promoItem__text"
                        style={{cursor: description.hasMore ? 'pointer' : 'normal'}}
                        dangerouslySetInnerHTML={{__html: description.text}}
                        onClick={toggleDescription}
                    />
                ) : (
                    <>
                        {!!props.item.product_plan_description1 && (
                            <p
                                className="promoItem__text"
                                dangerouslySetInnerHTML={{__html: props.item.product_plan_description1}}
                            />
                        )}
                    </>
                )}
            </div>



        {isEnabledByMarket('machineSubscriptionPlanBonus') ? (
            <div className="promoItem__body">
                <div className="gearItem__price">
                    <h5 className="gearItem__priceValue">{formatPriceObj(props.item.product_plan_price, 0)}</h5>
                    <h5 className="gearItem__priceLabel">/ {t('stay_home.gear.price_label')}</h5>
                    {props.item.product_type === Config.COFFEE_TYPE.PLAN_COFFEE && (
                        <h4 className="promoItem__period">{t('plan_price_label')}</h4>
                    )}
                </div>

                <div className="gearItem__addon">
                    {t('stay_home.gear.addon', formatPrice(Number(props.item.product_plan_machine_price) - Number(props.item.product_plan_machine_refund)))}
                </div>

                {!isEnabledByMarket('hideSubscriptionPlanItemOriginalMachinePrice') ? (
                    <div className="gearItem__inStoresFrom">
                        {t('stay_home.gear.in_stores_from', formatPrice(props.item.product_plan_machine_price))}
                    </div>
                ) : (<div className="--mb2"/>)}

                <button
                    className="link -primary"
                    onClick={() => setModalVisible(true)}
                >{t('read_more')}</button>
            </div>
        ) : (
            <div className="promoItem__body">
                <div className="promoItem__line --mb2">
                    {!!props.item.product_plan_price && (
                        <>
                            <h4 className="promoItem__price">{formatPriceObj(props.item.product_plan_price, 0)}</h4>
                            {props.item.product_type === Config.COFFEE_TYPE.PLAN_COFFEE && (
                                <h4 className="promoItem__period">{t('plan_price_label')}</h4>
                            )}
                        </>
                    )}
                </div>

                <button
                    className="link -primary"
                    onClick={() => setModalVisible(true)}
                >{t('read_more')}</button>
            </div>
        )}


            <div className="promoItem__content -center primaryUl">
                <div
                    dangerouslySetInnerHTML={{__html: props.item.product_plan_description2 ? props.item.product_plan_description2 : ''}}
                />
            </div>


            <div className="promoItem__footer">
                <div className="coffeeItem__button">
                    <Button
                        type="primary"
                        size="sm"
                        label={t(!itemInCart ? 'buy_now' : 'remove_from_cart')}
                        onClick={() => clearCartAndAddItem(props.item.id_product, !itemInCart ? props.item.product_step : 0)}
                        disabled={isLocked && !itemInCart}
                    />

                </div>
            </div>


            {!!modalVisible && (
                <PlanRmsItemModal
                    visible={modalVisible}
                    item={props.item}
                    onClose={() => setModalVisible(false)}
                />
            )}
        </div>
    )
}
