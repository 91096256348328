import {ExperienceItem} from "./index";


export default function ExperiencesList({data, selectedItem, onChange}) {
    return (
        <div className="experiencesList">
            {data.map((item, idx) => (
                <ExperienceItem
                    key={idx}
                    item={item}
                    selectedItem={selectedItem}
                    onClick={onChange}
                />
            ))}
        </div>
    )
}
