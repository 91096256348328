import {MaskInputController, TextInputController} from "../controllers";
import React from "react";
import {useFieldFormat, useTranslation} from "../../hooks";

export default function CompanyTaxIdInput(props) {
    const {t} = useTranslation()
    const {formats} = useFieldFormat()

    return formats.companyTaxId.isEnabled && (
        <MaskInputController
            name="companyTaxId"
            mask={formats.companyTaxId.getMask()}
            label={t('company_tax_id') || t('tax_id')}
            {...props}
        />
    )
}
