import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import {reducers} from "../../misc/Hooks";
import {useOptIns, useOrderData} from "../../hooks";

const MODAL_TYPE = {
    DEFAULT: 'default',
    OPTIN: 'optin'
}

const STORE_NAME_PLANS = [
    'subscription',
    'eTrade',
    'eTradeReopen'
]

export default function usePromoModal(storeName = 'shop', popupType = MODAL_TYPE.DEFAULT) {
    const store = useSelector(state => ({
        promotion: state[storeName]?.promotion,
        openModals: STORE_NAME_PLANS.includes(storeName) || state[storeName]?.products?.find(p => {
            const item = state[storeName].cart?.items?.find(i => i.id === p.id_product);
            return item && p.product_type.startsWith('plan_') && storeName !== 'shop'
        }) ? state[storeName]?.openPlanModals : state[storeName]?.openPromotionModals,
        nespressoMember: state[storeName]?.nespressoMember,
        customer: state[storeName]?.customer,
        plan: state[storeName]?.products?.find(p => {
            const item = state[storeName].cart?.items?.find(i => i.id === p.id_product);
            return item && p.product_type.startsWith('plan_') && storeName !== 'shop'
        }),
    }))
    const optIns = useOptIns(storeName)
    const reducer = useMemo(() => reducers[storeName], [storeName])

    const orderData = useOrderData()
    const dispatch = useDispatch();

    const [modalVisible, setModalVisible] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [modalBenefits, setModalBenefits] = useState([])
    const [modalCurrentBenefits, setModalCurrentBenefits] = useState(null)

    useEffect(() => {
        if (store.plan || STORE_NAME_PLANS.includes(storeName)) {
            dispatch(reducer.setOpenPlanModals([]))
        }
        if ((store.promotion?.promotion_popups?.length > 0 || store.plan?.popups?.length > 0) && popupType === MODAL_TYPE.DEFAULT) {
            openDefaultModal();
        }
    }, [store.promotion, store.plan])

    useEffect(() => {
        if (popupType === MODAL_TYPE.OPTIN) {
            openOptInModal()
        }
    }, [])

    const openDefaultModal = () => {
        const popups = store.plan || STORE_NAME_PLANS.includes(storeName)
            ? store.plan.popups.filter(popup => popup.product_popup_type === popupType)
            : store.promotion.promotion_popups.filter(popup => popup.promotion_popup_type === popupType)

        if (!popups.length > 0)
            return null;

        popups.map(popup => {
            const openModals = [...store.openModals]
            const popupItem = {}
            Object.keys(popup).map(k => {
                popupItem[k.replace(/product/, 'promotion')] = popup[k]
            })

            if (!openModals.includes(popupItem.id_promotion_popup)) {
                openModals.push(popupItem.id_promotion_popup)
                if (store.plan || STORE_NAME_PLANS.includes(storeName)) {
                    dispatch(reducer.setOpenPlanModals(openModals))
                } else {
                    dispatch(reducer.setOpenPromotionModals(openModals))
                }
                setModalData(popupItem)
                setModalVisible(true)
                return null;
            }
        })
    }

    const closeModal = () => {
        setModalVisible(false)

        if (popupType === MODAL_TYPE.DEFAULT) {
            setTimeout(() => {
                openDefaultModal();
            }, 500)
        }

        if (popupType === MODAL_TYPE.OPTIN) {
            setTimeout(() => {
                openOptInModal();
            }, 500)
        }
    }

    const openOptInModal = () => {
        const popups = store.plan || STORE_NAME_PLANS.includes(storeName)
            ? store.plan?.popups?.filter(popup => popup.product_popup_type === popupType)
            : store.promotion?.promotion_popups?.filter(popup => popup.promotion_popup_type === popupType)

        if (!popups?.length > 0)
            return null;

        popups.map(popup => {
            const openModals = [...store.openModals]
            const popupItem = {}
            Object.keys(popup).map(k => {
                popupItem[k.replace(/product/, 'promotion')] = popup[k]
            })
            if (!openModals.includes(popupItem.id_promotion_popup)) {
                openModals.push(popupItem.id_promotion_popup)
                if (store.plan || STORE_NAME_PLANS.includes(storeName)) {
                    dispatch(reducer.setOpenPlanModals(openModals))
                } else {
                    dispatch(reducer.setOpenPromotionModals(openModals))
                }

                const currentBenefits = orderData.getCustomerBenefitsData();
                const notSelected = [];
                if (!currentBenefits.marketing_email) notSelected.push('email')
                if (!currentBenefits.marketing_mobile) notSelected.push('mobile')
                if (!currentBenefits.marketing_phone) notSelected.push('phone')
                if (!currentBenefits.marketing_post) notSelected.push('post')
                if (!currentBenefits.marketing_social_media) notSelected.push('socialMedia')

                const modalOptins = notSelected.filter(i => popupItem.promotion_popup_required_optins.includes(i))

                if (modalOptins.length > 0) {
                    setModalData(popupItem)
                    setModalBenefits(modalOptins)
                    setModalCurrentBenefits(currentBenefits)
                    setModalVisible(true)
                    return null;
                }
            }
        })
    }

    const onChangeBenefit = (data) => {
        dispatch(reducer.setCustomer({
            ...store.customer,
            benefits: {
                ...store.customer?.benefits,
                ...data,
            }
        }))
        optIns.update({
            ...optIns.data,
            ...data
        })

    }

    return {
        closeModal,
        onChangeBenefit,
        store,
        modalVisible,
        modalData,
        modalBenefits,
        modalCurrentBenefits
    }
}