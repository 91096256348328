import BaseModal from "../../../components/modals/BaseModal";
import React from "react";
import {useTranslation} from "../../../hooks";
import {BsXLg} from "react-icons/bs";
import Button from "../../../components/buttons/Button";

export default function ConfirmActionModal({
    visible,
    onClose,
    action,
    onConfirm,
    items,
    columns,
    setSelectedItems
}) {

    const {t} = useTranslation()

    const removeItem = (item) => {
        const arr = [...items]
        const idx = arr.findIndex(arrItem => arrItem === item)

        arr.splice(idx, 1)

        setSelectedItems(arr)
    }


    return (
        <BaseModal
            visible={visible}
            onClose={onClose}
        >
            <div className="modal__header">
                <h3 className="modal__title">{t('modal.reseller_confirm.title', action)}</h3>
            </div>

            <div className="modal__body">
                <div className="confirmTable">
                    <table>

                        <thead>
                        <tr>
                            <th>#</th>
                            {columns.map((c, idx) => (
                                <th key={idx}>{t(c.label)}</th>
                            ))}
                            <th>{t('remove')}</th>
                        </tr>
                        </thead>

                        <tbody>
                        {items.map((item, idx) => (
                            <tr key={idx}>
                                <td style={{maxWidth: `1.5rem`}}>{idx+1}.</td>
                                {columns.map(c => (
                                    <td key={`${c.name}${idx}`}>{c.cell(item)}</td>
                                ))}
                                <td>
                                    <button
                                        className="confirmTable__remove"
                                        onClick={() => removeItem(item)}
                                    >
                                        <BsXLg/>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>

                    </table>
                </div>
            </div>
            <div className="modal__footer">
               <div style={{display: "flex", justifyContent: "flex-end", columnGap: "1rem"}}>
                   <Button
                       label={t('cancel')}
                       type="secondary"
                       onClick={onClose}
                   />
                   <Button
                       label={t('take_the_action')}
                       onClick={onConfirm}
                   />
               </div>
            </div>
        </BaseModal>
    )
}