import {useHistory, useLocation} from "react-router-dom";
import {useMemo} from "react";

export default function useTableFilters({
    builder,
    mapFunc,
    searchPrefix,
    delayFields,
    config: _config,
    ...props
}) {

    const config = {...defaultConfig, ..._config}

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const history = useHistory()

    function getAllSearchParams() {
        return location.search.replace('?', '')
            .split('&')
            .filter(p => p)
            .reduce((obj, param) => {
                const [name, value] = param.split('=')
                obj[name] = value
                return obj
            }, {})
    }

    function updateSearchParams(params, config = {}) {
        config = {exceptUndefined: true, ...config}
        let payload = {...getAllSearchParams(), ...params}
        if (config.exceptUndefined) {
            payload = Object.keys(payload)
                // .filter(param => typeof payload[param] !== 'undefined')
                // .filter(param => typeof payload[param] === 'string' ? payload[param] : true)
                .reduce((obj, param) => {
                    obj[param] = payload[param]
                    return obj
                }, {})
        }

        Object.keys(payload).forEach(k => {
            searchParams.set(k, payload[k])

            if(!payload[k]) searchParams.delete(k)
        })

        history.replace({search: searchParams.toString()})

    }

    const data = useMemo(() => getAllSearchParams(), [searchParams])
    const rawData = useMemo(() => getRawParams(), [data])

    function onSubmit(data) {
        if (typeof mapFunc === 'function')
            data = mapFunc(data)


        if (searchPrefix) {
            const prefixedParams = {}
            Object.keys(data).map(k => {
                prefixedParams[getParamName(k)] = data[k]
            })
            updateSearchParams(prefixedParams)
            return
        }


        updateSearchParams(data)
    }

    function getParamName(paramName) {
        if (!searchPrefix)
            return paramName
        const key = `${paramName[0].toUpperCase()}${paramName.substring(1)}`
        const prefix = typeof searchPrefix === 'string' ? searchPrefix : ''
        return `${prefix}${key}`
    }

    function getRawParams() {
        if (searchPrefix) {
            let rawParams = {};
            Object.keys(data).forEach((d) => {
                if (d.includes(searchPrefix)) {
                    let paramName = d.replace(searchPrefix, '')
                    paramName = `${paramName[0].toLowerCase()}${paramName.slice(1)}`

                    if(isNaN(Number(data[d]))) rawParams[paramName] = decodeURIComponent(data[d].replace('+', ' '))
                    else rawParams[paramName] = Number(data[d])
                }
            })

            return rawParams
        }
        return data
    }

    return {
        data,
        builder,
        onSubmit,
        rawData,
        searchPrefix,
        delayFields,
        config
    }
}

const defaultConfig = {
    grid: 'auto-fill'
}