import {sanitize, addHook} from 'dompurify';

addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener');
    }
});

export default function sanitizeText(text){
    if(typeof text !== 'string') return text;
    return {dangerouslySetInnerHTML: {__html: sanitize(text)}}
}