import CustomerScreen from "../screens/CustomerScreen";
import {useSeoPage} from "../../../hooks";

export default function CustomerContainer() {
    useSeoPage({title: 'stay_home.customer.title'})

    return (
        <CustomerScreen/>
    )
}
