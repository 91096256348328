import React, {Fragment, useEffect, useMemo} from 'react';
import {Controller, useForm} from "react-hook-form";
import {CustomCheckbox} from "../../forms/inputs/_index";
import {
    getImage,
    handleCheckboxArrayChange,
    handleCheckboxChange,
    isCheckboxArrayChecked,
    isCheckboxChecked
} from "../../../misc/Helpers";
import Config from "../../../utils/Config";
import {Button} from "../../buttons/_index";
import {useTranslation} from "../../../misc/Hooks";
import {useSelector} from "react-redux";
import {FilterCategory} from "./index";
import {getFiltersOrder} from "../../../utils/MarketConfig";

export default function FilterForm(props) {
    const productTypes = useMemo(() => {
        const types = [
            {
                label: 'capsule_or_package',
                names: [Config.COFFEE_TYPE.CAPSULE, Config.COFFEE_TYPE.PACKAGE],
                category: null,
            },
            {
                label: 'accessory',
                names: [Config.COFFEE_TYPE.ACCESSORY],
                category: null,
            },
        ]
        return types
            .map(t => ({
                ...t,
                category: props.categories.find(c => c.category_gallery && t.names.includes(c.category_type)),
            }))
            .filter(t => t.category)
    }, [props.categories])
    const t = useTranslation();
    const {control, watch, setValue, handleSubmit} = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            types: props.filter?.types ?? [],
            line: props.filter?.line,
            intensities: props.filter?.intensities ?? [],
            sizes: props.filter?.sizes ?? [],
            aromaticProfiles: props.filter?.aromaticProfiles ?? [],
            coffeeTypes: props.filter?.coffeeTypes ?? [],
            accessoryTypes: props.filter?.accessoryTypes ?? [],
        }
    });
    const store = useSelector(({main}) => ({
        coffeeSizes: main.coffeeSizes,
        aromaticProfiles: main.aromaticProfiles,
        coffeeTypes: main.coffeeTypes,
        accessoryTypes: main.accessoryTypes,
        coffeeLines: main.coffeeLines,
    }))

    useEffect(() => {
        setValue('types', props.filter?.types ?? [])
        setValue('line', props.filter?.line);
        setValue('intensities', props.filter?.intensities ?? []);
        setValue('sizes', props.filter?.sizes ?? []);
        setValue('aromaticProfiles', props.filter?.aromaticProfiles ?? []);
        setValue('coffeeTypes', props.filter?.coffeeTypes ?? []);
        setValue('accessoryTypes', props.filter?.accessoryTypes ?? []);
    }, [props.filter])

    const optionVisible = (types) => {
        return watch('types')?.length ? !!types.find(t => watch('types').includes(t)) : true
    }

    const sortStackValues = (data, stack) => {
        return data.sort((a, b) => {
            const labelA = stack?.find(i => i.stack_value === a)?.stack_title ?? a
            const labelB = stack?.find(i => i.stack_value === b)?.stack_title ?? b
            return labelA.toString().localeCompare(labelB)
        })
    }

    const AromaticProfileComponent = () => optionVisible([Config.COFFEE_TYPE.CAPSULE, Config.COFFEE_TYPE.PACKAGE]) && !!props.aromaticProfiles?.length && (
        <div className="filterOption --mb4">
            <h3 className="filterOption__title par-2">{t('filter.aromatic_profile')}</h3>
            <Controller
                name="aromaticProfiles"
                control={control}
                render={({field, fieldState}) => (
                    <div className="filterOption__items">
                        {sortStackValues(props.aromaticProfiles, store.aromaticProfiles).map((key, idx) => (
                            <CustomCheckbox
                                key={idx}
                                value={isCheckboxChecked(field.value, key)}
                                label={store.aromaticProfiles?.find(i => i.stack_value === key)?.stack_title ?? key}
                                onChange={() => {
                                    field.onChange(handleCheckboxChange(field.value, key))
                                    handleSubmit(props.onSubmit)()
                                }}
                            />
                        ))}
                    </div>
                )}
            />
        </div>
    )

    const CoffeeLineComponent = () => props.coffeeLines?.length > 1 && (
        <div className="filterOption --mb4">
            <h3 className="filterOption__title par-2">{t('filter.line')}</h3>
            <Controller
                name="line"
                control={control}
                render={({field, fieldState}) => (
                    <div className="filterOption__items">
                        {sortStackValues(props.coffeeLines, store.coffeeLines)
                            .map(({stack_value}) => stack_value)
                            .map((key, idx) => (
                                <CustomCheckbox
                                    key={idx}
                                    value={field.value === key}
                                    label={store.coffeeLines?.find(i => i.stack_value === key)?.stack_title ?? key}
                                    onChange={v => {
                                        field.onChange(field.value === key ? null : key)
                                        handleSubmit(props.onSubmit)()
                                    }}
                                />
                            ))}
                    </div>
                )}
            />
        </div>
    )

    const CoffeeTypeComponent = () => optionVisible([Config.COFFEE_TYPE.CAPSULE, Config.COFFEE_TYPE.PACKAGE]) && !!props.coffeeTypes?.length && (
        <div className="filterOption --mb4">
            <h3 className="filterOption__title par-2">{t('filter.coffee_type')}</h3>
            <Controller
                name="coffeeTypes"
                control={control}
                render={({field, fieldState}) => (
                    <div className="filterOption__items">
                        {sortStackValues(props.coffeeTypes, store.coffeeTypes).map((key, idx) => (
                            <CustomCheckbox
                                key={idx}
                                value={isCheckboxChecked(field.value, key)}
                                label={store.coffeeTypes?.find(i => i.stack_value === key)?.stack_title ?? key}
                                onChange={() => {
                                    field.onChange(handleCheckboxChange(field.value, key))
                                    handleSubmit(props.onSubmit)()
                                }}
                            />
                        ))}
                    </div>
                )}
            />
        </div>
    )

    const IntensityComponent = () => optionVisible([Config.COFFEE_TYPE.CAPSULE, Config.COFFEE_TYPE.PACKAGE]) && (
        <div className="filterOption --mb4">
            <h3 className="filterOption__title par-2">{t('filter.intensity')}</h3>
            <Controller
                name="intensities"
                control={control}
                render={({field, fieldState}) => (
                    <div className="filterOption__items">
                        {Config.FILTER.INTENSITY.map((key, idx) => (
                            <CustomCheckbox
                                key={idx}
                                value={isCheckboxChecked(field.value, key)}
                                label={t(`filter.intensities.${key}`) || key}
                                onChange={() => {
                                    field.onChange(handleCheckboxChange(field.value, key))
                                    handleSubmit(props.onSubmit)()
                                }}
                            />
                        ))}
                    </div>
                )}
            />
        </div>
    )

    const CupSizesComponent = () => optionVisible([Config.COFFEE_TYPE.CAPSULE, Config.COFFEE_TYPE.PACKAGE]) && !!props.cupSizes?.length && (
        <div className="filterOption --mb4">
            <h3 className="filterOption__title par-2">{t('filter.size')}</h3>
            <Controller
                name="sizes"
                control={control}
                render={({field, fieldState}) => (
                    <div className="filterOption__items">
                        {props.cupSizes.map((key, idx) => (
                            <CustomCheckbox
                                key={idx}
                                value={isCheckboxChecked(field.value, key)}
                                label={store.coffeeSizes?.find(i => i.stack_value === key)?.stack_title ?? key}
                                onChange={() => {
                                    field.onChange(handleCheckboxChange(field.value, key))
                                    handleSubmit(props.onSubmit)()
                                }}
                            />
                        ))}
                    </div>
                )}
            />
        </div>
    )

    const AccessoryTypeComponent = () => optionVisible([Config.COFFEE_TYPE.ACCESSORY]) && !!props.accessoryTypes?.length && (
        <div className="filterOption --mb4">
            <h3 className="filterOption__title par-2">{t('filter.accessory_type')}</h3>
            <Controller
                name="accessoryTypes"
                control={control}
                render={({field, fieldState}) => (
                    <div className="filterOption__items">
                        {sortStackValues(props.accessoryTypes).map((key, idx) => (
                            <CustomCheckbox
                                key={idx}
                                value={isCheckboxChecked(field.value, key)}
                                label={sortStackValues(store.accessoryTypes)?.find(i => i.stack_value === key)?.stack_title ?? key}
                                onChange={() => {
                                    field.onChange(handleCheckboxChange(field.value, key))
                                    handleSubmit(props.onSubmit)()
                                }}
                            />
                        ))}
                    </div>
                )}
            />
        </div>
    )

    const optionComponents = useMemo(() => {
        if(getFiltersOrder()) {
            return getFiltersOrder().map(type => {
                switch (type) {
                    case 'aromatic_profile': return AromaticProfileComponent;
                    case 'coffee_line': return CoffeeLineComponent;
                    case 'coffee_type': return CoffeeTypeComponent;
                    case 'intensity': return IntensityComponent;
                    case 'cup_size': return CupSizesComponent;
                    case 'accessory_type': return AccessoryTypeComponent;
                }
            })
        }

        const options = {
            [t('filter.aromatic_profile')]: AromaticProfileComponent,
            [t('filter.line')]: CoffeeLineComponent,
            [t('filter.coffee_type')]: CoffeeTypeComponent,
            [t('filter.intensity')]: IntensityComponent,
            [t('filter.size')]: CupSizesComponent,
            [t('filter.accessory_type')]: AccessoryTypeComponent,
        }

        return Object.keys(options)
            .sort((a, b) => a.localeCompare(b))
            .map(k => options[k])
            .filter(o => o)
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit(props.onSubmit)}>

                {productTypes?.length > 1 && (
                    <div className="filterCategories --mb4">
                        <Controller
                            name="types"
                            control={control}
                            render={({field, fieldState}) => productTypes.map(type => (
                                <FilterCategory
                                    key={type.label}
                                    label={t(`filter.type.${type.label}`)}
                                    image={getImage(type.category?.category_gallery, Config.GALLERY_TYPE.COVER)}
                                    bgImage={getImage(type.category?.category_gallery, Config.GALLERY_TYPE.BACKGROUND)}
                                    isSelected={isCheckboxArrayChecked(field.value, type.names)}
                                    onClick={() => {
                                        field.onChange(handleCheckboxArrayChange(field.value, type.names))
                                        handleSubmit(props.onSubmit)()
                                    }}
                                />
                            ))}
                        />
                    </div>
                )}

                {optionComponents.map((component, idx) => (
                    <Fragment key={idx}>
                        {component()}
                    </Fragment>
                ))}

                <div style={{textAlign: 'center'}}>
                    <Button
                        type="secondary"
                        label={t('filter.submit', props.productsCount)}
                        onClick={props.hidePopup}
                    />
                </div>
            </form>
        </>
    );
}
