import {useModuleContext, useModuleStepsNavigation, useTranslation} from "../../../hooks";
import {AssistedAuthForm, IsNewMemberForm} from "../../../forms";
import React from "react";
import {useHistory} from "react-router-dom";
import {GTM} from "../../../misc/_index";
import {clearStore} from "../../../redux/noMachineShopReducer";
import {NespressoMember} from "../../../services/NespressoMemberService";

export default function CustomerIdentificationScreen() {
    const {t} = useTranslation()
    const history = useHistory();
    const {dispatch, reducer, store} = useModuleContext()
    const stepsNavigation = useModuleStepsNavigation()


    const handleSubmitIsNewMember = (isNewMember) => {
        if (isNewMember !== store.isNewMember) {
            dispatch(reducer.setIsNewMember(undefined))
            dispatch(reducer.setNespressoMember(null))
            dispatch(reducer.setPromotion(null))
            dispatch(reducer.setOrderAccount(null, false, false))
            dispatch(reducer.setOptIn(null))
        }
        dispatch(reducer.setIsNewMember(isNewMember));
        if (isNewMember === true) {
            dispatch(reducer.setCustomer(null))
            history.push(stepsNavigation.next());
        }
    }

    const handleSubmit = (data) => {
        const nespressoMember = new NespressoMember({
            email: data.email,
            rawData: data,
        })
        dispatch(reducer.setNespressoMember(nespressoMember))
        dispatch(reducer.setCustomer({
            details: {
                email: data.email,
                lang: data.lang,
            }
        }))
        stepsNavigation.navToNext()
    }


    return (
        <div className="evCID">
            <div className="grid">
                <div>
                    <IsNewMemberForm
                        onSubmit={handleSubmitIsNewMember}
                    />
                    {store.isNewMember === false && (
                        <>
                            <AssistedAuthForm
                                onSubmit={handleSubmit}
                            />
                        </>
                    )}
                </div>
                <div/>
            </div>
        </div>
    )
}