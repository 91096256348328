import {useModuleContext, useReseller} from "./index";
import GTM from "../misc/GTM";
import {useEffect, useMemo} from "react";
import {useMutation} from "@tanstack/react-query";
import * as MainActions from '../actions/MainActions'
export default function useGTM() {

    const reseller = useReseller()
    const {store, storeName} = useModuleContext()
    const promotionTypes = ['register_machine']
    const modules = ['machineRegistration']

    const gtmLogMutation = useMutation(
        async (payload) => MainActions.postGtmLog(payload)
    )

    const {gtmEnabledPromotion, gtmEnabledModule} = useMemo(() => ({
        gtmEnabledPromotion: promotionTypes.includes(store?.promotion?.promotion_type),
        gtmEnabledModule: modules.includes(storeName)
    }), [store?.promotion, storeName])

    const showPage = () => {
        if (gtmEnabledModule)
            GTM.showPage(reseller.isLoggedIn)
    }

    const redemptionProcessStarted = () => {
        if (gtmEnabledModule)
            GTM.redemptionProcessStarted()
    }

    const successfullyEntersMachineSerialNumber = (serialNumber) => {
        if (gtmEnabledModule)
            GTM.successfullyEntersMachineSerialNumber(serialNumber)
    }

    const userLoggedIn = (memberId) => {
        if (gtmEnabledModule || gtmEnabledPromotion)
            GTM.userLoggedIn(memberId)
    }

    const userNotLoggedIn = () => {
        if (gtmEnabledModule || gtmEnabledPromotion)
            GTM.userNotLoggedIn()
    }

    const competePromoChoice = (promotionName) => {
        if (gtmEnabledPromotion)
            GTM.competePromoChoice(promotionName)
    }

    const completeCoffeeSelection = (productName) => {
        if (gtmEnabledPromotion)
            GTM.completeCoffeeSelection(productName)
    }

    const finishCustomerInformationStep = (userCreateNewAccount, clubMemberID = null) => {
        if (gtmEnabledPromotion)
            GTM.finishCustomerInformationStep(userCreateNewAccount, clubMemberID)
    }

    const completeDeliveryStep = (promotionType = null) => {
        if (gtmEnabledPromotion || promotionTypes.includes(promotionType)){
            return GTM.completeDeliveryStep()
        }
    }

    const errorTracking = (errorType, errorName) => {
        if (gtmEnabledModule || gtmEnabledPromotion)
            GTM.errorTracking(errorType, errorName)
    }


    return {
        showPage,
        redemptionProcessStarted,
        successfullyEntersMachineSerialNumber,
        userLoggedIn,
        userNotLoggedIn,
        competePromoChoice,
        completeCoffeeSelection,
        finishCustomerInformationStep,
        completeDeliveryStep,
        errorTracking,
        gtmLogMutation
    }
}