import {useState} from "react";
import {useReseller, useTranslation} from "../../../../hooks";
import {useMutation} from "@tanstack/react-query";
import {useResellerActions} from "../../../../actions/useResellerActions";
import {ApiException} from "../../../../errors";

export default function useStockQuantity(_config) {

    const config = {
        initValue: 0,
        step: 10,
        idProduct: 0,
        idPos: 0,
        ..._config
    }

    const {t} = useTranslation()
    const {updatePosStock} = useResellerActions()

    const [quantity, setQuantity] = useState(config.initValue)
    const [lastQuantity, setLastQuantity] = useState(config.initValue)
    const [error, setError] = useState(null)
    const [isEditable, setIsEditable] = useState(false)

    const onChangeValue = (v) => {
        setQuantity(Number(v))
        if (v % config.step)
            setError(t('stock_management.step_error', config.step))
        else if (v < 0)
            setError(t('stock_management.negative_error'))
        else {
            setError(null)
        }
    }
    const onClickIncrease = () => {
        onChangeValue(quantity + config.step)
    }

    const onClickDecrease = () => {
        if (quantity - config.step >= 0)
            onChangeValue(quantity - config.step)
    }

    const onClickReject = () => {
        onChangeValue(lastQuantity)
        setIsEditable(false)
    }

    const updateMutation = useMutation(async () => {
        const payload = {
            local_stock: [
                {
                    id_product: config.idProduct,
                    local_stock_quantity: quantity
                }
            ]
        }

        const {data} = await updatePosStock(config.idPos, payload)

        if (data.status === 'success') return data
        throw new ApiException(data.messages[0], data)
    }, {
        onSuccess: ({data}) => {
            const qty = data.find(d => d.id_product === config.idProduct).local_stock_quantity
            setLastQuantity(Number(qty))
            setQuantity(Number(qty))
            setIsEditable(false)
        },
        onError: (error) => {
            setError(t('error_occurred'))
        }
    })

    return {
        quantity,
        error,
        isEditable,
        setIsEditable,
        onClickDecrease,
        onClickIncrease,
        onChangeValue,
        onClickReject,
        updateMutation,
        lastQuantity
    }
}