import BaseModal from "./BaseModal";
import React from "react";
import {useTranslation} from "../../hooks";
import {Button} from "../buttons/_index";


export default function SkipMsnModal({
    isVisible,
    onClose,
    onSubmit,
}) {
    const {t} = useTranslation()

    return (
        <BaseModal
            visible={isVisible}
            onClose={onClose}
        >
            <div className="modal__header">
                <h3
                    className="modal__title"
                    dangerouslySetInnerHTML={{__html: t('modal.skip_msn.title')}}
                />
            </div>

            <div className="modal__body --mb3">
                <div className="getInTouchModal__body">
                    <p
                        className="par-2 --mb4"
                        dangerouslySetInnerHTML={{__html: t('modal.skip_msn.text')}}
                    />
                    <div className="modal__buttons">
                        <Button type="grey" label={t('modal.skip_msn.go_back')} onClick={onClose}/>
                        <Button label={t('modal.skip_msn.continue')} onClick={onSubmit}/>
                    </div>
                </div>

            </div>
        </BaseModal>
    )
}
