import React, {useState} from "react";
import {AuthForm, Form, IsNewMemberForm, PromoCodeForm} from "../../../forms";
import {useForm} from "react-hook-form";
import {useTranslation} from "../../../hooks";
import editIcon from '../../../assets/img/icons/arrow-right-brown.svg';
import {Button} from "../../../components/buttons/_index";
import {useFormRulesBuilder} from "../../../forms/hooks";
import * as machineRegistrationReducer from "../../../redux/machineRegistrationReducer";
import PromoItem from "../../../components/PromoItem";

export default function PostPurchasePromoCodeScreen({
    store,
    text1,
    dispatch,
    stepsNavigation,
    nespressoMember,
    onSubmitIsNewMember,
    loginState,
    onSubmitLogin,
}) {
    const {t} = useTranslation()
    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            promoCode: store.promoCode?.promo_code_name,
        }
    })
    const formRules = useFormRulesBuilder({}, formMethods)
    const [editable, setEditable] = useState(!store.promoCode)

    const onSubmit = ({promoCodeData}) => {
        if (store.isPostPurchasePromoCode) {
            if (promoCodeData.promotion?.length) {
                dispatch(machineRegistrationReducer.setPromotions(promoCodeData.promotion))
                dispatch(machineRegistrationReducer.setPromotion(promoCodeData.promotion[0]))
            }
        }
        dispatch(machineRegistrationReducer.setPromoCode(promoCodeData));
        setEditable(false)
    }

    return (
        <div>
            {!!text1 && (
                <div className="grid --mb3">
                    <div>
                        <p
                            className="par-7 --500"
                            dangerouslySetInnerHTML={{__html: text1}}
                        />
                    </div>
                    <div/>
                </div>
            )}

            <div className="grid --mb4">
                <div>
                    <Form
                        formMethods={formMethods}
                        formRules={formRules}
                        onSubmit={onSubmit}
                    >
                        <PromoCodeForm
                            editable={editable}
                            setEditable={setEditable}
                        />
                        {editable ? (
                            <>
                                <div className="--mb3"/>
                                <Button
                                    btnType="submit"
                                    label={t('continue')}
                                />
                            </>
                        ) : (
                            <button
                                type="button"
                                className="link -primary"
                                onClick={() => setEditable(true)}
                            >
                                {t('pop.edit_promo_code_button')}
                                {' '}
                                <img src={editIcon} alt=""/>
                            </button>
                        )}
                    </Form>
                </div>
                <div/>
            </div>

            {!!store.promotion && !editable && (
                <div className="evPS__items">
                    <PromoItem
                        promotion={store.promotion}
                        readonly
                    />
                </div>
            )}

            {!editable && (
                <div className="grid --mb4">
                    <div>
                        <IsNewMemberForm
                            onSubmit={onSubmitIsNewMember}
                        />

                        {store.isNewMember === false && (
                            <>
                                {nespressoMember ? (
                                    <Button
                                        href={stepsNavigation.next()}
                                        label={t('continue')}
                                    />
                                ) : (
                                    <AuthForm
                                        state={loginState}
                                        onSubmit={onSubmitLogin}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    <div/>
                </div>
            )}

        </div>
    )
}
