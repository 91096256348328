import {useLocalizeObject, useTranslation} from "../../../misc/Hooks";
import React from "react";
import {PlanItem} from "../components";

import {useSelector} from "react-redux";
import {useStepsNavigation} from "../../../hooks/modules/stay_home";
import ProductsSelection from "../../../components/ProductsSelection";
import WelcomeMessage from "../../../components/WelcomeMessage";


export default function PlanSelection(props) {
    const t = useTranslation()
    const localizeObject = useLocalizeObject()
    const stepsNavigation = useStepsNavigation()
    const store = useSelector(({stayHome}) => ({
        data: stayHome.data,
        loadingCategories: stayHome.loadingCategories,
    }))

    return (
        <div className="shPS">
            <WelcomeMessage/>
            {!store.loadingCategories && !!props.plans.length && (
                <>
                    <div className="evPS__header">
                        <p className="par-7 --bold">{t('stay_home.plan_selection.plan_selection_title')}</p>
                        <div className="par-2" dangerouslySetInnerHTML={{__html: localizeObject(store.data.stay_home_config_description1)}}/>
                    </div>

                    <div className="evPS__items --mb4">
                        {props.plans.map(p => (
                            <PlanItem
                                key={p.id_product}
                                storeName="stayHome"
                                item={p}
                                cart={props.cart}
                                onChange={props.onChange}
                            />
                        ))}
                    </div>
                </>
            )}

            <ProductsSelection
                storeName="stayHome"
                stepsNavigation={stepsNavigation}
                texts={{
                    title: store.data.stay_home_config_products_selection_text,
                    subtitle: store.data.stay_home_config_description2,
                }}
                hideIfNoProducts
            />

        </div>
    )
}
