import './style.scss'
import {BarLoader} from "react-spinners";
export default function TableLoading({isFetching}){
    const style = getComputedStyle(document.body)

    return (
        <div className="tableLoading">
            {isFetching && (
                <BarLoader
                    color={style.getPropertyValue('--green')}
                    width="100%"
                    height="4px"
                    speedMultiplier={0.8}
                />
            )}
        </div>
    )
}