import {BiInfoCircle, BiMessageDetail} from "react-icons/bi";
import {useReseller} from "../../../hooks";

export default function InfoAlert(){

    const {reseller, isLoggedIn} = useReseller()

    if(!reseller.shopLabel || !isLoggedIn) return null
    return (
        <div className="infoAlert" data-tip={`POS: ${reseller.shopLabel}`} >
            <BiInfoCircle/>
        </div>
    )
}