import React, {useState} from 'react';
import BaseModal from "./BaseModal";
import {GetInTouchModal} from "./_index";
import {useCountryImages, useTranslation} from "../../hooks";
import {COUNTRY_IMAGE_TYPE} from "../../hooks/useCountryImages";

export default (props) => {
    const {t} = useTranslation();
    const {getImage} = useCountryImages()
    const [gitModalVisible, setGitModalVisible] = useState(false);

    return (
        <BaseModal
            visible={props.visible}
            onClose={props.onClose}
        >
            <div className="modal__header">
                <h3 className="modal__title">{t('modal.sn_help.title')}</h3>
            </div>

            <div className="modal__body">
                <div className="snHelpModal__body">
                    <div className="snHelpModal__thumb">
                        <img src={getImage(COUNTRY_IMAGE_TYPE.helpMachine)} alt=""/>
                    </div>
                    <div className="snHelpModal__content">
                        <p
                            className="par-2 --mb4"
                            dangerouslySetInnerHTML={{__html: t('modal.sn_help.text')}}
                        />
                        <img src={getImage(COUNTRY_IMAGE_TYPE.helpNumber)} alt="" className="img-fluid"/>
                    </div>
                </div>
            </div>


            <div className="modal__footer">

                <div className="par-4">
                    <b>{t('need_help')} </b>
                    <a className="link -primary -parentSize" onClick={() => setGitModalVisible(true)}>
                        <b>{t('get_in_touch')}</b>
                    </a>
                </div>
            </div>

            <GetInTouchModal
                visible={gitModalVisible}
                onClose={() => setGitModalVisible(false)}
            />
        </BaseModal>
    );
}
