import {useMemo, useState} from "react";

export default function usePermissions() {
    const [status, setStatus] = useState('idle')
    const statuses = useMemo(() => {
        return {
            isDenied: status === 'denied',
        }
    }, [status])

    async function requestPermission(name) {
        return await navigator.permissions.query({name})
            .then(result => {
                setStatus(result.state)
                switch (result.state) {
                    case 'granted':
                    case 'prompt':
                        return true
                    case 'denied':
                        return false
                }
            })
    }

    return {
        status,
        ...statuses,
        requestPermission,
    }
}
