import './style.scss'
import {Button} from "../../../../../components/buttons/_index";
import {useTranslation} from "../../../../../hooks";
import BaseModal from "../../../../../components/modals/BaseModal";
import React, {useEffect, useState} from "react";
import {getImage} from "../../../../../misc/Helpers";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import LocalStockRequestQuantity from "./LocalStockRequestQuantity";
import {useMutation} from "@tanstack/react-query";
import * as orderActions from "../../../../../actions/OrderActions";
import {ApiException} from "../../../../../errors";
import {useResellerActions} from "../../../../../actions/useResellerActions";
import {InfoComponent} from "../../../../../components/_index";

export default function LocalStockRequest({
    query,
    selectedItems,
    setSelectedItems,
    idFieldName,
    confirmingActions,
    customActionsVariables
}) {
    const [modalVisible, setModalVisible] = useState(false)
    const [order, setOrder] = useState([])
    const [status, setStatus] = useState({type: '', message: ''})

    const {createPosStockRequest} = useResellerActions()

    const {t} = useTranslation()

    const onCloseModal = () => {
        if(status.type === 'success') {
            setSelectedItems([])
        }
        setStatus({type: '', message: ''})
        setModalVisible(false)
        setOrder([])
    }

    const sendOrderMutation = useMutation({
            mutationFn: async (payload) => {
                const postPayload = {
                    requests: payload.map((d => ({
                        id_product: d.id,
                        local_stock_request_value: d.value
                    })))
                }
                const {data} = await createPosStockRequest(customActionsVariables.idShop, postPayload)

                if (data.status === 'success')
                    return data.data
                throw new ApiException(data.messages[0], data)
            },
            onError: (error) => {
                console.log(error)
                setStatus({
                    type: "error",
                    message: t('stock_management.error_request_send')
                })
            },
            onSuccess: (data) => {
                setStatus({
                    type: "success",
                    message: t('stock_management.success_request_send')
                })
                customActionsVariables.requestsTableQuery.refetch()
                setOrder([])

            }
        }
    )

    const handleSubmitOrder = () => {
        sendOrderMutation.mutate(order)
        setStatus({type: '', message: ''})
    }

    return (
        <div className="localStockRequest">
            <Button
                label={t('stock_management.order_selected_products', selectedItems.length)}
                size="lg"
                onClick={() => setModalVisible(true)}
            />

            <BaseModal
                visible={modalVisible}
                onClose={onCloseModal}
            >
                <div className="localStockRequestModal">
                    <h2 className="localStockRequestModal__title">{t('stock_management.order_modal_title')}</h2>
                    {status.type !== 'success' ? (
                     <>
                         <div className="localStockRequestModal__tableWrapper">
                             <table className="localStockRequestModal__table">
                                 <thead>
                                 <tr>
                                     <th>{t('stock_management.product_image')}</th>
                                     <th>{t('stock_management.product_title')}</th>
                                     <th>{t('stock_management.order_quantity')}</th>
                                 </tr>
                                 </thead>
                                 <tbody>
                                 {selectedItems.map((item, idx) => {
                                     return (
                                         <tr key={idx}>
                                             <td>
                                                 <img className="productImage" src={getImage(item.product_gallery)} alt=""/>
                                             </td>
                                             <td>{item.product_title}</td>
                                             <td>
                                                 <LocalStockRequestQuantity
                                                     item={item}
                                                     order={order}
                                                     setOrder={setOrder}
                                                 />
                                             </td>
                                         </tr>
                                     )
                                 })}
                                 </tbody>
                             </table>
                         </div>
                         <div className="localStockRequestModal__buttonWrapper">
                             <Button
                                 label={sendOrderMutation.isLoading ? t('table.sending') : t('stock_management.order_modal_send')}
                                 size="lg"
                                 disabled={!order.length || sendOrderMutation.isLoading}
                                 onClick={handleSubmitOrder}
                                 isLoading={sendOrderMutation.isLoading}
                             />
                         </div>
                         {status.type === 'error' && (
                             <InfoComponent {...status}/>
                         )}
                     </>
                    ) : (
                        <InfoComponent {...status}/>
                    )}

                </div>


            </BaseModal>
        </div>
    )
}