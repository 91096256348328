import {useMutation, useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {ApiException} from "../../../../errors";
import {useResellerActions} from "../../../../actions/useResellerActions";
import {useMemo, useState} from "react";

export default function useResellerUser() {
    const {id} = useParams()
    const actions = useResellerActions()
    const [alert, setAlert] = useState({type: '', message: ''})
    const [lackOfAccess, setLackOfAccess] = useState(false)

    const getUserQuery = useQuery(
        ['editReseller', id],
        async () => {
            const {data} = await actions.getResellerEditData(id)
            if (data.status === 'success')
                return data
            throw new ApiException(data.messages[0], data)
        },
        {
            onSuccess: () => {},
            onError: ({error}) => {
                if (error === 'no_relation')
                    setLackOfAccess(true)
                else setAlert({type: 'error', message: 'error_occurred'})
            }
        }
    )

    const editUserMutation = useMutation(
        async (payload) => {
            console.log(payload)
            const postData = {
                id_reseller: id ?? 0,
                reseller_firstname: payload.firstname ?? '',
                reseller_lastname: payload.lastname ?? '',
                reseller_email: payload.email ?? '',
                reseller_role: payload.role.value ?? '',
                reseller_number: payload.number ?? '',
                id_parent: payload.supervisor.value ?? 0,
                id_shop: payload.shop.value ?? 0,
                id_child_shop: 0,
                id_child_parent: 0,
                reseller_status: payload.status ? 1 : 0
            }
            const {data} = await actions.resellerUpdate(postData)
            if (data.status === 'success') return data
            throw new ApiException(data.messages[0], data)
        },
        {
            onMutate: () => setAlert({type: '', message: ''}),
            onSuccess: () => setAlert({type: 'success', message: 'reseller_client.success_save'}),
            onError: () => setAlert({type: 'error', message: 'reseller_client.error_save'})
        }
    )

    return {
        alert,
        getUserQuery,
        editUserMutation,
        lackOfAccess
    }
}