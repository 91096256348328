import {useResellerActions} from "../../../../actions/useResellerActions";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useNavigation} from "../../../../misc/Hooks";
import Routes from "../../../../utils/Routes";

export default function useMessage(id) {
    const history = useHistory()
    const navigation = useNavigation()
    const actions = useResellerActions()
    const query = useQuery(
        ['reseller.message', id],
        () => actions.getThread(id),
    )
    const mutation = useMutation((data) => actions.sendThreadMessage(id, data))
    const newMessageMutation = useMutation(
        (id) => actions.createThread(id),
        {
            onSuccess: ({data}) => {
                if(data?.status === 'success') {
                    history.push(navigation(Routes.V2_RESELLER_MESSAGE, data.data.id_thread))
                }
            },
            onError: (error) => {}
        }
    )

    const sendComment = (data) => {
        const formData = new FormData()
        formData.append('id_thread', id)
        formData.append('thread_message_text', data.message)

        if(data.files) {
            Object.values(data.files)
                .filter(file => file.size <= 8388608)
                .map(file => formData.append('attachments[]', file))
        }

        mutation.mutate(formData)
    }

    const createThread = (commentId) => {
        newMessageMutation.mutate(commentId)
    }

    const get = (msg) => ({
        id: msg.id_thread,
        title: msg.thread_title,
        text: msg.thread_message?.thread_message_text,
        updated_at: msg.updated_at,
        created_at: msg.created_at,
    })

    return {
        query,
        data: query.isSuccess ? query.data.data.data : null,
        mutation,
        newMessageMutation,
        sendComment,
        createThread,
        get,
    }
}
