import {useTranslation} from "../../../../hooks";
import {DefaultSelectedActions} from "../../components";

export default function SelectedRequestsActions({
    query,
    selectedItems,
    setSelectedItems,
    idFieldName,
    confirmingActions
}){
    const {t} = useTranslation()

    const actionsOptions = [
        {label: t('reseller_client.accept') || t('accept'), value: 'accept_reseller_request'},
        {label: t('reseller_client.reject') || t('reject'), value: 'reject_reseller_request'},
    ]
    
    return (
        <DefaultSelectedActions
            query={query}
            selectedItems={selectedItems}
            actionsOptions={actionsOptions}
            setSelectedItems={setSelectedItems}
            idFieldName={idFieldName}
            confirmingActions={confirmingActions}
        />
    )
}