import {Button} from "../../components/buttons/_index";
import {isEnabledByMarket} from "../../utils/MarketConfig";
import {Preloader} from "../../misc/_index";
import React, {useMemo} from "react";
import {useLocalizeObject, useTranslation} from "../../misc/Hooks";
import {useCountry, useModuleContext, useReseller, useSearchParams} from "../../hooks";
import {
    DeliveryMethodFormFragment,
    PaymentMethodConfirmFormFragment,
    PaymentMethodFormFragment
} from "../../forms/fragments";
import InfoComponent from "../infoComponent/InfoComponent";
import {FormGroup} from "../../forms/components";
import {RecaptchaInput} from "../../forms/presets";
import {PickupPoints} from "../_index";

export default function SummaryForm({
    storeName,
    deliveryMethodEnabled,
    ...props
}) {
    const t = useTranslation()
    const localizeObject = useLocalizeObject()
    const {store} = useModuleContext()
    const reseller = useReseller()
    const {isAssistedSimplifiedFlowEnabled} = useCountry()
    const recaptchaVisible = useMemo(() => {
        return reseller.isLoggedIn
            && isAssistedSimplifiedFlowEnabled()
            && (props.paymentMethod
                ? !props.paymentMethod.api_use_hop
                && !props.paymentMethod.payment_method_requires_one_time_token
                && !props.paymentMethod.adyen_payment_type
                && (props.paymentMethod.payment_method_integration_type !== 'xml')
                : false)
            && !store.searchedMember?.hasWebAccount
    }, [reseller.isLoggedIn, isAssistedSimplifiedFlowEnabled, store.searchedMember])
    const searchParams = useSearchParams()
    const mapVisible = searchParams.get('map') ? !!Number(searchParams.get('map')) : false

    if (props.order) {
        return (
            <div className="grid -bigColumnGap">
                <div>

                    <div>
                        {!!props.deliveryMethod && (
                            <div className="summary__row --mb1">
                                <div className="summary__name --bold">{t('delivery_type')}</div>
                                <div
                                    className="summary__value -bold"
                                >{localizeObject(props.deliveryMethod?.delivery_method_title)}</div>
                            </div>
                        )}
                        <div className="summary__row --mb4">
                            <div className="summary__name --bold">{t('payment_type')}</div>
                            <div
                                className="summary__value -bold"
                            >{localizeObject(props.paymentMethod?.payment_method_title)}</div>
                        </div>
                    </div>

                    {!!localizeObject(props.paymentMethod?.payment_method_text) && (
                        <div className="summary__row --mb4">
                            <div
                                className="par-5"
                                dangerouslySetInnerHTML={{__html: localizeObject(props.paymentMethod?.payment_method_text)}}
                            />
                        </div>
                    )}

                    {!!props.error && (
                        <>
                            <InfoComponent
                                type="error"
                                message={props.error}
                                liteShadow
                            />
                        </>
                    )}

                    {!!props.loading && (
                        <div style={{textAlign: 'center'}}>
                            <Preloader classes="--mb3"/>
                            <p className="par-2"
                               dangerouslySetInnerHTML={{__html: t('ev_delivery_payment.loading_text')}}/>
                        </div>
                    )}
                </div>
                <div>
                    {props.children}
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="evDP__info">
                <div className="grid -bigColumnGap --mb3">
                    {deliveryMethodEnabled && (
                        <div className="--mb4">
                            <h3 className="par-1">{t('choose_delivery_type')}</h3>

                            <DeliveryMethodFormFragment
                                deliveryMethods={props.deliveryMethods}
                            />
                        </div>
                    )}
                    <div>
                        <div>
                            <h3 className="par-1">{t('choose_payment_type')}</h3>

                            <PaymentMethodFormFragment
                                paymentMethods={props.paymentMethods}
                            />
                            {!!props.plan && (
                                <PaymentMethodConfirmFormFragment
                                    machinePlan={props.plan}
                                    paymentMethods={props.paymentMethods}
                                />
                            )}
                        </div>
                    </div>
                </div>

                {!!(props.deliveryMethod?.quotationData?.type === 'pickup' || mapVisible) && (
                    <div>
                        <PickupPoints/>
                    </div>
                )}

                {recaptchaVisible && (
                    <FormGroup>
                        <RecaptchaInput/>
                    </FormGroup>
                )}

                {!!(reseller.user && t('reseller_save_order_info')) && (
                    <p className="par-2 --error --pt1"
                       dangerouslySetInnerHTML={{__html: t('reseller_save_order_info')}}/>
                )}
            </div>

            {/*{!!(paymentMethod && paymentMethod.payment_method_integration_type === 'quotation' && paymentMethod.payment_method_requires_one_time_token) && (*/}
            {/*    <MemberCreditInfo memberCredit={props.memberCredit}/>*/}
            {/*)}*/}
            <div className="step__nav -left -bottom --mb4">
                <Button
                    type="primary-outline"
                    size="sm"
                    label={t('back')}
                    href={props.stepNavigation.prev()}
                    // disabled={props.loading}
                />
                <Button
                    type="primary"
                    btnType="submit"
                    label={props.user ? t('confirm') : t('buy')}
                    size={isEnabledByMarket('smallFormSubmitButton') ? 'sm' : undefined}
                    // disabled={!props.isCartValid || !paymentMethod || loading}
                    disabled={!props.isCartValid || props.loading}
                    isLoading={props.loading}
                />
            </div>

            {!!localizeObject(props.paymentMethod?.payment_method_text) && (
                <div className="summary__row --mb4">
                    <div
                        className="par-5"
                        dangerouslySetInnerHTML={{__html: localizeObject(props.paymentMethod?.payment_method_text)}}
                    />
                </div>
            )}

            {!!props.error && (
                <>
                    <InfoComponent
                        type="error"
                        message={props.error}
                        liteShadow
                    />
                </>
            )}
        </div>
    )
}
