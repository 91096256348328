import {useEffect, useRef, useState} from "react";
import * as actions from '../../../actions/MainActions';
import {useSelector} from "react-redux";


export default function usePaymentMethodCodeVerify() {
    const mountedRef = useRef(false)
    const store = useSelector(({main}) => ({
        country: main.country,
    }))
    const [accountNumber, setAccountNumber] = useState(null)
    const [loadingCode, setLoadingCode] = useState(false)
    const [codeError, setCodeError] = useState(null);
    const [codeVisible, setCodeVisible] = useState(false);
    const [codeToken, setCodeToken] = useState(null);
    const [loadingVerifyCode, setLoadingVerifyCode] = useState(false)
    const [codeVerifyError, setCodeVerifyError] = useState(null);

    useEffect(() => {
        mountedRef.current = true
        return () => mountedRef.current = false
    }, [])

    const generateCode = async (email, productId, _accountNumber) => {
        if(_accountNumber === accountNumber)
            return
        const codeFormData = {
            email: email,
            id_country: store.country.id_country,
        };
        setLoadingCode(true)
        setCodeError(null)
        setCodeToken(null)
        setCodeVisible(false)
        setAccountNumber(_accountNumber)
        await actions.generateCode(codeFormData, productId)
            .then(r => {
                const res = r.data;
                if (res.status === 'success') {
                    if (mountedRef.current) {
                        setCodeToken(res.data)
                        setCodeVisible(true)
                    }
                } else {
                    if (res.messages?.length) {
                        if (mountedRef.current) {
                            setCodeError(res.messages[0])
                        }
                    }
                }
            })
            .catch(err => {
                console.error(err)
                setCodeError('unknown_error')
            })
            .finally(() => {
                if (mountedRef.current)
                    setLoadingCode(false)
            })
    }

    const verifyCode = async (verificationCode) => {
        setLoadingVerifyCode(true)
        setCodeVerifyError(null)
        return await actions.verifyCode(verificationCode, codeToken)
            .then(r => {
                const res = r.data;
                if (res.status === 'success') {
                    setCodeVisible(false)
                    return true
                } else {
                    if (res.messages?.length) {
                        if (mountedRef.current) {
                            setCodeVerifyError(res.messages[0])
                        }
                    }
                }
                return false
            })
            .catch(err => {
                console.error(err)
                setCodeError('unknown_error')
                return false
            })
            .finally(() => {
                if (mountedRef.current)
                    setLoadingVerifyCode(false)
            })
    }

    const resendCode = async (email, productId, _accountNumber) => {
        setAccountNumber(null)
        await generateCode(email, productId, _accountNumber)
    }

    return {
        loadingCode,
        codeVisible,
        codeToken,
        codeError,
        loadingVerifyCode,
        codeVerifyError,
        generateCode,
        verifyCode,
        resendCode,
    }
}
