import './style.scss'
import {uuidv4} from "../../misc/Helpers";
import React, {useEffect} from "react";
import {TableFilters, TableFooter, TableLoading} from "./components";
import {useTranslation} from "../../hooks";
import sanitizeText from "../../helpers/sanitizeText";
import ReactTooltip from "react-tooltip";
import {FiRefreshCcw} from "react-icons/fi";
import {RiCheckboxIndeterminateLine} from "react-icons/ri";
import {usePagination, useTable} from "react-table";

export default function Table(
    {
        query,
        tableData,
        tableColumns,
        setPage,
        currentPage,
        setSize,
        size,
        title = '',
        hiddenColumns = [],
        filters,
        filtersIsLoading = false,
        selectedItems,
        setSelectedItems,
        actionsAlwaysVisible = false,
        SelectedActionsComponent,
        selectAllItems = [],
        actionsTitle,
        idFieldName,
        confirmingActions,
        hideTable = false,
        customActionsVariables = {}
    }
) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setPageSize,
    } = useTable({
        columns: tableColumns ?? [],
        data: tableData ?? [],
        initialState: {pageSize: size, hiddenColumns},
    }, usePagination)
    const {t} = useTranslation();


    useEffect(() => {
        setPage(1)
        setPageSize(size)
    }, [size])

    return (
        <div className="tableWrapper">

            {!!filters && (
                <TableFilters {...filters} title={title} isLoading={filtersIsLoading}/>
            )}
            {!hideTable && (
                <div className="tableWrapper__shadow">
                    <div className="tableWrapper__header">
                        <h3 className="heading-3">{title}</h3>
                        <button
                            className="tableWrapper__refresh"
                            onClick={() => query.refetch()}
                            data-tip={t('refresh')}
                            disabled={query.isFetching}
                        >
                            <FiRefreshCcw/>
                        </button>
                    </div>
                    <div className="tableWrapper__table">
                        <table {...getTableProps()}>
                            <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr key={uuidv4()} {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => {
                                        if (typeof column.render('Header') === 'object') {
                                            return (
                                                <th key={uuidv4()}>{column.render('Header')}</th>
                                            )
                                        }
                                        return (
                                            <th key={uuidv4()} {...column.getHeaderProps()}>
                                                <span {...sanitizeText(column.render('Header'))}/>
                                            </th>
                                        )
                                    })}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr key={uuidv4()} {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td key={uuidv4()} {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                )
                            })}
                            {(!query?.data?.data?.data?.data?.length && !query.isLoading) && (
                                <tr>
                                    <td colSpan="100" className="-noResults">{t('table.no_results')}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>

                    </div>
                    <TableFooter
                        setSize={setSize}
                        size={size}
                        query={query}
                        setPage={setPage}
                        page={currentPage}
                    />
                    <TableLoading isFetching={query.isFetching}/>
                </div>
            )}

            {((selectedItems?.length > 0 || actionsAlwaysVisible) && SelectedActionsComponent) && (
                <div className="tableSelectedActions">
                    <div className="tableSelectedActions__header">
                      <h4 className="tableSelectedActions__title">
                          {actionsTitle ?? t('actions')}
                      </h4>

                        {!hideTable && (
                            <button
                                className="tableSelectedActions__clear"
                                data-tip={t('clear_selection')}
                                onClick={() => setSelectedItems([])}
                            >
                                <RiCheckboxIndeterminateLine/>
                            </button>
                        )}
                    </div>
                    <div className="tableSelectedActions__body">
                        <SelectedActionsComponent
                            query={query}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            selectAllItems={selectAllItems}
                            idFieldName={idFieldName}
                            confirmingActions={confirmingActions}
                            customActionsVariables={customActionsVariables}
                        />
                    </div>
                </div>
            )}

        </div>
    )
}