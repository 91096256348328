import {FormGroup, FormRow} from "../components";
import {useFormHelper, useTranslation} from "../../hooks";
import {useWatch} from "react-hook-form";
import React, {useEffect} from "react";
import {FormError, useAsyncSelect, useFetchPostcodesByCity} from "../hooks";
import {AsyncSelectController, SelectController} from "../controllers";
import * as mainActions from "../../actions/MainActions";
import {useLocalizeObject} from "../../misc/Hooks";

export default function PostcodeByCityInputs({}) {
    const {t} = useTranslation()
    const localizeObject = useLocalizeObject()
    const {formMethods, ...formHelpers} = useFormHelper()
    const postcode = useWatch({
        control: formMethods.control,
        name: formHelpers.getName('postcode'),
    })
    const city = useWatch({
        control: formMethods.control,
        name: formHelpers.getName('city'),
    })
    const {query, selectOptions} = useFetchPostcodesByCity(city?.value)
    const citySelect = useAsyncSelect({
        name: 'cities',
        action: mainActions.getCities,
        paramFunc: v => ({startsWith: v, limit: 100}),
        mapFunc: i => ({label: localizeObject(i.city_title), value: i.id_city}),
        timeout: 0,
    })

    useEffect(() => {
        formHelpers.clearErrors('postcode')
        if (query.isSuccess && postcode && !selectOptions.find(o => o.value === postcode.value)) {
            formHelpers.setValue('postcode', null)
            return
        }
        if (query.isError) {
            formHelpers.setError('postcode', FormError('invalid', t('validation.invalid')))
            formHelpers.setError('city', FormError('invalid', t('validation.invalid')))
        }
    }, [query.isError, query.isSuccess])

    useEffect(() => {
        if(!postcode && query.isSuccess && selectOptions.length === 1) {
            formHelpers.setValue('postcode', selectOptions[0])
        }
    }, [selectOptions, city])

    return (
        <FormRow ignoreBottomMargin>
            <FormGroup>
                <AsyncSelectController
                    name="city"
                    label={t('city')}
                    rules="citySelect"
                    asyncSelect={citySelect}
                />
            </FormGroup>
            <FormGroup>
                <SelectController
                    name="postcode"
                    label={t('postcode')}
                    rules="postcodeSelect"
                    selectOptions={selectOptions}
                    options={{
                        isLoading: query.isLoading && query.isFetching,
                    }}
                />
            </FormGroup>
        </FormRow>
    )
}
