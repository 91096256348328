import {
    useCountry,
    useGTM,
    useMachine,
    useModuleContext,
    useModuleStepsNavigation,
    useNespressoMember,
    useRequiredField,
    useReseller, useSeoPage,
    useTranslation
} from "../../../hooks";
import React, {useEffect, useState} from "react";
import {
    AssistedAuthForm,
    AuthForm,
    IsNewMemberForm,
    ProofOfPurchaseAndPromoCodeForm,
    SerialNumberForm
} from "../../../forms";
import {uuidv4} from "../../../misc/Helpers";
import BaseModal from "../../../components/modals/BaseModal";
import {NespressoMember} from "../../../services/NespressoMemberService";
import {useHistory} from "react-router-dom";
import {Button} from "../../../components/buttons/_index";
import MachinePreview from "../../../components/machinePreview";
import {AbandonedOrderScreen, PostPurchasePromoCodeScreen} from "./index";
import {isEnabledByMarket} from "../../../utils/MarketConfig";
import SearchNespressoMember from "../../../components/searchNespressoMember";

export default function MachineRegistrationScreen({authorizationDisabled}) {
    useSeoPage({title: 'shop_machine_registration.title'})
    const {t} = useTranslation()
    const {store, dispatch, reducer} = useModuleContext()
    const {data: country} = useCountry()
    const reseller = useReseller();
    const history = useHistory()
    const machine = useMachine()
    const stepsNavigation = useModuleStepsNavigation()
    const {
        loginMutation,
        error: loginError,
        setError: setLoginError,
        nespressoMember,
        storeLoggedUser
    } = useNespressoMember()
    const [editSNPopup, setEditSNPopup] = useState(false);
    const [editablePop, setEditablePop] = useState(!store.promoCode && !store.proofOfPurchase)
    const {popStepValid} = useRequiredField()
    const {userNotLoggedIn} = useGTM()
    const text1 = reseller.isLoggedIn
        ? t('ev_machine_registration.text1_reseller') || t('ev_machine_registration.text1')
        : t('ev_machine_registration.text1')

    useEffect(() => {
        setEditablePop(!popStepValid)
    }, [popStepValid])

    const resetStore = () => {
        dispatch(reducer.setIsNewMember(undefined))
        dispatch(reducer.setNespressoMember(null))
        dispatch(reducer.setPromotion(null))
        dispatch(reducer.setOrderAccount(null, false, false))
        dispatch(reducer.setOptIn(null))
        dispatch(reducer.setAbandonedOrderConsentAccepted(undefined))
        dispatch(reducer.setPersonalization(null))
    }

    const handleSubmitSerialNumber = ({data, logs, machine, isRequired}) => {
        setEditSNPopup(false)

        if (data.serialNumber !== store.serialNumber?.serial_number || !store.machine) {
            dispatch(reducer.clearStore())
            dispatch(reducer.setUuid(uuidv4()))

            if(data.serialNumber && machine) {
                dispatch(reducer.setMachine(machine.machine, machine.test_order, {
                    ...store.serialNumber,
                    serial_number: data.serialNumber,
                }));
                dispatch(reducer.setMachineResponse(machine))
            }
            if(logs && logs.length) {
                dispatch(reducer.setOcrLogs([...store.ocrLogs, ...logs]))
            }
        }

        if(typeof reducer.setMsnRequired === 'function') {
            dispatch(reducer.setMsnRequired(isRequired))
        }
    }

    const handleSubmitPop = (data) => {
        if (data.machinePrice) {
            data.machinePrice = Number(data.machinePrice.toString().replace(',', '.'))
        }
        if (data.proofOfPurchaseData || store.proofOfPurchaseLogs) {
            const proofOfPurchase = {
                file: data.file,
                date: data.date,
                region: data.region,
                shopGroup: data.shopGroup,
                shop: data.shop,
                invoiceNumber: data.invoiceNumber,
                machinePrice: data.machinePrice,
            }
            if (proofOfPurchase.file instanceof File) {
                proofOfPurchase.file = data.proofOfPurchaseData.filename
            }
            if (store.proofOfPurchase?.shop?.value !== proofOfPurchase.shop?.value) {
                resetStore()
            }
            dispatch(reducer.setProofOfPurchase(proofOfPurchase));
            dispatch(reducer.setProofOfPurchaseLogs(data.proofOfPurchaseData));
        } else {
            dispatch(reducer.setProofOfPurchase(null));
            dispatch(reducer.setProofOfPurchaseLogs(null));
        }
        if (data.promoCodeData) {
            dispatch(reducer.setPromoCode(data.promoCodeData));
            if (store.isPostPurchasePromoCode) {
                if (data.promoCodeData.promotion?.length) {
                    dispatch(reducer.setPromotions(data.promoCodeData.promotion))
                    dispatch(reducer.setPromotion(data.promoCodeData.promotion[0]))
                }
            }
        } else {
            dispatch(reducer.setPromoCode(null));
        }
    }

    const handleLogin = async (data) => {
        const payload = {
            login: data.email,
            password: data.password,
            recaptchaToken: data.recaptchaToken,
            serial: store.serialNumber?.serial_number,
        }

        loginMutation.mutateAsync(payload)
            .then((data) => {
                const purchasedBy = machine.getPurchasedBy()
                if ((purchasedBy && purchasedBy !== Number(data.member_id)) || payload.login.includes('machine_registration_process_already_started')) {
                    setLoginError(t('error.machine_registration_process_already_started'))
                    return
                }

                storeLoggedUser(data, payload)
                stepsNavigation.navToNext()
            })
    }

    const handleSubmitIsNewMember = (isNewMember) => {
        if (isNewMember !== store.isNewMember && !store.isPostPurchasePromoCode) {
            resetStore()
        }
        dispatch(reducer.setIsNewMember(isNewMember));
        if (isNewMember === true) {
            dispatch(reducer.setCustomer(null))
            history.push(stepsNavigation.next());
            userNotLoggedIn()
        }
    }

    const handleSubmitAssisted = (data) => {
        const nespressoMember = new NespressoMember({
            email: data.email,
            rawData: data,
        })
        dispatch(reducer.setNespressoMember(nespressoMember))
        dispatch(reducer.setCustomer({
            details: {
                email: data.email,
                lang: data.lang,
            }
        }))
        stepsNavigation.navToNext()
    }

    if (store.isPostPurchasePromoCode)
        return (
            <PostPurchasePromoCodeScreen
                store={store}
                text1={text1}
                dispatch={dispatch}
                stepsNavigation={stepsNavigation}
                nespressoMember={nespressoMember}
                onSubmitIsNewMember={handleSubmitIsNewMember}
                loginState={{
                    error: loginError,
                    isLoading: loginMutation.isLoading,
                }}
                onSubmitLogin={handleLogin}
            />
        )

    if (store.abandonedOrder && !nespressoMember)
        return (
            <AbandonedOrderScreen
                store={store}
            />
        )

    return (
        <div>
            {!!t('ev_machine_registration.top_text') && (
                <div
                    className="par-7 --500 --mb3"
                    dangerouslySetInnerHTML={{__html: t('ev_machine_registration.top_text')}}
                />
            )}

            {!!text1 && (
                <div className="grid --mb3">
                    <div>
                        <p
                            className="par-7 --500"
                            dangerouslySetInnerHTML={{__html: text1}}
                        />
                    </div>
                    <div/>
                </div>
            )}

            {!store.machine ? (
                <SerialNumberForm
                    options={{
                        checkPromotionLocalStock: true,
                    }}
                    onSubmit={handleSubmitSerialNumber}
                />
            ) : (
                <div className="grid --mb4">
                    <MachinePreview
                        onCLickEdit={() => setEditSNPopup(true)}
                    />
                    <div/>
                </div>
            )}

            {!!(store.machine || !store.msnRequired) && (
                <div className="grid --mb4">
                    <ProofOfPurchaseAndPromoCodeForm
                        stepFilled={popStepValid}
                        editable={editablePop}
                        setEditable={setEditablePop}
                        onSubmit={handleSubmitPop}
                    />
                    <div/>
                </div>
            )}

            {!!((store.machine || !store.msnRequired) && popStepValid && !editablePop && !authorizationDisabled) && (
                <div className="--mb4">
                    {isEnabledByMarket('disableEcapi') ? (
                        <Button
                            type="primary"
                            label={t('continue')}
                            href={stepsNavigation.next()}
                        />
                    ) : (
                        <div>
                            <div className="grid --mb4">
                                <div>
                                    <IsNewMemberForm
                                        onSubmit={handleSubmitIsNewMember}
                                    />

                                    {store.isNewMember === false && (
                                        <>
                                            {reseller.isLoggedIn ? (
                                                <>
                                                    {!country.country_assisted_simplified_flow_enabled && (
                                                        <AssistedAuthForm
                                                            onSubmit={handleSubmitAssisted}
                                                        />
                                                    )}
                                                </>
                                            ) : nespressoMember ? (
                                                <Button
                                                    href={stepsNavigation.next()}
                                                    label={t('continue')}
                                                />
                                            ) : (
                                                <AuthForm
                                                    state={{
                                                        error: loginError,
                                                        isLoading: loginMutation.isLoading,
                                                    }}
                                                    onSubmit={handleLogin}
                                                />
                                            )}
                                        </>
                                    )}
                                    {store.isNewMember === true && (
                                        <Button
                                            href={stepsNavigation.next()}
                                            label={t('continue')}
                                        />
                                    )}
                                </div>
                                <div/>
                            </div>

                            {!!country.country_assisted_simplified_flow_enabled && store.isNewMember === false && reseller.isLoggedIn && (
                                <SearchNespressoMember/>
                            )}
                        </div>
                    )}
                    <div/>
                </div>
            )}


            {!!authorizationDisabled && (!editablePop && popStepValid) && (
                <Button
                    type="primary"
                    label={t('continue')}
                    href={stepsNavigation.next()}
                />
            )}


            <BaseModal
                visible={editSNPopup}
                onClose={() => setEditSNPopup(false)}
                small
            >
                <SerialNumberForm
                    options={{
                        inRow: false,
                    }}
                    onSubmit={handleSubmitSerialNumber}
                />
            </BaseModal>
        </div>
    )
}
