import React, {useState} from 'react';
import {Button} from "./buttons/_index";
import {PlanItemModal} from "./modals/_index";
import {useCart, usePriceFormatter, useTranslation} from "../misc/Hooks";
import {getImage, unlockByOptIn} from "../misc/Helpers";
import {Config} from "../utils/_index";
import LockedByOptIn from "./misc/LockedByOptIn";

export default (props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const {formatPriceObj} = usePriceFormatter();
    const {updateItem, isItemLocked} = useCart(props.storeName ?? 'ev');
    const t = useTranslation();


    const itemInCart = props.cart?.items.find(i => i.id === props.item.id_product);
    const isLocked = isItemLocked(props.item.id_product);
    const lockedByOptIn = unlockByOptIn(props.item.promotion_product, props.category.promotionCategory)

    const coverImage = getImage(props.item.product_gallery);

    return (
        <div className="promoItem">
            <div className="promoItem__header">
                {!!coverImage && (
                    <div className="promoItem__thumb">
                        <img src={coverImage} alt={props.item.product_title}/>
                    </div>
                )}
                <h3 className="promoItem__title">{props.item.product_title}</h3>
                <p
                    className="promoItem__text"
                    dangerouslySetInnerHTML={{__html: props.item.product_plan_description1}}
                />
            </div>


            <div className="promoItem__body">
                <div className="promoItem__line">
                    {!!props.item.product_plan_price && (
                        <>
                            <h4 className="promoItem__price">{formatPriceObj(props.item.product_plan_price, 0)}</h4>
                            {props.item.product_type === Config.COFFEE_TYPE.PLAN_COFFEE && (
                                <h4 className="promoItem__period">{t('plan_price_label')}</h4>
                            )}
                        </>
                    )}
                </div>
                <div className="--mb2">
                    {props.item.product_type === Config.COFFEE_TYPE.PLAN_COFFEE ? (
                        <p className="promoItem__label">{t('for_your_coffee')}</p>
                    ) : (
                        <p className="promoItem__label">{t('for_your_first_coffee_order')}</p>
                    )}
                </div>

                <button
                    className="link -primary"
                    onClick={() => setModalVisible(true)}
                >{t('read_more')}</button>

            </div>

            <LockedByOptIn {...lockedByOptIn}/>

            <div className="promoItem__content -center primaryUl">
                <div
                    dangerouslySetInnerHTML={{__html: props.item.product_plan_description2}}
                />
            </div>



            <div className="promoItem__footer">
                <div className="coffeeItem__button">
                    {lockedByOptIn.value ? (
                            <Button
                                type="primary"
                                size="sm"
                                label={t(!itemInCart ? 'buy_now' : 'remove_from_cart')}
                                disabled
                            />
                        ) : (
                        <Button
                            type="primary"
                            size="sm"
                            label={t(!itemInCart ? 'buy_now' : 'remove_from_cart')}
                            onClick={() => updateItem(props.item.id_product, !itemInCart ? props.item.product_step : 0)}
                            disabled={isLocked && !itemInCart}
                        />
                    )}
                </div>
            </div>


            {!!modalVisible && (
                <PlanItemModal
                    visible={modalVisible}
                    item={props.item}
                    onClose={() => setModalVisible(false)}
                />
            )}
        </div>
    )
}
