import {AsyncSelectController} from "../controllers";
import React, {useEffect} from "react";
import {useCountry, useTranslation} from "../../hooks";
import {getShops} from '../../actions/ShopActions';
import {useAsyncSelect} from "../hooks";
import {SelectOption} from "../../services";

export default function ShopSelect({groupId, regionId, city, ...props}) {
    const {t} = useTranslation()
    const country = useCountry()
    const asyncSelect = useAsyncSelect({
        name: 'shops',
        action: params => getShops(country.data.id_country, params),
        paramFunc: (slug) => ({
            search: slug,
            limit: 100,
            group: groupId,
            region: regionId,
            city,
        }),
        mapFunc: o => new SelectOption(country.formatShopLabel(o), o.id_shop, {
            shopGroupId: groupId,
        }),
        queryOptions: {
            enabled: !!groupId,
        },
        refetchOnChangeParams: [city],
    })

    useEffect(() => {
        if(groupId) {
            asyncSelect.query.refetch()
        }
    }, [groupId])

    return (
        <AsyncSelectController
            asyncSelect={asyncSelect}
            name="shop"
            label={t('retailer_store')}
            selectOptions={asyncSelect.query.isRefetching || !groupId ? [] : undefined}
            {...props}
            options={{
                autoSelectFirstIfOneOption: true,
                ...props.options,
            }}
        />
    )
}
