import {CustomerForm} from "../../../forms";
import {
    useCreateNespressoMember,
    useCustomer, useGTM,
    useModuleContext,
    useModuleStepsNavigation,
    useReseller, useSeoPage,
    useTranslation
} from "../../../hooks";
import {
    AllowedToLoginClearStorageResponseException,
    AllowedToLoginResponseException,
    MemberExistsResponseException,
    NotAllowedResponseException,
    PhoneBlockingRuleException,
    SmsValidationResponseException
} from "../../../errors";
import {usePromotionsContext} from "../../../hooks/useFetchPromotions";
import React, {useMemo} from "react";
import {useCountryConfig, useLocalizeFile, useMarketConfig} from "../../../misc/Hooks";
import {useSelector} from "react-redux";
import GTM from "../../../misc/GTM";

export default function CustomerScreen({customerFormProps, stayHomeId}) {
    useSeoPage({title: 'shop_customer_information.title'})
    const {t} = useTranslation()
    const {isLoggedIn} = useReseller()
    const marketConfig = useMarketConfig()
    const localizeFile = useLocalizeFile()
    const countryConfig = useCountryConfig()
    const {data: customer, nespressoMember} = useCustomer()
    const {promotion} = usePromotionsContext()
    const {finishCustomerInformationStep} = useGTM()
    const {store, storeName} = useModuleContext()
    const plan = useSelector(state => {
        return state[storeName].products?.find(p => {
            const item = state[storeName].cart?.items?.find(i => i.id === p.id_product);
            return item && p.product_type.startsWith('plan_')
        })
    })
    const {
        isLoading,
        status,
        error,
        setError,
        canRegisterAndCreateAccount,
        canAttempt,
        userCanLogin,
        loginFormVisible,
        smsPhoneVerification,
    } = useCreateNespressoMember()
    const stepsNavigation = useModuleStepsNavigation()
    const {privacyFileUrl, termsFileUrl} = useMemo(() => {
        return {
            termsFileUrl: localizeFile(plan?.product_files ?? promotion?.promotion_files, 'regulations'),
            privacyFileUrl: localizeFile(plan?.product_files ?? promotion?.promotion_files, 'privacy_policy')
                || localizeFile(countryConfig.files, 'privacy_policy') || localizeFile(marketConfig.files, 'privacy_policy')
        }
    }, [plan, promotion, localizeFile, countryConfig.files, marketConfig.files])

    const handleSubmit = async (data) => {
        const payload = {
            data,
            customData: {
                serial: store.serialNumber?.serial_number,
            },
            promotionId: promotion?.id_promotion,
            machinePlanId: plan?.id_product,
            stayHomeId,
        }


        if (nespressoMember) {
            await canAttempt({...payload, nespressoMember, customer})
                .then(() => {
                    finishCustomerInformationStep(false)
                    stepsNavigation.navToNext()
                })
                .catch(e => {
                    if (e instanceof PhoneBlockingRuleException) {
                        setError(e.error)
                        console.log('set error', e)
                        return
                    }
                    console.log(e)
                    setError(t('error.unknown_error'))
                })
            return
        }
        const account = await canRegisterAndCreateAccount(payload)
            .catch(e => {
                if (e instanceof AllowedToLoginResponseException) {
                    // display login form
                    console.log('display login form', e)
                    return
                }
                if (e instanceof AllowedToLoginClearStorageResponseException) {
                    // display login form
                    console.log('clear storage and redirect to auth step', e)
                    return
                }
                if (e instanceof NotAllowedResponseException
                    || e instanceof MemberExistsResponseException
                    || e instanceof PhoneBlockingRuleException) {
                    setError(e.error)
                    console.log('set error', e)
                    return
                }
                if (e instanceof SmsValidationResponseException) {
                    // display sms form
                    console.log('display sms form')
                    return;
                }
                console.log(e)
                setError(t(`error.${e.error}`) || t('error.unknown_error'))
            })

        if (account) {
            finishCustomerInformationStep(true, account.memberNumber)

            stepsNavigation.navToNext()
        }
    }

    return (
        <div>
            <CustomerForm
                state={{
                    isLoading,
                    status,
                    error,
                    loginFormVisible,
                    userCanLogin,
                }}
                options={{
                    paymentDetailsVisible: !countryConfig.payment_details_visable && isLoggedIn ? false : promotion?.promotion_show_payment_details,
                    ...customerFormProps,
                    benefitFields: {
                        phone: promotion?.promotion_marketing_phone_visible ?? plan?.promotion_marketing_phone_visible,
                        email: promotion?.promotion_marketing_email_visible ?? plan?.promotion_marketing_email_visible,
                        mobile: promotion?.promotion_marketing_mobile_visible ?? plan?.promotion_marketing_mobile_visible,
                        post: promotion?.promotion_marketing_post_visible ?? plan?.promotion_marketing_post_visible,
                        socialMedia: promotion?.promotion_marketing_social_media_visible ?? plan?.promotion_marketing_social_media_visible,
                        ...customerFormProps?.benefitFields,
                    },
                    benefitConfig: {
                        required: promotion?.promotion_marketing_required ?? plan?.promotion_marketing_required,
                        visibility: marketConfig.user_marketing_options_visibility,
                        selectAllVisible: promotion?.promotion_marketing_select_all_benefits_enabled ?? plan?.promotion_marketing_select_all_benefits_enabled,
                        ...customerFormProps?.benefitConfig,
                    },
                    termsOptions: {
                        termsAndConditionsFileUrl: termsFileUrl,
                        privacyPolicyFileUrl: privacyFileUrl,
                        ...customerFormProps?.termsOptions,
                    },
                }}
                nespressoMember={nespressoMember}
                smsPhoneVerification={smsPhoneVerification}
                prevRoute={stepsNavigation.prev()}
                onSubmit={handleSubmit}
            />
        </div>
    )
}
