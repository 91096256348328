import {useModuleContext} from "../../../hooks";
import {Config} from "../../../utils/_index";
import {checkPromoPrice, getImage, getPackageCapsulesLength, unlockByOptIn} from "../../../misc/Helpers";
import {usePriceFormatter} from "../../../misc/Hooks";




export default function useCart2(customData = null) {
    const {store, dispatch, reducer, storeName} = useModuleContext()
    const {formatPriceObj, totalProductPrice} = usePriceFormatter();

    const hideSubtitleTypes = [
        Config.COFFEE_TYPE.GIFT,
        Config.COFFEE_TYPE.EASY_ORDER_GIFT,
        Config.COFFEE_TYPE.DELIVERY,
        Config.COFFEE_TYPE.PLAN_COFFEE,
        Config.COFFEE_TYPE.PLAN_MACHINE,
        Config.COFFEE_TYPE.PLAN_WORK_HOME,
        Config.COFFEE_TYPE.REBATE,
        Config.COFFEE_TYPE.ACCESSORY,
    ]

    const hideRemoveButtonTypes = [
        Config.COFFEE_TYPE.GIFT,
        Config.COFFEE_TYPE.EASY_ORDER_GIFT,
        Config.COFFEE_TYPE.DELIVERY,
        Config.COFFEE_TYPE.REBATE
    ]

    const hideSummaryProductPriceTypes = [
        Config.COFFEE_TYPE.GIFT,
        Config.COFFEE_TYPE.EASY_ORDER_GIFT,
        Config.COFFEE_TYPE.DELIVERY,
        Config.COFFEE_TYPE.PLAN_MACHINE,
        Config.COFFEE_TYPE.PLAN_WORK_HOME,
        Config.COFFEE_TYPE.REBATE,
    ]

    const hidePlusMinusButtonTypes = [
        Config.COFFEE_TYPE.GIFT,
        Config.COFFEE_TYPE.EASY_ORDER_GIFT,
        Config.COFFEE_TYPE.DELIVERY,
        Config.COFFEE_TYPE.PLAN_COFFEE,
        Config.COFFEE_TYPE.PLAN_WORK_HOME,
        Config.COFFEE_TYPE.REBATE,
        Config.COFFEE_TYPE.PLAN_MACHINE,
    ]

    const orderData = customData ?? store

    const getCategories = () => {
        return Object.values(orderData.categories)
            .reduce((previousValue, currentValue, currentIndex, array) => {
                const existsInSubcategories = array.find(c => c.category?.subcategories?.find(sc => sc.id_category === currentValue.category?.id_category))
                if(!existsInSubcategories) {
                    previousValue.push(currentValue)
                }
                return previousValue
            }, [])
            .sort((a, b) => a.promotion_category_position < b.promotion_category_position ? -1 : 0)
            .sort(a => [Config.COFFEE_TYPE.PLAN_WORK_HOME].includes(a.category.category_type) ? -1 : 0)
    }

    const getProducts = (parentCat) => {
        const capsulesCount = {
            toBeDelivered: 0,
            toPickUp: 0,
        }
        let products = [];

        orderData.cart.items.map(i => {

            const prod = orderData.products.find(p => p.id_product === i.id
                && (
                    parentCat.products.find(p => p.id_product === i.id)
                    || parentCat.category.subcategories?.find(c => c.id_category === p.id_category)
                )
            )


            if (prod) {
                if (parentCat.category.category_type === Config.COFFEE_TYPE.PACKAGE) {
                    const qty = i.value * getPackageCapsulesLength(prod)
                    if(prod.product_local_stock) {
                        capsulesCount.toPickUp += qty;
                    } else {
                        capsulesCount.toBeDelivered += qty;
                    }
                } else {
                    const qty = i.value * prod.product_package_qty
                    if(prod.product_local_stock) {
                        capsulesCount.toPickUp += qty;
                    } else {
                        capsulesCount.toBeDelivered += qty;
                    }
                }

                products.push(prod);
            }

        })

        products = products.sort((a, b) => a.product_position < b.product_position ? -1 : 0)

        const toBeDelivered = products.filter(p => !p.product_local_stock)
        const toPickUp = products.filter(p => p.product_local_stock)

        return {
            products,
            toBeDelivered,
            toPickUp,
            capsulesCountToBeDelivered: capsulesCount.toBeDelivered,
            capsulesCountToPickUp: capsulesCount.toPickUp,
            capsulesCount: capsulesCount.toBeDelivered + capsulesCount.toPickUp,
        }
    }

    const categorySubtitleVisible = (cat) => {
        return !hideSubtitleTypes.includes(cat.category.category_type)
    }

    const getProduct = (product, parentCat) => {
        const itemInCart = orderData.cart.items.find(i => i.id === product.id_product);

        const hasPromoPrice = checkPromoPrice(
            parseInt(product.product_price_promo?.status),
            product.product_price_promo?.date_from,
            product.product_price_promo?.date_to,
        );

        const promotionProduct = parentCat.products?.find(p => p.id_product === product.id_product)
            ?.promotion_product

        const optInConfig = unlockByOptIn(promotionProduct, parentCat)

        let price = product.product_price;
        let productTotalOldPrice = null

        if (parentCat.category.category_type.startsWith('plan_')) {
            price = product.product_plan_price;
        }

        if (hasPromoPrice) {
            price = product.product_price_promo
            productTotalOldPrice = totalProductPrice(product.product_price, itemInCart.value * product.product_package_qty)
        }

        const productPrice = product.product_percentage_rebate ? (
            `-${Number(product.product_percentage_rebate).toFixed(0)}%`
        ) : formatPriceObj(price)

        const productTotalPrice = totalProductPrice(price, itemInCart.value * product.product_package_qty)

        let image = getImage(product.product_gallery)
        if(product.product_type === Config.COFFEE_TYPE.PLAN_WORK_HOME)
            image = getImage(store.planMachine?.machine_gallery ?? product.product_gallery)


        return {
            image,
            optInConfig,
            productTotalOldPrice,
            productTotalPrice,
            productPrice,
            itemInCart
        }
    }

    return {
        getCategories,
        getProducts,
        categorySubtitleVisible,
        getProduct,
        store,
        storeName,
        hideRemoveButtonTypes,
        hideSummaryProductPriceTypes,
        hidePlusMinusButtonTypes
    }
}

