import {InputSpinner} from "../../../forms/components";
import {MaskInputController} from "../../../forms/controllers";
import React, {useEffect, useMemo, useState} from "react";
import {useFieldFormat, useFormHelper, useModuleContext, useTranslation} from "../../../hooks";
import {useMutation} from "@tanstack/react-query";
import * as machineActions from '../../../actions/MachineActions';
import {ApiException} from "../../../errors";
import {useWatch} from "react-hook-form";

export default function TpsSerialNumber(){

    const {t} = useTranslation()
    const {formMethods} = useFormHelper()
    const {dispatch, store, reducer} = useModuleContext()
    const {formats, getIfValid} = useFieldFormat()
    const styles = useMemo(() => ({
        input: {
            letterSpacing: 1,
        },
    }), [])

    const validateMutation = useMutation(
        async (serialNumber) => {
            const {data} = await machineActions.validateSerialNumberTps({serial_number: serialNumber})
            if (data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        {
            onMutate: () => {
                formMethods.clearErrors('serialNumber')
            },
            onSuccess: (data) => {
                dispatch(reducer.setMachineData(data))
                formMethods.setValue('machine', new Option(data.machine.machine_title, data.machine.id_machine))
                formMethods.setValue('machineSku', data.machine.machine_sku_number)
                formMethods.clearErrors('machine')
                //TODO: set machine, machine local code
            },
            onError: ({error}, payload) => {
                formMethods.setError('serialNumber', {type: 'invalid', message: t(`error.${error}`) || t('error.unknown_error')})
            },
        })

    const serialNumber = useWatch({control: formMethods.control, name: 'serialNumber'})

    useEffect(() => {
        const value = getIfValid(serialNumber, formats.serialNumber.pattern)
        if(value)
            validateMutation.mutate(serialNumber)
    }, [serialNumber])


    return (
        <MaskInputController
            name="serialNumber"
            mask={formats.serialNumber.getMask()}
            label={t('serial_number')}
            options={{
                inputProps: {
                    style: styles.input,
                },
                renderIcon: () => {
                    if (validateMutation.isLoading) {
                        return <InputSpinner/>
                    }
                },
            }}
        />
    )
}