import {InputLabel, InvalidFeedback} from "../components";
import {useInputValidationClassNames} from "../hooks";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";
import React, {useState} from "react";

/**
 * TextInput
 * @param inputRef
 * @param fieldState
 * @param name
 * @param label
 * @param value
 * @param {{inputProps, isRequired: boolean, isLoading: boolean, maxLength: number, renderIcon}} options
 * @param onChange
 * @param onBlur
 * @returns {JSX.Element}
 * @constructor
 */
export default function PasswordInput({
    inputRef,
    fieldState,
    name,
    label,
    value,
    options,
    onChange,
    onBlur,
}) {
    options = {...defaultOptions, ...options}
    const [showPassword, setShowPassword] = useState(false)
    const {className} = useInputValidationClassNames({
        value,
        fieldState,
        params: {
            '-icons': !!value,
        },
        deps: [value],
    })

    const handleChange = (e) => {
        const value = e.target.value
        if (options.maxLength && value?.toString().length > options.maxLength) {
            return
        }
        onChange(value)
    }

    const togglePasswordVisibility = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setShowPassword(prev => !prev)
    }

    return (
        <fieldset className={className}>
            <div className="fieldset__wrapper">
                <input
                    className="fieldset__input"
                    ref={inputRef}
                    id={name}
                    type={showPassword ? 'text' : 'password'}
                    name={name}
                    placeholder={label}
                    value={value}
                    onChange={handleChange}
                    onBlur={onBlur}
                    {...options.inputProps}
                />
                {!!value && (
                    <div className="fieldset__icons">
                        <button
                            type="button"
                            // className="formGroup__passwordVisibility"
                            onClick={togglePasswordVisibility}
                        >
                            {!options.inputProps?.disabled && (
                                showPassword ? <AiOutlineEyeInvisible/> : <AiOutlineEye/>
                            )}
                        </button>
                    </div>
                )}
                <InputLabel
                    name={name}
                    value={value}
                    label={label}
                    options={options}
                />
            </div>
            <InvalidFeedback error={fieldState.error}/>
        </fieldset>
    )
}

const defaultOptions = {
    inputProps: null,
    isRequired: false,
    isLoading: false,
    maxLength: 0,
    renderIcon: null,
}
