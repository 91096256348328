import Form from "./Form";
import {useForm} from "react-hook-form";
import {useFormRulesBuilder} from "./hooks";
import {FormGroup} from "./components";
import {EmailInput, LangSelect} from "./presets";
import {Button} from "../components/buttons/_index";
import {useModuleContext, useTranslation} from "../hooks";
import sanitizeText from "../helpers/sanitizeText";
import React, {useEffect, useMemo} from "react";
import * as stayHomeReducer from "../redux/stayHomeReducer";
import {useSelector} from "react-redux";
import {TextInput} from "./inputs";
import {TextInputController} from "./controllers";

export default function StayHomeAuthForm({email, state, onSubmit}) {
    state = {
        isLoading: false,
        error: null,
        ...state,
    }
    const {t} = useTranslation()
    const stayHomeConfig = useSelector(({main}) => main.stayHomeConfig)
    const {store, reducer, dispatch} = useModuleContext()
    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            email: email ?? '',
            promoCode: store.promoCode?.promo_code_name,
        }
    })
    const formRules = useFormRulesBuilder({
    }, formMethods)
    const bothEnabled = useMemo(() => {
        return Number(stayHomeConfig.stay_home_email) && Number(stayHomeConfig.stay_home_promocode)
    }, [stayHomeConfig])

    useEffect(() => {
        if (state.error) {
            if (store.withEmail) {
                formMethods.setError('email', {type: 'invalid', message: t(`error.${state.error}`)})
            } else {
                formMethods.setError('promoCode', {type: 'invalid', message: t(`validation.${state.error}`)})
            }
        } else {
            formMethods.clearErrors(store.withEmail ? 'email' : 'promoCode');
        }
    }, [state.error])

    const toggleWithEmail = () => {
        formMethods.setValue('email', '')
        formMethods.setValue('promoCode', '')
        dispatch(reducer.setTokenAndClearStore(null))
        dispatch(reducer.setWithEmail(!store.withEmail))
    }

    return store.withEmail ? (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            state={{
                isDisabled: state.isLoading,
            }}
            onSubmit={onSubmit}
        >
            {bothEnabled ? (
                <div className="par-2">
                    <span {...sanitizeText(t('stay_home.identification.email_info_prefix'))}/>
                    <button
                        type="button"
                        className="link -parentSize --brown"
                        {...sanitizeText(t('stay_home.identification.email_info_button'))}
                        onClick={toggleWithEmail}
                    />
                    <span {...sanitizeText(t('stay_home.identification.email_suffix'))}/>
                </div>
            ) : (
                <div
                    className="par-2"
                    {...sanitizeText(t('stay_home.identification.email_info'))}
                />
            )}

            <FormGroup>
                <EmailInput
                    label={t('stay_home.identification.email_placeholder')}
                />
            </FormGroup>

            <Button
                btnType="submit"
                label={t('continue')}
                disabled={state.isLoading}
                isLoading={state.isLoading}
            />
        </Form>
    ) :(
        <Form
            formMethods={formMethods}
            formRules={formRules}
            state={{
                isDisabled: state.isLoading,
            }}
            onSubmit={onSubmit}
        >
            {bothEnabled ? (
                <div className="par-2">
                    <span {...sanitizeText(t('stay_home.identification.promo_code_info_prefix'))}/>
                    <button
                        type="button"
                        className="link -parentSize --brown"
                        {...sanitizeText(t('stay_home.identification.promo_code_info_button'))}
                        onClick={toggleWithEmail}
                    />
                    <span {...sanitizeText(t('stay_home.identification.promo_code_info_suffix'))}/>
                </div>
            ) : (
                <div
                    className="par-2"
                    {...sanitizeText(t('stay_home.identification.promo_code_info'))}
                />
            )}

            <FormGroup>
                <TextInputController
                    name="promoCode"
                    label={t('stay_home.identification.promo_code_placeholder')}
                />
            </FormGroup>

            <Button
                btnType="submit"
                label={t('continue')}
                disabled={state.isLoading}
                isLoading={state.isLoading}
            />
        </Form>
    )
}
