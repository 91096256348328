import {useDispatch, useSelector} from "react-redux";
import * as mainReducer from "../redux/mainReducer";
import {resellerRoles, rolePermissions} from "../misc/PermissionsConfig";
import * as machineRegistrationReducer from "../redux/machineRegistrationReducer";
import * as subscriptionReducer from "../redux/subscriptionReducer";
import * as resellerReducer from "../redux/resellerReducer";

export default function useReseller() {
    const dispatch = useDispatch()
    const store = useSelector(({main, reseller}) => ({
        user: main.user,
        userToken: main.userToken,
        reseller: reseller
    }))

    const isNespressoWorker = () => store.user?.reseller_role === 'nca_user'
        || store.user?.reseller_role === 'nca_super_user'

    const logout = () => {
        dispatch(machineRegistrationReducer.clearStore())
        dispatch(subscriptionReducer.clearStore())
        dispatch(resellerReducer.clearStore())
        dispatch(mainReducer.clearUser())
    }

    const hasPermission = (permission) => {

        if (typeof permission === "undefined") return true;

        const role = Object.keys(resellerRoles).find(r => resellerRoles[r] === store.user?.reseller_role)
        const permissions = rolePermissions[role];

        if (!permissions) return false

        return permissions.includes(permission)
    }

    const getShop = () => {
        const id = store.reseller.shop
        return store.user.reseller_shops.find(s => s.id_shop === id)
    }

    const isPopRequired = () => {
        const shop = getShop()
        return !!shop?.assisted_order_flow
    }

    return {
        user: store.user,
        reseller: store.reseller,
        isNespressoWorker: isNespressoWorker(),
        isLoggedIn: !!store.user,
        logout,
        hasPermission,
        getShop,
        shopId: store.reseller.shop,
        isPopRequired,
        role: store?.user?.reseller_role
    }
}
