import React from 'react';
import BaseModal from "./BaseModal";
import {useTranslation} from "../../misc/Hooks";

const RecaptchaKeyNotFoundModal = (props) => {
    const t = useTranslation();


    return (
        <BaseModal
            visible={props.visible}
            onClose={props.onClose}
        >
            <div className="modal__header">
                <h3
                    className="modal__title"
                    dangerouslySetInnerHTML={{__html: t('modal.recaptcha_key_not_found.title')}}
                />
            </div>

            <div className="modal__body">
                <div className="getInTouchModal__body">
                    <p
                        className="par-2 --mb4"
                        dangerouslySetInnerHTML={{__html: t('modal.recaptcha_key_not_found.text')}}
                    />
                </div>

            </div>

        </BaseModal>
    );
}


export default RecaptchaKeyNotFoundModal;
