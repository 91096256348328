import {useEffect} from "react";
import {scrollVertically} from "../../misc/Helpers";

export default function useFocusError(formElement, formMethods) {

    useEffect(() => {
        const {errors} = formMethods.formState
        if(!errors || !Object.keys(errors).length)
            return
        const errorElement = formElement.querySelector('.-isInvalid')
        const input = errorElement?.querySelector('input')
        if(input) {
            scrollVertically(errorElement, 5)
            input.focus()
        }
    }, [formMethods.formState.isSubmitting])

}
