import {getImage} from "../../../misc/Helpers";
import React from "react";


export default function ShopLogo({gallery, url}) {
    const imageUrl = getImage(gallery)

    if(!imageUrl)
        return null

    const Component = () => (
        <img src={imageUrl} alt="Nespresso" />
    )

    return url ? (
        <a href={url} target="_blank" className="header__logo -secondary"><Component/></a>
    ) : (
        <span className="header__logo -secondary"><Component/></span>
    )
}