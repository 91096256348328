import {useEffect, useRef, useState} from "react";
import * as mainActions from '../../../actions/MainActions';
import {useTranslation} from "../../../misc/Hooks";
import {useSelector} from "react-redux";


export default function useFetchBank(storeName) {
    const mountedRef = useRef(false)
    const t = useTranslation()
    const store = useSelector(state => ({
        uuid: state[storeName]?.uuid,
    }))
    const [bank, setBank] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        mountedRef.current = true
        return () => mountedRef.current = false
    }, [])

    const fetchBank = async (accountNumber) => {
        if (!accountNumber) return
        setLoading(true)
        setError(null)
        let bankBranch = accountNumber
        switch (process.env.REACT_APP_MARKET) {
            case 'nl':
            case 'pt':
                bankBranch = accountNumber.match(/^PT50 [0-9]{4}/)
                if(bankBranch) {
                    bankBranch = bankBranch[0]?.split(' ')?.reverse()[0];
                }
                break
        }
        if (bankBranch) {
            const payload = {
                uuid: store.uuid,
                iban: accountNumber,
                bsb_number: bankBranch,
                marketCode: process.env.REACT_APP_MARKET,
            }
            return await mainActions.getBank(payload)
                .then(r => {
                    const res = r.data;
                    if (res.status === 'success') {
                        if (mountedRef.current) {
                            setBank(res.data)
                            return res.data
                        }
                    } else {
                        if (mountedRef.current) {
                            setBank(null)
                            setError(t('validation.account_number_invalid'))
                            return null
                        }
                    }
                })
                .catch(err => {
                    console.error('GetBankByBSBNumber', err)
                })
                .finally(() => {
                    if(mountedRef.current)
                        setLoading(false)
                })
        }
    }

    return {
        bank,
        loading,
        error,
        fetchBank,
    }
}
