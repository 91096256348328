import {useEffect, useMemo} from "react";
import {getDateTime} from "../../../../misc/Helpers";


export default function AttachmentItem({item, ...props}) {
    const previewAvailable = useMemo(() => {
        return !!item.thread_message_attachment_type.match(/^image/)
    }, [item])

    return (
        <div className="commentAttachment">
            <a
                href={item.thread_message_attachment_url}
                className={`commentAttachment__thumb ${previewAvailable ? '-preview' : ''}`}
                target="_blank"
            >
                <img
                    src={previewAvailable ? item.thread_message_attachment_url : require('../../../../assets/img/icons/file-solid.svg').default}
                    alt=""
                />
            </a>
            <a
                href={item.thread_message_attachment_url}
                className="commentAttachment__content"
                target="_blank"
            >
                <p className="commentAttachment__name" >{item.thread_message_attachment_name?.toString().substring(0, 18)}...</p>
                <p className="commentAttachment__date">{getDateTime(item.updated_at)}</p>
            </a>
        </div>
    )
}
