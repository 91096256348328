import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useResellerActions} from "../../../actions/useResellerActions";
import React, {useEffect, useMemo} from "react";
import {Preloader} from "../../../misc/_index";
import {useTranslation} from "../../../hooks";
import {DataCell} from "../../../components/Summary/components";
import {getDateTime, getImage} from "../../../misc/Helpers";
import {Button} from "../../../components/buttons/_index";
import {ApiException} from "../../../errors";

export default function FinalizeOrderScreen() {
    const {t} = useTranslation()
    const resellerActions = useResellerActions()
    const params = useParams()
    const query = useQuery({
        queryKey: ['reseller.finalizeOrder', params.uuid],
        queryFn: async () => {
            return await resellerActions.finalizeOrder(params.uuid)
                .then(res => {
                    if(res.response) {
                        throw new ApiException(res.response?.data?.message, res.response?.data)
                    }
                    return res.data?.data
                })
        },
        refetchOnWindowFocus: false,
    })
    const mutation = useMutation({
        mutationFn: async () => {
            return await resellerActions.deliverOrder({uuid: params.uuid})
                .then(res => {
                    if(res.response) {
                        throw new ApiException(res.response?.data?.message, res.response?.data)
                    }
                    return res.data?.data
                })
        }
    })
    const {data, itemsToPickup, itemsToDeliver} = useMemo(() => {
        const data = query.data

        const items = data?.items
            ?.sort((a,b) => a.product_title.localeCompare(b.product_title)) ?? []

        const itemsToDeliver = items.filter(i => !i.order_item_local_stock) ?? []
        const itemsToPickup = items.filter(i => i.order_item_local_stock) ?? []

        return {
            data,
            itemsToDeliver,
            itemsToPickup,
        }
    }, [query.status])

    return (
        <div className="wrapper">
            {query.isLoading ? (
                <Preloader
                    message={t('loading')}
                />
            ) : query.isError ? (
                <div>
                    <p className="--error">{t('error.unknown_error')}</p>
                </div>
            ) : query.isSuccess ? (
                <div>
                    <div className="grid --mb4">
                        <div className="editArea">
                            <h1>#{data.id_order}</h1>
                            <p>
                                {t('reseller_finalize_order.order_placed_at')}: <b>{getDateTime(data.created_at)}</b>
                                {!!data.paid_at && (
                                    <>
                                        <br/>{t('reseller_finalize_order.order_paid_at')}: <b>{getDateTime(data.paid_at)}</b>
                                    </>
                                )}
                            </p>
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <div className="--mb2">
                                <Button
                                    label={mutation.isSuccess || data.order_picked_up ? t('reseller_finalize_order.delivered') : t('reseller_finalize_order.submit_pickup_button')}
                                    isLoading={mutation.isLoading}
                                    disabled={mutation.isLoading || mutation.isSuccess || data.order_picked_up}
                                    onClick={mutation.mutate}
                                />
                            </div>
                            {mutation.isError && (
                                <p className="--error">{t('reseller_finalize_order.error_on_deliver')}</p>
                            )}
                        </div>
                    </div>
                    <div className="grid">
                        <div className="--mb4">
                            <p className="par-7 --bold">{t('reseller_finalize_order.customer_details')}</p>
                            <hr className="-light --mb2_5"/>
                            <div className="grid">
                                <DataCell
                                    label={t('name')}
                                    value={data.delivery_firstname}
                                />
                                <DataCell
                                    label={t('surname')}
                                    value={data.delivery_lastname}
                                />
                            </div>
                            <DataCell
                                label={t('email')}
                                value={data.delivery_email}
                            />
                        </div>
                        <div>
                            <p className="par-7 --bold">{t('reseller_finalize_order.products_to_pickup')}</p>
                            <div className="--mb3">
                                {itemsToPickup?.map((i, idx) => (
                                    <ProductItem key={idx} product={i}/>
                                ))}
                            </div>
                            <p className="par-7 --bold">{t('reseller_finalize_order.products_to_be_delivery')}</p>
                            {itemsToDeliver?.map((i, idx) => (
                                <ProductItem key={idx} product={i}/>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div>...?</div>
            )}
        </div>
    )
}

function ProductItem({product}) {
    const {t} = useTranslation()
    const image = getImage(product.product_gallery)

    return (
        <div className="summaryItemNew">
            <div className="summaryItemNew__thumb">
                <img src={image} alt={product.product_title}/>
            </div>
            <div className="summaryItemNew__content">
                <div className="summaryItemNew__header">
                    <h4 className="summaryItemNew__title">
                        {product.product_title} <small className="--brown">({product.product_code})</small>
                    </h4>
                </div>
                <div className="summaryItemNew__footer">
                    <div className={`summaryItemNew__priceWrapper -oneLine`}>
                        <div/>
                        <div className="summaryItemNew__price --normal">
                            {t('reseller_finalize_order.quantity')}: {product.order_item_qty}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
