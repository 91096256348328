import {useReuploadPop, useTranslation} from "../hooks";
import {AppProvider, Preloader} from "../misc/_index";
import {Footer, Header} from "../components/misc/_index";
import InfoComponent from "../components/infoComponent/InfoComponent";
import {Form} from "../forms";
import FileDropzone from "../forms/presets/FileDropzone";
import {useFormRulesBuilder} from "../forms/hooks";
import {useForm, useWatch} from "react-hook-form";
import {Button} from "../components/buttons/_index";
import {FormGroup} from "../forms/components";
import {useEffect} from "react";

export default function ReuploadPopScreen(){
    const {orderInfoMutation, reuploadPopMutation} = useReuploadPop()
    const {t} = useTranslation()

    const formMethods = useForm({
        mode: 'onChange',
    })

    const formRules = useFormRulesBuilder({
        file: b => b.required()
    }, formMethods)

    const file = useWatch({
        control: formMethods.control,
        name: 'file',
    })

    return (
        <AppProvider>
            <div className="app">
                <Header cartDisabled/>
                <div className="main">
                    <div className="wrapper-bg formContainer">
                        <div className="wrapper">
                            <div className="grid">
                                <div>
                                    <div className="--mb4">
                                        {orderInfoMutation.isSuccess ? (
                                            <>
                                                <InfoComponent
                                                    message={t('upload_pop.order_id', orderInfoMutation.data.id_order)}
                                                    type="info"
                                                    liteShadow
                                                />
                                                {(!reuploadPopMutation.data && !reuploadPopMutation.isSuccess) ? (
                                                    <Form
                                                        formMethods={formMethods}
                                                        formRules={formRules}
                                                        onSubmit={reuploadPopMutation.mutate}
                                                    >
                                                        <FormGroup>
                                                            <FileDropzone
                                                                rules={formRules.values.file}
                                                            />
                                                        </FormGroup>
                                                        <Button
                                                            label={t('upload')}
                                                            btnType={"submit"}
                                                            disabled={!file || reuploadPopMutation.isLoading}
                                                            isLoading={reuploadPopMutation.isLoading}
                                                        />
                                                    </Form>
                                                ) : (
                                                    <InfoComponent message={t('pop_reupload_success')}/>
                                                    )}
                                                </>
                                        ) : (
                                            <Preloader message={t('checking_order')}/>
                                        )}
                                        {reuploadPopMutation.isError && (
                                            <InfoComponent message={t('pop_reupload_error')} type="error"/>
                                            )}
                                    </div>
                                </div>
                                <div/>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer/>
            </div>
        </AppProvider>
    )
}