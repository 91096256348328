import {getDefaultCivility} from "../utils/MarketConfig";
import getObjectValue from "../helpers/getObjectValue";

export function NespressoMember({
    memberNumber,
    email,
    token,
    consents,
    hasSubscription,
    addressId,
    customAddressId,
    rawData,
    firstName,
    lastName,
    preferredDeliveryMethod,
    preferredPaymentMethod,
    defaultPaymentMethod,
    defaultDeliveryMethod,
}) {
    return {
        memberNumber,
        email,
        token,
        consents,
        hasSubscription,
        addressId,
        customAddressId,
        rawData,
        firstName,
        lastName,
        preferredDeliveryMethod,
        preferredPaymentMethod,
        defaultPaymentMethod,
        defaultDeliveryMethod,
    }
}

function NespressoMemberPayload({
    recaptchaToken,
    countryId,
    lang,
    details,
    customAddress,
    withCustomAddress,
    paymentDetails,
    benefits,
    terms,
    uuid,
    countryShortcode,
}) {
    return {
        customer: {
            id_country: countryId,
            lang,
            // address_id: customer.memberAddress?.value ?? module.orderAccount?.order?.address_id ?? null,
            delivery_is_company: details?.isCompanyAddress ? 1 : 0,
            delivery_company_name: details?.companyName ?? '',
            delivery_company_tax_id: (details?.isCompanyAddress ? details?.companyTaxId : details?.taxId) ?? '',
            delivery_company_irs: '',
            delivery_company_profession: details?.profession ?? null,
            delivery_civility: details?.title ?? 0,
            delivery_firstname: details?.firstname ?? '',
            delivery_lastname: details?.lastname ?? '',
            delivery_zipcode: getObjectValue(details?.postcode, 'label') ?? '',
            delivery_city: getObjectValue(details?.city, 'label') ?? '',
            delivery_address1: details?.addressLine1 ?? '',
            delivery_address2: details?.addressLine2 ?? '',
            delivery_region: details?.region ?? null,
            delivery_country: details?.country ?? '',
            delivery_phone: details?.phone ?? '',
            delivery_phone_mobile: details?.phoneMobile ?? '',
            delivery_phone_home: details?.phoneHome ?? '',
            delivery_email: details?.email ?? '',

            // other_address_id: module.orderAccount?.order?.other_address_id ?? null,
            other_delivery: withCustomAddress ? 1 : 0,
            other_delivery_is_company: customAddress?.isCompanyAddress ? 1 : 0,
            other_delivery_company_name: customAddress?.companyName ?? '',
            other_delivery_company_tax_id: (customAddress?.isCompanyAddress ? customAddress?.companyTaxId : customAddress?.taxId) ?? '',
            other_delivery_civility: customAddress?.title,
            other_delivery_firstname: customAddress?.firstname ?? '',
            other_delivery_lastname: customAddress?.lastname ?? '',
            other_delivery_zipcode: getObjectValue(customAddress?.postcode, 'label') ?? '',
            other_delivery_city: getObjectValue(customAddress?.city, 'label') ?? '',
            other_delivery_address1: customAddress?.addressLine1 ?? '',
            other_delivery_address2: customAddress?.addressLine2 ?? '',
            other_delivery_region: customAddress?.region ?? null,
            other_delivery_country: customAddress?.country ?? '',
            other_delivery_phone: customAddress?.phone ?? '',
            other_delivery_phone_mobile: customAddress?.phoneMobile ?? '',
            other_delivery_phone_home: customAddress?.phoneHome ?? '',
            other_delivery_email: customAddress?.email ?? '',

            payment_bsb_number: paymentDetails?.bsbNumber ?? null,
            payment_account_number: paymentDetails?.accountNumber ?? null,
            payment_data_confirmed: paymentDetails?.isAccountNumberConfirmed ? 1 : 0,

            // id_subscription_bank: customer.paymentMethodCodeVerify?.bankId,
            // bank_account_number: customer.paymentMethodCodeVerify?.accountNumber,

            regulations: terms?.termsAndConditions ? 1 : 0,
            privacy_policy: terms?.privacyPolicy ? 1 : 0,
            privacy_policy_use_of_personal_data_for_marketing: terms?.privacyPolicyUseOfPersonalDataForMarketing ? 1 : 0,
            privacy_policy_personalized_management: terms?.privacyPolicyPersonalizedManagement ? 1 : 0,
            privacy_policy_use_satisfaction_research: terms?.privacyPolicyUseSatisfactionResearch ? 1 : 0,

            marketing_email: benefits?.email ? 1 : 0,
            marketing_mobile: benefits?.mobile ? 1 : 0,
            marketing_phone: benefits?.phone ? 1 : 0,
            marketing_post: benefits?.post ? 1 : 0,
            marketing_social_media: benefits?.socialMedia ? 1 : 0,
            
            member_password: details?.password,
            uuid,
        },
        recaptcha_token: recaptchaToken,
    }
}

function Consents({
    phone,
    email,
    mobile,
    post,
    socialMedia,
}) {
    return {
        phone,
        email,
        mobile,
        post,
        socialMedia,
    }
}

export function nespressoMemberFromLoginData(data, custom) {
    if(!data) return null
    return new NespressoMember({
        memberNumber: data.member_id,
        token: data.token,
        email: custom.email,
        consents: new Consents({
            phone: !!data.consents?.contactByPhone,
            email: !!data.consents?.contactByEmail,
            post: !!data.consents?.contactByPost,
            mobile: !!data.consents?.mobileNotification,
            socialMedia: !!data.consents?.socialMedia,
        }),
        hasSubscription: !!data.has_subscription,
        rawData: data,
        firstName: data.first_name,
        lastName: data.last_name,
        preferredDeliveryMethod: data.preferred_delivery_method,
        preferredPaymentMethod: data.preferred_payment_method,
        defaultPaymentMethod: data.default_payment_method,
        defaultDeliveryMethod: data.default_delivery_method,
    })
}

export function nespressoMemberFromCreateAccount(data, custom = {}) {
    if(!data) return null
    return new NespressoMember({
        memberNumber: data.member_number,
        token: data.jwtToken,
        email: custom.email,
        consents: new Consents({
            phone: !!data.order?.marketing_phone,
            email: !!data.order?.marketing_email,
            post: !!data.order?.marketing_post,
            mobile: !!data.order?.marketing_mobile,
            socialMedia: !!data.order?.marketing_social_media,
        }),
        hasSubscription: false,
        rawData: data,
    })
}

export function nespressoMemberPayloadFromCustomerForm({
    data,
    countryId,
    countryShortcode,
    lang,
    uuid,
}) {
    if(!data) return null
    if(!data.details) return null
    return new NespressoMemberPayload({
        countryId,
        recaptchaToken: data.recaptchaToken,
        details: {
            ...data.details,
            lang: data.details?.lang?.value || lang,
            title: typeof data.details?.title === 'object' ? Number(data.details.title.value) : data.details?.title ?? getDefaultCivility(),
            profession: data.details?.profession?.value,
            region: data.details?.region?.value,
            country: data.details?.country?.value || countryShortcode,
        },
        customAddress: {
            ...data.customAddress,
            title: typeof data.customAddress?.title === 'object' ? Number(data.customAddress.title.value) : data.customAddress?.title ?? getDefaultCivility(),
            profession: data.customAddress?.profession?.value,
            region: data.customAddress?.region?.value,
            country: data.details?.customAddress?.value || countryShortcode,
        },
        withCustomAddress: data.withCustomAddress,
        paymentDetails: data.paymentDetails,
        benefits: data.benefits,
        terms: data.terms,
        uuid: data.uuid || uuid,
    })
}

export function paymentDetailsPayloadFromCustomerForm({data, nespressoMember, uuid}) {
    if(!data || !nespressoMember) return null
    return {
        customer: {
            member_number: nespressoMember.memberNumber,
            payment_bsb_number: data.paymentDetails?.bsbNumber ?? null,
            payment_account_number: data.paymentDetails?.accountNumber ?? null,
            payment_data_confirmed: data.paymentDetails?.isAccountNumberConfirmed ? 1 : 0,
            uuid,
        },
    }
}
