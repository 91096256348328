import React, {useEffect, useRef, useState} from 'react';
import {AiOutlineCheck, AiOutlineClose} from "react-icons/ai";


export default function MySwitch(props) {
    const inputRef = useRef();
    const [isFocused, setFocused] = useState(false);

    useEffect(() => {
        // console.log(!!props.error)
    }, [props.error])


    const handleChange = (e) => {
        if (['A', 'BUTTON'].includes(e.target.nodeName)) {
            return
        }

        if (props.inputRef) {
            props.inputRef.current.click()
        } else {
            inputRef.current?.click();
        }
    }


    let formGroupClassNames = '';

    if (props.error) {
        formGroupClassNames += ' -invalid';
    }

    let formInputClassNames = '';
    if (props.value === null) {
        formInputClassNames += ' -notSelected';
    } else if (props.value) {
        formInputClassNames += ' -true';
    } else {
        formInputClassNames += ' -false';
    }

    return (
        <div className={`formSwitch formCheckbox ${formGroupClassNames}`}>

            <div className="formSwitch__field">
                <input
                    ref={inputRef}
                    type="checkbox"
                    id={props.name ?? ''}
                    name={props.name ?? ''}
                    checked={props.value}
                    onChange={e => props.onChange(e.target.checked)}
                    hidden
                />

                <div
                    className="formSwitch__input"
                    data-checked={props.value}
                    onClick={(e) => props.value === null ? undefined : handleChange(e)}
                >
                    <div className={`formSwitch__status ${formInputClassNames}`}>
                        <div className="formSwitch__check"><AiOutlineCheck/></div>
                        <div className="formSwitch__risti"><AiOutlineClose/></div>
                    </div>
                    {props.value === null && (
                        <>
                            <div className="formSwitch__inputBox -false" onClick={() => props.onChange(false)}/>
                            <div className="formSwitch__inputBox -true" onClick={() => props.onChange(true)}/>
                        </>
                    )}
                </div>

                <label onClick={!props.disableLabelClick ? handleChange : undefined}>
                    {props.label ?? props.children}
                </label>
            </div>

            {!!props.error && (
                <span className="formGroup__invalidFeedback">{props.error.message}</span>
            )}
        </div>
    );
}
