import {useCurrentLanguage, useFieldFormat, useSerialNumber, useTranslation} from "../hooks";
import {useForm, useWatch} from "react-hook-form";
import {Form} from "./index";
import {FormGroup, FormRow} from "./components";
import {useFormRulesBuilder} from "./hooks";
import React, {useEffect, useMemo} from "react";
import {EmailInput, SerialNumberInput} from "./presets";
import {Button, ButtonClose} from "../components/buttons/_index";

export default function CreateFastOrderForm({
    onSubmit,
    state,
}){
    state = {
        isLoading: false,
        error: null,
        ...state,
    }

    const {t} = useTranslation();
    const {mutation, fileMutation, machine, fileData, isLoading, error, logs} = useSerialNumber()
    const {formats, getIfValid} = useFieldFormat()
    const formMethods = useForm({
        mode: 'onChange',
    })
    const formRules = useFormRulesBuilder({
        serialNumber: b => b.required()
            .pattern(formats.serialNumber.pattern, t('error.serialnumber_to_short'))
            .validate(v => machine)
    }, formMethods)



    const serialNumber = useWatch({
        control: formMethods.control,
        name: 'serialNumber',
    })
    const file = useWatch({
        control: formMethods.control,
        name: 'serialNumberFile',
    })
    const email = useWatch({
        control: formMethods.control,
        name: 'email',
    })
    const fileUrl = useMemo(() => {
        if (file instanceof File)
            return URL.createObjectURL(file)
        if (typeof file === 'string')
            return file
        return undefined
    }, [file])

    useEffect(() => {
        const value = getIfValid(serialNumber, formats.serialNumber.pattern)
        if (value) {
            mutation.mutate({serialNumber})
        }
    }, [serialNumber])

    useEffect(() => {
        if (file instanceof File) {
            formMethods.setValue('serialNumber', undefined)
            fileMutation.mutate({file})
        }
    }, [file])

    useEffect(() => {
        if (fileData) {
            formMethods.setValue('serialNumber', fileData.serial_number, {shouldDirty: true})
        }
    }, [fileData])

    useEffect(() => {
        if (error) {
            formMethods.setError('serialNumber', {type: 'invalid', message: error})
        } else {
            formMethods.clearErrors('serialNumber')
        }
    }, [error])


    useEffect(() => {
        if(serialNumber) {
            formMethods.trigger('serialNumber')
        }
    }, [machine])

    const handleRemoveFile = () => {
        formMethods.setValue('serialNumberFile', undefined)
    }




    const handleSubmit = (data) => {
        if (typeof onSubmit === 'function')
            onSubmit(data)
    }

    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            onSubmit={handleSubmit}
        >
            <FormRow>
                <div>
                    <FormGroup>
                        <SerialNumberInput isLoading={isLoading}/>
                    </FormGroup>
                    <FormGroup>
                        <EmailInput/>
                    </FormGroup>
                </div>
                <FormGroup>
                    {!!file && (
                        <div className="thumb__box">
                            <ButtonClose
                                positionClass="-topRight"
                                onClick={handleRemoveFile}
                            />
                            <img src={fileUrl} alt="" className="thumb__img"/>
                        </div>
                    )}
                </FormGroup>
            </FormRow>

            <Button
                type="primary"
                btnType="submit"
                label={t('continue')}
                isLoading={state.isLoading}
                disabled={isLoading || !serialNumber || !email || state.isLoading}
            />
        </Form>
    )
}