import {Button} from "../../../components/buttons/_index";
import React from "react";
import * as noMachineShopReducer from "../../../redux/noMachineShopReducer";
import {Routes} from "../../../utils/_index";
import {useTranslation} from "../../../hooks";
import {useDispatch} from "react-redux";
import {useNavigation} from "../../../misc/Hooks";
import {useHistory} from "react-router-dom";
import {useNoMachineShopTable} from "../hooks";
import {TableComponent} from "../../../components/baseTable";

export default function NoMachineShopScreen(){
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigation = useNavigation()
    const history = useHistory()

    const {noMachineOrdersTable, ordersFilter} = useNoMachineShopTable()

    const navToNoMachineShop = () => {
        dispatch(noMachineShopReducer.clearStore())
        history.push(navigation(Routes.NO_MACHINE_SHOP))
    }


    return (
        <div className="wrapper -xxl">
            <div className="resMS">
                <div className="resMS__header">
                    <div className="--flex --spaceBetween">
                        <Button
                            type="primary-outline"
                            label={t('no_machine_shop.reseller.create')}
                            onClick={navToNoMachineShop}
                        />
                    </div>
                </div>
                <TableComponent
                    {...noMachineOrdersTable}
                    title={t('no_machine_shop.reseller.table_title')}
                    filters={ordersFilter}
                />
            </div>

        </div>
    )
}