import {MaskInputController} from "../controllers";
import React from "react";
import {useFieldFormat, useTranslation} from "../../hooks";
import sanitizeText from "../../helpers/sanitizeText";

export default function TaxIdInput(props) {
    const {t} = useTranslation()
    const {formats} = useFieldFormat()

    return formats.taxId.isEnabled && (
        <div>
            {!!t('tax_id_text') && (
                <p className="--small --mb0" {...sanitizeText(t('tax_id_text'))}/>
            )}
            <MaskInputController
                name="taxId"
                mask={formats.taxId.getMask()}
                label={t('tax_id')}
                {...props}
            />
        </div>
    )
}
