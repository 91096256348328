import React from "react";

export default function DataCell({label, value}) {
    return !!value && (
        <div className="info__info">
            <h5 className="info__name">{label}</h5>
            <p className="info__value">{value}</p>
        </div>
    )
}
