import AuthorizationScreen from "../screens/AuthorizationScreen";
import {useSeoPage} from "../../../hooks";

export default function AuthorizationContainer(props) {
    useSeoPage({title: 'etrade.authorization.title'})

    return (
        <AuthorizationScreen/>
    )
}
