import React from 'react';
import {Controller, FormProvider, useForm} from "react-hook-form";
import {MyRadio} from "./inputs/_index";
import {useLocalizeObject, useTranslation} from "../../misc/Hooks";


const PaymentTypeForm = (props) => {
    const t = useTranslation();
    const localizeObject = useLocalizeObject();
    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            type: props.type,
        }
    });


    const rules = {
        type: {
            required: {
                value: true,
                message: t('validation.required'),
            }
        },
    }


    const handleChange = (data) => {
        formMethods.setValue('type', data);
        formMethods.handleSubmit(props.onSubmit)()
    }

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(props.onSubmit)}>

                <Controller
                    name="type"
                    control={formMethods.control}
                    rules={rules.type}
                    render={({field, fieldState}) => (
                        <>
                            {props.types?.map((type, idx) => (
                                <MyRadio
                                    key={idx}
                                    name="type"
                                    value={type}
                                    selected={field.value}
                                    error={fieldState.error}
                                    onChange={handleChange}
                                >
                                    <div
                                        className={`paymentMethods ${type?.payment_method_images?.length < 4 ? '-inLine' : ''}`}>
                                        <b>{localizeObject(type?.payment_method_title)}</b>
                                        {type?.payment_method_images?.length > 0 && (
                                            <div className={`paymentMethods__images`}>
                                                {type?.payment_method_images?.map((image, key) => (
                                                    <div className="paymentMethods__item">
                                                        <img src={image.path} alt={image.title}/>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                </MyRadio>
                            ))}
                        </>
                    )}
                />

            </form>
        </FormProvider>
    );
}


export default PaymentTypeForm;
