import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useCallback, useMemo, useState} from "react";
import {useTranslation} from "../../../../hooks";
import {FiFilePlus, FiX} from 'react-icons/fi';
import {ImSpinner8} from 'react-icons/im';
import {BiSend} from 'react-icons/bi';
import {makeClassName} from "../../../../helpers";

export default function Editor({
    inputRef,
    config,
    onFocus,
    onBlur,
    field,
    fieldState
}) {
    const {t} = useTranslation()
    const [focused, setFocused] = useState(false)
    const modules = useMemo(() => ({
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
            [{'list': 'ordered'}, {'list': 'bullet'}, 'blockquote'],
        ]
    }), [focused])

    const handleBlur = (e) => {
        setFocused(false)
        onBlur(e)
    }

    const handleFocus = (e) => {
        setFocused(true)
        if (typeof onFocus === 'function')
            onFocus(e)
    }

    const getError = (file) => {
        if (typeof file !== 'object')
            return 'Not a file!'
        if (file.size > config.maxFileSize)
            return t('error.file_too_big', Math.floor(config.maxFileSize / 1000000))
        return false
    }

    return (
        <>
            <ReactQuill
                forwardedRef={inputRef}
                modules={modules}
                value={field.value}
                placeholder={t('reseller_messages.editor_placeholder')}
                onChange={field.onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            <div className="quill__footer">
                <div className="quill__footerItems" style={{flex: 1}}>
                    {!!config.onClickUpload && (
                        <button
                            type="button"
                            className="quill__footerItem"
                            onClick={config.onClickUpload}
                        ><FiFilePlus/></button>
                    )}
                    <div>
                        {!!config.files && Object.values(config.files)?.map((file, idx) => (
                            <File
                                key={idx}
                                file={file}
                                error={getError(file)}
                                onClickRemove={() => config.onClickRemoveFile(idx)}
                            />
                        ))}
                    </div>
                </div>
                <div className="quill__footerItems">
                    <button
                        className="quill__submitButton"
                        disabled={config.submitButtonState.disabled}
                    >
                        <BiSend/>
                        {config.submitButtonState.loading && (
                            <ImSpinner8 className="spinner" style={{marginLeft: 4, fontSize: '0.75em'}}/>
                        )}
                    </button>
                </div>
            </div>
        </>
    )
}

function File({file, error, onClickRemove}) {

    const handleClickRemove = useCallback(() => onClickRemove(file), [file, onClickRemove])
    const classNames = useMemo(() => makeClassName('quill__file', {
        '-invalid': error,
    }), [file])

    return (
        <div className={classNames}>
            <span className="quill__fileName">
                {file.name}
            </span>
            {!!error && (
                <span className="quill__fileError">({error})</span>
            )}
            <button
                type="button"
                className="quill__removeFile"
                onClick={handleClickRemove}
            ><FiX/></button>
        </div>
    )
}
