import React from "react";
import {SummaryScreen} from "../../machine_registration/screens";
import Routes from "../../../utils/Routes";
import {useSeoPage} from "../../../hooks";

export default function SummaryStepContainer() {
    useSeoPage({title: 'shop_summary.title'})

    return (
        <SummaryScreen assistedRedirectTo={Routes.V2_RESELLER_NO_MACHINE_SHOP}/>
    )
}
