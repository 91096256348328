import {useSelector} from "react-redux";
import {mapStackToOptions} from "../misc/Helpers";


export default function useCivilityOptions() {
  const civilities = useSelector(({main}) => main.civilities)
  const options = mapStackToOptions(civilities)
    .sort((a, b) => Number(a.value) < Number(b.value) ? -1 : 1)

  const find = (value) => {
    if(!value) return null
    if(typeof value === 'object')
      return options.find(v => v.value === value.value)
    return options.find(v => v.value === value)
  }

  return {options, find}
}
