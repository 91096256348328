import {useParams} from "react-router-dom";
import {EditorForm, MessageNav} from "../components";
import {useMutation} from "@tanstack/react-query";
import {useResellerActions} from "../../../../actions/useResellerActions";

export default function NewMessageScreen({}) {

    return (
        <>

        </>
    )
}
