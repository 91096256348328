import React from 'react';

export default (props) => {
    return (
        <button
            type={props.btnType ?? 'button'}
            className={`btnClose ${props.positionClass}`}
            style={props.style}
            disabled={props.disabled} onClick={props.onClick}
        >
            <img src={require('../../assets/img/icons/close.svg').default} alt="x" />
        </button>
    )
}
