import React, {useState} from "react";
import {CategorySection, FavoriteProducts} from "./index";
import {useCountryConfig} from "../../../misc/Hooks";


export default function CategorySections({
    categories,
    cart,
    showAdditional,
    storeName,
    currentSelectedCategory,
    isMobileView,
    ...props
}) {
    const {selectable_products_enable: selectableProductsEnable} = useCountryConfig()

    const [opened, setOpened] = useState(0)

    const handleOpen = (index) => {
        setOpened(index)
    }

    return (
        <>
            {categories.map((cat, idx) => (!selectableProductsEnable || isMobileView || currentSelectedCategory === cat.id_category) && (
                <CategorySection
                    key={idx}
                    index={idx}
                    category={cat}
                    cart={cart}
                    showAdditional={showAdditional}
                    opened={opened}
                    storeName={storeName}
                    onClickOpen={handleOpen}
                />
            ))}
        </>
    )
}
