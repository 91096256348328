import {Button} from "../buttons/_index";
import BaseModal from "./BaseModal";
import {useTranslation} from "../../hooks";

export default function WebAccountNotExistConfirmationModal({
    isVisible,
    onClose,
    onSubmit,
}) {
    const {t} = useTranslation()

    return (
        <BaseModal
            visible={isVisible}
            onClose={onClose}
        >
            {!!t('modal.web_account_not_exist_confirmation.title') && (
                <div className="modal__header">
                    <h3 className="modal__title" dangerouslySetInnerHTML={{__html: t('modal.web_account_not_exist_confirmation.title')}}/>
                </div>
            )}

            <div className="modal__body --alignCenter">
                {!!t('modal.web_account_not_exist_confirmation.text') && (
                    <p
                        className="par-2 --mb4"
                        dangerouslySetInnerHTML={{__html: t('modal.web_account_not_exist_confirmation.text')}}
                    />
                )}
                <div style={{display: 'flex', justifyContent: 'center', gap: '1rem'}}>
                    <Button label={t('yes')} onClick={onSubmit}/>
                    <Button
                        label={t('no')}
                        onClick={onClose}
                    />
                </div>
            </div>
        </BaseModal>
    )
}
