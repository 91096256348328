import React, {useEffect, useRef} from 'react';
import MaskedInput from 'react-input-mask';

export default (props) => {
    // const inputRef = useRef(null);
    const maskRef = useRef(null);


    useEffect(() => {
        if(props.value !== maskRef.current?.value) {
            if(props.forceUppercase && maskRef.current.value) {
                props.onChange(maskRef.current.value.toUpperCase());
            } else {
                props.onChange(maskRef.current.value);
            }
        }
    }, [props.value])


    const handleChange = (event) => {
        if(props.forceUppercase && event?.target?.value) {
            props.onChange(event.target.value.toUpperCase());
        } else {
            props.onChange(event);
        }
    }


    let formGroupClassNames = '';
    if(props.value?.length || props.error) {
        formGroupClassNames += ' -active';
    }
    if(props.error && props.value?.length) {
        formGroupClassNames += ' -invalid';
    }
    if(!props.error && props.value?.length) {
        formGroupClassNames += ' -valid';
    }

    formGroupClassNames += ` ${props.formGroupClassNames}`

    return (
        <div className={`formGroup -active ${formGroupClassNames}`}>
            <label className="formGroup__field">
                {!!props.label && (
                    <span className="formGroup__label">{props.label}</span>
                )}
                <MaskedInput
                    ref={maskRef}
                    mask={props.mask}
                    maskChar={props.maskChar ?? '_'}
                    formatChars={props.formatChars ?? undefined}
                    alwaysShowMask={typeof props.alwaysShowMask !== 'undefined' ? props.alwaysShowMask : true}
                    name={props.name}
                    value={props.value}
                    onChange={handleChange}
                >
                    {(inputProps) => (
                        <input
                            ref={props.inputRef}
                            {...inputProps}
                        />
                    )}
                </MaskedInput>

                {!!props.loading && (
                    <span className="formGroup__loading">
                        <img src={require('../../../assets/img/icons/preloader2.gif').default} alt=""/>
                    </span>
                )}
            </label>

            {!!props.error && (
                <span className="formGroup__invalidFeedback">{props.error.message}</span>
            )}
        </div>
    );
}
