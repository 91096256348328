import {Config} from "../../../utils/_index";
import {useSelector} from "react-redux";
import Routes from "../../../utils/Routes";


/**
 * Returns Shop module steps
 * @returns {{component, route: string, title: string}[]}
 */
export default function useSteps() {
    const store = useSelector(({noMachineShop, main}) => ({
        user: main.user,
        customer: noMachineShop.customer,
        nespressoMember: noMachineShop.nespressoMember
    }))


    return Config.NO_MACHINE_SHOP_STEPS.filter(s => store.user ? !(store.nespressoMember && s.route === Routes.NO_MACHINE_SHOP_CUSTOMER) : true)
}
