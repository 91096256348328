import React, {useEffect, useMemo} from "react";
import {useCountryConfig, useLocalizeFile, useMarketConfig} from "../../../misc/Hooks";
import {CustomerForm} from "../../../forms";
import {
    useApiLogger,
    useCreateNespressoMember,
    useCustomer,
    useSeoPage,
    useModuleContext,
    useTranslation, useGTM
} from "../../../hooks";
import {useStepsNavigation} from "../../../hooks/modules/order_finish";
import {
    AllowedToLoginClearStorageResponseException,
    AllowedToLoginResponseException,
    MemberExistsResponseException,
    NotAllowedResponseException,
    PhoneBlockingRuleException,
    SmsValidationResponseException
} from "../../../errors";
import {useSelector} from "react-redux";
import {paymentDetailsPayloadFromCustomerForm} from "../../../services/NespressoMemberService";
import WelcomeMessage from "../../../components/WelcomeMessage";

export default function CustomerScreen() {
    useSeoPage({title: 'finish_order_customer.title'})
    const {t} = useTranslation()
    const stepsNavigation = useStepsNavigation()
    const marketConfig = useMarketConfig()
    const {store} = useModuleContext()
    const localizeFile = useLocalizeFile()
    const countryConfig = useCountryConfig()
    const {data: customer, nespressoMember} = useCustomer()
    const {finishCustomerInformationStep} = useGTM()
    const {
        isLoading,
        status,
        error,
        setError,
        canRegisterAndCreateAccount,
        canAttempt,
        createBankDetails,
        userCanLogin,
        loginFormVisible,
        smsPhoneVerification,
    } = useCreateNespressoMember()
    const apiLogger = useApiLogger()
    const plan = useSelector(({of}) => {
        return of.products?.find(p => {
            const item = of.cart?.items?.find(i => i.id === p.id_product);
            return item && p.product_type.startsWith('plan_')
        })
    })
    const {privacyFileUrl, termsFileUrl} = useMemo(() => {
        return {
            termsFileUrl: localizeFile(plan ? plan.product_files : store.promotion?.promotion_files, 'regulations'),
            privacyFileUrl: localizeFile(plan ? plan.product_files : store.promotion?.promotion_files, 'privacy_policy')
                ?? localizeFile(countryConfig.files, 'privacy_policy') ?? localizeFile(marketConfig.files, 'privacy_policy')
        }
    }, [plan, store.promotion, localizeFile, countryConfig.files, marketConfig.files])

    const handleSubmit = async (data) => {
        const payload = {
            data,
            promotionId: store.promotion?.id_promotion,
            machinePlanId: plan?.id_product,
        }

        if (nespressoMember) {
            if (store.promotion?.promotion_show_payment_details
                && (!store.finishOrder.payment_account_number || !store.finishOrder.payment_bsb_number)
                && (!customer.paymentDetails || customer?.paymentDetails.bsbNumber !== data.paymentDetails?.bsbNumber || customer?.paymentDetails.accountNumber !== data.paymentDetails?.accountNumber)
            ) {
                const payload = paymentDetailsPayloadFromCustomerForm({
                    data,
                    nespressoMember,
                    uuid: store.uuid,
                })

                await createBankDetails(payload)
                    .catch(e => {
                        setError(t(`error.${e.error}`) || t('error.unknown_error'))
                        apiLogger.info({
                            type: 'CREATE_BANK_DETAILS',
                            payload,
                            response: e.data,
                            uuid: store.uuid,
                        })
                        console.error(e)
                    })
            }

            await canAttempt({...payload, nespressoMember, customer})
                .then(() => {
                    finishCustomerInformationStep(false)
                    stepsNavigation.navToNext()
                })
                .catch(e => {
                    if (e instanceof PhoneBlockingRuleException) {
                        setError(e.error)
                        console.log('set error', e)
                        return
                    }
                    console.log(e)
                    setError(t('error.unknown_error'))
                })
            return
        }
        const account = await canRegisterAndCreateAccount(payload)
            .catch(e => {
                if (e instanceof AllowedToLoginResponseException) {
                    // display login form
                    console.log('display login form', e)
                    return
                }
                if (e instanceof AllowedToLoginClearStorageResponseException) {
                    // display login form
                    console.log('clear storage and redirect to auth step', e)
                    return
                }
                if (e instanceof NotAllowedResponseException
                    || e instanceof MemberExistsResponseException
                    || e instanceof PhoneBlockingRuleException) {
                    setError(e.error)
                    console.log('set error', e)
                    return
                }
                if (e instanceof SmsValidationResponseException) {
                    // display sms form
                    console.log('display sms form')
                    return;
                }
                console.log(e)
                setError(t('error.unknown_error'))
            })

        if (account) {
            finishCustomerInformationStep(true, account.memberNumber)
            stepsNavigation.navToNext()
        }
    }

    return (
        <div className="evCIN">
            <WelcomeMessage/>

            <CustomerForm
                state={{
                    isLoading,
                    status,
                    error,
                    loginFormVisible,
                    userCanLogin,
                }}
                options={{
                    paymentDetailsVisible: store.promotion?.promotion_show_payment_details,
                    benefitFields: {
                        phone: store.promotion?.promotion_marketing_phone_visible ?? plan?.promotion_marketing_phone_visible,
                        email: store.promotion?.promotion_marketing_email_visible ?? plan?.promotion_marketing_email_visible,
                        mobile: store.promotion?.promotion_marketing_mobile_visible ?? plan?.promotion_marketing_mobile_visible,
                        post: store.promotion?.promotion_marketing_post_visible ?? plan?.promotion_marketing_post_visible,
                        socialMedia: store.promotion?.promotion_marketing_social_media_visible ?? plan?.promotion_marketing_social_media_visible,
                    },
                    benefitConfig: {
                        required: store.promotion?.promotion_marketing_required ?? plan?.promotion_marketing_required,
                        visibility: marketConfig.user_marketing_options_visibility,
                        selectAllVisible: store.promotion?.promotion_marketing_select_all_benefits_enabled ?? plan?.promotion_marketing_select_all_benefits_enabled,
                    },
                    termsOptions: {
                        termsAndConditionsFileUrl: termsFileUrl,
                        privacyPolicyFileUrl: privacyFileUrl,
                    },
                }}
                nespressoMember={nespressoMember}
                smsPhoneVerification={smsPhoneVerification}
                prevRoute={stepsNavigation.prev()}
                onSubmit={handleSubmit}
            />
        </div>
    );
}