import {useMutation} from "@tanstack/react-query";
import {ApiException} from "../../../errors";
import {useResellerActions} from "../../../actions/useResellerActions";
import {useState} from "react";

export default function useVerifyMember() {
    const actions = useResellerActions()
    const [codeSent, setCodeSent] = useState(false)
    const sendMutation = useMutation({
        mutationFn: async (payload) => {
            setCodeSent(false)
            // return await fetch('https://rc2-bo.nespresso-ev.com/api/v2/reseller/assisted-simplified-flow/verification/send', {
            //     headers: {
            //         'Authorization': 'Bearer YWVkZDg4MTA3OWJlYTEwMzY2Zjg2NTQ5MmZhMjdlYzkyMGQ3YWZhNGJkODVlOGUwZjZjNzBiN2FiYWFmZTQzYg',
            //         'Content-Type': 'application/json',
            //         'Accept': 'application/json',
            //     },
            //     method: 'post',
            //     body: JSON.stringify(payload)
            // })
            //     .then(async req => {
            //         const response = await req.json()
            //         if(req.ok) {
            //             return response.data
            //         }
            //         throw new ApiException(response?.message, response)
            //     })

            return await actions.sendVerificationCodeAssistedSimplifiedFlow(payload)
                .then(res => {
                    if(res.response) {
                        throw new ApiException(res.response?.data?.message, res.response?.data)
                    }
                    return res.data?.data
                })
        },
        onSuccess: (data) => {
            setCodeSent(true)
        },
    })
    const verifyMutation = useMutation({
        mutationFn: async (payload) => {
            return await actions.verifyCodeAssistedSimplifiedFlow(payload)
                .then(res => {
                    if(res.response) {
                        throw new ApiException(res.response?.data?.message || res.response?.data?.messages[0], res.response?.data)
                    }
                    return res.data?.data
                })
        },
    })
    const isLoading = sendMutation.isLoading || verifyMutation.isLoading
    const isError = sendMutation.isError || verifyMutation.isError
    const error = sendMutation.error || verifyMutation.error

    const reset = () => {
        setCodeSent(false)
    }

    return {
        sendMutation,
        verifyMutation,
        isLoading,
        isError,
        error,
        codeSent,
        reset,
    }
}
