import {proofOfPurchasePdfOcr, proofOfPurchaseOcr} from '../actions/MainActions';
import {useMutation} from "@tanstack/react-query";
import {ApiException} from "../errors";
import {useMemo, useState} from "react";
import {useModuleContext, useTranslation} from "./index";
import {useSelector} from "react-redux";

export default function useProofOfPurchase() {
    const {t} = useTranslation()
    const {storeName} = useModuleContext()
    const store = useSelector(state => ({
        proofOfPurchase: state[storeName]?.proofOfPurchase,
        proofOfPurchaseLogs: state[storeName]?.proofOfPurchaseLogs,
    }))
    // console.log('store:', store.proofOfPurchase)
    const [data, setData] = useState(store.proofOfPurchaseLogs)
    const [error, setError] = useState(null)
    const [progress, setProgress] = useState(0)
    const [logs, setLogs] = useState([])
    const mutation = useMutation(
        async (payload) => {
            const formData = new FormData()
            formData.append('file', payload.file)

            const action = payload.file.type.match('application/pdf') ? proofOfPurchasePdfOcr : proofOfPurchaseOcr
            const {data} = await action(formData, {
                onUploadProgress: (e) => {
                    setProgress(e.loaded * 100 / e.total)
                },
            })

            if(data.status === 'success')
                return data
            throw new ApiException(data.messages[0], data)
        },
        {
            onMutate: () => {
                setError(null)
                setData(null)
            },
            onSuccess: ({data}) => {
                setData(data)
            },
            onError: ({error}) => {
                setError(t(`error.${error}`) || t('error.unknown_error'))
            },
        }
    )
    const mappedData = useMemo(() => mapData(data), [data])

    function mapData (data) {
        if(!data)
            return null
        const obj = {
            file: data.filename,
            date: data.data.date ? (new Date(data.data.date)).toISOString() : null,
            shopGroup: undefined,
            shop: undefined,
            invoiceNumber: data.data.invoice,
            machinePrice: data.data.total,
        }

        if(data.data.id_shop_group) {
            obj.shopGroup = {
                value: data.data.id_shop_group,
                label: data.data.shop_group_title,
            }
        }

        if(data.data.id_shop) {
            obj.shop = {
                value: data.data.id_shop,
                label: data.data.shop_title,
                data: {
                    shopGroupId: data.data.id_shop_group,
                }
            }
        }

        return obj
    }

    const makeLog = (name, value, manually) => {
        return {
            date: (new Date()).toISOString(),
            manually,
            field: name,
            value,
        }
    }

    return {
        mutation,
        progress,
        data,
        mappedData,
        error,
        mapData,
        logs,
        setLogs,
        makeLog,
        defaultValue: store.proofOfPurchase,
    }
}
