import {useMutation} from "@tanstack/react-query";
import {useTranslation} from "../../../../hooks";
import {isoDateConverter} from "../../../../misc/Helpers";
import {useResellerActions} from "../../../../actions/useResellerActions";
import {ApiException} from "../../../../errors";
import {useState} from "react";

export default function useGenerateReport(){

    const {t} = useTranslation();
    const actions = useResellerActions();

    const [modalVisible, setModalVisible] = useState(false)
    const [generateStatus, setGenerateStatus] = useState({
        isGenerated: true,
        reportUrl: null,
    })

    const [alert, setAlert] = useState({type: '', message: ''})

    const generateReportMutation = useMutation(
        async (payload) => {
            const postData = {
                reseller_id: payload.resellerIds ?? [],
                date_from: isoDateConverter(payload.dateFrom, '-'),
                date_to: isoDateConverter(payload.dateTo, '-'),
                translations: {
                    columns: {
                        id: t('order.id'),
                        shop_name: t('order.shop_name'),
                        reseller_email: t('order.reseller_email'),
                        promotion_type: t('order.promotion_type'),
                        tracking_code: t('order.tracking_code'),
                        status_of_registration: t('order.status_of_registration'),
                        status_of_payment: t('order.status_of_payment'),
                        data_time: t('order.date_time'),
                        machine_type: t('order.machine_type'),
                        serial_number: t('order.serial_number')
                    },
                    subscription_status: {
                        "-3": t('subscription_status.-3'),
                        "-1": t('subscription_status.-1'),
                        "0": t('subscription_status.0'),
                        "1": t('subscription_status.1'),
                        processed: t('subscription_status.processed'),
                        in_treatment: t('subscription_status.in_treatment')
                    },
                    payment_status: {
                        "-1": t('payment_status.-1'),
                        expired: t('payment_status.expired'),
                        "0": t('payment_status.0'),
                        "1": t('payment_status.1'),
                        "2": t('payment_status.2'),
                    }
                }
            }

            const {data} = await actions.generateRaport(postData)
            if (data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        {
            onSettled: () => setAlert({type: '', message: ''}),
            onSuccess: ({id_reseller_report}) => {
                setGenerateStatus({isGenerated: true, reportUrl: null})
                setModalVisible(true)
                setTimeout(() => {
                    checkGenerateStatusMutation.mutate(id_reseller_report)
                }, 2000)
            },
            onError: () => setAlert({type: 'error', message: 'error.unknown_error'})
        }
    )

    const checkGenerateStatusMutation = useMutation(
        async (id) => {
            const {data} = await actions.getGeneratedReportsV2({id})
            if (data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        {
            onSettled: () => setAlert({type: '', message: ''}),
            onSuccess: ({data}) => {
                if(data[0].reseller_report_status === 0){
                    setTimeout(() => {
                        checkGenerateStatusMutation.mutate(data[0].id_reseller_report)
                    }, 2500)
                } else setGenerateStatus({isGenerated: false, reportUrl: data[0].reseller_report_url})
            },
            onError: () => setAlert({type: 'error', message: 'error.unknown_error'})
        }
    )

    return {
        generateReportMutation,
        alert,
        modalVisible,
        setModalVisible,
        generateStatus
    }
}