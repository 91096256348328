import React, {useRef, useState} from 'react';

export default (props) => {
    const inputRef = useRef(null);
    const [isFocused, setFocused] = useState(false);


    const handleChange = (e) => {
        if(['A', 'BUTTON'].includes(e.target.nodeName)) {
            return
        }

        if(props.inputRef) {
            props.inputRef.current.click()
        } else {
            inputRef.current?.click();
        }
    }


    let formGroupClassNames = '';
    if(props.value?.length || props.error) {
        formGroupClassNames += ' -active';
    }
    if(isFocused) {
        formGroupClassNames += ' -focused';
    }
    if(props.error) {
        formGroupClassNames += ' -invalid';
    }
    if(!props.error && props.value?.length) {
        formGroupClassNames += ' -valid';
    }
    if(props.green) {
        formGroupClassNames += ' -green';
    }

    return (
        <div className={`formCheckbox ${formGroupClassNames}`}>

            <div className="formCheckbox__field">
                <input
                    ref={inputRef}
                    type="checkbox"
                    id={props.name ?? ''}
                    name={props.name ?? ''}
                    checked={props.value}
                    onChange={e => props.onChange(e.target.checked)}
                    hidden
                />

                <div className="formCheckbox__input"
                     data-checked={props.value}
                     onClick={handleChange}
                />

                <label onClick={!props.disableLabelClick ? handleChange : undefined}>
                    {props.label ?? props.children}
                </label>
            </div>

            {!!props.error && (
                <span className="formGroup__invalidFeedback">{props.error.message}</span>
            )}
        </div>
    );
}
