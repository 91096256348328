import {Button} from "../components/buttons/_index";
import {useModuleContext, useTranslation} from "../hooks";
import {useSelector} from "react-redux";

export default function IsNewMemberForm({text, onSubmit}) {
    const {t} = useTranslation()
    const {storeName} = useModuleContext()
    const isNewMember = useSelector(state => state[storeName]?.isNewMember)

    return (
        <div>
            <p className="par-1 --semiBold --mb3">{text || t('ev_customer_identification.text')}</p>

            <div className="evCID__buttons --mb4">
                <Button
                    label={t('yes')}
                    type="grey"
                    active={isNewMember === false}
                    onClick={() => onSubmit(false)}
                />
                <Button
                    label={t('no')}
                    type="grey"
                    active={isNewMember === true}
                    onClick={() => onSubmit(true)}
                />
            </div>
        </div>
    )
}
