import Form from "./Form";
import {useForm, useWatch} from "react-hook-form";
import {useFormRulesBuilder} from "./hooks";
import {
    useDateFormatter,
    useFormHelper,
    useMachine,
    useModuleContext,
    useProofOfPurchase, useRequiredField,
    useTranslation, useValidateInvoiceNumber
} from "../hooks";
import {FormGroup, FormRow, InputPreview} from "./components";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import FileDropzone from "./presets/FileDropzone";
import {DatePicker, InvoiceNumberInput, MachinePriceInput, ShopRegionSelect} from "./presets";
import ShopGroupSelect from "./presets/ShopGroupSelect";
import ShopSelect from "./presets/ShopSelect";
import {Button} from "../components/buttons/_index";
import {useMarketConfig} from "../misc/Hooks";
import Patterns from "../utils/Patterns";
import {isEnabledByMarket} from "../utils/MarketConfig";
import {getUploadUrl} from "../helpers";
import editIcon from '../assets/img/icons/edit.svg';
import {ProofOfPurchaseModal} from "../components/modals/_index";
import {useSelector} from "react-redux";
import sanitizeText from "../helpers/sanitizeText";
import getObjectValue from "../helpers/getObjectValue";

export default function ProofOfPurchaseForm({
    editable,
    setEditable,
    emptyForm,
    setEmptyForm,
}) {
    const machine = useMachine()
    const {
        mutation,
        data,
        mappedData,
        error,
        progress,
        mapData,
        logs,
        setLogs,
        makeLog,
        defaultValue,
    } = useProofOfPurchase()
    const validateInvoiceNumber = useValidateInvoiceNumber()
    const mutationData = data
    const {t} = useTranslation()
    const marketConfig = useMarketConfig()
    const {getDate} = useDateFormatter()
    const [popModalVisible, setPopModalVisible] = useState(false);
    const machinePurchasedAt = useMemo(() => machine.getPurchaseDate(), [machine.machineResponse])
    const {fieldsConfig} = useRequiredField()
    const {formMethods, setError, clearErrors, trigger} = useFormHelper()
    const formRules = useFormRulesBuilder({
        file: b => b.required()
            .validate(v => !error, error),
        date: b => b.required(fieldsConfig.date.isRequired),
        region: b => b,
        shopGroup: b => b.required(fieldsConfig.shopGroup.isRequired),
        shop: b => b.required(fieldsConfig.shop.isRequired),
        invoiceNumber: b => b.required(fieldsConfig.invoiceNumber.isRequired)
            .validate(v => validateInvoiceNumber.isValid),
        machinePrice: b => b.required(fieldsConfig.machinePrice.isRequired)
            .pattern(fieldsConfig.machinePrice.isRequired ? Patterns.number : undefined)
    }, formMethods)
    const file = useWatch({
        control: formMethods.control,
        name: 'file',
    })
    const date = useWatch({
        control: formMethods.control,
        name: 'date',
    })
    const region = useWatch({
        control: formMethods.control,
        name: 'region',
    })
    const shopGroup = useWatch({
        control: formMethods.control,
        name: 'shopGroup',
    })
    const shop = useWatch({
        control: formMethods.control,
        name: 'shop',
    })
    const invoiceNumber = useWatch({
        control: formMethods.control,
        name: 'invoiceNumber',
    })
    const machinePrice = useWatch({
        control: formMethods.control,
        name: 'machinePrice',
    })
    const fieldsValues = {
        file,
        date,
        region,
        shopGroup,
        shop,
        invoiceNumber,
        machinePrice,
    }
    const fileUrl = useMemo(() => {
        if (!file)
            return null
        if (file instanceof File) {
            if (data?.filename && typeof data.filename === 'string')
                return getUploadUrl(data.filename)
            return URL.createObjectURL(file)
        }
        if (typeof file === 'string')
            return getUploadUrl(file)
        return undefined
    }, [file, data])

    useEffect(() => {
        if (!editable) return
        if (file) {
            mutation.mutate({file})
        } else {
            setEmptyForm(true)
            formMethods.setValue('date', undefined)
            formMethods.setValue('region', undefined)
            formMethods.setValue('shopGroup', undefined)
            formMethods.setValue('shop', undefined)
            formMethods.setValue('invoiceNumber', undefined)
            formMethods.setValue('machinePrice', undefined)
        }
    }, [file])

    useEffect(() => {
        if (error) {
            setError('file', {type: 'invalid', message: error})
        } else {
            clearErrors('file')
        }
    }, [error])

    useEffect(() => {
        if(!validateInvoiceNumber.validationRequired || !invoiceNumber)
            return

        if (!validateInvoiceNumber.isValid) {
            setError('invoiceNumber', {type: 'invalid', message: t('validation.invalid')})
        } else {
            clearErrors('invoiceNumber')
        }
    }, [validateInvoiceNumber.isValid])

    useEffect(() => {
        if (!editable) return
        if (typeof shopGroup !== 'number' && typeof shopGroup !== 'string')
            formMethods.setValue('shopGroup', null)
        if (typeof shop !== 'number' && typeof shop !== 'string')
            formMethods.setValue('shop', null)
    }, [region?.value])

    useEffect(() => {
        if (!editable) return
        if(shopGroup && shop && shop.data?.shopGroupId !== shopGroup.value) {
            formMethods.setValue('shop', null)
        }
    }, [shopGroup?.value])

    useEffect(() => {
        if (!editable) return
        if(invoiceNumber && validateInvoiceNumber.validationRequired) {
            validateInvoiceNumber.validate(invoiceNumber)
        }
    }, [invoiceNumber, editable])

    useEffect(() => {
        if (!editable) return
        const res = mapData(data)
        if (!res) return

        const _logs = [...logs]

        const handleField = (name, value, logName) => {
            if (value) {
                formMethods.setValue(name, value)
                formMethods.clearErrors(name)
                if (logName) {
                    _logs.push(makeLog(logName, value, 0));
                }
            } else {
                formMethods.setValue(name, undefined)
                if (formRules.values[name]?.required && !isEnabledByMarket('hideUploadPopErrors')) {
                    formMethods.setError(name, {
                        type: 'required',
                        message: t(`validation.pop_invalid_${name}`) || t('validation.pop_invalid')
                    })
                }
            }
        }

        handleField('date', machinePurchasedAt ?? res.date, 'date')
        handleField('shopGroup', res.shopGroup, 'id_shop_group')
        handleField('shop', res.shop, 'id_shop')
        handleField('invoiceNumber', res.invoiceNumber, 'invoice')
        if (res.machinePrice) {
            let value = res.machinePrice
            if (marketConfig.pop_validation_ocr_machine_price_mandatory) {
                const price = Number(
                    res.machinePrice
                        ?.replace(/(,|\.)/, 'SEPARATOR')
                        .replace(/[,.]/g, '')
                        .replace('SEPARATOR', '.')
                        .replace(/^(,\.)|[^0-9.,]+/g, '')
                )
                if (typeof price === 'number' && !Number.isNaN(price) && Number.isFinite(price)) {
                    if (isEnabledByMarket('commaInsteadOfDotInPriceFormat')) {
                        value = price.toString().replace(/\./, ',')
                    } else {
                        value = price
                    }
                }
            }
            handleField('machinePrice', value, 'total')
        } else {
            handleField('machinePrice', res.machinePrice, 'total')
        }

        setLogs(_logs)
    }, [data])

    useEffect(() => {
        if (editable) return
        Object.keys(fieldsValues)
            .filter(k => !isPreviewVisible(k))
            .map(k => {
                trigger(k)
            })
    }, [])

    useEffect(() => {
        formMethods.setValue('proofOfPurchaseData', mutationData)
    }, [mutationData])

    useEffect(() => {
        formMethods.setValue('proofOfPurchaseLogs', logs)
    }, [logs])

    const pushLog = (name, logName, value, manually) => {
        const res = mapData(data)
        if (value && (!res || (res && res[name] !== value))) {
            const _logs = [...logs]
            _logs.push(makeLog(logName, value, manually))
            setLogs(_logs)
        }
    }

    const onChangeFile = (value) => {
        pushLog('file', 'file', value, 1)
        formMethods.setValue('file', value, {shouldValidate: true})
    }

    const onChangeDate = (value) => {
        pushLog('date', 'date', value, 1)
        formMethods.setValue('date', value, {shouldValidate: true})
    }

    const onChangeShopGroup = (value) => {
        pushLog(
            'shopGroup',
            'id_shop_group',
            getObjectValue(value),
            1
        )
        formMethods.setValue('shopGroup', value, {shouldValidate: true})
    }

    const onChangeShop = (value) => {
        pushLog(
            'shop',
            'id_shop',
            getObjectValue(value),
            1
        )
        formMethods.setValue('shop', value, {shouldValidate: true})
    }

    const onChangeInvoiceNumber = (e) => {
        const value = e.target.value
        pushLog('invoiceNumber', 'invoice', value, 1)
        formMethods.setValue('invoiceNumber', value, {shouldValidate: true})

        validateInvoiceNumber.validate(value)
    }

    const onChangeMachinePrice = (e) => {
        const value = e.target.value
        pushLog('machinePrice', 'total', value, 1)
        formMethods.setValue('machinePrice', value, {shouldValidate: true})
    }

    const resetForm = () => {
        setEmptyForm(true)
        setEditable(true)
        formMethods.reset({
            file: undefined,
            date: undefined,
            region: undefined,
            shopGroup: undefined,
            shop: undefined,
            invoiceNumber: undefined,
            machinePrice: undefined,
        })
    }

    function isPreviewVisible(name) {
        if (editable || !defaultValue)
            return false
        return !emptyForm && !(!editable && !defaultValue[name] && fieldsConfig[name]?.isRequired)
    }

    const FormFragment = (
        <Fragment>
            {fieldsConfig.date.isVisible && (isPreviewVisible('date') ? (
                <InputPreview label={t('date')} value={getDate(date)}/>
            ) : (
                <FormGroup>
                    <DatePicker
                        rules={formRules.values.date}
                        options={{
                            isLoading: mutation.isLoading,
                            inputProps: {
                                disabled: machinePurchasedAt ? true : mutation.isLoading,
                                onChange: onChangeDate,
                            }
                        }}
                    />
                    {!!machinePurchasedAt && (
                        <p className="par-4 --pt1" {...sanitizeText(t('machine_purchased_at_info'))}/>
                    )}
                </FormGroup>
            ))}
            {fieldsConfig.region.isVisible && !isPreviewVisible('region') && (
                <FormGroup>
                    <ShopRegionSelect
                        rules={formRules.values.region}
                        options={{
                            isLoading: mutation.isLoading,
                            disabled: mutation.isLoading,
                        }}
                    />
                </FormGroup>
            )}
            {fieldsConfig.shopGroup.isVisible && (isPreviewVisible('shopGroup') ? (
                <InputPreview label={t('trade_partner')} value={shopGroup?.label}/>
            ) : (
                <FormGroup>
                    <ShopGroupSelect
                        // key={editable}
                        rules={formRules.values.shopGroup}
                        regionId={region?.value}
                        options={{
                            isLoading: mutation.isLoading,
                            disabled: mutation.isLoading,
                            inputProps: {
                                onChange: onChangeShopGroup,
                            }
                        }}
                    />
                </FormGroup>
            ))}
            {fieldsConfig.shop.isVisible && (isPreviewVisible('shop') ? (
                <InputPreview label={t('retailer_store')} value={shop?.label}/>
            ) : (
                <FormGroup>
                    <ShopSelect
                        rules={formRules.values.shop}
                        groupId={shopGroup?.value}
                        regionId={region?.value}
                        options={{
                            isLoading: mutation.isLoading,
                            disabled: mutation.isLoading,
                            inputProps: {
                                onChange: onChangeShop,
                            }
                        }}
                    />
                </FormGroup>
            ))}
            {fieldsConfig.invoiceNumber.isVisible && (isPreviewVisible('invoiceNumber') ? (
                <InputPreview label={t('invoice_number')} value={invoiceNumber}/>
            ) : (
                <FormGroup>
                    <InvoiceNumberInput
                        rules={formRules.values.invoiceNumber}
                        options={{
                            isLoading: mutation.isLoading || validateInvoiceNumber.mutation.isLoading,
                            inputProps: {
                                disabled: mutation.isLoading,
                                onChange: onChangeInvoiceNumber,
                            }
                        }}
                    />
                </FormGroup>
            ))}
            {fieldsConfig.machinePrice.isVisible && (isPreviewVisible('machinePrice') ? (
                <InputPreview label={t('machine_price')} value={machinePrice}/>
            ) : (
                <FormGroup>
                    <MachinePriceInput
                        rules={formRules.values.machinePrice}
                        options={{
                            isLoading: mutation.isLoading,
                            inputProps: {
                                disabled: mutation.isLoading,
                                onChange: onChangeMachinePrice,
                            }
                        }}
                    />
                </FormGroup>
            ))}
        </Fragment>
    )

    const EditableFormView = (
        <div>
            <FormRow>
                <FormGroup>
                    <div className="pop__col">
                        <p
                            className="par-2 --semiBold"
                            dangerouslySetInnerHTML={{__html: t('pop.ready_img_title')}}
                        />
                        {!!fileUrl && (
                            <div className="pop__img">
                                <img src={fileUrl} alt=""/>
                            </div>
                        )}
                    </div>
                </FormGroup>
                <div>
                    <h4
                        className="par-2 --semiBold"
                        dangerouslySetInnerHTML={{__html: t('pop.ready_details_title')}}
                    />
                    {FormFragment}
                    {/*{editable ? FormFragment : <ReadonlyFormFragment/>}*/}
                </div>
            </FormRow>
            <FormRow className="--mb3">
                <div>
                    <button
                        type="button"
                        className="link -primary"
                        onClick={resetForm}
                    >
                        {t('pop.edit_img_button')}
                        {' '}
                        <img src={editIcon} alt="" style={{width: '12px'}}/>
                    </button>
                </div>
                {!editable && (
                    <div>
                        <button
                            type="button"
                            className="link -primary"
                            onClick={() => setEditable(true)}
                        >
                            {t('pop.edit_details_button')}
                            {' '}
                            <img src={editIcon} alt="" style={{width: '12px'}}/>
                        </button>
                    </div>
                )}
            </FormRow>
        </div>
    )

    const EmptyFormView = (
        <div>
            <FormGroup>
                {isEnabledByMarket('POPModalButtonTop') && !file && (
                    <button
                        type="button"
                        className="link -primary --mb2_5"
                        onClick={() => setPopModalVisible(true)}
                    >{t('what_is_proof_of_purchase')}</button>
                )}

                <div className="--mb2">
                    <FileDropzone
                        rules={formRules.values.file}
                        options={{
                            progress: progress,
                            inputProps: {
                                disabled: mutation.isLoading,
                            }
                        }}
                    />
                </div>
                {!file && (
                    <div className="--mb4">
                        <p
                            className="uploadBox__caption --mb1"
                            dangerouslySetInnerHTML={{__html: t('ev_machine_registration.pop_text2')}}
                        />
                        {!isEnabledByMarket('POPModalButtonTop') && (
                            <button
                                type="button"
                                className="link -primary"
                                onClick={() => setPopModalVisible(true)}
                            >{t('what_is_proof_of_purchase')}</button>
                        )}

                    </div>
                )}
            </FormGroup>

            {!!(file && t('proof_of_purchase_details_info')) && (
                <p className="par-2" {...sanitizeText(t('proof_of_purchase_details_info'))}/>
            )}

            {!!file && FormFragment}

            {!!(file && t('proof_of_purchase_details_footer')) && (
                <p className="par-2 --mb4" {...sanitizeText(t('proof_of_purchase_details_footer'))}/>
            )}

        </div>
    )

    return (
        <div>
            {emptyForm ? EmptyFormView : EditableFormView}

            <ProofOfPurchaseModal
                visible={popModalVisible}
                onClose={() => setPopModalVisible(false)}
            />
        </div>
    )
}
