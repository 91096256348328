import {useFormBuilder, useFormBuilderConfig, useFormRulesBuilder} from "../hooks";
import {getCustomerCustomAddressForm, getCustomerDetailsForm, isEnabledByMarket} from "../../utils/MarketConfig";
import {EmailInput, PasswordInput, WithCustomAddressCheckbox} from "../presets";
import React from "react";
import {FormBuilderComponent, FormGroup} from "../components";
import {useWatch} from "react-hook-form";
import {useCountry, useFieldFormat, useFormHelper, useTranslation} from "../../hooks";

export default function NewCustomerFormFragment({
    options,
}) {
    const {t} = useTranslation()
    const {hideNewMemberCustomAddress} = useCountry()
    const {formMethods} = useFormHelper()
    const {formats} = useFieldFormat()
    const {getFieldsConfig} = useFormBuilderConfig()
    const formRules = useFormRulesBuilder({
        'reEmail': b => b.required().email().equals('$details.email', {sameAs: t('email')}),
        'rePassword': b => b.required().password().equals('$details.password', {sameAs: t('password')}),
    }, formMethods)
    const detailsIsCompanyAddress = useWatch({
        control: formMethods.control,
        name: 'details.isCompanyAddress',
    })
    const withCustomAddress = useWatch({
        control: formMethods.control,
        name: 'withCustomAddress',
    })
    const customAddressIsCompanyAddress = useWatch({
        control: formMethods.control,
        name: 'customAddress.isCompanyAddress',
    })
    const {components: detailsComponents} = useFormBuilder({
        formConfig: getFieldsConfig() ?? getCustomerDetailsForm(),
        inputVisibility: {
            'title': !detailsIsCompanyAddress,
            'taxId': !detailsIsCompanyAddress,
            'taxCategory': !detailsIsCompanyAddress,
            'profession': !detailsIsCompanyAddress,
            'companyName': !!detailsIsCompanyAddress,
            'companyTaxId': !!detailsIsCompanyAddress,
            'companyTaxCategory': !!detailsIsCompanyAddress,
            'companyProfession': !!detailsIsCompanyAddress,
            'companyTaxOffice': !!detailsIsCompanyAddress,
        },
        inputComponents: {
            'reEmail': (
                <EmailInput
                    name="reEmail"
                    rules={formRules.values.reEmail}
                    label={t('re_email')}
                    format={formats.reEmail}
                />
            ),
            'rePassword': (
                <PasswordInput
                    name="rePassword"
                    rules={formRules.values.rePassword}
                    label={t('re_password')}
                />
            ),
        }
    })
    const {components: customAddressComponents} = useFormBuilder({
        formConfig: getFieldsConfig('customAddress') ?? getCustomerCustomAddressForm(),
        inputVisibility: {
            'title': !customAddressIsCompanyAddress,
            'taxId': !customAddressIsCompanyAddress,
            'taxCategory': !customAddressIsCompanyAddress,
            'profession': !customAddressIsCompanyAddress,
            'companyName': !!customAddressIsCompanyAddress,
            'companyTaxId': !!customAddressIsCompanyAddress,
            'companyTaxCategory': !!customAddressIsCompanyAddress,
            'companyProfession': !!customAddressIsCompanyAddress,
            'companyTaxOffice': !!customAddressIsCompanyAddress,
        },
        inputComponents: {},
    })

    return (
        <>
            <FormBuilderComponent prefix="details" components={detailsComponents}/>

            {!!options.customAddressVisible && !hideNewMemberCustomAddress() && (
                <>
                    <FormGroup>
                        <WithCustomAddressCheckbox/>
                    </FormGroup>
                    {withCustomAddress && (
                        <FormBuilderComponent prefix="customAddress" components={customAddressComponents}/>
                    )}
                </>
            )}
        </>
    )
}