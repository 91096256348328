export default function mapToOldCategoriesStructure({categories, products}){

    const findProduct = (id, promotion_product = null) => {
        const product = products.find((p => p.id_product === id))
        return {...product, promotion_product}
    }

    const replaceSubCategory = (subcategory) => {
        return subcategory.reduce((cv, subCat) => {
            const result = [...cv]
            const cat = {...subCat}

            if(cat?.subcategories?.length)
                cat.subcategories = replaceSubCategory(cat.subcategories)

            if(cat?.products?.length){
                cat.products = cat.products.map((p) => findProduct(p.id_product, p.promotion_product))
            }

            result.push(cat)

            return result;
        }, [])
    }

    const oldCategories = categories.reduce((cv, cat) => {
        const promoCategory = {...cat}

        promoCategory.category.products = promoCategory.category.products.map((p) => findProduct(p.id_product, p.promotion_product))
        promoCategory.products = promoCategory.category.products

        if(promoCategory.category?.subcategories?.length){
            promoCategory.category.subcategories = replaceSubCategory(promoCategory.category.subcategories)
        }

        return [...cv, promoCategory]
    }, [])

    return {
        categories: oldCategories,
        products: products
    }
}