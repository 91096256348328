import React from "react";
import {useTranslation} from "../../../hooks";
import CartSummaryProduct from "./CartSummaryProduct";
import useCart2 from "../hooks/useCart2";

export default function CartSummaryCategory({
    products,
    capsulesCount,
    parentCat,
    disableEdit,
    customData,
    hidePlusMinusButtons,
    titleSmallText,
}) {
    const {t} = useTranslation()
    const cart = useCart2(customData)

    return (
        <div key={parentCat.id_category}>
            <div className="cartSummaryNew__header">
                <div className="cartSummaryNew__title">
                    {parentCat.category.category_title}
                    {!!titleSmallText && (
                        <>
                            {' '}<small>{titleSmallText}</small>
                        </>
                    )}
                </div>
                {(cart.categorySubtitleVisible(parentCat) && capsulesCount > 0) && (
                    <div className="cartSummaryNew__subtitle">{t('cart.capsules_total', capsulesCount)}</div>
                )}
            </div>

            <div className="cartSummaryNew__items">
                {products.map(product => (
                    <CartSummaryProduct
                        product={product}
                        parentCat={parentCat}
                        disableEdit={disableEdit}
                        key={product.id_product}
                        customData={customData}
                        hidePlusMinusButtons={hidePlusMinusButtons}
                    />
                ))}
            </div>
        </div>
    )
}