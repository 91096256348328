import './style.scss';
import React, {useCallback, useMemo} from "react";
import {makeClassName} from "../../helpers";
import {useTranslation} from "../../hooks";
import Button from "../buttons/Button";
import {InfoComponent} from "../_index";

export default function MemberDetailsCard({data, isSelected, onClick}) {
    const {t} = useTranslation()
    const cardStyles = useMemo(() => makeClassName('card', {
        '-selected': isSelected,
    }), [isSelected])
    const handleClick = useCallback(() => {
        if (typeof onClick === 'function')
            onClick(data)
    }, [onClick])

    function getPhoneNumber(phoneNumber) {
        if (!phoneNumber) return null

        phoneNumber = phoneNumber.toString()

        const hiddenPart = phoneNumber.substring(0, phoneNumber.length - 2).replace(/./g, '*')
        const visiblePart = phoneNumber.substring(phoneNumber.length - 2)

        return `${hiddenPart}${visiblePart}`
    }

    function getEmail(email) {
        if (!email) return null

        const [name, domain] = email.split('@')
        let visibleLength = 1

        if (name.length > 3)
            visibleLength = 3

        const visiblePart = name.substring(0, visibleLength)
        const hiddenPart = name.substring(visibleLength).replace(/./g, '*')

        return `${visiblePart}${hiddenPart}@${domain}`
    }

    if (!data)
        return null

    return (
        <div className={cardStyles} onClick={handleClick}>
            <div className="card__row">
                <Item label={t('firstname')} value={data.firstName}/>
                <Item label={t('lastname')} value={data.lastName}/>
            </div>
            <Item label={t('phone')} value={getPhoneNumber(data.phoneNumber)}/>
            <Item label={t('email')} value={getEmail(data.email)}/>
            {!data.hasWebAccount && (
                <InfoComponent
                    type="info"
                    customClassName="-sm"
                    message={t('assisted_search_nespresso_member.web_account_not_created')}
                />
            )}
            <div className="card__footer">
                <Button
                    size="sm"
                    label={isSelected ? t('selected') : t('select')}
                    disabled={isSelected}
                    onClick={handleClick}
                />
            </div>
        </div>
    )
}

function Item({label, value}) {
    return (
        <div className="item">
            <h3 className="item__label">{label}</h3>
            <p className="item__value">{value || '-'}</p>
        </div>
    )
}
