import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import {InputLabel, InvalidFeedback} from "../components";
import {useInputValidationClassNames} from "../hooks";
import {useMemo, useRef} from "react";
import calendarImg from '../../assets/img/icons/calendar.svg';
import {useCountry, useCurrentLanguage} from "../../hooks";
import {useLocalizeObject} from "../../misc/Hooks";
import InputSpinner from "../components/InputSpinner";
import {MdOutlineChevronLeft, MdOutlineChevronRight} from 'react-icons/md'
import * as dateFns from 'date-fns'
import * as locales from 'date-fns/esm/locale'

/**
 * TextInput
 * @param type
 * @param inputRef
 * @param fieldState
 * @param name
 * @param label
 * @param value
 * @param {{inputProps, selectProps, isRequired: boolean, isLoading: boolean, maxLength: number, renderIcon}} options
 * @param onChange
 * @param onBlur
 * @returns {JSX.Element}
 * @constructor
 */
export default function DatePicker({
    inputRef = {},
    fieldState = {},
    name,
    label,
    value,
    options,
    onChange,
    onBlur,
}) {
    options = {...defaultOptions, ...options}
    const ref = useRef()
    const {data: country} = useCountry()
    const localizeObject = useLocalizeObject()
    const {currentLanguage} = useCurrentLanguage()
    const {className} = useInputValidationClassNames({
        className: 'fieldset -date',
        value,
        fieldState,
        params: {
            '-icons': !!options.renderIcon || options.isLoading,
        },
        deps: [options.renderIcon, options.isLoading],
    })
    const selectedValue = useMemo(() => {
        if (!value) return null
        try {
            return new Date(value)
        } catch (e) {
            return null
        }
    }, [value])
    const months = useMemo(() => {
        return new Array(12)
            .fill(0)
            .map((i, idx) => {
                const date = new Date()
                date.setMonth(idx, 1)
                return dateFns.format(date, 'MMMM', {locale: locales[getCountryCode(currentLanguage)]})
            })
            .map(m => `${m.charAt(0).toUpperCase()}${m.slice(1)}`)
    }, [currentLanguage])
    const years = useMemo(() => {
        return new Array(20)
            .fill(dateFns.getYear(new Date()))
            .map((i, idx) => i - idx)
    }, [])

    const handleChange = (date) => {
        if (!date) {
            onChange(null)
            return
        }
        onChange(date.toISOString())
    }

    const openCalendar = (e) => {
        if(options.inputProps?.disabled)
            return
        ref.current.setOpen(true)
    }

    const HeaderComponent = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => (
        <div className="datePicker__header">
            {!prevMonthButtonDisabled ? (
                <button type="button" className="datePicker__navButton" onClick={decreaseMonth}>
                    <MdOutlineChevronLeft/>
                </button>
            ) : <span/>}
            <div className="datePicker__headerContent">
                <select
                    value={dateFns.getMonth(date)}
                    className="datePicker__select"
                    style={{flex: 1}}
                    onChange={({target}) => changeMonth(target.value)}
                >
                    {months.map((m, idx) => (
                        <option key={idx} value={idx}>{m}</option>
                    ))}
                </select>
                <select
                    value={dateFns.getYear(date)}
                    className="datePicker__select"
                    onChange={({target}) => changeYear(target.value)}
                >
                    {years.map(y => (
                        <option key={y} value={y}>{y}</option>
                    ))}
                </select>
            </div>
            {!nextMonthButtonDisabled ? (
                <button type="button" className="datePicker__navButton" onClick={increaseMonth}>
                    <MdOutlineChevronRight/>
                </button>
            ) : <span/>}
        </div>
    )

    return (
        <fieldset className={className}>
            <div className="fieldset__wrapper">
                <ReactDatePicker
                    ref={r => {
                        inputRef.current = r
                        ref.current = r
                    }}
                    name={name}
                    selected={selectedValue}
                    dateFormat={country.country_custom_format.date_format || "dd/MM/yyyy"}
                    placeholderText={localizeObject(country.country_custom_format.date_format_placeholder) || "DD/MM/YYYY"}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="fieldset__input"
                    autoComplete="off"
                    // open={isOpen}
                    renderCustomHeader={HeaderComponent}
                    maxDate={new Date()}
                    onChange={handleChange}
                    onBlur={onBlur}
                    {...options.inputProps}
                />
                <label htmlFor={name} className="fieldset__icons">
                    {options.isLoading ? (
                        <InputSpinner/>
                    ) : options.renderIcon ? (
                        options.renderIcon()
                    ) : (
                        <button type="button" onClick={openCalendar}>
                            <img src={calendarImg} alt=""/>
                        </button>
                    )}
                </label>
                <InputLabel
                    name={name}
                    value={value}
                    label={label}
                    options={options}
                />
            </div>
            <InvalidFeedback error={fieldState.error}/>
        </fieldset>
    )
}

const defaultOptions = {
    inputProps: null,
    selectProps: null,
    isRequired: false,
    isLoading: false,
    maxLength: 0,
    renderIcon: null,
}

const codes = {
    'cz': 'cs',
    'gr': 'el',
    'dk': 'da',
    'jp': 'ja',
};

function getCountryCode(code) {
    return codes[code] ?? code;
}
