import {useCurrentLanguage, useFieldFormat, useSerialNumber, useTranslation} from "../hooks";
import {useForm, useWatch} from "react-hook-form";
import {Form} from "./index";
import {FormGroup, FormRow, InputSpinner} from "./components";
import {useFormRulesBuilder} from "./hooks";
import React, {useEffect, useMemo} from "react";
import {EmailInput, SerialNumberInput} from "./presets";
import {Button, ButtonClose} from "../components/buttons/_index";
import {MaskInputController, TextInputController} from "./controllers";
import {useCountryConfig} from "../misc/Hooks";

export default function BurnForm({
    onSubmit,
    state,
    isSuccess,
}) {
    state = {
        isLoading: false,
        error: null,
        ...state,
    }

    const {t} = useTranslation();
    const {formats} = useFieldFormat()
    const countryConfig = useCountryConfig()
    const styles = useMemo(() => ({
        input: {
            letterSpacing: 1,
        },
    }), [])
    const formMethods = useForm({
        mode: 'onChange',
    })
    const formRules = useFormRulesBuilder({
        code: b => b.maxLength(countryConfig?.promo_code_regex_max_length),
        serialNumber: b => b.pattern(formats.serialNumber.pattern, t('error.serialnumber_to_short')),
    }, formMethods)

    const handleSubmit = (data) => {
        console.log(data)
        if (typeof onSubmit === 'function')
            onSubmit(data)
    }

    const code = useWatch({
        control: formMethods.control,
        name: 'code'
    })

    const serialNumber = useWatch({
        control: formMethods.control,
        name: 'serialNumber'
    })

    useEffect(() => {
        if(isSuccess)
            formMethods.reset()
    }, [isSuccess])

    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            onSubmit={handleSubmit}
        >
            <FormGroup>
                <TextInputController
                    name="code"
                    label={t('promo_code')}
                />
            </FormGroup>
            <FormGroup>
                <MaskInputController
                    name="serialNumber"
                    mask={formats.serialNumber.getMask()}
                    label={t('serial_number')}
                    options={{
                        inputProps: {
                            style: styles.input,
                        },
                    }}
                />
            </FormGroup>

            <Button
                type="primary"
                btnType="submit"
                label={t('reseller_burn.burn')}
                isLoading={state.isLoading}
                disabled={(!code && !serialNumber) || state.isLoading}
            />
        </Form>
    )
}