import {TextInputController} from "../controllers";
import React from "react";
import {useTranslation} from "../../hooks";

export default function PhoneticNameInput(props) {
    const {t} = useTranslation()

    return (
        <TextInputController
            name="phoneticName"
            label={t('phonetic_name')}
            {...props}
        />
    )
}
