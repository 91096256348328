import {Route, Switch} from "react-router-dom";
import Routes from "../../utils/Routes";
import React, {useEffect} from "react";
import Provider from "./Provider";
import StepsFunnel from "./StepsFunnel";
import {useSteps} from "../../hooks/modules/machine_registration";
import {getStepsRoutes} from "../../misc/Helpers";
import {AppProvider, GTM} from "../../misc/_index";
import {useSelector} from "react-redux";
import AbandonedOrderConsent from "../../components/AbandonedOrderConsent";
import {makeStore, ModuleProvider, useGTM} from "../../hooks";
import {OrderFinalizedScreen} from "../../screens";


export default function Funnel(props) {
    const steps = useSteps()

    const contextValue = makeStore({
        storeName: 'machineRegistration',
        steps,
    })

    return (
        <AppProvider>
            <ModuleProvider value={contextValue}>
                <Provider>
                    <Switch>
                        <Route
                            exact path={[
                            ...getStepsRoutes(steps),
                        ]}
                            component={StepsFunnel}
                        />

                        <Route
                            exact
                            path={Routes.MACHINE_REGISTRATION_ORDER_FINALIZED}
                            component={OrderFinalizedScreen}
                        />
                    </Switch>

                    <AbandonedOrderConsent storeName={contextValue.storeName}/>
                </Provider>
            </ModuleProvider>
        </AppProvider>
    )
}
