import {useTranslation} from "../hooks";
import {useForm, useWatch} from "react-hook-form";
import {useFormRulesBuilder} from "./hooks";
import {FormGroup} from "./components";
import {SelectController} from "./controllers";
import {Form} from "./index";
import {useMarketConfig} from "../misc/Hooks";
import React, {Fragment, useEffect} from "react";
import {FirstnameInput, LastnameInput} from "./presets";
import {Button} from "../components/buttons/_index";

export default function ResellerShopSelectionForm({
    state,
    onSubmit,
    shops,
    submitText
}) {
    state = {
        isLoading: false,
        error: null,
        ...state,
    }

    const {t} = useTranslation();
    const {reseller_custom_name: customNameReseller} = useMarketConfig()
    const formMethods = useForm({
        mode: 'onChange',
    })

    const formRules = useFormRulesBuilder({
        'shop': b => b.required(),
        'firstname': b => b.required(),
        'lastname': b => b.required(),
    }, formMethods)

    const handleSubmit = (data) => {
        if (typeof onSubmit === 'function')
            onSubmit(data)
    }

    const shop = useWatch({
        control: formMethods.control,
        name: 'shop'
    })
    const firstname = useWatch({
        control: formMethods.control,
        name: 'firstname'
    })
    const lastname = useWatch({
        control: formMethods.control,
        name: 'lastname'
    })

    useEffect(() => {
        if(shops.length === 1 && !customNameReseller){
            handleSubmit({
                shop: shops[0]
            })
        }
    }, [customNameReseller, shops])


    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            onSubmit={handleSubmit}
        >
            <FormGroup>
                <SelectController
                    name="shop"
                    selectOptions={shops}
                    label={t('shop')}
                />
            </FormGroup>

            {!!customNameReseller && (
                <Fragment>
                    <FormGroup>
                        <FirstnameInput/>
                    </FormGroup>
                    <FormGroup>
                        <LastnameInput/>
                    </FormGroup>
                </Fragment>
            )}

            <Button
                btnType="submit"
                type="primary"
                label={submitText ?? t('continue')}
                disabled={!!customNameReseller ? (!shop || !firstname || !lastname) : !shop}
                isLoading={state.isLoading}
            />
        </Form>
    )
}