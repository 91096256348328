import Provider from "./Provider";
import Header from "../../components/misc/Header";
import React from "react";
import {Route, Switch} from "react-router-dom";
import Routes from "../../utils/Routes";
import {useNavigation} from "../../misc/Hooks";
import {useSelector} from "react-redux";
import FastOrderCustomerContainer from "./containers/FastOrderCustomerContainer";
import {AppProvider} from "../../misc/_index";

export default function Funnel() {
    const navigation = useNavigation();
    const store = useSelector(({main}) => ({
        user: main.user,
    }));


    return (
        <AppProvider>
            <Provider>
                <div className="app">
                    <Header
                        cartDisabled
                        alertsDisabled
                    />
                    <div className="main">
                        <div className="wrapper-bg formContainer">
                            <div className="wrapper -fill">

                                <Switch>
                                    <Route exact path={Routes.FAST_ORDER_CUSTOMER}
                                           component={FastOrderCustomerContainer}/>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </Provider>
        </AppProvider>
    )
}