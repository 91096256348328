import {FormGroup, FormRow} from "../components";
import {PostcodeInput} from "./index";
import {useFieldFormat, useFormHelper, useTranslation} from "../../hooks";
import {useWatch} from "react-hook-form";
import React, {useEffect} from "react";
import {FormError, useFetchCitiesByPostcode} from "../hooks";
import {SelectController} from "../controllers";

export default function CityByPostcodeInputs({}) {
    const {t} = useTranslation()
    const {formMethods, ...formHelpers} = useFormHelper()
    const {formats, getIfValid} = useFieldFormat()
    const postcode = useWatch({
        control: formMethods.control,
        name: formHelpers.getName('postcode'),
    })
    const city = useWatch({
        control: formMethods.control,
        name: formHelpers.getName('city'),
    })
    const {query, selectOptions} = useFetchCitiesByPostcode(getIfValid(postcode, formats.postcode.pattern))

    useEffect(() => {
        formHelpers.clearErrors('city')
        if (query.isSuccess && city && !selectOptions.find(o => o.value === city.value)) {
            formHelpers.setValue('city', null)
            return
        }
        if (query.isError) {
            formHelpers.setError('postcode', FormError('invalid', t('validation.invalid')))
            formHelpers.setError('city', FormError('invalid', t('validation.invalid')))
        }
    }, [query.isError, query.isSuccess])

    useEffect(() => {
        if(!city && query.isSuccess && selectOptions.length === 1) {
            formHelpers.setValue('city', selectOptions[0])
        }
    }, [selectOptions, postcode])

    return (
        <FormRow ignoreBottomMargin>
            <FormGroup>
                <PostcodeInput/>
            </FormGroup>
            <FormGroup>
                <SelectController
                    name="city"
                    label={t('city')}
                    rules="citySelect"
                    selectOptions={selectOptions}
                    options={{
                        isLoading: query.isLoading && query.isFetching,
                    }}
                />
            </FormGroup>
        </FormRow>
    )
}
