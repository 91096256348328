import {AsyncSelectController} from "../controllers";
import React from "react";
import {useCountry, useTranslation} from "../../hooks";
import {getShopRegion2} from '../../actions/ShopActions';
import {useAsyncSelect} from "../hooks";
import {SelectOption} from "../../services";

export default function ShopRegionSelect(props) {
    const {t} = useTranslation()
    const country = useCountry()
    const asyncSelect = useAsyncSelect({
        name: 'shopRegions',
        action: params => getShopRegion2(country.data.id_country, params),
        paramFunc: (slug) => ({
            search: slug,
            limit: 100,
        }),
        mapFunc: o => new SelectOption(o, o),
        queryOptions: {
            refetchOnWindowFocus: false,
        },
    })

    return (
        <AsyncSelectController
            asyncSelect={asyncSelect}
            name="region"
            label={t('shop_region')}
            {...props}
            options={{
                nullable: true,
                ...props.options,
            }}
        />
    )
}
