import {useSelector} from "react-redux";
import {useLanguage} from "./index";
import {useMemo} from "react";
import objectMerge from 'object-merge';

let translationLanguage = null
let translationObject = null

export default function useTranslation() {
    const currentLanguage = useLanguage()
    const store = useSelector(({main}) => ({
        country: main.country,
        translations: main.translations,
    }))
    const translations = useMemo(() => {
        if(!translationObject || translationLanguage !== currentLanguage) {
            translationObject = getTranslations(currentLanguage, store.country?.country_shortcode, store.translations)
            translationLanguage = currentLanguage
        }
        return translationObject
    }, [currentLanguage, store.translations])

    const t = (name, ...args) => {
        let slug = {...translations};
        const params = name.toString().split('.');

        params.forEach(s => {
            if (typeof slug !== "undefined")
                slug = slug[s];
            else
                slug = name;
        })

        if (!slug) {
            slug = '';
        }

        args.map(arg => {
            if (typeof arg === 'object' && arg !== null) {
                slug = slug.toString().replace(`{${arg.name}}`, arg.value);
            } else {
                slug = slug.toString().replace('{arg}', arg);
            }
        })
        slug = slug.toString().replace(/\{arg}/g, '')
            .replace(/\{[a-zA-Z0-9]+}/g, '');


        return slug;
    }

    return {t}
}


function getTranslations(language, country = null, translations = null) {
    const baseFile = require(`../assets/translations/_base.json`);

    let marketTranslations = {}
    let countryTranslations = {}
    let customTranslations = {}
    try {
        marketTranslations = require(`../assets/translations/${process.env.REACT_APP_MARKET}/${language}.json`);
    } catch (e) {}
    try {
        if(country) {
            countryTranslations = require(`../assets/translations/${process.env.REACT_APP_MARKET}/${country}/${language}.json`);
        }
    } catch (e) {}


    if(translations && translations[language]) {
        customTranslations = translations[language]
    }

    return objectMerge(objectMerge(baseFile, objectMerge(marketTranslations, countryTranslations)), customTranslations)
}

