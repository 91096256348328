import {useMemo} from "react";
import {makeClassName} from "../../helpers";

/**
 * useInputValidationClassNames
 * @param className
 * @param value
 * @param error
 * @param fieldState
 * @param params
 * @param deps
 * @returns {{isValid: boolean, isInvalid: boolean, className: string}}
 */
export default function useInputValidationClassNames({className = 'fieldset', value, fieldState, params = null, deps = []}) {
    return useMemo(() => {
        const isValid = value && !fieldState.invalid
        const isInvalid = !!fieldState.error
        return {
            isValid,
            isInvalid,
            className: makeClassName(className,{
                '-isValid': isValid,
                '-isInvalid': isInvalid,
                ...params,
            }),
        }
    }, [value, fieldState.error, fieldState.isDirty, fieldState.invalid, ...deps])
}

