import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Env from "../utils/Env";
import * as rmsReducer from "../redux/rmsReducer";
import * as mainReducer from "../redux/mainReducer";
import * as evReducer from "../redux/evReducer";
import * as subscriptionReducer from "../redux/subscriptionReducer";
import * as resellerReducer from "../redux/resellerReducer";
import {useLanguage, useReseller} from "../hooks";
import {queryParams} from "../misc/Helpers";
import Axios from "../utils/Axios";


const instance = axios.create()
instance.defaults.baseURL = Env.API_URL + '/reseller/';
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const baseUrlV2 = {baseURL: Env.API_URL + '/v2/reseller/'}
export const useResellerActions = () => {
    const dispatch = useDispatch();
    const currentLanguage = useLanguage();
    const {shopId} = useReseller()
    const store = useSelector(({main}) => ({
        userToken: main.userToken,
    }))

    instance.defaults.headers.common['Language'] = currentLanguage;
    instance.defaults.headers.get['Authorization'] = store.userToken ? `Bearer ${store.userToken}` : ''
    instance.defaults.headers.post['Authorization'] =  store.userToken ? `Bearer ${store.userToken}` : ''
    instance.defaults.headers.put['Authorization'] =  store.userToken ? `Bearer ${store.userToken}` : ''
    instance.defaults.headers.common['Shop'] = shopId;

    instance.interceptors.response.use(r => {
        const res = r.data;
        if (res?.status === 'error') {
            if (res.messages.find(m => m === 'reseller_not_logged')) {
                logout()
            }
        }
        return r;
    }, err => err)

    const logout = () => {
        dispatch(subscriptionReducer.clearStore())
        dispatch(rmsReducer.clearStore())
        dispatch(evReducer.clearStore())
        dispatch(mainReducer.clearUser())
        dispatch(resellerReducer.clearStore())
    }

    const getPlanByMachineId = (id, countryId) => instance.get(`plan-coffee/${id}/${countryId}`);
    const getBanks = () => instance.get(`/banks`);
    const generateCode = (data, planId) => instance.post(`/generate-code/${planId}`, data);
    const verifyCode = (code, token) => instance.post(`/check-code/${code}/${token}`);

    const forgotPassword = (data) => instance.post(`/forgot-password`, data);
    const isForgotPasswordTokenValid = (token) => instance.get(`/change-password/${token}`);
    const changePassword = (token, data) => instance.post(`/change-password/${token}`, data);

    const getCategories = (promotionId, machineId, shopId, userLogged) => {
        // return instance.get(`/categories/${promotionId}/${machineId}/${shopId}/${userLogged}`);
        return Axios.get(`/v2/promotions/${promotionId}/products/${machineId}/${shopId}/${userLogged}`);
    }

    /**
     * @param params
     * @param params.promotionId
     * @param params.machineId
     * @param params.shopId
     * @param params.isExistingMember
     * @param paramObj
     * @returns {Promise<AxiosResponse<never>>}
     */
    const fetchAllCategories = (params, paramObj) => {
        // return instance.get(`/categories/${promotionId}/${machineId}/${shopId}/${userLogged}`);
        return Axios.get(`/v2/promotions/${params.promotionId}/products/${params.machineId}/${params.shopId}/${params.isExistingMember}${queryParams(paramObj)}`);
    }

    const getResellerChildren = (resellerId = 0, params) => {
        return instance.get(`/childs?id_reseller=${resellerId}&${params}`)
    }

    const getResellerChildrenV2 = (params) => {
        return instance.get(`/childs${queryParams(params)}`)
    }

    const getNewRequest = (params) => {
        return instance.get(`/unverified?${params}`)
    }

    const getNewRequestV2 = (params) => {
        return instance.get(`/unverified${queryParams(params)}`)
    }

    const certificateRenewal = (data) => instance.post(`/certificate`, data);

    const sendResetPasswordEmail = (resellerId) => {
        return instance.get(`/password-reset?id_reseller=${resellerId}`)
    }

    const changeResellerStatus = (data) => instance.post(`/status`, data);

    const getResellerEditData = (resellerId) => {
        return instance.get(`/form-data?id_reseller=${resellerId}`)
    }

    const resellerUpdate = (data) => instance.post(`/update`, data);
    const resellerRegister = (data) => instance.post(`/register`, data);

    const resellerVerify = (data) => instance.post(`/verify`, data);

    const getReportsResellerOptions = () => {
        return instance.get(`/reports/resellers`)
    };
    const getGeneratedReports = (params) => {
        return instance.get(`/reports?${params}`)
    };
    const getGeneratedReportsV2 = (params) => instance.get(`/reports${queryParams(params)}`)

    const generateRaport = (data) => instance.post(`/reports/generate`, data)

    const getReportsFilters = (params) => instance.get(`/reports/filters${queryParams(params)}`);

    const reportsFilter = (params) => instance.get(`/reports/filters${queryParams(params)}`);

    const getResellerList = (params) => instance.get(`/reports/list${queryParams(params)}`)
    const getResellerListV2 = (params) => instance.get(`/reports/v2/list${queryParams(params)}`)

    const getSubscriptions = (type, params = '') => {
        return instance.get(`/subscriptions/${type}?${params}`)
    }

    const getOrders = (data, params = '') => {
        return instance.post(`/reports/orders${params}`, data)
    }
    const getOrdersV2 = (data, params = {}) => {
        return instance.post(`/reports/orders${queryParams(params)}`, data)
    }

    const resendOrder = (id) => {
        return instance.post(`/resend/${id}`)
    }

    const resendFastOrder = (id) => {
        return instance.post(`/order/fast/resend/${id}`)
    }

    const processOrder = (id) => {
        return instance.post(`/process/${id}`)
    }

    const saveSubscription = (data) => {
        return instance.post(`/subscription`, data);
    }

    const saveFastOrder = (data) => {
        return instance.post(`/order/fast`, data);
    }

    const blockCode = (params) => {
        return instance.get(`/burn${queryParams(params)}`)
    }

    const getAssistedSubscription = (id_machine, id_shop = 0, id_country = 0) => {
        return instance.get(`/plan-coffee/${id_machine}/${id_shop}/${id_country}`)
    }
    const getPlans = ({machineId = 0, countryId = 0, shopId = 0}) => {
        return instance.get(`/plan-coffee/${machineId}/${shopId}/${countryId}`)
    }

    const getThreads = (obj) => {
        const params = queryParams(obj)
        return instance.get(`/communication/threads${params}`)
    }

    const getThread = (id) => {
        return instance.get(`/communication/threads/${id}`)
    }

    const getMessage = (id) => {
        return instance.get(`/communication/threads/message/${id}`)
    }

    const getThreadMessages = (id, obj) => {
        const params = queryParams(obj)
        return instance.get(`/communication/threads/${id}/messages${params}`)
    }

    const fetchUnreadMessages = () => {
        return instance.get(`/communication/threads/unread-messages`)
    }

    const sendThreadMessage = (id, data) => {
        return instance.post(`/communication/threads/${id}/send-message`, data, {
        })
    }

    const sendMessage = (id, data) => {
        return instance.post(`/communication/threads/${id}/send-thread-message`, data)
    }

    const createThread = (id) => {
        return instance.post(`/communication/threads/create/${id}`)
    }

    const putResellerQrScannedChoice = ({uuid, was_qr_code_scanned}) => {
        return instance.put(`/order/save/${uuid}/qr-choice`, {was_qr_code_scanned})
    }

    const searchNespressoMembers = (data) => {
        return instance.post(`/v2/reseller/search-customer`, data, {
            baseURL: Env.API_URL,
        })
    }

    const getMemberAddressesByMemberNumber = (memberNumber) => {
        return instance.get(`/v2/reseller/get-addresses/${memberNumber}`, {
            baseURL: Env.API_URL,
        })
    }

    const sendVerificationCodeAssistedSimplifiedFlow = (data) => {
        return instance.post(`/v2/reseller/assisted-simplified-flow/verification/send`, data, {
            baseURL: Env.API_URL,
        })
    }

    const verifyCodeAssistedSimplifiedFlow = (data) => {
        return instance.post(`/v2/reseller/assisted-simplified-flow/verification/verify`, data, {
            baseURL: Env.API_URL,
        })
    }

    const fetchResellerPos = (params) => {
        return instance.get(`local-stock/reseller-shops${queryParams(params)}`, baseUrlV2)
    }

    const fetchPosStock = (idPos, params) => {
        return instance.get(`local-stock/${idPos}${queryParams(params)}`, baseUrlV2)
    }

    const updatePosStock = (idPos, data) => {
        return instance.post(`local-stock/update/${idPos}`, data, baseUrlV2)
    }

    const fetchPos = (idPos) => {
        return instance.get(`local-stock/shop-name/${idPos}`, baseUrlV2)
    }

    const localStockPromotionsExists = (shopId) => {
        return instance.get(`/v2/reseller/local-stock/promotions?id_shop=${shopId}`, {
            baseURL: Env.API_URL,
        })
    }

    const finalizeOrder = (uuid) => {
        return instance.get(`/v2/reseller/local-stock/qrcode/${uuid}`, {
            baseURL: Env.API_URL,
        })
    }

    const fetchPosStockRequests = (idShop, params) => {
        return instance.get(`local-stock/get-requests/${idShop}${queryParams(params)}`, baseUrlV2)
    }

    const createPosStockRequest = (idShop, data) => {
        return instance.post(`local-stock/request-products/${idShop}`, data, baseUrlV2)
    }

    const finalizePosStockRequest = (idShop, data) => {
        return instance.post(`local-stock/finalize-request/${idShop}`, data, baseUrlV2)
    }

    const cancelPosStockRequest = (idShop, data) => {
        return instance.post(`local-stock/cancel-request/${idShop}`, data, baseUrlV2)
    }

    const deliverOrder = (data) => {
        return instance.post(`local-stock/deliver-order`, data, baseUrlV2)
    }

    const resendPosStockRequest = (idShop, data) => {
        return instance.post(`local-stock/resend-request-message/${idShop}`, data, baseUrlV2)
    }

    const fetchStockRequestLog = (idRequest) => {
        return instance.get(`local-stock/request-history/${idRequest}`, baseUrlV2)
    }
    return {
        getPlanByMachineId,
        getBanks,
        generateCode,
        verifyCode,
        forgotPassword,
        isForgotPasswordTokenValid,
        changePassword,
        getCategories,
        getResellerChildren,
        getResellerChildrenV2,
        certificateRenewal,
        sendResetPasswordEmail,
        changeResellerStatus,
        getResellerEditData,
        resellerUpdate,
        resellerRegister,
        getNewRequest,
        getNewRequestV2,
        resellerVerify,
        getReportsResellerOptions,
        generateRaport,
        getGeneratedReports,
        getGeneratedReportsV2,
        getReportsFilters,
        reportsFilter,
        getResellerList,
        getResellerListV2,
        getSubscriptions,
        getOrders,
        getOrdersV2,
        resendOrder,
        resendFastOrder,
        processOrder,
        saveSubscription,
        saveFastOrder,
        blockCode,
        logout,
        getAssistedSubscription,
        getThreads,
        getThread,
        getMessage,
        getThreadMessages,
        fetchUnreadMessages,
        sendThreadMessage,
        sendMessage,
        createThread,
        putResellerQrScannedChoice,
        fetchAllCategories,
        getPlans,
        searchNespressoMembers,
        getMemberAddressesByMemberNumber,
        sendVerificationCodeAssistedSimplifiedFlow,
        verifyCodeAssistedSimplifiedFlow,
        fetchResellerPos,
        fetchPosStock,
        updatePosStock,
        fetchPos,
        fetchPosStockRequests,
        createPosStockRequest,
        finalizePosStockRequest,
        cancelPosStockRequest,
        localStockPromotionsExists,
        finalizeOrder,
        deliverOrder,
        resendPosStockRequest,
        fetchStockRequestLog
    }
}
