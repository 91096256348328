import {Header} from "../../components/misc/_index";
import React, {useEffect} from "react";
import Footer from "../../components/misc/Footer";
import {TpsForm, useTps} from "./index";
import {Preloader} from "../../misc/_index";
import {InfoComponent} from "../../components/_index";
import {useModuleContext, useTranslation} from "../../hooks";
import {Button} from "../../components/buttons/_index";
import {clearStore} from "../../redux/tpsReducer";

export default function TpsScreen(){

    const {
        store,
        countryMutation,
        stacksMutation,
        machinesMutation,
        handleSubmit,
        cartError,
        saveMessage,
        isLoading
    } = useTps()

    const {t} = useTranslation()
    const {dispatch, reducer} = useModuleContext()

    useEffect(async () => {

        await dispatch(reducer.clearStore())

        countryMutation.mutate()
        machinesMutation.mutate()
        stacksMutation.mutate()
    }, [])


    return (
        <div className="app">
            <Header cartDisabled/>
            <div className="main">
                <div className="wrapper-bg formContainer">
                    <div className="wrapper">
                                {(!!store.country && machinesMutation.isSuccess && stacksMutation.isSuccess)
                                    ? <TpsForm
                                        onSubmit={handleSubmit}
                                        cartError={cartError}
                                        state={{isLoading: isLoading, isDisabled: isLoading || saveMessage.type === 'success'}}
                                        hideButtons={saveMessage.type === 'success'}
                                    />
                                    : <Preloader/>}

                        <div className="grid">
                            <div>
                                {!!saveMessage.message && (
                                    <InfoComponent liteShadow message={saveMessage.message} type={saveMessage.type}/>
                                )}
                                {saveMessage.type === 'success' && (
                                    <div>
                                        <Button
                                            label={t('tps.clear_and_reload_page')}
                                            onClick={() => window.location.reload()}
                                        />
                                    </div>
                                )}
                            </div>
                            <div/>
                        </div>

                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}