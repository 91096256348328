import {Error404, Footer, Header} from "../../components/misc/_index";
import {Config, Routes} from "../../utils/_index";
import React, {useEffect, useState} from "react";
import {useCountryConfig, useMarketConfig, useNavigation} from "../../misc/Hooks";
import {Route, Switch} from "react-router-dom";
import {Breadcrumb} from "../../components/_index";
import {useReseller} from "../../hooks";
import {
    AdminAndReportsScreen,
    AssistedRegistrationScreen,
    BurnScreen,
    DashboardScreen,
    FastOrderScreen, FinalizeOrderScreen,
    MachineSubscriptionScreen,
    NoMachineShopScreen
} from "./screens";
import Provider from "./Provider";
import {useIdleTimer} from "react-idle-timer";
import * as resellerReducer from "../../redux/resellerReducer";
import {useDispatch} from "react-redux";
import {isEnabledByMarket} from "../../utils/MarketConfig";
import {permissions} from "../../misc/PermissionsConfig";
import Error401 from "../../components/misc/Error401";
import {UserScreen, UsersScreen} from "./users/screens";
import {ReportsScreen} from "./reports/screens";
import {AxScreen} from "../reseller_v2/ax/screens";
import {MessageScreen, MessagesScreen, NewMessageScreen} from "./messages/screens";
import {PSMChoicePosScreen, PSMPosStockScreen} from "./posStockManagement/screens";

const ROUTE_LIST = [
    {
        route: Routes.V2_RESELLER,
        size: "",
        component: DashboardScreen,
        trace: [
            {
                route: null,
                name: 'reseller'
            }
        ]
    },
    {
        route: Routes.V2_RESELLER_ASSISTED_REGISTRATION,
        size: "-xxl",
        component: AssistedRegistrationScreen,
        permission: permissions.assistedRegistration,
        trace: [
            {
                route: Routes.V2_RESELLER,
                name: 'reseller'
            },
            {
                route: null,
                name: 'reseller_options.assisted_registration'
            }
        ]
    },
    {
        route: Routes.V2_RESELLER_FAST_ORDER,
        component: FastOrderScreen,
        size: "",
        permission: permissions.fastOrder,
        trace: [
            {
                route: Routes.V2_RESELLER,
                name: 'reseller'
            },
            {
                route: Routes.V2_RESELLER_ASSISTED_REGISTRATION,
                name: 'reseller_options.assisted_registration'
            },
            {
                route: null,
                name: 'fast_order.title'
            },
        ]
    },
    {
        route: Routes.V2_RESELLER_MACHINE_SUBSCRIPTIONS,
        component: MachineSubscriptionScreen,
        permission: permissions.machineSubscription,
        size: "-xxl",
        trace: [
            {
                route: Routes.V2_RESELLER,
                name: 'reseller'
            },
            {
                route: null,
                name: 'reseller_options.machine_subscription'
            }
        ]
    },
    {
        route: Routes.V2_RESELLER_ADMIN_REPORTS,
        component: AdminAndReportsScreen,
        permission: permissions.adminAndReports,
        size: "",
        trace: [
            {
                route: Routes.V2_RESELLER,
                name: 'reseller'
            },
            {
                route: null,
                name: 'reseller_options.admin_and_reports'
            },
        ]
    },
    {
        route: Routes.V2_RESELLER_USERS,
        component: UsersScreen,
        permission: permissions.userManagement,
        size: "-xxl",
        trace: [
            {
                route: Routes.V2_RESELLER,
                name: 'reseller'
            },
            {
                route: Routes.V2_RESELLER_ADMIN_REPORTS,
                name: 'reseller_options.admin_and_reports'
            },
            {
                route: null,
                name: 'reseller_options.users'
            }
        ]
    },
    {
        route: Routes.V2_RESELLER_USERS_USER,
        permission: permissions.userManagement,
        component: UserScreen,
        name: 'users.edit',
        trace: [
            {
                route: Routes.V2_RESELLER,
                name: 'reseller'
            },
            {
                route: Routes.V2_RESELLER_ADMIN_REPORTS,
                name: 'reseller_options.admin_and_reports'
            },
            {
                route: Routes.V2_RESELLER_USERS,
                name: 'reseller_options.users'
            },
            {
                route: null,
                name: 'reseller_client.edit_user'
            }
        ]
    },
    {
        route: Routes.V2_RESELLER_REPORTS,
        component: ReportsScreen,
        permission: permissions.reporting,
        size: "-xxl",
        trace: [
            {
                route: Routes.V2_RESELLER,
                name: 'reseller'
            },
            {
                route: Routes.V2_RESELLER_ADMIN_REPORTS,
                name: 'reseller_options.admin_and_reports'
            },
            {
                route: null,
                name: 'reseller_options.reports'
            }
        ]
    },
    {
        route: Routes.V2_RESELLER_STOCK_MANAGEMENT,
        component: PSMChoicePosScreen,
        permission: permissions.posStockManagement,
        size: "-xxl",
        trace: [
            {
                route: Routes.V2_RESELLER,
                name: 'reseller'
            },
            {
                route: Routes.V2_RESELLER_ADMIN_REPORTS,
                name: 'reseller_options.admin_and_reports'
            },
            {
                route: null,
                name: 'reseller_options.pos_stock_management'
            }
        ]
    },
    {
        route: Routes.V2_RESELLER_STOCK_MANAGEMENT_POS,
        component: PSMPosStockScreen,
        permission: permissions.posStockManagement,
        size: "-xxl",
        trace: [
            {
                route: Routes.V2_RESELLER,
                name: 'reseller'
            },
            {
                route: Routes.V2_RESELLER_ADMIN_REPORTS,
                name: 'reseller_options.admin_and_reports'
            },
            {
                route: Routes.V2_RESELLER_STOCK_MANAGEMENT,
                name: 'reseller_options.pos_stock_management'
            },
            {
                route: null,
                name: 'stock_management.pos'
            }
        ]
    },
    {
        route: Routes.V2_RESELLER_NO_MACHINE_SHOP,
        permission: permissions.noMachineShop,
        component: NoMachineShopScreen,
        size: "-xxl",
        trace: [
            {
                route: Routes.V2_RESELLER,
                name: 'reseller'
            },
            {
                route: null,
                name: 'reseller_options.no_machine_shop'
            }
        ]
    },
    {
        route: Routes.V2_RESELLER_MESSAGES,
        component: MessagesScreen,
        permission: permissions.messages,
        size: "",
        trace: [
            {
                route: Routes.V2_RESELLER,
                name: 'reseller'
            },
            {
                route: null,
                name: 'reseller_options.messages'
            }
        ]
    },
    {
        route: Routes.V2_RESELLER_NEW_MESSAGE,
        component: NewMessageScreen,
        permission: permissions.messages,
        size: "",
        trace: [
            {
                route: Routes.V2_RESELLER,
                name: 'reseller'
            },
            {
                route: Routes.V2_RESELLER_MESSAGES,
                name: 'reseller_options.messages'
            },
            {
                route: null,
                name: 'reseller_options.new_message'
            }
        ]
    },
    {
        route: Routes.V2_RESELLER_MESSAGE,
        component: MessageScreen,
        permission: permissions.messages,
        size: "",
        trace: [
            {
                route: Routes.V2_RESELLER,
                name: 'reseller'
            },
            {
                route: Routes.V2_RESELLER_MESSAGES,
                name: 'reseller_options.messages'
            },
            {
                route: null,
                name: 'reseller_options.message'
            }
        ]
    },
    {
        route: Routes.V2_RESELLER_BURN,
        component: BurnScreen,
        permission: permissions.burn,
        size: "",
        trace: [
            {route: Routes.V2_RESELLER, name: 'reseller'},
            {route: null, name: 'reseller_burn.title'}
        ]
    },
    {
        route: Routes.V2_RESELLER_AX,
        component: AxScreen,
        permission: permissions.ax,
        size: "",
        trace: [
            {route: Routes.V2_RESELLER, name: 'reseller'},
            {route: null, name: 'reseller_ax.title'}
        ]
    },
    {
        route: Routes.V2_RESELLER_FINALIZE_ORDER,
        component: FinalizeOrderScreen,
        permission: permissions.assistedRegistration,
        size: "",
        trace: [
            {route: Routes.V2_RESELLER, name: 'reseller'},
            {route: null, name: 'reseller_finalize_order.title'}
        ]
    },
]

export default function Funnel() {
    const navigation = useNavigation()
    const marketConfig = useMarketConfig()
    const countryConfig = useCountryConfig()
    const {hasPermission} = useReseller()

    const canRender = (name) => {
        if (name === undefined) return true
        return marketConfig.resellerModuleIsEnabled(name) && countryConfig.resellerModuleIsEnabled(name) && hasPermission(name)
    }


    return (
        <Provider>
            <div className="app">
                <Header
                    homeRoute={navigation(Routes.V2_RESELLER)}
                    cartDisabled
                />
                <div className="main">
                    <div className="wrapper-bg formContainer">
                        <Switch>
                            {ROUTE_LIST.map((item, idx) => {
                                if (canRender(item?.permission))
                                    return <Route key={idx} exact path={item.route}
                                                  render={() => <Breadcrumb item={item}/>}/>
                            })}
                        </Switch>
                        <Switch>
                            {ROUTE_LIST.map(item => {


                                if (!hasPermission(item?.permission))
                                    return <Route key={item.route} exact path={item.route} component={Error401}/>


                                if (marketConfig.resellerModuleIsEnabled(item?.permission) && countryConfig.resellerModuleIsEnabled(item?.permission))
                                    return <Route key={item.route} exact path={item.route} component={item.component}/>

                                return <Route key={item.route} exact path={item.route} component={Error404}/>

                            })}
                        </Switch>
                    </div>
                </div>
                <Footer hideContact={isEnabledByMarket('hideContactFooterInResellerPanel')}/>
            </div>
        </Provider>
    )

}
