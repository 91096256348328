import React from 'react';

export default function Alert({type, size, text, ...props}){
    return (
        <div className={`alert -${type} ${size ? `-${size}` : ''}`}>
            {text ? (
                <span dangerouslySetInnerHTML={{__html: text}} />
            ) : props.children}
        </div>
    )
}
