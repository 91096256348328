import BaseModal from "../../../../components/modals/BaseModal";
import {Button} from "../../../../components/buttons/_index";
import {Preloader} from "../../../../misc/_index";
import React from "react";
import {useTranslation} from "../../../../hooks";
import {InfoComponent} from "../../../../components/_index";

export default function ReportGenerateStatusModal({visible, onClose, isGenerated, reportUrl, alert}) {
    const {t} = useTranslation()
    return (
        <BaseModal
            visible={visible}
            onClose={onClose}
        >
            <div className="raportMessage">
                <span className={`--dBlock --alignCenter --mb3 --bold ${isGenerated ? '--success' : ''}`}>
                    {isGenerated
                        ? t('reports_users.modal.in_the_process')
                        : t('reports_users.modal.ready')
                    }
                </span>
                {!isGenerated && reportUrl ? (
                    <a href={reportUrl} download><Button label={t('reports_users.modal.download')}/></a>
                ) : <Preloader/>}
                <InfoComponent type={alert?.type} message={t(alert?.message)}/>
            </div>
        </BaseModal>
    )
}