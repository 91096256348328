import ReCAPTCHA from "react-google-recaptcha";
import Env from "../../utils/Env";
import {Config} from "../../utils/_index";
import React from "react";
import {useLanguage} from "../../hooks";
import {useSelector} from "react-redux";
import {useInputValidationClassNames} from "../hooks";
import {InvalidFeedback} from "../components";

/**
 * Recaptcha
 * @param inputRef
 * @param fieldState
 * @param name
 * @param value
 * @param onChange
 * @param customOnChange
 * @param onBlur
 * @param storeName
 * @returns {JSX.Element}
 * @constructor
 */
export default function Recaptcha({
    fieldState,
    onChange,
}) {
    const currentLanguage = useLanguage();
    const store = useSelector(({main}) => ({
        recaptchaKey: main.recaptchaKey,
    }))
    const {className} = useInputValidationClassNames({
        className: 'recaptcha',
        fieldState,
    })

    if (!store.recaptchaKey && Env.ENV !== 'dev') {
        return (
            <p className="--error">Recaptcha key not found!</p>
        );
    }

    return (
        <fieldset className={className}>
            <ReCAPTCHA
                sitekey={Env.ENV === 'dev' ? Config.RECAPTCHA_DEV : store.recaptchaKey}
                size={document.body.offsetWidth < 768 ? 'compact' : 'normal'}
                hl={getCountryCode(currentLanguage)}
                onChange={onChange}
                onExpired={() => onChange('')}
            />
            <InvalidFeedback error={fieldState.error}/>
        </fieldset>
    )
}

const codes = {
    'cz': 'cs',
    'gr': 'el',
    'dk': 'da',
    'jp': 'ja',
};

function getCountryCode(code) {
    return codes[code] ?? code;
}