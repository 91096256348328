import React from "react";
import {useMarketConfig, useTranslation} from "../../misc/Hooks";


/**
 * Informations about locked by opt in product, and ways to unlock it
 * @param {boolean} value Is product locked
 * @param {string: 'email' | 'mobile' | 'phone' | 'post' } props Notification channels
 * @returns {false|JSX.Element}
 * @constructor
 */
export default function LockedByOptIn({value, props}) {
    const t = useTranslation()
    const marketConfig = useMarketConfig()

    return !!value && (
        <div className="lockedByOptIn" style={{color: marketConfig.locked_product_by_opt_in_color}}>
            <p
                className="coffeeItem__subtitle --bold"
                dangerouslySetInnerHTML={{__html: t('unlock_by_opt_in.info')}}
            />
            <div className="par-4 primaryUl --bold">
                <ul>
                    {props.email && (
                        <li dangerouslySetInnerHTML={{__html: t('unlock_by_opt_in.email')}}/>
                    )}
                    {props.mobile && (
                        <li dangerouslySetInnerHTML={{__html: t('unlock_by_opt_in.mobile')}}/>
                    )}
                    {props.phone && (
                        <li dangerouslySetInnerHTML={{__html: t('unlock_by_opt_in.phone')}}/>
                    )}
                    {props.post && (
                        <li dangerouslySetInnerHTML={{__html: t('unlock_by_opt_in.post')}}/>
                    )}
                </ul>
            </div>
        </div>
    )
}