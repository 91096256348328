import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getImage} from "../../../misc/Helpers";
import {Config} from "../../../utils/_index";
import * as OrderActions from "../../../actions/OrderActions";
import GTM from "../../../misc/GTM";
import {useCurrentLanguage, useGTM} from "../../../hooks";
import {reducers, useCart} from "../../../misc/Hooks";
import {useHistory} from "react-router-dom";

let MOUNTED = false;

export default function useCoffeeProducts(storeName) {
    const {apiLangCode} = useCurrentLanguage()
    const dispatch = useDispatch()
    const history = useHistory()
    const {isCartValid, isCategoryValid} = useCart(storeName);
    const {errorTracking} = useGTM()
    const {competePromoChoice, completeCoffeeSelection} = useGTM()

    const store = useSelector(state => ({
        promotion: state[storeName].promotion,
        categories: state[storeName].categories,
        products: state[storeName].products,
        cart: state[storeName].cart,
        easyOrderSetup: state[storeName].easyOrderSetup,
        loadingCategories: state[storeName].loadingCategories,
        proofOfPurchase: state[storeName].proofOfPurchase,
        frequencies: state[storeName].frequencies,
        coffeeLines: state.main.coffeeLines
    }))

    const [categories, setCategories] = useState([]);
    const [originalCategories, setOriginalCategories] = useState([])
    const [showAdditional, setShowAdditional] = useState(() => {
        return !!store.categories.find(c => c.promotion_category_is_additional)
    });
    const [showAdditionalEnabled, setShowAdditionalEnabled] = useState(false);

    const getFrequencies = () => {
        OrderActions.easyOrderFrequencies(apiLangCode).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                if (MOUNTED) {
                    dispatch(reducers[storeName].setFrequencies(res.data.data))
                }
            } else {
                if (res.messages?.length) {
                    errorTracking('Coffee Selection', res.messages[0])
                }
                console.log('Something gone wrong! ', res);
            }
        })
    }

    const goToNextStep = (route) => {
        if (store.promotion)
            competePromoChoice(store.promotion.promotion_code)
        store.cart?.items?.map((item) => {
            const product = store.products.find(p => p?.id_product === item?.id);
            if (!!product)
                completeCoffeeSelection(product?.product_name)
        })
        history.push(route)
    }

    const handleSubmitEasyOrderSetup = (data) => {
        dispatch(reducers[storeName].setEasyOrderSetup(data))
    }

    useEffect(() => {
        MOUNTED = true;

        return () => {
            MOUNTED = false;
        }
    }, [])

    useEffect(() => {
        if (store.promotion?.promotion_type === Config.PROMO_TYPE.EASY_ORDER) {
            getFrequencies();
        }
    }, [store.promotion])

    // useEffect(() => {
    //     // if(!categories.length)
    //         setCategories(getCategories(store.categories));
    //     setOriginalCategories(getCategories(store.categories));
    // }, [store.categories])

    return {
        storeName: storeName,
        store: store,
        promoImage: getImage(store.promotion?.promotion_gallery, Config.GALLERY_TYPE.BACKGROUND),
        categories,
        setCategories,
        originalCategories,
        setOriginalCategories,
        showAdditional,
        setShowAdditional,
        isCartValid,
        isCategoryValid,
        showAdditionalEnabled,
        setShowAdditionalEnabled,
        goToNextStep,
        handleSubmitEasyOrderSetup,
    }
}
