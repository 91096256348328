import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {
    evReducer,
    mainReducer,
    ofReducer,
    resellerReducer,
    rmsReducer,
    stayHomeReducer,
    tpsReducer,
    eTradeReducer,
    eTradeReopenReducer,
    shopReducer,
    shopReopenReducer,
    noMachineShopReducer,
    subscriptionReducer,
    machineRegistrationReducer,
    paymentReducer,
} from "./_index";


export const initialState = {
    country: null,
    marketConfig: null,

    recaptchaKey: '',

    user: null,
    userToken: null,
}


const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2
}


const persistedReducer = persistReducer(persistConfig, combineReducers({
    ev: evReducer,
    shop: shopReducer,
    machineRegistration: machineRegistrationReducer,
    eTrade: eTradeReducer,
    eTradeReopen: eTradeReopenReducer,
    stayHome: stayHomeReducer,
    reseller: resellerReducer,
    rms: rmsReducer,
    of: ofReducer,
    tps: tpsReducer,
    main: mainReducer,
    shopReopen: shopReopenReducer,
    noMachineShop: noMachineShopReducer,
    subscription: subscriptionReducer,
    payment: paymentReducer,
}))


export const store = createStore(persistedReducer, applyMiddleware(thunk))
export const persistor = persistStore(store)
