import React from "react";
import {useFetchCategories, useModuleContext} from "../../../hooks";
import {Routes} from "../../../utils/_index";
import {OrderSummary} from "../../../components/Summary";
import * as orderActions from "../../../actions/OrderActions";
import {getSummaryStayHomeOrder} from "../../../utils/MarketConfig";
import Config from "../../../utils/Config";


export default function SummaryScreen() {
    const fetchCategories = useFetchCategories()
    const {store} = useModuleContext()

    const payloadFn = ({orderData, paymentMethod, deliveryMethod, pickupPoint}) => {
        return {
            customer: {
                ...orderData.getData(),
                ...orderData.getPaymentMethodData(paymentMethod),
                ...orderData.getDeliveryMethodData(deliveryMethod, pickupPoint),
                id_machine: store.planMachine?.id_machine,
                promotion_type: Config.PROMO_TYPE.STAY_HOME,
                coffee_type: store.planMachine?.machine_coffee_type
            },
            cart: orderData.getCart(),
            ...orderData.getCartQuotationData(),
        }
    }

    return (
        <OrderSummary
            homeRoute={Routes.STAY_HOME}
            orderFinalizedRoute={Routes.STAY_HOME_ORDER_FINALIZED}
            action={orderActions.finalizeStayHome}
            actionParams={store.token}
            payloadFn={payloadFn}
            state={{
                isLoading: fetchCategories.query.isLoading,
                isFetching: fetchCategories.query.isFetching,
            }}
            paymentMethods={store.data?.payment_methods}
            getCustomSummaryOrder={getSummaryStayHomeOrder}
        />
    )
}