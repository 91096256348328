import {MessageList} from "../components";
import {useTranslation} from "../../../../hooks";


export default function MessagesScreen(props) {
    const {t} = useTranslation()
    return (
        <div className="wrapper">
            <div className="messages__header">
                <div className="par-7" dangerouslySetInnerHTML={{__html: t('reseller_messages.header_title')}}/>
                <div className="par-2" dangerouslySetInnerHTML={{__html: t('reseller_messages.header_text')}}/>
            </div>

            <div className="messages__body">
                <MessageList/>
            </div>

        </div>
    )
}
