
const SET_UUID = 'stayHome/setUuid';
const SET_DATA = 'stayHome/setData';
const SET_TOKEN_AND_CLEAR_STORE = 'stayHome/setTokenAndClearStore';
const SET_TOKEN_AND_EMAIL = 'stayHome/setTokenAndEmail';
const SET_EMAIL = 'stayHome/setEmail';
const SET_CODE = 'stayHome/setCode';
const SET_IS_NEW_MEMBER = 'stayHome/setIsNewMember';
const SET_NESPRESSO_MEMBER = 'stayHome/setNespressoMember';
const SET_NESPRESSO_MEMBER_EXIST = 'stayHome/setNespressoMemberExist';
const SET_USER_HAS_PLAN = 'stayHome/setUserHasPlan';
const SET_CUSTOMER = 'stayHome/setCustomer';
const SET_PRODUCTS = 'stayHome/setProducts';
const SET_EXTRA_LINE_PRODUCTS = 'stayHome/setExtraLineProducts';
const SET_EXTRA_LINE_CATEGORIES = 'stayHome/setExtraLineCategories';
const SET_CATEGORIES = 'stayHome/setCategories';
const UPDATE_CART = 'stayHome/updateCart';
const SET_PLAN_MACHINE = 'stayHome/setPlanMachine';
const SET_CAN_REGISTER_RESPONSE = 'stayHome/setCanRegisterResponse';
const SET_ORDER = 'stayHome/setOrder';
const SET_ORDER_FINALIZED_DATA = 'stayHome/setOrderFinalizedData';
const SET_PAYMENT_METHOD = 'stayHome/setPaymentMethod'
const SET_DELIVERY_METHOD = 'stayHome/setDeliveryMethod';
const SET_ADDRESSES = 'stayHome/setAddresses';
const SET_ORDER_ACCOUNT = 'stayHome/setOrderAccount';
const SET_LOADING_CATEGORIES = 'stayHome/setLoadingCategories';
const SET_CART_VISIBLE = 'stayHome/setCartVisible';
const SET_WITH_EMAIL = 'stayHome/setWithEmail';
const SET_PROMO_CODE = 'stayHome/setPromoCode';
const SET_CART_QUOTATION_DATA = 'stayHome/setCartQuotationData';
const SET_OPEN_PLAN_MODALS = 'stayHome/setOpenPlanModals';
const SET_LAST_VISIT = 'stayHome/setLastVisit';
const CLEAR_CART = 'stayHome/clearCart';
const SET_PERSONALIZATION = 'stayHome/setPersonalization';
const CLEAR_STORE = 'stayHome/clearStore';

const initialState = {
    uuid: '',
    data: null,
    token: null,
    email: null,
    code: null,
    isNewMember: undefined,
    nespressoMember: null,
    nespressoMemberExist: false,
    userHasPlan: undefined,
    customer: null,
    categories: [],
    products: [],
    extraLineCategories: [],
    extraLineProducts: [],
    cart: {
        items: [],
        sum: '',
    },
    canRegisterResponse: null,
    order: null,
    orderFinalizedData: null,
    paymentMethod: null,
    deliveryMethod: null,
    addresses: [],
    orderAccount: null,
    orderAccountLinked: false,
    loadingCategories: false,
    cartVisible: false,
    withEmail: true,
    promoCode: false,
    lastVisit: null,
    ecapiAccountCreated: false,
    cartQuotationData: null,
    openPlanModals: [],
    personalization: null,

}


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_UUID:
            return {...state, uuid: action.uuid}
        case SET_DATA:
            return {...state, data: action.data}
        case SET_EMAIL:
            return {...state, email: action.email}
        case SET_TOKEN_AND_CLEAR_STORE:
            return {...initialState, email: state.email, token: action.token}
        case SET_TOKEN_AND_EMAIL:
            return {...state, token: action.token, email: action.email}
        case SET_CODE:
            if(action.code)
                return {...state, code: action.code}
            return {...initialState, email: state.email, token: state.token, code: action.code}
        case SET_IS_NEW_MEMBER: return {...state, isNewMember: action.isNewMember}
        case SET_NESPRESSO_MEMBER:
            return {...state, nespressoMember: action.nespressoMember}
        case SET_NESPRESSO_MEMBER_EXIST:
            return {...state, nespressoMemberExist: action.nespressoMemberExist}
        case SET_USER_HAS_PLAN:
            return {...state, userHasPlan: action.userHasPlan}
        case SET_CUSTOMER:
            return {...state, customer: action.customer}
        case SET_PRODUCTS:
            return {...state, products: action.products}
        case SET_CATEGORIES:
            return {...state, categories: action.categories}
        case SET_EXTRA_LINE_CATEGORIES:
            return {...state, extraLineCategories: action.extraLineCategories}
        case SET_EXTRA_LINE_PRODUCTS:
            return {...state, extraLineProducts: action.extraLineProducts}
        case UPDATE_CART:
            return {...state, cart: action.cart}
        case SET_PLAN_MACHINE:
            return {...state, planMachine: action.planMachine}
        case SET_CAN_REGISTER_RESPONSE:
            return {...state, canRegisterResponse: action.canRegisterResponse}
        case SET_ORDER:
            return {...state, order: action.order}
        case SET_ORDER_FINALIZED_DATA:
            return {...state, orderFinalizedData: action.orderFinalizedData}
        case SET_PAYMENT_METHOD:
            return {...state, paymentMethod: action.paymentMethod}
        case SET_DELIVERY_METHOD: return {...state, deliveryMethod: action.deliveryMethod}
        case SET_ADDRESSES:
            return {...state, addresses: action.addresses}
        case SET_ORDER_ACCOUNT:
            return {
                ...state,
                orderAccount: action.orderAccount,
                orderAccountLinked: action.orderAccountLinked,
                ecapiAccountCreated: action.ecapiAccountCreated,
            }
        case SET_LOADING_CATEGORIES: return {...state, loadingCategories: action.loadingCategories}
        case SET_OPEN_PLAN_MODALS: return {...state, openPlanModals: action.openPlanModals}
        case SET_CART_VISIBLE: return {...state, cartVisible: action.cartVisible}
        case SET_WITH_EMAIL: return {...state, withEmail: action.withEmail}
        case SET_PROMO_CODE: return {...state, promoCode: action.promoCode}
        case SET_LAST_VISIT: return {...state, lastVisit: action.lastVisit}
        case SET_CART_QUOTATION_DATA:
            return {...state, cartQuotationData: action.cartQuotationData}
        case SET_PERSONALIZATION: return {...state, personalization: action.personalization}
        case CLEAR_CART:
            return {...state, cart: {...initialState.cart}}
        case CLEAR_STORE:
            return {...initialState}

        default: return state;
    }
}

export const setUuid = (uuid) => ({type: SET_UUID, uuid})
export const setData = (data) => ({type: SET_DATA, data})
export const setTokenAndClearStore = (token) => ({type: SET_TOKEN_AND_CLEAR_STORE, token});
export const setTokenAndEmail = (token, email) => ({type: SET_TOKEN_AND_EMAIL, token, email});
export const setEmail = email => ({type: SET_EMAIL, email});
export const setCode = code => ({type: SET_CODE, code});
export const setIsNewMember = (isNewMember) => ({type: SET_IS_NEW_MEMBER, isNewMember})
export const setNespressoMember = (nespressoMember) => ({type: SET_NESPRESSO_MEMBER, nespressoMember})
export const setNespressoMemberExist = (nespressoMemberExist) => ({type: SET_NESPRESSO_MEMBER_EXIST, nespressoMemberExist})
export const setUserHasPlan = (userHasPlan) => ({type: SET_USER_HAS_PLAN, userHasPlan})
export const setCustomer = (customer) => ({type: SET_CUSTOMER, customer})
export const setProducts = (products) => ({type: SET_PRODUCTS, products})
export const setExtraLineProducts = (extraLineProducts) => ({type: SET_EXTRA_LINE_PRODUCTS, extraLineProducts})
export const setExtraLineCategories = (extraLineCategories) => ({type: SET_EXTRA_LINE_CATEGORIES, extraLineCategories})
export const setCategories = (categories) => ({type: SET_CATEGORIES, categories})
export const updateCart = (cart) => ({type: UPDATE_CART, cart})
export const setPlanMachine = (planMachine) => ({type: SET_PLAN_MACHINE, planMachine})
export const setCanRegisterResponse = (canRegisterResponse) => ({type: SET_CAN_REGISTER_RESPONSE, canRegisterResponse})
export const setOrder = (order) => ({type: SET_ORDER, order})
export const setOrderFinalizedData = (orderFinalizedData) => ({type: SET_ORDER_FINALIZED_DATA, orderFinalizedData})
export const setPaymentMethod = (paymentMethod) => ({type: SET_PAYMENT_METHOD, paymentMethod})
export const setDeliveryMethod = (deliveryMethod) => ({type: SET_DELIVERY_METHOD, deliveryMethod})
export const setAddresses = (addresses) => ({type: SET_ADDRESSES, addresses})
export const setOrderAccount = (orderAccount, orderAccountLinked, ecapiAccountCreated = false) => ({
    type: SET_ORDER_ACCOUNT,
    orderAccount,
    orderAccountLinked,
    ecapiAccountCreated,
})
export const setLoadingCategories = (loadingCategories) => ({type: SET_LOADING_CATEGORIES, loadingCategories})
export const setCartVisible = (cartVisible) => ({type: SET_CART_VISIBLE, cartVisible})
export const setWithEmail = (withEmail) => ({type: SET_WITH_EMAIL, withEmail})
export const setPromoCode = (promoCode) => ({type: SET_PROMO_CODE, promoCode})
export const setLastVisit = (lastVisit) => ({type: SET_LAST_VISIT, lastVisit})
export const setCartQuotationData = (cartQuotationData) => ({type: SET_CART_QUOTATION_DATA, cartQuotationData})
export const setOpenPlanModals = (openPlanModals) => ({type: SET_OPEN_PLAN_MODALS, openPlanModals})
export const setPersonalization = (personalization) => ({type: SET_PERSONALIZATION, personalization})
export const clearStore = () => ({type: CLEAR_STORE})
export const clearCart = () => ({type: CLEAR_CART})


export default reducer;
