import {useMutation} from "@tanstack/react-query";
import {validateSerialNumber, getSerialNumberFromImage} from '../actions/MachineActions';
import {ApiException} from "../errors";
import {useState} from "react";
import {useModuleContext, useTranslation} from "./index";
import {useSelector} from "react-redux";

export default function useSerialNumber() {
    const {t} = useTranslation()
    const context = useModuleContext()
    const store = useSelector(state => ({
        machine: state[context.storeName]?.machine,
        serialNumber: state[context.storeName]?.serialNumber,
    }))
    const [machine, setMachine] = useState(store.machine ?? null)
    const [fileData, setFileData] = useState(null)
    const [error, setError] = useState(null)
    const [logs, setLogs] = useState([])
    const mutation = useMutation(
        async (payload) => {
            const requestPayload = {
                serial_number: payload.serialNumber,
            }
            const {data} = await validateSerialNumber(requestPayload)
            if(data.status === 'success')
                return data
            throw new ApiException(data.messages[0], data)
        },
        {
            onMutate: () => {
                setMachine(null)
                setError(null)
            },
            onSuccess: ({data}, payload) => {
                setMachine(data)
                pushLog({manually: 1, value: payload.serialNumber, valid: 1})
            },
            onError: ({error}, payload) => {
                setError(t(`error.${error}`) || t('error.unknown_error'))
                pushLog({manually: 1, value: payload.serialNumber, valid: 0})
            },
        }
    )

    const fileMutation = useMutation(
        async (payload) => {
            const formData = new FormData();
            formData.append('file', payload.file)
            const {data} = await getSerialNumberFromImage(formData)
            if(data.status === 'success')
                return data
            throw new ApiException(data.messages[0], data)
        },
        {
            onMutate: () => {
                setMachine(null)
                setError(null)
            },
            onSuccess: ({data}) => {
                setFileData(data)
                pushLog({manually: 0, value: data.serial_number, valid: 0})
            },
            onError: ({error}) => {
                setError(t(`error.${error}`) || t('error.unknown_error'))
            },
        }
    )

    const pushLog = (l) => {
        const arr = [...logs.filter(l => l.type === 'serial_number')];
        const last = [...arr].reverse()[0]
        if (last) {
            if (last.manually === l.manually && last.value === l.value && last.valid === l.valid) {
                return
            }
            if (l.manually && !last.manually && last.value === l.value) {
                arr.splice(arr.length - 1, 1)
                l.manually = 0
            }
        }
        arr.push({
            type: 'serial_number',
            date: new Date().toISOString(),
            ...l,
        })
        setLogs(arr)
    }

    return {
        mutation,
        fileMutation,
        isLoading: mutation.isLoading || fileMutation.isLoading,
        machine,
        serialNumber: store?.serialNumber?.serial_number,
        fileData,
        error,
        logs,
    }
}
