import React from 'react';
import BaseModal from "./BaseModal";
import {useTranslation} from "../../misc/Hooks";
import {getImage} from "../../misc/Helpers";

export default (props) => {
    const t = useTranslation();

    const coverImage = getImage(props.item.product_gallery);

    return (
        <BaseModal
            visible={props.visible}
            onClose={props.onClose}
        >
            <div className="coffeeModal">
                {!!coverImage && (
                    <div>
                        <div className="coffeeModal__thumb --mb3">
                            <img src={coverImage} alt={props.item.product_title}/>
                        </div>
                    </div>
                )}
                <div>
                    <h3 className="coffeeModal__title">{props.item.product_title}</h3>
                    <div className="coffeeModal__lead par-5" dangerouslySetInnerHTML={{__html: props.item.product_plan_description1}}/>
                    <div className="coffeeModal__lead primaryUl par-5" dangerouslySetInnerHTML={{__html: props.item.product_plan_description3}}/>

                </div>
            </div>

        </BaseModal>
    );
}
