import Provider from "./Provider";
import {Redirect, Route, Switch} from "react-router-dom";
import {useSteps} from "../../hooks/modules/etrade_reopen";
import React from "react";
import {useNavigation} from "../../misc/Hooks";
import {Footer, Header} from "../../components/misc/_index";
import {Routes} from "../../utils/_index";
import {InvalidTokenScreen, TokenExpiredScreen} from "../etrade/screens";
import {makeStore, ModuleProvider} from "../../hooks";
import {getStepsRoutes} from "../../misc/Helpers";
import StepsFunnel from "./StepsFunnel";
import {AppProvider} from "../../misc/_index";
import {OrderFinalizedEtradeScreen} from "../../screens";

export default function Funnel() {
    const navigation = useNavigation()
    const steps = useSteps()

    const contextValue = makeStore({
        storeName: 'eTradeReopen',
        steps,
    })

    return (
        <AppProvider>
            <ModuleProvider value={contextValue}>
                <Provider>

                    <Switch>

                        <Route
                            exact path={[
                            ...getStepsRoutes(steps),
                        ]}
                            component={StepsFunnel}
                        />

                        <Route exact path={Routes.ETRADE_REOPEN_ORDER_FINALIZED}>
                            <OrderFinalizedEtradeScreen homeRoute={Routes.ETRADE_REOPEN_ORDER_FINALIZED}/>
                        </Route>
                        <Route>
                            <div className="app">
                                <Header
                                    storeName="eTradeReopen"
                                    homeRoute={navigation(Routes.ETRADE_REOPEN)}
                                    cartDisabled
                                    alertsDisabled
                                />

                                <div className="main">
                                    <div className="wrapper-bg formContainer">
                                        <Switch>
                                            <Route exact path={Routes.ETRADE_REOPEN_INVALID_TOKEN}
                                                   component={InvalidTokenScreen}/>
                                            <Route exact path={Routes.ETRADE_REOPEN_TOKEN_EXPIRED}
                                                   component={TokenExpiredScreen}/>

                                            <Route path={Routes.ETRADE_REOPEN}>
                                                <Redirect to={navigation(Routes.ETRADE_REOPEN_AUTHORIZATION)}/>
                                            </Route>
                                        </Switch>
                                    </div>
                                </div>

                                <Footer module="eTradeReopen"/>
                            </div>
                        </Route>

                    </Switch>

                </Provider>
            </ModuleProvider>
        </AppProvider>
    )
}
