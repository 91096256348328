import React, {useState} from "react";
import PromoItem from "../PromoItem";
import {Button} from "../buttons/_index";
import {useTranslation} from "../../hooks";
import {usePromotionsContext} from "../../hooks/useFetchPromotions";
import PromoModal from "../PromoModal";
import {Preloader} from "../../misc/_index";
import {useCategoriesContext} from "../../hooks/useFetchCategories";


export default function PromotionSelection({
    query,
    data,
}) {
    const {promotion, setPromotion} = usePromotionsContext()
    const {query: categoriesQuery} = useCategoriesContext()
    const {t} = useTranslation();
    const [isCompact, setIsCompact] = useState(!!promotion)

    const onChangePromotion = (promotion) => {
        setIsCompact(true)
        setPromotion(promotion)
    }

    return (
        <div className="evPS">
            {(query.isLoading || (query.isFetching && !query.data?.length)) ? (
                <Preloader message={t('fetching_promotions')}/>
            ) : (
                <>
                    {query.isSuccess && (
                        <>
                            {data?.length ? (
                                <>
                                    <div className="evPS__header">
                                        <p className="par-7 --bold">{t('shop_offer_selection.promo_selection_title')}</p>
                                        {!!promotion && (
                                            <Button
                                                size="sm"
                                                label={t(isCompact ? 'expand_promotions' : 'collapse_promotions')}
                                                onClick={() => setIsCompact(old => !old)}
                                            />
                                        )}
                                    </div>

                                    <div className="evPS__items">
                                        {data.map(promo => (
                                            <PromoItem
                                                key={promo.id_promotion}
                                                promotion={promo}
                                                selectedPromotion={promotion}
                                                isCompact={isCompact}
                                                disabled={categoriesQuery.isFetching}
                                                onClick={() => onChangePromotion(promo)}
                                            />
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <div className="--mb4">
                                    <p className="par-5 --bold"
                                       dangerouslySetInnerHTML={{__html: t('error.promotions_not_found')}}/>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}


            {query.isError && !query.isFetching && (
                <div>
                    <p
                        className="par-5 --bold"
                        dangerouslySetInnerHTML={{__html: t(`error.${query.error?.error}`) || t('error.unknown_error')}}
                    />
                </div>
            )}

            <PromoModal
                popupType="default"
            />
        </div>
    )
}
