import {MaskInputController, TextInputController} from "../controllers";
import React from "react";
import {useFieldFormat, useTranslation} from "../../hooks";

export default function BsbNumberInput(props) {
    const {t} = useTranslation()
    const {formats} = useFieldFormat()

    return (
        <MaskInputController
            name="bsbNumber"
            label={t('bank_account.bsb_number')}
            mask={formats.bsbNumber.getMask()}
            {...props}
        />
    )
}
