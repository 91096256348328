import {useMutation} from "@tanstack/react-query";
import * as AuthActions from "../../../actions/AuthorizationActions";
import {ApiException} from "../../../errors";
import {useResellerActions} from "../../../actions/useResellerActions";
import {useReseller} from "../../../hooks";
import {useState} from "react";
import {Routes} from "../../../utils/_index";
import {useHistory} from "react-router-dom";
import {useNavigation} from "../../../misc/Hooks";

export default function useFastOrder(){
    const resellerActions = useResellerActions()
    const {reseller} = useReseller()
    const history = useHistory()
    const navigation = useNavigation();
    const [modalVisible, setModalVisible] = useState(false)
    const [error, setError] = useState(false)

    const createFastOrderMutation = useMutation(
        async ({serialNumber, email}) => {
            const {data} = await resellerActions.saveFastOrder({
                customer: {
                    serial: serialNumber,
                    delivery_email: email,
                    id_shop: reseller.shop
                }
            })
            if (data.status === 'success') return data

            throw new ApiException(data.messages[0], data)
        },{
            onSuccess: () => setModalVisible(true),
            onError: ({error}) => setError('error.unknown_error')
        }
    )

    const onCloseModal = () => {
        history.push(navigation(Routes.V2_RESELLER_ASSISTED_REGISTRATION))
    }

    return {
        createFastOrderMutation,
        modalVisible,
        onCloseModal,
        error
    }
}