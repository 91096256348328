import {useTableBuilder, useTableFilters} from "../../../../components/baseTable";
import {isoDateConverter, isoDateConverterWithTime} from "../../../../misc/Helpers";
import {useResellerActions} from "../../../../actions/useResellerActions";
import useLanguage from "../../../../hooks/useLanguage";
import {useReseller, useTranslation} from "../../../../hooks";
import SubscriptionStatusLabel from "../../../../components/SubscriptionStatusLabel";
import React, {useEffect, useMemo, useState} from "react";
import PaymentStatusLabel from "../../../../components/PaymentStatusLabel";
import {isEnabledByMarket} from "../../../../utils/MarketConfig";
import {DatePickerController} from "../../../../forms/controllers";
import {useMutation} from "@tanstack/react-query";
import {ApiException} from "../../../../errors";
import {SelectOption} from "../../../../services";
import MultiSelectController from "../../../../forms/controllers/MultiSelectController";

export default function useReportOrdersTable({
    optionColumn = {},
}) {

    const actions = useResellerActions()
    const lang = useLanguage()
    const {t} = useTranslation()
    const {role} = useReseller()
    const [selectedShops, setSelectedShops] = useState([])
    const [selectedResellers, setSelectedResellers] = useState([])


    const filtersDataMutation = useMutation(async (params) => {
            const {data} = await actions.reportsFilter({
                all_resellers: 1,
                ...params,
            })

            if (data.status === 'success')
                return data
            throw new ApiException(data.messages[0], data)
        }
    )

    const resellersOptions = useMemo(() => (
            filtersDataMutation.data?.data?.resellers?.map((r) => (
                new SelectOption(
                    `${r.reseller_firstname} ${r.reseller_lastname} [${r.shop_title}]`,
                    Number(r.id_reseller)
                )
            ))
        ),
        [filtersDataMutation.data?.data?.resellers])

    const shopsOptions = useMemo(() => (
        filtersDataMutation.data?.data?.shops?.map((s) => (
            new SelectOption(s.shop_title, Number(s.id_shop))
        ))
    ), [filtersDataMutation.data?.data?.shops])

    const orderFilter = useTableFilters({
        builder: {
            formConfig: [[
                'dateFrom',
                'dateTo',
                role === 'ka_super_user' ? 'shop' : null,
                role !== 'pos_user' ? 'reseller' : null,
            ]],
            inputComponents: {
                dateFrom: (
                    <DatePickerController
                        name="dateFrom"
                        label={t('assisted_registration.filters.date_from')}
                    />
                ),
                dateTo: (
                    <DatePickerController
                        name="dateTo"
                        label={t('assisted_registration.filters.date_to')}
                    />
                ),
                shop: (
                    <MultiSelectController
                        name='shop'
                        label={t('assisted_registration.filters.shop')}
                        selectOptions={shopsOptions ?? []}
                        options={{
                            inputProps: {
                                isLoading: filtersDataMutation.isLoading,
                                isDisabledValidation: true,
                                isDisabled: !!selectedResellers.length
                            }
                        }}
                    />
                ),
                reseller: (
                    <MultiSelectController
                        name='reseller'
                        label={t('assisted_registration.filters.reseller')}
                        selectOptions={resellersOptions ?? []}
                        options={{
                            inputProps: {
                                isLoading: filtersDataMutation.isLoading,
                                isDisabledValidation: true,
                                isDisabled: !!selectedShops.length
                            }
                        }}
                    />
                )
            },
        },
        searchPrefix: "orderFilters",
        mapFunc: ((data) => {
            setSelectedShops(data?.orderFilters?.shop ?? [])
            setSelectedResellers(data?.orderFilters?.reseller ?? [])
            return {
                dateFrom: data?.orderFilters?.dateFrom ? isoDateConverter(data.orderFilters.dateFrom, '-') : undefined,
                dateTo: data?.orderFilters?.dateTo ? isoDateConverter(data.orderFilters.dateTo, '-') : undefined,
            }
        }),
        config: {
            grid: 'auto-fit'
        }

    })

    useEffect(() => {
        console.log(selectedShops)
    }, [selectedShops])

    const orderTable = useTableBuilder({
        name: 'reportsOrder',
        action: async ({dateFrom, dateTo, selectedShops, selectedResellers, ...params}) => {

            const payload = {
                sort: null,
                order_type: null,
                date_from: dateFrom ?? null,
                date_to: dateTo ?? null,
                id_shop: selectedShops ?? [],
                id_reseller: selectedResellers ?? [],
                status: [],
            }
            return actions.getOrdersV2(payload, params)
        },
        columns: [
            {name: 'id_order', label: 'assisted_registration.id'},
            {
                name: 'pos_name',
                label: 'assisted_registration.pos_name',
                cell: ({row: {original}}) => {
                    let posName = null;
                    if (original.shop_title_translate) {
                        const json = JSON.parse(original.shop_title_translate);
                        const keys = Object.keys(json)
                        const index = keys.findIndex(k => k === lang);
                        if (index > -1) {
                            posName = Object.values(json)[index];
                        }
                    }
                    return posName ?? original.shop_title
                }
            },
            {name: 'reseller_email', label: 'assisted_registration.reseller_email'},
            {
                name: 'promotion_type',
                label: 'assisted_registration.type_of_order',
                cell: ({row: {original}}) => t(`assisted_registration.type_order.${original.promotion_type}`)
            },
            {name: 'tracking_code', label: 'assisted_registration.tracking_code'},
            {
                name: 'status',
                label: 'assisted_registration.status_of_registration',
                cell: ({value}) => <SubscriptionStatusLabel status={value}/>,
            },
            {
                name: 'payment_status',
                label: 'assisted_registration.status_of_payment',
                cell: ({value}) => <PaymentStatusLabel status={value}/>,
            },
            {
                name: 'created_at',
                label: 'assisted_registration.date',
                cell: ({value}) => isoDateConverterWithTime(value, '-', ':')
            },
            {
                name: 'machine_coffee_type',
                label: 'assisted_registration.type_of_machine',
            },
            {
                name: 'serial',
                label: 'assisted_registration.serial_number',
            },
            ...optionColumn
        ],
        config: {
            searchParamPrefix: 'orders',
            autoRefetch: true,
            autoRefetchTimeout: 20000,
            hiddenColumns: [isEnabledByMarket('hidePaymentStatusReseller') ? 'payment_status' : null],
        },
        params: {
            selectedShops: selectedShops?.length ? selectedShops?.map((ss => ss.value)) : [],
            selectedResellers: selectedResellers?.length ? selectedResellers?.map((ss => ss.value)) : [],
            ...orderFilter.rawData,
        }
    })

    useEffect(() => {
        filtersDataMutation.mutate()
    }, [])

    return {
        orderTable,
        orderFilter,
    }
}