import {TextInputController} from "../controllers";
import React from "react";
import {useTranslation} from "../../hooks";

export default function FirstnameInput(props) {
    const {t} = useTranslation()

    return (
        <TextInputController
            name="firstname"
            label={t('firstname')}
            {...props}
        />
    )
}
