import {useCountryConfig, useLocalizeFile, useMarketConfig} from "../../../misc/Hooks";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {CustomerScreen as CustomerScreenComponent} from '../../machine_registration/screens'
import {useModuleContext} from "../../../hooks";

export default function CustomerScreen() {
    const localizeFile = useLocalizeFile()
    const marketConfig = useMarketConfig()
    const countryConfig = useCountryConfig()
    const {store, storeName} = useModuleContext()
    const plan = useSelector(state => {
        return state[storeName].products?.find(p => {
            const item = state[storeName].cart?.items?.find(i => i.id === p.id_product);
            return item && p.product_type.startsWith('plan_')
        })
    })
    const {privacyFileUrl, termsFileUrl} = useMemo(() => {
        return {
            termsFileUrl: localizeFile(plan?.product_files?.length ? plan.product_files : store.data?.stay_home_config_files, 'regulations'),
            privacyFileUrl: localizeFile(plan?.product_files?.length ? plan.product_files : store.data?.stay_home_config_files, 'privacy_policy')
                || localizeFile(countryConfig.files, 'privacy_policy') || localizeFile(marketConfig.files, 'privacy_policy')
        }
    }, [plan, store.data, localizeFile, countryConfig.files, marketConfig.files])

    return (
        <CustomerScreenComponent
            customerFormProps={{
                benefitFields: {
                    phone: store.data?.stay_home_config_marketing_phone_visible,
                    email: store.data?.stay_home_config_marketing_email_visible,
                    mobile: store.data?.stay_home_config_marketing_mobile_visible,
                    post: store.data?.stay_home_config_marketing_post_visible,
                    socialMedia: store.data?.stay_home_config_marketing_social_media_visible,
                },
                benefitConfig: {
                    required: store.data?.stay_home_config_marketing_required,
                    selectAllVisible: store.data?.stay_home_config_marketing_select_all_benefits_enabled,
                },
                termsOptions: {
                    termsAndConditionsFileUrl: termsFileUrl,
                    privacyPolicyFileUrl: privacyFileUrl,
                },
            }}
            stayHomeId={store.data?.id_stay_home_config}
        />
    )
}
