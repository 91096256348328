import {Redirect, useLocation} from "react-router-dom";
import React from "react";
import {useReseller} from "../../hooks";
import {AppProvider} from "../../misc/_index";
import {useNavigation} from "../../misc/Hooks";
import Routes from "../../utils/Routes";

export default function Provider(props){
    const navigation = useNavigation();
    const {isLoggedIn} = useReseller();
    const location = useLocation()

    if (!isLoggedIn)
        return (
            <Redirect
                to={{
                    pathname: navigation(Routes.V2_RESELLER_AUTH),
                    state: {
                        callbackUrl: location.pathname,
                    }
                }}
            />
        )

    return (
        <AppProvider>
            {props.children}
        </AppProvider>
    )
}
