import {useTranslation} from "../../../../../hooks";
import {Button} from "../../../../../components/buttons/_index";
import {useMutation} from "@tanstack/react-query";
import * as orderActions from "../../../../../actions/OrderActions";
import {ApiException} from "../../../../../errors";
import {useResellerActions} from "../../../../../actions/useResellerActions";

export default function LocalStockRequestCancel({idShop, idRequest, query}) {
    const {t} = useTranslation()
    const {cancelPosStockRequest} = useResellerActions()


    const cancelMutation = useMutation({
            mutationFn: async () => {
                const {data} = await cancelPosStockRequest(idShop, {id_local_stock_request: idRequest})

                if (data.status === 'success')
                    return data.data
                throw new ApiException(data.messages[0], data)
            },
            onError: (error) => {},
            onSuccess: () => {
                query.refetch()
            }
        })

    return (
        <Button
            label={cancelMutation.isError ? t('error_occurred') : t('stock_management.cancel')}
            size="md"
            type="secondary"
            isLoading={cancelMutation.isLoading}
            disabled={cancelMutation.isLoading || query.isFetching}
            onClick={cancelMutation.mutate}
        />
    )
}