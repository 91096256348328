import {useEffect, useState} from "react";
import PagePreloader from "../../components/PagePreloader";
import * as orderFinishReducer from "../../redux/ofReducer";
import {Routes} from "../../utils/_index";
import {checkAutoAdd} from "../../misc/Helpers";
import {useNavigation} from "../../misc/Hooks";
import * as OrderActions from "../../actions/OrderActions";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {useSteps} from "../../hooks/modules/order_finish";
import * as promotionActions from "../../actions/PromotionActions";
import {useCustomer, useReseller} from "../../hooks";


export default function Provider(props) {
    const navigation = useNavigation()
    const params = useParams()
    const steps = useSteps()
    const history = useHistory()
    const routeMatch = useRouteMatch()
    const location = useLocation()
    const dispatch = useDispatch()
    const {logout: logoutReseller} = useReseller()
    const {mapOrderToCustomer} = useCustomer()
    const store = useSelector(({main, of}) => ({
        civilities: main.civilities,
        token: of.finishOrderToken,
        order: of.order,
        machine: of.machine,
        ocrLogs: of.ocrLogs,
        isNewMember: of.isNewMember,
    }))
    const isStepRoute = steps.map(s => s.route).includes(routeMatch.path)
        || routeMatch.path === Routes.ORDER_FINISH
        || routeMatch.path === Routes.FINISH_ORDER
    const [loading, setLoading] = useState(isStepRoute && (!store.token || params.token))


    useEffect(() => {
        logoutReseller()
        if (isStepRoute) {
            if (location.pathname === navigation(Routes.ORDER_FINISH_SUMMARY) && location.search) {
                setLoading(false)
            } else {
                init(store.token, params.token)
            }
        }
    }, [])


    const init = (storedToken, paramToken) => {
        if (paramToken) {
            // console.log('settoken', paramToken)
            setToken(paramToken)
                .then(() => {
                    setLoading(false)
                })
        } else if (storedToken) {
            // console.log('checktoken', storedToken)
            // if (store.order) return
            // checkToken(storedToken)
            //     .then(isValid => {
            //         setLoading(false)
            //         if (isValid) {
            //             // stored token valid
            //             // console.log('stored token valid')
            //         } else {
            //             // stored token invalid, redirect to info page
            //             // console.log('stored token invalid, redirect to info page')
            //             dispatch(orderFinishReducer.clearStore())
            //             history.push(navigation(Routes.ORDER_FINISH_TOKEN_EXPIRED))
            //         }
            //     })
        } else {
            // no param or store token - redirect to info page
            // console.log('no param or store token - redirect to info page', store.token, params.token)
            setLoading(false)
            dispatch(orderFinishReducer.clearStore())
            history.push(navigation(Routes.ORDER_FINISH_INVALID_TOKEN))
        }
    }

    const setToken = async (token) => {
        await OrderActions.getOrder(token, {module: 'orderFinish', action: 'init'})
            .then(async r => {
                const res = r.data
                if (res.status === 'success') {
                    dispatch(orderFinishReducer.clearStore())
                    dispatch(orderFinishReducer.setUuid(token))
                    await updateOrder(token, res.data)
                } else {
                    dispatch(orderFinishReducer.clearStore())
                    history.push(navigation(Routes.ORDER_FINISH_INVALID_TOKEN))
                }
            })
    }

    const checkToken = async (token) => {
        return await OrderActions.getOrder(token, {module: 'orderFinish', action: 'update'})
            .then(async res => {
                if (res.data.status === 'success') {
                    await updateOrder(token, res.data.data)
                    return true
                }
                return false
            })
            .catch(() => false)
    }

    const updateOrder = async (token, order) => {
        const products = order.items.map(i => ({
            ...i,
            id_product: i.id_product,
            id_category: i.id_category,
            product_code: i.product_code,
            product_type: i.product_type,
            product_name: i.product_name,
            product_title: i.product_title,
            product_price: {
                net: i.order_item_price_net,
                gross: i.order_item_price_gross,
                vat: i.order_item_price_vat,
            },
            product_percentage_rebate: i.order_item_percentage_rebate,
            product_plan_price: {
                net: i.order_item_plan_price_net,
                gross: i.order_item_plan_price_gross,
                vat: i.order_item_plan_price_vat,
            },
            product_plan_marketing_email_visible: i.product_plan_marketing_email_visible,
            product_plan_marketing_mobile_visible: i.product_plan_marketing_mobile_visible,
            product_plan_marketing_phone_visible: i.product_plan_marketing_phone_visible,
            product_plan_marketing_post_visible: i.product_plan_marketing_post_visible,
            product_plan_marketing_social_media_visible: i.product_plan_marketing_social_media_visible,
            product_plan_marketing_required: i.product_plan_marketing_required,
            product_step: i.product_step,
            product_package_qty: i.product_package_qty,
            product_gallery: i.product_gallery,
            product_files: i.product_files,
        }))

        const categories = products.reduce((arr, product) => {
            const idx = arr.findIndex(c => c.id_category === product.id_category)
            if (idx !== -1) {
                arr[idx].products.push(product)
            } else {
                const cat = {
                    id_category: product.id_category,
                    category_name: product.category_name,
                    category_title: product.category_title,
                    category_type: product.category_type,
                }
                arr.push({
                    ...cat,
                    category: cat,
                    subcategories: [],
                    products: [product],
                })
            }
            return arr
        }, [])

        const cart = {
            items: products.map(p => ({
                id: p.id_product,
                value: Number(p.order_item_qty) > 0 ? p.order_item_qty / p.product_package_qty : p.product_step,
            })),
            sum: ''
        }

        let promotion = null

        if (order.id_promotion) {
            if (order.promotion) {
                promotion = order.promotion
            } else {
                await promotionActions.getById(order.id_promotion)
                    .then(r => {
                        const res = r.data;
                        if (res.status === 'success') {
                            promotion = res.data
                        } else {
                            console.error('Cannot get promotion')
                        }
                    })
                    .catch(err => {
                        console.error('Error: ', err)
                    })
            }
        }

        dispatch(orderFinishReducer.setFinishOrder(
            token,
            checkAutoAdd(categories, products, cart, store.machine),
            products,
            categories,
            order,
        ))
        dispatch(orderFinishReducer.setMachine(order.machine, order.test_order, {
            serial_number: order.serial,
            filename: order.filename_machine,
        }))
        dispatch(orderFinishReducer.setOcrLogs([
            ...JSON.parse(order.ocr_log),
            !!order?.order_serial_number_from_qr_code && ({
                type: 'custom_log',
                date: new Date().toISOString(),
                name: 'qr_scanner_was_used',
                value: true
            })
        ]))

        dispatch(orderFinishReducer.setProofOfPurchase({
            shop: {value: order.id_shop},
            date: order.invoice_date,
            invoiceNumber: order.invoice_number,
            machinePrice: order.invoice_price,
        }))
        dispatch(orderFinishReducer.setProofOfPurchaseLogs({
            filename: order.filename_receipt,
            data: {
                invoice: order.ocr_number,
                date: order.ocr_date,
                total: order.ocr_price,
                id_shop: order.ocr_shop,
                id_shop_group: order.ocr_shop_group,
            }
        }))
        dispatch(orderFinishReducer.setPromotion(promotion))
        dispatch(orderFinishReducer.setIsNewMember(!order.webaccount_exist))
        dispatch(orderFinishReducer.setCustomer(mapOrderToCustomer(order)))
    }

    return (
        <>
            {loading ? <PagePreloader/> : props.children}
        </>
    )
}
