import {useModuleContext} from "./index";
import {useSelector} from "react-redux";

export default function useMachine() {
    const {storeName} = useModuleContext()
    const data = useSelector(state => state[storeName]?.machine)
    const machineResponse = useSelector(state => state[storeName]?.machineResponse)

    function getPurchaseDate() {
        if(!machineResponse || !machineResponse?.machine_purchased_at)
            return null
        const date = new Date(machineResponse.machine_purchased_at)
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
        return date
    }

    function getPurchasedBy() {
        if(!machineResponse || !machineResponse?.clientId)
            return null
        return Number(machineResponse.clientId)
    }

    return {
        data,
        machineResponse,
        getPurchaseDate,
        getPurchasedBy,
    }
}
