
const SET_UUID = 'no_machine_shop/setUuid';
const SET_RECAPTCHA_TOKEN = 'no_machine_shop/setRecaptchaToken';

const SET_IS_NEW_MEMBER = 'no_machine_shop/setIsNewMember';
const SET_NESPRESSO_MEMBER = 'no_machine_shop/setNespressoMember';
const SET_NESPRESSO_MEMBER_EXIST = 'no_machine_shop/setNespressoMemberExist';
const SET_PROMOTION = 'no_machine_shop/setPromotion';
const SET_ONLY_PROMOTION = 'no_machine_shop/setOnlyPromotion';
const SET_CUSTOMER = 'no_machine_shop/setCustomer';
const SET_OPT_IN = 'no_machine_shop/setOptIn';

const SET_PROMOTIONS = 'no_machine_shop/setPromotions';
const SET_PRODUCTS = 'no_machine_shop/setProducts';
const SET_CATEGORIES = 'no_machine_shop/setCategories';
const UPDATE_CART = 'no_machine_shop/updateCart';

const SET_EXTRA_LINE_PRODUCTS = 'no_machine_shopsetExtraLineProducts';
const SET_EXTRA_LINE_CATEGORIES = 'no_machine_shop/setExtraLineCategories';

const SET_PAID = 'no_machine_shop/setPaid';
const SET_OCR_LOGS = 'no_machine_shop/setOcrLogs';
const SET_ORDER = 'no_machine_shop/setOrder';

const SET_COFFEE_LINES = 'no_machine_shop/setCoffeeLines';
const SET_ADDRESSES = 'no_machine_shop/setAddresses';

const SET_PAYMENT_METHOD = 'no_machine_shop/setPaymentMethod';
const SET_DELIVERY_METHOD = 'no_machine_shop/setDeliveryMethod';
const SET_EASY_ORDER_SETUP = 'no_machine_shop/setEasyOrderSetup';

const SET_LOADING_CATEGORIES = 'no_machine_shop/setLoadingCategories';
const SET_CAN_REGISTER_RESPONSE = 'no_machine_shop/setCanRegisterResponse';
const SET_COOKIES_ACCEPTED = 'no_machine_shop/setCookiesAccepted';

const SET_ORDER_ACCOUNT = 'no_machine_shop/setOrderAccount';
const SET_LAST_VISIT = 'no_machine_shop/setLastVisit';
const SET_CART_QUOTATION_DATA = 'no_machine_shop/setCartQuotationData';
const SET_FREQUENCIES = 'no_machine_shop/setFrequencies';

const SET_CART_VISIBLE = 'no_machine_shop/setCartVisible';
const SET_OPEN_PROMOTION_MODALS = 'no_machine_shop/setOpenPromotionModals';
const SET_OPEN_PLAN_MODALS = 'no_machine_shop/setOpenPlanModals';
const SET_WEB_ACCOUNT_EXISTS = 'no_machine_shop/setWebAccountExists';

const CLEAR_STORE = 'no_machine_shop/clearStore';


const initialState = {
    uuid: '',
    isNoMachine: true,

    testOrder: false,
    serialNumber: null,

    isNewMember: false,
    nespressoMember: null,
    nespressoMemberExist: false,
    promotion: null,
    customer: null,
    optIn: null,

    promotions: [],
    categories: [],
    products: [],

    extraLineCategories: [],
    extraLineProducts: [],

    cart: {
        items: [],
        sum: '',
    },

    paymentMethod: null,
    deliveryMethod: null,
    order: null,
    paid: false,

    coffeeLines: null,
    addresses: [],

    recaptchaToken: null,

    easyOrderSetup: null,

    ocrLogs: [],

    loadingCategories: false,
    canRegisterResponse: null,

    cookiesAccepted: undefined,

    orderAccount: null,
    orderAccountLinked: false,
    ecapiAccountCreated: false,
    cartQuotationData: null,

    lastVisit: null,
    cartVisible: false,

    frequencies: [],
    openPromotionModals: [],
    openPlanModals: [],
    webAccountExists: false,

};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_UUID: return {...state, uuid: action.uuid}
        case SET_RECAPTCHA_TOKEN: return {...state, recaptchaToken: action.recaptchaToken}

        case SET_IS_NEW_MEMBER: return {...state, isNewMember: action.isNewMember}
        case SET_NESPRESSO_MEMBER: return {...state, nespressoMember: action.nespressoMember}
        case SET_NESPRESSO_MEMBER_EXIST: return {...state, nespressoMemberExist: action.nespressoMemberExist}
        case SET_PROMOTION:
            return {
                ...state,
                promotion: action.promotion,
                products: initialState.products,
                categories: initialState.categories,
                cart: {
                    items: [],
                    sum: '',
                },
                easyOrderSetup: initialState.easyOrderSetup,
                openPromotionModals: []
            }
        case SET_ONLY_PROMOTION: return {...state, promotion: action.promotion}

        case SET_CUSTOMER: return {...state, customer: action.customer}
        case SET_OPEN_PROMOTION_MODALS: {
            return {
                ...state,
                openPromotionModals: action.openPromotionModals
            }
        }
        case SET_OPEN_PLAN_MODALS: return {...state, openPlanModals: action.openPlanModals}
        case SET_OPT_IN: return {...state, optIn: action.optIn}

        case SET_PROMOTIONS: return {...state, promotions: action.promotions}
        case SET_PRODUCTS: return {...state, products: action.products}
        case SET_CATEGORIES:
            return {...state, categories: action.categories, loadingCategories: action.loadingCategories}
        case UPDATE_CART: return {...state, cart: action.cart}

        case SET_EXTRA_LINE_CATEGORIES: return {...state, extraLineCategories: action.extraLineCategories}
        case SET_EXTRA_LINE_PRODUCTS: return {...state, extraLineProducts: action.extraLineProducts}

        case SET_PAYMENT_METHOD: return {...state, paymentMethod: action.paymentMethod}
        case SET_DELIVERY_METHOD: return {...state, deliveryMethod: action.deliveryMethod}
        case SET_PAID: return {...state, paid: action.paid}
        case SET_OCR_LOGS: return {...state, ocrLogs: action.ocrLogs}
        case SET_ORDER: return {...state, order: action.order}

        case SET_COFFEE_LINES:
            return {...state, coffeeLines: action.coffeeLines}
        case SET_ADDRESSES:
            return {...state, addresses: action.addresses}

        case SET_EASY_ORDER_SETUP: return {...state, easyOrderSetup: action.easyOrderSetup}

        case SET_LOADING_CATEGORIES: return {...state, loadingCategories: action.loadingCategories}
        case SET_CAN_REGISTER_RESPONSE: return {...state, canRegisterResponse: action.canRegisterResponse}
        case SET_COOKIES_ACCEPTED: return {...state, cookiesAccepted: action.cookiesAccepted}
        case SET_ORDER_ACCOUNT:
            return {
                ...state,
                orderAccount: action.orderAccount,
                orderAccountLinked: action.orderAccountLinked,
                ecapiAccountCreated: action.ecapiAccountCreated,
            }
        case SET_CART_QUOTATION_DATA:
            return {...state, cartQuotationData: action.cartQuotationData}
        case SET_FREQUENCIES: return {...state, frequencies: action.frequencies}

        case SET_CART_VISIBLE: return {...state, cartVisible: action.cartVisible}

        case SET_LAST_VISIT: return {...state, lastVisit: action.lastVisit}
        case SET_WEB_ACCOUNT_EXISTS: return {...state, webAccountExists: action.webAccountExists}

        case CLEAR_STORE:
            return {
                ...initialState,
                cart: {
                    items: [],
                    sum: '',
                },
            }

        default: return state
    }
}


export const setUuid = (uuid) => ({type: SET_UUID, uuid})
export const setRecaptchaToken = (recaptchaToken) => ({type: SET_RECAPTCHA_TOKEN, recaptchaToken})

export const setIsNewMember = (isNewMember) => ({type: SET_IS_NEW_MEMBER, isNewMember})
export const setNespressoMember = (nespressoMember) => ({type: SET_NESPRESSO_MEMBER, nespressoMember})
export const setNespressoMemberExist = (nespressoMemberExist) => ({type: SET_NESPRESSO_MEMBER_EXIST, nespressoMemberExist})
export const setPromotion = (promotion) => ({type: SET_PROMOTION, promotion})
export const setOnlyPromotion = (promotion) => ({type: SET_ONLY_PROMOTION, promotion})
export const setOpenPromotionModals = (openPromotionModals) => ({type: SET_OPEN_PROMOTION_MODALS, openPromotionModals})
export const setOpenPlanModals = (openPlanModals) => ({type: SET_OPEN_PLAN_MODALS, openPlanModals})
export const setCustomer = (customer) => ({type: SET_CUSTOMER, customer})
export const setOptIn = (optIn) => ({type: SET_OPT_IN, optIn})

export const setPromotions = (promotions) => ({type: SET_PROMOTIONS, promotions})
export const setProducts = (products) => ({type: SET_PRODUCTS, products})
export const setCategories = (categories) => ({type: SET_CATEGORIES, categories})
export const setExtraLineProducts = (extraLineProducts) => ({type: SET_EXTRA_LINE_PRODUCTS, extraLineProducts})
export const setExtraLineCategories = (extraLineCategories) => ({type: SET_EXTRA_LINE_CATEGORIES, extraLineCategories})
export const updateCart = (cart) => ({type: UPDATE_CART, cart})

export const setPaymentMethod = (paymentMethod) => ({type: SET_PAYMENT_METHOD, paymentMethod})
export const setDeliveryMethod = (deliveryMethod) => ({type: SET_DELIVERY_METHOD, deliveryMethod})

export const setPaid = (paid) => ({type: SET_PAID, paid})
export const setOrder = (order) => ({type: SET_ORDER, order})
export const setOcrLogs = (ocrLogs) => ({type: SET_OCR_LOGS, ocrLogs})

export const setCoffeeLines = (coffeeLines) => ({type: SET_COFFEE_LINES, coffeeLines})
export const setAddresses = (addresses) => ({type: SET_ADDRESSES, addresses})

export const setEasyOrderSetup = (easyOrderSetup) => ({type: SET_EASY_ORDER_SETUP, easyOrderSetup})
export const setLoadingCategories = (loadingCategories) => ({type: SET_LOADING_CATEGORIES, loadingCategories})
export const setCanRegisterResponse = (canRegisterResponse) => ({type: SET_CAN_REGISTER_RESPONSE, canRegisterResponse})
export const setOrderAccount = (orderAccount, orderAccountLinked, ecapiAccountCreated = false) => ({
    type: SET_ORDER_ACCOUNT,
    orderAccount,
    orderAccountLinked,
    ecapiAccountCreated,
})
export const setCookiesAccepted = (cookiesAccepted) => ({type: SET_COOKIES_ACCEPTED, cookiesAccepted})

export const setLastVisit = (lastVisit) => ({type: SET_LAST_VISIT, lastVisit})
export const setCartQuotationData = (cartQuotationData) => ({type: SET_CART_QUOTATION_DATA, cartQuotationData})
export const setFrequencies = (frequencies) => ({type: SET_FREQUENCIES, frequencies})

export const setCartVisible = (cartVisible) => ({type: SET_CART_VISIBLE, cartVisible})
export const setWebAccountExists = (webAccountExists) => ({type: SET_WEB_ACCOUNT_EXISTS, webAccountExists})

export const clearStore = () => ({type: CLEAR_STORE})



export default reducer;
