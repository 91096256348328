import {useTranslation} from "../../../../hooks";
import {useResellerUser} from "../hooks";
import {Preloader} from "../../../../misc/_index";
import {InfoComponent} from "../../../../components/_index";
import React from "react";
import {EditResellerForm} from "../../../../forms";
import {useHistory} from "react-router-dom";
import {IoArrowBackOutline} from "react-icons/io5";
import Error401 from "../../../../components/misc/Error401";

export default function UserScreen() {
    const {t} = useTranslation();

    const {getUserQuery, alert, editUserMutation, lackOfAccess} = useResellerUser();
    const history = useHistory()

    if (lackOfAccess)
        return <Error401/>

    return (
        <div className="wrapper">
            <div className="resellerUsers">
                <div className="resHome">
                    <div className="resellerOptions">
                        <div className="resellerOptions__header">
                            <h2 className="heading-2 --uppercase">{t('reseller_client.edit_user')}</h2>
                            <button className="backLink" onClick={() => history.goBack()}>
                                <IoArrowBackOutline/>
                                <span>{t('reseller_client.back_to_resellers')}</span>
                            </button>
                        </div>
                        {getUserQuery.isLoading ? (
                            <Preloader/>
                        ) : (
                            <>
                                {getUserQuery.isError ? (
                                    <div className="grid">
                                        <InfoComponent type={alert.type} message={t(alert.message)}/>
                                        <div/>
                                    </div>
                                ) : (
                                    <EditResellerForm
                                        userData={getUserQuery?.data?.data?.user}
                                        resellerData={getUserQuery?.data?.data?.reseller}
                                        onSubmit={editUserMutation.mutate}
                                        state={{
                                            isLoading: editUserMutation.isLoading
                                        }}
                                        alert={alert}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}