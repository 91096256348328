import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo} from "react";
import {useMutation} from "@tanstack/react-query";
import * as orderActions from "../actions/OrderActions";
import {reducers} from "../misc/Hooks";

export default function useExtraLineProducts(storeName) {
    const mutation = useMutation(
        data => orderActions.getExtraLineProducts(data),
        {
            onSuccess: ({data}) => {
                if (data.status === 'success')
                    addExtraProductToCart(data.data)
            },
            onError: (err) => {
                console.error(err)
            }
        })

    const store = useSelector(state => ({
        cartQuotationData: state[storeName].cartQuotationData,
        cart: state[storeName].cart,
        categories: state[storeName].categories,
        products: state[storeName].products,
        extraLineProducts: state[storeName].extraLineProducts
    }))
    const dispatch = useDispatch();
    const reducer = useMemo(() => reducers[storeName], [storeName])

    useEffect(() => {
        if (store.cartQuotationData) {
            let productsCodes = store.cartQuotationData?.lines?.map(line => line.product.productCode);
            if (productsCodes?.length > 0) mutation.mutate({product_code: productsCodes})
        }
    }, [store.cartQuotationData])

    function addExtraProductToCart(data) {
        const storeCategories = []
        data.categories.map(category => {
                storeCategories.push({
                    ...category,
                    products: data.products.filter(p => p.id_category === category.id_category),
                    category: {
                        ...category,
                        subcategories: [],
                    }
                })
        })

        const storeProducts = []
        const storeCartItems = [...store.cart.items]
        store.extraLineProducts.map((ep) => {
            const idx = storeCartItems.findIndex(item => item.id === ep.id_product)
            if(idx !== -1){
                storeCartItems.splice(idx, 1)
            }
        })

        data.products.map(product => {
            storeProducts.push(product)
            if(!storeCartItems.find(cartItem => cartItem.id === product.id_product)){
                const value = store.cartQuotationData.lines.find(l => l.product.productCode === product.product_code)?.product?.quantity ?? 1
                storeCartItems.push({id: product.id_product, value: value})
            }
        })

        dispatch(reducer.setExtraLineCategories(storeCategories))
        dispatch(reducer.setExtraLineProducts(storeProducts))
        const newCart = {
            items: storeCartItems,
            sum: JSON.stringify(storeCartItems)
        };
        if(newCart.sum !== store.cart.sum){
            dispatch(reducer.updateCart(newCart))
        }
    }
}
