import React from "react";
import CustomerScreenComponent from '../../machine_registration/screens/CustomerScreen';
import WelcomeMessage from "../../../components/WelcomeMessage";

export default function CustomerScreen() {
    return (
        <>
            <WelcomeMessage/>
            <CustomerScreenComponent/>
        </>
    )
}
