import {useFormHelper} from "../../hooks";
import React, {useMemo} from "react";
import {useWatch} from "react-hook-form";
import {PaymentMethodCodeVerifyFormFragment, PaymentMethodIbanProofFormFragment} from "./index";

export default function PaymentMethodConfirmFormFragment({paymentMethods, machinePlan}) {
    const {formMethods} = useFormHelper()
    const paymentMethodId = useWatch({
        control: formMethods.control,
        name: 'paymentMethod',
    })

    const paymentMethod = useMemo(() => {
        return paymentMethods.find(p => p.id_payment_method === Number(paymentMethodId))
    }, [paymentMethodId, paymentMethods])

    return (
        <>
            {!!paymentMethod?.payment_method_need_code_verify && (machinePlan ? (
                <PaymentMethodCodeVerifyFormFragment
                    paymentMethod={paymentMethod}
                    paymentMethodId={paymentMethodId}
                    machinePlan={machinePlan}
                />
            ) : (
                <p className="par-2 --error">
                    Machine plan not found. Please check if this is correct payment method!
                </p>
            ))}

            {!!paymentMethod && ['required', 'optional'].includes(paymentMethod?.payment_method_iban_proof) && (
                <PaymentMethodIbanProofFormFragment
                    paymentMethod={paymentMethod}
                    paymentMethodId={paymentMethodId}
                />
            )}
        </>
    )
}
