import {SelectController} from "../../../forms/controllers";
import {useTranslation} from "../../../hooks";
import {useEffect, useRef, useState} from "react";

export default function PlaceInput({service}) {
    const {t} = useTranslation()
    const inputValueTimeout = useRef(null)
    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState([])
    const [fetching, setFetching] = useState(false)

    useEffect(() => {
        if(inputValue) {
            clearTimeout(inputValueTimeout.current)
            inputValueTimeout.current = setTimeout(() => {
                fetchData(inputValue)
            }, 500)
        } else {
            clearTimeout(inputValueTimeout.current)
        }
    }, [inputValue])

    function fetchData(slug) {
        if(!service) {
            console.error('SERVICE NOT INITIALIZED!')
            return null
        }

        const request = {
            query: slug,
        }

        setFetching(true)
        service.textSearch(request, response => {
            const arr = response.map(i => ({
                value: i.place_id,
                label: i.formatted_address,
                data: i,
            }))
            setOptions(arr)
            setFetching(false)
        })
    }

    const onInputChange = (value) => {
        setInputValue(value)
    }

    return (
        <SelectController
            name="place"
            label={t('pickup_points.place_search_label')}
            selectOptions={options}
            options={{
                isFetching: fetching,
                inputProps: {
                    cacheOptions: true,
                }
            }}
            props={{
                onInputChange,
            }}
        />
    )
}