import {useModuleContext, useModuleStepsNavigation, useReseller, useTranslation} from "../../hooks";
import {useWatch} from "react-hook-form";
import {Form} from "../../forms";
import {useFormRulesBuilder} from "../../forms/hooks";
import SummaryForm from "../SummaryForm";
import PaymentIframe from "../PaymentIframe";
import {Routes} from "../../utils/_index";
import React, {useEffect, useMemo} from "react";
import {CartSummary} from "../cartSummaryComponent";
import {AddressComponent, DataCell} from "./components";
import {useCart, useMarketConfig, useNavigation} from "../../misc/Hooks";
import {SummaryPriceComponent} from "./index";
import FilePreview from "../FilePreview";
import {getAllStepsHeaderHeight} from "../../misc/Helpers";
import {Payment} from "../_index";


export default function SummaryComponent({
    formMethods,
    data,
    orderFinalizedUrl,
    state,
    onSubmit,
    getCustomSummaryOrder,
    previewMode,
    deliveryMethodEnabled,
}) {
    data = {
        customer: null,
        savedOrder: null,
        promotion: null,
        plan: null,
        paymentMethods: [],
        deliveryMethods: [],
        memberCredit: null,
        ...data,
    }
    state = {
        isLoading: false,
        error: null,
        ...state,
    }
    const {isLoggedIn} = useReseller()
    const navigation = useNavigation()
    const marketConfig = useMarketConfig()
    const {t} = useTranslation()
    const {storeName, dispatch, reducer} = useModuleContext()
    const cart = useCart(storeName, data.savedOrder, false);
    const stepNavigation = useModuleStepsNavigation()
    const formRules = useFormRulesBuilder({}, formMethods)
    const paymentMethodId = useWatch({
        control: formMethods.control,
        name: 'paymentMethod',
    })
    const deliveryMethodId = useWatch({
        control: formMethods.control,
        name: 'deliveryMethod',
    })
    const paymentMethod = useMemo(() => {
        let id = Number(paymentMethodId)
        if (data.savedOrder?.order) {
            id = data.savedOrder?.order?.id_payment_method
        }
        if (data.customer?.paymentMethod) {
            id = Number(data.customer?.paymentMethod)
        }
        return data.paymentMethods.find(p => p.id_payment_method === id)
    }, [paymentMethodId, JSON.stringify(data.paymentMethods), JSON.stringify(data.savedOrder?.order)])
    const deliveryMethod = useMemo(() => {
        let id = Number(deliveryMethodId)
        if (data.savedOrder?.order) {
            id = data.savedOrder?.order?.id_delivery_method
        }
        return data?.deliveryMethods?.find(p => p?.id_delivery_method === id)
    }, [deliveryMethodId, JSON.stringify(data.deliveryMethods), JSON.stringify(data.savedOrder?.order)])
    const withCustomAddress = useMemo(() => {
        return data.customer && data.customer.details && data.customer.withCustomAddress
    }, [data.customer])

    useEffect(() => {
        dispatch(reducer.setDeliveryMethod(deliveryMethod))
    }, [deliveryMethod])

    useEffect(() => {
        const cartWrapperRef = document.querySelector('.evDP__cart')
        if (cartWrapperRef) {
            cartWrapperRef.style.marginTop = `-${getAllStepsHeaderHeight()}px`
        }
    })

    const CustomerDetailsComponent = (
        <div>
            <h3 className="evDP__title">{t('ev_delivery_payment.delivery_address')}</h3>
            <AddressComponent
                data={data.customer.details ?? data.customer.customAddress}
            />
            {!!data.customer.paymentDetails && (
                <>
                    <hr className="-light --mb2_5"/>
                    <div className="info__row">
                        <DataCell
                            label={t('bank_account.bsb_number')}
                            value={data.customer.paymentDetails.bsbNumber}
                        />
                        <DataCell
                            label={t('bank_account.number')}
                            value={data.customer.paymentDetails.accountNumber}
                        />
                    </div>
                </>
            )}
            {!!(data.customer.paymentMethodCodeVerify || data.customer.paymentMethodIbanProof?.file) && (
                <hr className="-light --mb2_5"/>
            )}
            {!!data.customer.paymentMethodCodeVerify && (
                <DataCell
                    label={t('payment_method_code_verify.summary_label')}
                    value={data.customer.paymentMethodCodeVerify.accountNumber}
                />
            )}
            {!!data.customer.paymentMethodIbanProof?.uploadedFile && (
                <DataCell
                    label={t('payment_method_iban_proof.summary_label')}
                    value={(
                        <FilePreview
                            file={data.customer.paymentMethodIbanProof.uploadedFile.url}
                        />
                    )}
                />
            )}
        </div>
    )

    const CustomerCustomAddressComponent = !!data.customer.details && (
        <div>
            <h3 className="evDP__title">{t('ev_delivery_payment.custom_delivery_address')}</h3>
            <AddressComponent
                data={data.customer.customAddress}
            />
        </div>
    )

    const PriceComponent = (
        <SummaryPriceComponent
            {...cart}
            moduleName={storeName}
            showNetPrice={marketConfig.showNetPrice}
            promotion={data.promotion}
            paymentMethod={paymentMethod}
            memberCredit={data.memberCredit}
            plan={data.plan}
            getCustomSummaryOrder={getCustomSummaryOrder}
            // deliveryValue={deliveryMethod?.quotationData?.amount}
        />
    )

    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            onSubmit={onSubmit}
        >

            <div className="evDP__info grid -bigColumnGap">
                <div/>
                <div id="summaryStepCart" className="evDP__cart">
                    <CartSummary
                        hidePlusMinusButtons={previewMode || data.savedOrder?.order}
                        scrollable={false}
                        customData={data.savedOrder}
                        disableFooter
                    />
                </div>
            </div>

            {withCustomAddress ? (
                <>

                    <div className="evDP__info grid -bigColumnGap --mb4">
                        <div>
                            {CustomerDetailsComponent}
                        </div>
                        <div>
                            {CustomerCustomAddressComponent}
                        </div>
                    </div>
                    <div className="evDP__info grid -bigColumnGap">
                        <div>
                            {PriceComponent}
                        </div>
                        <div/>
                    </div>
                </>
            ) : (
                <div className="evDP__info grid -bigColumnGap">
                    <div className="evDP__priceBox">
                        {PriceComponent}
                    </div>
                    <div>
                        {CustomerDetailsComponent}
                    </div>
                </div>
            )}

            {!!t('ev_delivery_payment.bottom_info') && (
                <div className="--mb4">
                    <div
                        className="par-2"
                        dangerouslySetInnerHTML={{__html: t('ev_delivery_payment.bottom_info')}}
                    />
                </div>
            )}

            <SummaryForm
                storeName={storeName}
                order={data.savedOrder}
                customer={data.customer}
                plan={data.plan}
                deliveryMethods={data.deliveryMethods}
                paymentMethods={data.paymentMethods}
                isCartValid={cart.isCartValid}
                error={state.error}
                loading={state.isLoading}
                submitDisabled={state.submitDisabled}
                paymentMethod={paymentMethod}
                deliveryMethod={deliveryMethod}
                stepNavigation={stepNavigation}
                deliveryMethodEnabled={deliveryMethodEnabled}
            >
                {(data.savedOrder?.order?.quotation_response === 'PAYMENT') && !isLoggedIn && (
                    <PaymentIframe
                        uuid={data.savedOrder.order.uuid}
                        memberToken={data.savedOrder.order.member_token}
                        orderFinalizedUrl={orderFinalizedUrl || navigation(Routes.ORDER_FINALIZED, data.savedOrder.order.uuid)}
                    />
                )}
                {(data.savedOrder?.order?.quotation_response === 'ADYEN') && !isLoggedIn && (
                    <Payment
                        uuid={data.savedOrder.order.uuid}
                        orderFinalizedUrl={orderFinalizedUrl || navigation(Routes.ORDER_FINALIZED, data.savedOrder.order.uuid)}
                    />
                )}
            </SummaryForm>

        </Form>
    )
}
