import {useDispatch} from "react-redux";
import axios from "axios";
import Env from "../utils/Env";
import * as stayHomeReducer from "../redux/stayHomeReducer";
import {useHistory} from "react-router-dom";
import {useNavigation} from "../misc/Hooks";
import {Routes} from "../utils/_index";
import {useLanguage} from "../hooks";


export const useShActions = () => {
    const history = useHistory()
    const navigation = useNavigation()
    const dispatch = useDispatch();
    const instance = axios.create();
    const currentLanguage = useLanguage();



    instance.defaults.baseURL = Env.API_URL + '/stay-home/';
    instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

    instance.defaults.headers.common['Language'] = currentLanguage;


    instance.interceptors.response.use(r => {
        const res = r.data;
        if (res.status === 'error') {
            if (res.messages.find(m => m === 'token_invalid')) {
                dispatch(stayHomeReducer.clearStore())
                history.push(navigation(Routes.STAY_HOME_TOKEN_EXPIRED))
            }
        }
        return r;
    }, err => err)


    const checkEmail = email => instance.post('check-email', {email});
    const plans = token => instance.get(`gears/${token}`);
    const checkCode = (code, token) => instance.post(`check-code/${code}/${token}`);
    const saveOrder = (token, data) => instance.post(`stay-home/gears/${token}`, data);
    const getCategories = (token, machineId, userLoggedIn) => {
        return instance.get(`/categories/${token}/${machineId}/${userLoggedIn}`);
    }

    return {
        checkEmail,
        plans,
        checkCode,
        saveOrder,
        getCategories,
    }
}
