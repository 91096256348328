import CustomerScreen from "../screens/CustomerScreen";
import {useSeoPage} from "../../../hooks";
import WelcomeMessage from "../../../components/WelcomeMessage";

export default function CustomerContainer() {
    useSeoPage({title: 'etrade.customer.title'})

    return (
        <>
            <WelcomeMessage/>
            <CustomerScreen/>
        </>
    )
}
