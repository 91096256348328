import {useQuery} from "@tanstack/react-query";
import {fetchAll} from '../actions/CategoryActions';
import {useCountry, useModuleContext, useNespressoMember, useReseller} from "./index";
import {ApiException} from "../errors";
import {useDispatch, useSelector} from "react-redux";
import {checkAutoAdd, getDate} from "../misc/Helpers";
import {createContext, useContext, useMemo} from "react";
import {reducers, useCart} from "../misc/Hooks";
import {useResellerActions} from "../actions/useResellerActions";
import {isEnabledByMarket} from "../utils/MarketConfig";
import mapToOldCategoriesStructure from "../helpers/mapToOldCategoriesStructure";

const CategoriesContext = createContext(null)
export const CategoriesProvider = CategoriesContext.Provider

export function useCategoriesContext() {
    const {storeName} = useModuleContext()
    const reducer = useMemo(() => reducers[storeName], [storeName])
    const dispatch = useDispatch()
    const {categories, products} = useSelector(state => ({
        categories: state[storeName]?.categories,
        products: state[storeName]?.products,
    }))
    const context = useContext(CategoriesContext)

    return {
        ...context,
        categories,
        products,
    }
}

export default function useFetchCategories() {
    const {isLoggedIn} = useReseller()
    const resellerActions = useResellerActions()
    const {storeName, reducer} = useModuleContext()
    const dispatch = useDispatch()
    const {nespressoMember, isNewMember} = useNespressoMember()
    const {data: country} = useCountry()
    const store = useSelector(state => ({
        resellerShop: state.reseller?.shop,
        promotion: state[storeName]?.promotion,
        machine: state[storeName]?.machine,
        proofOfPurchase: state[storeName]?.proofOfPurchase,
        promoCode: state[storeName]?.promoCode,
        categories: state[storeName]?.categories,
        products: state[storeName]?.products,
        customer: state[storeName]?.customer,
        cart: state[storeName]?.cart,
        msnRequired: state[storeName]?.msnRequired,
    }))
    const {
        params,
        queryParamObj,
    } = useMemo(() => {
        const params = {
            promotionId: store.promotion?.id_promotion ?? 0,
            machineId: store.machine?.id_machine ?? 0,
            shopId: store.promoCode ? store.promoCode.id_shop : store.proofOfPurchase?.shop?.value ?? store.resellerShop ?? 0,
            isExistingMember: isNewMember ? 0 : 1,
        }
        const queryParamObj = {}
        if (!isEnabledByMarket('disableEcapi') && !isNewMember && nespressoMember?.memberNumber) {
            queryParamObj.member_number = nespressoMember?.memberNumber;
        }

        if(!store.msnRequired) {
            queryParamObj.msn_required = store.machine ? 1 : 0
        }

        return {
            params,
            queryParamObj,
        }
    }, [
        isNewMember,
        nespressoMember,
        store.promotion,
        store.machine,
        store.promoCode,
        store.proofOfPurchase,
        store.resellerShop,
        store.msnRequired,
    ])
    const query = useQuery(
        ['categories', params, queryParamObj],
        async () => {
            const action = isLoggedIn ? resellerActions.fetchAllCategories : fetchAll

            const {data} = await action(params, queryParamObj)

            if(data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        {
            enabled: !!store.promotion,
            initialData: {
                categories: store.categories,
                products: store.products,
            },
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                const {categories, products} = mapToOldCategoriesStructure(data)
                dispatch(reducer.setCategories(categories))
                dispatch(reducer.setProducts(products))

                dispatch(reducer.updateCart(
                    checkAutoAdd(
                        categories,
                        products,
                        store.cart ?? {items: [], sum: ''},
                        store.machine,
                        store.proofOfPurchase?.machinePrice,
                        store.customer,
                        true,
                        {rules: !!store.promotion ? store.promotion.promotion_rules : null}
                    )
                ))
            },
        },
    )

    return {
        query,
    }
}
