import Axios from "../utils/Axios";
import {queryParams} from "../misc/Helpers";


export const getTranslations = (lang) => {
    return Axios.get(`/translations`);
}

export const getTYP = (promotionId) => {
    return Axios.get(`/thank-you-page/${promotionId}`);
}

export const getThankYouPage = (orderUuid) => {
    return Axios.get(`/typ/uuid/${orderUuid}`);
}

export const getPlanCoffeeTYP = (productId) => {
    return Axios.get(`/plan-machine/thank-you-page/${productId}`);
}

export const getEtradeTYP = (uuid) => {
    return Axios.get(`/etrade/thank-you-page/${uuid}`);
}

export const getStayHomeTYP = (uuid) => {
    return Axios.get(`/stay-home/thank-you-page/${uuid}`);
}

export const proofOfPurchaseOcr = (data, config) => {
    return Axios.post(`/validate-pop`, data, config);
}

export const proofOfPurchasePdfOcr = (data, config) => {
    return Axios.post(`/preview-pdf`, data, config);
}

// export const getPlanByMachineId = (id) => {
//     return Axios.get(`/reseller/plan-coffee/${id}`);
// }

export const getStackByType = (type, params = '') => {
    return Axios.get(`/stack/${type}${params}`);
}

export const getPromoCode = (code, machineId = null) => {
    return Axios.get(`/promo-code/${code}?id_machine=${machineId}`);
}

export const getETradePlan = (slug) => {
    return Axios.get(`/etrade/plans/${slug}`);
}

export function getCitiesByPostcode(postcode) {
    return Axios.get(`cities/${postcode}`)
}

export function generateQrCode(paramObj) {
    const params = queryParams(paramObj)
    return Axios.get(`/qr${params}`)
}

export function getStayHomeConfig() {
    return Axios.get(`/stay-home-config`)
}

export const getBank = (data, paramObj = null) => {
    return Axios.post(`/bank${queryParams(paramObj)}`, data);
}

export const getBankByBsbNumber = (bsbNumber, paramObj = null) => {
    return Axios.get(`/bank/${bsbNumber}${queryParams(paramObj)}`);
}

export const generateCode = (data, planId) => {
    return Axios.post(`/generate-code/${planId}`, data);
}

export const verifyCode = (code, token) => {
    return Axios.post(`/check-code/${code}/${token}`);
}

export function sefazInvoiceNumberValidation(value) {
    return Axios.post(`sefaz`, {nfe: value})
}

export function getCities(params) {
    return Axios.get(`/cities${params}`)
}

export function getPostcodes(params) {
    return Axios.get(`/postcodes${params}`)
}

export function getAxLandingPage(slug) {
    return Axios.get(`/ax/get/${slug}`)
}

export function sendAxEmail(data){
    return Axios.post(`/ax/send-email`, data)
}

export function storeFile(data) {
    return Axios.post(`/store-file`, data)
}

export const getAxExperiences = () => {
    return Axios.get(`/ax/experiences`)
}

export const getAxShop = (posNumber) => {
    return Axios.get(`/ax/shops/${posNumber}`)
}

export const webAccountExists = (data) => {
    return Axios.post(`/v2/nessoft/web-account/exists`, data)
}

export const postGtmLog = (payload) => {
    return Axios.post(`/v2/log/create/gtm`, payload)
}

export const nonPersistentQuotationPrepare = (payload) => {
    return Axios.post(`/v2/nessoft/quotation/non-persistent/prepare`, payload)
}

export const nonPersistentQuotationVerify = (payload) => {
    return Axios.post(`/v2/nessoft/quotation/non-persistent/verify`, payload)
}

export const pointsOfInterest = (data) => {
    return Axios.post(`/v2/nessoft/points-of-interest`, data)
}


export const getAdyenSessionByOrder = (paramObj) => {
    const params = queryParams(paramObj)
    return Axios.get(`v2/payment/adyen/session${params}`)
}

