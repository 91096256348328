import React, {useEffect, useState} from "react";
import PagePreloader from "../../components/PagePreloader";
import {useHistory, useLocation, useParams} from "react-router-dom";
import * as orderActions from '../../actions/OrderActions';
import {useNavigation} from "../../misc/Hooks";
import Routes from "../../utils/Routes";
import {checkAutoAdd, mapOrder, mapOrderToCustomer2, uuidv4} from "../../misc/Helpers";
import {useCivilityOptions, useCountryOptions, useStackOptions} from "../../hooks";
import {useDispatch, useSelector} from "react-redux";
import * as shopReopenReducer from "../../redux/shopReopenReducer";
import * as promotionActions from "../../actions/PromotionActions";
import * as categoryActions from "../../actions/CategoryActions";
import * as shopActions from "../../actions/ShopActions";
import {useResellerActions} from "../../actions/useResellerActions";
import mapToOldCategoriesStructure from "../../helpers/mapToOldCategoriesStructure";

export default function Provider(props) {
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()
    const location = useLocation()
    const navigation = useNavigation()
    const civilityOptions = useCivilityOptions()
    const countryOptions = useCountryOptions()
    const professionOptions = useStackOptions('professions')
    const regionOptions = useStackOptions('regions')
    const resellerActions = useResellerActions();
    const store = useSelector(({main, shopReopen}) => ({
        country: main.country,
        machine: shopReopen.machine,
        data: shopReopen.data,
        shops: shopReopen.shops,
        promotion: shopReopen.promotion,
        cart: shopReopen.cart,
        proofOfPurchaseLogs: shopReopen.proofOfPurchaseLogs,
        nespressoMember: shopReopen.nespressoMember
    }))
    const orderUuid = new URLSearchParams(location?.search).get('uuid')
    const [loaded, setLoaded] = useState((!params.token && store.data) || !!orderUuid)

    useEffect(() => {
        if (params.token) {
            fetch(params.token)
                .then(async order => {
                    if (order) {
                        const {cart} = mapOrder(order)
                        const customer = mapOrderToCustomer2(order, civilityOptions, professionOptions, regionOptions, countryOptions)

                        dispatch(shopReopenReducer.clearStore())
                        resellerActions.logout();

                        await Promise.all([
                            fetchPromotion(order, cart),
                            fetchShops(order),
                        ])

                        dispatch(shopReopenReducer.setCustomer(customer))
                        dispatch(shopReopenReducer.setIsNewMember(!order.webaccount_exist))
                        dispatch(shopReopenReducer.setUuid(uuidv4()))
                        dispatch(shopReopenReducer.setData(order))

                        history.push(navigation(Routes.SHOP_REOPEN_AUTHORIZATION))
                        setLoaded(true)
                    } else {
                        history.push(navigation(Routes.SHOP_REOPEN_INVALID_TOKEN))
                        setLoaded(true)
                    }
                })
        } else if (!store.data && !orderUuid) {
            history.push(navigation(Routes.SHOP_REOPEN_INVALID_TOKEN))
            setLoaded(true)
        }

    }, [])

    useEffect(() => {
        // if (store.data && store.promotion) {
        //     getCategories()
        // }
    }, [store.promotion])

    const fetch = async (token) => {
        if (!token) return null
        return await orderActions.reopen(token)
            .then(r => {
                const res = r.data
                if (res.status === 'success') {
                    return res.data
                } else {
                    return null
                }
            })
            .catch(err => {
                console.error(err)
                return null
            })
    }

    const fetchPromotion = async (order, cart) => {
        if (order.id_promotion) {
            const params = {}
            const payload = {}

            if (order.webaccount_exist) {
                params['member_number'] = order.member_number
            }

            if (order.id_promo_code) {
                payload.id_promotions = [order.id_promotion]
            }
            if (order.promo_code) {
                dispatch(shopReopenReducer.setPromoCode({
                    ...order.promo_code,
                    id_shop: order.id_shop,
                }))
            }

            const promotions = await promotionActions.getAll(
                order.id_country,
                order.machine.id_machine,
                order.id_shop,
                order.invoice_date,
                order.webaccount_exist,
                0,
                params,
                payload,
            ).then(r => {
                const res = r.data;
                if (res.status === 'success') {
                    return res.data.sort((a, b) => a.promotion_priority > b.promotion_priority ? -1 : 1);
                } else {
                    console.warn('Something gone wrong! ', res);
                }
            }).catch(err => {
                console.error('Error: ', err);
            })

            if (promotions) {
                dispatch(shopReopenReducer.setPromotions(promotions))
            }

            const promotion = promotions?.find(p => p.id_promotion === order.id_promotion)
            if (promotion) {
                dispatch(shopReopenReducer.setPromotion(promotion))

                let params = {}
                if (store.nespressoMember?.member_id) {
                    params.member_number = store.nespressoMember?.member_id;
                }

                await categoryActions.getAll(
                    order.id_promotion,
                    order.id_machine ?? 0,
                    order.id_shop ?? 0,
                    order.webaccount_exist,
                    params
                ).then(handleSuccess).catch(err => {
                    console.error('Error: ', err)
                })

                async function handleSuccess(r) {
                    const res = r.data;
                    if (res.status === 'success') {
                        const {categories, products} = mapToOldCategoriesStructure(res.data)

                        dispatch(shopReopenReducer.setProducts(Object.values(products)));
                        dispatch(shopReopenReducer.setCategories(categories, false));
                        dispatch(shopReopenReducer.updateCart(checkAutoAdd(
                            categories,
                            Object.values(products),
                            cart,
                            order.machine,
                            order.status === -1 ? null : Number(order.invoice_price)
                        )))
                    } else {
                        console.warn('Something gone wrong! ', res);
                    }
                }
            }
        }
    }

    const fetchShops = async (order) => {
        const shops = await getShops(order.id_country)
            .catch(err => {
                console.error(err)
                history.push(navigation(Routes.MACHINE_REGISTRATION_REGISTRATION))
            })

        dispatch(shopReopenReducer.setShops(shops))

        dispatch(shopReopenReducer.setMachine(order.machine, order.test_order, {serial_number: order.serial}))
        if (order.shop && order.filename_receipt) {
            const shopGroup = shops.find(s => s.id_shop_group === order.shop.id_shop_group)
            const shop = shopGroup?.shops.find(s => s.id_shop === order.id_shop)
            dispatch(shopReopenReducer.setProofOfPurchase({
                file: order.filename_receipt,
                date: new Date(order.invoice_date),
                shopGroup: shopGroup ? {value: shopGroup.id_shop_group, label: shopGroup.shop_group_title} : null,
                shop: shop ? {value: shop.id_shop, label: shop.shop_title} : null,
                invoiceNumber: order.invoice_number,
                machinePrice: order.status === -1 ? null : order.invoice_price,
            }))
            dispatch(shopReopenReducer.setProofOfPurchaseLogs({
                data: {
                    invoice: order.ocr_number,
                    date: order.ocr_date,
                    total: order.ocr_price,
                    id_shop: order.ocr_shop,
                    id_shop_group: order.ocr_shop_group,
                },
                filename: order.filename_receipt,
            }))
            dispatch(shopReopenReducer.setOcrLogs(JSON.parse(order.ocr_log)))
        }
    }

    const getShops = async (countryId = store.country.id_country) => {
        return await shopActions.getAll(countryId).then(r => {
            const res = r.data;
            if (res.status === 'success') {
                const items = res.data.sort((a, b) => a.shop_group_title.toLowerCase() < b.shop_group_title.toLowerCase() ? -1 : 1);
                items.map(g => g.shops = g.shops.sort((a, b) => a.shop_title.toLowerCase() < b.shop_title.toLowerCase() ? -1 : 0))
                return items
            } else {
                console.warn('Something gone wrong! ', res)
                throw new Error('Something gone wrong')
            }
        })
    }

    return (
        <>
            {loaded ? props.children : <PagePreloader/>}
        </>
    )
}
