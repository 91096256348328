import {useParams} from "react-router-dom"
import React from "react"
import {useNavigation} from "../../misc/Hooks"
import {Config, Routes} from "../../utils/_index"
import {Step} from "./components"
import {StepsHeader} from "../../components/_index";
import {CartSideBox} from "../../components/cartSummaryComponent";
import BaseStepsFunnel from "../../components/stepsFunnel";

export default function StepsFunnel() {
    const navigation = useNavigation()
    const params = useParams()

    return (
        <BaseStepsFunnel
            sessionTime={Config.ORDER_FINISH_SESSION_TIME}
            homeRoute={navigation(Routes.ORDER_FINISH, params.uuid)}
            summaryRoute={Routes.ORDER_FINISH_SUMMARY}
            StepComponent={Step}
            HeaderComponent={props => (
                <StepsHeader
                    {...props}
                    disableEdit
                    alertsDisabled
                />
            )}
            CartSideBoxComponent={props => (
                <CartSideBox
                    {...props}
                    disableEdit
                />
            )}
        />
    )
}
