import React from 'react';
import {useDispatch} from 'react-redux';
import {Button} from "./buttons/_index";
import * as mainReducer from '../redux/mainReducer';
import * as rmsReducer from '../redux/rmsReducer';
import {useHistory} from "react-router-dom";
import {Routes} from "../utils/_index";
import {useNavigation, useTranslation} from "../misc/Hooks";
import {useReseller} from "../hooks";

export default function AuthBox(props) {
    const t = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const reseller = useReseller();
    const navigation = useNavigation();


    const handleClick = (e) => {
        e.preventDefault();
        dispatch(rmsReducer.clearStore());
        dispatch(mainReducer.clearUser());
        history.push(navigation(Routes.V2_RESELLER_AUTH))
    }


    if(!reseller.user)
        return null;

    return (
        <>

                <Button
                    size="sm"
                    type="grey"
                    label={t('reseller')}
                    href={navigation(Routes.V2_RESELLER)}
                />

            <Button
                size="sm"
                type="grey"
                label={t('logout')}
                onClick={handleClick}
            />
        </>
    )
}
