import {useQuery} from "@tanstack/react-query";
import {useSelector} from "react-redux";
import * as mainActions from '../../../../actions/MainActions';

export default function useShop() {
    const store = useSelector(({reseller}) => ({
        shop: reseller.shop,
    }))
    const query = useQuery(
        ['reseller.shop', store.shop],
        () => mainActions.getAxShop(store.shop),
    )

    return {
        query,
        data: query.isSuccess ? query.data?.data?.data : null,
    }
}
