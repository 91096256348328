import Env from "../utils/Env";
import {useSelector} from "react-redux";


export default function useLanguages() {
    const country = useSelector(({main}) => main.country)

    const languages = Env.LANGUAGES_FROM_BACKOFFICE ? (
        country?.country_languages?.map(l => l.lang_code) ?? Env.LANGUAGES
    ) : Env.LANGUAGES;

    const defaultLang = Env.DEFAULT_LANG ? languages.includes(Env.DEFAULT_LANG) ? Env.DEFAULT_LANG : languages[0] : languages[0];

    return {
        languages,
        defaultLang,
    }
}
