import {AsyncSelectController} from "../../../forms/controllers";
import React, {useEffect} from "react";
import {useAsyncSelect} from "../../../forms/hooks";
import * as promotionActions from "../../../actions/PromotionActions";
import {useModuleContext, useTranslation} from "../../../hooks";
import {useLocalizeObject} from "../../../misc/Hooks";
import {useWatch} from "react-hook-form";

export default function PromotionSelect({isLoading}) {
    const {store, dispatch, reducer} = useModuleContext()
    const localizeObject = useLocalizeObject()
    const {t} = useTranslation();

    const promotionSelect = useAsyncSelect({
        name: 'promotion',
        action: () => promotionActions.tps(store.country?.id_country),
        mapFunc: i => new Option(`${localizeObject(i.promotion_title)} - ${i.promotion_name}`, i.id_promotion),
        deps: [store.country?.id_country],
        queryOptions: {
            onSuccess: ({data: {data}}) => {
                dispatch(reducer.setPromotions(data))
            }
        }
    })

    return (
        <AsyncSelectController
            name="promotion"
            label={t('tps.promotion')}
            asyncSelect={promotionSelect}
            options={{
                isLoading: isLoading,
                disabled: isLoading,
            }}
        />
    )
}