import {createContext, useContext} from "react";

export const FormFragmentContext = createContext({prefix: null})
export const FormFragmentProvider = FormFragmentContext.Provider

export default function useFormFragmentContext() {
    const context = useContext(FormFragmentContext)

    const getInputName = (name) => {
        if(context.prefix)
            return `${context.prefix}.${name}`
        return name
    }

    return {
        context,
        getInputName,
    }
}
