import './style.scss'
import React, {useEffect, useMemo} from "react";
import {useTranslation} from "../../../../hooks";
import {TablePagination} from "../index";
import {Select} from "../../../../forms/inputs";
import {SelectController} from "../../../../forms/controllers";
import {Form} from "../../../../forms";
import {useForm, useWatch} from "react-hook-form";

export default function TableFooter({setSize, page, size, query, setPage}){
    const {t} = useTranslation();

    const formMethods = useForm({
        mode: 'onChange',
    })

    const sizeOptions = useMemo(() => ([
        {label: '5', value: 5},
        {label: '10', value: 10},
        {label: '20', value: 20},
        {label: '50', value: 50},
        {label: '100', value: 100},
    ]), [])

    const sizeWatch = useWatch({
        control: formMethods.control,
        name: 'size'
    })

    useEffect(() => {
        setSize(sizeWatch?.value ?? 10)
    }, [sizeWatch])

    return (
       <div className="tableFooter">
           <div className="tableFooter__resultSize">
                <Form formMethods={formMethods}>
                    <SelectController
                        name="size"
                        label={t('table.results')}
                        selectOptions={sizeOptions}
                        defaultValue={{label: size ?? 10, value: size ?? 10}}
                    />
                </Form>
               <span className="tableFooter__result">{t('table.page_status', page, size, query?.data?.data?.data?.total ?? 0)}</span>
           </div>
           <TablePagination data={query?.data?.data?.data} setPage={setPage}/>
       </div>
    )
}