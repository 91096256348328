import Axios from "../utils/Axios";
import {queryParams} from "../misc/Helpers";


export const get = (id_country) => {
    return Axios.get(`/country/${id_country}`);
}


export const getAll = () => {
    return Axios.get(`/countries`);
}


export const getByCode = (code) => {
    return Axios.get(`/countries/code/${code}`);
}


export const getByDomain = (paramObj) => {
    const params = queryParams(paramObj)
    return Axios.get(`/countries/by-domain${params}`);
}
