import {MachineRegistrationScreen} from "../../machine_registration/screens";


export default function MachineRegistrationStepScreen() {
    return (
        <MachineRegistrationScreen
            authorizationDisabled
        />
    )
}
