import {Fragment} from "react";
import {useTranslation} from "../../../../hooks";
import Button from "../../../../components/buttons/Button";
import {useResellerVerify} from "../hooks";
import {Preloader} from "../../../../misc/_index";

export default function VerifyButtons({idReseller, query}) {
    const {t} = useTranslation()

    const {verifyMutation} = useResellerVerify(query)

    return (
        <Fragment>
            {query.isFetching ? <Preloader size="1rem"/> : (
                <Fragment>
                    {verifyMutation.isLoading ? (<span>{t('reseller_verification.wait')}</span>) : (
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: 'center',
                            columnGap: '.5rem'
                        }}>
                            <Button
                                size="sm"
                                label={t('reseller_client.accept') || t('accept')}
                                onClick={() => verifyMutation.mutate({id: idReseller, status: 1})}
                            />
                            <Button
                                size="sm"
                                label={t('reseller_client.reject') || t('reject')}
                                type={'secondary'}
                                onClick={() => verifyMutation.mutate({id: idReseller, status: -1})}
                            />
                        </div>
                        )}
                </Fragment>
            )}
        </Fragment>
    )
}