import {useMutation} from "@tanstack/react-query";
import * as mainActions from '../../../actions/MainActions';

export default function usePaymentMethodIbanProof(storeName) {
    const mutation = useMutation(data => mainActions.storeFile(data), {
        onSuccess: ({data}) => {
            if(data.status === 'error')
                throw new Error('error')
        },
    })

    const store = async (file) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('extension', file.name.split('.').reverse()[0])
        return mutation.mutate(formData)
    }

    return {
        mutation,
        data: mutation.data?.data?.data,
        store,
    }
}
