import {Route, Switch} from "react-router-dom";
import React from "react";
import Provider from "./Provider";
import StepsFunnel from "./StepsFunnel";
import {useSteps} from "../../hooks/modules/subscription";
import {getStepsRoutes} from "../../misc/Helpers";
import {ConfirmOrderScreen} from "./screens";
import Routes from "../../utils/Routes";
import {makeStore, ModuleProvider} from "../../hooks";
import {AppProvider} from "../../misc/_index";

export default function Funnel(props) {
    const steps = useSteps()

    const contextValue = makeStore({
        storeName: 'subscription',
        steps,
    })

    return (
        <AppProvider>
            <ModuleProvider value={contextValue}>
                <Provider>
                    <Switch>
                        <Route
                            exact path={getStepsRoutes(steps)}
                            component={StepsFunnel}
                        />
                        <Route
                            exact path={Routes.SUBSCRIPTION_CONFIRM_ORDER}
                            component={ConfirmOrderScreen}
                        />
                    </Switch>
                </Provider>
            </ModuleProvider>
        </AppProvider>
    )
}
