import {useSelector} from "react-redux";
import {Config} from "../utils/_index";
import {useMemo} from "react";

export default function useCartValidation(storeName) {
    const store = useSelector(state => ({
        cart: state[storeName]?.cart,
        categories: state[storeName]?.categories,
        products: state[storeName]?.products,
    }))
    const {status, firstError} = useMemo(() => {
        const status = getStatus()
        return {
            status,
            firstError: status.invalidCategories[0]
                // .filter(c => ![
                //     Config.COFFEE_TYPE.GIFT,
                //     Config.COFFEE_TYPE.REBATE,
                //     Config.COFFEE_TYPE.EASY_ORDER_GIFT,
                //     Config.COFFEE_TYPE.DELIVERY,
                //     Config.COFFEE_TYPE.EXTRA_LINE,
                // ].includes(c.category.category.category_type))[0]
        }
    }, [store.cart, store.categories, store.products])

    function validateCategory(category) {
        if (!category)
            return null
        if (typeof category !== 'object')
            category = store.categories.find(c => c.id_promotion_category === category)

        const validatedCategory = new ValidatedCategory({
            category,
            isRequired: category.promotion_category_required_category,
            minimalProducts: category.promotion_category_minimal_products,
        })

        let productsIds = category.products.map(p => p.id_product)
        category.category.subcategories?.map(s => s.products.map(p => productsIds.push(p.id_product)))

        if (!productsIds.length) {
            validatedCategory.isValid = true
            return validatedCategory
        }

        const cartItems = store.cart.items.filter(i => productsIds.includes(i.id))

        if ([Config.COFFEE_TYPE.PLAN_WORK_HOME, Config.COFFEE_TYPE.PLAN_MACHINE].includes(category.category.category_type)) {
            if (cartItems.length)
                validatedCategory.isValid = true
            else
                validatedCategory.isValid = !!store.cart.items.length
            return validatedCategory
        }

        let productsCount = 0;
        cartItems.map(i => {
            const prod = store.products.find(p => p.id_product === i.id);
            if (prod) {
                switch (prod.product_type) {
                    case Config.COFFEE_TYPE.PACKAGE:
                        if (prod.product_coffees_in_set?.length) {
                            let c = 0;
                            Object.keys(prod.product_coffees_in_set).map(id => c += Number(prod.product_coffees_in_set[id]))
                            productsCount += i.value * c;
                        } else {
                            productsCount += i.value * prod.product_package_qty
                        }
                        break;
                    case Config.COFFEE_TYPE.ACCESSORY:
                        break;
                    default:
                        productsCount += i.value * prod.product_package_qty
                }
            }
        })

        if (validatedCategory.isRequired || (!validatedCategory.isRequired && productsCount)) {
            validatedCategory.isValid = !(productsCount < validatedCategory.minimalProducts
                || productsCount % category.promotion_category_interval_products !== 0);

            validatedCategory.missingProducts = validatedCategory.minimalProducts - productsCount
            if (validatedCategory.missingProducts < 0)
                validatedCategory.missingProducts = 0

            validatedCategory.invalidInterval = productsCount % category.promotion_category_interval_products !== 0
        } else {
            validatedCategory.isValid = true
        }

        return validatedCategory
    }

    function isCartValid() {
        const res = store.categories.map(c => {
            return validateCategory(c)
        })
        return !res.find(c => !c.isValid)
    }

    function getStatus() {
        const res = store.categories.map(c => {
            return validateCategory(c)
        })

        return {
            isValid: !res.find(c => !c.isValid),
            invalidCategories: res.filter(c => !c.isValid),
        }
    }

    return {
        status,
        firstError,
        getStatus,
        isCartValid,
        validateCategory
    }
}

class ValidatedCategory {
    category = null
    isValid = false
    isRequired = false
    minimalProducts = 0
    missingProducts = 0
    invalidInterval = false


    constructor({category, isRequired, minimalProducts}) {
        this.category = category;
        this.isRequired = isRequired;
        this.minimalProducts = minimalProducts;
    }
}
