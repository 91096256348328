import QRCode from "react-qr-code";
import {useLanguage, useTranslation} from "../../../../hooks";
import {useCountryConfig, useMarketConfig} from "../../../../misc/Hooks";
import {ExperienceItem, ExperiencesList} from "../components";
import {useMemo} from "react";
import {useHistory} from "react-router-dom";
import {useExperiences, useShop} from "../hooks";
import {Preloader} from "../../../../misc/_index";
import {getURLSearchParams} from "../../../../misc/Helpers";

export default function AxScreen() {
    const {t} = useTranslation();
    const marketConfig = useMarketConfig();
    const countryConfig = useCountryConfig()
    const currentLanguage = useLanguage()
    const shop = useShop()
    const history = useHistory()
    const experiences = useExperiences()
    const experience = useMemo(() => {
        const params = new URLSearchParams(history.location.search)
        return experiences.data.find(e => e.id_ax_experience === Number(params.get('id')))
    }, [experiences.data, history.location])
    const url = useMemo(() => {
        if (!experience)
            return null

        let url = marketConfig.ax_slug ?? ''
        url += experience.ax_experience_url

        const params = {
            layout: 'info',
            market: countryConfig.ax_market,
            language: currentLanguage,
        }

        if(marketConfig.ax_shop_mapping && shop.data) {
            params.store = shop.data.ax_shop_pos
        }

        url += getURLSearchParams(params)
        return url
    }, [experience])

    const handleChange = (experience) => {
        history.replace({search: `?id=${experience.id_ax_experience}`})
    }

    return (
        <div className="wrapper">
            {experiences.query.isLoading && (
                <Preloader/>
            )}

            {experiences.query.isSuccess && (
                <>
                    <div className="--mb4">
                        {experience ? (
                            <>
                                <button
                                    className="link -primary -parentSize --mb2"
                                    onClick={() => history.replace({search: null})}
                                >{t('reseller_ax.display_all_experiences')}</button>
                                <ExperienceItem
                                    item={experience}
                                    classNames="-horizontal"
                                />
                            </>
                        ) : (
                            <>
                                <h1 className="heading-2">{t('reseller_ax.experiences_title')}</h1>
                                <ExperiencesList
                                    data={experiences.data}
                                    selectedItem={experience}
                                    onChange={handleChange}
                                />
                            </>
                        )}
                    </div>
                    {!!experience && (
                        <div className="axQrCode">
                            <h1 className="heading-2">{t('reseller_ax.qr')}</h1>
                            <div className="--mb3">
                                <QRCode size={300} value={url}/>
                            </div>
                            <div style={{maxWidth: '100%', overflowX: 'auto'}}>
                                <a href={url} target="_blank">{url}</a>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
