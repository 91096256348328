
const SET_UUID = 'subscription/setUuid';
const SET_RECAPTCHA_TOKEN = 'subscription/setRecaptchaToken';
const SET_QR_CODE_URL = 'subscription/setQrCodeUrl';
const SET_SHOPS = 'subscription/setShops';
const SET_SHOP_DATA = 'subscription/setShopData';

const SET_MACHINE = 'subscription/setMachine';
const SET_PROOF_OF_PURCHASE = 'subscription/setProofOfPurchase';
const SET_PROOF_OF_PURCHASE_LOGS = 'subscription/setProofOfPurchaseLogs';
const SET_IS_NEW_MEMBER = 'ev/setIsNewMember';
const SET_NESPRESSO_MEMBER = 'subscription/setNespressoMember';
const SET_NESPRESSO_MEMBER_EXIST = 'subscription/setNespressoMemberExist';
const SET_PROMOTION = 'subscription/setPromotion';
const SET_CUSTOMER = 'subscription/setCustomer';

const SET_PRODUCTS = 'subscription/setProducts';
const SET_CATEGORIES = 'subscription/setCategories';
const UPDATE_CART = 'subscription/updateCart';
const SET_PLANS = 'subscription/setPlans';

const SET_EXTRA_LINE_PRODUCTS = 'subscription/setExtraLineProducts';
const SET_EXTRA_LINE_CATEGORIES = 'subscription/setExtraLineCategories';

const SET_PAID = 'subscription/setPaid';
const SET_OCR_LOGS = 'subscription/setOcrLogs';
const SET_ORDER = 'subscription/setOrder';

const SET_PAYMENT_METHOD = 'subscription/setPaymentMethod';
const SET_DELIVERY_METHOD = 'subscription/setDeliveryMethod';
const SET_VERIFIED_PAYMENT_METHOD = 'subscription/setVerifiedPaymentMethod';
const SET_BANK = 'subscription/setBank';
const SET_BANKS = 'subscription/setBanks';
const SET_VERIFICATION_CODE_TOKEN = 'subscription/setVerificationCodeToken';
const SET_CAN_REGISTER_RESPONSE = 'subscription/setCanRegisterResponse';
const SET_LAST_VISIT = 'subscription/setLastVisit';
const SET_ORDER_ACCOUNT = 'subscription/setOrderAccount';

const SET_CART_QUOTATION_DATA = 'subscription/setCartQuotationData';
const SET_CART_VISIBLE = 'subscription/setCartVisible';
const SET_ADDRESSES = 'subscription/setAddresses';
const SET_RESELLER_LOGGED = 'subscription/setResellerLogged';
const SET_OPEN_PLAN_MODALS = 'subscription/setOpenPlanModals';
const SET_SEARCHED_MEMBERS = 'machineRegistration/setSearchedMembers';
const SET_SEARCHED_MEMBER = 'machineRegistration/setSearchedMember';
const SET_SEARCH_MEMBER_ATTEMPTS = 'machineRegistration/setSearchMemberAttempts';
const SET_SEARCH_MEMBER_ATTEMPT_DELAYED_AT = 'machineRegistration/setSearchMemberAttemptDelayedAt';

const SET_OPT_IN = 'subscription/setOptIn';
const SET_WEB_ACCOUNT_EXISTS = 'subscription/setWebAccountExists';
const SET_PERSONALIZATION = 'subscription/setPersonalization';
const SET_MSN_REQUIRED = 'machineRegistration/setMsnRequired';

const CLEAR_STORE = 'subscription/clearStore';


const initialState = {
    uuid: '',
    qrCodeUrl: null,
    shops: [],
    shopData: null,

    testOrder: false,
    serialNumber: null,

    machine: null,
    proofOfPurchase: null,
    proofOfPurchaseLogs: null,
    proofOfPurchaseHistory: null,
    isNewMember: undefined,
    nespressoMember: null,
    nespressoMemberExist: false,
    promotion: null,
    customer: null,

    categories: [],
    products: [],

    extraLineCategories: [],
    extraLineProducts: [],

    cart: {
        items: [],
        sum: '',
    },

    paymentMethod: null,
    deliveryMethod: null,
    setVerifiedPaymentMethod: null,
    order: null,
    paid: false,

    recaptchaToken: null,

    ocrLogs: [],
    bank: null,
    banks: [],
    verificationCodeToken: '',

    canRegisterResponse: null,

    orderAccount: null,
    orderAccountLinked: false,
    ecapiAccountCreated: false,
    cartQuotationData: null,

    lastVisit: null,
    cartVisible: false,
    addresses: [],
    isResellerLogged: false,
    openPlanModals: [],

    optIn: null,
    searchedMembers: [],
    searchedMember: null,
    searchMemberAttempts: -1,
    searchMemberAttemptDelayedAt: null,
    webAccountExists: false,
    personalization: null,
    msnRequired: true,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_UUID: return {...state, uuid: action.uuid}
        case SET_RECAPTCHA_TOKEN: return {...state, recaptchaToken: action.recaptchaToken}
        case SET_QR_CODE_URL: return {...state, qrCodeUrl: action.qrCodeUrl}
        case SET_SHOPS: return {...state, shops: action.shops}
        case SET_SHOP_DATA: return {...state, shopData: action.shopData}

        case SET_MACHINE: {
            const res = {
                ...state,
                machine: action.machine,
            }
            if(action.testOrder) {
                res.testOrder = action.testOrder;
            }
            if(action.serialNumber) {
                res.serialNumber = action.serialNumber;
            }
            return res;
        }
        case SET_PROOF_OF_PURCHASE: return {...state, proofOfPurchase: action.proofOfPurchase}
        case SET_PROOF_OF_PURCHASE_LOGS: return {...state, proofOfPurchaseLogs: action.proofOfPurchaseLogs}
        case SET_IS_NEW_MEMBER: return {...state, isNewMember: action.isNewMember}
        case SET_NESPRESSO_MEMBER: return {...state, nespressoMember: action.nespressoMember}
        case SET_NESPRESSO_MEMBER_EXIST: return {...state, nespressoMemberExist: action.nespressoMemberExist}
        case SET_PROMOTION:
            return {
                ...state,
                promotion: action.promotion,
                products: initialState.products,
                categories: initialState.categories,
                cart: initialState.cart,
            }
        case SET_CUSTOMER: return {...state, customer: action.customer}
        case SET_OPT_IN: return {...state, optIn: action.optIn}

        case SET_PRODUCTS: return {...state, products: action.products}
        case SET_CATEGORIES: return {...state, categories: action.categories}
        case UPDATE_CART: return {...state, cart: action.cart}
        case SET_PLANS: return {...state, plans: action.plans}
        case SET_OPEN_PLAN_MODALS: return {...state, openPlanModals: action.openPlanModals}

        case SET_EXTRA_LINE_CATEGORIES: return {...state, extraLineCategories: action.extraLineCategories}
        case SET_EXTRA_LINE_PRODUCTS: return {...state, extraLineProducts: action.extraLineProducts}

        case SET_PAYMENT_METHOD: return {...state, paymentMethod: action.paymentMethod}
        case SET_DELIVERY_METHOD: return {...state, deliveryMethod: action.deliveryMethod}
        case SET_VERIFIED_PAYMENT_METHOD: return {...state, verifiedPaymentMethod: action.verifiedPaymentMethod}
        case SET_PAID: return {...state, paid: action.paid}
        case SET_OCR_LOGS: return {...state, ocrLogs: action.ocrLogs}
        case SET_ORDER: return {...state, order: action.order}
        case SET_BANK: return {...state, bank: action.bank}
        case SET_BANKS: return {...state, banks: action.banks}
        case SET_VERIFICATION_CODE_TOKEN: return {...state, verificationCodeToken: action.verificationCodeToken}
        case SET_CAN_REGISTER_RESPONSE: return {...state, canRegisterResponse: action.canRegisterResponse}
        case SET_LAST_VISIT: return {...state, lastVisit: action.lastVisit}
        case SET_ORDER_ACCOUNT:
            return {
                ...state,
                orderAccount: action.orderAccount,
                orderAccountLinked: action.orderAccountLinked,
                ecapiAccountCreated: action.ecapiAccountCreated,
            }
        case SET_CART_QUOTATION_DATA:
            return {...state, cartQuotationData: action.cartQuotationData}
        case SET_CART_VISIBLE: return {...state, cartVisible: action.cartVisible}
        case SET_ADDRESSES:
            return {...state, addresses: action.addresses}
        case SET_RESELLER_LOGGED:
            return {...state, isResellerLogged: action.isResellerLogged}
        case SET_SEARCHED_MEMBERS:return {...state, searchedMembers: action.searchedMembers}
        case SET_SEARCHED_MEMBER:return {...state, searchedMember: action.searchedMember}
        case SET_SEARCH_MEMBER_ATTEMPTS:return {...state, searchMemberAttempts: action.searchMemberAttempts}
        case SET_SEARCH_MEMBER_ATTEMPT_DELAYED_AT:return {...state, searchMemberAttemptDelayedAt: action.searchMemberAttemptDelayedAt}
        case SET_PERSONALIZATION: return {...state, personalization: action.personalization}
        case SET_WEB_ACCOUNT_EXISTS:
            return {...state, webAccountExists: action.webAccountExists}
        case SET_MSN_REQUIRED:return {...state, msnRequired: action.msnRequired}
        case CLEAR_STORE: return {...initialState};

        default: return state;

    }
}


export const setUuid = (uuid) => ({type: SET_UUID, uuid})
export const setRecaptchaToken = (recaptchaToken) => ({type: SET_RECAPTCHA_TOKEN, recaptchaToken})
export const setQrCodeUrl = (qrCodeUrl) => ({type: SET_QR_CODE_URL, qrCodeUrl})
export const setShops = (shops) => ({type: SET_SHOPS, shops})
export const setShopData = (shopData) => ({type: SET_SHOP_DATA, shopData})

export const setMachine = (machine, testOrder = null, serialNumber = null) => ({
    type: SET_MACHINE,
    machine,
    testOrder,
    serialNumber
})
export const setIsNewMember = (isNewMember) => ({type: SET_IS_NEW_MEMBER, isNewMember})
export const setNespressoMember = (nespressoMember) => ({type: SET_NESPRESSO_MEMBER, nespressoMember})
export const setNespressoMemberExist = (nespressoMemberExist) => ({type: SET_NESPRESSO_MEMBER_EXIST, nespressoMemberExist})
export const setProofOfPurchase = (proofOfPurchase) => ({type: SET_PROOF_OF_PURCHASE, proofOfPurchase})
export const setProofOfPurchaseLogs = (proofOfPurchaseLogs) => ({type: SET_PROOF_OF_PURCHASE_LOGS, proofOfPurchaseLogs})
export const setPromotion = (promotion) => ({type: SET_PROMOTION, promotion})
export const setCustomer = (customer) => ({type: SET_CUSTOMER, customer})
export const setOptIn = (optIn) => ({type: SET_OPT_IN, optIn})

export const setProducts = (products) => ({type: SET_PRODUCTS, products})
export const setCategories = (categories) => ({type: SET_CATEGORIES, categories})
export const updateCart = (cart) => ({type: UPDATE_CART, cart})
export const setPlans = (plans) => ({type: SET_PLANS, plans})
export const setOpenPlanModals = (openPlanModals) => ({type: SET_OPEN_PLAN_MODALS, openPlanModals})

export const setExtraLineProducts = (extraLineProducts) => ({type: SET_EXTRA_LINE_PRODUCTS, extraLineProducts})
export const setExtraLineCategories = (extraLineCategories) => ({type: SET_EXTRA_LINE_CATEGORIES, extraLineCategories})

export const setPaymentMethod = (paymentMethod) => ({type: SET_PAYMENT_METHOD, paymentMethod})
export const setDeliveryMethod = (deliveryMethod) => ({type: SET_DELIVERY_METHOD, deliveryMethod})
export const setVerifiedPaymentMethod = (verifiedPaymentMethod) => ({type: SET_VERIFIED_PAYMENT_METHOD, verifiedPaymentMethod})

export const setPaid = (paid) => ({type: SET_PAID, paid})
export const setOrder = (order) => ({type: SET_ORDER, order})
export const setOcrLogs = (ocrLogs) => ({type: SET_OCR_LOGS, ocrLogs})
export const setBank = (bank) => ({type: SET_BANK, bank})
export const setBanks = (banks) => ({type: SET_BANKS, banks})
export const setVerificationCodeToken = (verificationCodeToken) => ({type: SET_VERIFICATION_CODE_TOKEN, verificationCodeToken})
export const setCanRegisterResponse = (canRegisterResponse) => ({type: SET_CAN_REGISTER_RESPONSE, canRegisterResponse})
export const setLastVisit = (lastVisit) => ({type: SET_LAST_VISIT, lastVisit})
export const setOrderAccount = (orderAccount, orderAccountLinked, ecapiAccountCreated = false) => ({
    type: SET_ORDER_ACCOUNT,
    orderAccount,
    orderAccountLinked,
    ecapiAccountCreated,
})
export const setCartQuotationData = (cartQuotationData) => ({type: SET_CART_QUOTATION_DATA, cartQuotationData})
export const setCartVisible = (cartVisible) => ({type: SET_CART_VISIBLE, cartVisible})
export const setAddresses = (addresses) => ({type: SET_ADDRESSES, addresses})
export const setResellerLogged = (isResellerLogged) => ({type: SET_RESELLER_LOGGED, isResellerLogged})
export const setSearchedMembers = (searchedMembers) => ({type: SET_SEARCHED_MEMBERS, searchedMembers})
export const setSearchedMember = (searchedMember) => ({type: SET_SEARCHED_MEMBER, searchedMember})
export const setSearchMemberAttempts = (searchMemberAttempts) => ({type: SET_SEARCH_MEMBER_ATTEMPTS, searchMemberAttempts})
export const setSearchMemberAttemptDelayedAt = (searchMemberAttemptDelayedAt) => ({type: SET_SEARCH_MEMBER_ATTEMPT_DELAYED_AT, searchMemberAttemptDelayedAt})
export const setPersonalization = (personalization) => ({type: SET_PERSONALIZATION, personalization})
export const setWebAccountExists = (webAccountExists) => ({type: SET_WEB_ACCOUNT_EXISTS, webAccountExists})
export const setMsnRequired = (msnRequired) => ({type: SET_MSN_REQUIRED, msnRequired})

export const clearStore = () => ({type: CLEAR_STORE})



export default reducer;
