import Form from "./Form";
import {useForm, useWatch} from "react-hook-form";
import {useFormRulesBuilder} from "./hooks";
import {FormGroup} from "./components";
import {EmailInput, PasswordInput} from "./presets";
import React from "react";
import {useTranslation} from "../hooks";
import {Button} from "../components/buttons/_index";
import {Link} from "react-router-dom";
import {Routes} from "../utils/_index";
import {useNavigation} from "../misc/Hooks";
import {TextInputController} from "./controllers";
import {AiTwotoneEdit} from "react-icons/ai";

export default function ResellerLoginForm({
    HeaderComponent,
    FooterComponent,
    onSubmit,
    SubmitComponent,
    submitText,
    text,
    state,
    defaultLogin,
    editLogin
}) {

    state = {
        isLoading: false,
        error: null,
        ...state,
    }

    const {t} = useTranslation();
    const navigation = useNavigation();

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues:{
            login: defaultLogin ?? ''
        }
    })
    const formRules = useFormRulesBuilder({
        'login': b => b.required(),
        'password': b => b.required(),
    }, formMethods)

    const handleSubmit = (data) => {
        if (typeof onSubmit === 'function')
            onSubmit(data)
    }

    const login = useWatch({
        control: formMethods.control,
        name: 'login'
    })

    const password = useWatch({
        control: formMethods.control,
        name: 'password'
    })


    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            onSubmit={handleSubmit}
        >
            {HeaderComponent ? <HeaderComponent/> : (
                <p className="par-2">{text ?? t('ev_customer_identification.sign_in')}</p>
            )}


            <FormGroup>
                <div className="resellerLoginInput">
                    <TextInputController
                        name="login"
                        label={t('login')}
                        defaultValue={defaultLogin}
                        options={{
                            inputProps: {
                                disabled: state.isLoading || defaultLogin
                            }
                        }}
                    />
                    <button
                        onClick={editLogin}
                        className="resellerLoginInput__edit"
                        type="button"
                    >
                        <AiTwotoneEdit/>
                    </button>
                </div>
            </FormGroup>

            <FormGroup>
                <PasswordInput
                    options={{
                        inputProps: {
                            disabled: state.isLoading
                        }
                    }}
                />
            </FormGroup>

            {FooterComponent ? <FooterComponent/> : (
                <div className="--mb2" style={{textAlign: 'right'}}>
                        <Button
                            type="grey"
                            size="sm"
                            label={t('forgot_your_password')}
                            href={navigation(Routes.V2_RESELLER_FORGOT_PASSWORD)}
                        />
                </div>
            )}

            {SubmitComponent ? <SubmitComponent/> : (
                <Button
                    btnType="submit"
                    type="primary"
                    label={submitText ?? t('continue')}
                    disabled={state.isLoading || !login || !password}
                    isLoading={state.isLoading}
                />
            )}

        </Form>
    )


}