import React from "react";
import {useNespressoMember, useTranslation} from "../../../hooks";
import {AuthForm} from "../../../forms";
import {useNavigation} from "../../../misc/Hooks";
import {useHistory} from "react-router-dom";
import Routes from "../../../utils/Routes";

export default function AbandonedOrderScreen({
    store,
}) {
    const {t} = useTranslation()
    const navigation = useNavigation()
    const history = useHistory()
    const {loginMutation, error, storeLoggedUser} = useNespressoMember()

    const onSubmit = (data) => {
        const payload = {
            login: data.email,
            password: data.password,
            recaptchaToken: data.recaptchaToken,
            serial: store.serialNumber?.serial_number,
        }

        loginMutation.mutateAsync(payload).then((data) => {
            storeLoggedUser(data, payload)
            history.push(navigation(Routes.MACHINE_REGISTRATION_SUMMARY))
        })
    }

    return (
        <div>
            {!!t('ev_machine_registration.abandon_order_text') && (
                <div className="grid --mb3">
                    <div>
                        <p
                            className="par-1 --semiBold"
                            dangerouslySetInnerHTML={{
                                __html: t('ev_machine_registration.abandon_order_text', store.abandonedOrder.order?.customer?.details?.firstname ?? '')
                            }}
                        />
                    </div>
                    <div/>
                </div>
            )}

            <div className="grid">
                <div>
                    <AuthForm
                        state={{
                            error: error,
                            isLoading: loginMutation.isLoading,
                        }}
                        onSubmit={onSubmit}
                    />
                </div>
                <div/>
            </div>
        </div>
    )
}
