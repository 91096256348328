import Form from "./Form";
import {useFormRulesBuilder} from "./hooks";
import {useForm, useWatch} from "react-hook-form";
import {useModuleContext, useTranslation} from "../hooks";
import React, {useEffect, useState} from "react";
import {CityInput, ShopGroupSelect, ShopSelect} from "./presets";
import {FormGroup, InputPreview} from "./components";
import editIcon from '../assets/img/icons/edit.svg';
import {Button} from "../components/buttons/_index";

export default function ShopForm({
    editable,
    setEditable,
    onSubmit,
}) {
    const {t} = useTranslation()
    const {store} = useModuleContext()
    const formMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            shopGroup: null,
            shop: null,
            city: '',
            ...store.shopData,
        },
    })
    const formRules = useFormRulesBuilder({
        city: b => b,
        shopGroup: b => b.required(),
        shop: b => b.required(),
    }, formMethods)
    const params = new URLSearchParams(store.qrCodeUrl)
    const [shopGroupDisabled, setShopGroupDisabled] = useState(!!params.get('keyAccount'))
    const [shopDisabled, setShopDisabled] = useState(!!params.get('pos'))
    const shopGroup = useWatch({
        control: formMethods.control,
        name: 'shopGroup',
    })
    const shop = useWatch({
        control: formMethods.control,
        name: 'shop',
    })
    const city = useWatch({
        control: formMethods.control,
        name: 'city',
    })

    useEffect(() => {
        if(!editable) return
        if (typeof shop !== 'number' && typeof shop !== 'string')
            formMethods.setValue('shop', null)
    }, [shopGroup?.value])

    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            onSubmit={onSubmit}
        >
            {editable ? (
                <div>
                    <p className="par-1 --semiBold --mb3">{t('subscription.shop_selection_title')}</p>

                    <FormGroup>
                        <ShopGroupSelect
                            rules={formRules.values.shopGroup}
                        />
                    </FormGroup>
                    <FormGroup>
                        <CityInput
                            rules={formRules.values.city}
                            options={{
                                inputProps: {
                                    autoComplete: 'off',
                                }
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ShopSelect
                            rules={formRules.values.shop}
                            groupId={shopGroup?.value}
                            city={city}
                        />
                    </FormGroup>

                    <Button
                        btnType="submit"
                        label={t('continue')}
                    />
                </div>
            ) : (
                <div className="--mb4">
                    {!!t('subscription.shop_selected_title') && (
                        <p
                            className="par-1 --semiBold"
                            dangerouslySetInnerHTML={{__html: t('subscription.shop_selected_title')}}
                        />
                    )}
                    <InputPreview label={t('trade_partner')} value={shopGroup?.label}/>
                    <InputPreview label={t('retailer_store')} value={shop?.label}/>

                    {!(shopGroupDisabled && shopDisabled) && (
                        <button
                            type="button"
                            className="link -primary"
                            onClick={() => setEditable(true)}
                        >
                            {t('subscription.edit_form_button')}
                            {' '}
                            <img src={editIcon} alt="" style={{width: '12px'}}/>
                        </button>
                    )}
                </div>
            )}
        </Form>
    )
}
