import {useSelector} from "react-redux";

export default function useCategoryCupSizes() {
    const store = useSelector(({main}) => ({
        coffeeSizes: main.coffeeSizes,
    }))

    const getAll = (category) => {
        const arr = category.products.reduce((sizes, product) => {
            sizes.push(...product.product_cup_size)
            return sizes
        }, [])
        return [...new Set(arr)]
            .map(s => store.coffeeSizes.find(i => i.stack_value === (s.toString().endsWith('_image') ? s : `${s}_image`)))
            .filter(s => s)
    }

    return {
        getAll,
    }
}
