import {createContext, useContext, useMemo} from "react";
import {reducers} from "../misc/Hooks";
import {useDispatch, useSelector} from "react-redux";

const Context = createContext(makeStore({}))

export const ModuleProvider = Context.Provider

export default function useModuleContext() {
    const context = useContext(Context)
    const reducer = useMemo(() => reducers[context.storeName], [context.storeName])
    const dispatch = useDispatch()
    const store = useSelector(state => state[context.storeName])

    return {
        ...context,
        reducer,
        store,
        dispatch,
    }
}

export function makeStore({
    storeName,
    steps,
}) {
    return {
        storeName,
        steps,
    }
}
