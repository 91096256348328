
export function Address({
    addressId,
    isCompanyAddress,
    companyName,
    title,
    firstname,
    lastname,
    city,
    postcode,
    addressLine1,
    addressLine2,
    phone,
    contact,
    isMain,
}) {
    return {
        addressId,
        isCompanyAddress,
        companyName,
        title,
        firstname,
        lastname,
        city,
        postcode,
        addressLine1,
        addressLine2,
        phone,
        contact,
        isMain,
    }
}

export function nespressoMemberAddress(data) {
    if(!data)
        return null
    return new Address({
        addressId: data.id,
        isCompanyAddress: !!data.is_company,
        companyName: data.is_company ? data.name : '',
        title: data.title,
        firstname: data.is_company ? '' : data.name,
        lastname: data.surname,
        city: data.city,
        postcode: data.postcode,
        addressLine1: data.address,
        addressLine2: data.address2,
        phone: data.phone,
        contact: data.contact,
        isMain: data?.is_main
    })
}

export function nespressoMemberAddressFromCreateAccount(data) {
    if(!data)
        return null
    return new Address({
        addressId: data.address_id,
        isCompanyAddress: !!data.delivery_is_company,
        companyName: data.delivery_company_name,
        title: data.delivery_civility,
        firstname: data.delivery_firstname,
        lastname: data.delivery_lastname,
        city: data.delivery_city,
        postcode: data.delivery_zipcode,
        addressLine1: data.delivery_address1,
        addressLine2: data.delivery_address2,
        phone: data.delivery_phone,
        contact: null,
    })
}

export function nespressoMemberCustomAddressFromCreateAccount(data) {
    if(!data || !data.other_delivery)
        return null
    return new Address({
        addressId: data.other_address_id,
        isCompanyAddress: !!data.other_delivery_is_company,
        companyName: data.other_delivery_company_name,
        title: data.other_delivery_civility,
        firstname: data.other_delivery_firstname,
        lastname: data.other_delivery_lastname,
        city: data.other_delivery_city,
        postcode: data.other_delivery_zipcode,
        addressLine1: data.other_delivery_address1,
        addressLine2: data.other_delivery_address2,
        phone: data.other_delivery_phone,
        contact: null,
    })
}

export function getAddressLabel(address) {
    if(!address)
        return ''
    const part1 = [address.addressLine1, address.addressLine2].join(' ')
    const part2 = [address.postcode, address.city].join(' ')
    return [part1, part2].join(', ')
}
