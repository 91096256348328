import Badge from "../Badge";
import React, {useMemo} from "react";
import {useTranslation} from "../../hooks";


export default function PaymentStatusLabel({status, ...props}) {
    const {t} = useTranslation()
    const className = useMemo(() => {
        switch (status) {
            case -1:
                return '-error';
            case 'expired':
                return '-dark';
            case 0:
                return '-success -outline';
            case 1:
                return '-success -outline';
            case 2:
                return '-success -outline';
            case 3:
                return '-success -outline';
        }
    }, [status])

    return (
        <Badge className={className} label={t(`payment_status.${status}`)}/>
    )
}
