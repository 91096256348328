import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {useTranslation} from "../hooks";
import {useFormBuilder, useFormRulesBuilder} from "./hooks";
import Form from "./Form";
import {FormBuilderComponent, FormGroup} from "./components";
import {Button} from "../components/buttons/_index";
import {PasswordInput} from "./presets";
import {RecaptchaController} from "./controllers";


export default function PasswordForm({
    state,
    nespressoMember,
    onSubmit,
}) {
    state = {
        isLoading: false,
        error: null,
        ...state,
    }
    const {t} = useTranslation()
    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            password: '',
            rePassword: '',
            recaptchaToken: '',
        }
    });
    const formRules = useFormRulesBuilder({
        'rePassword': b => b.required().password().equals('$password', {sameAs: t('password')}),
    }, formMethods)

    useEffect(() => {
        if(state.error) {
            formMethods.setError('password', {type: 'invalid', message: state.error})
            formMethods.setError('rePassword', {type: 'invalid', message: state.error})
        } else {
            formMethods.clearErrors(['password', 'rePassword'])
        }
    }, [state.error])

    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            state={{
                isDisabled: state.isLoading,
            }}
            onSubmit={onSubmit}
        >
            <FormGroup>
                <PasswordInput/>
            </FormGroup>
            <FormGroup>
                <PasswordInput
                    name="rePassword"
                    rules={formRules.values.rePassword}
                    label={t('re_password')}
                />
            </FormGroup>
            <FormGroup>
                <RecaptchaController
                    name="recaptchaToken"
                />
            </FormGroup>

            <Button
                btnType="submit"
                label={state.isLoading ? t(`customer_form.status.${state.status}`) || '...' : (!nespressoMember ? t('save_my_data') : t('continue'))}
                isLoading={state.isLoading}
                disabled={state.isLoading}
            />
        </Form>
    );
}
