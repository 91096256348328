

export default function stripHtmlTags(text) {
    console.log(text)
    const div = document.createElement('div')
    div.innerHTML = text
    const stripedText = div.textContent || div.innerText || ''
    return stripedText.replace(/[\r\n\t]/gm, '')
        .replace(/(&nbsp;| )/gm, ' ')
        .replace(/\s+/gm, ' ')
        .replace(/^ | $/, '')
}
