import {useIdleTimer} from "react-idle-timer";
import * as resellerReducer from "../redux/resellerReducer";
import {useReseller, useTranslation} from "../hooks";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {getSessionTime} from "../utils/MarketConfig";
import {useBeforeunload} from "react-beforeunload";
import Routes from "../utils/Routes";
import {useHistory} from "react-router-dom";
import {useNavigation} from "../misc/Hooks";

export default function ResellerAppProvider(props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const navigation = useNavigation()
    const {logout, reseller} = useReseller()
    const {getLastActiveTime} = useIdleTimer({
        timeout: getSessionTime('reseller'),
        onIdle: () => logoutAndNav(),
        debounce: 500,
        crossTab: true,
    })

    useEffect(() => {
        if (reseller.lastVisit) {
            if ((reseller.lastVisit + getSessionTime('reseller')) <= Date.now()) {
                logoutAndNav()
            }
        }
    }, [])

    useBeforeunload(e => {
        dispatch(resellerReducer.setLastVisit(getLastActiveTime().getTime()))
    })

    function logoutAndNav() {
        logout()
        history.push({
            pathname: navigation(Routes.V2_RESELLER_AUTH),
            state: {
                message: t('session_expired.reseller')
            }
        })
    }

    return props.children
}
