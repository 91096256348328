import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useResellerActions} from "../../actions/useResellerActions";
import {useAbandonedOrder, useGTM, useLanguage, useReseller} from "../../hooks";
import {useDispatch, useSelector} from "react-redux";
import Axios from "../../utils/Axios";
import * as machineRegistrationReducer from "../../redux/machineRegistrationReducer";
import {uuidv4} from "../../misc/Helpers";
import {useNavigation} from "../../misc/Hooks";
import Routes from "../../utils/Routes";
import * as MainActions from "../../actions/MainActions";
import PagePreloader from "../../components/PagePreloader";
import {useMutation} from "@tanstack/react-query";

let MOUNTED = false;
let LOADING_UUID = false;

export default function Provider(props) {
    const {isLoggedIn} = useReseller()
    const resellerActions = useResellerActions()
    const currentLanguage = useLanguage();
    const location = useLocation()
    const history = useHistory()
    const navigation = useNavigation()
    const abandonedOrder = useAbandonedOrder('machineRegistration')
    const dispatch = useDispatch();
    const {showPage, redemptionProcessStarted} = useGTM()
    const store = useSelector(({main, reseller, machineRegistration}) => ({
        user: main.user,
        marketConfig: main.marketConfig,
        country: main.country,
        coffeeTypes: main.coffeeTypes,
        coffeeSizes: main.coffeeSizes,
        aromaticProfiles: main.aromaticProfiles,
        civilities: main.civilities,
        shop: reseller.shop,
        promotion: machineRegistration.promotion,
        machine: machineRegistration.machine,
        proofOfPurchase: machineRegistration.proofOfPurchase,
        cart: machineRegistration.cart,
        uuid: machineRegistration.uuid,
        coffeeLines: machineRegistration.coffeeLines,
        categories: machineRegistration.categories,
        products: machineRegistration.products,
        nespressoMember: machineRegistration.nespressoMember,
        customer: machineRegistration.customer,
        cookiesAccepted: machineRegistration.cookiesAccepted,
        isNewMember: machineRegistration.isNewMember,
    }))
    const queryParams = new URLSearchParams(location.search)
    const [loaded, setLoaded] = useState(!queryParams.get('promoCode') && !queryParams.get('abandonedOrder'))

    useEffect(() => {
        Axios.defaults.headers.common['Language'] = currentLanguage;

        if (!store.promotion) {
            dispatch(machineRegistrationReducer.updateCart({items: [], sum: ''}))
        }
    }, [store.categories])

    useEffect(async () => {
        MOUNTED = true;

        showPage()
        redemptionProcessStarted()

        // if(isLoggedIn) {
        //     await localStockPromotionsMutation.mutateAsync()
        // }

        const params = new URLSearchParams(location.search)
        const postPurchase = params.get('postPurchase')
        const promoCode = params.get('promoCode')
        const abandonedOrderUuid = params.get('abandonedOrder')
        if (postPurchase) {
            resellerActions.logout()
            dispatch(machineRegistrationReducer.clearStore())
            dispatch(machineRegistrationReducer.setUuid(uuidv4()));
            dispatch(machineRegistrationReducer.setIsPostPurchasePromoCode(true))
            if (promoCode) {
                await MainActions.getPromoCode(promoCode, 0)
                    .then(async r => {
                        const res = r.data;
                        if (res.status === 'success') {
                            if (MOUNTED) {
                                dispatch(machineRegistrationReducer.setPromoCode(res.data));
                                if (res.data.promotion?.length) {
                                    dispatch(machineRegistrationReducer.setPromotions(res.data.promotion))
                                    dispatch(machineRegistrationReducer.setPromotion(res.data.promotion[0]))
                                }
                                setLoaded(true)
                                history.push(navigation(Routes.MACHINE_REGISTRATION))
                            }
                        } else {
                            dispatch(machineRegistrationReducer.clearStore())
                            setLoaded(true)
                            history.push(navigation(Routes.MACHINE_REGISTRATION))
                        }
                    })
                    .catch(err => console.error(err))
            }
        } else if (abandonedOrderUuid) {
            dispatch(machineRegistrationReducer.clearStore())
            getAbandonedOrder(abandonedOrderUuid)
                .finally(() => setLoaded(true))
        }

        return () => {
            MOUNTED = false;
        }
    }, [])

    const getAbandonedOrder = async (uuid) => {
        await abandonedOrder.get(uuid)
            .catch(err => {
                console.error('Error on fetch abandon order', err)
                history.push(navigation(Routes.MACHINE_REGISTRATION_REGISTRATION))
            })
    }

    return (
        <>
            {loaded ? props.children : <PagePreloader/>}
        </>
    )
}
