import {useTranslation} from "../../../misc/Hooks";
import React, {useEffect, useState} from "react";
import {Button} from "../../../components/buttons/_index";
import {useStepsNavigation} from "../../../hooks/modules/order_finish";
import {AuthForm, PasswordForm} from "../../../forms";
import {useCreateNespressoMember, useModuleContext, useNespressoMember} from "../../../hooks";
import {InfoComponent} from "../../../components/_index";
import * as ofReducer from "../../../redux/ofReducer";
import GTM from "../../../misc/GTM";


export default function AuthorizationScreen() {
    const t = useTranslation()
    const {store, dispatch} = useModuleContext()
    const stepsNavigation = useStepsNavigation()
    const {
        nespressoMember,
        loginMutation,
        error: loginError,
        setError: setLoginError,
        loginAttempts,
        storeLoggedUser
    } = useNespressoMember()
    const [alert, setAlert] = useState({type: '', message: ''})
    const {
        createAccount,
        status,
        setError: setCreateAccountError,
        error: createAccountError,
        isLoading,
        loginFormVisible,
    } = useCreateNespressoMember()

    useEffect(() => {
        if (loginAttempts >= 2) {
            if (store.finishOrder.allow_registration) {
                setAlert({type: 'info', message: 'finish_order_account.alert_create_account'})
            } else {
                setAlert({type: 'info', message: 'finish_order_account.alert_forgot_password'})
            }
        }
    }, [loginAttempts])

    const handleLogin = (data) => {
        setAlert({type: '', message: ''})
        const payload = {
            login: data.email,
            password: data.password,
            recaptchaToken: data.recaptchaToken,
        }
        loginMutation.mutateAsync(payload).then((data) => {
            if (store.finishOrder?.promotion_type?.startsWith('subscription_') && data.has_subscription) {
                setLoginError(t('validation.customer_already_has_subscription'))
                return
            }


            storeLoggedUser(data, payload)
            stepsNavigation.navToNext()
        })
    }

    const handleSubmit = async (data) => {
        const payload = {
            ...store.customer,
            recaptchaToken: data.recaptchaToken,
            details: {
                ...store.customer.details,
                password: data.password,
                rePassword: data.rePassword,
            }
        }

        const account = await createAccount({data: payload})
            .catch(e => {
                console.log(e)
                setCreateAccountError(t('error.unknown_error'))
            })

        if (account) {
            stepsNavigation.navToNext()
        }
    }

    const onCreateNewAccount = () => {
        dispatch(ofReducer.setCanRegister(true))
        dispatch(ofReducer.setIsNewMember(true))
        stepsNavigation.navToNext()
    }

    const AuthFooterComponent = ({forgotPasswordLink}) => (
        <>
            <div className="--mb3 orderFinishSignInFormButtons">
                <a href={forgotPasswordLink ?? t('forgot_your_password_url')} target="_blank">
                    <Button
                        type="grey"
                        size="sm"
                        label={t('forgot_your_password')}
                    />
                </a>
                {(store.finishOrder.allow_registration && loginAttempts >= 2) && (
                    <Button
                        size="sm"
                        type="grey"
                        label={t('create_new_account')}
                        onClick={onCreateNewAccount}
                    />
                )}
            </div>
            <InfoComponent type={alert.type} message={t(alert.message)} liteShadow customClassName="--mb3"/>
        </>
    )

    return (
        <div>
            <div className="grid">
                <div>
                    {nespressoMember ? (
                        <>
                            <p className="par-2 --mb4">{t('finish_order_account.you_are_signed_in')}</p>
                            <div className="step__nav -bottom">
                                <Button
                                    label={t('continue')}
                                    href={stepsNavigation.next()}
                                />
                            </div>

                        </>
                    ) : store.finishOrder?.member_number || store.finishOrder?.webaccount_exist || loginFormVisible ? (
                        <>
                            <div className="--mb4">
                                <AuthForm
                                    state={{
                                        isLoading: loginMutation.isLoading,
                                        error: loginError,
                                    }}
                                    defaultValues={{
                                        email: store.finishOrder?.was_web_account_found ? store.finishOrder?.delivery_email : undefined,
                                    }}
                                    onSubmit={handleLogin}
                                    FooterComponent={AuthFooterComponent}
                                />
                            </div>
                        </>
                    ) : (
                        <PasswordForm
                            state={{
                                isLoading,
                                status,
                                error: createAccountError,
                            }}
                            nespressoMember={nespressoMember}
                            onSubmit={handleSubmit}
                        />
                    )}
                </div>
                <div/>
            </div>
        </div>
    )

}
