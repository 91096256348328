import React, {useState} from 'react';
import BaseModal from "./BaseModal";
import {GetInTouchModal} from "./_index";
import {useTranslation} from "../../misc/Hooks";
import {getMarketImage} from "../../utils/MarketConfig";

export default (props) => {
    const t = useTranslation();
    const [gitModalVisible, setGitModalVisible] = useState(false);

    return (
        <BaseModal
            visible={props.visible}
            onClose={props.onClose}
        >
            <div className="modal__header">
                <h3 className="modal__title">{t('modal.pop.title')}</h3>
            </div>

            <div className="modal__body">
                {!!t('modal.pop.subtitle') && (
                    <div className="--mb3" dangerouslySetInnerHTML={{__html: t('modal.pop.subtitle')}}/>
                )}
                <div className="popModal__body">
                    {!!t('modal.pop.info') && (
                        <div className="popModal__row">
                            <div className="popModal__thumb"/>
                            <div className="popModal__content">
                                <ul
                                    className="-primary"
                                    dangerouslySetInnerHTML={{__html: t('modal.pop.info')}}
                                />
                            </div>
                        </div>
                    )}
                    {!!t('modal.pop.text1') && (
                        <div className="popModal__row">
                            <div className="popModal__thumb">
                                <img src={getMarketImage('modal_pop1')} alt=""/>
                            </div>
                            <div className="popModal__content">
                                <p
                                    className="par-2"
                                    dangerouslySetInnerHTML={{__html: t('modal.pop.text1')}}
                                />
                            </div>
                        </div>
                    )}
                    {!!t('modal.pop.text2') && (
                        <div className="popModal__row">
                            <div className="popModal__thumb">
                                <img src={require('../../assets/img/pop-rec.png')} alt=""/>
                            </div>
                            <div className="popModal__content">
                                <p
                                    className="par-2"
                                    dangerouslySetInnerHTML={{__html: t('modal.pop.text2')}}
                                />
                            </div>
                        </div>
                    )}
                    {!!t('modal.pop.text3') && (
                        <div className="popModal__row">
                            <div className="popModal__thumb">
                                <img src={require('../../assets/img/pop-conf.png')} alt=""/>
                            </div>
                            <div className="popModal__content">
                                <p
                                    className="par-2"
                                    dangerouslySetInnerHTML={{__html: t('modal.pop.text3')}}
                                />
                            </div>
                        </div>
                    )}
                    {!!t('modal.pop.text4') && (
                        <div className="popModal__row">
                            <div className="popModal__thumb">
                                <img src={require('../../assets/img/pop-doc.png')} alt=""/>
                            </div>
                            <div className="popModal__content">
                                <p
                                    className="par-2"
                                    dangerouslySetInnerHTML={{__html: t('modal.pop.text4')}}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="modal__footer">

                <div className="par-4">
                    <b>{t('need_help')} </b>
                    <a className="link -primary -parentSize" onClick={() => setGitModalVisible(true)}>
                        <b>{t('get_in_touch')}</b>
                    </a>
                </div>
            </div>

            <GetInTouchModal
                visible={gitModalVisible}
                onClose={() => setGitModalVisible(false)}
            />
        </BaseModal>
    );
}
