import React from 'react';
import {Error404, Footer, Header} from "./_index";

export default ({...props}) => {
    return (
        <div className="app">
            <Header cartDisabled/>

            <div className="main">
                <div className="wrapper-bg">
                    <div className="wrapper">
                        {props.children}
                    </div>
                </div>
            </div>

            <Footer simple/>
        </div>
    );
}
