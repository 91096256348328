import {useResellerUsers} from "../hooks";
import {TableComponent} from "../../../../components/baseTable";
import React, {Fragment} from "react";
import {useTranslation} from "../../../../hooks";
import {Link} from "react-router-dom";
import {Routes} from "../../../../utils/_index";
import {useNavigation} from "../../../../misc/Hooks";
import {IoArrowBackOutline} from "react-icons/io5";
import {ResellersListModal} from "../../../../components/modals/reseller";
import {SelectedRequestsActions, SelectedUsersActions} from "../components";
import Error401 from "../../../../components/misc/Error401";

export default function UsersScreen() {

    const {
        currentReseller,
        paramsId,
        resellerChildTable,
        shopsModalVisible,
        modalShops,
        setShopsModalVisible,
        resellerChildFilters,
        resellerRequestFilters,
        resellerRequestTable,
        lackOfAccess,
    } = useResellerUsers()
    const {t} = useTranslation()
    const navigation = useNavigation()

    if (lackOfAccess)
        return <Error401/>

    return (
        <Fragment>
            <div className="wrapper -xxl">
                <div className="resellerUsers">
                    <div className="resHome">
                        <div className="resellerOptions">
                            <div className="resellerOptions__header">
                                {!currentReseller ? <h2 className="heading-2">{t('loading')}</h2> : (
                                    <Fragment>
                                        <h2 className="heading-2 --mb0">
                                            {t('reseller_client.reseller') || t('reseller')}: {`${currentReseller.reseller_firstname} ${currentReseller.reseller_lastname}`}
                                        </h2>
                                        <h3 className="heading-3">{t(`reseller_client.reseller_role.${currentReseller.reseller_role}`)}</h3>
                                        {!!paramsId && (
                                            <Link className="backLink" to={navigation(Routes.V2_RESELLER_USERS)}>
                                                <IoArrowBackOutline/>
                                                <span>{t('back')}</span>
                                            </Link>
                                        )}
                                    </Fragment>
                                )}
                            </div>
                            <TableComponent
                                {...resellerChildTable}
                                title={t('users')}
                                filters={resellerChildFilters}
                                SelectedActionsComponent={SelectedUsersActions}
                            />
                            {!paramsId && (
                                <TableComponent
                                    {...resellerRequestTable}
                                    title={t('reseller_client.new_request')}
                                    filters={resellerRequestFilters}
                                    SelectedActionsComponent={SelectedRequestsActions}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ResellersListModal
                visible={shopsModalVisible}
                onClose={() => setShopsModalVisible(false)}
                shopsList={modalShops}
            />
        </Fragment>
    )
}