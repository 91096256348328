import Provider from "./Provider";
import {Route, Switch} from "react-router-dom";
import {useSteps} from "../../hooks/modules/stay_home";
import React from "react";
import {Routes} from "../../utils/_index";
import StepsFunnel from "./StepsFunnel";
import {getStepsRoutes} from "../../misc/Helpers";
import TokenExpiredScreen from "./screens/TokenExpiredScreen";
import {makeStore, ModuleProvider} from "../../hooks";
import {AppProvider} from "../../misc/_index";
import {OrderFinalizedScreen} from "../../screens";


export default function Funnel() {
    const steps = useSteps()

    const contextValue = makeStore({
        storeName: 'stayHome',
        steps,
    })

    return (
        <AppProvider>
            <ModuleProvider value={contextValue}>
                <Provider>
                    <Switch>
                        <Route
                            exact path={getStepsRoutes(steps)}
                            component={StepsFunnel}
                        />
                        <Route
                            exact
                            path={Routes.STAY_HOME_ORDER_FINALIZED}
                            component={OrderFinalizedScreen}
                        />
                        <Route
                            exact
                            path={Routes.STAY_HOME_TOKEN_EXPIRED}
                            component={TokenExpiredScreen}
                        />
                    </Switch>
                </Provider>
            </ModuleProvider>
        </AppProvider>
    )
}
