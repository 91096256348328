import './style.scss';
import MemberDetailsCard from "../memberDetailsCard";

export default function MembersList({items, selectedItem, onClick}) {
    if(!items || !items.length)
        return null
    return (
        <div className="items">
            {items.map((item, idx) => (
                <MemberDetailsCard
                    key={idx}
                    data={item}
                    isSelected={selectedItem?.memberNumber === item.memberNumber}
                    onClick={onClick}
                />
            ))}
        </div>
    )
}
