import {useSelector} from "react-redux";
import {useCivilityOptions, useLanguage, useModuleContext} from "./index";
import {Config} from "../utils/_index";
import {getDate, isoDateConverter} from "../misc/Helpers";
import {getDefaultCivility} from "../utils/MarketConfig";
import {useState} from "react";
import getObjectValue from "../helpers/getObjectValue";


export default function useOrderData() {
    const {store: module, storeName} = useModuleContext()
    const store = useSelector(state => ({
        main: state.main,
        reseller: state.reseller,
        ...module,
    }))

    const currentLanguage = useLanguage()
    const civilityOptions = useCivilityOptions()
    const [quotationError, setQuotationError] = useState(null)


    const getData = () => {
        let data = {
            source: 'ev',
            id_country: store.main.country.id_country,
            currency_prefix: store.main.country.country_currency_prefix,
            currency_suffix: store.main.country.country_currency_suffix,
            currency_name: store.main.country.country_currency_name,
            test_order: module.testOrder ? 1 : 0,
            uuid: module.uuid,
            filename_receipt: null,
            id_shop: module?.proofOfPurchase?.shop?.value ?? module?.finishOrder?.id_shop ?? store?.reseller?.shop ?? module?.promoCode?.id_shop ?? 0,
            id_shop_group: module?.proofOfPurchase?.shopGroup?.value ?? module?.finishOrder?.id_shop_group ?? module?.promoCode?.id_shop_group ?? 0,
            abandon_communication_consent: module.abandonedOrderConsentAccepted ? 1 : 0,
            id_reopened_order: storeName === 'shopReopen' ? module.data.id_order : 0,
            order_serial_number_from_qr_code: !!module.ocrLogs?.find((ol) => ol.type === 'custom_log' && ol.name === 'qr_scanner_was_used') ? 1 : 0,
            was_web_account_found: module?.webAccountExists ? 1 : 0,
            ...getPromotionData(),
            ...getPromoCodeData(),
            ...getMachineData(),
            ...getSerialNumberData(),
            ...getProofOfPurchaseData(),
            ...getCustomerData(),
            ...getCustomerBenefitsData(),
            ...getNespressoMemberData(),
            ...getOcrLogsData(),
            ...getCanRegisterResponseData(),
            ...getUserData(),
            ...getPaymentMethodData(),
            ...getPaymentMethodCodeVerifyData(),
            ...getPaymentMethodIbanProofData(),
            ...getDeliveryMethodData(),
        }

        if (store.main.user) {
            if (module.nespressoMember === null) {
                // data.webaccount_exist = 1;
            }

            if (module.nespressoMemberExist) {
                // data.webaccount_exist = 1;
            }

            if (!data.id_shop) {
                data.id_shop = store.reseller.shop ?? null;
            }
        }

        if (storeName === 'subscription' && module.shopData) {
            data.id_shop = module.shopData?.shop?.value
            data.id_shop_group = module.shopData?.shopGroup?.value
        }

        return data
    }

    const getCustomer = (customer) => {
        return {
            source: 'ev',
            id_country: store.main.country.id_country,
            currency_prefix: store.main.country.country_currency_prefix,
            currency_suffix: store.main.country.country_currency_suffix,
            currency_name: store.main.country.country_currency_name,
            test_order: module.testOrder ? 1 : 0,
            uuid: module.uuid,
            filename_receipt: null,
            serial: store.serialNumber?.serial_number ?? null,
            id_shop: module?.proofOfPurchase?.shop?.value ?? module?.finishOrder?.id_shop ?? store?.reseller?.shop ?? module?.promoCode?.id_shop ?? 0,
            id_shop_group: module?.proofOfPurchase?.shopGroup?.value ?? module?.finishOrder?.id_shop_group ?? module?.promoCode?.id_shop_group ?? 0,
            id_machine: module?.machine?.id_machine ?? module?.finishOrder?.id_machine ?? null,
            id_promotion: module?.promotion?.id_promotion ?? module?.finishOrder?.id_promotion ?? null,
            invoice_date: module?.proofOfPurchase?.date ? isoDateConverter(module?.proofOfPurchase?.date, '-') : module?.finishOrder?.invoice_date ? isoDateConverter(module?.finishOrder?.invoice_date, '-') : isoDateConverter(Date.now(), '-'),
            invoice_price: module?.proofOfPurchase?.machinePrice ?? module?.finishOrder?.invoice_price ?? null,
            was_web_account_found: module?.webAccountExists ? 1 : 0,

            ...getCustomerData(customer),
            ...getCustomerBenefitsData(customer),
            ...getNespressoMemberData(),
            ...getCanRegisterResponseData(),
        }
    }

    const getPromotionData = (promotion = module.promotion) => {
        if (!promotion) return null
        const data = {
            id_promotion: promotion.id_promotion,
            promotion_type: promotion.promotion_type,
        }
        if (promotion.promotion_type === Config.PROMO_TYPE.EASY_ORDER && module.frequencies && module.easyOrderSetup) {
            data.easy_order_frequency = module.frequencies.find(i => i.id === module.easyOrderSetup.frequency?.value)
            data.easy_order_next_shipment = getDate(module.easyOrderSetup.nextShipment)
        }
        if (!!module.isFastOrder) {
            data.promotion_type = 'fast_order'
        }
        return data
    }

    const getPromoCodeData = (promoCode = module.promoCode) => {
        if (!promoCode) return null
        return {
            id_promo_code: promoCode.id_promo_code,
        }
    }

    const getMachineData = (machine = module.machine) => {
        if (!machine) return null
        return {
            id_machine: machine.id_machine,
            coffee_type: machine.machine_coffee_type,
            machine_nestle_id: module.machineResponse?.machineId,
        }
    }

    const getSerialNumberData = (serialNumber = module.serialNumber) => {
        if (!serialNumber) return null
        return {
            serial: serialNumber.serial_number ?? null,
            filename_machine: serialNumber.filename ?? null,
        }
    }

    const getProofOfPurchaseData = (proofOfPurchase = module.proofOfPurchase) => {
        if (!proofOfPurchase) return null
        let data = {
            id_shop: proofOfPurchase.shop?.value ?? 0,
            id_shop_group: proofOfPurchase.shopGroup?.value ?? 0,
            invoice_date: getDate(proofOfPurchase.date),
            invoice_number: proofOfPurchase.invoiceNumber,
            invoice_price: proofOfPurchase.machinePrice ?? null,
        }
        if (module.proofOfPurchaseLogs) {
            data = {
                ...data,
                ocr_number: module.proofOfPurchaseLogs.data?.invoice,
                ocr_date: module.proofOfPurchaseLogs.data?.date,
                ocr_price: module.proofOfPurchaseLogs.data?.total,
                ocr_shop: module.proofOfPurchaseLogs.data?.id_shop,
                ocr_shop_group: module.proofOfPurchaseLogs.data?.id_shop_group,
                filename_receipt: module.proofOfPurchaseLogs.filename,
            }
        }
        return data
    }

    const getCustomerData = (customer = module.customer) => {
        if (!customer) return null

        function getData(data) {
            return {
                memberAddress: data.memberAddress?.value ?? module.orderAccount?.order?.address_id ?? null,
                isCompanyAddress: data.isCompanyAddress ? 1 : 0,
                contact: data.contact,
                companyName: data.companyName ?? '',
                taxCategory: data.isCompanyAddress ? data.companyTaxCategory?.value : data.taxCategory?.value,
                taxId: (data.isCompanyAddress ? data.companyTaxId : data.taxId) ?? '',
                irs: data.irs ?? '',
                title: typeof data.title === 'object' ? Number(data.title.value) : data.title ?? getDefaultCivility(),
                firstname: data.firstname ?? '',
                lastname: data.lastname ?? '',
                phoneticName: data.phoneticName ?? '',
                postcode: getObjectValue(data.postcode, 'label') ?? '',
                city: getObjectValue(data.city, 'label') ?? '',
                addressLine1: data.addressLine1 ?? '',
                addressLine2: data.addressLine2 ?? '',
                profession: data.isCompanyAddress ? getObjectValue(data.companyProfession) : getObjectValue(data.profession),
                taxOffice: !!data.companyTaxOffice ? getObjectValue(data.companyTaxOffice) : null,
                region: getObjectValue(data.region),
                country: data.country?.value || store.main.country.country_shortcode,
                phone: data.phone ?? '',
                phoneMobile: data.phoneMobile ?? '',
                phoneHome: data.phoneHome ?? '',
                email: data.email ?? module.nespressoMember?.email ?? '',
            }
        }

        function getDelivery(data) {
            if (!data) return null
            const res = getData(data)
            return {
                address_id: res.memberAddress ? Number(res.memberAddress) : null,
                delivery_is_company: res.isCompanyAddress,
                delivery_contact: res.contact,
                delivery_company_name: res.companyName,
                tax_category: res.taxCategory,
                delivery_company_tax_id: res.taxId,
                delivery_company_irs: res.irs,
                delivery_company_profession: res.profession,
                delivery_company_tax_office: res.taxOffice,
                delivery_civility: res.title,
                delivery_firstname: res.firstname,
                delivery_lastname: res.lastname,
                delivery_phonetic_name: res.phoneticName,
                delivery_zipcode: res.postcode,
                delivery_city: res.city,
                delivery_address1: res.addressLine1,
                delivery_address2: res.addressLine2,
                delivery_region: res.region,
                delivery_country: res.country,
                delivery_phone: res.phone,
                delivery_phone_mobile: res.phoneMobile,
                delivery_phone_home: res.phoneHome,
                delivery_email: res.email,
            }
        }

        function getOtherDelivery(data) {
            if (!data) return null
            const res = getData(data)
            return {
                other_address_id: res.memberAddress ? Number(res.memberAddress) : null,
                other_delivery_is_company: res.isCompanyAddress,
                other_delivery_contact: data.contact,
                other_delivery_company_name: data.companyName,
                other_delivery_tax_category: res.taxCategory,
                other_delivery_company_tax_id: res.taxId,
                other_delivery_company_irs: res.irs,
                other_delivery_company_profession: res.profession,
                other_delivery_civility: res.title,
                other_delivery_firstname: res.firstname,
                other_delivery_lastname: res.lastname,
                other_delivery_phonetic_name: res.phoneticName,
                other_delivery_zipcode: res.postcode,
                other_delivery_city: res.city,
                other_delivery_address1: res.addressLine1,
                other_delivery_address2: res.addressLine2,
                other_delivery_region: res.region,
                other_delivery_country: res.country,
                other_delivery_phone: res.phone,
                other_delivery_phone_mobile: res.phoneMobile,
                other_delivery_phone_home: res.phoneHome,
                other_delivery_email: res.email,
            }
        }

        const delivery = getDelivery(customer.details ? customer.details : customer.customAddress)
        const otherDelivery = customer.details && getOtherDelivery(customer.customAddress)

        return {
            lang: customer.lang?.value ?? currentLanguage,
            ...delivery,
            ...otherDelivery,

            other_delivery: customer.details && customer.withCustomAddress ? 1 : 0,

            payment_bsb_number: customer.paymentDetails?.bsbNumber ?? null,
            payment_account_number: customer.paymentDetails?.accountNumber ?? null,
            payment_data_confirmed: customer.paymentDetails?.isAccountNumberConfirmed ? 1 : 0,

            // id_subscription_bank: customer.paymentMethodCodeVerify?.bankId,
            // bank_account_number: customer.paymentMethodCodeVerify?.accountNumber,

            regulations: customer.terms?.termsAndConditions ? 1 : 0,
            privacy_policy: customer.terms?.privacyPolicy ? 1 : 0,
            privacy_policy_use_of_personal_data_for_marketing: customer.terms?.privacyPolicyUseOfPersonalDataForMarketing ? 1 : 0,
            privacy_policy_personalized_management: customer.terms?.privacyPolicyPersonalizedManagement ? 1 : 0,
            privacy_policy_use_satisfaction_research: customer.terms?.privacyPolicyUseSatisfactionResearch ? 1 : 0,

            member_password: customer.details?.password,

            webaccount_exist: module.isNewMember ? 0 : 1,
        }
    }

    const getNespressoMemberData = (nespressoMember = module.nespressoMember) => {
        if (!nespressoMember) return null
        return {
            member_number: nespressoMember.memberNumber,
            member_token: nespressoMember.token,
        }
    }

    const getCustomerBenefitsData = (customer = module.customer) => {
        if (!customer) return null

        function getValue(oldValue, formValue) {
            if (formValue !== undefined && formValue !== null)
                return formValue ? 1 : 0
            if (oldValue)
                return oldValue ? 1 : 0
            return null
        }

        return {
            marketing_email: getValue(module.nespressoMember?.consents?.email, customer.benefits?.email),
            marketing_mobile: getValue(module.nespressoMember?.consents?.mobile, customer.benefits?.mobile),
            marketing_phone: getValue(module.nespressoMember?.consents?.phone, customer.benefits?.phone),
            marketing_post: getValue(module.nespressoMember?.consents?.post, customer.benefits?.post),
            marketing_social_media: getValue(module.nespressoMember?.consents?.socialMedia, customer.benefits?.socialMedia),
        }
    }

    const getOcrLogsData = (ocrLogs = module.ocrLogs) => {
        if (!ocrLogs) return null
        return {
            ocr_log: JSON.stringify(ocrLogs.filter(ol => ol.type !== 'custom_log')),
        }
    }

    const getCanRegisterResponseData = (canRegisterResponse = module.canRegisterResponse) => {
        if (!canRegisterResponse) return null
        const data = {
            can_register_status: canRegisterResponse,
        }
        if (canRegisterResponse.status === 'MEMBER_EXISTS') {
            // data.member_number = canRegisterResponse.memberNumber;
        }
        if (store.main.user && canRegisterResponse.status === 'ACCOUNT_EXISTS' && canRegisterResponse.memberNumber) {
            // data.member_number = canRegisterResponse.memberNumber;
            // data.webaccount_exist = 1;
        }
        return data
    }

    const getUserData = (user = store.main.user) => {
        if (!user) return null
        return {
            reseller_order: 1,
            id_reseller: user.id_reseller,
            reseller_firstname: store.reseller.firstname ?? null,
            reseller_lastname: store.reseller.lastname ?? null,
        }
    }

    const getPaymentMethodData = (paymentMethod = module.paymentMethod) => {
        if (!paymentMethod) return null
        return {
            id_payment_method: paymentMethod.id_payment_method ?? null,
            payment_method_id_nestle: paymentMethod.id_nestle ?? '',
        }
    }

    const getDeliveryMethodData = (deliveryMethod = module.deliveryMethod, pickupPoint = null) => {
        if (!deliveryMethod) return null
        const res = {
            id_delivery_method: deliveryMethod.id_delivery_method ?? null,
        }

        if (pickupPoint) {
            res.id_point_of_interest = pickupPoint.value
            res.point_of_interest_type = pickupPoint.data?.type
        }

        return res
    }

    const getCartQuotationData = (cartQuotationData = module.cartQuotationData) => {
        if (quotationError)
            return {quotation_total_amount: -2}
        if (!cartQuotationData) return null
        return {
            quotation_finance: cartQuotationData.finance,
            quotation_total_amount: cartQuotationData.finance?.amount?.toPay,
            quotation_delivery_method: cartQuotationData.delivery?.deliveryMode?.id,
        }
    }

    const getDeliveryMethodsData = (deliveryMethods) => {
        return deliveryMethods?.map(i => i.id_delivery_method) ?? []
    }

    const memberCreditCart = (cart = module.cart, products = module.products) => {
        if (!cart || !products) return []
        return cart.items.reduce((arr, item) => {
            const product = products.find(p => p.id_product === item.id);
            if (product) {
                arr.push({
                    item: product.product_code,
                    quantity: item.value * product.product_package_qty,
                })
            }
            return arr
        }, [])
    }

    const mapAddressToCustomer = (addressId) => {
        const address = module.addresses?.find(a => a.addressId === addressId)
        if (!address) return null
        const {id, email, phone, ...rest} = address
        const data = {
            ...rest,
            addressId: id,
            title: civilityOptions.find(address.title),
            isCompanyAddress: !!address.is_company,
        }

        if (rest.is_company) {
            data.name = ''
            data.companyName = rest.name
        }
        return data
    }

    const getPaymentMethodCodeVerifyData = (paymentMethodCodeVerify = module.customer?.paymentMethodCodeVerify) => {
        if (!paymentMethodCodeVerify) return null
        return {
            id_bank: paymentMethodCodeVerify?.idBank,
            bank_id: paymentMethodCodeVerify?.bankId,
            bank_branch: paymentMethodCodeVerify?.bankBranch,
            bank_name: paymentMethodCodeVerify?.bankName,
            bank_account_number: paymentMethodCodeVerify?.accountNumber,
        }
    }

    const getPaymentMethodIbanProofData = (paymentMethodIbanProof = module.customer?.paymentMethodIbanProof) => {
        if (!paymentMethodIbanProof) return null
        return {
            filename_iban: paymentMethodIbanProof?.uploadedFile?.filename,
        }
    }

    const getCart = (cart = store.cart) => {
        const items = cart?.items;
        return {
            items: items,
            sum: JSON.stringify(items)
        }
    }

    const getCartWithoutExtraLine = (cart = store.cart, extraLineProducts = store.extraLineProducts) => {
        const items = cart?.items.filter(i => !extraLineProducts.find(elp => elp.id_product === i.id));
        return {
            items: items,
            sum: JSON.stringify(items)
        }
    }

    return {
        getData,
        getCustomer,
        getCartQuotationData,
        setQuotationError,
        memberCreditCart,
        mapAddressToCustomer,
        getPaymentMethodData,
        getPaymentMethodCodeVerifyData,
        getCart,
        getPaymentMethodIbanProofData,
        getCustomerBenefitsData,
        getCartWithoutExtraLine,
        getSerialNumberData,
        getDeliveryMethodsData,
        getDeliveryMethodData,
    }
}
