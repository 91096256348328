import {InvalidFeedback, RadioLabel} from "../components";
import {useInputValidationClassNames} from "../hooks";

/**
 * Radio
 * @param inputRef
 * @param fieldState
 * @param name
 * @param value
 * @param option
 * @param renderOption
 * @param {{inputProps, isRequired: boolean, maxLength: number}} options
 * @param onChange
 * @param onBlur
 * @returns {JSX.Element}
 * @constructor
 */
export default function Radio({
    inputRef,
    fieldState,
    name,
    value,
    option,
    renderOption,
    options = {
        inputProps: null,
        isRequired: false,
        maxLength: 0,
        // green: false,
    },
    onChange,
    onBlur,
}) {
    const {className} = useInputValidationClassNames({
        className: 'checkbox',
        value,
        fieldState,
    })

    return (
        <fieldset className={className}>
            <label htmlFor={`${name}.${option.value}`} className="checkbox__row">
                <input
                    ref={inputRef}
                    id={`${name}.${option.value}`}
                    className="checkbox__input"
                    type="radio"
                    name={name}
                    value={option.value}
                    checked={value?.toString() === option.value?.toString()}
                    onChange={onChange}
                    onBlur={onBlur}
                    {...options.inputProps}
                />
                <span className="checkbox__checkmark"/>
                <RadioLabel
                    label={typeof renderOption === 'function' ? renderOption(option) : option.label}
                    options={options}
                />
            </label>
        </fieldset>
    )
}
