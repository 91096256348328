import {SelectController} from "../controllers";
import React from "react";
import {useCivilityOptions, useTranslation} from "../../hooks";

export default function TitleSelect(props) {
    const {t} = useTranslation()
    const civilityOptions = useCivilityOptions()

    return (
        <SelectController
            name="title"
            label={t('title')}
            selectOptions={civilityOptions.options}
            {...props}
        />
    )
}
