import {CheckboxLabel, InvalidFeedback} from "../components";
import {useInputValidationClassNames} from "../hooks";
import {AiOutlineCheck, AiOutlineClose} from "react-icons/ai";
import React, {useMemo} from "react";
import {makeClassName} from "../../helpers";

/**
 * Switch
 * @param type
 * @param inputRef
 * @param fieldState
 * @param type
 * @param name
 * @param label
 * @param value
 * @param {{inputProps, isRequired: boolean}} options
 * @param onChange
 * @param onBlur
 * @returns {JSX.Element}
 * @constructor
 */
export default function Switch({
    inputRef,
    fieldState = {},
    name,
    label,
    value,
    options = {
        inputProps: null,
        isRequired: false,
        // green: false,
    },
    onChange,
    onBlur,
}) {
    const {className} = useInputValidationClassNames({
        className: 'checkbox -switch',
        value,
        fieldState,
    })
    const switchClassNames = useMemo(() => makeClassName('formSwitch__status', {
        '-notSelected': typeof value !== 'boolean',
        '-true': value === true,
        '-false': value === false,
    }), [value])

    const onClickFalse = (e) => {
        e.preventDefault()
        onChange(false)
    }

    const onClickTrue = (e) => {
        e.preventDefault()
        onChange(true)
    }

    return (
        <fieldset className={className}>
            <label htmlFor={name} className="checkbox__row">
                <input
                    ref={inputRef}
                    id={name}
                    className="checkbox__input"
                    type="checkbox"
                    name={name}
                    value={value}
                    checked={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    {...options.inputProps}
                />
                <div
                    className="formSwitch__input"
                    data-checked={value}
                >
                    <div className={switchClassNames}>
                        <div className="formSwitch__check"><AiOutlineCheck/></div>
                        <div className="formSwitch__risti"><AiOutlineClose/></div>
                    </div>
                    {typeof value !== 'boolean' && (
                        <>
                            <div className="formSwitch__inputBox -false" onClick={onClickFalse}/>
                            <div className="formSwitch__inputBox -true" onClick={onClickTrue}/>
                        </>
                    )}
                </div>
                {/*<span className="checkbox__checkmark"/>*/}
                <CheckboxLabel
                    label={label}
                    options={options}
                />
            </label>
            <InvalidFeedback error={fieldState.error}/>
        </fieldset>
    )
}
