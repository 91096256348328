import {useDateFormatter, useModuleContext, useTranslation} from "../../../hooks";
import {useMutation} from "@tanstack/react-query";
import * as categoryActions from '../../../actions/CategoryActions'
import {ApiException} from "../../../errors";
import {useState} from "react";
import * as machineActions from "../../../actions/MachineActions";
import * as countryActions from "../../../actions/CountryActions";
import * as mainActions from "../../../actions/MainActions";
import {isoDateConverter, uuidv4} from "../../../misc/Helpers";
import Env from "../../../utils/Env";
import * as orderActions from '../../../actions/OrderActions';

export default function useTps() {

    const {reducer, dispatch, store} = useModuleContext()
    const [cartError, setCartError] = useState('')
    const [saveMessage, setSaveMessage] = useState({
        message: '',
        type: ''
    })
    const {t} = useTranslation()


    const machinesMutation = useMutation(async () => {
            const {data} = await machineActions.getAll()
            if (data.status === 'success')
                return data.data
            throw new ApiException(data.messages[0], data)
        },
        {
            onSuccess: data => {
                dispatch(reducer.setMachines(data))
            }
        })

    const tpsCategoriesMutation = useMutation(async (promotionId) => {
        const promotion = store.promotions.find(p => Number(p.id_promotion) === Number(promotionId))
        if (!promotion)
            return;

        const {data} = await categoryActions.getTpsCategories(promotionId)

        if (data.status === 'success')
            return data.data
        throw new ApiException(data.messages[0], data)
    }, {
        onSuccess: (data) => {
            if (!data) {
                dispatch(reducer.setCategories([]))
                return;
            }

            dispatch(reducer.setCategories(data))
        },
        onError: () => {
            dispatch(reducer.setCategories([]))
        }
    })

    const countryMutation = useMutation(async () => {
        const {data} = await countryActions.getAll()

        if (data.status === 'success')
            return data.data

        throw new ApiException(data.messages[0], data)
    }, {
        onSuccess: (data) => {
            if (data.length)
                dispatch(reducer.setCountry(data[0]))
        }
    })


    const stacksMutation = useMutation(async () => {
            const machineModels = await mainActions.getStackByType('machine_model')
            const machineColors = await mainActions.getStackByType('machine_color')
            const machinePartners = await mainActions.getStackByType('machine_partner')
            const tpsCompanyTitles = await mainActions.getStackByType('tps_company_title')

            if (machineModels.data.status === 'success'
                && machineColors.data.status === 'success'
                && machinePartners.data.status === 'success'
                && tpsCompanyTitles.data.status === 'success')
                return {
                    machineModels: machineModels.data.data,
                    machineColors: machineColors.data.data,
                    machinePartners: machinePartners.data.data,
                    tpsCompanyTitles: tpsCompanyTitles.data.data,
                }

            throw new ApiException()
        },
        {
            onSuccess: ({machineModels, machineColors, machinePartners, tpsCompanyTitles}) => {
                dispatch(reducer.setStacks(machineModels, machineColors, machinePartners, tpsCompanyTitles))
            }
        })

    const handleSubmit = (data) => {
        setCartError('')
        if(!data.cart.length){
            setCartError('error.tps_basket_cannot_be_empty')
            return
        }

        const paymentMethod = store.promotion.payment_methods.find(pm => pm.id_payment_method === Number(data.paymentMethod))
        const machineFromSelect = store.machines.find(m => m.id_machine === Number(data.machine?.value))

        console.log(data.serialNumber)

        const payload = {
            customer: {
                source: 'ev',
                test_order: !!data.serialNumber && store.machineData?.test_order ? 1 : 0,
                id_promotion: data.promotion?.value,
                id_machine: !!data.notNespressoMachine ? undefined : !!data.serialNumber ? store.machineData.machine.id_machine : machineFromSelect.id_machine,
                id_country: data.country.value,
                id_shop: store.shop?.id_shop,
                promotion_type: store.promotion?.promotion_type,
                coffee_type: !!data.notNespressoMachine ? undefined : !!data.serialNumber ? store.machineData.machine.machine_coffee_type : machineFromSelect.machine_coffee_type,
                serial: !!data.notNespressoMachine ? '' : data.serialNumber,
                uuid: uuidv4(),
                lang: data.lang?.value ?? Env.DEFAULT_LANG,
                filename_machine: '',
                filename_receipt: store.receiptData?.filename ?? '',
                currency_prefix: store.country.country_currency_prefix,
                currency_suffix: store.country.country_currency_suffix,
                currency_name: store.country.country_currency_name,
                voucher_number: data.voucherNumber ?? '',
                invoice_date: data.purchaseDate ? isoDateConverter(data.purchaseDate, '-') : null,
                invoice_price: data.machinePrice ?? '',
                ocr_number: store.receiptData?.data.invoice ?? '',
                ocr_date: store.receiptData?.data.date ?? '',
                ocr_price: store.receiptData?.data.total ?? '',
                ocr_shop: store.receiptData?.data.id_shop ?? null,
                ocr_shop_group: store.receiptData?.data.id_shop_group ?? null,
                delivery_is_company: data.companyAddress ? 1 : 0,
                delivery_company_name: data.companyName ?? '',
                delivery_civility: data.title ? Number(data.title.value) : 0,
                delivery_firstname: data.name ?? '',
                delivery_lastname: data.surname ?? '',
                delivery_zipcode: data.postcode ?? '',
                delivery_city: data.city ?? '',
                delivery_address1: data.address ?? '',
                delivery_address2: data.address2 ?? '',
                delivery_phone: data.phone ?? '',
                delivery_phone_mobile: data.phoneMobile ?? '',
                delivery_phone_home: data.phoneHome ?? '',
                delivery_email: data.email ?? '',
                other_delivery: data.withCustomAddress ? 1 : 0,
                other_delivery_is_company: data.customCompanyAddress ? 1 : 0,
                other_delivery_company_name: data.customCompanyName ?? '',
                other_delivery_civility: data.customTitle ? Number(data.customTitle.value) : 0,
                other_delivery_firstname: data.customName ?? '',
                other_delivery_lastname: data.customSurname ?? '',
                other_delivery_zipcode: data.customPostcode ?? '',
                other_delivery_city: data.customCity ?? '',
                other_delivery_address1: data.customAddress ?? '',
                other_delivery_address2: data.customAddress2 ?? '',
                other_delivery_phone: data.customPhone ?? '',
                other_delivery_phone_mobile: data.customPhoneMobile ?? '',
                other_delivery_phone_home: data.customPhoneHome ?? '',
                id_payment_method : paymentMethod?.id_payment_method ?? null,
                payment_method_id_nestle : paymentMethod?.id_nestle ?? '',
                member_number: data.customerNumber ?? '',
            },
            cart: {items: data.cart}
        }

        saveOrderMutation.mutate(payload)
    }

    const saveOrderMutation = useMutation(async (payload) => {
        const {data} = await orderActions.finalizeTps(payload)
        if (data.status === 'success')
            return data.data

        throw new ApiException(data.messages[0], data)
    },
        {
            onMutate: () => setSaveMessage({message: '', type: ''}),
            onSuccess: () => setSaveMessage({message: t('tps.order_saved'), type: 'success'}),
            onError: ({error}) => setSaveMessage({message: t('error.tps_save_error', error), type: 'error'})
        })


    return {
        store,
        tpsCategoriesMutation,
        countryMutation,
        stacksMutation,
        machinesMutation,
        handleSubmit,
        saveMessage,
        isLoading: saveOrderMutation.isLoading,
        cartError: {
            setCartError,
            cartError
        }
    }
}