import {useFetchOrder, useGTM, useReseller, useTranslation} from "../../../hooks";
import {useState} from "react";
import * as OrderService from "../../../services/OrderService";
import {useHistory} from "react-router-dom";
import {useNavigation} from "../../../misc/Hooks";

export default function useSavedOrder({
    homeRoute,
    orderFinalizedRoute,
    orderUuid,
    setLinkSentModal,
    setIsAssistedExistingSimplifiedFlow,
}) {
    const {t} = useTranslation()
    const {isLoggedIn} = useReseller()
    const {fetchOrder} = useFetchOrder()
    const history = useHistory()
    const navigation = useNavigation()
    const [data, setData] = useState(null)
    const [isLoading, setLoading] = useState(!!orderUuid)
    const [error, setError] = useState(null)
    const {errorTracking} = useGTM()

    function getOrder(uuid) {
        setLoading(true)

        fetchOrder(uuid)
            .then(async data => {
                if (!data.order) {
                    history.push(navigation(homeRoute))
                    return
                }

                let order = {...data.order}

                setData({...data})

                if (!order.quotation_response && (!isLoggedIn || order.is_simplified_flow)) {
                    const quotationData = await OrderService.makeQuotation(order.id_order)
                        .catch(err => {
                            errorTracking('Delivery And Payment', 'order_save_error')
                            setError(t('error.order_save_error'))
                        })

                    order = quotationData?.order

                    if (order) {
                        // setSavedOrder({...data, order})
                        await getOrder(uuid)
                        return
                    } else {
                        errorTracking('Delivery And Payment', 'order_save_error')
                        setError(t('error.order_save_error'))
                        setLoading(false)
                    }
                }

                if (order) {
                    setData({...data})
                    setLoading(false)
                    if (isLoggedIn) {
                        setLinkSentModal(true)
                        setIsAssistedExistingSimplifiedFlow(!!order.is_simplified_flow)
                    } else {
                        switch (order.quotation_response) {
                            case 'TYP':
                                history.push(navigation(orderFinalizedRoute, order.uuid))
                                break
                            case 'HOP':
                                window.location.href = order.hop_link
                                break
                            case 'PAYMENT':
                                if (order.is_paid) {
                                    history.push(navigation(orderFinalizedRoute, order.uuid))
                                }
                        }
                    }
                }
            })
    }

    return {
        data,
        isLoading,
        error,
        getOrder,
    }
}
