import './style.scss'
import sanitizeText from "../../helpers/sanitizeText";
import {
    IoMdCheckmarkCircleOutline,
    IoMdInformationCircleOutline,
} from "react-icons/io";
import {
    MdErrorOutline,
    MdWarningAmber
} from "react-icons/md";
import {useMemo} from "react";
import {makeClassName} from "../../helpers";

/**
 * InfoComponent
 * @param {string} message
 * @param {('success'|'info'|'warn'|'error')} type
 * @param {boolean} liteShadow
 * @param customClassName
 * @param props
 * @return JSX
 */
export default function InfoComponent({
    message = '',
    type = 'success',
    liteShadow = false,
    customClassName = '',
    ...props
}){
    const className = useMemo(() => makeClassName(`infoComponent -${type}`, {
        '-liteShadow':  liteShadow,
    }), [type, liteShadow])

    if(!message) return null
    if(typeof message !== "string") return null

    return (
        <div className={`${className} ${customClassName}`}>
            <div className="infoComponent__icon">{icons[type] ?? icons['success']}</div>
            <div className="infoComponent__messageWrap">
                <span className="infoComponent__message" {...sanitizeText(message)}/>
            </div>
        </div>
    )
}

const icons = {
    success: <IoMdCheckmarkCircleOutline/>,
    error: <MdErrorOutline/>,
    info: <IoMdInformationCircleOutline/>,
    warn: <MdWarningAmber/>
}
