import {useState} from "react";
import * as mainActions from '../../../actions/MainActions';

export default function useBank() {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const getBank = async (bsbNumber) => {
        setLoading(true);
        setError(null)
        let data = null
        await mainActions.getBankByBsbNumber(bsbNumber)
            .then(r => {
                const res = r.data;
                if(res.status === 'success') {
                    data = res.data
                } else {
                    setError(res.messages[0])
                }
            })
            .catch(err => {
                console.error(err)
                setError('unknown_error')
            })
        setData(data)
        setLoading(false);
        return data
    }

    return {
        data,
        loading,
        error,
        getBank,
    }
}
