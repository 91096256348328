
import {useFavoriteProducts} from "../hooks";
import {CoffeeItem} from "./index";
import React from "react";
import sanitizeText from "../../../helpers/sanitizeText";

export default function FavoriteProducts({cart}){
    const {products, category, storeName} = useFavoriteProducts()

    if(!products.length) return null

    return (
        <div className="favoriteProducts">
            <div className="favoriteProducts__header">
                <span className="favoriteProducts__title" {...sanitizeText(category.category_title)}/>
                {!!category.category_description && (
                    <div className="favoriteProducts__description par-2" {...sanitizeText(category.category_description)}/>
                )}
            </div>
            <div className="evCS__products">
                {products.map(p => (
                    <CoffeeItem
                        key={p.product.id_product}
                        item={p.product}
                        cart={cart}
                        category={p.category}
                        // onChange={props.onChange}
                        storeName={storeName}
                    />
                ))}
            </div>
        </div>
    )
}