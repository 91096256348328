import React, {useContext, useEffect, useMemo, useState} from "react";
import {useFormHelper, useModuleContext, useTranslation} from "../../../hooks";
import {useCountryConfig, usePriceFormatter} from "../../../misc/Hooks";
import {useWatch} from "react-hook-form";
import {Form} from "../../../forms";
import {FormGroup} from "../../../forms/components";
import {SelectController, TextInputController} from "../../../forms/controllers";
import {Button} from "../../../components/buttons/_index";
import {InfoComponent} from "../../../components/_index";

export default function CartFormFragment({cartError, hiddeButtons, state}) {

    const {t} = useTranslation()
    const {store} = useModuleContext()
    const countryConfig = useCountryConfig()
    const {formMethods} = useFormHelper()

    const {formatPriceObj, formatPrice} = usePriceFormatter();

    const [selectedProduct, setSelectedProduct] = useState(null)
    const [price, setPrice] = useState(0)

    const category = useWatch({control: formMethods.control, name: 'category'})
    const product = useWatch({control: formMethods.control, name: 'product'})
    const quantity = useWatch({control: formMethods.control, name: 'quantity'})
    const cart = useWatch({control: formMethods.control, name: 'cart'})

    const handleSubmit = () => {
        const arr = [...cart];
        const idx = arr.findIndex(i => i.id === product.value);
        const obj = {
            id: product.value,
            value: Number(quantity) / selectedProduct?.product_package_qty,
        };

        if (idx !== -1) arr[idx] = obj;
        else arr.push(obj)

        formMethods.setValue('cart', arr)
    }

    const categoriesOptions = useMemo(() => {
        if (!store.categories?.length) return []

        const arr = [];
        store.categories.map((c) => {
            if (c.categories?.length) {
                const options = [];
                c.categories.map(sc => {
                    if (sc?.products?.length) {
                        options.push({
                            value: sc.id_category,
                            label: sc.category_title,
                        })
                    }
                })
                if (options.length) {
                    arr.push({
                        label: c.category_title,
                        options,
                    })
                }
            } else {
                if (c?.products?.length) {
                    arr.push({
                        value: c.id_category,
                        label: c.category_title,
                    })
                }
            }
        })

        return arr;

    }, [store.categories])

    const productsOptions = useMemo(() => {
        if (!category) return []
        const arr = []
        const categories = store.categories
        let currentCategory = categories?.find(c => c.id_category === category.value)
        if (!currentCategory) {
            categories.find(c => c.categories?.find(sc => {
                if (sc.id_category === category.value) {
                    currentCategory = sc;
                    return true
                } else {
                    return false
                }
            }))
        }

        currentCategory?.products?.map(p => {
            arr.push({
                value: p.id_product,
                label: p.product_title,
            })
        })

        return arr;
    }, [category])

    const onChangeProduct = (v) => {
        setSelectedProduct(getProduct(v.value));
        formMethods.setValue('quantity', '')
        formMethods.setValue('product', v)
    }

    const onChangeCategory = (v) => {
        setSelectedProduct(null)
        formMethods.setValue('product', null)
        formMethods.setValue('quantity', '')
        formMethods.setValue('category', v)
    }

    const removeProduct = (id) => {
        const arr = [...cart];
        const idx = arr.findIndex(i => i.id === id);

        if (idx !== -1) {
            arr.splice(idx, 1);

            setSelectedProduct(null);
            formMethods.setValue('category', null);
            formMethods.setValue('product', null);
            formMethods.setValue('quantity', '');

            formMethods.setValue('cart', arr)
        }
    }

    function getProduct(id) {
        let product = null
        store.categories?.find(c => {
            const found = c.categories?.find(sc => sc.products?.find(p => {
                if (p.id_product === id) {
                    product = p
                    return true
                }
                return false
            }))
            if (found)
                return true

            product = c.products?.find(p => p.id_product === id)
            return product
        })
        return product
    }

    function getProductStep(p) {
        if (!p) return 10
        return p.product_step * p.product_package_qty
    }

    useEffect(() => {
        let totalPrice = 0;

        cart.map(i => {
            const product = getProduct(i.id);
            const qty = getProductStep(product);

            if (countryConfig?.price_type_display === 'net') {
                totalPrice += qty * i.value * product.product_price?.net;
            } else {
                totalPrice += qty * i.value * product.product_price?.gross
            }
        })

        setPrice(totalPrice)
    }, [cart])

    console.log(category)

    return (
        <div className="tpsForm">
            <div className="tpsForm__cart">
                <h2 className="par-1 --bold">{t('tps.cart')}</h2>
                <div className="tpsCart">
                    <div className="tpsCart__header grid --mb4">
                        <FormGroup>
                            <SelectController
                                name="category"
                                label={t('tps.category')}
                                selectOptions={categoriesOptions}
                                options={{
                                    inputProps: {
                                        onChange: onChangeCategory
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <SelectController
                                name="product"
                                label={t('tps.product')}
                                selectOptions={productsOptions}
                                options={{
                                    inputProps: {
                                        onChange: onChangeProduct,
                                        isDisabled: !category?.value || state.isDisabled
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInputController
                                type="number"
                                label={t('tps.quantity')}
                                name="quantity"
                                options={{
                                    inputProps: {
                                        step: getProductStep(selectedProduct),
                                        min: getProductStep(selectedProduct),
                                        max: selectedProduct?.product_max_qty ?? 100,
                                        disabled: !product?.value || state.isDisabled
                                    }
                                }}
                            />
                        </FormGroup>
                        {!hiddeButtons && (
                            <Button
                                label={t('tps.add')}
                                // btnType="button"
                                disabled={!category?.value || !product?.value || !quantity || state.isDisabled}
                                onClick={handleSubmit}
                            />
                        )}
                    </div>

                    <div style={{overflowX: 'auto'}}>
                        <table className="tpsCart__table">
                            <thead>
                            <tr>
                                <th style={{minWidth: 125}}>{t('tps.product_name')}</th>
                                <th style={{width: 125}}>{t('tps.price')}</th>
                                <th style={{width: 125}}>{t('tps.quantity')}</th>
                                <th style={{width: 125}}>{t('tps.value')}</th>
                                <th style={{width: 125}}/>
                            </tr>
                            </thead>
                            <tbody>
                            {cart?.map(i => {
                                const product = getProduct(i.id)
                                const qty = getProductStep(product);
                                return !!product && (
                                    <tr key={i.id}>
                                        <td>{product.product_title}</td>
                                        <td>{formatPriceObj(product.product_price)}</td>
                                        <td>{qty * i.value}</td>
                                        <td>
                                            {countryConfig?.price_type_display === 'net' ? (
                                                formatPrice(qty * i.value * product.product_price?.net)
                                            ) : (
                                                formatPrice(qty * i.value * product.product_price?.gross)
                                            )}
                                        </td>
                                        <td style={{textAlign: 'right'}}>
                                            {!hiddeButtons && (
                                                <Button
                                                    type="secondary"
                                                    label={t('remove')}
                                                    onClick={() => removeProduct(i.id)}
                                                    disabled={state.isDisabled}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan={2}/>
                                <td><b>{t('total')}</b></td>
                                <td>{formatPrice(price)}</td>
                            </tr>
                            </tfoot>
                        </table>
                        <div className="grid">
                            {!!cartError.cartError && (
                                <InfoComponent message={t(cartError.cartError)} type="error"/>
                            )}
                            <div/>
                        </div>
                    </div>

                </div>
            </div>
            <div className="tpsForm__items"/>
        </div>
    )
}