import Form from "./Form";
import {useForm, useWatch} from "react-hook-form";
import {useFormRulesBuilder} from "./hooks";
import {FormGroup} from "./components";
import {EmailInput, PasswordInput} from "./presets";
import React from "react";
import {useTranslation} from "../hooks";
import {Button} from "../components/buttons/_index";
import {Link} from "react-router-dom";
import {Routes} from "../utils/_index";
import {useNavigation} from "../misc/Hooks";
import {TextInputController} from "./controllers";

export default function ResellerLoginSsoForm({
    HeaderComponent,
    FooterComponent,
    onSubmit,
    SubmitComponent,
    submitText,
    text,
    state,
}) {

    state = {
        isLoading: false,
        error: null,
        ...state,
    }

    const {t} = useTranslation();
    const navigation = useNavigation();

    const formMethods = useForm({
        mode: 'onChange',
    })
    const formRules = useFormRulesBuilder({
        'login': b => b.required(),
    }, formMethods)

    const handleSubmit = (data) => {
        if (typeof onSubmit === 'function')
            onSubmit(data)
    }

    const login = useWatch({
        control: formMethods.control,
        name: 'login'
    })


    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            onSubmit={handleSubmit}
        >
            {HeaderComponent ? <HeaderComponent/> : (
                <p className="par-2">{text ?? t('ev_customer_identification.sign_in')}</p>
            )}

            <FormGroup>
                <TextInputController
                    name="login"
                    label={t('login')}
                    options={{
                        inputProps: {
                            disabled: state.isLoading
                        }
                    }}
                />
            </FormGroup>


            {SubmitComponent ? <SubmitComponent/> : (
                <Button
                    btnType="submit"
                    type="primary"
                    label={submitText ?? t('continue')}
                    disabled={state.isLoading || !login}
                    isLoading={state.isLoading}
                />
            )}

        </Form>
    )


}