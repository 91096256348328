import {useEffect} from "react";
import {useFormHelper, useTranslation} from "../../hooks";
import {TextInputController} from "../controllers";
import {useFormRulesBuilder} from "../hooks";

export default function SmsPhoneVerificationFormFragment({formVisible = false, error, ...props}) {
    const {t} = useTranslation()
    const {formMethods, setError} = useFormHelper()
    const formRules = useFormRulesBuilder({
        smsPhoneVerificationCode: b => b.required(),
    }, formMethods)

    useEffect(() => {
        if(error) {
            setError('smsPhoneVerificationCode', {type: 'invalid', message: error})
        }
    }, [error])

    return formVisible && (
        <div>
            {!!t('sms_phone_validation.text') && (
                <div className="par-2" dangerouslySetInnerHTML={{__html: t('sms_phone_validation.text')}}/>
            )}
            <TextInputController
                name="smsPhoneVerificationCode"
                label={t('sms_phone_validation.code_label')}
                rules={formRules.values.smsPhoneVerificationCode}
            />
        </div>
    )
}
