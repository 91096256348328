import {Button} from "../../../../components/buttons/_index";
import {TableComponent, useTableBuilder, useTableFilters} from "../../../../components/baseTable";
import {useReseller, useTranslation} from "../../../../hooks";
import {useResellerActions} from "../../../../actions/useResellerActions";
import {useNavigation} from "../../../../misc/Hooks";
import Routes from "../../../../utils/Routes";
import {useHistory} from "react-router-dom";
import {hi} from "date-fns/locale";

export default function PSMChoicePosScreen(){
    const {t} = useTranslation()

    const {fetchResellerPos} = useResellerActions()
    const {user: {id_reseller}} = useReseller()
    const navigation = useNavigation()
    const history = useHistory()

    const posFilter = useTableFilters({
        builder: {
            formConfig: [['slug']],
        },
        delayFields: ['slug'],
        searchPrefix: "posFilter",
        mapFunc: (data) => ({
            slug: data.posFilter?.slug ?? '',
        }),
    });

    const posTable = useTableBuilder({
        name: `psm_pos_list_${id_reseller}`,
        action: async (params) => fetchResellerPos(params),
        queryProps: {
            onSuccess: ({data}) => {
                if(data.data.total === 1 && !posFilter.rawData?.slug){
                    history.replace(navigation(Routes.V2_RESELLER_STOCK_MANAGEMENT_POS, data.data.data[0].id_shop))
                }
            }
        },
        columns: [
            {
                name: 'shop_title',
                label: 'stock_management.shop_title'
            },
            {
                name: 'shop_city',
                label: 'stock_management.shop_city'
            },
            {
                name: 'shop_address',
                label: 'stock_management.shop_address'
            },
            {
                name: 'id_shop',
                label: 'stock_management.local_stock',
                cell: ({value}) => (
                    <Button
                        href={navigation(Routes.V2_RESELLER_STOCK_MANAGEMENT_POS, value)}
                        label={t('stock_management.management')}
                        size={"md"}

                    />
                )
            },
        ],
        config: {},
        params: {...posFilter.rawData}
    })

    return (
        <div className="wrapper -xxl">
            <div className="resMS">
                <div className="resMS__header">

                    <TableComponent
                        {...posTable}
                        title={t('stock_management.pos_list')}
                        filters={posFilter}
                    />
                </div>
            </div>
        </div>

    )
}