import {useRef, useState} from "react";

export default (props) => {
    const [opened, setOpened] = useState(false);
    const bodyRef = useRef(null);


    return (
        <div className="accordion">
            <div className="accordion__header" onClick={() => setOpened(!opened)}>
                <h4 className="accordion__title par-1">{props.title}</h4>
                <img
                    src={require('../../assets/img/icons/arrow-down.svg').default}
                    className={opened ? `-active` : ''}
                    alt="down"
                />
            </div>
            <div
                className="accordion__body"
                style={{maxHeight: opened ? `${bodyRef?.current?.scrollHeight ?? 0}px` : '0px'}}
                ref={bodyRef}
            >
                {props.children}
            </div>
        </div>
    )
}
