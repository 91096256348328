import Env from "../utils/Env";


export default function useApiLogger() {
    const info = (data) => {
        const baseData = {
            type: 'LOG',
            method: 'LOG',
            uuid: '',
            token: '',
            url: window.location.href,
            payload: null,
            response: null,
        }
        const payload = {...baseData, ...data};

        fetch(`${Env.API_URL}/payment-log`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            // redirect: 'follow',
            body: JSON.stringify(payload),
            keepalive: true,
        })
    }

    return {
        info,
    }
}
