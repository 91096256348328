import {Footer, Header} from "../components/misc/_index";
import {AuthForm, CustomerForm} from "../forms";

export default function TestScreen() {
    return (
        <Layout>
            <h1>test screen2!</h1>
            <hr/>
            {/*<SerialNumberForm/>*/}
            <AuthForm/>
        </Layout>
    )
}

function Layout({children}) {
    return (
        <div className="app">
            <Header
                cartDisabled
            />
            <div className="main">
                <div className="wrapper-bg formContainer">
                    <div className="wrapper">
                        {children}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
