import Form from "./Form";
import {useForm, useWatch} from "react-hook-form";
import {useFormRulesBuilder} from "./hooks";
import {FormGroup} from "./components";
import {EmailInput, PasswordInput} from "./presets";
import React from "react";
import {useTranslation} from "../hooks";
import {Button} from "../components/buttons/_index";
import {Link} from "react-router-dom";
import {Routes} from "../utils/_index";
import {useNavigation} from "../misc/Hooks";
import {TextInputController} from "./controllers";

export default function ResellerForgotPasswordForm({
    onSubmit,
    state,
}) {

    state = {
        isLoading: false,
        error: null,
        ...state,
    }

    const {t} = useTranslation();

    const formMethods = useForm({
        mode: 'onChange',
    })
    const formRules = useFormRulesBuilder({
        'email': b => b.required().email(),
    }, formMethods)

    const handleSubmit = (data) => {
        if (typeof onSubmit === 'function')
            onSubmit(data)
    }

    const email = useWatch({
        control: formMethods.control,
        name: 'email'
    })


    return (
        <Form
            formMethods={formMethods}
            formRules={formRules}
            onSubmit={handleSubmit}
        >

            <FormGroup>
                <EmailInput/>
            </FormGroup>

            <Button
                btnType="submit"
                type="primary"
                label={t('continue')}
                disabled={state.isLoading || !email}
                isLoading={state.isLoading}
            />

        </Form>
    )


}