import {getFieldFormat, regexFromString} from "../misc/Helpers";
import {useCountryConfig} from "../misc/Hooks";
import {useMemo} from "react";
import {useReseller} from "./index";

export default function useFieldFormat() {
    const countryConfig = useCountryConfig()
    const {isLoggedIn} = useReseller()
    const formats = useMemo(() => ({
        taxId: modifyFormat(countryConfig.custom_format?.custom_tax_id_format),
        companyTaxId: modifyFormat(countryConfig.custom_format?.custom_company_tax_id_format),
        companyName: modifyFormat(typeof countryConfig.custom_format?.custom_company_name_format === 'undefined' ? {max_length: 35} : countryConfig.custom_format?.custom_company_name_format),
        firstname: modifyFormat(countryConfig.custom_format?.custom_firstname_format),
        lastname: modifyFormat(countryConfig.custom_format?.custom_lastname_format),
        postcode: modifyFormat(countryConfig.custom_format?.custom_postcode_format),
        city: modifyFormat(countryConfig.custom_format?.custom_city_format),
        addressLine1: modifyFormat(countryConfig.custom_format?.custom_address1_format),
        addressLine2: modifyFormat(countryConfig.custom_format?.custom_address2_format),
        phone: modifyFormat(countryConfig.custom_format?.custom_phone_format),
        phoneMobile: modifyFormat(countryConfig.custom_format?.custom_phone_mobile_format),
        phoneHome: modifyFormat(countryConfig.custom_format?.custom_phone_home_format),
        accountNumber: modifyFormat(countryConfig.custom_format?.custom_bank_account_format),
        email: modifyFormat(countryConfig.custom_format?.custom_email_format),
        reEmail: modifyFormat(countryConfig.custom_format?.custom_re_email_format),

        bsbNumber: modifyFormat({
            pattern: /^[0-9]{3}-[0-9]{3}$/,
            mask: '999-999',
            mask_status: 1,
        }),
        // accountNumber: modifyFormat({
        //     pattern: /^[0-9]{6,11}$/,
        //     mask: null,
        //     mask_status: 0,
        // }),
        serialNumber: modifyFormat({
            pattern: /[a-zA-Z0-9]{19}/,
            mask: new Array(19).fill('*').join(''),
            mask_status: 1,
        }),
        promoCode: modifyFormat({
            pattern: regexFromString(countryConfig?.promo_code_regex) ?? /^.+/,
            max_length: countryConfig?.promo_code_regex_max_length,
        }),
        login: modifyFormat({
            pattern: /^[A-Za-z0-9_.]+$/
        })
    }), [countryConfig.custom_format])

    function modifyFormat(object) {
        const format = getFieldFormat(object)
        const newFormat = {
            ...format,
            status: isLoggedIn ? format.reseller_status : format.status,
            required: isLoggedIn ? format.reseller_required : format.required,
            copyEnabled: object?.copy ? !!Number(object.copy) : true,
            pasteEnabled: object?.past ? !!Number(object.past) : true,
        }

        newFormat.isEnabled = !!newFormat.status
        newFormat.isRequired = !!newFormat.required

        return newFormat
    }

    function getIfValid(value, format) {
        if(!value || !format)
            return null
        return value.toString().match(format)?.length ? value : null
    }

    return {
        formats,
        getIfValid,
    }
}
