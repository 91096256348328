import {useSelector} from "react-redux";
import {useModuleContext} from "./index";

export default function usePlan() {
    const {storeName} = useModuleContext()
    const plan = useSelector(state => {
        return state[storeName]?.products?.find(p => {
            const item = state[storeName]?.cart?.items?.find(i => i.id === p.id_product);
            return item && p.product_type.startsWith('plan_')
        })
    })

    return {
        plan,
    }
}
