import React, {useEffect, useMemo, useRef, useState} from "react";
import {useCart, useNavigation} from "../../misc/Hooks";
import {useHistory} from "react-router-dom";
import {
    useCountry,
    useCreateNespressoMember,
    useCustomer,
    useExtraLineProducts,
    useModuleContext,
    useNonPersistentQuotation,
    useOrderData,
    usePlan,
    useReseller,
    useSaveOrder,
    useSearchParams,
    useTranslation
} from "../../hooks";
import {Preloader} from "../../misc/_index";
import {Routes} from "../../utils/_index";
import {LinkSentModal} from "../../components/modals/_index";
import {isEnabledByMarket} from "../../utils/MarketConfig";
import PromoModal from "../../components/PromoModal";
import {useSavedOrder} from "../../components/Summary/hooks";
import {SummaryComponent} from "./index";
import AssistedOrderFullySavedModal from "../modals/AssistedOrderFullySavedModal";
import {useForm, useWatch} from "react-hook-form";

/**
 * Default order summary component with saving order
 * @param {string} homeRoute home route of module
 * @param {string} orderFinalizedRoute order finalized route
 * @param {string} assistedRedirectTo redirect to after save (when reseller is logged in)
 * @param {array|undefined} _paymentMethods (optional) payment methods (if not present, payment methods from promotion or plan will be used
 * @param {array|undefined} _deliveryMethods (optional) delivery methods (if not present, delivery methods from promotion or plan will be used
 * @param {function} action save order action
 * @param {function} resellerAction reseller save order action
 * @param actionParams
 * @param {function} payloadFn action's payload function
 * @param {{isLoading: boolean|undefined}} state
 * @param {function} getCustomSummaryOrder
 * @param {boolean} previewMode preview mode
 * @param {boolean} deliveryMethodEnabled delivery methods enabled
 * @returns {JSX.Element}
 * @constructor
 */
export default function OrderSummary({
    homeRoute,
    orderFinalizedRoute,
    assistedRedirectTo,
    paymentMethods: _paymentMethods,
    deliveryMethods: _deliveryMethods,
    action,
    resellerAction,
    actionParams,
    payloadFn,
    state,
    getCustomSummaryOrder,
    previewMode,
    deliveryMethodEnabled = false,
}) {
    state = {
        isLoading: false,
        isFetching: false,
        ...state,
    }
    const {t} = useTranslation()
    const {isLoggedIn} = useReseller()
    const quotationTimeoutRef = useRef(null)
    const {data: country} = useCountry()
    const {store, storeName, reducer, dispatch} = useModuleContext()
    const {
        cartValue,
        deliveryValue
    } = useCart(storeName);
    const navigation = useNavigation();
    const history = useHistory();
    const {plan} = usePlan()
    const {mapOrderToCustomer} = useCustomer()
    const searchParams = useSearchParams()
    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            // deliveryMethod: store.deliveryMethod?.id_delivery_method,
        }
    })
    const orderUuid = useMemo(() => {
        return searchParams.get('uuid')
    }, [searchParams.params, searchParams.get])
    const [linkSentModal, setLinkSentModal] = useState(false);
    const [initialQuotationCalled, setInitialQuotationCalled] = useState(false);
    const [isAssistedExistingSimplifiedFlow, setIsAssistedExistingSimplifiedFlow] = useState(false)
    const deliveryMethodId = useWatch({
        control: formMethods.control,
        name: 'deliveryMethod',
    })
    const deliveryMethods = useMemo(() => {
        return _deliveryMethods ? _deliveryMethods : store.promotion?.delivery_methods ?? plan?.delivery_methods
    }, [_deliveryMethods, store.promotion?.id_promotion, plan?.id_product])
    const nonPersistentQuotation = useNonPersistentQuotation({
        deliveryMethods,
        paymentMethods: _paymentMethods ? _paymentMethods : store.promotion?.payment_methods ?? plan?.payment_methods,
        deliveryMethodEnabled,
    })
    const createNespressoMember = useCreateNespressoMember()
    const savedOrder = useSavedOrder({
        homeRoute,
        orderFinalizedRoute,
        orderUuid,
        setLinkSentModal,
        setIsAssistedExistingSimplifiedFlow,
    })
    const saveOrder = useSaveOrder({
        action,
        params: actionParams,
        setLinkSentModal,
    })
    const resellerSaveOrder = useSaveOrder({
        action: resellerAction,
        params: actionParams,
    })
    const saveOrderState = useMemo(() => {
        return {
            isLoading: saveOrder.isLoading || resellerSaveOrder.mutation.isLoading || createNespressoMember.isLoading,
            error: saveOrder.error || resellerSaveOrder.mutation.error || createNespressoMember.error,
        }
    }, [saveOrder.isLoading, saveOrder.error, resellerSaveOrder.mutation.status, createNespressoMember.status])
    const orderData = useOrderData()

    useExtraLineProducts(storeName)

    useEffect(() => {
        if (orderUuid) {
            if (store.uuid) {
                dispatch(reducer.clearStore())
            } else {
                savedOrder.getOrder(orderUuid)
            }
        }
    }, [orderUuid, store.uuid])

    useEffect(() => {
        if (isEnabledByMarket('disableEcapi') || isLoggedIn || orderUuid)
            return

        if (initialQuotationCalled) {
            clearTimeout(quotationTimeoutRef.current)
            quotationTimeoutRef.current = setTimeout(() => {
                // makeQuotation()
                nonPersistentQuotation.prepareQuotation()
            }, 850)
        } else {
            // makeQuotation()
            nonPersistentQuotation.prepareQuotation()
            setInitialQuotationCalled(true)
        }
    }, [JSON.stringify(store.cart)])

    useEffect(() => {
        if (isEnabledByMarket('disableEcapi') || isLoggedIn || orderUuid || !deliveryMethodEnabled)
            return
        if (!deliveryMethodId) return

        const deliveryMethod = nonPersistentQuotation.deliveryMethods?.find(i => i.id_delivery_method === Number(deliveryMethodId))
        if (deliveryMethod) {
            nonPersistentQuotation.verifyQuotation(deliveryMethod)
        }
    }, [deliveryMethodId])

    const handleSubmit = async (data) => {
        const paymentMethod = nonPersistentQuotation.paymentMethods?.find(p => p.id_payment_method === Number(store.customer?.paymentMethod ?? data.paymentMethod))
        const deliveryMethod = nonPersistentQuotation.deliveryMethods?.find(p => p.id_delivery_method === Number(store.customer?.deliveryMethod ?? data.deliveryMethod))

        const payload = payloadFn({
            orderData,
            paymentMethod,
            deliveryMethod,
            pickupPoint: data.pickupPoint,
            formData: data,
        })

        const _pm = paymentMethod?.payment_method_basket_null && (Number(cartValue + deliveryValue) <= 0)
            ? paymentMethod?.payment_method_basket_null
            : paymentMethod

        const isAssistedExistingSimplifiedFlow = !_pm.api_use_hop
            && !_pm.payment_method_requires_one_time_token
            && !_pm.adyen_payment_type
            && (_pm.payment_method_integration_type !== 'xml')
            && country.country_assisted_simplified_flow_enabled

        setIsAssistedExistingSimplifiedFlow(isAssistedExistingSimplifiedFlow)

        if (isLoggedIn && isAssistedExistingSimplifiedFlow) {
            await resellerSaveOrder.mutation.mutateAsync([
                payload,
                {
                    paymentMethod,
                    deliveryMethod,
                    pickupPoint: data.pickupPoint,
                    isAssistedExistingSimplifiedFlow,
                }
            ]).catch(e => {
                resellerSaveOrder.setError(t(`error.order_save_error`))
                throw new Error(e.error)
            })

            if (!store.searchedMember?.hasWebAccount) {
                await createNespressoMember.createAccount({
                    data: {
                        details: {},
                        recaptchaToken: data.recaptchaToken,
                    }
                }).catch(e => {
                    createNespressoMember.setError(t(`error.${e.error}`) || t('error.unknown_error'))
                    throw new Error(e.error)
                })
            }
        }

        await saveOrder.save({
            payload,
            customData: {
                paymentMethod,
                deliveryMethod,
                pickupPoint: data.pickupPoint,
                isAssistedExistingSimplifiedFlow,
            }
        })
    }

    if (savedOrder.isLoading
        || state.isLoading
        || (!store.uuid && !savedOrder.data)
        || (!isLoggedIn
            && (!orderUuid && !isEnabledByMarket('disableEcapi')
                && (nonPersistentQuotation.prepareMutation.isLoading || (nonPersistentQuotation.prepareMutation.isIdle && !!['error', 'success'].includes(nonPersistentQuotation.prepareMutation.status)))
                || (nonPersistentQuotation.quotationMutation.isLoading || (nonPersistentQuotation.quotationMutation.isIdle && !!['error', 'success'].includes(nonPersistentQuotation.quotationMutation.status))))
        )
    ) {
        return <Preloader/>
    }

    return (
        <>
            <SummaryComponent
                formMethods={formMethods}
                data={{
                    customer: savedOrder.data ? mapOrderToCustomer(savedOrder.data.order) : store.customer,
                    savedOrder: savedOrder.data ? savedOrder.data : null,
                    promotion: savedOrder.data ? savedOrder.data.promotion : store.promotion,
                    paymentMethods: savedOrder.data ? savedOrder.data.paymentMethods : nonPersistentQuotation.paymentMethods,
                    deliveryMethods: savedOrder.data ? savedOrder.data.deliveryMethods : nonPersistentQuotation.deliveryMethods,
                    plan,
                    memberCredit: nonPersistentQuotation.memberCredit,
                }}
                getCustomSummaryOrder={getCustomSummaryOrder}
                previewMode={previewMode}
                deliveryMethodEnabled={deliveryMethodEnabled}
                state={{
                    isLoading: saveOrderState.isLoading || savedOrder.isLoading || (state.isFetching && !store.cart?.items?.length) || nonPersistentQuotation.verifyMutation.isLoading,
                    error: saveOrderState.error || savedOrder.error,
                }}
                onSubmit={handleSubmit}
            />

            {isAssistedExistingSimplifiedFlow ? (
                <AssistedOrderFullySavedModal
                    visible={linkSentModal}
                    onClose={() => {
                        setLinkSentModal(false);
                        dispatch(reducer.clearStore())
                        history.push(navigation(assistedRedirectTo ?? Routes.V2_RESELLER_ASSISTED_REGISTRATION));
                    }}
                />
            ) : (
                <LinkSentModal
                    visible={linkSentModal}
                    onClose={() => {
                        setLinkSentModal(false);
                        dispatch(reducer.clearStore())
                        history.push(navigation(assistedRedirectTo ?? Routes.V2_RESELLER_ASSISTED_REGISTRATION));
                    }}
                    uuid={orderUuid}
                    lang={store.customer?.lang?.value ?? store.customer?.details?.lang?.value}
                />
            )}

            <PromoModal
                popupType="optin"
            />
        </>
    )
}
