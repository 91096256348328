import React from 'react';
import {ImSpinner8} from "react-icons/im";
import sanitizeText from "../helpers/sanitizeText";

export default function Preloader({
    size = '2rem',
    position = 'center',
    message = ''
}){

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: position

        }}>
            <ImSpinner8 className="spinner" style={{fontSize: size ?? '2rem'}}/>
            {!!message && <div className="spinnerText editArea" {...sanitizeText(message)}/>}
        </div>
    );

}
