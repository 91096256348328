import {Routes} from "../../../utils/_index";
import {useCart, useNavigation} from "../../../misc/Hooks";
import {useSelector} from "react-redux";
import {useStepsNavigation} from "./index";
import {useHistory, useLocation} from "react-router-dom";
import {isEnabledByMarket} from "../../../utils/MarketConfig";
import {useRequiredField} from "../../index";


/**
 * Validates machine registration module route / step
 * @param route
 * @type route: string
 * @returns {{lastValidRoute: (*), value: boolean}}
 */
export default function useStepValidation(route) {
    const navigation = useNavigation()
    const location = useLocation()
    const stepsNavigation = useStepsNavigation()
    const history = useHistory()
    const {requiredField} = useRequiredField()
    const store = useSelector(({main, machineRegistration}) => ({
        country: main.country,
        user: main.user,
        machine: machineRegistration.machine,
        data: machineRegistration.data,
        nespressoMember: machineRegistration.nespressoMember,
        proofOfPurchase: machineRegistration.proofOfPurchase,
        proofOfPurchaseLogs: machineRegistration.proofOfPurchaseLogs,
        promoCode: machineRegistration.promoCode,
        promotion: machineRegistration.promotion,
        customer: machineRegistration.customer,
        isFastOrder: machineRegistration.isFastOrder,
        isPostPurchasePromoCode: machineRegistration.isPostPurchasePromoCode,
        abandonedOrderConsentAccepted: machineRegistration.abandonedOrderConsentAccepted,
        isNewMember: machineRegistration.isNewMember,
        msnRequired: machineRegistration.msnRequired,
    }))
    const orderUuid = new URLSearchParams(location?.search).get('uuid')
    const {isCartValid} = useCart('machineRegistration')

    const response = {
        canShowStep: true,
        lastValidStep: Routes.MACHINE_REGISTRATION,
    }

    switch (route) {
        case Routes.MACHINE_REGISTRATION_SUMMARY:
            if (orderUuid) {
                response.canShowStep = true
                response.lastValidStep = Routes.ORDER_FINISH_SUMMARY
                break
            }

            if (!store.customer || (!store.user && !store.nespressoMember)) {
                response.canShowStep = false
                response.lastValidStep = Routes.MACHINE_REGISTRATION_CUSTOMER_INFORMATION
            }
        case Routes.MACHINE_REGISTRATION_CUSTOMER_INFORMATION:
            if (!isCartValid || !store.promotion) {
                response.canShowStep = false
                response.lastValidStep = Routes.MACHINE_REGISTRATION_OFFER_SELECTION
            }
        case Routes.MACHINE_REGISTRATION_OFFER_SELECTION:
            if ((store.isPostPurchasePromoCode ? true : store.machine || !store.msnRequired) && (isEnabledByMarket('disableEcapi') || (store.isNewMember === false ? store.nespressoMember : typeof store.isNewMember === 'boolean'))) {
                if (!store.isPostPurchasePromoCode) {
                    switch (requiredField) {
                        case 'proof_of_purchase_and_promo_code':
                            if (!(store.proofOfPurchase && store.promoCode))
                                response.canShowStep = false
                            break
                        case 'proof_of_purchase_or_promo_code':
                            if (!(store.proofOfPurchase || store.promoCode))
                                response.canShowStep = false
                            break
                        case 'only_proof_of_purchase':
                            if (!store.proofOfPurchase)
                                response.canShowStep = false
                            break
                        case 'only_promo_code':
                            if (!store.promoCode)
                                response.canShowStep = false
                            break
                    }
                }
            } else {
                response.canShowStep = false
                response.lastValidStep = stepsNavigation.prev()
            }
        case Routes.MACHINE_REGISTRATION_REGISTRATION:
    }

    return {
        value: response.canShowStep,
        lastValidRoute: navigation(response.lastValidStep),
    }
}