import {Route, Switch} from "react-router-dom";
import Routes from "../../utils/Routes";
import React from "react";
import Provider from "./Provider";
import StepsFunnel from "./StepsFunnel";
import {useSteps} from "../../hooks/modules/shop_reopen";
import {InvalidTokenScreen} from "./screens";
import {makeStore, ModuleProvider} from "../../hooks";
import {AppProvider} from "../../misc/_index";
import {OrderFinalizedScreen} from "../../screens";

export default function Funnel() {
    const steps = useSteps()

    const contextValue = makeStore({
        storeName: 'shopReopen',
        steps,
    })

    return (
        <AppProvider>
            <ModuleProvider value={contextValue}>
                <Provider>
                    <Switch>
                        <Route
                            exact
                            path={Routes.SHOP_REOPEN_ORDER_FINALIZED}
                            component={OrderFinalizedScreen}
                        />
                        <Route
                            exact
                            path={Routes.SHOP_REOPEN_INVALID_TOKEN}
                            component={InvalidTokenScreen}
                        />
                        <Route
                            exact path={steps.map(s => s.route)}
                            component={StepsFunnel}
                        />
                    </Switch>
                </Provider>
            </ModuleProvider>
        </AppProvider>
    )
}
